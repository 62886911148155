






















































import { Component, Prop, Vue } from 'vue-property-decorator'
import { addCommas } from '@/utils/business.util'
import _ from 'lodash'
import PaymentHistoryPopup from '@/views/freightHistory/PaymentHistoryPopup.vue'

@Component({
  components: {
    PaymentHistoryPopup,
  },
})
export default class CardPaymentInit extends Vue {
  @Prop(Object) itemDetail

  get paymentInfo() {
    return {
      title: '결제 정보',
      data: this.setPaymentData(),
    }
  }

  setPaymentData() {
    let data: any = [
      {
        title: '운송료 (VAT별도)',
        content: this.itemDetail?.additionalCharge
          ? `${addCommas(
              this.itemDetail?.totalFreightCharge -
                this.itemDetail?.additionalCharge,
            )}원`
          : `${addCommas(this.itemDetail?.totalFreightCharge)}원`,
      },
      {
        title: '추가 운송료',
        content: `${addCommas(this.itemDetail?.additionalCharge)}원`,
      },
      {
        title: '총 운송료(VAT별도)',
        content: `${addCommas(this.itemDetail?.totalFreightCharge)}원`,
      },
    ]
    return data
  }

  lineDisplay(item) {
    if (_.isEqual(item.title, '추가 운송료')) return true
    else return false
  }

  isHistoryList = false
  callRequestId = null

  openHistoryListPopup() {
    this.callRequestId = this.itemDetail.callRequestId
    this.isHistoryList = true
  }
}
