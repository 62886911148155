var logi = logi ?? {};
logi['maps'] = logi['maps'] ?? {};
import a22_0x3d1286 from './base/logi-maps-defines.js';
logi['maps']['Defines'] = a22_0x3d1286;
import a22_0x4a330b from './base/logi-maps-types.js';
logi['maps']['ObjectsInTile'] = a22_0x4a330b['ObjectsInTile'];
import a22_0xf7ce2b from './base/logi-maps-utils.js';
logi['maps']['Utils'] = a22_0xf7ce2b;
import a22_0x7daf1c from './base/logi-maps-object.js';
logi['maps']['Object'] = a22_0x7daf1c;
import a22_0x34acac from './base/logi-maps-layer.js';
logi['maps']['Layer'] = a22_0x34acac;
import a22_0x360f24 from './base/logi-maps-boundarydata.js';
logi['maps']['BoundaryData'] = a22_0x360f24;
import a22_0x2552d5 from './base/logi-maps-boundarychecker.js';
logi['maps']['BoundaryChecker'] = a22_0x2552d5;
import a22_0x4f0459 from './logi-maps-line.js';
logi['maps']['Line'] = a22_0x4f0459, logi['maps']['UiLayer'] = class extends logi['maps']['Layer'] {
    #centerMark;
    #devWaterMarkFlag;
    constructor(_0xa47b92, _0x36166d) {
        const _0x162f16 = Math['random']()['toString'](0x24)['slice'](-0x8);
        super(_0xa47b92, _0x36166d, 'uilayer_' + _0x162f16), this.#centerMark = {
            'length': 0x0,
            'thickness': 0x0,
            'color': 'red'
        }, this.#devWaterMarkFlag = ![];
    }
    ['setCenterMark'](_0xf95c59, _0x41a625, _0x3a3d5a) {
        (this.#centerMark['length'] != _0xf95c59 || this.#centerMark['thickness'] != _0x41a625 || this.#centerMark['color'] != _0x3a3d5a) && (this.#centerMark['length'] = _0xf95c59 ?? 0x0, this.#centerMark['thickness'] = _0x41a625 ?? 0x0, this.#centerMark['color'] = _0x3a3d5a ?? 'red', this['updateLayer']());
    }
    ['setDevWaterMarkFlag'](_0x3935f1) {
        this.#devWaterMarkFlag != _0x3935f1 && (this.#devWaterMarkFlag = _0x3935f1, this['updateLayer']());
    }
    ['triggerEvent'](_0x543de0) {
    }
    ['hitObj'](_0x159d59, _0x21c5a8) {
        return null;
    }
    ['draw']() {
        this['clear'](), this.#drawCrossHair();
    }
    #drawCrossHair = function () {
        if (this.#centerMark['length'] > 0x0 && this.#centerMark['thickness'] > 0x0) {
            const _0x4006c7 = this['getDevicePixelRatio'](), _0x563402 = {
                    'x': this['getCanvasWidth']() / _0x4006c7 * 0.5,
                    'y': this['getCanvasHeight']() / _0x4006c7 * 0.5
                }, _0x4a35eb = this['getContext2D']();
            _0x4a35eb['save'](), _0x4a35eb['scale'](_0x4006c7, _0x4006c7);
            const _0x1b6945 = this.#centerMark['length'] * 0.5;
            logi['maps']['Line']['drawLine'](_0x4a35eb, {
                'x': _0x563402['x'] - _0x1b6945,
                'y': _0x563402['y']
            }, {
                'x': _0x563402['x'] + _0x1b6945,
                'y': _0x563402['y']
            }, this.#centerMark['thickness'], this.#centerMark['color'] ?? 'red'), logi['maps']['Line']['drawLine'](_0x4a35eb, {
                'x': _0x563402['x'],
                'y': _0x563402['y'] - _0x1b6945
            }, {
                'x': _0x563402['x'],
                'y': _0x563402['y'] + _0x1b6945
            }, this.#centerMark['thickness'], this.#centerMark['color'] ?? 'red'), _0x4a35eb['restore']();
        }
    };
    #drawDevWaterMark = function () {
        if (this.#devWaterMarkFlag) {
            const _0x209a3e = this['getDevicePixelRatio'](), _0x1993a1 = this['getContext2D']();
            _0x1993a1['save'](), _0x1993a1['scale'](_0x209a3e, _0x209a3e), _0x1993a1['font'] = 'bold\x2032px\x20' + logi['maps']['Defines']['DEFAULT_FONT'], _0x1993a1['fillStyle'] = '#00000020', _0x1993a1['textAlign'] = 'left', _0x1993a1['textBaseline'] = 'bottom', _0x1993a1['fillText']('dev-server', 0x10, this['getCanvasHeight']() / _0x209a3e - 0x8), _0x1993a1['restore']();
        }
    };
};
export default logi['maps']['UiLayer'];