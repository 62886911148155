import _ from 'lodash'
//import { PERIOD_INTERVAL } from '@/new-views/page/callerReport/ChartData'
//import { h } from '@vue/composition-api'

const moment = require('moment')

class DatetimeUtil {
  dayOfWeek() {
    const weekDay = ['일', '월', '화', '수', '목', '금', '토']
    return weekDay[moment().day()]
  }

  plusMinutes(date, m = 0) {
    if (!date) throw Error()
    return new Date(date.getTime() + m * 60 * 1000)
  }

  getMonth(m = 0) {
    const newDate = new Date()
    newDate.setMonth(newDate.getMonth() + m)
    return newDate
  }

  getYear(m = 0) {
    const newDate = new Date()
    newDate.setFullYear(newDate.getFullYear() + m)
    return newDate
  }

  plusDays(date, d = 0) {
    if (!date) throw Error()
    return new Date(date.getTime() + d * 24 * 60 * 60 * 1000)
  }

  now() {
    return moment().format('YYYY-MM-DD  HH:mm:ss')
  }

  rightNow() {
    return moment().format('YYYY-MM-DD-HH-mm-ss')
  }

  now_format(format) {
    return moment().format(format)
  }

  nowPlusMinutes(minutes = 0, format: any) {
    return moment().add(minutes, 'minutes').format(format)
  }

  nowPlusHours(hours = 0, format: any) {
    return moment().add(hours, 'hours').format(format)
  }

  nowPlusWeeks(weeks = 0, format: any) {
    return moment().add(weeks, 'weeks').format(format)
  }

  nowPlusDays(d = 0, format: any) {
    if (format === 'YYYY-MM-DD')
      return moment().add(d, 'days').format('YYYY-MM-DD')
    else return moment().add(d, 'days').format('YYYY-MM-DD HH:mm:ss')
  }

  nowCeil10Minutes(format: any) {
    const now = moment()
    now.minute(Math.ceil((now.minute() + 1) / 10) * 10)
    return now.format(format)
  }

  nowPlus1HourCeil10Minutes(format: any) {
    const now = moment()
    now.add(1, 'hours')
    now.minute(Math.ceil((now.minute() + 1) / 10) * 10)
    return now.format(format)
  }

  datePlusDays(date: any, d = 0) {
    return moment(date).add(d, 'days').format('YYYY-MM-DD')
  }

  datePlusMonths(date: any, d = 0) {
    return moment(date).add(d, 'month').format('YYYY-MM-DD')
  }

  datePlusYears(date: any, d = 0) {
    return moment(date).add(d, 'year').format('YYYY-MM-DD')
  }

  dateMinusMonths(date: any, month = 0, format: any = 'YYY-MM-DD') {
    return moment(date).subtract(month, 'month').format(format)
  }

  dateMinusHour(date: any, hour = 0, format: any = 'YYY-MM-DD HH:mm:ss') {
    return moment(date).subtract(hour, 'hours').format(format)
  }

  dateMinusSeconds(date: any, sec = 0, format: any = 'YYY-MM-DD HH:mm:ss') {
    return moment(date).subtract(sec, 'seconds').format(format)
  }

  stringDate(dateTime: any, format: any) {
    return moment(dateTime).format(format)
  }

  stringTimerDate(dateTime: any) {
    return moment(dateTime, 'HH:mm').format('HH:mm:ss')
  }

  setDateSearchData(value: any) {
    let dateSearchData: any = []

    if (value.length == 1) {
      return { fromDt: value[0], toDt: this.datePlusDays(value[0], 1) }
    } else if (value.length > 1) {
      dateSearchData = _.sortBy(value, function (o) {
        return o
      })
      return {
        fromDt: dateSearchData[0],
        toDt: this.datePlusDays(dateSearchData[1], 1),
      }
    } else {
      return { fromDt: null, toDt: null }
    }
  }

  setTypeStartDate(date, type) {
    return moment(date).startOf(type).format('YYYY-MM-DD HH:mm:ss')
  }

  setTypeEndDate(date, type, format = 'YYYY-MM-DD HH:mm:ss') {
    return moment(date).startOf(type).add(1, 'days').format(format)
  }

  setRealEndDate(type) {
    return moment().endOf(type).format('YYYY-MM-DD')
  }

  setTypeStartDay(date, type) {
    return moment(date).startOf(type).format('YYYY-MM-DD')
  }

  setTypeEndDay(date, type) {
    return moment(date).endOf(type).add(1, 'days').format('YYYY-MM-DD')
  }

  setKoreaDate(date, type) {
    if (_.isNil(date)) return '-'
    else if (type === 'year') {
      return moment(date).format('YYYY년')
    } else if (type === 'month') {
      return moment(date).format('YYYY년MM월')
    } else if (type === 'full') {
      return moment(date).format('YYYY년MM월DD일 HH시mm분')
    } else {
      return moment(date).format('YYYY년MM월DD일')
    }
  }

  dayOfWeekIndex(date) {
    return moment(date).day()
  }

  dayOfWeekString(value) {
    const weekDay = ['일', '월', '화', '수', '목', '금', '토']
    return weekDay[value]
  }

  changed24Timer(timeString) {
    const formattedTime = moment(timeString, 'A hh:mm').format('HH:mm:ss')
    return formattedTime
  }

  roundedTime(hour) {
    const currentTime = moment().add(hour, 'hours')
    const roundedTime = moment(
      Math.ceil(currentTime / (30 * 60 * 1000)) * (30 * 60 * 1000),
    ) // 30분 단위로 반올림
    return roundedTime.format('A hh:mm')
  }

  nextMonday() {
    const monday = moment().add(1, 'weeks').startOf('isoWeek')
    return monday.format('YYYY-MM-DD')
  }

  isDateAfter(afterDate, beforeDate) {
    return moment(moment(afterDate).format('YYYY-MM-DD HH:MM:SS')).isAfter(
      moment(beforeDate).format('YYYY-MM-DD HH:MM:SS'),
    )
  }

  isDateBefore(afterDate, beforeDate) {
    return moment(moment(afterDate).format('YYYY-MM-DD HH:MM:SS')).isBefore(
      moment(beforeDate).format('YYYY-MM-DD HH:MM:SS'),
    )
  }

  getStartOfMonth(date) {
    const startOfMonth = moment(moment(date).format('YYYY-MM')).startOf('month')
    return startOfMonth.format('YYYY-MM-DD')
  }

  getEndOfMonth(date) {
    const endOfMonth = moment(moment(date).format('YYYY-MM')).endOf('month')
    return endOfMonth.format('YYYY-MM-DD')
  }

  getStartOfYear(date) {
    const startOfYear = moment(moment(date).format('YYYY-MM')).startOf('year')
    return startOfYear.format('YYYY-MM-DD')
  }

  getEndOfYear(date) {
    const endOfYear = moment(moment(date).format('YYYY-MM')).endOf('year')
    return endOfYear.format('YYYY-MM-DD')
  }

  timePickerFormatter(selectedTime) {
    let hours = parseInt(selectedTime.hh)
    const minutes = selectedTime.mm
    const period = selectedTime.A

    if (period === 'PM' && hours != 12) {
      hours += 12
    } else if (period === 'AM' && hours === 12) {
      hours = 0
    }

    return `${hours.toString().padStart(2, '0')}:${minutes}`
  }

  getDaysDiffTime(date) {
    const currentTime = moment()
    return currentTime.diff(date, 'days')
  }

  getHoursDiffTime(date) {
    const currentTime = moment()
    return currentTime.diff(date, 'hours')
  }

  getMinutesDiffTime(date) {
    const currentTime = moment()
    return currentTime.diff(date, 'minutes')
  }

  getSecondDiffTime(date) {
    const currentTime = moment()
    return currentTime.diff(date, 'second')
  }

  asDays(fromDay, toDay) {
    const from = moment(fromDay)
    const to = moment(toDay)

    return moment.duration(to.diff(from)).asDays()
  }

  asMonths(fromMonth, toMonth) {
    const from = moment(fromMonth)
    const to = moment(toMonth)

    return moment.duration(to.diff(from)).asMonths()
  }
}

export default new DatetimeUtil()
