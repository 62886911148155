<script>
import { Bar } from 'vue-chartjs'
import { chartColors } from '@/views/constant/ChartData'

export default {
  name: 'ChartjsComponentBarChart',
  extends: Bar,
  props: {
    baseLabels: {
      type: Array,
      default: null,
    },
    baseData: {
      type: Array,
      default: null,
    },
    maxValue: {
      type: Number,
      default: 0,
    },
    stepSize: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    this.renderBarChart()
  },
  watch: {
    baseLabels: function () {
      this.renderBarChart()
    },
    baseData: function () {
      this.renderBarChart()
    },
    maxValue: function () {
      this.renderBarChart()
    },
    stepSize: function () {
      this.renderBarChart()
    },
  },

  methods: {
    renderBarChart: function () {
      this.renderChart(
        {
          labels: this.baseLabels,
          datasets: [
            {
              data: this.baseData[0],
              backgroundColor: '#E5318E',
              borderColor: 'transparent',
              barThickness: 15,
            },
            {
              data: this.baseData[1],
              backgroundColor: '#1E436F',
              borderColor: 'transparent',
              barThickness: 15,
            },
          ],
        },
        {
          responsive: true,
          elements: {
            rectangle: {
              borderWidth: 2, // 막대의 경계선 두께를 설정합니다.
              cornerRadius: 10, // 모서리를 둥글게 만듭니다.
            },
          },
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          legend: {
            display: false,
          },
          tooltips: {
            callbacks: {
              label(tooltipItem, data) {
                return tooltipItem.value
              },
            },
          },
          scales: {
            xAxes: [
              {
                display: true,
                gridLines: {
                  display: true,
                  color: chartColors.grid_line_color,
                  zeroLineColor: chartColors.grid_line_color,
                },
                scaleLabel: {
                  display: false,
                },
                ticks: {
                  fontColor: chartColors.labelColor,
                  callback: function (tick) {
                    return tick.toLocaleString()
                  },
                },
              },
            ],
            yAxes: [
              {
                display: true,
                gridLines: {
                  color: chartColors.grid_line_color,
                  zeroLineColor: chartColors.grid_line_color,
                },
                ticks: {
                  stepSize: this.stepSize,
                  min: 0,
                  max: this.maxValue,
                  fontColor: chartColors.labelColor,
                  callback: function (tick) {
                    return tick.toLocaleString()
                  },
                },
              },
            ],
          },
        },
      )
    },
  },
}
</script>
