var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-wrapper content_body" },
    [
      _c(
        "div",
        { staticClass: "content-inner" },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "space-between",
                    "align-items": "center",
                  },
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "pr-2",
                          attrs: { size: "50" },
                          on: { click: _vm.backToList },
                        },
                        [_vm._v(" " + _vm._s(_vm.icons.mdiChevronLeft) + " ")]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "font-size26 font-weight700 surface800",
                        },
                        [
                          _vm._v(
                            " 계정 관리 : " + _vm._s(_vm.reviewInfo.name) + " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass:
                            "itgoWhite--text px-2 mt-n1 font-size18 search-btn mr-2",
                          attrs: {
                            color: "itgoInfo",
                            disabled: _vm.btnDisable,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onOpenManagerPopup(1)
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.reviewInfo.inactiveDt
                                  ? "계정 활성화"
                                  : "계정 비활성화"
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass:
                            "itgoWhite--text px-2 mt-n1 font-size18 search-btn mr-2",
                          attrs: {
                            color: "itgoInfo",
                            disabled: _vm.btnDisable,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onOpenManagerPopup(2)
                            },
                          },
                        },
                        [_vm._v(" 계정 삭제 ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass:
                            "itgoWhite--text px-2 mt-n1 font-size18 search-btn",
                          attrs: {
                            color: "itgoInfo",
                            disabled: _vm.btnChangedDisable,
                          },
                          on: { click: _vm.setEditManagerUser },
                        },
                        [_vm._v(" 계정 변경 ")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "v-card-text",
                { staticClass: "mt-10 pb-5" },
                _vm._l(_vm.personalInfo, function (item, index) {
                  return _c(
                    "v-card",
                    {
                      key: index,
                      staticClass: "pa-11",
                      attrs: { outlined: "" },
                    },
                    [
                      _c(
                        "v-card-title",
                        {
                          key: "title_" + index,
                          staticClass:
                            "px-0 mb-5 font-size16 surface800 font-weight700",
                        },
                        [_vm._v(" " + _vm._s(item.title) + " ")]
                      ),
                      _c(
                        "v-card-text",
                        { staticClass: "px-0 pt-0 pb-6" },
                        _vm._l(item.data, function (_item, i) {
                          return _item.show
                            ? _c("freight-info-item", {
                                key: "data_" + index + "-" + i,
                                attrs: {
                                  title: _item.title,
                                  content: _item.content,
                                },
                              })
                            : _vm._e()
                        }),
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          staticClass: "v-dialog",
          attrs: { persistent: "", "max-width": "600px" },
          model: {
            value: _vm.isUserAddPopup,
            callback: function ($$v) {
              _vm.isUserAddPopup = $$v
            },
            expression: "isUserAddPopup",
          },
        },
        [
          _c("manager-edit-popup", {
            key: _vm.editCount,
            attrs: { "pre-user-info": _vm.reviewInfo },
            on: { close: _vm.editPopupAct },
          }),
        ],
        1
      ),
      _c("manager-status-changed-popup", {
        attrs: {
          "open-popup-type": _vm.openPopupType,
          value: _vm.openManagerPopup,
          "pre-user-info": _vm.reviewInfo,
        },
        on: { close: _vm.onCloseManagerPopup },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }