<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="viewBox"
  >
    <g id="그룹_40429" data-name="그룹 40429" transform="translate(-46 -452)">
      <rect
        id="사각형_31089"
        data-name="사각형 31089"
        width="28"
        height="28"
        transform="translate(46 452)"
        fill="none"
      />
      <g
        id="그룹_40209"
        data-name="그룹 40209"
        transform="translate(48.333 454.333)"
      >
        <g
          id="패스_29420"
          data-name="패스 29420"
          transform="translate(-0.333 -0.333)"
          fill="none"
        >
          <path d="M12,0A12,12,0,1,1,0,12,12,12,0,0,1,12,0Z" stroke="none" />
          <path
            d="M 12 1.600000381469727 C 6.265420913696289 1.600000381469727 1.600000381469727 6.265420913696289 1.600000381469727 12 C 1.600000381469727 17.73458099365234 6.265420913696289 22.39999961853027 12 22.39999961853027 C 17.73458099365234 22.39999961853027 22.39999961853027 17.73458099365234 22.39999961853027 12 C 22.39999961853027 6.265420913696289 17.73458099365234 1.600000381469727 12 1.600000381469727 M 12 0 C 18.62742042541504 0 24 5.372579574584961 24 12 C 24 18.62742042541504 18.62742042541504 24 12 24 C 5.372579574584961 24 0 18.62742042541504 0 12 C 0 5.372579574584961 5.372579574584961 0 12 0 Z"
            stroke="none"
            :fill="iconColor"
          />
        </g>
        <g
          id="그룹_40207"
          data-name="그룹 40207"
          transform="translate(7.12 6.98)"
        >
          <path
            id="패스_29342"
            data-name="패스 29342"
            d="M7.4,0H0V1.3H7.4Z"
            transform="translate(7.794 9.443) rotate(-90)"
            :fill="iconColor"
            :stroke="iconColor"
            stroke-width="0.4"
            fill-rule="evenodd"
          />
          <path
            id="패스_29343"
            data-name="패스 29343"
            d="M10.393,0H0V1.3H10.393Z"
            transform="translate(3.897 9.443) rotate(-90)"
            :fill="iconColor"
            :stroke="iconColor"
            stroke-width="0.4"
            fill-rule="evenodd"
          />
          <path
            id="패스_29344"
            data-name="패스 29344"
            d="M5.2,0H0V1.3H5.2Z"
            transform="translate(0 9.443) rotate(-90)"
            :fill="iconColor"
            :stroke="iconColor"
            stroke-width="0.4"
            fill-rule="evenodd"
          />
        </g>
        <g
          id="그룹_40208"
          data-name="그룹 40208"
          transform="translate(14 12.833)"
        >
          <line
            id="선_926"
            data-name="선 926"
            x2="2.383"
            y2="2.67"
            transform="translate(7.534 8.413)"
            fill="none"
            :stroke="iconColor"
            stroke-linejoin="round"
            stroke-width="1.6"
          />
          <g
            id="타원_2608"
            data-name="타원 2608"
            transform="translate(-0.333 -0.167)"
            fill="#081A38"
            :stroke="iconColor"
            stroke-width="1.5"
          >
            <circle cx="5.5" cy="5.5" r="5.5" stroke="none" />
            <circle cx="5.5" cy="5.5" r="4.75" fill="none" />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'calculate',

  props: {
    viewBox: { type: String, default: '0 0 28 28' },
    iconName: { type: String, default: '' },
    width: { type: [Number, String], default: 28 },
    height: { type: [Number, String], default: 28 },
    iconColor: { type: String, default: '#ffffff' },
  },
}
</script>

<style scoped></style>
