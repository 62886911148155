var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "find vuetify-reset" }, [
    _c("div", { staticClass: "find__inner" }, [
      _vm._m(0),
      _vm._m(1),
      _c(
        "div",
        { staticClass: "findPasswordId-input" },
        [
          _vm._m(2),
          _c("v-text-field", {
            attrs: {
              dense: "",
              outlined: "",
              clearable: "",
              placeholder: "6~20자 / 영문 소문자, 숫자 조합",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "find__btn-fixed" },
        [
          _c("v-btn", { staticClass: "btnNavy-48" }, [_vm._v("돌아가기")]),
          _c("v-btn", { staticClass: "prev-button" }, [_vm._v("로그인")]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logo__inner" }, [
      _c("div", { staticClass: "logo" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "find-text" }, [
      _c("h2", [_vm._v("비밀번호 재설정")]),
      _c("p", [_vm._v("아이디를 입력해 주세요.")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", [_vm._v(" 아이디 "), _c("span", [_vm._v("*")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }