var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "get-notice-date d-flex flex-column",
      attrs: { height: "792px", width: "551px" },
    },
    [
      _c(
        "v-card-title",
        {
          staticClass:
            "px-8 pt-6 font-size24 surface800 font-weight700 d-flex justify-space-between",
        },
        [
          _c("span", [_vm._v("불러오기")]),
          _c(
            "v-btn",
            {
              staticClass: "mt-n2 me-n3",
              attrs: { icon: "" },
              on: {
                click: function ($event) {
                  return _vm.cancelBtn()
                },
              },
            },
            [
              _c("v-icon", { attrs: { size: "25" } }, [
                _vm._v(" " + _vm._s(_vm.icons.mdiWindowClose) + " "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-divider", { staticClass: "mx-6" }),
      _c("v-card-text", { staticClass: "px-8 font-size20" }, [
        _vm._v(" 총 " + _vm._s(_vm.temporaryItems.length) + "개 "),
      ]),
      _c("v-divider", { staticClass: "ma-0" }),
      _c(
        "v-list",
        { staticClass: "get-notice-list pt-0 px-8" },
        [
          _c(
            "perfect-scrollbar",
            {
              staticClass: "ps-favorites-notifications",
              attrs: { options: _vm.perfectScrollbarOptions },
            },
            _vm._l(_vm.temporaryItems, function (item, index) {
              return _c(
                "v-list-item",
                { key: index, staticClass: "get-notice-item pa-3" },
                [
                  _c(
                    "v-row",
                    { staticClass: "pa-0 ma-0" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pa-0", attrs: { cols: "1" } },
                        [
                          _c(
                            "v-list-item-action",
                            { staticClass: "mt-0" },
                            [
                              _c("v-checkbox", {
                                staticClass: "item-checkbox ma-0",
                                attrs: { color: "accent" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleCheckboxClick(item)
                                  },
                                },
                                model: {
                                  value: item.checked,
                                  callback: function ($$v) {
                                    _vm.$set(item, "checked", $$v)
                                  },
                                  expression: "item.checked",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "pa-0", attrs: { cols: "11" } },
                        [
                          _c(
                            "v-list-item-content",
                            { staticClass: "pa-0" },
                            [
                              _c("v-list-item-title", {
                                domProps: { textContent: _vm._s(item.title) },
                              }),
                              _c("v-list-item-subtitle", {
                                domProps: { textContent: _vm._s(item.regDt) },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _c("v-divider", { staticClass: "ma-0" }),
      _c(
        "v-card-actions",
        {
          staticClass: "py-8 d-flex justify-center",
          staticStyle: { background: "white" },
        },
        [
          _c(
            "v-btn",
            {
              staticClass: "popup_btn",
              attrs: { outlined: "" },
              on: { click: _vm.loadBtn },
            },
            [_vm._v("불러오기")]
          ),
          _c(
            "v-btn",
            {
              staticClass: "popup_btn",
              staticStyle: { "box-shadow": "none !important" },
              on: {
                click: function ($event) {
                  _vm.isChangeCheck ? _vm.deleteBtn() : _vm.cancelBtn()
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.isChangeCheck ? "삭제" : "취소") + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }