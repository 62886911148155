import axios from 'axios'

class SvcService {
  private apiUrl: string

  constructor() {
    this.apiUrl = '/svc'
  }

  /**
   * 기간별 사용기록통계 조회
   */
  getSvcPeriodList(params) {
    return axios.get(this.apiUrl + `/stat/period`, { params : params }).then((res) => {
      return Promise.resolve(res.data)
    })
  }

  /**
   * 특정기간의 시나리오별 사용기록통계 조회
   */
  getSvcScenarioList(params) {
    return axios.get(this.apiUrl + `/stat/period/scenario`, { params : params }).then((res) => {
      return Promise.resolve(res.data)
    })
  }

  /**
   * 기간별 사용기록통계 엑셀 다운로드
   */
  getSvcExcelDownload(params){
    return axios.get(this.apiUrl + `/excel/period`, { params : params, responseType:"blob" }).then((res) => {
      return Promise.resolve(res.data)
    })
  }
}

export default new SvcService()
