


















































































































































































import { Vue, Component, Watch, Prop, Ref } from 'vue-property-decorator'
import { mdiEyeOffOutline, mdiEyeOutline, mdiWindowClose } from '@mdi/js'
import RegexUtils from '@/utils/regex.utils'
import UserService from '@/services-itgo/user.service'
import AdminService from '@/services-itgo/admin.service'
import _ from 'lodash'

@Component({})
export default class ManagerEditPopup extends Vue {
  @Prop(Object) preUserInfo

  @Ref() form
  @Ref() userLoginId

  public isPasswordVisible = false
  public isRePasswordVisible = false

  mounted() {
    if (this.preUserInfo) {
      console.log(this.preUserInfo)
      this.isUserExist = true
    }
  }

  getListItems() {
    if (this.userInfo.controllerRole === 1) return this.userSuperAdminTypeItems
    else return this.userTypeItems
  }

  get userInfo() {
    return {
      userId: this.preUserInfo?.userId || null,
      name: this.preUserInfo?.name || null,
      loginId: this.preUserInfo?.loginId || null,
      password: null,
      rePassword: null,
      controllerRole: this.userControllerRole(
        this.preUserInfo?.controllerRole || null,
      ),
      reason: '',
    }
  }

  public valid: boolean = false

  get userId() {
    console.log(this.userInfo.loginId)
    return true
  }

  // 유효성 검사
  public rules: any = {
    userNameRules: [(v) => !!v || '사용자명은 필수 값입니다.'],
    userIdRules: [
      (v) => !!v || '6자 이상 20자 이내 영문 소문자, 숫자 조합',
      (v) =>
        RegexUtils.userIdManagerRules(v) ||
        '6자 이상 20자 이내 영문 소문자, 숫자 조합',
      (v) => this.userExtBtn(v),
      (v) => this.isUserExit() || '사용 중인 아이디 입니다.',
    ],
    userPassWordRules: [
      (v) =>
        !!v ||
        '비밀번호 조건을 다시 확인해 주세요. \n 10자 이상 20자 이내 영문 소문자, 숫자, 특수문자 2자 이상 조합',
      (v) =>
        RegexUtils.userPassWordRules(v) ||
        `비밀번호 조건을 다시 확인해 주세요. \n 10자 이상 20자 이내 영문 소문자, 숫자, 특수문자 2자 이상 조합`,
    ],
    userRePassWordRules: [
      // (v) =>
      //   !!v ||
      //   '*비밀번호 조건을 다시 확인해 주세요. - 10자 이상 20자 이내 영문 소문자, 숫자, 특수문자 2자 이상 조합',
      // (v) =>
      //   RegexUtils.userPassWordRules(v) ||
      //   `*비밀번호 조건을 다시 확인해 주세요. - 10자 이상 20자 이내 영문 소문자, 숫자, 특수문자 2자 이상 조합`,
      (v) => this.passwordChecking() || `비밀번호가 일치하지 않습니다.`,
    ],
    userEmailRules: [
      (v) => !!v || '유효한 이메일 형식이 아닙니다.',
      (v) =>
        RegexUtils.isCheckEmailRules(v) || `유효한 이메일 형식이 아닙니다.`,
    ],
    controllerRoleRules: [(v) => !!v || '권한을 입력해주세요'],
    reasonMessage: [(v) => !!v || '사유를 입력하세요'],
  }

  icons: any = {
    mdiWindowClose,
    mdiEyeOutline,
    mdiEyeOffOutline,
  }

  userTypeItems = [
    { name: '선택', value: 0 },
    { name: 'Admin', value: 2 },
    { name: 'Manager', value: 3 },
    { name: 'User', value: 4 },
  ]

  userSuperAdminTypeItems = [{ name: 'SuperAdmin', value: 1 }]

  userControllerRole(roleStr) {
    if (roleStr === 'SUPERADMIN') return 1
    else if (roleStr === 'ADMIN') return 2
    else if (roleStr === 'MANAGER') return 3
    else if (roleStr === 'USER') return 4
    else return null
  }

  public existBtn: boolean = false

  userExtBtn(v) {
    if (RegexUtils.userIdManagerRules(v)) {
      this.existBtn = true
      return true
    } else {
      this.existBtn = false
      return false
    }
  }

  isUserExit() {
    if (this.isUserSearch) return this.isUserExist
    else return true
  }

  preUserInfoData() {
    if (this.preUserInfo) return true
  }

  async cancelBtn() {
    const result = await this.$confirm({
      title: '작성 중인 내용이 있습니다.',
      content: `팝업 종료 시 작성 내용이 사라집니다. <br/> 계속 하시겠습니까?.`,
    })
    if (result) {
      this.$emit('close', false)
    }
  }

  async userAddEditBtn() {
    try {
      console.log(this.userInfo)
      if (this.userInfo.userId) {
        const body = {
          userId: this.userInfo.userId,
          name: this.userInfo.name,
          loginId: this.userInfo.loginId,
          password: this.userInfo.password,
          controllerRole: this.userInfo.controllerRole,
          reason: this.userInfo.reason,
        }
        console.log(body)
        await AdminService.setManagerUserModify(body)
      } else {
        const body = {
          name: this.userInfo.name,
          loginId: this.userInfo.loginId,
          password: this.userInfo.password,
          controllerRole: this.userInfo.controllerRole,
          reason: this.userInfo.reason,
        }
        console.log(body)
        await AdminService.setNewManagerUser(body)
      }

      let contentStr = '새로운 계정이 생성 되었습니다'
      if (this.userInfo.userId) {
        contentStr = '계정을 변경하였습니다'
      }

      await this.$toast({
        type: 'notice',
        content: contentStr,
      })

      this.$emit('close', true)
    } catch (e) {
      await this.$alert({
        title: '계정 생성 실패',
        content: e.response.data.errMsg,
      })
    } finally {
    }
  }

  isUserExist: boolean = false
  isUserSearch: boolean = false

  async userExist() {
    try {
      const result = await UserService.getUserExist(this.userInfo.loginId)
      this.isUserSearch = true
      this.isUserExist = !result

      this.userLoginId.validate()

      this.isUserSearch = false
    } catch (e) {
    } finally {
    }
  }

  changedLoginId() {
    this.isUserExist = false
  }

  isMatchPassword: boolean = false
  checkingPassword() {
    if (!_.isEmpty(this.userInfo.password)) {
      if (_.isEqual(this.userInfo.password, this.userInfo.rePassword)) {
        this.isMatchPassword = true
      } else {
        this.isMatchPassword = false
      }
      const rePasswordField = this.$refs.rePassword as any
      if (rePasswordField && typeof rePasswordField.validate === 'function') {
        rePasswordField.validate()
      }
    } else {
      this.isMatchPassword = false
    }
  }

  get isBtn() {
    // console.log(this.valid)
    // console.log(this.isUserExist)
    console.log(this.isMatchPassword)
    return !(this.valid && this.isUserExist && this.isMatchPassword)
    // if(this.preUserInfo) {
    //   if(this.isUserExist && this.isMatchPassword)
    //     return false
    //   else
    //     return true
    // }else{
    //   return !(this.valid && this.isUserExist && this.isMatchPassword);
    // }
  }

  get userIdDisable() {
    if (this.preUserInfo) return true
    else return false
  }

  passwordChecking() {
    if (this.userInfo.rePassword !== this.userInfo.password) return false
    else return true
  }
}
