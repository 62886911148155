





























































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { mdiChevronLeft } from '@mdi/js'
import FreightInfoItem from '@/views/freightHistory/FreightInfoItem.vue'
import AdminService from '@/services-itgo/admin.service'
import ManagerEditPopup from '@/views/managerMember/ManagerEditPopup.vue'
import ManagerStatusChangedPopup from '@/views/managerMember/ManagerStatusChangedPopup.vue'

@Component({
  components: { ManagerStatusChangedPopup, ManagerEditPopup, FreightInfoItem },

  mounted() {
    if (!this.reviewInfo) {
      const name = 'managerUserList'
      return this.$router.push({ name })
    }
  },
})
export default class ManagerUserInfo extends Vue {
  public icons: any = {
    mdiChevronLeft,
  }

  public isUserAddPopup: boolean = false

  get reviewInfo(): any {
    console.log(this.$route.params.userInfo)
    return this.$route.params.userInfo
  }

  get btnDisable(): any {
    return (
      this.reviewInfo?.controllerRole === 'SUPERADMIN' ||
      !!this.reviewInfo.deleteDt
    )
  }

  get btnChangedDisable(): any {
    return !!this.reviewInfo.deleteDt
  }

  backToList() {
    console.log('backToList')
    this.$router.back()
  }

  get personalInfo() {
    return [
      {
        title: '개인 정보',
        data: [
          {
            title: '이름',
            content: this.reviewInfo?.name || '-',
            show: true,
          },
          {
            title: 'ID',
            content: this.reviewInfo?.loginId || '-',
            show: true,
          },
          {
            title: '권한',
            content: this.reviewInfo?.controllerRole || '-',
            show: true,
          },
          {
            title: '생성일',
            content: this.reviewInfo?.regDt || '-',
            show: true,
          },
          {
            title: '생성사유',
            content: this.reviewInfo?.regReason || '-',
            show: true,
          },
          {
            title: '변경일',
            content: this.reviewInfo?.changeDt || '-',
            show: true,
          },
          {
            title: '변경사유',
            content: this.reviewInfo?.changeReason || '-',
            show: true,
          },
          {
            title: '삭제일',
            content: this.reviewInfo?.deleteDt || '-',
            show: true,
          },
          {
            title: '삭제사유',
            content: this.reviewInfo?.deleteReason || '-',
            show: true,
          },
        ],
        show: true,
      },
    ]
  }

  // async setInactiveManagerUser() {
  //   try {
  //     const result = await this.$confirm({
  //       title: this.reviewInfo.inactiveDt ? `계정 활성화` : `계정 비활성화`,
  //       content: this.reviewInfo.inactiveDt
  //         ? `해당 계정을 활성화 하시겠습니까?`
  //         : `해당 계정을 비활성화 하시겠습니까?`,
  //     })
  //     if (result) {
  //       const body: any = {
  //         userId: this.reviewInfo.userId,
  //         onYn: this.reviewInfo.inactiveDt ? `N` : `Y`,
  //       }
  //       await AdminService.setInactiveManagerUser(body)
  //
  //       await this.$toast({
  //         type: 'notice',
  //         content: this.reviewInfo.inactiveDt
  //           ? `계정이 활성화 되었습니다.`
  //           : `계정이 비활성화 되었습니다.`,
  //       })
  //
  //       await this.$router.back()
  //     }
  //   } catch (e) {
  //     await this.$alert({
  //       title: this.reviewInfo.inactiveDt
  //         ? `계정 활성화 실패`
  //         : `계정 비활성화 실패`,
  //       content: e.response.data.errMsg,
  //     })
  //   } finally {
  //   }
  // }

  // async setDeletedManagerUser() {
  //   try {
  //     const result = await this.$confirm({
  //       title: `계정 삭제`,
  //       content: `해당 계정을 삭제 하시겠습니까?`,
  //     })
  //     if (result) {
  //       await AdminService.setDeletedManagerUser(this.reviewInfo.userId)
  //
  //       await this.$toast({
  //         type: 'error',
  //         content: `계정이 삭제 되었습니다.`,
  //       })
  //
  //       await this.$router.back()
  //     }
  //   } catch (e) {
  //     await this.$alert({
  //       title: `계정 삭제 실패`,
  //       content: e.response.data.errMsg,
  //     })
  //   } finally {
  //   }
  // }

  public editCount: number = 0

  setEditManagerUser() {
    this.isUserAddPopup = true
    this.editCount++
  }

  editPopupAct(value) {
    this.isUserAddPopup = !this.isUserAddPopup
    if (value) this.$router.go(-1)
  }

  openManagerPopup = false
  openPopupType: string = ''
  onOpenManagerPopup(index: number) {
    if (index === 1) {
      if (this.reviewInfo.inactiveDt) this.openPopupType = 'activated'
      else this.openPopupType = 'disActivated'
    } else this.openPopupType = 'deleted'
    this.openManagerPopup = true
  }
  onCloseManagerPopup(value) {
    this.openManagerPopup = false
    console.log(value)
    if (value) {
      this.$router.back()
    }
  }
}
