var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mr-4 ml-4" },
    [
      _c(
        "v-menu",
        {
          attrs: {
            "offset-y": "",
            left: "",
            "nudge-bottom": "22",
            transition: "slide-y-transition",
            "min-width": "328",
            "content-class": "user-profile-menu-content",
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function (ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-avatar",
                    _vm._g(
                      _vm._b(
                        { attrs: { size: "28px" } },
                        "v-avatar",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/icons/person.svg"),
                          height: "100%",
                        },
                      }),
                    ]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "v-list",
            { staticClass: "px-0 py-2" },
            [
              _c(
                "div",
                { staticClass: "px-4 py-2 d-flex" },
                [
                  _c(
                    "v-avatar",
                    { staticClass: "pr-2", attrs: { size: "48px" } },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/icons/person.svg"),
                          height: "100%",
                        },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex flex-column justify-center" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "text--primary font-weight-semibold font-size14",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm._f("maskingName")(_vm.userName)) +
                              "님 "
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "text--disabled font-size13" }, [
                        _vm._v(_vm._s(_vm.email)),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c("v-divider", { staticClass: "my-2" }),
              _c(
                "v-list-item",
                { on: { click: _vm.onClickPasswordPopup } },
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", { staticClass: "font-size14" }, [
                        _vm._v(" 비밀번호 변경 "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider", { staticClass: "my-2" }),
              _c(
                "v-list-item",
                { on: { click: _vm.onClickVersionInfo } },
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", { staticClass: "font-size14" }, [
                        _vm._v(" 버전 정보 확인 "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider", { staticClass: "my-2" }),
              _c(
                "v-list-item",
                { on: { click: _vm.onClickLogout } },
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", { staticClass: "font-size14" }, [
                        _vm._v("로그아웃"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          staticClass: "v-dialog",
          attrs: { "max-width": "408", persistent: "" },
          on: {
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              return _vm.closeVersionInfo.apply(null, arguments)
            },
          },
          model: {
            value: _vm.openVersionInfo,
            callback: function ($$v) {
              _vm.openVersionInfo = $$v
            },
            expression: "openVersionInfo",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                {
                  staticClass: "d-flex align-center",
                  staticStyle: { padding: "12px 12px 12px 24px" },
                },
                [
                  _c("span", [_vm._v("버전 정보")]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "pa-0",
                      attrs: { icon: "" },
                      on: { click: _vm.closeVersionInfo },
                    },
                    [
                      _c("v-icon", { attrs: { size: "20" } }, [
                        _vm._v(" " + _vm._s(_vm.icons.mdiClose) + " "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider", { staticClass: "ma-0" }),
              _c(
                "v-card-text",
                { staticClass: "px-5 py-0" },
                [
                  _c(
                    "v-list",
                    [
                      _c(
                        "v-list-item",
                        { staticClass: "px-2 py-0" },
                        [
                          _c(
                            "v-list-item-avatar",
                            { staticClass: "mr-5" },
                            [
                              _c("v-icon", { attrs: { size: "28px" } }, [
                                _vm._v(" " + _vm._s(_vm.icons.mdiWeb) + " "),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [_vm._v("Web Version")]),
                              _c("v-list-item-subtitle", [
                                _vm._v(" " + _vm._s(_vm.webVersion) + " "),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-divider", { staticClass: "ma-0" }),
                      _c(
                        "v-list-item",
                        { staticClass: "px-2 py-0" },
                        [
                          _c(
                            "v-list-item-avatar",
                            { staticClass: "mr-5" },
                            [
                              _c("v-icon", { attrs: { size: "28px" } }, [
                                _vm._v(" " + _vm._s(_vm.icons.mdiServer) + " "),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v("Backend Version"),
                              ]),
                              _c("v-list-item-subtitle", [
                                _vm._v(" " + _vm._s(_vm.backendVersion) + " "),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          key: _vm.componentKey.passwordPopupKey,
          staticClass: "v-dialog",
          attrs: { "max-width": "551", persistent: "" },
          on: {
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              return _vm.closePasswordPopup.apply(null, arguments)
            },
          },
          model: {
            value: _vm.openPasswordPopup,
            callback: function ($$v) {
              _vm.openPasswordPopup = $$v
            },
            expression: "openPasswordPopup",
          },
        },
        [_c("password-edit-popup", { on: { close: _vm.closePasswordPopup } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }