










import {Component, Vue} from 'vue-property-decorator'
import {State} from "vuex-class";

@Component({
  components: {},
})
export default class AppBarCompany extends Vue {

  @State((state) => state.auth.user) loginUser

  mounted() {
    console.log('AppBarCompany mounted')
  }
}
