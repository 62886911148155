var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-card-title",
        { staticClass: "font-size24 surface800 font-weight900" },
        [
          _c("span", [_vm._v("주소 검색")]),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              staticClass: "mt-n2 me-n3",
              attrs: { icon: "" },
              on: { click: _vm.close },
            },
            [
              _c("v-icon", { attrs: { size: "25" } }, [
                _vm._v(" " + _vm._s(_vm.icons.mdiWindowClose) + " "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-card-text", { staticClass: "mt-n4 pb-0" }, [_c("v-divider")], 1),
      _c(
        "v-card-text",
        [
          _c("v-text-field", {
            attrs: {
              autofocus: "",
              clearable: "",
              placeholder: "예) 판교역로 235, 한남동 174",
              maxlength: "100",
              "append-icon": _vm.icons.mdiMagnify,
            },
            on: {
              keypress: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.onSearch.apply(null, arguments)
              },
            },
            scopedSlots: _vm._u([
              {
                key: "append",
                fn: function () {
                  return [
                    _c("v-icon", { on: { click: _vm.onSearch } }, [
                      _vm._v(" " + _vm._s(_vm.icons.mdiMagnify) + " "),
                    ]),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.searchText,
              callback: function ($$v) {
                _vm.searchText = $$v
              },
              expression: "searchText",
            },
          }),
        ],
        1
      ),
      _c(
        "v-card-text",
        [
          _vm.addressItems.length
            ? _c(
                "v-card",
                { attrs: { outlined: "", color: "transparent" } },
                [
                  _c(
                    "v-list-item-group",
                    { attrs: { color: "primary" } },
                    [
                      _c("v-virtual-scroll", {
                        attrs: {
                          items: _vm.addressItems,
                          "item-height": 72,
                          height: "500",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var item = ref.item
                                return [
                                  _c(
                                    "v-list-item",
                                    {
                                      key: item.subno,
                                      staticStyle: { padding: "0 !important" },
                                      attrs: { dense: "", height: "20px" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onClickAddress(item)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-list-item-content",
                                        {
                                          staticClass: "d-flex",
                                          staticStyle: {
                                            padding: "12px",
                                            border: "1px solid #ededed",
                                            "border-radius": "5px",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex align-center",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "font-size13",
                                                  staticStyle: { width: "20%" },
                                                },
                                                [_vm._v("지번")]
                                              ),
                                              _c("v-list-item-title", {
                                                staticStyle: { width: "80%" },
                                                domProps: {
                                                  textContent: _vm._s(
                                                    item.intersectinglocality
                                                  ),
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex align-center",
                                              staticStyle: {
                                                "margin-top": "6px",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "font-size13",
                                                  staticStyle: { width: "20%" },
                                                },
                                                [_vm._v("도로명")]
                                              ),
                                              _c("v-list-item-subtitle", {
                                                staticStyle: { width: "80%" },
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.addAddressSubno(item)
                                                  ),
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2733064955
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "v-card",
                {
                  attrs: { outlined: "", color: "transparent", height: "500" },
                },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "px-0", style: "color: #2f2f2f" },
                    [_vm._v("tip")]
                  ),
                  _c(
                    "v-card-text",
                    {
                      staticClass: "font-size16 px-0",
                      style: "color: #2f2f2f",
                    },
                    [
                      _vm._v(
                        " 지번 주소나 도로명을 이용해 검색해보세요. 건물번호, 번지를 함께 입력하시면 더욱 정확한 결과가 검색됩니다. "
                      ),
                    ]
                  ),
                  _c(
                    "v-card-text",
                    { staticClass: "px-0", style: "color: #757575" },
                    [_vm._v(" 예) 판교역로 235, 제주 첨단로 242, 한남동 714 ")]
                  ),
                ],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }