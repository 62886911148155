import _ from 'lodash'

/**
 * 차량 정보
 */
export = (() => {
  const _METHOD_TYPE: any = {
    FORK_LIFT: 1, // 지게차
    HOIST: 2, // 호이스트
    CRANE: 3, // 크레인
    HANDWORK1: 4, // 수작업
    HANDWORK2: 5, // 수해줌
    CONVEYOR: 6, // 컨베이어
  }

  const _METHOD_TYPE_STRING: any = {
    FORK_LIFT: '지', // 지게차
    HOIST: '호', // 호이스트
    CRANE: '크', // 크레인
    HANDWORK1: '수', // 수작업
    HANDWORK2: '수해줌', // 수해줌
    CONVEYOR: '컨', // 컨베이어
  }

  const _METHOD_TOTAL_TYPE_STRING: any = {
    FORK_LIFT: '지게차',
    HOIST: '호이스트',
    CRANE: '크레인',
    HANDWORK1: '수작업',
    HANDWORK2: '수해줌',
    CONVEYOR: '컨베이어',
  }

  Object.freeze(_METHOD_TYPE)
  Object.freeze(_METHOD_TYPE_STRING)

  const _METHOD_TYPE_WEB_NAME: Map<number, string> = new Map<number, string>([
    [_METHOD_TYPE.FORK_LIFT, _METHOD_TYPE_STRING.FORK_LIFT],
    [_METHOD_TYPE.HOIST, _METHOD_TYPE_STRING.HOIST],
    [_METHOD_TYPE.CRANE, _METHOD_TYPE_STRING.CRANE],
    [_METHOD_TYPE.HANDWORK1, _METHOD_TYPE_STRING.HANDWORK1],
    [_METHOD_TYPE.HANDWORK2, _METHOD_TYPE_STRING.HANDWORK2],
    [_METHOD_TYPE.CONVEYOR, _METHOD_TYPE_STRING.CONVEYOR],
  ])

  const _METHOD_TYPE_WEB_TOTAL_NAME: Map<number, string> = new Map<
    number,
    string
  >([
    [_METHOD_TYPE.FORK_LIFT, _METHOD_TOTAL_TYPE_STRING.FORK_LIFT],
    [_METHOD_TYPE.HOIST, _METHOD_TOTAL_TYPE_STRING.HOIST],
    [_METHOD_TYPE.CRANE, _METHOD_TOTAL_TYPE_STRING.CRANE],
    [_METHOD_TYPE.HANDWORK1, _METHOD_TOTAL_TYPE_STRING.HANDWORK1],
    [_METHOD_TYPE.HANDWORK2, _METHOD_TOTAL_TYPE_STRING.HANDWORK2],
    [_METHOD_TYPE.CONVEYOR, _METHOD_TOTAL_TYPE_STRING.CONVEYOR],
  ])

  return Object.freeze({
    FORK_LIFT: _METHOD_TYPE.FORK_LIFT,
    HOIST: _METHOD_TYPE.HOIST,
    CRANE: _METHOD_TYPE.CRANE,
    HANDWORK1: _METHOD_TYPE.HANDWORK1,
    HANDWORK2: _METHOD_TYPE.HANDWORK2,
    CONVEYOR: _METHOD_TYPE.CONVEYOR,

    FORK_LIFT_STRING: _METHOD_TYPE_STRING.FORK_LIFT,
    HOIST_STRING: _METHOD_TYPE_STRING.HOIST,
    CRANE_STRING: _METHOD_TYPE_STRING.CRANE,
    HANDWORK1_STRING: _METHOD_TYPE_STRING.HANDWORK1,
    HANDWORK2_STRING: _METHOD_TYPE_STRING.HANDWORK2,
    CONVEYOR_STRING: _METHOD_TYPE_STRING.CONVEYOR,

    METHOD_NAME_WEB: (methodIndex: number): string => {
      const methodName: string | undefined =
        _METHOD_TYPE_WEB_NAME.get(methodIndex)
      return methodName ? methodName : '-'
    },

    METHOD_TOTAL_NAME_WEB: (methodIndex: number): string => {
      const methodName: string | undefined =
        _METHOD_TYPE_WEB_TOTAL_NAME.get(methodIndex)
      return methodName ? methodName : '-'
    },
  })
})()
