import _ from 'lodash'

/**
 * 운행 상태 정의
 ** TOTAL 전체
 ** DISPATCH_WAITING 배차대기
 ** DISPATCH_REQ: 배차요청
 ** DISPATCH_COMPLETED 배차완료
 ** IN_TRANSIT: 운송중
 ** TRANS_COMPLETED 운송완료
 ** CANCEL 화물취소
 ** HIDDEN 비공개
 ** HOLDING: 보류중
 */
export = (() => {
  const _FREIGHT_STATUS: any = {
    TOTAL: 0, // 전체
    DISPATCH_WAITING: 1, // 배차대기
    DISPATCH_REQ: 15, // 배차요청
    DISPATCH_COMPLETED: 3, // 배차완료
    IN_TRANSIT: 10, // 운송중
    TRANS_COMPLETED: 5, // 운송완료
    // CANCEL: 11, // 화물취소
    HIDDEN: 6, // 비공개
    HOLDING: 16, // 보류중

    FAILED: 8, // 의뢰실패 (이거 없어도 되지 않나?)
    CANCELLED_BY_CALLER: 7, // 화주에 의한 의뢰취소 >>> 현재 사용중인 취소 status
    CANCELLED_BY_BROKER: 11, // 주선사에 의한 의뢰취소 >>> 현재 사용중인 취소 status
    CANCELLED_BY_CONTROLLER_FOR_CALLER: 12, // 화주의 요청으로 관제에 의한 의뢰취소
    CANCELLED_BY_CONTROLLER_FOR_BROKER: 13, // 주선사의 요청으로 관제에 의한 의뢰취소 (TBD)
    DELETED_BY_CONTROLLER: 22, // 관제에 의한 의뢰삭제 >>> 현재 사용중인 취소 status
    CANCELLED_BY_PICKUP_SCHEDULE: 9, // 상차요청시각 되었으나 수주 건이 없어 자동취소
  }

  const _FREIGHT_STATUS_STRING: any = {
    TOTAL: '전체', // 전체
    DISPATCH_WAITING: '배차대기', // 배차대기
    DISPATCH_REQ: '배차요청', // 배차요청
    DISPATCH_COMPLETED: '배차완료', // 배차완료
    IN_TRANSIT: '운송중', // 운송중
    TRANS_COMPLETED: '운송완료', // 운송완료
    // CANCEL: '화물취소', // 화물취소
    HIDDEN: '비공개', // 비공개
    HOLDING: '보류중', // 보류중

    FAILED: '화물취소', // 의뢰실패 (이거 없어도 되지 않나?)
    CANCELLED_BY_CALLER: '화물취소', // 화주에 의한 의뢰취소 >>> 현재 사용중인 취소 status
    CANCELLED_BY_BROKER: '화물취소', // 주선사에 의한 의뢰취소 >>> 현재 사용중인 취소 status
    CANCELLED_BY_CONTROLLER_FOR_CALLER: '화물취소', // 화주의 요청으로 관제에 의한 의뢰취소
    CANCELLED_BY_CONTROLLER_FOR_BROKER: '화물취소', // 주선사의 요청으로 관제에 의한 의뢰취소 (TBD)
    DELETED_BY_CONTROLLER: '화물취소', // 관제에 의한 의뢰삭제 >>> 현재 사용중인 취소 status
    CANCELLED_BY_PICKUP_SCHEDULE: '화물취소', // 상차요청시각 되었으나 수주 건이 없어 자동취소
  }

  Object.freeze(_FREIGHT_STATUS)
  Object.freeze(_FREIGHT_STATUS_STRING)

  const _FREIGHT_STATUS_WEB_NAME: Map<number, string> = new Map<number, string>(
    [
      [_FREIGHT_STATUS.TOTAL, '전체'],
      [_FREIGHT_STATUS.DISPATCH_WAITING, '배차대기'],
      [_FREIGHT_STATUS.DISPATCH_REQ, '배차요청'],
      [_FREIGHT_STATUS.DISPATCH_COMPLETED, '배차완료'],
      [_FREIGHT_STATUS.IN_TRANSIT, '운송중'],
      [_FREIGHT_STATUS.TRANS_COMPLETED, '운송완료'],
      // [_FREIGHT_STATUS.CANCEL, '화물취소'],
      [_FREIGHT_STATUS.HIDDEN, '비공개'],
      [_FREIGHT_STATUS.HOLDING, '보류중'],

      [_FREIGHT_STATUS.FAILED, '화물취소'],
      [_FREIGHT_STATUS.CANCELLED_BY_CALLER, '화물취소'],
      [_FREIGHT_STATUS.CANCELLED_BY_BROKER, '화물취소'],
      [_FREIGHT_STATUS.CANCELLED_BY_CONTROLLER_FOR_CALLER, '화물취소'],
      [_FREIGHT_STATUS.CANCELLED_BY_CONTROLLER_FOR_BROKER, '화물취소'],
      [_FREIGHT_STATUS.DELETED_BY_CONTROLLER, '화물취소'],
      [_FREIGHT_STATUS.CANCELLED_BY_PICKUP_SCHEDULE, '화물취소'],
    ],
  )

  const _FREIGHT_STATUS_BG_COLOR_NAME: Map<number, string> = new Map<
    number,
    string
  >([
    [_FREIGHT_STATUS.TOTAL, '#004bba'],
    [_FREIGHT_STATUS.DISPATCH_WAITING, '#F4F2F7'],
    [_FREIGHT_STATUS.DISPATCH_REQ, '#F4F2F7'],
    [_FREIGHT_STATUS.DISPATCH_COMPLETED, '#F4F2F7'],
    [_FREIGHT_STATUS.IN_TRANSIT, '#E8F1FF'],
    [_FREIGHT_STATUS.TRANS_COMPLETED, '#FBE7F3'],
    // [_FREIGHT_STATUS.CANCEL, '#2F2F2F'],
    [_FREIGHT_STATUS.HIDDEN, '#2F2F2F'],
    [_FREIGHT_STATUS.HOLDING, '#2F2F2F'],

    [_FREIGHT_STATUS.FAILED, '#2F2F2F'],
    [_FREIGHT_STATUS.CANCELLED_BY_CALLER, '#2F2F2F'],
    [_FREIGHT_STATUS.CANCELLED_BY_BROKER, '#2F2F2F'],
    [_FREIGHT_STATUS.CANCELLED_BY_CONTROLLER_FOR_CALLER, '#2F2F2F'],
    [_FREIGHT_STATUS.CANCELLED_BY_CONTROLLER_FOR_BROKER, '#2F2F2F'],
    [_FREIGHT_STATUS.DELETED_BY_CONTROLLER, '#2F2F2F'],
    [_FREIGHT_STATUS.CANCELLED_BY_PICKUP_SCHEDULE, '#2F2F2F'],
  ])

  const _FREIGHT_STATUS_NAME_COLOR_NAME: Map<number, string> = new Map<
    number,
    string
  >([
    [_FREIGHT_STATUS.TOTAL, '#FFFFFF'],
    [_FREIGHT_STATUS.DISPATCH_WAITING, '#6647BE'],
    [_FREIGHT_STATUS.DISPATCH_REQ, '#6647BE'],
    [_FREIGHT_STATUS.DISPATCH_COMPLETED, '#6647BE'],
    [_FREIGHT_STATUS.IN_TRANSIT, '#196CE7'],
    [_FREIGHT_STATUS.TRANS_COMPLETED, '#E5318E'],
    // [_FREIGHT_STATUS.CANCEL, '#2F2F2F'],
    [_FREIGHT_STATUS.HIDDEN, '#2F2F2F'],
    [_FREIGHT_STATUS.HOLDING, '#2F2F2F'],

    [_FREIGHT_STATUS.FAILED, '#2F2F2F'],
    [_FREIGHT_STATUS.CANCELLED_BY_CALLER, '#2F2F2F'],
    [_FREIGHT_STATUS.CANCELLED_BY_BROKER, '#2F2F2F'],
    [_FREIGHT_STATUS.CANCELLED_BY_CONTROLLER_FOR_CALLER, '#2F2F2F'],
    [_FREIGHT_STATUS.CANCELLED_BY_CONTROLLER_FOR_BROKER, '#2F2F2F'],
    [_FREIGHT_STATUS.DELETED_BY_CONTROLLER, '#2F2F2F'],
    [_FREIGHT_STATUS.CANCELLED_BY_PICKUP_SCHEDULE, '#2F2F2F'],
  ])

  return Object.freeze({
    TOTAL_STATUS: _FREIGHT_STATUS,
    TOTAL: _FREIGHT_STATUS.TOTAL,
    DISPATCH_WAITING: _FREIGHT_STATUS.DISPATCH_WAITING,
    DISPATCH_REQ: _FREIGHT_STATUS.DISPATCH_REQ,
    DISPATCH_COMPLETED: _FREIGHT_STATUS.DISPATCH_COMPLETED,
    IN_TRANSIT: _FREIGHT_STATUS.IN_TRANSIT,
    TRANS_COMPLETED: _FREIGHT_STATUS.TRANS_COMPLETED,
    // CANCEL: _FREIGHT_STATUS.CANCEL,
    HIDDEN: _FREIGHT_STATUS.HIDDEN,
    HOLDING: _FREIGHT_STATUS.HOLDING,

    FAILED: _FREIGHT_STATUS.FAILED,
    CANCELLED_BY_CALLER: _FREIGHT_STATUS.CANCELLED_BY_CALLER,
    CANCELLED_BY_BROKER: _FREIGHT_STATUS.CANCELLED_BY_BROKER,
    CANCELLED_BY_CONTROLLER_FOR_CALLER:
      _FREIGHT_STATUS.CANCELLED_BY_CONTROLLER_FOR_CALLER,
    CANCELLED_BY_CONTROLLER_FOR_BROKER:
      _FREIGHT_STATUS.CANCELLED_BY_CONTROLLER_FOR_BROKER,
    DELETED_BY_CONTROLLER: _FREIGHT_STATUS.DELETED_BY_CONTROLLER,
    CANCELLED_BY_PICKUP_SCHEDULE: _FREIGHT_STATUS.CANCELLED_BY_PICKUP_SCHEDULE,

    TOTAL_STRING: _FREIGHT_STATUS_STRING.TOTAL,
    DISPATCH_WAITING_STRING: _FREIGHT_STATUS_STRING.DISPATCH_WAITING,
    DISPATCH_REQ_STRING: _FREIGHT_STATUS_STRING.DISPATCH_REQ,
    DISPATCH_COMPLETED_STRING: _FREIGHT_STATUS_STRING.DISPATCH_COMPLETED,
    IN_TRANSIT_STRING: _FREIGHT_STATUS_STRING.IN_TRANSIT,
    TRANS_COMPLETED_STRING: _FREIGHT_STATUS_STRING.TRANS_COMPLETED,
    // CANCEL_STRING: _FREIGHT_STATUS_STRING.CANCEL,
    HIDDEN_STRING: _FREIGHT_STATUS_STRING.HIDDEN,
    HOLDING_STRING: _FREIGHT_STATUS_STRING.HOLDING,

    FAILED_STRING: _FREIGHT_STATUS_STRING.FAILED,
    CANCELLED_BY_CALLER_STRING: _FREIGHT_STATUS_STRING.CANCELLED_BY_CALLER,
    CANCELLED_BY_BROKER_STRING: _FREIGHT_STATUS_STRING.CANCELLED_BY_BROKER,
    CANCELLED_BY_CONTROLLER_FOR_CALLER_STRING:
      _FREIGHT_STATUS_STRING.CANCELLED_BY_CONTROLLER_FOR_CALLER,
    CANCELLED_BY_CONTROLLER_FOR_BROKER_STRING:
      _FREIGHT_STATUS_STRING.CANCELLED_BY_CONTROLLER_FOR_BROKER,
    DELETED_BY_CONTROLLER_STRING: _FREIGHT_STATUS_STRING.DELETED_BY_CONTROLLER,
    CANCELLED_BY_PICKUP_SCHEDULE_STRING:
      _FREIGHT_STATUS_STRING.CANCELLED_BY_PICKUP_SCHEDULE,

    NAME_WEB: (callStatus: number): string => {
      const callStatusName: string | undefined =
        _FREIGHT_STATUS_WEB_NAME.get(callStatus)
      return callStatusName ? callStatusName : '-'
    },

    NAME_BG_COLOR: (callStatus: number): string => {
      const callStatusName: string | undefined =
        _FREIGHT_STATUS_BG_COLOR_NAME.get(callStatus)
      return callStatusName ? callStatusName : 'error'
    },

    NAME_COLOR: (callStatus: number): string => {
      const callStatusName: string | undefined =
        _FREIGHT_STATUS_NAME_COLOR_NAME.get(callStatus)
      return callStatusName ? callStatusName : 'error'
    },
  })
})()
