var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: _vm.width,
        height: _vm.height,
        viewBox: _vm.viewBox,
      },
    },
    [
      _c(
        "g",
        {
          attrs: {
            id: "그룹_40436",
            "data-name": "그룹 40436",
            transform: "translate(-45 -372)",
          },
        },
        [
          _c("rect", {
            attrs: {
              id: "사각형_31199",
              "data-name": "사각형 31199",
              width: "28",
              height: "28",
              transform: "translate(45 372)",
              fill: "none",
            },
          }),
          _c(
            "g",
            {
              attrs: {
                id: "Layer_2",
                "data-name": "Layer 2",
                transform: "translate(47.333 374.333)",
              },
            },
            [
              _c(
                "g",
                {
                  attrs: { id: "invisible_box", "data-name": "invisible box" },
                },
                [
                  _c("rect", {
                    attrs: {
                      id: "사각형_31198",
                      "data-name": "사각형 31198",
                      width: "25",
                      height: "25",
                      transform: "translate(-0.333 -0.333)",
                      fill: "none",
                    },
                  }),
                ]
              ),
              _c(
                "g",
                {
                  attrs: {
                    id: "icons_Q2",
                    "data-name": "icons Q2",
                    transform: "translate(2.003 1.502)",
                  },
                },
                [
                  _c(
                    "g",
                    { attrs: { id: "그룹_40199", "data-name": "그룹 40199" } },
                    [
                      _c(
                        "g",
                        {
                          attrs: {
                            id: "그룹_40204",
                            "data-name": "그룹 40204",
                            transform: "translate(3.364 3.752)",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              id: "패스_29374",
                              "data-name": "패스 29374",
                              d: "M16.973,15.946A2.973,2.973,0,1,0,14,12.973,2.973,2.973,0,0,0,16.973,15.946Zm0-4.247A1.274,1.274,0,1,1,15.7,12.973,1.232,1.232,0,0,1,16.973,11.7Z",
                              transform: "translate(-11.824 -10)",
                              fill: _vm.iconColor,
                              stroke: _vm.iconColor,
                              "stroke-width": "0.3",
                            },
                          }),
                          _c("path", {
                            attrs: {
                              id: "패스_29375",
                              "data-name": "패스 29375",
                              d: "M10.936,32.147h8.426a.984.984,0,0,0,.936-1.025V27.741l-.421-.307a8.5,8.5,0,0,0-9.456,0L10,27.741v3.382A.984.984,0,0,0,10.936,32.147Zm.936-3.228a6.616,6.616,0,0,1,6.554,0V30.1H11.873Z",
                              transform: "translate(-10 -19.319)",
                              fill: _vm.iconColor,
                              stroke: _vm.iconColor,
                              "stroke-width": "0.3",
                            },
                          }),
                        ]
                      ),
                      _c("path", {
                        attrs: {
                          id: "패스_29376",
                          "data-name": "패스 29376",
                          d: "M20.026,3H5a.985.985,0,0,0-1,.968V22.363a.985.985,0,0,0,1,.968H20.026a.985.985,0,0,0,1-.968V3.968A.985.985,0,0,0,20.026,3Zm-1,18.395H6V4.936H19.024Z",
                          transform: "translate(-4 -3)",
                          fill: _vm.iconColor,
                          stroke: _vm.iconColor,
                          "stroke-width": "0.3",
                        },
                      }),
                      _c("path", {
                        attrs: {
                          id: "패스_29377",
                          "data-name": "패스 29377",
                          d: "M40.859,19H40v5.008h.859a.939.939,0,0,0,.859-1V20A.939.939,0,0,0,40.859,19Z",
                          transform: "translate(-21.971 -11.338)",
                          fill: _vm.iconColor,
                          stroke: _vm.iconColor,
                          "stroke-width": "0.2",
                        },
                      }),
                      _c("path", {
                        attrs: {
                          id: "패스_29378",
                          "data-name": "패스 29378",
                          d: "M40.859,31H40v5.008h.859a.939.939,0,0,0,.859-1V32A.939.939,0,0,0,40.859,31Z",
                          transform: "translate(-21.971 -17.329)",
                          fill: _vm.iconColor,
                          stroke: _vm.iconColor,
                          "stroke-width": "0.2",
                        },
                      }),
                      _c("path", {
                        attrs: {
                          id: "패스_29379",
                          "data-name": "패스 29379",
                          d: "M40.859,7H40v5.008h.859a.939.939,0,0,0,.859-1V8A.939.939,0,0,0,40.859,7Z",
                          transform: "translate(-21.971 -5.348)",
                          fill: _vm.iconColor,
                          stroke: _vm.iconColor,
                          "stroke-width": "0.2",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }