import _ from 'lodash'

/**
 * 차량 정보
 */
export = (() => {
  const _CARGO_CATEGORY: any = {
    GENERAL: 1,
    LIQUID: 2,
    LONG: 3,
    LARGE: 4,
    CLEAN: 5,
    TREATY: 6,
    REFRIGERATED: 7,
    FROZEN: 8,
  }

  const _CARGO_CATEGORY_STRING: any = {
    GENERAL: '일반소화물',
    LIQUID: '액체화물',
    LONG: '장척화물',
    LARGE: '거대화물',
    CLEAN: '청결화물',
    TREATY: '조약화물',
    REFRIGERATED: '냉장화물',
    FROZEN: '냉동화물',
  }

  Object.freeze(_CARGO_CATEGORY)
  Object.freeze(_CARGO_CATEGORY_STRING)

  const _CARGO_CATEGORY_WEB_NAME: Map<number, string> = new Map<number, string>(
    [
      [_CARGO_CATEGORY.GENERAL, _CARGO_CATEGORY_STRING.GENERAL],
      [_CARGO_CATEGORY.LIQUID, _CARGO_CATEGORY_STRING.LIQUID],
      [_CARGO_CATEGORY.LONG, _CARGO_CATEGORY_STRING.LONG],
      [_CARGO_CATEGORY.LARGE, _CARGO_CATEGORY_STRING.LARGE],
      [_CARGO_CATEGORY.CLEAN, _CARGO_CATEGORY_STRING.CLEAN],
      [_CARGO_CATEGORY.TREATY, _CARGO_CATEGORY_STRING.TREATY],
      [_CARGO_CATEGORY.REFRIGERATED, _CARGO_CATEGORY_STRING.REFRIGERATED],
      [_CARGO_CATEGORY.FROZEN, _CARGO_CATEGORY_STRING.FROZEN],
    ],
  )

  return Object.freeze({
    GENERAL: _CARGO_CATEGORY.GENERAL,
    LIQUID: _CARGO_CATEGORY.LIQUID,
    LONG: _CARGO_CATEGORY.LONG,
    LARGE: _CARGO_CATEGORY.LARGE,
    CLEAN: _CARGO_CATEGORY.CLEAN,
    TREATY: _CARGO_CATEGORY.TREATY,
    REFRIGERATED: _CARGO_CATEGORY.REFRIGERATED,
    FROZEN: _CARGO_CATEGORY.FROZEN,

    GENERAL_STRING: _CARGO_CATEGORY_STRING.GENERAL,
    LIQUID_STRING: _CARGO_CATEGORY_STRING.LIQUID,
    LONG_STRING: _CARGO_CATEGORY_STRING.LONG,
    LARGE_STRING: _CARGO_CATEGORY_STRING.LARGE,
    CLEAN_STRING: _CARGO_CATEGORY_STRING.CLEAN,
    TREATY_STRING: _CARGO_CATEGORY_STRING.TREATY,
    REFRIGERATED_STRING: _CARGO_CATEGORY_STRING.REFRIGERATED,
    FROZEN_STRING: _CARGO_CATEGORY_STRING.FROZEN,

    CARGO_CATEGORY_NAME_WEB: (index: number): string => {
      const cargoCategoryName: string | undefined =
        _CARGO_CATEGORY_WEB_NAME.get(index)

      return cargoCategoryName ? cargoCategoryName : '-'
    },
  })
})()
