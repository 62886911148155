















import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import {} from '@mdi/js'

@Component({
  name: 'FreightInfoItem',
  components: {},
})
export default class FreightInfoItem extends Vue {
  @Prop(String) title
  @Prop(String) content
}
