import axios, { AxiosRequestConfig } from 'axios'

class DriverService {
  public apiUrl: string

  constructor() {
    this.apiUrl = '/driver'
  }

  /**
   * 차주 목록 조회
   */
  getDriverList(params) {
    return axios
      .post(this.apiUrl + '/page', {}, { params })
      .then((res) => res.data)
  }

  /**
   * 차주 정보 조회
   */
  getDriverUserInfo(id) {
    const body: any = {
      userId: id,
    }
    return axios.post(this.apiUrl + `/user-info`, body).then((res) => res.data)
  }

  getDriverInfoScore(body) {
    return axios
      .post(this.apiUrl + `/driver-info`, body)
      .then((res) => res.data)
  }

  /**
   * 추천 주선사 변경
   */
  modifyRecommendBroker(params) {
    return axios
      .put(this.apiUrl + `/modify-recommend`, params)
      .then((res) => res.data)
  }

  /**
   * 7.1.5 주선사별 차주 가입 순위 API
   */
  getDriverMemberOrder(params) {
    return axios
      .get(this.apiUrl + `/member-order`, { params: params })
      .then((res) => res.data)
  }

  /**
   * 7.1.4 차주 가입 / 탈퇴 현황 API
   */
  getDriverMemberStatus() {
    return axios.get(this.apiUrl + `/member-status`).then((res) => res.data)
  }

  /**
   * 준회원 서류 등록
   */
  associateImgUpload(body) {
    return axios.post(this.apiUrl + `/img-upload`, body).then((res) => res.data)
  }

  /**
   * 차주의 위치정보 조회
   */
  getDriverLocation(driverId) {
    const params = {
      driverId,
    }
    return axios
      .get(this.apiUrl + `/location`, { params: params })
      .then((res) => res.data)
  }
}

export default new DriverService()
