var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      staticClass: "v-dialog wrapper",
      attrs: { persistent: "", "max-width": "551px" },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c(
        "v-card",
        { staticStyle: { width: "550px", height: "710px" } },
        [
          _c(
            "v-card-title",
            { staticClass: "font-size24 surface800 font-weight900" },
            [
              _vm._v(" 회원 상태 처리 "),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "mt-n2 me-n3",
                  attrs: { icon: "" },
                  on: { click: _vm.onClickClose },
                },
                [
                  _c("v-icon", { attrs: { size: "25" } }, [
                    _vm._v(" " + _vm._s(_vm.icons.mdiWindowClose) + " "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "px-8 py-0" },
            [
              _c("v-divider"),
              _c("div", { staticClass: "mb-4" }, [
                _c("span", { staticClass: "font-size18 surface800" }, [
                  _vm._v("상태 선택"),
                ]),
              ]),
              _c("v-select", {
                staticStyle: { width: "100%" },
                attrs: {
                  items: _vm.typeItems,
                  label: "회원 상태 처리",
                  "item-text": "text",
                  "item-value": "value",
                  dense: "",
                  outlined: "",
                  "hide-details": "",
                  "menu-props": { bottom: true, offsetY: true },
                },
                model: {
                  value: _vm.status,
                  callback: function ($$v) {
                    _vm.status = $$v
                  },
                  expression: "status",
                },
              }),
            ],
            1
          ),
          _c(
            "v-card-text",
            {
              staticClass: "px-8 py-0",
              staticStyle: {
                "padding-top": "20px",
                width: "100%",
                height: "250px",
              },
            },
            [
              _vm.status
                ? _c(
                    "div",
                    [
                      _c(
                        "v-radio-group",
                        {
                          model: {
                            value: _vm.radioReasonGroup,
                            callback: function ($$v) {
                              _vm.radioReasonGroup = $$v
                            },
                            expression: "radioReasonGroup",
                          },
                        },
                        _vm._l(_vm.reasonList, function (reason) {
                          return _c("v-radio", {
                            key: reason.value,
                            attrs: {
                              label: reason.text,
                              value: reason.value,
                              color: "#E5318E",
                            },
                          })
                        }),
                        1
                      ),
                      _c("v-text-field", {
                        attrs: {
                          width: "100%",
                          dense: "",
                          outlined: "",
                          clearable: "",
                          placeholder: "사유를 입력해 주세요",
                          disabled: _vm.radioReasonGroup !== 4,
                          maxlength: "25",
                          counter: "25",
                        },
                        model: {
                          value: _vm.reasonText,
                          callback: function ($$v) {
                            _vm.reasonText = $$v
                          },
                          expression: "reasonText",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
          _c("v-card-text", {
            staticStyle: {
              "padding-top": "20px",
              width: "100%",
              height: "160px",
            },
            attrs: { lass: "px-3 py-0" },
          }),
          _c("v-card-text", { staticClass: "pb-0" }, [_c("v-divider")], 1),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-center align-center" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "popup_btn",
                  attrs: {
                    disabled: _vm.radioReasonGroup === 0,
                    color: "primary",
                  },
                  on: { click: _vm.onClickChangeUserStatus },
                },
                [_vm._v(" 확인 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }