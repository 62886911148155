/**
 * user 모듈
 */
export const user = {
  namespaced: true,
  state: {
    userReviewListFilter: {
      keyword: null,
      userType: 0,
      reviewStatus: 0,
      fromDt: null,
      toDt: null,
      sort: null,
      pageNum: 1,
      pageSize: 10,
    },
    userReviewList: [],
    vehicle2ListFilter: {},
    vehicle2List: [],
    userData: {},
  },
  actions: {},

  mutations: {
    setCallerData(state, userData) {
      state.userData = userData
    },
    getUserReviewList(state, userReviewList) {
      state.userReviewList = userReviewList
    },
    setUserListFilter(state, filter) {
      state.userReviewListFilter = filter
    },
    getSubVehicle2List(state, vehicle2List) {
      state.vehicle2List = vehicle2List
    },
    setSubVehicle2ListFilter(state, filter) {
      state.vehicle2ListFilter = filter
    },
    clean(state) {
      state.userData = {}
    },
  },

  getters: {
    getCallerData(state) {
      return state.userData
    },
    getUserListFilter(state) {
      return state.userListFilter
    },
  },
}
