import exp from 'constants'

export const chartColors = {
  primaryColorShade: '#836AF9',
  yellowColor: '#ffe800',
  successColorShade: '#28dac6',
  warningColorShade: '#ffe802',
  warningLightColor: '#FDAC34',
  infoColorShade: '#299AFF',
  greyColor: '#4F5D70',
  blueColor: '#2c9aff',
  blueLightColor: '#84D0FF',
  greyLightColor: '#EDF1F4',
  tooltipShadow: 'rgba(0, 0, 0, 0.25)',
  lineChartPrimary: '#666ee8',
  lineChartDanger: '#ff4961',
  labelColor: '#6e6b7b',
  gridLineColor: 'rgba(200, 200, 200, 0.2)',
  itgoReg : '#E5318E',
  itgoWithdrawal : '#196CE7',
}

export const PERIOD_INTERVAL = {
  Y: 'year',
  M: 'month',
  W: 'week',
  D: 'day',
}

export const LOCATION_AREA = {
  PICKUP_ADMIN_AREA: 'pickupAdminArea',
  PICKUP_SUB_ADMIN_AREA: 'pickupSubAdminArea',
  DROPOFF_ADMIN_AREA: 'dropoffAdminArea',
  DROPOFF_SUB_ADMIN_AREA: 'dropoffSubAdminArea',
}

export const doughnutColors = [
  '#836AF9',
  '#ffe800',
  '#28dac6',
  '#ffe802',
  '#FDAC34',
  '#299AFF',
  '#84D0FF',
  '#ff4961',
  '#f0d888',
  '#f66888',
  '#f99999',
  '#facaaa',
  '#666ee8',
  '#EDF1F4',
  '#ADF1F9',
  '#BDAFF9',
  '#CD1FA9',
]

export const doughnutColors_settlement = ['#E5318E', '#F5F5F5']

export const doughnutColors_local = ['#1E436F', '#F5F5F5']
