









































































































































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import FreightStatusType from '@/views/freightHistory/FreightStatusType.vue'
import Vue2DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ko'
import DatetimeUtils from '@/utils/datetime.utils'
import Pagination from '@/views/utils/Pagination.vue'
import { mdiMagnify, mdiChevronUp, mdiChevronDown } from '@mdi/js'
import CALL_STATUS from '@/views/constant/call.status'
import FREIGHT_STATUS from '@/views/constant/freight.status'
import CALL_REQUEST_STATUS from '@/views/constant/call.request.status'
import MapCard from '@/views/map/MapCard.vue'
import { State, Mutation } from 'vuex-class'
import callRequestService from '@/services-itgo/call.request.service'
import FreightHistoryPopup from '@/views/freightHistory/FreightHistoryPopup.vue'
import _ from 'lodash'
import truck_70x70_cancel from '@/views/icons/webIcon/truck_70x70_cancel.vue'
import truck_70x70_disable from '@/views/icons/webIcon/truck_70x70_disable.vue'
import truck_70x70_completed from '@/views/icons/webIcon/truck_70x70_completed.vue'
import truck_70x70_hidden from '@/views/icons/webIcon/truck_70x70_hidden.vue'
import truck_70x70_holding from '@/views/icons/webIcon/truck_70x70_holding.vue'
import moment from 'moment'

@Component({
  name: 'FreightHistory',
  components: {
    Vue2DatePicker,
    FreightStatusType,
    MapCard,
    Pagination,
    FreightHistoryPopup,
    truck_70x70_cancel,
    truck_70x70_disable,
    truck_70x70_completed,
    truck_70x70_hidden,
    truck_70x70_holding,
  },
  created(){
    this.$router.beforeEach((from, to ,next)=>{
      if(to.name === 'freightHistory'&& from.name === 'freightInfo'){
        this.setIsFreightHistoryFilterStatus(true)
      }
      next()
    })
  },
  async mounted() {
    this.date = this.baseDate

    if (this.isFreightHistory) {
      this.keyword = this.filterData.keyword
      this.status = this.filterData.statusList
      this.date = [this.filterData.fromRequestDt,this.filterData.toRequestDt ]
      this.searchType = this.filterData.searchType
      this.pageNum = this.filterData.pageNum
      this.pageSize = this.filterData.pageSize
      
      this.setIsFreightHistoryFilterStatus(false)
    }
    await this.fetchCallList()
  },
})
export default class FreightHistory extends Vue {
  @State((state) => state.auth.user) loginUser
  @State((state) => state.pageFilter.status.isFreightHistory) isFreightHistory
  @State((state) => state.pageFilter.filter.freightHistoryFilter) filterData
  @Mutation('pageFilter/setIsFreightHistoryFilterStatus') setIsFreightHistoryFilterStatus
  @Mutation('pageFilter/setFreightHistoryFilterData') setFreightHistoryFilterData

  icons: any = {
    mdiMagnify,
    mdiChevronUp,
    mdiChevronDown,
  }

  status = null

  get baseDate() {
    const today = new Date()
    const beforeWeek = new Date(today)
    beforeWeek.setDate(today.getDate() - 7)
    return [beforeWeek, today]
  }

  date: any = [,]

  keyword: string = ''

  callInfo = {
    loading: false,
    headers: [
      { text: '', align: 'center', value: 'badge', show: true },
      {
        text: '화물 상태',
        align: 'center',
        value: 'callRequestStatus',
        show: true,
        width: '7%',
      },
      {
        text: '화물 번호',
        align: 'center',
        value: 'callRequestCode',
        show: true,
        width: '15%',
      },
      {
        text: '등록 일자',
        align: 'center',
        value: 'requestDt',
        show: true,
        width: '12%',
      },
      {
        text: '주선사명',
        align: 'center',
        value: 'brokerCompanyName',
        show: true,
        width: '7%',
      },
      {
        text: '상차지',
        align: 'center',
        value: 'pickUp',
        show: true,
        width: '12%',
      },
      {
        text: '하차지',
        align: 'center',
        value: 'dropOff',
        show: true,
        width: '12%',
      },
      {
        text: '차주명',
        align: 'center',
        value: 'driverName',
        show: true,
        width: '7%',
      },
      {
        text: '운송료 (VAT별도)',
        align: 'center',
        value: 'finalPaymentAmount',
        show: true,
        width: '12%',
      },
      {
        text: '이력',
        align: 'center',
        value: 'detail',
        show: false,
        width: '5%',
      },
      {
        text: '위치 확인',
        align: 'center',
        value: 'point',
        show: false,
        width: '7%',
      },
    ],
    items: [],
    serverItemsLength: 10,
  }

  searchType = 0

  searchTypeItems: any = [
    { text: '선택', value: 0 },
    { text: '주선사명', value: 1 },
    { text: '차주명', value: 2 },
    { text: '화물번호', value: 3 },
  ]

  isSearched = false

  get totalPageCount() {
    const length = Math.ceil(this.callInfo.serverItemsLength / this.pageSize)
    return length === 0 ? 1 : length
  }

  /**
   * Pagiation 관련
   */
  pageSizes = [10, 20, 30]
  pageSize = 10
  pageNum = 1

  @Watch('pageSize')
  onChangePageSize() {
    this.pageNum = 1
    this.fetchCallList()
  }

  @Watch('pageNum')
  onChangePageNum() {
    this.fetchCallList()
  }

  expanded: any = []
  expandItem: any = {}

  displayMapHeight = '450px'

  async fetchCallList() {
    console.log('this.date', this.date[0], this.date[1])

    if (this.date[0] && this.date[1]) {
      if (
        moment
          .duration(moment(this.date[1]).diff(moment(this.date[0])))
          .asDays() > 7
      ) {
        this.date = this.baseDate
        return this.$alert({
          title: '기간 설정 오류',
          content: '기간 설정은 최대 7일까지 가능합니다.',
        })
      }
    }
    try {
      this.callInfo.loading = true
      this.callInfo.items = []
      //this.callInfo.serverItemsLength = 0

      const fromDate = this.date[0]
        ? DatetimeUtils.stringDate(this.date[0], 'YYYY-MM-DD')
        : ''
      const toDate = this.date[1]
        ? DatetimeUtils.datePlusDays(this.date[1], 1)
        : ''
 
      const params = {
        keyword: this.keyword,
        statusList: this.status,
        fromRequestDt: fromDate,
        toRequestDt: toDate,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        searchType: this.searchType,
      }

      this.setFreightHistoryFilterData({...params,fromRequestDt: this.date[0], toRequestDt: this.date[1]})

      const data = await callRequestService.getCallAdminList(params)

      this.callInfo.items = data.content.map((item) => ({
        ...item,
        key: item.callRequestId,
      }))
      this.callInfo.serverItemsLength = data.totalElementCount
    } catch (error: any) {
      this.$alert(error.response.data.errMsg)
    } finally {
      this.callInfo.loading = false
    }
  }

  onSearch() {
    this.isSearched = true
    this.pageNum = 1
    this.fetchCallList()
  }

  showBadge(item) {
    return (
      item.callRequestStatus === CALL_REQUEST_STATUS.ASSIGN_REQUEST ||
      item.callInfos[0].callStatus === CALL_STATUS.INVOICE_REGISTERED ||
      item.callInfos[0].callStatus === CALL_STATUS.INVOICE_REREGISTERED
    )
  }

  getColor(value) {
    return FREIGHT_STATUS.NAME_BG_COLOR(value)
  }

  getTextColor(value) {
    return FREIGHT_STATUS.NAME_COLOR(value)
  }

  getStatus(value) {
    return FREIGHT_STATUS.NAME_WEB(value)
  }

  getAddress(item: any, type: string) {
    const address = item.callDestinations.find((item) => item.type == type)

    return address
      ? address.adminAreaAbbr +
          ' ' +
          address.subAdminAreaAbbr +
          ' ' +
          address.localityAbbr?.slice(0, 2)
      : '-'
  }

  getCallStatus(item) {
    if (!item) return '-'

    // 배차 대기/배차 요청/배차 완료/운송 중 일 경우
    if (
      item.callRequestStatus === CALL_REQUEST_STATUS.ASSIGNABLE ||
      item.callRequestStatus === CALL_REQUEST_STATUS.ASSIGN_REQUEST ||
      item.callRequestStatus === CALL_REQUEST_STATUS.ASSIGNED ||
      item.callRequestStatus === CALL_REQUEST_STATUS.TRANSPORTING
    )
      return '-'

    if (
      item.callInfos[0].callStatus === CALL_STATUS.INVOICE_REGISTERED ||
      item.callInfos[0].callStatus === CALL_STATUS.INVOICE_REREGISTERED
    )
      return '대기'

    if (item.callInfos[0].callStatus === CALL_STATUS.INVOICE_REJECTED)
      return '반려'

    if (item.callInfos[0].callStatus === CALL_STATUS.COMPLETED) return '승인'

    return '-'
  }

  getPaymentStatus(item) {
    //TODO: 재확인 필요
    if (!item) return '-'

    // 배차 대기/배차 요청/배차 완료/운송 중 일 경우
    if (
      item.callRequestStatus === CALL_REQUEST_STATUS.ASSIGNABLE ||
      item.callRequestStatus === CALL_REQUEST_STATUS.ASSIGN_REQUEST ||
      item.callRequestStatus === CALL_REQUEST_STATUS.ASSIGNED ||
      item.callRequestStatus === CALL_REQUEST_STATUS.TRANSPORTING
    )
      return '-'

    if (item.callInfos[0].settleChargeIssueDt) return '완료'
    if (item.callInfos[0].callStatus === CALL_STATUS.COMPLETED) return '대기'
    // else if (item.callInfos[0].settleChargeIssueDt) return '실패'

    return '-'
  }

  callRequestStatus: any = CALL_REQUEST_STATUS

  getTransporting(item) {
    return !(
      item.callRequestStatus === CALL_REQUEST_STATUS.ASSIGNABLE ||
      item.callRequestStatus === CALL_REQUEST_STATUS.ASSIGN_REQUEST ||
      item.callRequestStatus === CALL_REQUEST_STATUS.ASSIGNED ||
      item.callRequestStatus === CALL_REQUEST_STATUS.HIDDEN ||
      item.callRequestStatus === CALL_REQUEST_STATUS.HOLDING ||
      _.includes(
        CALL_REQUEST_STATUS.CANCELLED_STATUS_LIST(),
        item.callRequestStatus,
      )
    )
  }

  getMessage(item) {
    // return item.callRequestStatus === CALL_REQUEST_STATUS.ASSIGNABLE ||
    //   item.callRequestStatus === CALL_REQUEST_STATUS.ASSIGN_REQUEST
    //   ? '배차 대기 중입니다.'
    //   : item.callRequestStatus === CALL_REQUEST_STATUS.CANCELLED_BY_CALLER ||
    //     item.callRequestStatus ===
    //       CALL_REQUEST_STATUS.CANCELLED_BY_PICKUP_SCHEDULE
    //   ? '취소된 화물입니다.'
    //   : item.callRequestStatus === CALL_REQUEST_STATUS.HIDDEN
    //   ? '비공개 처리된 화물입니다.'
    //   : '관리자에 의해서 보류 처리된 화물입니다.'
    if (
      _.includes(
        CALL_REQUEST_STATUS.CANCELLED_STATUS_LIST(),
        item.callRequestStatus,
      )
    )
      return '취소된 화물입니다.'

    let message = ''
    switch (item.callRequestStatus) {
      case CALL_REQUEST_STATUS.ASSIGNABLE:
        message = '배차 대기 중입니다.'
        break
      case CALL_REQUEST_STATUS.ASSIGN_REQUEST:
        message = '배차 요청 중입니다.'
        break
      case CALL_REQUEST_STATUS.ASSIGNED:
        message = '배차 완료 처리가 되었습니다.'
        break
      case CALL_REQUEST_STATUS.HIDDEN:
        message = '비공개 처리된 화물입니다.'
        break
      default:
        message = '관리자에 의해서 보류 처리된 화물입니다.'
    }

    return message
  }

  mapSizePlus() {
    this.displayMapHeight = '850px'
  }

  mapSizeMinus() {
    this.displayMapHeight = '450px'
  }

  public currentAddress: string = ''

  setCurrentAddress(address) {
    this.currentAddress = address
  }

  getExpansionStatus(item) {
    if (!this.expanded.length) return false

    return Boolean(
      this.expanded?.find(
        (_item) => _item.callRequestId === item.callRequestId,
      ),
    )
  }

  onRowClicked(item) {
    if (!this.expanded.length) return this.expanded.push(item)

    this.expanded = this.getExpansionStatus(item) ? [] : [item]
  }

  onClickCallRequestCode(item) {
    this.$router.push({
      name: 'freightInfo',
      params: { id: String(item.callRequestId) },
    })
  }

  getOulinedValue(item) {
    if (
      item.callRequestStatus === FREIGHT_STATUS.FAILED ||
      item.callRequestStatus === FREIGHT_STATUS.CANCELLED_BY_CALLER ||
      item.callRequestStatus === FREIGHT_STATUS.CANCELLED_BY_BROKER ||
      item.callRequestStatus ===
        FREIGHT_STATUS.CANCELLED_BY_CONTROLLER_FOR_CALLER ||
      item.callRequestStatus ===
        FREIGHT_STATUS.CANCELLED_BY_CONTROLLER_FOR_BROKER ||
      item.callRequestStatus === FREIGHT_STATUS.DELETED_BY_CONTROLLER ||
      item.callRequestStatus === FREIGHT_STATUS.CANCELLED_BY_PICKUP_SCHEDULE ||
      item.callRequestStatus === FREIGHT_STATUS.HIDDEN ||
      item.callRequestStatus === FREIGHT_STATUS.HOLDING
    )
      return true
    else return false
  }

  setStatus(status) {
    if (status.length === 1 && status[0] === 0) this.status = null
    else this.status = status
  }

  selectedItem = null

  openFreightHistoryPopup = false

  async onClickFreightHistory(item) {
    this.selectedItem = item
    this.openFreightHistoryPopup = true
  }

  onCloseFreightHistory() {
    this.openFreightHistoryPopup = false
  }

  isSimpleTableView(requestStatus) {
    if (
      _.includes(
        CALL_REQUEST_STATUS.DRIVER_INACTIVE_STATUS_LIST(),
        requestStatus,
      )
    ) {
      return false
    } else {
      return true
    }
  }

  setCallStatusStr(item) {
    const callStatus = item.callInfos[0].callStatus

    return item.orderInfos[0].deliveryRound === 'return'
      ? CALL_STATUS.RETURN_TRANSPORT_STATUS(callStatus)
      : item.callDestinations?.length > 2
      ? CALL_STATUS.TRANSIT_TRANSPORT_STATUS(callStatus)
      : CALL_STATUS.DEFAULT_TRANSPORT_STATUS(callStatus)
  }

  setPickupAddress(item) {
    const pickAddress = _.find(item.callDestinations, {
      type: 'PICKUP',
    })
    return pickAddress.address
  }

  setPickupTime(item) {
    const time = DatetimeUtils.stringDate(item.pickupRequestDt, 'HH:mm')
    return time
  }

  setTableStyle(item) {
    let result = ''
    // Text gray 스타일 : 비공개 / 화물취소 / 보류중
    const isGray =
      item.callRequestStatus === CALL_REQUEST_STATUS.FAILED ||
      item.callRequestStatus === CALL_REQUEST_STATUS.HIDDEN ||
      item.callRequestStatus === CALL_REQUEST_STATUS.HOLDING ||
      _.includes(
        CALL_REQUEST_STATUS.CANCELLED_STATUS_LIST(),
        item.callRequestStatus,
      )

    // bold 스타일 : 배차 요청 / 운송완료 (인수증 승인 요청)
    const isBold =
      item.callRequestStatus === CALL_REQUEST_STATUS.ASSIGN_REQUEST ||
      item.callInfos[0].callStatus === CALL_STATUS.INVOICE_REGISTERED ||
      item.callInfos[0].callStatus === CALL_STATUS.INVOICE_REREGISTERED

    if (isGray && isBold) result = 'txt-gray txt-bold row-style'
    else if (isGray && !isBold) result = 'txt-gray row-style'
    else if (!isGray && isBold) result = 'txt-bold row-style'
    else result = 'row-style'

    return result
  }
}
