var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "dash-board-card-contents1" },
    [
      _c("v-card-title", { staticClass: "align-start" }, [
        _c(
          "span",
          { staticClass: "font-size19 font-weight-semibold surface800" },
          [_vm._v(" 주간 화물 등록·배차 건수 ")]
        ),
      ]),
      _c("v-card-subtitle", { staticClass: "pt-5 pb-2 px-3" }, [
        _c("span", { staticClass: "font-size13 surface800" }, [
          _vm._v(_vm._s(_vm.todayString())),
        ]),
      ]),
      _c("v-card-text", { staticClass: "pb-5 px-3" }, [
        _c("div", { staticClass: "flex-start-contents" }, [
          _c("div", { staticStyle: { width: "75%" } }, [
            _c("span", { staticClass: "text-base" }, [_vm._v("총 ")]),
            _c("span", { staticClass: "font-size26 font-weight-bold" }, [
              _vm._v(_vm._s(_vm.maxCount)),
            ]),
            _c("span", { staticClass: "text-base" }, [_vm._v(" 건")]),
          ]),
          _c(
            "div",
            { staticStyle: { width: "25%" } },
            [
              _c("v-icon", { attrs: { color: "#E5318E" } }, [
                _vm._v(" " + _vm._s(_vm.icons.mdiCircleMedium) + " "),
              ]),
              _c("span", { staticClass: "font-size13 surface800" }, [
                _vm._v("의뢰건수"),
              ]),
              _c(
                "v-icon",
                { staticClass: "pl-2", attrs: { color: "#1E436F" } },
                [_vm._v(" " + _vm._s(_vm.icons.mdiCircleMedium) + " ")]
              ),
              _c("span", { staticClass: "font-size13 surface800" }, [
                _vm._v("배차건수"),
              ]),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "v-card-text",
        [
          _c("chartjs-component-bar-chart", {
            attrs: {
              height: 250,
              "base-labels": _vm.baseLabels,
              "base-data": _vm.baseData,
              "max-value": _vm.maxValue,
              "step-size": _vm.stepSize,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }