import { v1Axios } from '@/utils/axios.utils'

class V1AxiosService {
  private apiUrl: string
  constructor() {
    this.apiUrl = '/admin'
  }

  //TMS : /driver/driver-info
  getDriverInfoWithDriverGrade(userId){
    return v1Axios.get(this.apiUrl +`/driver/${userId}`)
      .then((res) => res.data)
  }
}

export default new V1AxiosService()
