





































































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { mdiArrowRight, mdiMenuUp, mdiMenuDown, mdiCircleMedium } from '@mdi/js'
import ChartjsComponentLineChart from '@/views/chartjs/charts-components/ChartjsComponentLineChart.vue'
import DriverService from '@/services-itgo/driver.service'
import BrokderSerice from '@/services-itgo/broker.service'
import _ from 'lodash'
import { chartColors, doughnutColors } from '@/views/constant/ChartData'
import IcGrup from '@/views/icons/webIcon/IcGrup.vue'

@Component({
  name: 'WeekRegReport',
  components: {
    ChartjsComponentLineChart,
    IcGrup,
  },
})
export default class WeekRegReport extends Vue {
  public icons = {
    mdiArrowRight,
    mdiMenuUp,
    mdiMenuDown,
    mdiCircleMedium,
  }

  public baseLabels: any[] = []
  public baseData: any[] = []
  public maxValue: number = 100
  public maxCount: string = ''
  public monthAccumulation: string = ''
  public cumulative: string = ''
  public signUp: string = ''
  public withdrawal: string = ''

  private dataset: any = {
    fill: false,
    tension: 0,
    pointRadius: 3, // 먄나는 지점의 포인트 크기
    label: '',
    pointHoverRadius: 4,
    pointStyle: 'circle', // 만나는 지점의 형태
    pointHoverBorderWidth: 2,
    pointBorderColor: 'transparent',
    borderColor: doughnutColors[0],
    backgroundColor: doughnutColors[0],
    data: [0, 0, 0, 0, 0, 0],
  }

  mounted() {
    this.getWeekRegReportData()
    this.totalMember()
  }

  async getWeekRegReportData() {
    const result = await DriverService.getDriverMemberStatus()

    console.log(result)
    this.baseData = []

    const dateset1: any = _.cloneDeep(this.dataset)
    dateset1.label = '가입'
    dateset1.data = result.status.map(function (content) {
      return content.confirmCount
    })
    dateset1.pointBorderColor = chartColors.itgoReg
    dateset1.borderColor = chartColors.itgoReg
    dateset1.backgroundColor = chartColors.itgoReg
    this.baseData.push(dateset1)

    const dateset2: any = _.cloneDeep(this.dataset)
    dateset2.label = '탈퇴'
    dateset2.data = result.status.map(function (content) {
      return content.withdrawalCount
    })
    dateset2.pointBorderColor = chartColors.itgoWithdrawal
    dateset2.borderColor = chartColors.itgoWithdrawal
    dateset2.backgroundColor = chartColors.itgoWithdrawal
    this.baseData.push(dateset2)

    _.forEach(result.status, (item) => {
      let dataSet: string = ''
      dataSet = item.month + '월'
      this.baseLabels.push(dataSet)
    })
  }

  async totalMember() {
    try {
      const result = await DriverService.getDriverMemberStatus()
      const lastStatus = result.status[result.status.length - 1]

      console.log(result)

      this.monthAccumulation = lastStatus.month
      this.signUp = lastStatus.confirmCount
      this.withdrawal = lastStatus.withdrawalCount
      this.cumulative = this.withdrawal + this.signUp
      this.getMaxValue(result.status)
    } catch (err) {
      console.error(err)
    } finally {
    }
  }

  getMaxValue(data) {
    const maxConfirmCount = data.reduce((max, item) => {
      return Math.max(max, item.confirmCount)
    }, 0)

    const maxWithdrawalCount = data.reduce((max, item) => {
      return Math.max(max, item.withdrawalCount)
    }, 0)

    if (maxConfirmCount > maxWithdrawalCount) {
      this.setMaxValue(maxConfirmCount)
    } else {
      this.setMaxValue(maxWithdrawalCount)
    }
  }
  setMaxValue(maxValue) {
    const power = Math.pow(10, Math.floor(Math.log10(maxValue)))
    const result = Math.floor(maxValue / power) * power

    this.maxValue = result * 2
    console.log(this.maxValue)
  }
}
