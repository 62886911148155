

















































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import Pagination from '@/views/utils/Pagination.vue'
import { mdiMagnify, mdiChevronDown, mdiChevronUp } from '@mdi/js'
import { State, Mutation } from 'vuex-class'
import brokerService from '@/services-itgo/broker.service'
import { maskingName } from '@/utils/business.util'
import USER_STATUS from '@/views/constant/user.status'

@Component({
  components: {
    Pagination,
  },
  created(){
    this.$router.beforeEach((from, to ,next)=>{
      if(from.name === 'brokerInfo' && to.name === 'brokerManagement'){
        this.setIsManageFilterStatus({type: 'brokerMng', data: true})
      }
      next()
    })
  },
  mounted() {
    if (this.isBrokerMng) {
      this.keyword = this.filterData.keyword
      this.pageNum = this.filterData.pageNum
      this.pageSize = this.filterData.pageSize
      this.setIsManageFilterStatus({type: 'brokerMng', data: false})
    }

    this.fetchBrokerCompanyList()
  },
})
export default class BrokerManagement extends Vue {
  @State((state) => state.auth.user) loginUser
  @State((state) => state.pageFilter.status.isBrokerMng) isBrokerMng
  @State((state) => state.pageFilter.filter.brokerMngFilter) filterData
  @Mutation('pageFilter/setIsManageFilterStatus') setIsManageFilterStatus
  @Mutation('pageFilter/setManageFilterData') setManageFilterData

  icons: any = {
    mdiMagnify,
    mdiChevronDown,
    mdiChevronUp,
  }

  keyword = ''

  brokerCompanyList: any = {
    loading: false,
    headers: [
      { text: '주선사명', value: 'companyName', align: 'center', width: '15%' },
      {
        text: '사업자 등록 번호',
        value: 'businessRegNum',
        align: 'center',
        width: '15%',
      },
      {
        text: '대표자명',
        value: 'representative',
        align: 'center',
        width: '15%',
      },
      {
        text: '주소',
        value: 'address',
        align: 'start',
        width: '30%',
      },
      { text: '상태', value: 'usetStatus', align: 'center', width: '20%' },
      { text: '', value: 'companyId', align: 'center', width: '5%' },
    ],
    items: [],
    serverItemsLength: 0,
  }

  brokerHeaders = [
    { text: '로그인 ID', align: 'center', value: 'loginId', width: '10%' },
    {
      text: '회원명',
      align: 'center',
      value: 'name',
      width: '10%',
    },
    {
      text: '전화번호',
      align: 'center',
      value: 'mobile',
      width: '10%',
    },
    { text: '총 화물', align: 'center', value: 'total', width: '10%' },
    {
      text: '배차 진행',
      align: 'center',
      value: 'assignable',
      width: '10%',
    },
    {
      text: '배차 완료',
      align: 'center',
      value: 'assigned',
      width: '10%',
    },
    {
      text: '운송중',
      align: 'center',
      value: 'transporting',
      width: '10%',
    },
    {
      text: '운송 완료',
      align: 'center',
      value: 'complete',
      width: '10%',
    },
    {
      text: '평점',
      align: 'center',
      value: 'grade',
      width: '10%',
    },
  ]
  brokerItems: any = {}

  get totalPageCount() {
    const length = Math.ceil(
      this.brokerCompanyList.serverItemsLength / this.pageSize,
    )
    return length === 0 ? 1 : length
  }

  pageSizes = [10, 20, 30]
  pageSize = 10
  pageNum = 1

  /**
   * pageSize: 페이지 크기
   * pageNum: 페이지 번호
   */
  @Watch('pageSize')
  onChangePageSize() {
    this.pageNum = 1
    this.fetchBrokerCompanyList()
  }

  @Watch('pageNum')
  onChangePageNum() {
    this.fetchBrokerCompanyList()
  }

  async fetchBrokerCompanyList() {
    try {
      this.brokerCompanyList.loading = true
      this.brokerCompanyList.items = []
      //this.brokerCompanyList.serverItemsLength = 0

      const params = {
        keyword: this.keyword,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      }

      this.setManageFilterData({ type :'brokerMng', data: params })

      const data = await brokerService.getBrokerCompanyList(params)

      this.brokerCompanyList.items = await Promise.all(
        data.companyList.map(async (item, index) => {
          const brokerList = await this.fetchBrokerList(item.companyId)
          return {
            ...item,
            key: `${item.companyId}_${index}`,
            usetStatus: USER_STATUS.GET_USER_STATUS(item.status),
            brokerList: brokerList.map((_item) => ({
              ..._item,
              companyName: item.companyName,
              companyId: item.companyId,
            })),
          }
        }),
      )
      this.brokerCompanyList.serverItemsLength = data.totalCount
    } catch (error: any) {
      this.$alert(error.response.data.errMsg)
    } finally {
      this.brokerCompanyList.loading = false
    }
  }

  async fetchBrokerList(companyId) {
    if (!companyId) return []

    return brokerService.getBrokerList({ companyId })
  }

  onSearch() {
    this.pageNum = 1
    this.fetchBrokerCompanyList()
  }

  onKeyupKeyword() {
    if (this.validateName(this.keyword)) return

    this.keyword = this.keyword
      .replace(/[^ㄱ-ㅎ가-힣0-9]/g, '')
      .replace(/\s/g, '')
  }

  validateName(value: string) {
    const regExp = /^[ㄱ-ㅎ|가-힣|0-9]+$/

    return regExp.test(value)
  }

  expanded: any = []

  isExpanded(companyId) {
    if (!this.expanded.length) return

    return this.expanded?.find((item) => item.companyId === companyId)
  }

  onRowClicked(item) {
    if (
      this.expanded.length > 0 &&
      item.companyId === this.expanded[0].companyId
    ) {
      this.expanded = []
    } else {
      this.expanded = [item]
    }
  }

  onClickRow(item) {
    this.$router.push({
      name: 'brokerInfo',
      params: { item },
    })
  }
}
