import axios from 'axios'

class NoticeService {
  private apiUrl: string
  constructor() {
    this.apiUrl = '/notice'
  }

  // 4. 공지 목록 조회
  getPageList(params) {
    return axios
      .get(this.apiUrl + `/page`, { params: params })
      .then((res) => res.data)
  }

  // 6. 공지 유형 목록 조회
  getCategories(params) {
    return axios
      .get(this.apiUrl + `/categories`, { params: params })
      .then((res) => res.data)
  }

  // 7. 공지 상세 조회
  getNoticeDetails(id) {
    const params = {
      noticeId: id,
    }
    return axios
      .get(this.apiUrl + `/detail`, { params: params })
      .then((res) => res.data)
  }

  // 3. 공지 삭제
  deleteNotice(id) {
    const params: any = {
      noticeIdList: id,
    }
    return axios.delete(this.apiUrl, { params: params }).then((res) => res.data)
  }

  // 8. 공지 PUSH 발송
  postAppPush(params) {
    return axios
      .post(this.apiUrl + `/app-push`, {
        noticeId: params.id,
        appPushTitle: params.title,
        appPushContent: params.content,
      })
      .then((res) => res.status)
  }

  // 9. 공지 SMS 발송
  postSmsPush(params) {
    return axios
      .post(this.apiUrl + `/sms-push`, {
        noticeId: params.id,
        smsPushTitle: params.title,
        smsPushContent: params.content,
      })
      .then((res) => res.status)
  }

  // 1. 공지 등록
  postRegisterNotice(body) {
    return axios.post(this.apiUrl, body).then((res) => res.data)
  }

  // 2. 공지 수정
  putEditNotice(body) {
    return axios.put(this.apiUrl, body).then((res) => res.data)
  }
}

export default new NoticeService()
