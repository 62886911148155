var logi = logi ?? {};
logi['maps'] = logi['maps'] ?? {};
import a19_0x1b39d4 from './base/logi-maps-object.js';
logi['maps']['Object'] = a19_0x1b39d4;
import a19_0x365146 from './base/logi-maps-utils.js';
logi['maps']['Utils'] = a19_0x365146, logi['maps']['Polygon'] = class extends logi['maps']['Object'] {
    #latlngs = [];
    #fillColor;
    #lineWidth;
    #lineColor;
    #screenCoord = {
        'origin': {
            'x': 0x0,
            'y': 0x0
        },
        'points': new Array(),
        'baseLayer': null,
        'level': null
    };
    constructor(_0x22738f, _0x11f29e, _0x1c6b67) {
        const _0x3fe0ab = _0x1c6b67?.['key'] ?? 'pg_' + Math['random']()['toString'](0x24)['slice'](-0x8), _0x26a806 = _0x1c6b67?.['class'] ?? '', _0x5d555a = _0x1c6b67?.['zIndex'] ?? 0x0;
        super(_0x3fe0ab, logi['maps']['Object']['OBJTYPE']['polygon'], _0x26a806, _0x5d555a), this.#latlngs = logi['maps']['Utils']['getRoughLatLngs'](_0x22738f ?? []), this.#fillColor = _0x11f29e ?? '#000000', this.#lineWidth = _0x1c6b67?.['lineWidth'] ?? 0x0, this.#lineColor = _0x1c6b67?.['lineColor'] ?? '#000000', this['setMap'](_0x1c6b67?.['map']);
    }
    ['isHit'](_0x31c9ea) {
        return this.#pointInPolygon(_0x31c9ea);
    }
    ['draw']() {
        if (!this['getLayer']() || !this['getVisible']())
            this.#screenCoord = {
                'origin': {
                    'x': 0x0,
                    'y': 0x0
                },
                'points': new Array(),
                'baseLayer': null,
                'level': null
            };
        else {
            const _0x22a713 = this['getMapCoord'](), _0x53623c = this['getDevicePixelRatio'](), _0x554182 = _0x22a713['getAdditionalScale']();
            if (this.#screenCoord['baseLayer'] != this['getLayer']() || this.#screenCoord['level'] != _0x22a713['getLevel']()) {
                this.#screenCoord['points'] = new Array();
                if (this.#latlngs['length'] >= 0x3) {
                    const _0x1e3895 = _0x22a713['world2screen'](this.#latlngs[0x0]['lng'], this.#latlngs[0x0]['lat'], { 'additionalScale': 0x1 });
                    let _0x2d0bb8 = {
                            'x': null,
                            'y': null
                        }, _0x14a5b7 = {
                            'x': null,
                            'y': null
                        };
                    for (let _0x1c5023 of this.#latlngs) {
                        _0x2d0bb8 = _0x22a713['world2screen'](_0x1c5023['lng'], _0x1c5023['lat'], { 'additionalScale': 0x1 }), _0x2d0bb8['x'] = _0x2d0bb8['x'] - _0x1e3895['x'], _0x2d0bb8['y'] = _0x2d0bb8['y'] - _0x1e3895['y'], (_0x14a5b7['x'] != _0x2d0bb8['x'] || _0x14a5b7['y'] != _0x2d0bb8['y']) && (this.#screenCoord['points']['push'](_0x2d0bb8), _0x14a5b7 = _0x2d0bb8);
                    }
                    if (this.#screenCoord['points']['length'] >= 0x3) {
                        const _0x5ae45f = this.#screenCoord['points'][0x0], _0x503872 = this.#screenCoord['points'][this.#screenCoord['points']['length'] - 0x1];
                        (_0x5ae45f['x'] != _0x503872['x'] || _0x5ae45f['y'] != _0x503872['y']) && this.#screenCoord['points']['push']({
                            'x': _0x5ae45f['x'],
                            'y': _0x5ae45f['y']
                        });
                    }
                }
                this.#screenCoord['baseLayer'] = this['getLayer'](), this.#screenCoord['level'] = _0x22a713['getLevel']();
            }
            if (this.#screenCoord['points']['length'] >= 0x4) {
                this.#screenCoord['origin'] = _0x22a713['world2screen'](this.#latlngs[0x0]['lng'], this.#latlngs[0x0]['lat']);
                const _0xd15e29 = this['getContext']();
                _0xd15e29['save'](), _0xd15e29['scale'](_0x53623c, _0x53623c), _0xd15e29['translate'](this.#screenCoord['origin']['x'], this.#screenCoord['origin']['y']), _0xd15e29['scale'](_0x554182, _0x554182), this.#drawPolygon(_0xd15e29, this.#screenCoord['points'], this.#fillColor, this.#lineWidth, this.#lineColor), _0xd15e29['restore']();
            }
        }
    }
    #drawPolygon = function (_0xae4923, _0x27ca60, _0x4db9dc, _0x3bafad, _0x5edd79) {
        if (_0x27ca60?.['length'] >= 0x4) {
            _0xae4923['beginPath'](), _0xae4923['fillStyle'] = _0x4db9dc, _0xae4923['lineCap'] = 'round', _0xae4923['lineJoin'] = 'round', _0xae4923['moveTo'](_0x27ca60[0x0]['x'], _0x27ca60[0x0]['y']);
            for (let _0x35cdb7 of _0x27ca60) {
                _0xae4923['lineTo'](_0x35cdb7['x'], _0x35cdb7['y']);
            }
            _0xae4923['fill'](), _0x3bafad > 0x0 && (_0xae4923['lineWidth'] = _0x3bafad, _0xae4923['strokeStyle'] = _0x5edd79, _0xae4923['stroke']());
        }
    };
    #pointInPolygon(_0x547391) {
        let _0x15d8cb = ![];
        if (!this['getLayer']() || !this['getVisible']())
            return _0x15d8cb;
        if (this.#screenCoord['points']['length'] >= 0x4) {
            const _0x4dd95b = this['getMapCoord'](), _0x5a0321 = {
                    'x': 0x0,
                    'y': 0x0
                };
            if (_0x4dd95b) {
                const _0x2d5217 = _0x4dd95b['getAdditionalScale']();
                _0x5a0321['x'] = _0x547391['x'] / _0x2d5217 - this.#screenCoord['origin']['x'], _0x5a0321['y'] = _0x547391['y'] / _0x2d5217 - this.#screenCoord['origin']['y'];
                let _0x57be03 = this.#screenCoord['points'][this.#screenCoord['points']['length'] - 0x1];
                for (let _0xd14e85 of this.#screenCoord['points']) {
                    const _0x1b10df = _0xd14e85['y'] > _0x5a0321['y'] != _0x57be03['y'] > _0x5a0321['y'] && _0x5a0321['x'] < (_0x57be03['x'] - _0xd14e85['x']) * (_0x5a0321['y'] - _0xd14e85['y']) / (_0x57be03['y'] - _0xd14e85['y']) + _0xd14e85['x'];
                    _0x1b10df && (_0x15d8cb = !_0x15d8cb), _0x57be03 = _0xd14e85;
                }
            }
        }
        return _0x15d8cb;
    }
};
export default logi['maps']['Polygon'];