




















































































































































































































































































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { mdiChevronLeft } from '@mdi/js'
import USER_STATUS from '@/views/constant/user.status'
import FreightInfoItem from '@/views/freightHistory/FreightInfoItem.vue'
import FreightInfoTwoItem from '@/views/freightHistory/FreightInfoTwoItem.vue'
import DatetimeUtils from '@/utils/datetime.utils'
import moment from 'moment'
import TermsInfoItem from '@/views/management/TermsInfoItem.vue'
import TermsHistoryPopup from '@/views/management/TermsHistoryPopup.vue'
import DriverService from '@/services-itgo/driver.service'
import {
  formatPhoneNumber,
  formatPhoneNumberIntegrated,
} from '@/utils/business.util'
import TermsConditionsHistoryTab from '@/views/management/TermsConditionsHistoryTab.vue'
import _ from 'lodash'

@Component({
  name: 'AssociateMemberInfo',
  components: {
    TermsConditionsHistoryTab,
    TermsHistoryPopup,
    TermsInfoItem,
    FreightInfoItem,
    FreightInfoTwoItem,
  },
  // beforeRouteLeave(to, from, next) {
  //   if (!this.termsWindow) {
  //     this.termsWindow = true
  //     next(false)
  //   } else {
  //     next()
  //   }
  // },
  mounted() {
    if (!this.reviewInfo) {
      const name = 'associateMemberReg'
      return this.$router.push({ name })
    }

    this.fetchUserInfo()
  },
})
export default class AssociateMemberInfo extends Vue {
  icons = {
    mdiChevronLeft,
  }

  get reviewInfo(): any {
    return this.$route.params.reviewInfo
  }

  get id() {
    return this.reviewInfo?.userId
  }

  get name() {
    return this.userInfo?.name || ''
  }

  get type() {
    return USER_STATUS.USER_TYPE_DRIVER
  }

  userInfo: any = null

  get personalInfo() {
    return [
      {
        title: '가입경로',
        content: '일반회원',
        show: true,
      },
      {
        title: '추천인명',
        content: this.userInfo?.recommendName || '-',
        show: true,
      },
      {
        title: '회원명',
        content: this.userInfo?.name,
        show: true,
      },
      {
        title: '추천인코드',
        content: this.userInfo?.recommendCode || '-',
        show: true,
      },
      {
        title: '전화번호',
        content: formatPhoneNumber(this.userInfo?.mobile),
        show: true,
      },
      {
        title: '추천인 운영사',
        content: this.userInfo?.recommendCompany || '-',
        show: true,
      },
      {
        title: '아이디',
        content: this.userInfo?.loginId,
        show: true,
      },
      {
        title: '추천인 전화번호',
        content: formatPhoneNumberIntegrated(this.userInfo?.recommendNumber),
        show: true,
      },
      {
        title: '가입일시',
        content: DatetimeUtils.stringDate(
          this.userInfo?.regDt,
          'YYYY-MM-DD HH:mm',
        ),
        show: true,
      },
    ]
  }

  get requiredInfo() {
    return [
      {
        title: '필수약관',
        data: [
          {
            title: '[필수] 화물잇고 서비스 이용 약관 동의',
            content: this.createdTermsDtDateString(
              this.userInfo?.itgoTermsDt,
              true,
            ),
            show: true,
            btnShow: false,
            termsType: '',
          },
          {
            title: '[필수] 개인 정보 수집 이용 동의',
            content: this.createdTermsDtDateString(
              this.userInfo?.privacy1TermsDt,
              true,
            ),
            show: true,
            btnShow: false,
            termsType: '',
          },
          {
            title: '[필수] 위치기반 서비스 이용 약관 동의',
            content: this.createdTermsDtDateString(
              this.userInfo?.locationTermsDt,
              true,
            ),
            show: true,
            btnShow: false,
            termsType: '',
          },
          {
            title: '[필수] 개인정보 국외이전에 관한 동의',
            content: this.createdTermsDtDateString(
              this.userInfo?.overseasTermsDt,
              true,
            ),
            show: true,
            btnShow: false,
            termsType: '',
          },
        ],
        show: true,
      },
    ]
  }

  get choiceInfo() {
    return [
      {
        title: '선택약관',
        data: [
          {
            title: '[선택] 마케팅 활용을 위한 개인정보 수집 및 이용 동의',
            content: this.checkingDtDate(
              this.userInfo?.choiceTermsDt,
              this.userInfo?.choiceDisagreeDt,
            )
              ? this.createdTermsDtDateString(
                  this.userInfo?.choiceTermsDt,
                  true,
                )
              : this.createdTermsDtDateString(
                  this.userInfo?.choiceDisagreeDt,
                  false,
                ),
            termsType: 'marketing',
            show: true,
            btnShow: true,
          },
          {
            title: '[선택] 광고성 정보 수신 동의',
            content: this.checkingDtDate(
              this.userInfo?.choiceBlurbTermsDt,
              this.userInfo?.choiceBlurbDisagreeDt,
            )
              ? this.createdTermsDtDateString(
                  this.userInfo?.choiceBlurbTermsDt,
                  true,
                )
              : this.createdTermsDtDateString(
                  this.userInfo?.choiceBlurbDisagreeDt,
                  false,
                ),
            termsType: 'blurb',
            show: true,
            btnShow: true,
          },
        ],
        show: true,
      },
    ]
  }

  async fetchUserInfo() {
    try {
      this.userInfo = await DriverService.getDriverUserInfo(this.id)
    } catch (error: any) {}
  }

  async updateUserInfo() {
    await this.fetchUserInfo()
  }

  termsHistoryPopup: boolean = false

  componentKey: any = {
    termsHistoryPopupKey: 0,
  }

  showHistory(value) {
    console.log(value)
    this.showHistoryData(value)
    if (_.size(this.userTermHistoryData)) {
      this.termsHistoryPopup = true
      this.componentKey.termsHistoryPopupKey++
    }
  }

  historyPopupHide() {
    this.termsHistoryPopup = false
  }

  createdTermsDtDateString(dt, isAgrees) {
    if (isAgrees) {
      return `동의 (${DatetimeUtils.setKoreaDate(dt, 'full')})`
    } else {
      return `비동의 (${DatetimeUtils.setKoreaDate(dt, 'full')})`
    }
  }

  checkingDtDate(agreesDt, disagreeDt) {
    if (agreesDt && disagreeDt === null) return true
    else if (disagreeDt && agreesDt === null) return false
    else {
      return moment(agreesDt).isAfter(disagreeDt)
    }
  }

  userTermHistoryData: any = []
  showHistoryData(value) {
    console.log(this.userInfo)
    this.userTermHistoryData = []
    if (value === 'marketing') {
      console.log(value)
      if (this.userInfo.choiceTermsDt) {
        let makeData_1: any = {
          termsStatus: '동의',
          regDt: this.userInfo.choiceTermsDt,
          changedType: USER_STATUS.GET_USER_STRING_TYPE(
            this.userInfo.choiceTermsUserType,
          ),
          changedContent: this.userInfo.choiceTermsReason,
          changedName: this.userInfo.choiceTermsUserId,
        }
        console.log(makeData_1)
        this.userTermHistoryData.push(makeData_1)
      }
      if (this.userInfo.choiceDisagreeDt) {
        let makeData_2: any = {
          termsStatus: '비동의',
          regDt: this.userInfo.choiceDisagreeDt,
          changedType: USER_STATUS.GET_USER_STRING_TYPE(
            this.userInfo.choiceDisagreeUserType,
          ),
          changedContent: this.userInfo.choiceDisagreeReason,
          changedName: this.userInfo.choiceDisagreeUserId,
        }
        console.log(makeData_2)
        this.userTermHistoryData.push(makeData_2)
      }
      console.log(this.userTermHistoryData)
    } else if (value === 'blurb') {
      console.log(value)
      if (this.userInfo.choiceBlurbTermsDt) {
        let makeData_1: any = {
          termsStatus: '동의',
          regDt: this.userInfo.choiceBlurbTermsDt,
          changedType: USER_STATUS.GET_USER_STRING_TYPE(
            this.userInfo.choiceBlurbTermsUserType,
          ),
          changedContent: this.userInfo.choiceBlurbTermsReason,
          changedName: this.userInfo.choiceBlurbTermsUserId,
        }
        console.log(makeData_1)
        this.userTermHistoryData.push(makeData_1)
      }
      if (this.userInfo.choiceBlurbDisagreeDt) {
        let makeData_2: any = {
          termsStatus: '비동의',
          regDt: this.userInfo.choiceBlurbDisagreeDt,
          changedType: USER_STATUS.GET_USER_STRING_TYPE(
            this.userInfo.choiceBlurbDisagreeUserType,
          ),
          changedContent: this.userInfo.choiceBlurbDisagreeReason,
          changedName: this.userInfo.choiceBlurbDisagreeUserId,
        }
        console.log(makeData_2)
        this.userTermHistoryData.push(makeData_2)
      }
      console.log(this.userTermHistoryData)
    }

    if (_.size(this.userTermHistoryData)) {
      this.userTermHistoryData = _.sortBy(this.userTermHistoryData, 'regDt')
    }
  }

  // 심사 서류 등록 스크립트
  public termsWindow = true

  public documentForm: any = {
    docBusiness: null,
    docCargo: null,
    docVehicle: null,
    docLeaseAgreement1: null,
    docLeaseAgreement2: null,
    docLeaseAgreement3: null,
    docLeaseAgreement4: null,
    docLeaseAgreement5: null,
  }

  get isDocsPossible() {
    return (
      this.userInfo?.imgPossible === 1 &&
      this.userInfo?.approvalStatus ===
        USER_STATUS.APPROVAL_STATUS_STRING_APPROVAL
    )
  }

  get isDocsConditionMet() {
    return (
      this.userInfo?.approvalStatus ===
      USER_STATUS.APPROVAL_STATUS_STRING_APPROVAL
    )
  }

  docsClickChange() {
    if (this.termsWindow) {
      this.docAuthority()
    } else {
      this.registerDocs()
    }
  }

  get isRegisterButtonDisabled() {
    if (this.termsWindow) {
      // 심사 서류 등록 버튼 로직
      return !(
        this.userInfo?.imgPossible === 1 &&
        this.userInfo?.approvalStatus ===
          USER_STATUS.APPROVAL_STATUS_STRING_APPROVAL
      )
    } else {
      // 등록 버튼 로직
      const filesRegister =
        this.documentForm.docBusiness &&
        this.documentForm.docCargo &&
        this.documentForm.docVehicle
      return !filesRegister
    }
  }

  // 심사 서류 페이지에 따른 뒤로 가기
  // togglePrev() {
  //   if (this.termsWindow) {
  //     this.$router.back() // 약관 이력 활성화 상태에서 뒤로 가기
  //   } else {
  //     this.termsWindow = true // 심사 서류 화면에서 약관 이력 화면으로 전환
  //   }
  // }

  togglePrev() {
    this.$router.back()
  }

  clearFileInputs() {
    this.documentForm.docBusiness = null
    this.documentForm.docCargo = null
    this.documentForm.docVehicle = null
    this.documentForm.docLeaseAgreement1 = null
    this.documentForm.docLeaseAgreement2 = null
    this.documentForm.docLeaseAgreement3 = null
    this.documentForm.docLeaseAgreement4 = null
    this.documentForm.docLeaseAgreement5 = null
  }

  fileDragDrop(fieldName, event) {
    const file = event.dataTransfer.files[0]
    if (this.isFileType(file.type) && file.size / 1024 / 1024 <= 10) {
      this.$set(this.documentForm, fieldName, file)

      const fileInput = this.$refs[fieldName] as Vue & {
        $emit: (event: string, ...args: any[]) => void
      }

      if (fileInput && fileInput.$emit) {
        fileInput.$emit('input', file)
      }
    } else {
      this.popupType(
        fieldName,
        file.size / 1024 / 1024 > 10 ? 'capacityExceeded' : 'filesExtensions',
      )
    }
  }

  // getDriverUserInfo (차주 정보 조회)
  // imgPossible (심사서류 등록 가능 여부)
  // 심사 서류 정보 활성화
  async docAuthority() {
    // 사용자 정보를 다시 가져와서 최신 상태로 유지
    await this.fetchUserInfo()

    // const { imgPossible } = this.userInfo

    if (this.isDocsPossible) {
      // 서류 등록 활성화
      this.termsWindow = false
    } else {
      // 필수 조건이 충족되지 않으면 팝업 표시
      // await this.$alert({
      //   title: '심사 서류 등록 불가',
      //   content: `필수 정보를 입력하지 않은 상태로 심사 서류 등록이 불가합니다. 잠시 후 다시 시도해주세요.`,
      // })
    }
  }

  // 이미지 등록 제한
  @Watch('documentForm', { deep: true })
  async onFileCheck() {
    const singleFileFields = ['docBusiness', 'docCargo', 'docVehicle']
    const multipleFileFields = [
      'docLeaseAgreement1',
      'docLeaseAgreement2',
      'docLeaseAgreement3',
      'docLeaseAgreement4',
      'docLeaseAgreement5',
    ]

    for (const fieldName of singleFileFields) {
      const file = this.documentForm[fieldName]
      if (file) {
        if (!this.isFileType(file.type)) {
          await this.popupType(fieldName, 'filesExtensions')
        } else if (file.size / 1024 / 1024 > 10) {
          await this.popupType(fieldName, 'capacityExceeded')
        }
      }
    }

    for (const fieldName of multipleFileFields) {
      const file = this.documentForm[fieldName]
      if (file) {
        if (!this.isFileType(file.type)) {
          await this.popupType(fieldName, 'filesExtensions')
        } else if (file.size / 1024 / 1024 > 10) {
          await this.popupType(fieldName, 'capacityExceeded')
        }
      }
    }
  }

  isFileType(fileType) {
    const validTypes = ['image/jpeg', 'image/png', 'image/jpg']
    return validTypes.includes(fileType)
  }

  async popupType(fieldName, errorType) {
    let content = ''
    if (errorType === 'capacityExceeded') {
      content =
        '파일의 크기가 초과되었습니다.<br/> 10MB 이하의 이미지(png, jpg)만 <br/> 첨부할 수 있습니다.'
    } else if (errorType === 'filesExtensions') {
      content = '지원하지 않는 파일입니다.'
    }
    // else if (errorType === 'exceedCount') {
    //   content = '파일 갯수가 초과되었습니다.<br/> 최대 5장까지 등록 가능합니다.'
    // }
    await this.$alert({
      title: '이미지 업로드 실패',
      content: content,
    })
    // if (fieldName === 'docLeaseAgreement') {
    //   this.documentForm[fieldName] = this.documentForm[fieldName].slice(0, 5)
    // }
    // if (fieldName === 'filesExtensions' || fieldName === 'capacityExceeded') {
    //   this.documentForm[fieldName] = null
    // }

    this.$set(this.documentForm, fieldName, null)
  }

  // fileExceed() {
  //   const files = this.documentForm.docLeaseAgreement
  //   if (files && files.length > 5) {
  //     this.popupType('docLeaseAgreement', 'exceedCount')
  //   }
  // }

  // associateImgUpload (준회원 서류 등록)
  // businessRegImg (사업자등록증), vehicleRegImg (차량등록증), transportLicenseImg (화물운송자격증), additionalImg (추가서류 이미지)
  // 심사 서류 등록
  async registerDocs() {
    try {
      const formData = new FormData()
      formData.append('businessRegImg', this.documentForm.docBusiness)
      formData.append('transportLicenseImg', this.documentForm.docCargo)
      formData.append('vehicleRegImg', this.documentForm.docVehicle)

      const additionalFiles = [
        this.documentForm.docLeaseAgreement1,
        this.documentForm.docLeaseAgreement2,
        this.documentForm.docLeaseAgreement3,
        this.documentForm.docLeaseAgreement4,
        this.documentForm.docLeaseAgreement5,
      ].filter((file) => file)

      additionalFiles.forEach((file) => {
        formData.append('additionalImg', file)
      })

      formData.append('userId', this.id)

      await DriverService.associateImgUpload(formData)

      await this.$toast({
        type: 'notice',
        content: `해당 회원의 심사 서류를 등록하였습니다.`,
      })

      this.$router.push({ name: 'memberReg' })
    } catch (error) {
      // await this.$alert({
      //   title: '서류 등록 실패',
      //   content: '심사 서류 등록에 실패했습니다. 다시 시도해주세요.',
      // })
    }
  }
}
