import axios from 'axios'

class CallRequestService {
  public apiUrl: string

  constructor() {
    this.apiUrl = '/call-request'
  }

  /**
   * 의뢰 목록 조회
   */
  // getCallList(params) {
  //   return axios.get(this.apiUrl, {params}).then((res) => res.data)
  // }

  /**
   * 단일 의뢰 상세 조회
   */
  getCallDetail(id) {
    const params = {callRequestId: id}
    return axios.get(this.apiUrl + `/broker`,{params}).then((res) => res.data)
  }

  /**
   * 의뢰 등록
   */
  // saveCallerRequestList(data) {
  //   return axios.post(this.apiUrl, data).then((res) => res.data)
  // }

  /**
   * 화물 취소 (화물 삭제하기)
   * @param id: callRequestId (의뢰 Id)
   */
  // deleteCall(id) {
  //   return axios.put(this.apiUrl + `/${id}/deletion`).then((res) => res.data)
  // }

  /**
   * 의뢰 목록 조회
   */
  getCallAdminList(params) {
    return axios
      .get(this.apiUrl + '/admin/list', {params : params})
      .then((res) => res.data)
  }

  /**
   * 화물 상태 변경 - 완료,취소,보류 (관리자)
   */
  changeStatus(id, data) {
    const params = { callRequestId: id}
    return axios
      .put(this.apiUrl + `/admin/state-change`, data, {params})
      .then((res) => res.data)
  }

  /**
   * 대시 보드 오늘의 현황
   */
  getTodayCount() {
    return axios.get(this.apiUrl + '/dashboard/admin/day').then((res) => res.data)
  }

  /**
   * (관리자) 주간 화물 등록 배차건수
   */
  getWeekRequestCount(){
    return axios.get(this.apiUrl + '/dashboard/admin/days').then((res) => res.data)
  }

  /**
   * 주선사별 화물 등록/배차 순위 API
   */
  getBrokerRankingCount(params){
    return axios.get(this.apiUrl + '/dashboard/admin/broker-ranking',{params: params}).then((res) => res.data)
  }

  /**
   * 화물 조건별 이용 조회
   */
  getCallRequestUsage(params){
    return axios.get(this.apiUrl + '/admin/usage', {params : params}).then((res) => res.data)
  }

  /**
   * 화물 조건별 이용 조회
   */
  getCallRequestUsageExcel(params){
    return axios.get(this.apiUrl + '/admin/usage/excel', {params : params, responseType:"blob"}).then((res) => res.data)
  }

}

export default new CallRequestService()
