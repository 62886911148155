var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      staticClass: "v-dialog wrapper",
      attrs: { persistent: "", "max-width": "480px" },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "font-size24 surface800 font-weight900" },
            [
              _vm._v(" 추천인 "),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "mt-n2 me-n3",
                  attrs: { icon: "" },
                  on: { click: _vm.onClickClose },
                },
                [
                  _c("v-icon", { attrs: { size: "25" } }, [
                    _vm._v(" " + _vm._s(_vm.icons.mdiWindowClose) + " "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "mt-n4 pb-0" },
            [_c("v-divider")],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "py-0", attrs: { color: "#2F2F2F" } },
            [
              _c("v-text-field", {
                staticStyle: { width: "100%" },
                attrs: {
                  placeholder: "주선사명으로 검색",
                  "append-icon": _vm.icons.mdiMagnify,
                  maxlength: "20",
                  dense: "",
                  outlined: "",
                  "hide-details": "",
                  clearable: "",
                },
                on: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.onSearch.apply(null, arguments)
                  },
                  "click:append": _vm.onSearch,
                },
                scopedSlots: _vm._u([
                  {
                    key: "append",
                    fn: function () {
                      return [
                        _c("v-icon", { on: { click: _vm.onSearch } }, [
                          _vm._v(" " + _vm._s(_vm.icons.mdiMagnify) + " "),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.keyword,
                  callback: function ($$v) {
                    _vm.keyword = $$v
                  },
                  expression: "keyword",
                },
              }),
            ],
            1
          ),
          _c("v-divider", { staticClass: "mb-0" }),
          _c(
            "v-card-text",
            { staticClass: "px-8 py-0" },
            [
              _c(
                "v-card",
                {
                  staticClass: "elevation-0 company-list",
                  attrs: { tile: "" },
                },
                [
                  _c(
                    "perfect-scrollbar",
                    {
                      staticClass: "ps-favorites-notifications",
                      attrs: { options: _vm.perfectScrollbarOptions },
                    },
                    [
                      _c(
                        "v-list-item-group",
                        {
                          model: {
                            value: _vm.selectedIndex,
                            callback: function ($$v) {
                              _vm.selectedIndex = $$v
                            },
                            expression: "selectedIndex",
                          },
                        },
                        [
                          _vm._l(_vm.brokerCompanyList, function (item, index) {
                            return [
                              _c("v-list-item", {
                                key: index,
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var active = ref.active
                                        return [
                                          _c(
                                            "v-list-item-action",
                                            [
                                              _c("v-checkbox", {
                                                attrs: {
                                                  "input-value": active,
                                                  color: "primary",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(item.companyName) +
                                                    " "
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm._f("formatBizno")(
                                                        item.businessRegNum
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              index < _vm.brokerCompanyList.length - 1
                                ? _c("v-divider", { staticClass: "pa-0 ma-0" })
                                : _vm._e(),
                            ]
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider", { staticClass: "mt-0" }),
          _c(
            "v-card-actions",
            { staticClass: "py-7 d-flex justify-center align-center" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "popup_btn",
                  attrs: { color: "primary" },
                  on: { click: _vm.onClickChangeCompany },
                },
                [_vm._v(" 확인 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }