import {initializeApp} from 'firebase/app'
import {
  getMessaging,
  getToken,
  onMessage,
  deleteToken,
} from 'firebase/messaging'
import {fcm} from '@/utils/fcm.util'
import _ from 'lodash'
import store from '../store'

class FcmService {
  private _store: any
  private _firebaseApp: any
  private FCM_SERVER_KEY: any

  constructor() {
    this._store = store
    this._firebaseApp = initializeApp({
      apiKey: process.env.VUE_APP_FCM_API_KEY,
      authDomain: 'itgo-dev-1a3ee.firebaseapp.com',
      projectId: 'itgo-dev-1a3ee',
      storageBucket: 'itgo-dev-1a3ee.appspot.com',
      messagingSenderId: '397373459305',
      appId: '1:397373459305:web:0debdadd5379b297454bf8',
      measurementId: 'G-KNEYGW3BGW',
    })
    this.FCM_SERVER_KEY = process.env.VUE_APP_FCM_SERVER_KEY
  }

  connect(controllerId) {
    const messaging = getMessaging(this._firebaseApp)

    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        console.log('Notification permission granted.')

        // 5. Get the token that uniquely identifies this device
        getToken(messaging, {
          vapidKey:
            'BB1jF96xBdAqSLQWpd83jgV6pcNPagVSPP9em53L5k237DHxJZApeP3KBbvTNyEAUZmpoaXUX-D2YT9jaJs013I',
        })
          .then((currentToken) => {
            if (currentToken) {
              console.log('FCM Token :: ', currentToken)
              // Send the token to your server and update the UI if necessary
              // ...
              // TODO: sendTokenToServer(currentToken)
              // TODO: updateUIForPushEnabled(currentToken)

              let topic: string = ''
              let noticeTopic: string = ''

              topic = `${process.env.VUE_APP_FCM_PREFIX}app_controller_${controllerId}`
              noticeTopic = `${process.env.VUE_APP_FCM_PREFIX}app_controller_notice`

              console.log(topic)
              console.log(noticeTopic)

              // 기본적으로 사용함.
              this.subscribeTokenToTopic(currentToken, topic)
              this.subscribeTokenToTopic(currentToken, noticeTopic)

              // 6. Handle incoming FCM messages
              onMessage(messaging, (payload) => {
                console.log('Message received. ', payload)

                fcm.$emit('fcm-topic-received', payload)
                this.setAllEventListener(payload)
                // fcm.$emit('fcm-topic-received 222', payload)
              })
            } else {
              // Show permission request UI
              console.log(
                'No registration token available. Request permission to generate one.',
              )
              // ...
            }
          })
          .catch((err) => {
            console.error('An error occurred while retrieving token. ', err)
            // ...
          })
      } else {
        console.log('Unable to get permission to notify.')
      }
    })
  }

  async deleteFCMToken() {
    try {
      const messaging = await getMessaging(this._firebaseApp)
      const result = await deleteToken(messaging)
      console.log(result)
    } catch (e) {
      console.log(e)
    } finally {
      console.log('deleteFCMToken')
    }
  }

  subscribeTokenToTopic(token, topic) {
    fetch(`https://iid.googleapis.com/iid/v1/${token}/rel/topics/${topic}`, {
      method: 'POST',
      headers: new Headers({
        Authorization: 'key=' + this.FCM_SERVER_KEY,
      }),
    })
      .then((response) => {
        console.log(response)
        if (response.status < 200 || response.status >= 400) {
          throw (
            'Error subscribing to topic: ' +
            response.status +
            ' - ' +
            response.text()
          )
        }
        console.log(`Subscribed to ${topic}`)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  setAllEventListener(payload) {
    console.log('getFcmMessage', payload)
    console.log('getFcmMessage', payload.data.payload)
    const stringToJson = JSON.parse(payload.data.payload)
    console.log(stringToJson)

    const category = stringToJson.category
    const type = stringToJson.type
    const user = this._store.state.auth.user
    //const token = this._store.state.auth.token

    if (category === 'NOTICE') {
      console.log('NOTICE')
    } else {
      console.log(type)
      switch (type) {
        /**
         * 중복 로그인
         * 중복 로그인 알림
         */
        case 'T0500': {
          const accessToken = stringToJson.data.accessToken
          const refreshToken = stringToJson.data.refreshToken

          const getAccessToken = window.sessionStorage.getItem('accessToken')
          const getRefreshToken = window.sessionStorage.getItem('refreshToken')

          if (_.includes(accessToken, getAccessToken)) {
            console.log("중복 로그인 시작 2 셋팅")
            this._store.dispatch('auth/signout', 2)
          } else if (_.includes(refreshToken, getRefreshToken)) {
            this._store.dispatch('auth/signout', 2)
          }
          break
        }

        /**
         * 가입 요청
         * 서류 변경 요청
         */
        case 'T0128':
        case 'T0129': {
          this._store.dispatch('notifications/setAlarmLists')
          break;
        }
        default:
          break
      }
    }
  }
}

export default new FcmService()
