var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-wrapper content_body" },
    [
      _c(
        "div",
        { staticClass: "content-inner" },
        [
          _c(
            "v-card",
            { staticClass: "pb-8" },
            [
              _c(
                "v-card-title",
                {
                  staticClass:
                    "px-4 pt-8 d-flex align-center justify-space-between w-full",
                },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex align-center" },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "surface800",
                          attrs: { size: "35" },
                          on: { click: _vm.togglePrev },
                        },
                        [_vm._v(" " + _vm._s(_vm.icons.mdiChevronLeft) + " ")]
                      ),
                      _vm.termsWindow
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "font-size26 font-weight700 surface800",
                            },
                            [_vm._v(" 강제 전환 고객 ")]
                          )
                        : _vm._e(),
                      _vm.termsWindow
                        ? _c("v-divider", {
                            staticStyle: { margin: "0 14px" },
                            attrs: { vertical: "" },
                          })
                        : _vm._e(),
                      _vm.termsWindow
                        ? _c(
                            "span",
                            { staticClass: "font-size20 font-weight500" },
                            [_vm._v(" " + _vm._s(_vm.name) + "님 ")]
                          )
                        : _c(
                            "span",
                            {
                              staticClass:
                                "font-size26 font-weight700 surface800",
                            },
                            [_vm._v(" 심사 서류 등록 ")]
                          ),
                    ],
                    1
                  ),
                  _vm.userInfo && _vm.userInfo.imgPossible === 1
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "itgoWhite--text font-size18 search-btn",
                          attrs: {
                            color: "itgoInfo",
                            disabled: _vm.isRegisterButtonDisabled,
                          },
                          on: { click: _vm.docsClickChange },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.termsWindow ? "심사 서류 등록" : "등록"
                              ) +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-card-text",
                {
                  staticClass:
                    "font-size20 font-weight700 surface800 pt-8 pl-11",
                },
                [_vm._v(" 회원 정보 ")]
              ),
              _c(
                "v-card",
                {
                  staticClass:
                    "mx-11 mt-2 terms-history-window elevation-0 transparent",
                  attrs: { outlined: "" },
                },
                [
                  _c(
                    "v-col",
                    { staticClass: "mx-4", attrs: { cols: "12", sm: "12" } },
                    [
                      _c(
                        "v-row",
                        _vm._l(_vm.personalInfo, function (_item, i) {
                          return _c("freight-info-two-item", {
                            key: i,
                            attrs: {
                              title: _item.title,
                              content: _item.content,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.termsWindow
                ? _c(
                    "div",
                    [
                      _c(
                        "v-card-text",
                        {
                          staticClass:
                            "font-size20 font-weight700 surface800 pt-8 pl-11",
                        },
                        [_vm._v(" 약관 이력 ")]
                      ),
                      _c(
                        "v-card",
                        {
                          staticClass: "mx-11 mt-2 transparent",
                          attrs: { outlined: "" },
                        },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "12" } },
                            [
                              _c(
                                "v-row",
                                _vm._l(
                                  [[_vm.requiredInfo, _vm.choiceInfo]],
                                  function (arr, key) {
                                    return _c(
                                      "v-col",
                                      {
                                        key: "__" + key,
                                        staticClass: "ma-0 pa-0",
                                        attrs: {
                                          cols: "12",
                                          md: "12",
                                          sm: "12",
                                        },
                                      },
                                      _vm._l(arr, function (info, i) {
                                        return _c(
                                          "v-card",
                                          {
                                            key: "info_" + key + "_" + i,
                                            staticClass: "pa-0 elevation-0",
                                          },
                                          _vm._l(info, function (item, index) {
                                            return item.show
                                              ? _c(
                                                  "v-card",
                                                  {
                                                    key: index,
                                                    staticClass:
                                                      "terms-history-window elevation-0",
                                                  },
                                                  [
                                                    _c(
                                                      "v-card-title",
                                                      {
                                                        key:
                                                          "title_" +
                                                          key +
                                                          "_" +
                                                          i +
                                                          "_" +
                                                          index,
                                                        staticClass:
                                                          "px-0 pt-0 pb-6 font-size18 surface800 font-weight700",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(item.title) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-card-text",
                                                      {
                                                        staticClass:
                                                          "px-0 pt-0 pb-0",
                                                      },
                                                      _vm._l(
                                                        item.data,
                                                        function (_item, idx) {
                                                          return _item.show
                                                            ? _c(
                                                                "terms-info-item",
                                                                {
                                                                  key:
                                                                    "data_" +
                                                                    key +
                                                                    "_" +
                                                                    i +
                                                                    "_" +
                                                                    index +
                                                                    "_" +
                                                                    idx,
                                                                  attrs: {
                                                                    title:
                                                                      _item.title,
                                                                    content:
                                                                      _item.content,
                                                                    isBtn:
                                                                      _item.btnShow,
                                                                    "terms-type":
                                                                      _item.termsType,
                                                                    "user-id":
                                                                      _vm.id,
                                                                    type: _vm.type,
                                                                  },
                                                                  on: {
                                                                    updateUserInfo:
                                                                      _vm.updateUserInfo,
                                                                    showHistory:
                                                                      _vm.showHistory,
                                                                  },
                                                                }
                                                              )
                                                            : _vm._e()
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          }),
                                          1
                                        )
                                      }),
                                      1
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "v-card",
                    {
                      staticClass: "mx-11 terms-history-window",
                      staticStyle: { "margin-bottom": "0 !important" },
                      attrs: { flat: "" },
                    },
                    [
                      _c("v-card-title", { staticClass: "pt-0 pb-2 px-0" }, [
                        _vm._v("심사 서류 정보"),
                      ]),
                      _c("v-card-text", { staticClass: "mb-2 px-0" }, [
                        _vm._v(
                          " 회원님의 소중한 개인정보 보호를 위해 서류 내 기재되어 있는 "
                        ),
                        _c("strong", [_vm._v("주민등록번호 뒷자리")]),
                        _vm._v(" 를 가린 후 업로드 해주세요. "),
                      ]),
                      _c(
                        "div",
                        {
                          on: {
                            dragover: function ($event) {
                              $event.preventDefault()
                            },
                            dragleave: function ($event) {
                              $event.preventDefault()
                            },
                            drop: function ($event) {
                              $event.preventDefault()
                              return _vm.fileDragDrop("docBusiness", $event)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "mb-2" }, [
                            _vm._v(" 사업자등록증 "),
                            _c("span", { staticStyle: { color: "#ff4c51" } }, [
                              _vm._v("*"),
                            ]),
                          ]),
                          _c("v-file-input", {
                            ref: "docBusiness",
                            attrs: {
                              label: "파일",
                              outlined: "",
                              dense: "",
                              color: "deep-purple accent-4",
                              accept: "image/png, image/jpeg",
                            },
                            model: {
                              value: _vm.documentForm.docBusiness,
                              callback: function ($$v) {
                                _vm.$set(_vm.documentForm, "docBusiness", $$v)
                              },
                              expression: "documentForm.docBusiness",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          on: {
                            dragover: function ($event) {
                              $event.preventDefault()
                            },
                            dragleave: function ($event) {
                              $event.preventDefault()
                            },
                            drop: function ($event) {
                              $event.preventDefault()
                              return _vm.fileDragDrop("docCargo", $event)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "mb-2" }, [
                            _vm._v(" 화물운송자격증 "),
                            _c("span", { staticStyle: { color: "#ff4c51" } }, [
                              _vm._v("*"),
                            ]),
                          ]),
                          _c("v-file-input", {
                            ref: "docCargo",
                            attrs: {
                              label: "파일",
                              outlined: "",
                              dense: "",
                              color: "deep-purple accent-4",
                              accept: "image/png, image/jpeg",
                            },
                            model: {
                              value: _vm.documentForm.docCargo,
                              callback: function ($$v) {
                                _vm.$set(_vm.documentForm, "docCargo", $$v)
                              },
                              expression: "documentForm.docCargo",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          on: {
                            dragover: function ($event) {
                              $event.preventDefault()
                            },
                            dragleave: function ($event) {
                              $event.preventDefault()
                            },
                            drop: function ($event) {
                              $event.preventDefault()
                              return _vm.fileDragDrop("docVehicle", $event)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "mb-2 d-flex align-end" }, [
                            _c("div", [
                              _vm._v(" 차량등록증 "),
                              _c(
                                "span",
                                { staticStyle: { color: "#ff4c51" } },
                                [_vm._v("*")]
                              ),
                            ]),
                            _c("div", { staticClass: "text-xs ml-2" }, [
                              _vm._v("마스킹 처리 후 업로드 해주세요."),
                            ]),
                          ]),
                          _c("v-file-input", {
                            ref: "docVehicle",
                            attrs: {
                              label: "파일",
                              outlined: "",
                              dense: "",
                              color: "deep-purple accent-4",
                              accept: "image/png, image/jpeg",
                            },
                            model: {
                              value: _vm.documentForm.docVehicle,
                              callback: function ($$v) {
                                _vm.$set(_vm.documentForm, "docVehicle", $$v)
                              },
                              expression: "documentForm.docVehicle",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          on: {
                            dragover: function ($event) {
                              $event.preventDefault()
                            },
                            dragleave: function ($event) {
                              $event.preventDefault()
                            },
                            drop: function ($event) {
                              $event.preventDefault()
                              return _vm.fileDragDrop(
                                "docLeaseAgreement1",
                                $event
                              )
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "mb-2 d-flex align-end" }, [
                            _c("div", [_vm._v("임대계약서/가입동의서(선택)")]),
                            _c("div", { staticClass: "text-xs ml-2" }, [
                              _vm._v("최대 5장까지 등록 가능합니다."),
                            ]),
                          ]),
                          _c("v-file-input", {
                            ref: "docLeaseAgreement1",
                            staticClass: "mb-2",
                            attrs: {
                              label: "파일",
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              color: "deep-purple accent-4",
                              accept: "image/png, image/jpeg",
                            },
                            model: {
                              value: _vm.documentForm.docLeaseAgreement1,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.documentForm,
                                  "docLeaseAgreement1",
                                  $$v
                                )
                              },
                              expression: "documentForm.docLeaseAgreement1",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          on: {
                            dragover: function ($event) {
                              $event.preventDefault()
                            },
                            dragleave: function ($event) {
                              $event.preventDefault()
                            },
                            drop: function ($event) {
                              $event.preventDefault()
                              return _vm.fileDragDrop(
                                "docLeaseAgreement2",
                                $event
                              )
                            },
                          },
                        },
                        [
                          _c("v-file-input", {
                            ref: "docLeaseAgreement2",
                            staticClass: "mb-2",
                            attrs: {
                              label: "파일",
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              color: "deep-purple accent-4",
                              accept: "image/png, image/jpeg",
                            },
                            model: {
                              value: _vm.documentForm.docLeaseAgreement2,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.documentForm,
                                  "docLeaseAgreement2",
                                  $$v
                                )
                              },
                              expression: "documentForm.docLeaseAgreement2",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          on: {
                            dragover: function ($event) {
                              $event.preventDefault()
                            },
                            dragleave: function ($event) {
                              $event.preventDefault()
                            },
                            drop: function ($event) {
                              $event.preventDefault()
                              return _vm.fileDragDrop(
                                "docLeaseAgreement3",
                                $event
                              )
                            },
                          },
                        },
                        [
                          _c("v-file-input", {
                            ref: "docLeaseAgreement3",
                            staticClass: "mb-2",
                            attrs: {
                              label: "파일",
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              color: "deep-purple accent-4",
                              accept: "image/png, image/jpeg",
                            },
                            model: {
                              value: _vm.documentForm.docLeaseAgreement3,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.documentForm,
                                  "docLeaseAgreement3",
                                  $$v
                                )
                              },
                              expression: "documentForm.docLeaseAgreement3",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          on: {
                            dragover: function ($event) {
                              $event.preventDefault()
                            },
                            dragleave: function ($event) {
                              $event.preventDefault()
                            },
                            drop: function ($event) {
                              $event.preventDefault()
                              return _vm.fileDragDrop(
                                "docLeaseAgreement4",
                                $event
                              )
                            },
                          },
                        },
                        [
                          _c("v-file-input", {
                            ref: "docLeaseAgreement4",
                            staticClass: "mb-2",
                            attrs: {
                              label: "파일",
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              color: "deep-purple accent-4",
                              accept: "image/png, image/jpeg",
                            },
                            model: {
                              value: _vm.documentForm.docLeaseAgreement4,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.documentForm,
                                  "docLeaseAgreement4",
                                  $$v
                                )
                              },
                              expression: "documentForm.docLeaseAgreement4",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          on: {
                            dragover: function ($event) {
                              $event.preventDefault()
                            },
                            dragleave: function ($event) {
                              $event.preventDefault()
                            },
                            drop: function ($event) {
                              $event.preventDefault()
                              return _vm.fileDragDrop(
                                "docLeaseAgreement5",
                                $event
                              )
                            },
                          },
                        },
                        [
                          _c("v-file-input", {
                            ref: "docLeaseAgreement5",
                            attrs: {
                              label: "파일",
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              color: "deep-purple accent-4",
                              accept: "image/png, image/jpeg",
                            },
                            model: {
                              value: _vm.documentForm.docLeaseAgreement5,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.documentForm,
                                  "docLeaseAgreement5",
                                  $$v
                                )
                              },
                              expression: "documentForm.docLeaseAgreement5",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          key: _vm.componentKey.termsHistoryPopupKey,
          staticClass: "v-dialog",
          attrs: { "max-width": "551", persistent: "" },
          model: {
            value: _vm.termsHistoryPopup,
            callback: function ($$v) {
              _vm.termsHistoryPopup = $$v
            },
            expression: "termsHistoryPopup",
          },
        },
        [
          _c("terms-history-popup", {
            attrs: {
              type: _vm.type,
              "terms-history-data": _vm.userTermHistoryData,
            },
            on: { historyPopupHide: _vm.historyPopupHide },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }