


























































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import DatePicker from '@/views/utils/DatePicker.vue'
import DateMonthPicker from '@/views/utils/DateMonthPicker.vue'
import Pagination from '@/views/utils/Pagination.vue'
// @ts-ignore
import Vue2DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ko'
import {
  mdiMagnify,
  mdiChevronDown,
  mdiCircleOutline,
  mdiCircleSlice8,
} from '@mdi/js'
import { State } from 'vuex-class'
import DatetimeUtils from '@/utils/datetime.utils'
import PayoutService from '@/services-itgo/payout.service'
import _ from 'lodash'
import payoutService from '@/services-itgo/payout.service'

@Component({
  components: {
    DatePicker,
    DateMonthPicker,
    Pagination,
    Vue2DatePicker,
  },

  mounted() {
    this.dayDate = this.baseDate
    this.selectDateType.dayType = true
    this.fetchPayoutList()
  },
})
export default class BrokerCalculate extends Vue {
  @State((state) => state.auth.user) loginUser

  icons: any = {
    mdiMagnify,
    mdiChevronDown,
    mdiCircleOutline,
    mdiCircleSlice8,
  }

  keyword = ''
  formatDateString = 'YYYY-MM-DD'

  dayDate : any = [new Date(), new Date()]
  monthDate : any = DatetimeUtils.getMonth(-1)
  yearDate : any = DatetimeUtils.getYear(-1)

  get baseDate() {
    const today = new Date()
    const beforeWeek = new Date(today)
    beforeWeek.setDate(today.getDate())
    return [beforeWeek, today]
  }

  @Watch('dayDate')
  changeDates(value) {
    if (value?.length > 1) {
      this.set(value)
    }
  }

  previousDayDate: Date[] | null = null

  async set(value) {
    const temp = [...this.dayDate]
    temp.sort((a, b) => new Date(a).getTime() - new Date(b).getTime())

    if (temp.length === 2) {
      if (DatetimeUtils.asDays(temp[0], temp[1]) >= 30) {
        this.dayDate = this.previousDayDate || this.baseDate
        await this.$alert({
          title: '기간 설정 오류',
          content: '기간 설정은 최대 30일까지 가능합니다.',
        })
      } else {
        this.previousDayDate = [...this.dayDate]
      }
    }
  }

  selectDateType = {
    dayType: false,
    monthType: false,
    yearType: false,
  }

  payoutList = {
    loading: false,
    headers: [
      { text: '날짜', value: 'regDt', align: 'center', width: '15%' },
      { text: '주선사명', value: 'companyName', align: 'center', width: '8%' },
      { text: '의뢰 건수', value: 'requestCnt', align: 'center', width: '10%' },
      {
        text: '배차 건수',
        value: 'dispatchCnt',
        align: 'center',
        width: '10%',
      },
      { text: '거래액', value: 'totalPrice', align: 'center', width: '10%' },
      {
        text: '수수료',
        value: 'totalFee',
        align: 'center',
        width: '10%',
      },
      {
        text: '인센티브',
        value: 'incentive',
        align: 'center',
        width: '10%',
      },
      {
        text: '추천 차주수',
        value: 'recommendDriver',
        align: 'center',
        width: '10%',
      },
      {
        text: 'MS',
        value: 'msString',
        align: 'center',
        width: '10%',
      },
    ],
    items: [],
    serverItemsLength: 0,
  }

  get pageLength() {
    const length = Math.ceil(this.payoutList.serverItemsLength / this.pageSize)
    return length === 0 ? 1 : length
  }

  totalPageCount = 1
  pageSizes = [10, 20, 30]
  pageSize = 10
  pageNum = 1

  /**
   * pageSize: 페이지 크기
   * pageNum: 페이지 번호
   */
  @Watch('pageSize')
  onChangePageSize() {
    this.pageNum = 1
    this.fetchPayoutList()
  }

  @Watch('pageNum')
  onChangePageNum() {
    this.fetchPayoutList()
  }

  public excelParams: any = {
    startDt: '',
    endDt: '',
    keyword: '',
  }

  async fetchPayoutList() {
    if (
      (this.selectDateType.dayType && !this.dayDate[0] && !this.dayDate[1]) ||
      (this.selectDateType.monthType && !this.monthDate) ||
      (this.selectDateType.yearType && !this.yearDate)
    ){
      await this.$alert({
        title: '기간 설정 오류',
        content: '검색 기간을 설정해 주세요.',
      })

      if(this.selectDateType.dayType && !this.dayDate[0] && !this.dayDate[1])
        this.dayDate = [new Date(), new Date()]
      else if(this.selectDateType.monthType && !this.monthDate)
        this.monthDate = DatetimeUtils.getMonth(-1)
      else
        this.yearDate = DatetimeUtils.getYear(-1)

      return
    }

    try {
      this.payoutList.loading = true
      this.payoutList.items = []
      //this.payoutList.serverItemsLength = 0

      if (this.selectDateType.dayType) this.formatDateString = 'YYYY-MM-DD'
      else if (this.selectDateType.monthType) this.formatDateString = 'YYYY-MM'
      else this.formatDateString = 'YYYY'

      const params = {
        startDt:
          (this.selectDateType.dayType &&
            DatetimeUtils.stringDate(this.dayDate[0], 'YYYY-MM-DD')) ||
          (this.selectDateType.monthType &&
            DatetimeUtils.stringDate(this.monthDate, 'YYYY-MM')) ||
          (this.selectDateType.yearType &&
            DatetimeUtils.stringDate(this.yearDate, 'YYYY')),
        endDt:
          (this.selectDateType.dayType &&
            DatetimeUtils.stringDate(this.dayDate[1], 'YYYY-MM-DD')) ||
          (this.selectDateType.monthType &&
            DatetimeUtils.stringDate(this.monthDate, 'YYYY-MM')) ||
          (this.selectDateType.yearType &&
            DatetimeUtils.stringDate(this.yearDate, 'YYYY')),
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        keyword: this.keyword,
      }

      const data = await PayoutService.getPayoutList(params)

      this.payoutList.items = data.content.map((item) => ({
        ...item,
        key: item.companyId,
        msString: this.msString(item.ms),
      }))

      this.payoutList.serverItemsLength = data.totalElementCount
      this.totalPageCount = this.pageLength
      this.excelParams = params
    } catch (error: any) {
      this.$alert(error.response.data.errMsg)
    } finally {
      this.payoutList.loading = false
    }
  }

  msString(ms) {
    if (this.selectDateType.yearType || this.selectDateType.dayType)
      return 'N/A'
    else {
      if (!ms) {
        return '-'
      } else {
        return ms.toLocaleString() + '원'
      }
    }
  }

  async onClickDownloadExcel() {
    try {
      console.log('onClickDownloadExcel')

      let dateExcel = ''
      if (this.selectDateType.dayType) {
        dateExcel = `${DatetimeUtils.stringDate(
          this.dayDate[0],
          'YYYY-MM-DD',
        )}~${DatetimeUtils.stringDate(this.dayDate[1], 'YYYY-MM-DD')}`
      } else if (this.selectDateType.monthType) {
        dateExcel = DatetimeUtils.stringDate(this.monthDate, 'YYYY-MM')
      } else if (this.selectDateType.yearType) {
        dateExcel = DatetimeUtils.stringDate(this.yearDate, 'YYYY')
      }

      const nameExcel = `주선사정산_${dateExcel}.xlsx`

      const data = await payoutService.getPayoutListExcel(this.excelParams)
      console.log(data)
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      const link = document.createElement('a')
      const blobUrl = URL.createObjectURL(blob)
      link.href = blobUrl
      link.download = nameExcel
      link.click()
      URL.revokeObjectURL(blobUrl)
    } catch (e) {
      await this.$alert({
        title: '엑셀 생성 실패',
        content: e.response.data.errMsg,
      })
    } finally {
    }
  }

  onSearch() {
    this.pageNum = 1
    this.fetchPayoutList()
  }

  dateTypeSelect(type) {
    switch (type) {
      case 'day':
        if(this.monthDate === null)
          this.monthDate = DatetimeUtils.getMonth(-1)
        if(this.yearDate === null)
          this.yearDate = DatetimeUtils.getYear(-1)
        this.selectDateType.monthType = false
        this.selectDateType.yearType = false
        break
      case 'month':
        if(this.dayDate[0] === null && this.dayDate[1] === null){
          this.dayDate = [new Date(), new Date()]
        }
        if(this.yearDate === null)
          this.yearDate = DatetimeUtils.getYear(-1)
        this.selectDateType.dayType = false
        this.selectDateType.yearType = false
        break
      case 'year':
        if(this.dayDate[0] === null && this.dayDate[1] === null){
          this.dayDate = [new Date(), new Date()]
        }
        if(this.monthDate === null)
          this.monthDate = DatetimeUtils.getMonth(-1)
        this.selectDateType.dayType = false
        this.selectDateType.monthType = false
        break
      default:
        break
    }
  }
}
