var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-select", {
    staticStyle: { width: "100%" },
    attrs: {
      items: _vm.paymentItems,
      label: "결제 방식",
      "item-text": "text",
      "item-value": "value",
      dense: "",
      outlined: "",
      "hide-details": "",
      "menu-props": { bottom: true, offsetY: true },
    },
    on: { change: _vm.changeItem },
    model: {
      value: _vm.payment,
      callback: function ($$v) {
        _vm.payment = $$v
      },
      expression: "payment",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }