// 페이지 경로 맵핑
import ManagerUserInfo from '@/views/managerMember/ManagerUserInfo.vue'
import { CONTROLLER_ROLE } from '@/views/constant/user.role.constant'

const routes = [
  {
    path: '/',
    redirect: 'management',
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/',
    redirect: 'login',
    component: () => import('@/views/layouts/LayoutBlank.vue'),
    children: [
      {
        name: 'login',
        path: '/login',
        component: () => import('@/views/login/Login-v1.vue'),
        requiresAuth: false,
      },
      {
        name: 'findId',
        path: '/findId',
        component: () => import('@/views/find/FindId.vue'),
        requiresAuth: false,
      },
      {
        name: 'findPasswordId',
        path: '/findPasswordId',
        component: () => import('@/views/find/FindPasswordId.vue'),
        requiresAuth: false,
      },
      {
        name: 'findPasswordReset',
        path: '/findPasswordReset',
        component: () => import('@/views/find/FindPasswordReset.vue'),
        requiresAuth: false,
      },
      {
        name: 'renewal',
        path: '/renewal',
        component: () => import('@/views/serviceCheck/ServiceCheck.vue'),
        requiresAuth: false,
      },
      {
        name: 'notFound',
        path: '/NotFound',
        component: () => import('@/views/miscellaneous/NotFound.vue'),
        requiresAuth: false,
      },
    ],
  },
  {
    path: '/management',
    redirect: 'management/dashboards',
    component: () => import('@/views/layouts/LayoutContentVerticalNav.vue'),
    children: [
      {
        name: 'dashboards',
        path: '/management/dashboards',
        component: () => import('@/views/dashBoards/DashBoard.vue'),
        meta: {
          requiresAuth: true,
          authorization: CONTROLLER_ROLE.ALL,
        },
      },

      /**
       * * 회원가입
       */
      {
        name: 'memberReg',
        path: '/management/memberReg',
        component: () => import('@/views/memberReg/MemberReg.vue'),
        meta: {
          requiresAuth: true,
          authorization: CONTROLLER_ROLE.ALL,
        },
      },
      {
        name: 'memberInfo',
        path: '/management/memberInfo',
        component: () => import('@/views/memberReg/MemberInfo.vue'),
        meta: {
          requiresAuth: true,
          authorization: CONTROLLER_ROLE.ALL,
        },
      },
      {
        name: 'associateMemberReg',
        path: '/management/associateMemberReg',
        component: () => import('@/views/memberReg/AssociateMemberReg.vue'),
        meta: {
          requiresAuth: true,
          authorization: CONTROLLER_ROLE.ALL,
        },
      },
      {
        name: 'associateMemberInfo',
        path: '/management/associateMemberInfo',
        component: () => import('@/views/memberReg/AssociateMemberInfo.vue'),
        meta: {
          requiresAuth: true,
          authorization: CONTROLLER_ROLE.ALL,
        },
      },
      {
        name: 'forcedConversionCustomer',
        path: '/management/forcedConversionCustomer',
        component: () =>
          import('@/views/memberReg/ForcedConversionCustomer.vue'),
        meta: {
          requiresAuth: true,
          authorization: CONTROLLER_ROLE.ALL,
        },
      },
      {
        name: 'forcedConversionCustomerInfo',
        path: '/management/forcedConversionCustomerInfo',
        component: () =>
          import('@/views/memberReg/ForcedConversionCustomerInfo.vue'),
        meta: {
          requiresAuth: true,
          authorization: CONTROLLER_ROLE.ALL,
        },
      },
      {
        name: 'memberDoc',
        path: '/management/memberDoc',
        component: () => import('@/views/memberReg/MemberDoc.vue'),
        meta: {
          requiresAuth: true,
          authorization: CONTROLLER_ROLE.ALL,
        },
      },
      /**
       * * 회원관리
       */
      {
        name: 'brokerManagement',
        path: '/management/brokerManagement',
        component: () => import('@/views/management/BrokerManagement.vue'),
        meta: {
          requiresAuth: true,
          authorization: CONTROLLER_ROLE.ALL,
        },
      },
      {
        name: 'brokerInfo',
        path: '/management/brokerManagement',
        component: () => import('@/views/management/BrokerInfo.vue'),
        meta: {
          requiresAuth: true,
          authorization: CONTROLLER_ROLE.ALL,
        },
      },
      {
        name: 'driverManagement',
        path: '/management/driverManagement',
        component: () => import('@/views/management/DriverManagement.vue'),
        meta: {
          requiresAuth: true,
          authorization: CONTROLLER_ROLE.ALL,
        },
      },
      {
        name: 'driverInfo',
        path: '/management/driverManagement',
        component: () => import('@/views/management/DriverInfo.vue'),
        meta: {
          requiresAuth: true,
          authorization: CONTROLLER_ROLE.ALL,
        },
      },
      {
        name: 'callerManagement',
        path: '/management/callerManagement',
        component: () => import('@/views/management/CallerManagement.vue'),
        meta: {
          requiresAuth: true,
          authorization: CONTROLLER_ROLE.ALL,
        },
      },
      {
        name: 'callerInfo',
        path: '/management/callerManagement',
        component: () => import('@/views/management/CallerInfo.vue'),
        meta: {
          requiresAuth: true,
          authorization: CONTROLLER_ROLE.ALL,
        },
      },
      /**
       * * 화물이력
       */
      {
        name: 'freightHistory',
        path: '/management/freightHistory',
        component: () => import('@/views/freightHistory/FreightHistory.vue'),
        meta: {
          requiresAuth: true,
          authorization: CONTROLLER_ROLE.ALL,
        },
      },
      {
        name: 'freightInfo',
        path: '/management/freightInfo',
        component: () => import('@/views/freightHistory/FreightInfo.vue'),
        meta: {
          requiresAuth: true,
          authorization: CONTROLLER_ROLE.ALL,
        },
      },
      /**
       * * 통계
       */

      {
        name: 'brokerCalculate',
        path: '/management/brokerCalculate',
        component: () => import('@/views/statistics/BrokerCalculate.vue'),
        meta: {
          requiresAuth: true,
          authorization: [CONTROLLER_ROLE.SUPERADMIN, CONTROLLER_ROLE.ADMIN],
        },
      },
      {
        name: 'freightUse',
        path: '/management/freightUse',
        component: () => import('@/views/statistics/FreightUse.vue'),
        meta: {
          requiresAuth: true,
          authorization: [CONTROLLER_ROLE.SUPERADMIN, CONTROLLER_ROLE.ADMIN],
        },
      },
      {
        name: 'driverApp',
        path: '/management/driverApp',
        component: () => import('@/views/statistics/view/DriverApp.vue'),
        meta: {
          requiresAuth: true,
          authorization: [CONTROLLER_ROLE.SUPERADMIN, CONTROLLER_ROLE.ADMIN],
        },
      },
      {
        name: 'testimonials',
        path: '/management/testimonials',
        component: () => import('@/views/statistics/Testimonials.vue'),
        meta: {
          requiresAuth: true,
          authorization: [CONTROLLER_ROLE.SUPERADMIN, CONTROLLER_ROLE.ADMIN],
        },
      },
      {
        name: 'driverMonthUse',
        path: '/management/driverMonthUse',
        component: () => import('@/views/statistics/DriverMonthUse.vue'),
        meta: {
          requiresAuth: true,
          authorization: [CONTROLLER_ROLE.SUPERADMIN, CONTROLLER_ROLE.ADMIN],
        },
      },
      {
        name: 'usageHistory',
        path: '/management/usageHistory',
        component: () => import('@/views/statistics/UsageHistory.vue'),
        meta: {
          requiresAuth: true,
          authorization: [CONTROLLER_ROLE.SUPERADMIN, CONTROLLER_ROLE.ADMIN],
        },
      },
      {
        name: 'subscriptionLeave',
        path: '/management/subscriptionLeave',
        component: () => import('@/views/statistics/SubscriptionLeave.vue'),
        meta: {
          requiresAuth: true,
          authorization: [CONTROLLER_ROLE.SUPERADMIN, CONTROLLER_ROLE.ADMIN],
        },
      },
      {
        name: 'noticeManagement',
        path: '/management/noticeManagement',
        component: () =>
          import('@/views/noticeManagement/NoticeManagement.vue'),
        meta: {
          requiresAuth: true,
          authorization: CONTROLLER_ROLE.ALL,
        },
      },

      {
        name: 'eventManagement',
        path: '/management/eventManagement',
        component: () => import('@/views/eventManagement/EventManagement.vue'),
        meta: {
          requiresAuth: true,
          authorization: CONTROLLER_ROLE.ALL,
        },
      },

      {
        name: 'faqManagement',
        path: '/management/faqManagement',
        component: () => import('@/views/faqManagement/FaqManagement.vue'),
        meta: {
          requiresAuth: true,
          authorization: CONTROLLER_ROLE.ALL,
        },
      },

      {
        name: 'vocManagement',
        path: '/management/vocManagement',
        component: () => import('@/views/vocManagement/VocManagement.vue'),
        meta: {
          requiresAuth: true,
          authorization: CONTROLLER_ROLE.ALL,
        },
      },
      {
        name: 'documentInformation',
        path: '/management/documentInformation',
        component: () =>
          import('@/views/membershipManagement/DocumentInformation.vue'),
        meta: {
          requiresAuth: true,
          authorization: CONTROLLER_ROLE.ALL,
        },
      },
      {
        name: 'managerUserList',
        path: '/management/managerUserList',
        component: () => import('@/views/managerMember/ManagerList.vue'),
        meta: {
          requiresAuth: true,
          authorization: [CONTROLLER_ROLE.SUPERADMIN],
        },
      },
      {
        name: 'managerUserInfo',
        path: '/management/managerUserInfo',
        component: () => import('@/views/managerMember/ManagerUserInfo.vue'),
        meta: {
          requiresAuth: true,
          authorization: [CONTROLLER_ROLE.SUPERADMIN],
        },
      },
      {
        name: 'mapUpload',
        path: '/management/mapUpload',
        component: () => import('@/views/mapUpload/MapUpload.vue'),
        meta: {
          requiresAuth: true,
          authorization: [CONTROLLER_ROLE.SUPERADMIN],
        },
      },
    ],
  },
]

export default routes
