import axios from 'axios'

class MiscService {
  private apiUrl: string

  constructor() {
    this.apiUrl = '/misc'
  }

  /**
   * 지역 및 화물개수 불러오기
   */
  // fetchRegionList() {
  //   return axios
  //     .get(this.apiUrl + '/admin-district/real')
  //     .then((res) => res.data)
  // }

   /**
   * 행정구역 조회
   */
    getRegionData() {
      return axios
        .get(this.apiUrl + '/admin-district')
        .then((res) => res.data)
    }
}

export default new MiscService()
