export default {
  ////////////////LG U+ //////////////////////////////
  //left panel title
  Dashboards: '대시보드',
  Membership: '회원가입',
  MemberRegistration: '회원가입 심사',
  MemberDocument: '서류변경 심사',
  AssociateMemberRegistration: '준회원 목록',
  ForcedConversionCustomer: '강제 전환 고객 목록',
  MemberManagement: '회원관리',
  Broker: '주선사',
  Driver: '차주',
  Caller: '화주',
  FreightHistory: '화물이력',
  AccountInfo: '계좌정보',
  Statistics: '통계',
  BrokerCalculate: '주선사 정산',
  UsageHistory: '사용 기록 통계',
  SubscriptionLeave: '가입/탈퇴 통계',
  FreightUse: '화물 조건별 이용',
  DriverApp: '차주 앱 화면 이용',
  Testimonials: '회원 평가',
  DriverMonthUse: '차주 월간 이용내역',
  NoticeManagement: '공지사항',
  EventManagement: '이벤트',
  FAQManagement: 'FAQ',
  VOCManagement: 'VOC',
  AdminManagement: '시스템 관리',
  ManagerUserList: '계정 관리',
  MapUpload: 'MAP Upload',

  //buttom
  AgreementButton: '동의',
  BeforeButton: '이전',
  NextButton: '다음',
  LoginButtonMove: '로그인 화면으로',

  //login text
  AgreeTermsConditions: '약관 동의',
  AgreeTermsConditionsDetail: '주선사 계정 서비스 약관에 동의해 주세요',
  AgreeCheck: '모두 동의 합니다.',
  AgreeCheckContent:
    '주선사 계정 약관, 개인정보 처리 방침,프로모션 정보 수집(선택)에 <br> 모두 동의합니다.<br>',
  ContractorTermsConditions: '[필수] 주선사 계정 약관',
  PrivacyTermsConditions: '[필수] 개인 정보 처리 방침',
  PromotionTermsConditions: '[선택] 프로모션 정보 수집',
  Privacy: '개인 정보',
  PrivacyContent: '추선사 웹에 사용할 계정을 등록해 주세요.',
  BusinessInfo: '사업자 정보',
  BusinessInfoContent: '사업자 정보를 등록해주세요.',
  AuditDocReg: '심사 서류 등록',
  AuditDocRegContent:
    '주선사 등록 위해 다음 서류를 미리 준비해 주세요.<br>파일 업로드 제한 : JPEG / PNG / PDF / BMP / GIF (최대 10MB)',
  AmountReg: '계좌 정보 등록',
  AmountRegContent: '입력하신 계좌 정보는 수수료 지급에 이용됩니다.',
  RegTitle: '심사 요청 완료',
  RegTitleContent:
    '등록하신 모든 정보는 암호화 처리되어 안전하게 관리되며,<br>심사 결과는 영업일 기준 24시간 이내 문자를 통해 알려드립니다. ',

  //common
  NameString: '이름',
  MobileString: '전화번호',
  BirthdayString: '생년원일',
  IdString: '아이디',
  PasswordString: '비밀번호',
  ConfirmPassWordString: '비밀번호 재확인',
  CompanyRegNum: '사업자 등록 번호',
  CompanyName: '상호명',
  CompanyOwner: '대표자명',
  CompanyLicenseNum: '주선면허번호',
  CompanyBaseAddress: '사업장 주소',
  CompanyDetailAddress: '사업장 상세주소',
  CompanyNum: '사업장 번호',
  Email: '이메일',

  //약관 내용 - 샘플
  PrivacyTermsConditionsContents: `주식회사 회사(이하 “회사”)는 정보 통신망 이용 촉진 및 정보 보호 등에
  관한 법률, 개인 정보 보호법, 통신 비밀 보호법, 전기 통신 사업법 등 정보
  통신 서비스 제공자가 준수 하여야 할 관련 법령상의 개인 정보 보호 규정을
  준수하며, 관련 법령에 의거한 개인 정보 처리 방침을 정하여 이용자 권익
  보호에 최선을 다하고 있습니다.
  <br />
  <br />
  “회사”가 운영하고 있는 서비스 로고 서비스(이하 “서비스”)에서 취급 하는
  모든 고객의 개인 정보는 관련 법령에 근거하거나 정보 주체의 동의에 의하여
  수집•보유 및 처리 되고 있습니다. 본 개인 정보 처리 방침은 “회사”가
  제공하는 “서비스” (모바일 웹/앱 포함) 이용에 적용되며 다음과 같은 내용을
  담고 있습니다.
  <br />
  <br />
  제 1 조 [개인정보의 수집 및 처리목적]
  <br />
  <br />
  회사는 다음의 목적을 위해 개인정보를 수집•처리합니다. 처리하고 있는
  개인정보는 다음의 목적이외의 용도로 이용되지 않으며, 이용 목적이
  변경되는 경우에는 개인 정보 보호법 제18조에 따라 별도의 동의 절차를 통해
  변경됩니다.
  <br />
  <br />
  회원등록 및 관리
  <br />
  <br />
  고객 가입의사 확인, 유료 서비스 제공에 따른 본인확인, 개인식별, 불량고객의
  부정이용 방지와 비인가 사용방지, 법정 대리인 본인 확인, 분쟁 조정을 위한
  기록 보존, 고객 불만처리 등 민원 처리, 고지 사항 전달 등을 목적으로
  개인정보를 처리합니다. 정보이용 계약이행 및 서비스 제공에 따른 요금정산
  금융거래 본인 인증 및 요금결제, 물품운송 또는 청구서 등 발송, 서비스 및
  콘텐츠 제공, 연령 인증, 요금 추심, 정산 등을 목적으로 개인정보를
  처리합니다.`,
}
