var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex-start-contents" },
    [
      _c(
        "div",
        {
          staticClass: "font-size16 surface500 my-1",
          staticStyle: { width: "30%" },
        },
        [_vm._v(" " + _vm._s(_vm.title) + " ")]
      ),
      _c("v-divider", { staticClass: "my-2 mr-4", attrs: { vertical: "" } }),
      _vm.isSocial
        ? _c(
            "div",
            {
              staticClass: "font-size16 surface800 my-1",
              staticStyle: { width: "70%" },
            },
            [
              _c(
                "div",
                { staticClass: "d-flex" },
                _vm._l(_vm.socialArray, function (info) {
                  return _c(
                    "div",
                    { staticClass: "mr-2" },
                    [
                      _c("v-avatar", { attrs: { size: "24px" } }, [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/icons/social/" +
                              info +
                              ".svg"),
                            height: "100%",
                          },
                        }),
                      ]),
                    ],
                    1
                  )
                }),
                0
              ),
            ]
          )
        : _c(
            "div",
            {
              staticClass: "font-size16 surface800 my-1",
              staticStyle: { width: "70%" },
            },
            [_vm._v(" " + _vm._s(_vm.content) + " ")]
          ),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }