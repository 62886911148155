













































import { Vue, Component, Prop, Ref, Watch } from 'vue-property-decorator'
import { mdiWindowClose } from '@mdi/js'
import SearchAddress from '@/views/utils/SearchAddress.vue'
import AdminService from '@/services-itgo/admin.service'


@Component({
  components: { SearchAddress },
})
export default class ManagerStatusChangedPopup extends Vue {
  @Prop(Boolean) value
  @Prop(String) openPopupType
  @Prop(Object) preUserInfo

  @Ref() reasonRef

  icons: any = {
    mdiWindowClose,
  }

  @Watch('value')
  resetReason() {
    this.reason = ''
  }

  reason = ''

  public rules: any = {
    reasonMessage: [(v) => !!v || '사유를 입력하세요'],
  }

  get titleString(){
    console.log(this.openPopupType)
    if(this.openPopupType === "activated")
      return "계정 활성화"
    else if(this.openPopupType === "disActivated")
      return "계정 비활성화"
    else
      return "계정 삭제"
  }

  get reasonLength(){
    return this.reason.length
  }

  async onClickStatusChanged() {
    try {
      if(this.openPopupType === "activated" || this.openPopupType === "disActivated"){
        const body: any = {
          userId: this.preUserInfo.userId,
          onYn: this.openPopupType === "activated" ? `N` : 'Y',
          reason : this.reason
        }

        console.log(body)

        await AdminService.setInactiveManagerUser(body)

        await this.$toast({
          type: 'notice',
          content: this.openPopupType === "activated"
            ? `계정이 활성화 되었습니다.`
            : `계정이 비활성화 되었습니다.`,
        })
        this.$emit('close', true)
      }
      else{
        const body: any = {
          userId: this.preUserInfo.userId,
          reason : this.reason
        }
        await AdminService.setDeletedManagerUser(this.preUserInfo.userId, body)

        await this.$toast({
          type: 'error',
          content: `계정이 삭제 되었습니다.`,
        })
        this.$emit('close', true)
      }
    } catch (error: any) {
        await this.$alert({
          title: `계정 변경 실패`,
          content: error.response.data.errMsg,
        })
    }
  }

  close() {
    this.$emit('close', false)
  }
}
