var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.canViewVerticalNavMenuHeader(_vm.item)
    ? _c(
        "v-subheader",
        [
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    !_vm.menuIsVerticalNavMini ||
                    (_vm.menuIsVerticalNavMini && _vm.isMouseHovered),
                  expression:
                    "\n      !menuIsVerticalNavMini || (menuIsVerticalNavMini && isMouseHovered)\n    ",
                },
              ],
              staticClass: "title-wrapper",
              class: {
                "no-style": _vm.menuIsVerticalNavMini && !_vm.isMouseHovered,
              },
            },
            [_c("span", [_vm._v(_vm._s(_vm.t(_vm.item.subheader)))])]
          ),
          _c(
            "v-icon",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.menuIsVerticalNavMini && !_vm.isMouseHovered,
                  expression: "menuIsVerticalNavMini && !isMouseHovered",
                },
              ],
            },
            [_vm._v(" " + _vm._s(_vm.icons.mdiMinus) + " ")]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }