







































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { mdiWindowClose } from '@mdi/js'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'

@Component({
  components: {
    PerfectScrollbar,
  },
})
export default class LoadPopup extends Vue {
  @Prop({ type: Number, default: 0 }) count
  @Prop({ type: Array }) temporaryItems

  icons: any = {
    mdiWindowClose,
  }

  title = ''
  content = ''

  perfectScrollbarOptions: any = {
    maxScrollbarLength: 30,
    wheelPropagation: false,
  }

  clearText() {
    this.title = ''
    this.content = ''
  }

  resetItems() {
    this.temporaryItems.forEach((item) => {
      this.$set(item, 'checked', false)
    })
  }

  async cancelBtn() {
    this.resetItems()
    this.$emit('close')
  }

  async deleteBtn() {
    const selectedList = this.temporaryItems.filter((x) => x.checked)
    const res = await this.$confirm({
      title: '임시 저장 삭제',
      content: '삭제하면 복구할 수 없습니다. 삭제하시겠습니까?',
    })
    if (res) {
      this.$emit('delete', selectedList)
    }
  }
  async loadBtn() {
    const selectedList = this.temporaryItems.find((x) => x.checked)
    this.$emit('close')
    this.$emit('load', selectedList)
  }

  handleCheckboxClick(checkedItem) {
    this.temporaryItems.forEach((item) => {
      if (item !== checkedItem) {
        this.$set(item, 'checked', false)
      }
    })
  }

  get isChangeCheck() {
    return this.temporaryItems.some((item) => item.checked)
  }
}
