import axios from 'axios'

class CallService {
  public apiUrl: string

  constructor() {
    this.apiUrl = '/call'
  }

  /**
   * 단일 운행의 이동한 경로, 예정 경로 통합 조회 API
   */
  getTrackRoute(params) {
    return axios
      .get(this.apiUrl + `/track-route`, {params})
      .then((res) => res.data)
  }

  /**
   * 인수증 파일 가져 오는 API
   */
  getInvoiceFile(params) {
    return axios
      .get(this.apiUrl + '/file', {
        responseType: 'blob',
        params: params,
      })
      .then((res) => res.data)
  }
}

export default new CallService()
