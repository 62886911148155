var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: _vm.width,
        height: _vm.height,
        viewBox: _vm.viewBox,
      },
    },
    [
      _c(
        "g",
        { attrs: { id: "ic_record", transform: "translate(417 -317)" } },
        [
          _c(
            "g",
            { attrs: { id: "mail_open", transform: "translate(-416 318)" } },
            [
              _c("path", {
                attrs: {
                  id: "패스_29447",
                  "data-name": "패스 29447",
                  d: "M15.6,7.124H8V6h7.6Z",
                  transform: "translate(1.698 0.5)",
                  fill: _vm.iconColor,
                  stroke: _vm.iconColor,
                  "stroke-width": "0.5",
                  "fill-rule": "evenodd",
                },
              }),
              _c("path", {
                attrs: {
                  id: "패스_29448",
                  "data-name": "패스 29448",
                  d: "M12.806,9.12H8V8h4.806Z",
                  transform: "translate(1.698 1.767)",
                  fill: _vm.iconColor,
                  stroke: _vm.iconColor,
                  "stroke-width": "0.5",
                  "fill-rule": "evenodd",
                },
              }),
              _c("path", {
                attrs: {
                  id: "패스_29449",
                  "data-name": "패스 29449",
                  d: "M3,8.971V22.25a1.167,1.167,0,0,0,1.167,1.167H22.833A1.167,1.167,0,0,0,24,22.25V8.971a1.167,1.167,0,0,0-.645-1.044L20.5,6.5V7.8l2.333,1.167v.964L13.5,14.083,4.167,9.935V8.971L6.5,7.8V6.5L3.645,7.928A1.167,1.167,0,0,0,3,8.971ZM13.5,15.36l9.333-4.148V22.25H4.167V11.212Z",
                  transform: "translate(0 0.583)",
                  fill: _vm.iconColor,
                  stroke: _vm.iconColor,
                  "stroke-width": "0.5",
                  "fill-rule": "evenodd",
                },
              }),
              _c("path", {
                attrs: {
                  id: "패스_29450",
                  "data-name": "패스 29450",
                  d: "M7.167,4.167H18.833v7.907L20,11.556V4.167A1.167,1.167,0,0,0,18.833,3H7.167A1.167,1.167,0,0,0,6,4.167v7.389l1.167.519Z",
                  transform: "translate(0.5)",
                  fill: _vm.iconColor,
                  stroke: _vm.iconColor,
                  "stroke-width": "0.5",
                },
              }),
            ]
          ),
          _c("rect", {
            attrs: {
              id: "사각형_31280",
              "data-name": "사각형 31280",
              width: "28",
              height: "28",
              transform: "translate(-417 317)",
              fill: "none",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }