


























































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import ExternalService from '@/services-itgo/external.service'
import { mdiMapMarkerOutline, mdiWindowClose, mdiMagnify } from '@mdi/js'
import _ from 'lodash'

@Component
export default class SearchAddress extends Vue {
  @Prop(Object) data

  icons: any = { mdiMapMarkerOutline, mdiWindowClose, mdiMagnify }

  // TODO: 기획확인필요 > 검색어 삭제 시 아래 리스트 제거되어야 하는지
  // @Watch('searchText', { deep: true })
  // watchAddressSearchText(text) {
  //   if (_.isNil(text)) {
  //     this.addressItems = []
  //   }
  // }

  searchText: string = ''
  addressItems: any = []

  addAddressSubno(item) {
    return (
      item.adminarea +
      ' ' +
      item.subadminarea +
      ' ' +
      item.locality +
      ' ' +
      item.subno
    )
  }

  onSearch() {
    console.log('onSearch :: searchText', this.searchText)

    if (!this.searchText) return

    this.addressItems = []

    ExternalService.extSearch(null, null, this.searchText, null)
      .then((addressDetail) => {
        this.addressItems = addressDetail.search
        console.log('this.addressItems', this.addressItems)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  resetData() {
    this.searchText = ''
    this.addressItems = []
  }

  onClickAddress(item) {
    console.log('onClickAddress', item)

    this.$emit('selectAddress', this.addAddressSubno(item))
    this.resetData()

    // const point = _.get(item, 'entrance')
    // console.log('point', point)

    // // TODO: 아래 로직 왜 있는지 확인 필요? item 이랑 response 데이터 똑같아 보임
    // ExternalService.extSearchCoord(point[0], point[1]).then((addressDetail) => {
    //   console.log('addressDetail', addressDetail)
    //   const addressInfo = _.get(addressDetail, 'searchcoord')
    //   console.log(addressInfo)
    //   console.log(addressInfo[0])
    //   this.$emit('selectAddress', addressInfo[0])
    // })
  }

  close() {
    this.$emit('close')
    this.resetData()
  }
}
