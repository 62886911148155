import NoticeService from '@/services-itgo/notice.service'

export = (() => {
  const _FCM_STATUS: any = {

    USER_REQUEST: 'T0128', // 가입 요청 // 가입이 완료 되지 않은 모든 가입자들에 대한 정보 (요청, 재요청시)
    USER_DENIED: 'T0114', // 가입 심사 반려
    USER_APPROVAL: 'T0115', //가입 심사 승인
    USER_REREQUEST: 'T0129', // 가입 완료 후 서류 변경 요청 알림

    VEHICLE_APPROVAL: 'T0126', // 차량 변경 승인
    VEHICLE_DENIED: 'T0127', // 차량 변경 승인

    BROKER_APPROVAL: 'T0150', // 주선허가증 변경 승인
    BROKER_DENIED: 'T0151', // 주선허가증 변경 반려

    NOTICE: 'T0408', // 공지 사항
    EVENT: 'T0409', // 이벤트

    ASSIGNABLE: 'T0401', // 신규화물추가 + 배차대기
    ASSIGN_REQUEST: 'T0414', // 배차요청
    ASSIGNED: 'T0423', // 배차완료

    TRANSPORTING: 'T0418', // 운송시작
    ARRIVED_PICKUP: 'T0419', // 상차지도착
    PICKED_UP: 'T0420', // 상차완료
    ARRIVED_WAYPOINT: 'T0422', // 경유지도착
    LEFT_WAYPOINT: 'T0432', // 경유완료
    ARRIVED_DROPOFF: 'T0404', // 하차지도착
    DROPPED_OFF: 'T0421', // 하차완료
    PICKUP_RETURNED: 'T0433', // 상차지복귀완료

    INVOICE_REGISTERED: 'T0416', // 인수증 승인 요청
    INVOICE_APPROVE: 'T0424', // 인수증 승인 완료
    INVOICE_REJECT: 'T0435', // 인수증 반려 완료

    CANCEL: 'T0425', // 배차취소
    FREIGHT_SHOW: 'T0430', // 화물 비공개 → 공개
    FREIGHT_HIDDEN: 'T0431', // 화물 공개 → 비공개

    CANCEL_APPROVE: 'T0402', // 화물 취소 요청 승인
    CANCEL_REJECT: 'T0403', // 화물 취소 요청 거절
    CHANGE_APPROVE: 'T0405', // 화물 변경 요청 승인
    CHANGE_REJECT: 'T0415', // 화물 변경 요청 거절

    COMPLETE_BY_ADMIN: 'T0406', // 관리자에 의한 운송 완료
    CANCEL_BY_ADMIN: 'T0407', // 관리자에 의한 운송 취소
    HOLDING_BY_ADMIN: 'T0410', // 관리자에 의한 운송 보류
    CANCELLED_BY_PICKUP_SCHEDULE: 'T0411', // 시스템에 의한 미수주 화물 취소
    COMPLETED_BY_DRIVER: 'T0417', // 차주의 운송료 수금 확인 (TODO: 확인 필요)

    DUPLICATE_LOGIN: 'T0500', // 중복 로그인
  }

  Object.freeze(_FCM_STATUS)

  const _USER_EXAMINE_WEB_NAME: Map<string, string> = new Map<
    string,
    string
  >([
    [_FCM_STATUS.USER_REQUEST, '새로운 심사 요청이 있습니다.'],
    [_FCM_STATUS.USER_REREQUEST, '서류 변경 심사 요청이 있습니다.'],
  ])

  const _FCM_STATUS_ALARM_WEB_NAME: Map<string, string> = new Map<
    string,
    string
  >([
    [_FCM_STATUS.ASSIGN_REQUEST, '새로운 배차 요청이 있습니다.'],
    [_FCM_STATUS.INVOICE_REGISTERED, '인수증 등록 요청이 있습니다.'],
    [_FCM_STATUS.CANCEL_APPROVE, '배차 취소 요청이 승인 되었습니다.'],
    [_FCM_STATUS.CANCEL_REJECT, '배차 취소 요청이 거절 되었습니다.'],
    [_FCM_STATUS.CHANGE_APPROVE, '화물 변경 요청이 승인 되었습니다.'],
    [_FCM_STATUS.CHANGE_REJECT, '화물 변경 요청이 거절 되었습니다.'],
    [
      _FCM_STATUS.COMPLETE_BY_ADMIN,
      '관리자에 의해서 완료 처리된 화물이 있습니다.',
    ],
    [
      _FCM_STATUS.CANCEL_BY_ADMIN,
      '관리자에 의해서 취소 처리된 화물이 있습니다.',
    ],
    [
      _FCM_STATUS.HOLDING_BY_ADMIN,
      '관리자에 의해서 보류 처리된 화물이 있습니다.',
    ],
    [
      _FCM_STATUS.CANCELLED_BY_PICKUP_SCHEDULE,
      '시스템에 의한 미수주 화물 취소',
    ],
    [_FCM_STATUS.NOTICE, '새로운 공지사항이 있습니다.'],
    [_FCM_STATUS.EVENT, '새로운 이벤트가 있습니다.'],
  ])

  return Object.freeze({

    USER_REQUEST : _FCM_STATUS.USER_REQUEST,
    USER_DENIED : _FCM_STATUS.USER_DENIED,
    USER_APPROVAL : _FCM_STATUS.USER_APPROVAL,
    USER_REREQUEST : _FCM_STATUS.USER_REREQUEST,

    VEHICLE_APPROVAL : _FCM_STATUS.VEHICLE_APPROVAL,
    VEHICLE_DENIED : _FCM_STATUS.VEHICLE_DENIED,

    BROKER_APPROVAL : _FCM_STATUS.BROKER_APPROVAL,
    BROKER_DENIED : _FCM_STATUS.BROKER_DENIED,

    ASSIGNABLE: _FCM_STATUS.ASSIGNABLE,
    NOTICE: _FCM_STATUS.NOTICE,
    EVENT: _FCM_STATUS.EVENT,
    ASSIGN_REQUEST: _FCM_STATUS.ASSIGN_REQUEST,
    ASSIGNED: _FCM_STATUS.ASSIGNED,
    TRANSPORTING: _FCM_STATUS.TRANSPORTING,
    ARRIVED_PICKUP: _FCM_STATUS.ARRIVED_PICKUP,
    PICKED_UP: _FCM_STATUS.PICKED_UP,
    ARRIVED_WAYPOINT: _FCM_STATUS.ARRIVED_WAYPOINT,
    ARRIVED_DROPOFF: _FCM_STATUS.ARRIVED_DROPOFF,
    DROPPED_OFF: _FCM_STATUS.DROPPED_OFF,
    INVOICE_REGISTERED: _FCM_STATUS.INVOICE_REGISTERED,
    INVOICE_APPROVE: _FCM_STATUS.INVOICE_APPROVE,
    INVOICE_REJECT: _FCM_STATUS.INVOICE_REJECT,
    CANCEL: _FCM_STATUS.CANCEL,
    FREIGHT_SHOW: _FCM_STATUS.FREIGHT_SHOW,
    FREIGHT_HIDDEN: _FCM_STATUS.FREIGHT_HIDDEN,
    CANCEL_APPROVE: _FCM_STATUS.CANCEL_APPROVE,
    CANCEL_REJECT: _FCM_STATUS.CANCEL_REJECT,
    CHANGE_APPROVE: _FCM_STATUS.CHANGE_APPROVE,
    CHANGE_REJECT: _FCM_STATUS.CHANGE_REJECT,
    COMPLETE_BY_ADMIN: _FCM_STATUS.COMPLETE_BY_ADMIN,
    CANCEL_BY_ADMIN: _FCM_STATUS.CANCEL_BY_ADMIN,
    HOLDING_BY_ADMIN: _FCM_STATUS.HOLDING_BY_ADMIN,
    CANCELLED_BY_PICKUP_SCHEDULE: _FCM_STATUS.CANCELLED_BY_PICKUP_SCHEDULE,
    COMPLETED_BY_DRIVER: _FCM_STATUS.COMPLETED_BY_DRIVER,
    DUPLICATE_LOGIN: _FCM_STATUS.DUPLICATE_LOGIN,

    CALL_ITEMS: [
      _FCM_STATUS.ASSIGNABLE,
      _FCM_STATUS.ASSIGN_REQUEST,
      _FCM_STATUS.ASSIGNED,
      _FCM_STATUS.TRANSPORTING,
      _FCM_STATUS.ARRIVED_PICKUP,
      _FCM_STATUS.PICKED_UP,
      _FCM_STATUS.ARRIVED_WAYPOINT,
      _FCM_STATUS.DROPPED_OFF,
      _FCM_STATUS.INVOICE_REGISTERED,
      _FCM_STATUS.INVOICE_APPROVE,
      _FCM_STATUS.INVOICE_REJECT,
      _FCM_STATUS.FREIGHT_SHOW,
      _FCM_STATUS.FREIGHT_HIDDEN,
      _FCM_STATUS.CANCEL_APPROVE,
      _FCM_STATUS.CANCEL_REJECT,
      _FCM_STATUS.CHANGE_APPROVE,
      _FCM_STATUS.CHANGE_REJECT,
      _FCM_STATUS.COMPLETE_BY_ADMIN,
      _FCM_STATUS.CANCEL_BY_ADMIN,
      _FCM_STATUS.HOLDING_BY_ADMIN,
      _FCM_STATUS.CANCELLED_BY_PICKUP_SCHEDULE,
      _FCM_STATUS.COMPLETED_BY_DRIVER,
    ],

    ALARM_LIST: (): string[] => [
      _FCM_STATUS.ASSIGN_REQUEST,
      _FCM_STATUS.INVOICE_REGISTERED,
      _FCM_STATUS.CANCEL_APPROVE,
      _FCM_STATUS.CANCEL_REJECT,
      _FCM_STATUS.CHANGE_APPROVE,
      _FCM_STATUS.CHANGE_REJECT,
      _FCM_STATUS.COMPLETE_BY_ADMIN,
      _FCM_STATUS.CANCEL_BY_ADMIN,
      _FCM_STATUS.HOLDING_BY_ADMIN,
      _FCM_STATUS.NOTICE,
      _FCM_STATUS.EVENT,
    ],

    ALARM_ACTIVE_LIST: (): string[] => [
      _FCM_STATUS.ASSIGN_REQUEST,
      _FCM_STATUS.INVOICE_REGISTERED,
      _FCM_STATUS.CANCEL_APPROVE,
      _FCM_STATUS.CANCEL_REJECT,
      _FCM_STATUS.CHANGE_APPROVE,
      _FCM_STATUS.CHANGE_REJECT,
      _FCM_STATUS.COMPLETE_BY_ADMIN,
      _FCM_STATUS.CANCEL_BY_ADMIN,
      _FCM_STATUS.HOLDING_BY_ADMIN,
    ],

    ALARM_NOTIFICATION_LIST: (): string[] => [
      _FCM_STATUS.NOTICE,
      _FCM_STATUS.EVENT,
    ],

    REQUEST_VIEW_LIST: (): string[] => [
      _FCM_STATUS.ASSIGN_REQUEST,
      _FCM_STATUS.CANCEL_APPROVE,
      _FCM_STATUS.CANCEL_REJECT,
      _FCM_STATUS.CHANGE_APPROVE,
      _FCM_STATUS.CHANGE_REJECT,
      _FCM_STATUS.COMPLETE_BY_ADMIN,
      _FCM_STATUS.CANCEL_BY_ADMIN,
      _FCM_STATUS.HOLDING_BY_ADMIN,
    ],

    ALARM_ADMIN_NOTIFICATION_LIST: (): string[] => [
      _FCM_STATUS.USER_REQUEST,
      _FCM_STATUS.USER_REREQUEST,
    ],

    ALARM_LIST_STRING: (topicType: string): string => {
      const listTitle: string | undefined =
        _USER_EXAMINE_WEB_NAME.get(topicType)
      return listTitle ? listTitle : '-'
    },

  })
})()
