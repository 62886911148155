import appConfigStoreModule from '@/@core/@app-config/appConfigStoreModule'

import Vue from 'vue'
import Vuex from 'vuex'
import app from './app'
/**
 * 참조 : https://www.npmjs.com/package/vuex-persistedstate
 */
import createPersistedState from 'vuex-persistedstate'

/**
 * Local Storage 데이터 암호화
 * 참조 : https://github.com/softvar/secure-ls
 */
import SecureLS from 'secure-ls'

import { auth } from './auth.module'
import { user } from './user.module'
import { notifications } from './notifications.module'
import { popup } from './popup.module'
import { serverConfig } from './server.config.module'
import { pageFilter } from './page.filter.modules'

Vue.use(Vuex)

const ls = new SecureLS({ isCompression: false })

export default new Vuex.Store({
  modules: {
    auth,
    user,
    notifications,
    pageFilter,

    //materio store
    appConfig: appConfigStoreModule,
    app,
    popup,
    serverConfig,
  },
  plugins: [
    // LocalStorage 에 Store Module 정보 저장
    createPersistedState({
      //            paths: ['auth', 'call_request','call', 'driver', 'user', 'report', 'notifications'],
      paths: ['auth', 'user', 'notifications', 'serverConfig'],
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
})
