var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "50",
        height: "60",
        viewBox: "0 0 50 60",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M36 0H4C1.79086 0 0 1.79086 0 4V46C0 48.2091 1.79086 50 4 50H36C38.2091 50 40 48.2091 40 46V4C40 1.79086 38.2091 0 36 0Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M36 1H4C2.34315 1 1 2.34315 1 4V46C1 47.6569 2.34315 49 4 49H36C37.6569 49 39 47.6569 39 46V4C39 2.34315 37.6569 1 36 1Z",
          stroke: "#707070",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
      _c("path", {
        attrs: { d: "M6.01807 9.1875H33.9829H6.01807Z", fill: "white" },
      }),
      _c("path", {
        attrs: {
          d: "M6.01807 9.1875H33.9829",
          stroke: "#707070",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
      _c("path", {
        attrs: { d: "M6.01807 16.9863H33.9829H6.01807Z", fill: "white" },
      }),
      _c("path", {
        attrs: {
          d: "M6.01807 16.9863H33.9829",
          stroke: "#707070",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
      _c("path", {
        attrs: { d: "M6.01807 24.7832H33.9829H6.01807Z", fill: "white" },
      }),
      _c("path", {
        attrs: {
          d: "M6.01807 24.7832H33.9829",
          stroke: "#707070",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
      _c("path", {
        attrs: { d: "M6.01807 32.5811H22.7358H6.01807Z", fill: "white" },
      }),
      _c("path", {
        attrs: {
          d: "M6.01807 32.5811H22.7358",
          stroke: "#707070",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
      _c("path", {
        attrs: { d: "M6.01904 40.3789H18.27H6.01904Z", fill: "white" },
      }),
      _c("path", {
        attrs: {
          d: "M6.01904 40.3789H18.27",
          stroke: "#707070",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M35.5 60C43.5081 60 50 53.5081 50 45.5C50 37.4919 43.5081 31 35.5 31C27.4919 31 21 37.4919 21 45.5C21 53.5081 27.4919 60 35.5 60Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M35.5 59C42.9558 59 49 52.9558 49 45.5C49 38.0442 42.9558 32 35.5 32C28.0442 32 22 38.0442 22 45.5C22 52.9558 28.0442 59 35.5 59Z",
          stroke: "#707070",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M29.7231 39.7227L41.2773 51.2768L29.7231 39.7227Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M29.7231 39.7227L41.2773 51.2768",
          stroke: "#707070",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M41.2769 39.7227L29.7227 51.2768L41.2769 39.7227Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M41.2769 39.7227L29.7227 51.2768",
          stroke: "#707070",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }