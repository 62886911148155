import axios from 'axios'

class CallHistoryService {
  public apiUrl: string

  constructor() {
    this.apiUrl = '/call-history'
  }

  /**
   * 화물 상세 이력 조회
   */
  // getCallHistory(id) {
  //   return axios.get(this.apiUrl + `/${id}`).then((res) => res.data)
  // }

  /**
   * 화물 상세 이력 조회
   */
  getCallHistoryWithStatus(id) {
    const params = { callRequestId : id }
    return axios
      .get(this.apiUrl + `/call-request`, {params})
      .then((res) => res.data)
  }
}

export default new CallHistoryService()
