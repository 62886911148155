var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: _vm.width,
        height: _vm.height,
        viewBox: _vm.viewBox,
      },
    },
    [
      _c(
        "g",
        {
          attrs: {
            id: "그룹_40430",
            "data-name": "그룹 40430",
            transform: "translate(-45 -492)",
          },
        },
        [
          _c(
            "g",
            {
              attrs: { id: "payment", transform: "translate(48.571 498.134)" },
            },
            [
              _c("path", {
                attrs: {
                  id: "패스_29329",
                  "data-name": "패스 29329",
                  d: "M13.907,13.272H5V12h8.907Z",
                  transform: "translate(-1.328 -4.365)",
                  fill: _vm.iconColor,
                  stroke: _vm.iconColor,
                  "stroke-width": "0.5",
                  "fill-rule": "evenodd",
                },
              }),
              _c("path", {
                attrs: {
                  id: "패스_29330",
                  "data-name": "패스 29330",
                  d: "M11.362,15.272H5V14h6.362Z",
                  transform: "translate(-1.328 -3.184)",
                  fill: _vm.iconColor,
                  stroke: _vm.iconColor,
                  "stroke-width": "0.5",
                  "fill-rule": "evenodd",
                },
              }),
              _c("path", {
                attrs: {
                  id: "패스_29331",
                  "data-name": "패스 29331",
                  d: "M3,7.311A1.242,1.242,0,0,1,4.159,6H22.7a1.242,1.242,0,0,1,1.159,1.311v13.11A1.242,1.242,0,0,1,22.7,21.732H4.159A1.242,1.242,0,0,1,3,20.421Zm1.159,0H22.7V9.933H4.159ZM22.7,11.244v9.177H4.159V11.244Z",
                  transform: "translate(-3 -6)",
                  fill: _vm.iconColor,
                  stroke: _vm.iconColor,
                  "stroke-width": "0.5",
                  "fill-rule": "evenodd",
                },
              }),
            ]
          ),
          _c("rect", {
            attrs: {
              id: "사각형_31206",
              "data-name": "사각형 31206",
              width: "28",
              height: "28",
              transform: "translate(45 492)",
              fill: "none",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }