class StringUtils {
  setAddressCombineStr(address, addressDetail, addressName = null) {
    let addressStr: string = ''
    if (addressDetail) {
      addressStr = address + ' ' + addressDetail
    } else {
      addressStr = address
    }
    if (addressName) addressStr = addressStr + ' [' + addressName + ']'

    return addressStr
  }


  /**
   * 줄바꿈 기능이 필요하여 추가함.
   * @param content
   */
  formattedContentText(content) {
    return content.replace(/\n/g, '<br>');
  }

  /**
   * 스네이크 -> 카멜 변경하는 함수
   * @param obj
   */
  snakeToCamelCase(obj){
    const camelCaseObj = {};
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const camelKey = key.replace(/([-_][a-z])/ig, ($1) => {
          return $1.toUpperCase()
            .replace('-', '')
            .replace('_', '');
        });
        camelCaseObj[camelKey] = obj[key];
      }
    }
    return camelCaseObj;
  }
}

export default new StringUtils()
