var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-chip", { staticClass: "mr-6", attrs: { outlined: "" } }, [
    _vm._v(" " + _vm._s(_vm.loginUser ? _vm.loginUser.name : "") + " "),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }