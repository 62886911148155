




























































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { mdiWindowClose, mdiMagnify } from '@mdi/js'
import userService from '@/services-itgo/user.service'
import { formatBizno, formatPhoneNumber } from '@/utils/business.util'
import _ from 'lodash'
//' utils/business.util'
@Component({
  components: {},
})
export default class LoadPopup extends Vue {
  @Prop({ type: Number, default: 0 }) count
  @Prop({ type: Array }) temporaryItems

  icons: any = {
    mdiWindowClose,
    mdiMagnify,
  }

  /**
   * 검색 관련
   */
  selectedUserType = null
  userType = [
    { text: '화주', value: 1 },
    { text: '차주', value: 2 },
    { text: '주선사', value: 3 },
    { text: '전체', value: null },
  ]

  keyword = ''
  userList = [] as any
  isEmpty = false

  async fetchUserList() {
    this.isEmpty = false
    const params = {
      keyword: this.keyword,
      userType: this.selectedUserType,
    }
    try {
      console.log(params)
      const data = await userService.getUserList(params)

      if (data.length == 0) this.isEmpty = true

      this.userList = data.map((x) => ({
        checked: false,
        itemList: [
          { text: '상호명', value: x.companyName },
          { text: '사업자번호', value: formatBizno(x.businessRegNum) },
          { text: '이름', value: x.name },
          { text: '전화번호', value: formatPhoneNumber(x.mobile) },
          { text: '이메일', value: x.email },
        ],
        payload: {
          name: x.name,
          mobile: x.mobile,
          email: x.email,
          userId: x.userId,
        },
      }))
    } catch (e: any) {
      await this.$alert({
        title: '회원찾기 에러',
        content: e.response.data.errMsg,
      })
    }
  }

  /**
   *
   */

  clearList() {
    this.keyword = ''
    this.userList = []
  }

  cancelBtn() {
    this.clearList()
    this.$emit('close')
  }

  async registerBtn() {
    const selectedList = this.userList.find((x) => x?.checked)
    if (selectedList?.checked) this.$emit('load', selectedList.payload)
    this.cancelBtn()
  }

  get isRegisterBtn() {
    const checkedCount = _.countBy(this.userList, 'checked')['true'] || 0
    if (checkedCount === 1) return false
    else return true
  }
}
