var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.popup
        ? _c(
            "div",
            [
              _vm.popup.type === "alert"
                ? _c(
                    "Alert",
                    _vm._b(
                      {
                        attrs: { value: "" },
                        on: {
                          close: function ($event) {
                            return _vm.onClose(false)
                          },
                          confirm: function ($event) {
                            return _vm.onClose(true)
                          },
                        },
                      },
                      "Alert",
                      _vm.alertProps,
                      false
                    )
                  )
                : _vm.popup.type === "confirm"
                ? _c(
                    "Confirm",
                    _vm._b(
                      {
                        attrs: { value: "" },
                        on: {
                          close: function ($event) {
                            return _vm.onClose()
                          },
                          cancel: function ($event) {
                            return _vm.onClose(false)
                          },
                          confirm: function ($event) {
                            return _vm.onClose(true)
                          },
                        },
                      },
                      "Confirm",
                      _vm.confirmProps,
                      false
                    )
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c("v-fade-transition", { attrs: { mode: "out-in" } }, [
        _vm.toast
          ? _c(
              "div",
              {
                key: _vm.toast.key,
                staticClass: "toast-wrapper",
                class: _vm.toast.type,
              },
              [
                _c("span", { staticClass: "toast-icon" }, [
                  _c("img", {
                    attrs: {
                      width: "28",
                      src: _vm.getToastIcon(_vm.toast.type),
                    },
                  }),
                ]),
                _c("div", {
                  domProps: { innerHTML: _vm._s(_vm.toast.content) },
                }),
              ]
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }