


























































































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import { State } from 'vuex-class'
import _ from 'lodash'

import Pagination from '@/views/utils/Pagination.vue'
import Vue2DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ko'
import DatetimeUtils from '@/utils/datetime.utils'
import subscribersService from '@/services-itgo/subscribers.service'
import Xlsx from '@/utils/xlsx'

import {
  mdiMagnify,
  mdiChevronDown,
  mdiCircleOutline,
  mdiCircleSlice8,
} from '@mdi/js'

type dateType = 'day' | 'month' | 'year'

@Component({
  components: {
    Pagination,
    Vue2DatePicker,
  },

  mounted() {
    this.dayDate = this.baseDate
    this.selectDateType.dayType = true
    this.fetchSubscriptionList()
  },
})
export default class SubscriptionLeave extends Vue {
  @State((state) => state.auth.user) loginUser

  icons: any = {
    mdiMagnify,
    mdiChevronDown,
    mdiCircleOutline,
    mdiCircleSlice8,
  }

  @Watch('currentTab')
  onCurrentTabChanged() {
    this.pageNum = 1

    this.selectDateType.dayType = true
    this.selectDateType.monthType = false
    this.selectDateType.yearType = false

    this.dayDate = this.baseDate

    this.fetchSubscriptionList()
  }

  @Watch('dayDate', { deep: true })
  @Watch('monthDate')
  @Watch('yearDate')
  onDateChanged() {
    this.selectedDate = new Date()
    // this.fetchSubscriptionList()
  }

  @Watch('dayDate')
  changeDates(value) {
    if (value?.length > 1) {
      this.set(value)
    }
  }

  previousDayDate: Date[] | null = null

  async set(value) {
    const temp = [...this.dayDate]
    temp.sort((a, b) => new Date(a).getTime() - new Date(b).getTime())

    if (temp.length === 2) {
      if (DatetimeUtils.asDays(temp[0], temp[1]) >= 30) {
        this.dayDate = this.previousDayDate || this.baseDate
        await this.$alert({
          title: '기간 설정 오류',
          content: '기간 설정은 최대 30일까지 가능합니다.',
        })
      } else {
        this.previousDayDate = [...this.dayDate]
      }
    }
  }

  selectedDate: Date | null = null

  selectDateType = {
    dayType: false,
    monthType: false,
    yearType: false,
  }

  formatDateString = 'YYYY-MM-DD'

  public currentTab = 0
  public userTypeChoice = { 0: 3, 1: 2, 2: 1 }

  get baseDate() {
    const today = new Date()
    const beforeWeek = new Date(today)
    beforeWeek.setDate(today.getDate() - 29)
    return [beforeWeek, today]
  }

  setDateType: dateType = 'day'
  dayDate : any = [new Date(), new Date()]
  monthDate : any = DatetimeUtils.getMonth(-1)
  yearDate : any = DatetimeUtils.getYear(-1)

  isSearched = false

  onSearch() {
    this.isSearched = true
    this.pageNum = 1
    this.fetchSubscriptionList()
  }

  public tabs = [
    { name: '주선사', label: '주선사' },
    {
      name: '차주',
      label: '차주',
    },
    {
      name: '화주',
      label: '화주',
    },
  ]

  get subscriptionLeaveInfo() {
    return this.tabData[this.currentTab]
  }

  get currentDate() {
    return DatetimeUtils.now_format('YYYY년 MM월 DD일 기준')
  }

  dateTypeSelect(type) {
    switch (type) {
      case 'day':
        if(this.monthDate === null)
          this.monthDate = DatetimeUtils.getMonth(-1)
        if(this.yearDate === null)
          this.yearDate = DatetimeUtils.getYear(-1)
        this.selectDateType.monthType = false
        this.selectDateType.yearType = false
        break
      case 'month':
        if(this.dayDate[0] === null && this.dayDate[1] === null){
          this.dayDate = [new Date(), new Date()]
        }
        if(this.yearDate === null)
          this.yearDate = DatetimeUtils.getYear(-1)
        this.selectDateType.dayType = false
        this.selectDateType.yearType = false
        break
      case 'year':
        if(this.dayDate[0] === null && this.dayDate[1] === null){
          this.dayDate = [new Date(), new Date()]
        }
        if(this.monthDate === null)
          this.monthDate = DatetimeUtils.getMonth(-1)
        this.selectDateType.dayType = false
        this.selectDateType.monthType = false
        break
      default:
        break
    }

    // this.fetchSubscriptionList()
  }

  get summaryTitle() {
    const tabNames = {
      0: '주선사 누적 가입자 수',
      1: '차주 누적 가입자 수',
      2: '화주 누적 가입자 수',
    }
    return tabNames[this.currentTab] || ''
  }

  public tabData = [
    {
      loading: false,
      headers: [
        { text: '날짜', align: 'center', value: 'day' },
        {
          text: '주선사 가입자 수',
          align: 'left',
          value: 'confirmCount',
        },
        {
          text: '주선사 탈퇴자 수',
          align: 'left',
          value: 'withdrawalCount',
        },
      ],
      items: [],
      serverItemsLength: 10,
    },
    {
      loading: false,
      headers: [
        { text: '날짜', align: 'center', value: 'day' },
        {
          text: '차주 가입자 수',
          align: 'left',
          value: 'confirmCount',
        },
        {
          text: '차주 탈퇴자 수',
          align: 'left',
          value: 'withdrawalCount',
        },
      ],
      items: [],
      serverItemsLength: 10,
    },
    {
      loading: false,
      headers: [
        { text: '날짜', align: 'center', value: 'day' },
        {
          text: '화주 가입자 수',
          align: 'left',
          value: 'confirmCount',
        },
        {
          text: '화주 탈퇴자 수',
          align: 'left',
          value: 'withdrawalCount',
        },
      ],
      items: [],
      serverItemsLength: 10,
    },
  ]

  totalPageCount = 0
  pageSizes = [10, 20, 30]
  pageSize = 10
  pageNum = 1

  @Watch('pageSize')
  onChangePageSize() {
    this.pageNum = 1
    this.fetchSubscriptionList()
  }

  @Watch('pageNum')
  onChangePageNum() {
    this.fetchSubscriptionList()
  }

  calculateTotalPageCount() {
    if (!this.selectedDate) return 0

    if (this.selectDateType.monthType || this.selectDateType.yearType) {
      return 1
    }

    if (!this.dayDate[0] || !this.dayDate[1]) {
      return 0
    }

    const startDate = new Date(this.dayDate[0]).getTime()
    const endDate = new Date(this.dayDate[1]).getTime()
    const diffTime = Math.abs(endDate - startDate)
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1

    return Math.ceil(diffDays / this.pageSize) || 1
  }

  userType = null
  dateType = null
  confirmTotal = null

  async fetchSubscriptionList() {
    if (
      (this.selectDateType.dayType && !this.dayDate[0] && !this.dayDate[1]) ||
      (this.selectDateType.monthType && !this.monthDate) ||
      (this.selectDateType.yearType && !this.yearDate)
    ) {
      await this.$alert({
        title: '기간 설정 오류',
        content: '검색 기간을 설정해 주세요.',
      })

      if(this.selectDateType.dayType && !this.dayDate[0] && !this.dayDate[1])
        this.dayDate = [new Date(), new Date()]
      else if(this.selectDateType.monthType && !this.monthDate)
        this.monthDate = DatetimeUtils.getMonth(-1)
      else
        this.yearDate = DatetimeUtils.getYear(-1)

      return
    }

    try {
      this.subscriptionLeaveInfo.loading = true
      this.subscriptionLeaveInfo.items = []

      const userType = this.userTypeChoice[this.currentTab]
      const dateType = this.selectDateType.dayType
        ? 1
        : this.selectDateType.monthType
        ? 2
        : 3

      let fromDt, toDt
      if (this.selectDateType.dayType) {
        fromDt = DatetimeUtils.stringDate(this.dayDate[0], 'YYYY-MM-DD')
        toDt = DatetimeUtils.stringDate(this.dayDate[1], 'YYYY-MM-DD')
      } else if (this.selectDateType.monthType) {
        fromDt = DatetimeUtils.stringDate(this.monthDate, 'YYYY-MM')
        toDt = fromDt
      } else if (this.selectDateType.yearType) {
        fromDt = DatetimeUtils.stringDate(this.yearDate, 'YYYY')
        toDt = fromDt
      }

      const params = {
        userType: userType,
        dateType: dateType,
        fromDt: fromDt,
        toDt: toDt,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      }

      const data = await subscribersService.getUserCount(params)

      const responseTotal = data.confirmTotal
      this.confirmTotal = responseTotal

      this.excelParams = params

      this.totalPageCount = this.calculateTotalPageCount()

      this.subscriptionLeaveInfo.items = data.status
      console.log(data)
    } catch (error: any) {
      this.$alert(error.response.data.errMsg)
    } finally {
      this.subscriptionLeaveInfo.loading = false
    }
  }

  public excelParams: any = null

  async onClickDownloadExcel() {
    try {
      console.log('onClickDownloadExcel')

      const tabNames = ['주선사', '차주', '화주']
      const tabName = tabNames[this.currentTab]

      let dateExcel = ''
      if (this.selectDateType.dayType) {
        dateExcel = `${DatetimeUtils.stringDate(
          this.dayDate[0],
          'YYYY-MM-DD',
        )}~${DatetimeUtils.stringDate(this.dayDate[1], 'YYYY-MM-DD')}`
      } else if (this.selectDateType.monthType) {
        dateExcel = DatetimeUtils.stringDate(this.monthDate, 'YYYY-MM')
      } else if (this.selectDateType.yearType) {
        dateExcel = DatetimeUtils.stringDate(this.yearDate, 'YYYY')
      }

      const nameExcel = `화물잇고_가입탈퇴목록_${tabName}_${dateExcel}.xlsx`

      const data = await subscribersService.getUserExcel(this.excelParams)
      console.log(data)
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      const link = document.createElement('a')
      const blobUrl = URL.createObjectURL(blob)
      link.href = blobUrl
      link.download = nameExcel
      link.click()
      URL.revokeObjectURL(blobUrl)
    } catch (e) {
      await this.$alert({
        title: '엑셀 생성 실패',
        content: e.response.data.errMsg,
      })
    } finally {
    }
  }
}
