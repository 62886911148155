




































































import { Vue, Component } from 'vue-property-decorator'
import { mdiChevronLeft } from '@mdi/js'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import FreightInfoItem from '@/views/freightHistory/FreightInfoItem.vue'
import UserInfoTab from '@/views/management/UserInfoTab.vue'
import FreightHistoryTab from '@/views/management/FreightHistoryTab.vue'
import ServiceFeeTab from '@/views/management/ServiceFeeTab.vue'
import UserStatusTab from '@/views/management/UserStatusTab.vue'

import Freight from '@/views/icons/leftPanel/freight.vue'
import USER_STATUS from '../constant/user.status'
import TermsConditionsHistoryTab from '@/views/management/TermsConditionsHistoryTab.vue'

@Component({
  components: {
    TermsConditionsHistoryTab,
    Freight,
    PerfectScrollbar,
    FreightInfoItem,
    UserInfoTab,
    FreightHistoryTab,
    ServiceFeeTab,
    UserStatusTab,
  },

  mounted() {
    if (!this.$route.params.item) return
    if (this.$route.params.backPath === 'freightInfo') return (this.tab = 1)
  },
})
export default class CallerInfo extends Vue {
  icons = {
    mdiChevronLeft,
  }

  type = USER_STATUS.USER_TYPE_CALLER

  get item(): any {
    return this.$route.params.item
  }

  get id() {
    return this.item?.userId || ''
  }

  get userStatus() {
    return this.item?.status || ''
  }

  get name() {
    return this.item?.driverName || this.item?.name || ''
  }

  get grade() {
    return this.item?.grade || ''
  }

  get count() {
    return this.item?.count || ''
  }

  tab = null
  tabItems = [
    { title: '회원 정보' },
    { title: '운송 이력' },
    { title: '회원 상태' },
    { title: '약관 이력' },
  ]
}
