import axios from 'axios'

class VocService {
    private apiUrl: string
    constructor() {
        this.apiUrl = '/Voc'
    }

    // 4. Voc 목록 조회
    getPageList(params){
        return axios.get(this.apiUrl + `/page`, {params: params}).then((res) => res.data)
    }

    // 6. Voc 유형 목록 조회 
    getCategories(){
        return axios.get(this.apiUrl + `/categories`).then(res=> res.data)
    }

    // 7. Voc 상세 조회
    getVocDetails(params){
        return axios.get(this.apiUrl+`/detail`,{params: params}).then(res=> res.data)
    }

    // 3. Voc 삭제
    deleteVoc(id){
        const params:any = {
            vocIdList : id
        }
        return axios.delete(this.apiUrl, {params:params}).then(res=> res.data)
    }

    // 8. Voc PUSH 발송
    // postAppPush(params){
    //     return axios.post(this.apiUrl+`/app-push`,{
    //         vocId : params.id,
    //         appPushTitle: params.title,
    //         appPushContent: params.content,
    //     }).then(res => res.data)
    // }

    // 9. Voc SMS 발송
    // postSmsPush(params){
    //     return axios.post(this.apiUrl+`/sms-push`,{
    //         vocId : params.id,
    //         smsPushTitle: params.title,
    //         smsPushContent: params.content,
    //     }).then(res => res.data)
    // }

    // 1. Voc 등록
    postRegisterVoc(body){
        return axios.post(this.apiUrl, body).then(res => res.status)
    }

     // 2. Voc 수정
     putEditVoc(body){
        return axios.put(this.apiUrl, body).then(res => res.status)
    }
}

export default new VocService()