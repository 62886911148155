import _ from 'lodash'

/**
 * 차량 옵션 정보
 */
export = (() => {
  const _VEHICLE_OPTION_TYPE: any = {
    NOT: 0, // 없음
    LIFT: 1, // 리프트
    COLD_STORAGE: 2, // 냉장
    FROZEN: 3, // 냉동
    NO_VIBRATION: 4, // 무진동
    EXTRA_LONG: 5, // 초장축
    PLUS: 6, // 플러스
    ROTOR_5T: 7, // 축차(5톤 이상)
    TEMP_HUMIDITY: 8, // 항온항습
  }

  const _VEHICLE_OPTION_TYPE_STRING: any = {
    NOT: '없음',
    LIFT: '리프트',
    COLD_STORAGE: '냉장',
    FROZEN: '냉동',
    NO_VIBRATION: '무진동',
    EXTRA_LONG: '초장축',
    PLUS: '플러스',
    ROTOR_5T: '축차(5톤 이상)',
    TEMP_HUMIDITY: '항온항습',
  }

  const _VEHICLE_OPTION_TEMP: any = {
    ROOM_TEMP: 1,
    COLD_TEMP: 2,
    FROZEN_TEMP: 3,
  }

  const _VEHICLE_OPTION_TEMP_STRING: any = {
    ROOM_TEMP: '상온',
    COLD_TEMP: '냉장 (0~5°C)',
    FROZEN_TEMP: '냉동 (-18°C 이하)',
  }

  Object.freeze(_VEHICLE_OPTION_TYPE)
  Object.freeze(_VEHICLE_OPTION_TYPE_STRING)
  Object.freeze(_VEHICLE_OPTION_TEMP)
  Object.freeze(_VEHICLE_OPTION_TEMP_STRING)

  const _VEHICLE_OPTION_TEMP_TYPE_WEB_NAME: Map<number, string> = new Map<
    number,
    string
  >([
    [_VEHICLE_OPTION_TEMP.ROOM_TEMP, _VEHICLE_OPTION_TEMP_STRING.ROOM_TEMP],
    [_VEHICLE_OPTION_TEMP.COLD_TEMP, _VEHICLE_OPTION_TEMP_STRING.COLD_TEMP],
    [_VEHICLE_OPTION_TEMP.FROZEN_TEMP, _VEHICLE_OPTION_TEMP_STRING.FROZEN_TEMP],
  ])

  const _VEHICLE_OPTION_TYPE_WEB_NAME: Map<number, string> = new Map<
    number,
    string
  >([
    [_VEHICLE_OPTION_TYPE.NOT, _VEHICLE_OPTION_TYPE_STRING.NOT],
    [_VEHICLE_OPTION_TYPE.LIFT, _VEHICLE_OPTION_TYPE_STRING.LIFT],
    [
      _VEHICLE_OPTION_TYPE.COLD_STORAGE,
      _VEHICLE_OPTION_TYPE_STRING.COLD_STORAGE,
    ],
    [_VEHICLE_OPTION_TYPE.FROZEN, _VEHICLE_OPTION_TYPE_STRING.FROZEN],
    [
      _VEHICLE_OPTION_TYPE.NO_VIBRATION,
      _VEHICLE_OPTION_TYPE_STRING.NO_VIBRATION,
    ],
    [_VEHICLE_OPTION_TYPE.EXTRA_LONG, _VEHICLE_OPTION_TYPE_STRING.EXTRA_LONG],
    [_VEHICLE_OPTION_TYPE.PLUS, _VEHICLE_OPTION_TYPE_STRING.PLUS],
    [_VEHICLE_OPTION_TYPE.ROTOR_5T, _VEHICLE_OPTION_TYPE_STRING.ROTOR_5T],
    [
      _VEHICLE_OPTION_TYPE.TEMP_HUMIDITY,
      _VEHICLE_OPTION_TYPE_STRING.TEMP_HUMIDITY,
    ],
  ])

  const _VEHICLE_OPTION_SELECT_LIST = [
    { name: _VEHICLE_OPTION_TYPE_STRING.NOT, value: _VEHICLE_OPTION_TYPE.NOT },
    {
      name: _VEHICLE_OPTION_TYPE_STRING.LIFT,
      value: _VEHICLE_OPTION_TYPE.LIFT,
    },
    {
      name: _VEHICLE_OPTION_TYPE_STRING.COLD_STORAGE,
      value: _VEHICLE_OPTION_TYPE.COLD_STORAGE,
    },
    {
      name: _VEHICLE_OPTION_TYPE_STRING.FROZEN,
      value: _VEHICLE_OPTION_TYPE.FROZEN,
    },
    {
      name: _VEHICLE_OPTION_TYPE_STRING.NO_VIBRATION,
      value: _VEHICLE_OPTION_TYPE.NO_VIBRATION,
    },
    {
      name: _VEHICLE_OPTION_TYPE_STRING.EXTRA_LONG,
      value: _VEHICLE_OPTION_TYPE.EXTRA_LONG,
    },
    {
      name: _VEHICLE_OPTION_TYPE_STRING.PLUS,
      value: _VEHICLE_OPTION_TYPE.PLUS,
    },
    {
      name: _VEHICLE_OPTION_TYPE_STRING.ROTOR_5T,
      value: _VEHICLE_OPTION_TYPE.ROTOR_5T,
    },
    {
      name: _VEHICLE_OPTION_TYPE_STRING.TEMP_HUMIDITY,
      value: _VEHICLE_OPTION_TYPE.TEMP_HUMIDITY,
    },
  ]

  return Object.freeze({
    NOT: _VEHICLE_OPTION_TYPE.NOT,
    HOIST: _VEHICLE_OPTION_TYPE.LIFT,
    COLD_STORAGE: _VEHICLE_OPTION_TYPE.COLD_STORAGE,
    FROZEN: _VEHICLE_OPTION_TYPE.FROZEN,
    NO_VIBRATION: _VEHICLE_OPTION_TYPE.NO_VIBRATION,
    EXTRA_LONG: _VEHICLE_OPTION_TYPE.EXTRA_LONG,
    PLUS: _VEHICLE_OPTION_TYPE.PLUS,
    ROTOR_5T: _VEHICLE_OPTION_TYPE.ROTOR_5T,
    TEMP_HUMIDITY: _VEHICLE_OPTION_TYPE.TEMP_HUMIDITY,

    VEHICLE_OPTION_TOTAL_LIST: _VEHICLE_OPTION_SELECT_LIST,

    VEHICLE_OPTION_NAME_WEB: (vehicleIndex: number): string => {
      const vehicleOptionName: string | undefined =
        _VEHICLE_OPTION_TYPE_WEB_NAME.get(vehicleIndex)
      return vehicleOptionName ? vehicleOptionName : ''
    },

    VEHICLE_OPTION_TEMP_NAME_WEB: (vehicleTempIndex: number): string => {
      const vehicleOptionTempName: string | undefined =
        _VEHICLE_OPTION_TEMP_TYPE_WEB_NAME.get(vehicleTempIndex)
      return vehicleOptionTempName ? vehicleOptionTempName : ''
    },
  })
})()
