





























































import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { mdiMenuUp, mdiMenuDown, mdiMinus } from '@mdi/js'
import DashBoardSimpleTable from '@/views/dashBoards/DashBoardSimpleTable.vue'
import CallRequestService from '@/services-itgo/call.request.service'
import DatetimeUtils from '@/utils/datetime.utils'
import Vue2DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ko'

@Component({
  name: 'BrokerDriverRegRanking',
  components: {
    DashBoardSimpleTable,
    Vue2DatePicker,
  },
})
export default class BrokerDriverRegRanking extends Vue {
  public icons: any = {
    mdiMenuUp,
    mdiMenuDown,
    mdiMinus,
  }

  public brokerRegRankingData: any = []
  public brokerDispatchRankingData: any = []
  fromDtReq: any = null
  toDtReq: any = null

  fromDtDispatch: any = null
  toDtDispatch: any = null
  monthDate = new Date()
  monthDateDispatch = new Date()

  mounted() {
    this.fromDtReq = DatetimeUtils.getStartOfMonth(
      DatetimeUtils.now_format('YYYY-MM'),
    )
    this.toDtReq = DatetimeUtils.getEndOfMonth(
      DatetimeUtils.now_format('YYYY-MM'),
    )

    this.fromDtDispatch = DatetimeUtils.getStartOfMonth(
      DatetimeUtils.now_format('YYYY-MM'),
    )
    this.toDtDispatch = DatetimeUtils.getEndOfMonth(
      DatetimeUtils.now_format('YYYY-MM'),
    )

    this.updateBrokerRankingList(true, true)
  }

  async updateBrokerRankingList(isReg, isDispatch) {
    try {
      if (isReg) {
        const params1 = {
          type: 'new',
          fromDt: this.fromDtReq,
          toDt: this.toDtReq,
        }
        this.brokerRegRankingData =
          await CallRequestService.getBrokerRankingCount(params1)
      }

      if (isDispatch) {
        const params2 = {
          type: 'assigned',
          fromDt: this.fromDtDispatch,
          toDt: this.toDtDispatch,
        }
        this.brokerDispatchRankingData =
          await CallRequestService.getBrokerRankingCount(params2)
      }
    } catch (e) {
      console.log(e.response.data.errMsg)
    }
  }

  regSetInputDate(value) {
    this.fromDtReq = DatetimeUtils.getStartOfMonth(value)
    this.toDtReq = DatetimeUtils.getEndOfMonth(value)
    this.updateBrokerRankingList(true, false)
  }

  dispatchSetInputDate(value) {
    this.fromDtDispatch = DatetimeUtils.getStartOfMonth(value)
    this.toDtDispatch = DatetimeUtils.getEndOfMonth(value)
    this.updateBrokerRankingList(false, true)
  }

  // vue2-date-picker
  notBeforeToday(date) {
    return date > new Date(new Date().setHours(0, 0, 0, 0))
  }
  notBeforeTodayTwelveOClock(date) {
    return date > new Date(new Date().setHours(12, 0, 0, 0))
  }
}
