





































import { Vue, Component } from 'vue-property-decorator'
import DatetimeUtils from '@/utils/datetime.utils'
import LogoLarge from '@/views/icons/logo/LogoLarge.vue'
import configService from '@/services-itgo/config.service'
import _ from 'lodash'

@Component({
  name: 'ServiceCheck',
  components: {
    LogoLarge,
  },
  methods: {
    DatetimeUtils() {
      return DatetimeUtils
    },
  },
})
export default class ServiceCheck extends Vue {
  public serviceTimeString: string = ''
  mounted() {
    this.getServerInspection()
  }

  async getServerInspection() {
    try {
      const result = await configService.getServerInspection()
      console.log(result)
      if (result.unavailable) {
        this.serviceTimeString = result.message
      } else {
        await this.$router.push({ name: 'login' })
      }
    } catch (e) {
    } finally {
    }
  }

  reload() {
    this.$router.go(0)
  }
}
