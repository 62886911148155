import _ from 'lodash'

class MaskingUtil {
  /**
   * 문자열 검색해서 중간 글자 *로 만들기
   * 2글자면 마지막 글자만
   * @param strName
   */
  // maskingName(strName) {
  //   if (strName.length > 2) {
  //     let originName: any = strName.split('')
  //     originName.forEach(function (name, i) {
  //       if (i === 0 || i === originName.length - 1) return
  //       originName[i] = '*'
  //     })
  //     let joinName = originName.join()
  //     return joinName.replace(/,/g, '')
  //   } else {
  //     let pattern: any = /.$/ // 정규식
  //     return strName.replace(pattern, '*')
  //   }
  // }

  maskingName(strName) {
    let originName = strName.split(' ')

    if (originName.length > 1) {
      let firstName = originName[1]
      if (firstName.length > 1) {
        originName[1] = firstName[0] + '*' + firstName.slice(2)
      }
      let joinName = originName.join()
      return joinName.replace(/,/g, '')
    } else {
      if (strName.length > 1) {
        return strName[0] + '*' + strName.slice(2)
      } else {
        let pattern: any = /.$/ // 정규식
        return strName.replace(pattern, '*')
      }
    }
  }

  /**
   * 뒤에서 부터 3글자를 * 처리한다. (ex. 12가3456 → 12가3***)
   * @param strCar
   */
  maskingCar(strCar) {
    if (strCar == undefined || strCar === '') {
      return ''
    }
    const pattern: any = /.{3}$/ // 정규식
    return strCar.replace(pattern, '***')
  }

  /**
   * 핸드폰번호 마스킹처리(010-1111-****)
   * 핸드폰번호 마스킹처리(010-111-****)
   * 정규식 이용.
   */
  maskingPhoneMobile(mobile) {
    let result: string = ''
    if (!_.isNil(mobile)) {
      let mobileStr: object = mobile.split('-')

      if (
        !_.isNil(mobileStr[0]) &&
        !_.isNil(mobileStr[1]) &&
        !_.isNil(mobileStr[2])
      ) {
        result = mobileStr[0] + '-' + mobileStr[1] + '-****'
      } else {
        result = mobile
      }
    }
    return result
  }

  /**
   * 핸드폰번호 마스킹처리(010-****-1111)
   * 핸드폰번호 마스킹처리(010-***-111)
   * 정규식 이용.
   */
  maskingCenterPhoneMobile(mobile) {
    let result: string = ''
    if (!_.isNil(mobile)) {
      let mobileStr: object = mobile.split('-')

      console.log(mobileStr)

      if (
        !_.isNil(mobileStr[0]) &&
        !_.isNil(mobileStr[1]) &&
        !_.isNil(mobileStr[2])
      ) {
        result = mobileStr[0] + '-****' + mobileStr[2]
      } else {
        result = mobile
      }
    }
    console.log(result)
    return result
  }

  /**
   * 사업자 등록 번호 마스킹 처리
   * @param num
   * @param type : 0: 마스킹 / 1: 전체 번호보임
   */
  maskingBizNo(num, type) {
    let formatNum: string = ''
    try {
      if (num.length == 10) {
        if (type == 0) {
          formatNum = num.replace(/(\d{3})(\d{2})(\d{5})/, '*****$3')
        } else {
          formatNum = num.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3')
        }
      }
    } catch (e) {
      formatNum = num
      console.log(e)
    }
    return formatNum
  }

  /**
   * 카드번화,계좌번호 마스킹
   */
  maskingCardBankNumber(bankNumber) {
    const length = bankNumber.length

    if (length <= 7) {
      return bankNumber
    }

    let unmaskedPrefix = bankNumber.substring(0, 3)
    let unmaskedSuffix = bankNumber.substring(length - 4, length)

    let middleLength = length - 7

    let maskedMiddle = '*'.repeat(middleLength)

    return unmaskedPrefix + maskedMiddle + unmaskedSuffix
  }
}

export default new MaskingUtil()
