var logi = logi ?? {};
logi['maps'] = logi['maps'] ?? {};
import a15_0x436f07 from './base/logi-maps-defines.js';
logi['maps']['Defines'] = a15_0x436f07;
import a15_0x39454f from './base/logi-maps-types.js';
logi['maps']['TileInfo'] = a15_0x39454f['TileInfo'], logi['maps']['ALIGN'] = a15_0x39454f['ALIGN'];
import a15_0x22336b from './base/logi-maps-utils.js';
logi['maps']['Utils'] = a15_0x22336b;
import a15_0x2e8879 from './base/logi-maps-resource.js';
logi['maps']['Resource'] = a15_0x2e8879;
import a15_0x1f6a38 from './base/logi-maps-object.js';
logi['maps']['Object'] = a15_0x1f6a38;
import a15_0x3c69b2 from './base/logi-maps-boundarydata.js';
logi['maps']['BoundaryData'] = a15_0x3c69b2;
import a15_0x43fc7c from './base/logi-maps-boundarychecker.js';
logi['maps']['BoundaryChecker'] = a15_0x43fc7c, logi['maps']['Label'] = class extends logi['maps']['Object'] {
    #position = {
        'lng': 0x0,
        'lat': 0x0
    };
    #offsetX;
    #offsetY;
    #angle;
    #align;
    #text;
    #textItems;
    #fontFamily;
    #textColor;
    #fontSize;
    #bgImgSrc;
    #bgImgOffsetX;
    #bgImgOffsetY;
    #bgImage = null;
    #tileInfo = new logi['maps']['TileInfo']();
    #expiredTileId = !![];
    #boundaryData = new logi['maps']['BoundaryData']();
    constructor(_0x2e438e, _0x179363, _0x2463c1) {
        const _0x8b4a2e = _0x2463c1?.['key'] ?? 'lb_' + Math['random']()['toString'](0x24)['slice'](-0x8), _0x13ae08 = _0x2463c1?.['class'] ?? '', _0x308eb4 = _0x2463c1?.['zIndex'] ?? 0x0;
        super(_0x8b4a2e, logi['maps']['Object']['OBJTYPE']['label'], _0x13ae08, _0x308eb4), this.#position['lng'] = _0x179363?.['lng'] ?? 0x0, this.#position['lat'] = _0x179363?.['lat'] ?? 0x0, this.#offsetX = _0x2463c1?.['offsetX'] ?? 0x0, this.#offsetY = _0x2463c1?.['offsetY'] ?? 0x0, this.#angle = _0x2463c1?.['angle'] ?? 0x0, this.#align = _0x2463c1?.['align'] ?? logi['maps']['ALIGN']['LT'], this.#text = _0x2e438e ?? '', this.#textItems = this.#text['toString']()['split']('\x0a'), this.#fontFamily = _0x2463c1?.['fontFamily'] ?? '', this.#textColor = _0x2463c1?.['color'] ?? '#000000', this.#fontSize = _0x2463c1?.['fontSize'] ?? 0x12, this['setBgImgSrc'](_0x2463c1?.['bgImgSrc'] ?? ''), this.#bgImgOffsetX = _0x2463c1?.['bgImgOffsetX'] ?? 0x0, this.#bgImgOffsetY = _0x2463c1?.['bgImgOffsetY'] ?? 0x0, this['boundaryData']['groupId'] = _0x2463c1?.['groupId'] ?? 0x0, this['resetBoundary'](), this['setMap'](_0x2463c1?.['map']);
    }
    ['setPosition'](_0xbd32ed) {
        _0xbd32ed['lng'] = _0xbd32ed['lng'] ?? 0x0, _0xbd32ed['lat'] = _0xbd32ed['lat'] ?? 0x0, (this.#position['lng'] !== _0xbd32ed['lng'] || this.#position['lat'] !== _0xbd32ed['lat']) && (this.#position['lng'] = _0xbd32ed['lng'], this.#position['lat'] = _0xbd32ed['lat'], (this.#position['lng'] < this['tileInfo']['boundary']['xMin'] || this.#position['lng'] > this['tileInfo']['boundary']['xMax'] || this.#position['lat'] < this['tileInfo']['boundary']['yMin'] || this.#position['lat'] > this['tileInfo']['boundary']['yMax']) && (this['expiredTileId'] = !![]), this['updateObj']());
    }
    ['getPosition']() {
        return {
            'lng': this.#position['lng'],
            'lat': this.#position['lat']
        };
    }
    ['setOffsetX'](_0x1e12ea) {
        _0x1e12ea = _0x1e12ea ?? 0x0, this.#offsetX !== _0x1e12ea && (this.#offsetX = _0x1e12ea, this['updateObj']());
    }
    ['getOffsetX']() {
        return this.#offsetX;
    }
    ['setOffsetY'](_0x1f4998) {
        _0x1f4998 = _0x1f4998 ?? 0x0, this.#offsetY !== _0x1f4998 && (this.#offsetY = _0x1f4998, this['updateObj']());
    }
    ['getOffsetY']() {
        return this.#offsetY;
    }
    ['setAngle'](_0x39b3aa) {
        _0x39b3aa = _0x39b3aa ?? 0x0, this.#angle !== _0x39b3aa && (this.#angle = _0x39b3aa, this['updateObj']());
    }
    ['getAngle']() {
        return this.#angle;
    }
    ['setAlign'](_0x4867e7) {
        _0x4867e7 = _0x4867e7 ?? logi['maps']['ALIGN']['LT'], this.#align !== _0x4867e7 && (this.#align = _0x4867e7, this['updateObj']());
    }
    ['getAlign']() {
        return this.#align;
    }
    ['setText'](_0x2023e2) {
        _0x2023e2 = _0x2023e2 ?? '', this.#text !== _0x2023e2 && (this.#text = _0x2023e2, this.#textItems = this.#text['toString']()['split']('\x0a'), this['updateObj']());
    }
    ['getText']() {
        return this.#text;
    }
    ['setTextColor'](_0x1a0f77) {
        _0x1a0f77 = _0x1a0f77 ?? 'FFFFFF', this.#textColor !== _0x1a0f77 && (this.#textColor = _0x1a0f77, this['updateObj']());
    }
    ['getTextColor']() {
        return this.#textColor;
    }
    ['setFontSize'](_0x497fa6) {
        _0x497fa6 = _0x497fa6 ?? 0x12, this.#fontSize !== _0x497fa6 && (this.#fontSize = _0x497fa6, this['updateObj']());
    }
    ['getFontSize']() {
        return this.#fontSize;
    }
    ['setBgImgSrc'](_0x537b5f) {
        this.#bgImgSrc = _0x537b5f ?? '';
        const _0x4e7f94 = this;
        logi['maps']['Resource']['getImage'](_0x4e7f94.#bgImgSrc)['then'](_0xf15a06 => {
            _0x4e7f94.#bgImage = _0xf15a06, _0x4e7f94['updateObj']();
        })['catch'](_0x9a769e => {
            _0x4e7f94.#bgImage = null, _0x4e7f94['updateObj'](), console['log'](_0x9a769e);
        });
    }
    ['getBgImgSrc']() {
        return this.#bgImgSrc;
    }
    ['setBgImgOffsetX'](_0x36927c) {
        _0x36927c = _0x36927c ?? 0x0, this.#bgImgOffsetX !== _0x36927c && (this.#bgImgOffsetX = _0x36927c, this['updateObj']());
    }
    ['getBgImgOffsetX']() {
        return this.#bgImgOffsetX;
    }
    ['setBgImgOffsetY'](_0x322851) {
        _0x322851 = _0x322851 ?? 0x0, this.#bgImgOffsetY !== _0x322851 && (this.#bgImgOffsetY = _0x322851, this['updateObj']());
    }
    ['getBgImgOffsetY']() {
        return this.#bgImgOffsetY;
    }
    get ['tileInfo']() {
        return this.#tileInfo;
    }
    set ['expiredTileId'](_0x21249d) {
        this.#expiredTileId = _0x21249d;
    }
    get ['expiredTileId']() {
        return this.#expiredTileId;
    }
    get ['boundaryData']() {
        return this.#boundaryData;
    }
    ['resetBoundary']() {
        this['boundaryData']['bLoad'] = logi['maps']['BoundaryData']['STATUS']['NOT_LOAD'], this['boundaryData']['tagName'] = this['getKey'](), this['boundaryData']['boundaryCircle'] = {
            'center': {
                'x': 0x0,
                'y': 0x0
            },
            'maxR': 0x0
        }, this['boundaryData']['boundaryRect'] = [
            {
                'x': 0x0,
                'y': 0x0
            },
            {
                'x': 0x0,
                'y': 0x0
            },
            {
                'x': 0x0,
                'y': 0x0
            },
            {
                'x': 0x0,
                'y': 0x0
            }
        ], this['boundaryData']['creationTick'] = logi['maps']['Utils']['getCurTick'](), this['boundaryData']['overlapCnt'] = 0x0;
    }
    ['updateBoundary']() {
        let _0x5889c7 = [
                {
                    'x': 0x0,
                    'y': 0x0
                },
                {
                    'x': 0x0,
                    'y': 0x0
                },
                {
                    'x': 0x0,
                    'y': 0x0
                },
                {
                    'x': 0x0,
                    'y': 0x0
                }
            ], _0x1a547b = {
                'center': {
                    'x': 0x0,
                    'y': 0x0
                },
                'maxR': 0x0
            }, _0x18216a = this['getMapCoord'](), _0x5a318b = _0x18216a['world2screen'](this.#position['lng'], this.#position['lat']);
        if (this.#bgImage) {
            let _0x4ca61f = {
                'x': 0x0,
                'y': 0x0
            };
            _0x4ca61f['x'] = _0x5a318b['x'] + this.#offsetX, _0x4ca61f['y'] = _0x5a318b['y'] + this.#offsetY;
            const _0x20858c = this.#bgImage?.['naturalWidth'], _0xf3428e = this.#bgImage?.['naturalHeight'];
            _0x5889c7 = logi['maps']['Utils']['getBoundaryBox'](_0x4ca61f, _0x20858c, _0xf3428e, this.#align, this.#angle, this.#bgImgOffsetX, this.#bgImgOffsetY);
            if (this.#bgImgOffsetX != 0x0 || this.#bgImgOffsetY != 0x0) {
                const _0x4fa342 = logi['maps']['Utils']['degToRad'](this.#angle);
                for (let _0x3c8543 = 0x0; _0x3c8543 < _0x5889c7['length']; ++_0x3c8543) {
                    const _0x564de7 = _0x5889c7[_0x3c8543]['x'], _0x52f9b8 = _0x5889c7[_0x3c8543]['y'];
                    _0x5889c7[_0x3c8543]['x'] = _0x564de7 + this.#bgImgOffsetX * Math['cos'](-_0x4fa342) + this.#bgImgOffsetY * Math['sin'](-_0x4fa342), _0x5889c7[_0x3c8543]['y'] = _0x52f9b8 - this.#bgImgOffsetX * Math['sin'](-_0x4fa342) + this.#bgImgOffsetY * Math['cos'](-_0x4fa342);
                }
            }
        } else {
            const _0x51769a = this.#fontSize * 1.2;
            let _0x42cc89 = {
                    'x': 0x0,
                    'y': 0x0
                }, _0x1ff824 = {
                    'w': 0x0,
                    'h': 0x0
                };
            _0x42cc89['x'] = _0x5a318b['x'] + this.#offsetX, _0x42cc89['y'] = _0x5a318b['y'] + this.#offsetY, this.#textItems['forEach'](_0xbca20f => {
                const _0x331626 = logi['maps']['Utils']['getTextWidth'](this['getContext'](), _0xbca20f, this.#fontFamily, this.#fontSize);
                _0x331626 > _0x1ff824['w'] && (_0x1ff824['w'] = _0x331626), _0x1ff824['h'] += _0x51769a;
            }), _0x5889c7 = logi['maps']['Utils']['getBoundaryBox'](_0x42cc89, _0x1ff824['w'], _0x1ff824['h'], this.#align, this.#angle);
        }
        _0x1a547b = logi['maps']['Utils']['getBoundaryCircle'](_0x5889c7), this['boundaryData']['setBoundary'](_0x1a547b, _0x5889c7);
    }
    ['isHit'](_0x1fded2) {
        if (!this['getLayer']() || !this['getVisible']())
            return ![];
        if (this['boundaryData']['bLoad'] != logi['maps']['BoundaryData']['STATUS']['LOAD'])
            return ![];
        return logi['maps']['BoundaryChecker']['pointInRegion'](_0x1fded2, this['boundaryData']['boundaryRect'], this['boundaryData']['boundaryRect']['length']);
    }
    ['draw']() {
        const _0x29562d = this['getMapCoord'](), _0x234dbb = this['getDevicePixelRatio'](), _0x229a5f = this['getContext']();
        _0x229a5f['save'](), _0x229a5f['scale'](_0x234dbb, _0x234dbb);
        if (this['getLayer']() && this['getVisible']()) {
            let _0x17ed35 = _0x29562d['world2screen'](this.#position['lng'], this.#position['lat']);
            _0x17ed35['x'] += this.#offsetX, _0x17ed35['y'] += this.#offsetY;
            if (this.#bgImage) {
                const _0x38fac7 = this.#bgImage?.['naturalWidth'], _0x17c152 = this.#bgImage?.['naturalHeight'], _0x3616b9 = _0x38fac7 * 0.5, _0x225d4e = _0x17c152 * 0.5;
                let _0x481e21 = logi['maps']['Utils']['getAlignPosition'](_0x17ed35['x'], _0x17ed35['y'], this.#align, _0x38fac7, _0x17c152), _0x1b0661 = logi['maps']['Utils']['getPivotPoint'](this.#align, _0x38fac7, _0x17c152);
                this.#angle == 0x0 ? _0x229a5f['drawImage'](this.#bgImage, _0x481e21['x'] + this.#bgImgOffsetX, _0x481e21['y'] + this.#bgImgOffsetY) : (_0x229a5f['save'](), _0x229a5f['translate'](_0x481e21['x'] + _0x1b0661['x'], _0x481e21['y'] + _0x1b0661['y']), _0x229a5f['rotate'](logi['maps']['Utils']['degToRad'](this.#angle)), _0x229a5f['drawImage'](this.#bgImage, this.#bgImgOffsetX - _0x1b0661['x'], this.#bgImgOffsetY - _0x1b0661['y']), _0x229a5f['restore']());
            }
            const _0x459901 = this.#fontSize * 1.2;
            let _0x3bef5d = 0x0;
            if (this.#textItems['length'] >= 0x2) {
                if (this.#align == logi['maps']['ALIGN']['LM'] || this.#align == logi['maps']['ALIGN']['CM'] || this.#align == logi['maps']['ALIGN']['RM'])
                    _0x3bef5d = (this.#textItems['length'] - 0x1) * _0x459901 * -0.5;
                else
                    (this.#align == logi['maps']['ALIGN']['LB'] || this.#align == logi['maps']['ALIGN']['CB'] || this.#align == logi['maps']['ALIGN']['RB']) && (_0x3bef5d = (this.#textItems['length'] - 0x1) * _0x459901 * -0x1);
            }
            this.#angle == 0x0 ? this.#textItems['forEach'](_0x522330 => {
                this.#drawText(_0x229a5f, _0x522330, _0x17ed35['x'], _0x17ed35['y'] + _0x3bef5d, this.#fontFamily, this.#fontSize, this.#textColor, this.#align), _0x3bef5d += _0x459901;
            }) : (_0x229a5f['save'](), _0x229a5f['translate'](_0x17ed35['x'], _0x17ed35['y']), _0x229a5f['rotate'](logi['maps']['Utils']['degToRad'](this.#angle)), this.#textItems['forEach'](_0x360cca => {
                this.#drawText(_0x229a5f, _0x360cca, 0x0, _0x3bef5d, this.#fontFamily, this.#fontSize, this.#textColor, this.#align), _0x3bef5d += _0x459901;
            }), _0x229a5f['restore']());
        }
        logi['maps']['Defines']['DEBUG_MODE'] && this.#drawBoundaryLine(_0x229a5f, this['boundaryData']['boundaryRect']), _0x229a5f['restore']();
    }
    #drawText = function (_0x41c901, _0xac8196, _0x168989, _0x1879cc, _0x133a8a, _0x3d4b64, _0x1f9d89, _0x581553) {
        _0x133a8a != '' && document['fonts'] && !document['fonts']['check']('1em\x20' + _0x133a8a) && (_0x133a8a = ''), _0x133a8a == '' ? _0x41c901['font'] = _0x3d4b64 + ('px\x20' + logi['maps']['Defines']['DEFAULT_FONT']) : _0x41c901['font'] = _0x3d4b64 + ('px\x20' + _0x133a8a), _0x41c901['fillStyle'] = _0x1f9d89, _0x41c901['textAlign'] = logi['maps']['Utils']['convertToTextAlign'](_0x581553), _0x41c901['textBaseline'] = logi['maps']['Utils']['convertToTextBaseline'](_0x581553), _0x41c901['fillText'](_0xac8196, _0x168989, _0x1879cc);
    };
    #drawBoundaryLine(_0x18af0f, _0x448e50) {
        if (_0x448e50?.['length'] >= 0x2) {
            _0x18af0f['beginPath'](), _0x18af0f['lineWidth'] = 0x2, _0x18af0f['strokeStyle'] = 'red', _0x18af0f['lineCap'] = 'round', _0x18af0f['lineJoin'] = 'round', _0x18af0f['moveTo'](_0x448e50[0x0]['x'], _0x448e50[0x0]['y']);
            for (let _0x4bf558 of _0x448e50) {
                _0x18af0f['lineTo'](_0x4bf558['x'], _0x4bf558['y']);
            }
            _0x18af0f['stroke']();
        }
    }
};
export default logi['maps']['Label'];