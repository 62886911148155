














































export default {
  name: 'FindId',
  data() {
    return {
      desserts: [
        {
          title: '이름',
          info: '홍*동',
        },
        {
          title: '전화번호',
          info: '010-****-1234',
        },
        {
          title: '가입일',
          info: '2023.01.01',
        },
        {
          title: '아이디',
          info: 'Id1234',
        },
      ],
    }
  },
}
