export default {
  CALLER: '화주',
  DRIVER: '차주',
  BROKER: '주선사',
  SUPERADMIN: 'SUPERADMIN',
  ADMIN: 'ADMIN',
  MANAGER: 'MANAGER',
  USER: 'USER',
  SYSTEM: '시스템',
}
