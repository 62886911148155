





























































import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { mdiCircleOutline, mdiCircleSlice8, mdiWindowClose } from '@mdi/js'
import userService from '@/services-itgo/user.service'

@Component({
  components: {},
})
export default class TermsHistoryPopup extends Vue {
  @Prop(Array) termsHistoryData
  @Prop(String) termsType
  @Prop(Number) userId
  @Prop(Number) type

  public icons: any = {
    mdiWindowClose,
  }

  mounted() {
    console.log(this.type);
  }


  /**
   * history api 연결하여 기능 구현 해야함.
   */
  async getModifyChoiceTerms(){
    try {

    }catch (e) {

    }finally {

    }
  }
}
