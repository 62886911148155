var logi = logi ?? {};
logi['maps'] = logi['maps'] ?? {};
import a3_0x446870 from './logi-maps-defines.js';
logi['maps']['Defines'] = a3_0x446870;
import a3_0x2bcc5 from './logi-maps-types.js';
logi['maps']['LatLng'] = a3_0x2bcc5['LatLng'], logi['maps']['Point'] = a3_0x2bcc5['Point'], logi['maps']['Rect'] = a3_0x2bcc5['Rect'], logi['maps']['TileUID'] = a3_0x2bcc5['TileUID'], logi['maps']['TileOnScreen'] = a3_0x2bcc5['TileOnScreen'], logi['maps']['TileInfo'] = a3_0x2bcc5['TileInfo'];
import a3_0x13f18e from './logi-maps-utils.js';
logi['maps']['Utils'] = a3_0x13f18e, logi['maps']['Coord'] = class {
    #country = logi['maps']['Defines']['COUNTRY_KOR'];
    #mapBoundary = new logi['maps']['Rect']();
    #screenRect = new logi['maps']['Rect']();
    #worldRect = new logi['maps']['Rect']();
    #mapCenter = new logi['maps']['LatLng']();
    #scaleLevel = 0x0;
    #additionalScale = 0x1;
    #devicePixelRatio = 0x1;
    constructor(_0x18c783, _0x2502ca) {
        this.#country = _0x18c783 ?? logi['maps']['Defines']['COUNTRY_KOR'];
        const _0x4219ff = _0x2502ca?.['min'] ?? logi['maps']['Defines']['MIN_LEVEL'], _0x506256 = _0x2502ca?.['max'] ?? logi['maps']['Defines']['MAX_LEVEL'];
        _0x506256 >= _0x4219ff ? (logi['maps']['Defines']['MIN_LEVEL'] = _0x4219ff, logi['maps']['Defines']['MAX_LEVEL'] = _0x506256) : (logi['maps']['Defines']['MIN_LEVEL'] = _0x506256, logi['maps']['Defines']['MAX_LEVEL'] = _0x4219ff), this.#scaleLevel = logi['maps']['Defines']['DETAIL_LEVEL'], this.#mapCenter['lng'] = 0x0, this.#mapCenter['lat'] = 0x0, this.#country == logi['maps']['Defines']['COUNTRY_KOR'] ? (this.#mapBoundary['xMin'] = 123.8, this.#mapBoundary['yMin'] = 32.8, this.#mapBoundary['xMax'] = 131.95, this.#mapBoundary['yMax'] = 38.8) : (this.#mapBoundary['xMin'] = -0xb4, this.#mapBoundary['yMin'] = -0x5a, this.#mapBoundary['xMax'] = 0xb4, this.#mapBoundary['yMax'] = 0x5a);
    }
    ['setScreenSize'](_0x2399f1, _0x66fb9a, _0x1c31fb) {
        this.#screenRect['xMin'] = 0x0, this.#screenRect['yMin'] = 0x0, this.#screenRect['xMax'] = _0x2399f1, this.#screenRect['yMax'] = _0x66fb9a, this.#devicePixelRatio = _0x1c31fb, this.#calcMapBoundRect();
    }
    ['getScreenSize']() {
        return {
            'left': this.#screenRect['xMin'],
            'top': this.#screenRect['yMin'],
            'right': this.#screenRect['xMax'],
            'bottom': this.#screenRect['yMax']
        };
    }
    ['screen2world'](_0xe93fc8, _0xf28dbb, _0x552adb) {
        const _0x451cdc = new logi['maps']['LatLng'](), _0xa10002 = _0x552adb?.['level'] ?? this.#scaleLevel, _0x2bda73 = _0x552adb?.['additionalScale'] ?? this.#additionalScale;
        let _0x40df19 = logi['maps']['Utils']['longitude2TileX'](this.#mapCenter['lng'], _0xa10002), _0x38b689 = logi['maps']['Utils']['latitude2TileY'](this.#mapCenter['lat'], _0xa10002);
        _0x40df19 += (_0xe93fc8 - (this.#screenRect['xMax'] - this.#screenRect['xMin']) / 0x2) / (logi['maps']['Defines']['TILE_W'] * _0x2bda73), _0x38b689 += (_0xf28dbb - (this.#screenRect['yMax'] - this.#screenRect['yMin']) / 0x2) / (logi['maps']['Defines']['TILE_H'] * _0x2bda73);
        const _0x2ea833 = logi['maps']['Defines']['POW_TABLE'][_0xa10002];
        while (_0x40df19 < 0x0) {
            _0x40df19 += _0x2ea833;
        }
        while (_0x40df19 >= _0x2ea833) {
            _0x40df19 -= _0x2ea833;
        }
        return _0x40df19 >= 0x0 && _0x40df19 <= _0x2ea833 && _0x38b689 >= 0x0 && _0x38b689 <= _0x2ea833 && (_0x451cdc['lng'] = logi['maps']['Utils']['tileX2Longitude'](_0x40df19, _0xa10002), _0x451cdc['lat'] = logi['maps']['Utils']['tileY2Latitude'](_0x38b689, _0xa10002)), {
            'lng': _0x451cdc['lng'],
            'lat': _0x451cdc['lat'],
            'x': _0x451cdc['lng'],
            'y': _0x451cdc['lat']
        };
    }
    ['world2screen'](_0x590c0f, _0x1af1dd, _0xc1926a) {
        const _0x22abf8 = new logi['maps']['Point'](), _0x556233 = _0xc1926a?.['level'] ?? this.#scaleLevel, _0x50f963 = _0xc1926a?.['additionalScale'] ?? this.#additionalScale, _0x12980a = logi['maps']['Utils']['longitude2TileX'](this.#mapCenter['lng'], _0x556233), _0x3bb190 = logi['maps']['Utils']['latitude2TileY'](this.#mapCenter['lat'], _0x556233), _0x2974fe = logi['maps']['Utils']['longitude2TileX'](_0x590c0f, _0x556233), _0x241461 = logi['maps']['Utils']['latitude2TileY'](_0x1af1dd, _0x556233), _0x43caae = logi['maps']['Defines']['POW_TABLE'][_0x556233];
        return _0x2974fe >= 0x0 && _0x2974fe <= _0x43caae && _0x241461 >= 0x0 && _0x241461 <= _0x43caae && (_0x22abf8['x'] = (this.#screenRect['xMax'] - this.#screenRect['xMin']) / 0x2 + this.#screenRect['xMin'] + (_0x2974fe - _0x12980a) * (logi['maps']['Defines']['TILE_W'] * _0x50f963), _0x22abf8['y'] = (this.#screenRect['yMax'] - this.#screenRect['yMin']) / 0x2 + this.#screenRect['yMin'] + (_0x241461 - _0x3bb190) * (logi['maps']['Defines']['TILE_H'] * _0x50f963)), _0x22abf8['x'] = parseInt(_0x22abf8['x']), _0x22abf8['y'] = parseInt(_0x22abf8['y']), _0x22abf8;
    }
    ['tileXY2screen'](_0x5b10a6, _0x892785, _0x179811) {
        const _0x4fa9ca = logi['maps']['Utils']['tileX2Longitude'](_0x5b10a6, _0x179811), _0x245586 = logi['maps']['Utils']['tileY2Latitude'](_0x892785, _0x179811);
        return this['world2screen'](_0x4fa9ca, _0x245586);
    }
    #calcMapBoundRect = function () {
        const _0x6e7b44 = this['screen2world'](this.#screenRect['xMin'], this.#screenRect['yMin']), _0x1ce242 = this['screen2world'](this.#screenRect['xMax'], this.#screenRect['yMax']);
        this.#worldRect['xMin'] = _0x6e7b44['lng'] <= _0x1ce242['lng'] ? _0x6e7b44['lng'] : _0x1ce242['lng'], this.#worldRect['yMin'] = _0x6e7b44['lat'] <= _0x1ce242['lat'] ? _0x6e7b44['lat'] : _0x1ce242['lat'], this.#worldRect['xMax'] = _0x6e7b44['lng'] >= _0x1ce242['lng'] ? _0x6e7b44['lng'] : _0x1ce242['lng'], this.#worldRect['yMax'] = _0x6e7b44['lat'] >= _0x1ce242['lat'] ? _0x6e7b44['lat'] : _0x1ce242['lat'];
    };
    ['adjustMapBoundRect'] = function () {
        function _0x5653eb(_0xd242a9, _0x192a8b, _0x14c836, _0x84e29f) {
            let _0x54c669 = 0x0;
            const _0x107481 = _0xd242a9 - _0x14c836, _0x277e5d = _0x192a8b - _0x84e29f;
            if (_0x107481 >= 0x0 && _0x277e5d <= 0x0)
                _0x54c669 = 0x0;
            else {
                if (_0x107481 < 0x0 && _0x277e5d > 0x0)
                    _0x54c669 = (_0x107481 + _0x277e5d) * -0.5;
                else {
                    if (_0x107481 < 0x0 && _0x277e5d <= 0x0)
                        _0x277e5d >= _0x107481 ? _0x54c669 = (_0x277e5d + (_0x107481 - _0x277e5d) * 0.5) * -0x1 : _0x54c669 = _0x107481 * -0x1;
                    else
                        _0x107481 >= 0x0 && _0x277e5d > 0x0 && (_0x277e5d >= _0x107481 ? _0x54c669 = (_0x107481 + (_0x277e5d - _0x107481) * 0.5) * -0x1 : _0x54c669 = _0x277e5d * -0x1);
                }
            }
            return _0x54c669;
        }
        const _0x430ac2 = _0x5653eb(this.#worldRect['xMin'], this.#worldRect['xMax'], this.#mapBoundary['xMin'], this.#mapBoundary['xMax']), _0x4545d3 = _0x5653eb(this.#worldRect['yMin'], this.#worldRect['yMax'], this.#mapBoundary['yMin'], this.#mapBoundary['yMax']);
        this.#mapCenter['lng'] += _0x430ac2, this.#mapCenter['lat'] += _0x4545d3;
        const _0x4a867d = this['screen2world'](this.#screenRect['xMin'], this.#screenRect['yMin']), _0x2f5ba1 = this['screen2world'](this.#screenRect['xMax'], this.#screenRect['yMax']);
        this.#worldRect['xMin'] = _0x4a867d['lng'] <= _0x2f5ba1['lng'] ? _0x4a867d['lng'] : _0x2f5ba1['lng'], this.#worldRect['yMin'] = _0x4a867d['lat'] <= _0x2f5ba1['lat'] ? _0x4a867d['lat'] : _0x2f5ba1['lat'], this.#worldRect['xMax'] = _0x4a867d['lng'] >= _0x2f5ba1['lng'] ? _0x4a867d['lng'] : _0x2f5ba1['lng'], this.#worldRect['yMax'] = _0x4a867d['lat'] >= _0x2f5ba1['lat'] ? _0x4a867d['lat'] : _0x2f5ba1['lat'];
    };
    ['setCenter'](_0x50054c, _0xac8582) {
        this.#mapCenter['lng'] = _0x50054c, this.#mapCenter['lat'] = _0xac8582, this.#calcMapBoundRect();
    }
    ['getCenter']() {
        return {
            'lng': this.#mapCenter['lng'],
            'lat': this.#mapCenter['lat']
        };
    }
    ['getMapRect']() {
        return this.#worldRect;
    }
    ['setBounds'](_0x40e327, _0x31ef48, _0x409e48, _0x28db2b, _0x117ef5, _0x2b61f1, _0x407429, _0x2169f5) {
        const _0x59af42 = {
                'cx': this.#screenRect['xMax'] - this.#screenRect['xMin'],
                'cy': this.#screenRect['yMax'] - this.#screenRect['yMin']
            }, _0x297fa0 = _0x59af42['cx'] - (_0x117ef5 + _0x407429), _0x4b8330 = _0x59af42['cy'] - (_0x2b61f1 + _0x2169f5);
        if (_0x297fa0 <= 0x0 || _0x4b8330 <= 0x0)
            return;
        let _0x54db6e = {
                'lng': _0x40e327,
                'lat': _0x31ef48
            }, _0x55a309 = {
                'lng': _0x409e48,
                'lat': _0x28db2b
            };
        _0x40e327 = _0x54db6e['lng'] < _0x55a309['lng'] ? _0x54db6e['lng'] : _0x55a309['lng'], _0x31ef48 = _0x54db6e['lat'] < _0x55a309['lat'] ? _0x54db6e['lat'] : _0x55a309['lat'], _0x409e48 = _0x54db6e['lng'] > _0x55a309['lng'] ? _0x54db6e['lng'] : _0x55a309['lng'], _0x28db2b = _0x54db6e['lat'] > _0x55a309['lat'] ? _0x54db6e['lat'] : _0x55a309['lat'], _0x40e327 = this.#mapBoundary['xMin'] < _0x40e327 ? _0x40e327 : this.#mapBoundary['xMin'], _0x31ef48 = this.#mapBoundary['yMin'] < _0x31ef48 ? _0x31ef48 : this.#mapBoundary['yMin'], _0x409e48 = this.#mapBoundary['xMax'] > _0x409e48 ? _0x409e48 : this.#mapBoundary['xMax'], _0x28db2b = this.#mapBoundary['yMax'] > _0x28db2b ? _0x28db2b : this.#mapBoundary['yMax'];
        const _0x19ee1f = (_0x409e48 - _0x40e327) * (_0x117ef5 / _0x297fa0), _0x2d5d32 = (_0x409e48 - _0x40e327) * (_0x407429 / _0x297fa0), _0x537a86 = (_0x28db2b - _0x31ef48) * (_0x2169f5 / _0x4b8330), _0x1f6416 = (_0x28db2b - _0x31ef48) * (_0x2b61f1 / _0x4b8330);
        _0x40e327 -= _0x19ee1f, _0x409e48 += _0x2d5d32, _0x31ef48 -= _0x537a86, _0x28db2b += _0x1f6416, this.#mapCenter['lng'] = (_0x40e327 + _0x409e48) / 0x2, this.#mapCenter['lat'] = (_0x28db2b + _0x31ef48) / 0x2;
        let _0x1db09c = -0x1;
        for (let _0x20fa6a = logi['maps']['Defines']['MAX_LEVEL']; _0x20fa6a >= logi['maps']['Defines']['MIN_LEVEL'] && _0x1db09c == -0x1; --_0x20fa6a) {
            let _0x26ef46 = this['world2screen'](_0x40e327, _0x28db2b, { 'level': _0x20fa6a }), _0x5698c9 = this['world2screen'](_0x409e48, _0x31ef48, { 'level': _0x20fa6a });
            logi['maps']['Utils']['pointInRect'](_0x26ef46['x'], _0x26ef46['y'], this.#screenRect['xMin'], this.#screenRect['yMin'], this.#screenRect['xMax'], this.#screenRect['yMax']) && logi['maps']['Utils']['pointInRect'](_0x5698c9['x'], _0x5698c9['y'], this.#screenRect['xMin'], this.#screenRect['yMin'], this.#screenRect['xMax'], this.#screenRect['yMax']) && (_0x1db09c = _0x20fa6a);
        }
        return _0x1db09c == -0x1 && (_0x1db09c = logi['maps']['Defines']['MIN_LEVEL']), this.#scaleLevel = _0x1db09c, this.#additionalScale = 0x1, this.#calcMapBoundRect(), !![];
    }
    ['panMap'](_0x361eb5, _0x509a9a, _0x5b75a1, _0x5b40fd) {
        const _0x8528a8 = logi['maps']['Utils']['longitude2TileX'](this.#mapCenter['lng'], this.#scaleLevel) - (_0x5b75a1 - _0x361eb5) / (logi['maps']['Defines']['TILE_W'] * this.#additionalScale), _0x3ca72a = logi['maps']['Utils']['tileX2Longitude'](_0x8528a8, this.#scaleLevel), _0x19bdd3 = logi['maps']['Utils']['latitude2TileY'](this.#mapCenter['lat'], this.#scaleLevel) - (_0x5b40fd - _0x509a9a) / (logi['maps']['Defines']['TILE_H'] * this.#additionalScale), _0x2a9023 = logi['maps']['Utils']['tileY2Latitude'](_0x19bdd3, this.#scaleLevel);
        this['setCenter'](_0x3ca72a, _0x2a9023);
    }
    ['setLevel'](_0xa86ddf) {
        if (_0xa86ddf < logi['maps']['Defines']['MIN_LEVEL'] || _0xa86ddf > logi['maps']['Defines']['MAX_LEVEL'])
            return;
        this.#scaleLevel = _0xa86ddf, this.#calcMapBoundRect();
    }
    ['getLevel']() {
        return this.#scaleLevel;
    }
    ['isZoomInMax']() {
        return this['getLevel']() >= logi['maps']['Defines']['MAX_LEVEL'];
    }
    ['zoomIn']() {
        this.#scaleLevel + 0x1 <= logi['maps']['Defines']['MAX_LEVEL'] && this['setLevel'](this.#scaleLevel + 0x1);
    }
    ['isZoomOutMax']() {
        return this['getLevel']() <= logi['maps']['Defines']['MIN_LEVEL'];
    }
    ['zoomOut']() {
        this.#scaleLevel - 0x1 >= logi['maps']['Defines']['MIN_LEVEL'] && this['setLevel'](this.#scaleLevel - 0x1);
    }
    ['setAdditionalScale'](_0x3ee37e) {
        this.#additionalScale = _0x3ee37e, this.#calcMapBoundRect();
    }
    ['getAdditionalScale']() {
        return this.#additionalScale;
    }
    #calcStartTilePositions = function (_0x33eda0, _0x2e540b, _0x215ed4, _0x2e15e4) {
        const _0x25b760 = logi['maps']['Utils']['longitude2TileX'](this.#mapCenter['lng'], _0x2e15e4), _0x1350e6 = logi['maps']['Utils']['latitude2TileY'](this.#mapCenter['lat'], _0x2e15e4);
        _0x33eda0['tile']['x'] = parseInt(_0x25b760), _0x33eda0['tile']['y'] = parseInt(_0x1350e6), _0x2e540b['tile']['x'] = parseInt(_0x25b760), _0x2e540b['tile']['y'] = parseInt(_0x1350e6);
        const _0x37a598 = (this.#screenRect['yMax'] - this.#screenRect['yMin']) / 0x2, _0x24e76c = (this.#screenRect['xMax'] - this.#screenRect['xMin']) / 0x2, _0x2a8d41 = this.#screenRect['xMin'] + _0x24e76c, _0x27a85f = this.#screenRect['yMin'] + _0x37a598;
        _0x33eda0['screen']['x'] = _0x2a8d41 - (_0x25b760 - Math['floor'](_0x25b760)) * _0x215ed4['x'], _0x33eda0['screen']['y'] = _0x27a85f - (_0x1350e6 - Math['floor'](_0x1350e6)) * _0x215ed4['y'];
        if (_0x33eda0['screen']['x'] > 0x0) {
            const _0x170c68 = parseInt(Math['ceil'](_0x33eda0['screen']['x'] / _0x215ed4['x']));
            _0x33eda0['tile']['x'] -= _0x170c68, _0x33eda0['screen']['x'] -= _0x215ed4['x'] * _0x170c68;
        }
        if (_0x33eda0['screen']['y'] > 0x0) {
            const _0x5de16d = parseInt(Math['ceil'](_0x33eda0['screen']['y'] / _0x215ed4['y']));
            _0x33eda0['tile']['y'] -= _0x5de16d, _0x33eda0['screen']['y'] -= _0x215ed4['y'] * _0x5de16d;
        }
        _0x2e540b['screen']['x'] = _0x2a8d41 + (_0x25b760 - Math['floor'](_0x25b760)) * _0x215ed4['x'], _0x2e540b['screen']['y'] = _0x27a85f + (_0x1350e6 - Math['floor'](_0x1350e6)) * _0x215ed4['y'];
        if (_0x2e540b['screen']['x'] < this.#screenRect['xMax']) {
            const _0x2642a1 = parseInt(Math['ceil'](_0x2e540b['screen']['x'] / _0x215ed4['x']));
            _0x2e540b['tile']['x'] += _0x2642a1, _0x2e540b['screen']['x'] += _0x215ed4['x'] * _0x2642a1;
        }
        if (_0x2e540b['screen']['y'] < this.#screenRect['yMax']) {
            const _0x3479f3 = parseInt(Math['ceil'](_0x2e540b['screen']['y'] / _0x215ed4['y']));
            _0x2e540b['tile']['y'] += _0x3479f3, _0x2e540b['screen']['y'] += _0x215ed4['y'] * _0x3479f3;
        }
    };
    ['getTilesOnScreen']() {
        const _0x1db4dc = {
                'screen': new logi['maps']['Point'](),
                'tile': new logi['maps']['Point']()
            }, _0x599d04 = {
                'screen': new logi['maps']['Point'](),
                'tile': new logi['maps']['Point']()
            }, _0x5176ee = {
                'x': logi['maps']['Defines']['TILE_W'] * this.#additionalScale,
                'y': logi['maps']['Defines']['TILE_H'] * this.#additionalScale
            }, _0x18d726 = this.#scaleLevel, _0x65282a = logi['maps']['Defines']['POW_TABLE'][_0x18d726], _0x27a6a9 = {
                'x': (this.#screenRect['xMax'] + this.#screenRect['xMin']) / 0x2,
                'y': (this.#screenRect['yMax'] + this.#screenRect['yMin']) / 0x2
            }, _0x5ab885 = new Array();
        this.#calcStartTilePositions(_0x1db4dc, _0x599d04, _0x5176ee, _0x18d726);
        for (let _0x3c1410 = _0x1db4dc['screen']['y'], _0x4c7110 = _0x1db4dc['tile']['y']; _0x3c1410 <= _0x599d04['screen']['y']; _0x3c1410 += _0x5176ee['y'], ++_0x4c7110) {
            if (_0x4c7110 >= 0x0 && _0x4c7110 < _0x65282a)
                for (let _0x26d311 = _0x1db4dc['screen']['x'], _0x30d1b4 = _0x1db4dc['tile']['x']; _0x26d311 <= _0x599d04['screen']['x']; _0x26d311 += _0x5176ee['x'], ++_0x30d1b4) {
                    while (_0x30d1b4 < 0x0) {
                        _0x30d1b4 += _0x65282a;
                    }
                    while (_0x30d1b4 >= _0x65282a) {
                        _0x30d1b4 -= _0x65282a;
                    }
                    const _0x6d7b61 = new logi['maps']['TileOnScreen']();
                    _0x6d7b61['tileUID']['setTile'](_0x30d1b4, _0x4c7110, _0x18d726), _0x6d7b61['rctOnScreen']['xMin'] = parseInt(_0x26d311), _0x6d7b61['rctOnScreen']['yMin'] = parseInt(_0x3c1410), _0x6d7b61['rctOnScreen']['xMax'] = parseInt(_0x26d311) + parseInt(_0x5176ee['x']), _0x6d7b61['rctOnScreen']['yMax'] = parseInt(_0x3c1410) + parseInt(_0x5176ee['y']);
                    if (logi['maps']['Utils']['rectOnRect'](_0x6d7b61['rctOnScreen']['xMin'], _0x6d7b61['rctOnScreen']['yMin'], _0x6d7b61['rctOnScreen']['xMax'], _0x6d7b61['rctOnScreen']['yMax'], this.#screenRect['xMin'], this.#screenRect['yMin'], this.#screenRect['xMax'], this.#screenRect['yMax'])) {
                        const _0x1cd144 = _0x26d311 + _0x5176ee['x'] / 0x2 - _0x27a6a9['x'], _0x2a10d4 = _0x3c1410 + _0x5176ee['y'] / 0x2 - _0x27a6a9['y'];
                        _0x6d7b61['distanceFromCenter'] = Math['sqrt'](_0x1cd144 * _0x1cd144 + _0x2a10d4 * _0x2a10d4), _0x5ab885['push'](_0x6d7b61);
                    }
                }
        }
        return _0x5ab885['sort'](function (_0x46503d, _0x5cdba1) {
            return _0x46503d['distanceFromCenter'] - _0x5cdba1['distanceFromCenter'];
        }), _0x5ab885;
    }
    ['getTileInfo'](_0x5310c2, _0x17816c, _0x4bc37d) {
        const _0x3bbf06 = Math['floor'](logi['maps']['Utils']['longitude2TileX'](_0x5310c2, _0x4bc37d)), _0x4ae011 = Math['floor'](logi['maps']['Utils']['latitude2TileY'](_0x17816c, _0x4bc37d)), _0x3b3dc8 = logi['maps']['Utils']['tileX2Longitude'](_0x3bbf06, _0x4bc37d), _0x23cd2d = logi['maps']['Utils']['tileY2Latitude'](_0x4ae011 + 0x1, _0x4bc37d), _0x4ce4b1 = logi['maps']['Utils']['tileX2Longitude'](_0x3bbf06 + 0x1, _0x4bc37d), _0x38ba05 = logi['maps']['Utils']['tileY2Latitude'](_0x4ae011, _0x4bc37d);
        let _0x26643d = new logi['maps']['TileUID']();
        _0x26643d['setTile'](_0x3bbf06, _0x4ae011, _0x4bc37d);
        let _0x426529 = new logi['maps']['TileInfo']();
        return _0x426529['tileId'] = _0x26643d['getId'](), _0x426529['boundary']['xMin'] = _0x3b3dc8, _0x426529['boundary']['yMin'] = _0x23cd2d, _0x426529['boundary']['xMax'] = _0x4ce4b1, _0x426529['boundary']['yMax'] = _0x38ba05, _0x426529;
    }
    ['getTilesInfo'](_0x4d7f59, _0xab25c, _0x127e7d, _0x3c1f7d, _0x1c9add) {
        let _0x148052 = Math['floor'](logi['maps']['Utils']['longitude2TileX'](_0x4d7f59, _0x1c9add)), _0xb69579 = Math['floor'](logi['maps']['Utils']['latitude2TileY'](_0x127e7d, _0x1c9add)), _0x22225c = Math['floor'](logi['maps']['Utils']['longitude2TileX'](_0xab25c, _0x1c9add)), _0x2571c4 = Math['floor'](logi['maps']['Utils']['latitude2TileY'](_0x3c1f7d, _0x1c9add));
        const _0x4ad1dd = logi['maps']['Defines']['POW_TABLE'][_0x1c9add];
        _0x148052 > _0x22225c && (_0x22225c += _0x4ad1dd);
        let _0x54db1e = [];
        for (let _0x528b97 = _0xb69579; _0x528b97 <= _0x2571c4; ++_0x528b97) {
            for (let _0x299743 = _0x148052; _0x299743 <= _0x22225c; ++_0x299743) {
                const _0x215371 = new logi['maps']['TileUID']();
                _0x299743 < _0x4ad1dd ? _0x215371['setTile'](_0x299743, _0x528b97, _0x1c9add) : _0x215371['setTile'](_0x299743 - _0x4ad1dd, _0x528b97, _0x1c9add), _0x54db1e['push'](_0x215371['getId']());
            }
        }
        return _0x54db1e;
    }
};
export default logi['maps']['Coord'];