








































































































import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { State } from 'vuex-class'
import brokerService from '@/services-itgo/broker.service'
import { mdiMagnify, mdiWindowClose } from '@mdi/js'
import driverService from '@/services-itgo/driver.service'
import userStatusService from '@/services-itgo/user.status.service'
import USER_STATUS from '@/views/constant/user.status'

@Component({
  components: {},
})
export default class ChangeUserStatusPopup extends Vue {
  @Prop(Boolean) value
  @Prop(Object) item

  @State((state) => state.auth.user) loginUser

  icons = {
    mdiMagnify,
    mdiWindowClose,
  }

  status = 0

  typeItems = [
    { text: '선택', value: 0 },
    { text: '주의', value: 2 },
    // { text: '경고', value: 3 },
    { text: '정지', value: 4 },
    { text: '강제 탈퇴', value: 5 },
  ]

  radioReasonGroup = 0

  reasonList = [
    { text: '원활한 서비스의 제공을 방해하는 행위', value: 1 },
    { text: '공정한 거래를 저해하는 행위', value: 2 },
    { text: '이용약관 및 서비스 정책을 위반하는 행위', value: 3 },
    { text: '기타', value: 4 },
  ]

  reasonText = ''

  suspensionDays = 3

  get suspensionDaysLists() {
    let suspensionDaysLists: any = []
    for (let i = 1; i <= 30; i++) {
      const item = {
        text: `${i}일`,
        value: i,
      }
      suspensionDaysLists.push(item)
    }
    return suspensionDaysLists
  }

  async onClickChangeUserStatus() {
    try {
      let body : any = { }

      if(this.item.type === USER_STATUS.USER_TYPE_BROKER) {
        body = {
          companyId:
            this.item.type === USER_STATUS.USER_TYPE_BROKER ? this.item.id : null,
          userType: this.item.type,
          status: this.status,
          suspensionDays: this.status === 4 ? this.suspensionDays : 0,
          reason:
            this.reasonText.length > 0
              ? this.reasonText
              : this.reasonList.find(
              (item) => item.value === this.radioReasonGroup,
            )?.text || '-',
        }
      }else{
        body = {
          userId:
            this.item.type !== USER_STATUS.USER_TYPE_BROKER ? this.item.id : null,
          userType: this.item.type,
          status: this.status,
          suspensionDays: this.status === 4 ? this.suspensionDays : 0,
          reason:
            this.reasonText.length > 0
              ? this.reasonText
              : this.reasonList.find(
              (item) => item.value === this.radioReasonGroup,
            )?.text || '-',
        }
      }

      if (
        this.status === USER_STATUS.USER_STATUS_SUSPENSION ||
        this.status === USER_STATUS.USER_STATUS_FORCED_WITHDRAWAL
      ) {
        const alertData = {
          title:
            this.status === USER_STATUS.USER_STATUS_SUSPENSION
              ? '정지 안내'
              : '강제 탈퇴 안내',
          content:
            this.status === USER_STATUS.USER_STATUS_SUSPENSION
              ? `해당 회원에게 정지를 부여할 경우<br/> 일부 서비스 사용이 제한됩니다.<br/> 계속하시겠습니까?`
              : `운송 중인 화물 또는 결제 되지 않은 운송 건이<br/> 존재할 경우 강제 탈퇴가 불가합니다.<br/> 계속 하시겠습니까?`,
        }
        const result = await this.$confirm(alertData)
        if (result) {
          const data = await userStatusService.postUserStatusList(body)
          await this.$toast({
            type: 'error',
            content: this.status === USER_STATUS.USER_STATUS_SUSPENSION
              ? `정지 처리가 완료 되었습니다.`
              : `강제 탈퇴 처리가 완료 되었습니다.`,
          })
          await this.$emit('changedUserStatus', data.status)
        }
      } else {
        const data = await userStatusService.postUserStatusList(body)
        await this.$emit('changedUserStatus', data.status)
      }

      this.onClickClose()
    } catch (error: any) {
      this.$alert({
        title : '강제 탈퇴 불가',
        content : '운송 중인 화물 또는 결제되지 않은 운송 건이 <br/> 존재하여 강제 탈퇴가 불가합니다.'})
    }
  }

  onClickClose() {
    this.$emit('close')
  }
}
