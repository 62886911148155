var logi = logi ?? {};
logi['maps'] = logi['maps'] ?? {}, logi['maps']['Layer'] = class {
    #mapCoord;
    #layerCanvas;
    #devicePixelRatio;
    #updateFlag;
    constructor(_0x28e727, _0x1eba4b, _0xa6b99a) {
        this.#mapCoord = _0x1eba4b, this.#layerCanvas = document['createElement']('canvas'), this.#layerCanvas['id'] = _0xa6b99a, this.#layerCanvas['style'] = 'position:absolute;\x20width:100%;\x20height:100%;\x20overflow-y:hidden;\x20overflow-x:hidden;', _0x28e727['appendChild'](this.#layerCanvas), this.#updateFlag = !![];
    }
    ['setScreenSize'](_0x195988, _0x4afd52, _0x22b29b) {
        this.#devicePixelRatio = _0x22b29b, this.#layerCanvas['width'] = Math['floor'](_0x195988 * _0x22b29b), this.#layerCanvas['height'] = Math['floor'](_0x4afd52 * _0x22b29b);
    }
    ['updateLayer']() {
        this.#updateFlag = !![];
    }
    ['setUpdateFlag'](_0x1ea257) {
        this.#updateFlag = _0x1ea257;
    }
    ['getUpdateFlag']() {
        return this.#updateFlag;
    }
    ['getCanvas']() {
        return this.#layerCanvas;
    }
    ['getCanvasWidth']() {
        return this.#layerCanvas['width'];
    }
    ['getCanvasHeight']() {
        return this.#layerCanvas['height'];
    }
    ['getDevicePixelRatio']() {
        return this.#devicePixelRatio;
    }
    ['getContext2D']() {
        return this.#layerCanvas['getContext']('2d');
    }
    ['clear']() {
        this['getContext2D']()['clearRect'](0x0, 0x0, this.#layerCanvas['width'], this.#layerCanvas['height']);
    }
    ['getMapCoord']() {
        return this.#mapCoord;
    }
};
export default logi['maps']['Layer'];