var logi = logi ?? {};
logi['maps'] = logi['maps'] ?? {}, logi['maps']['Defines'] = class {
    static ['DIV_PI_180'] = Math['PI'] / 0xb4;
    static ['DIV_180_PI'] = 0xb4 / Math['PI'];
    static ['POW_TABLE'] = [
        0x1,
        0x2,
        0x4,
        0x8,
        0x10,
        0x20,
        0x40,
        0x80,
        0x100,
        0x200,
        0x400,
        0x800,
        0x1000,
        0x2000,
        0x4000,
        0x8000,
        0x10000,
        0x20000,
        0x40000,
        0x80000,
        0x100000
    ];
    static ['MAP_DRAW_TYPE_POLYGON'] = 'polygon';
    static ['MAP_DRAW_TYPE_POLYLINE'] = 'polyline';
    static ['MAP_DRAW_TYPE_SYMBOL'] = 'symbol';
    static ['MAP_DRAW_TYPE_TEXT'] = 'text';
    static ['DETAIL_LEVEL'] = 0x12;
    static ['MIN_LEVEL'] = 0x8;
    static ['MAX_LEVEL'] = 0x12;
    static ['TILE_W'] = 0x100;
    static ['TILE_H'] = 0x100;
    static ['COUNTRY_KOR'] = 'kor';
    static ['COUNTRY_NAM'] = 'nam';
    static ['MAP_TILE_DATA_PNG'] = 'png';
    static ['MAP_TILE_DATA_SVG'] = 'svg';
    static ['MAP_TILE_DATA_XVG'] = 'xvg';
    static ['DEFAULT_FONT'] = 'Tahoma';
    static ['FPS_HIGH'] = 0x3c;
    static ['FPS_MEDIUM'] = 0x1e;
    static ['FPS_LOW'] = 0xa;
    static ['FPS_SLEEP'] = 0x5;
    static ['SLOWMODE_TIME'] = 0x4e20;
    static ['SLEEPMODE_TIME'] = 0xea60;
    static ['DEBUG_MODE'] = ![];
    static ['LOG_MODE'] = ![];
};
export default logi['maps']['Defines'];