






















import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import {} from '@mdi/js'
import _ from 'lodash'

@Component({
  name: 'FreightInfoItem',
  components: {},
})
export default class FreightInfoItem extends Vue {
  @Prop(String) title
  @Prop(String) content

  get isSocial(){
    return _.isEqual(this.title, "소셜계정")
  }

  get socialArray(){
    const arr : any = _.split(this.content, ',')
    if(arr.length)
      return arr
    else
      return []
  }
}
