var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "p-3", attrs: { width: "480px", height: "280px" } },
    [
      _c(
        "v-card-title",
        { staticClass: "d-flex align-center justify-space-between" },
        [
          _c("span", { staticClass: "font-size24 font-weight700" }, [
            _vm._v("공차 위치"),
          ]),
          _c(
            "v-btn",
            {
              attrs: {
                icon: "",
                width: "24px  !important",
                height: "24px !important",
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [
              _c("v-icon", { staticClass: "mr-n4", attrs: { size: "24" } }, [
                _vm._v(" " + _vm._s(_vm.icons.mdiWindowClose) + " "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-divider", { staticClass: "mt-4 mb-6 mx-5" }),
      _c("v-card-text", { staticClass: "font-size16" }, [
        _c("div", { staticClass: "d-flex align-center justify-start" }, [
          _c(
            "div",
            { staticClass: "mr-3", staticStyle: { color: "#757575" } },
            [_vm._v("주변 화물 확인 일시")]
          ),
          _c("div", [_vm._v(_vm._s(_vm.lastUpdateTime))]),
        ]),
        _c("div", { staticClass: "d-flex align-center justify-start mt-5" }, [
          _c(
            "div",
            { staticClass: "mr-3", staticStyle: { color: "#757575" } },
            [_vm._v("위치 정보")]
          ),
          _c("div", [_vm._v(_vm._s(_vm.lastLocation))]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "d-flex justify-center mt-2" },
        [
          _c(
            "v-btn",
            {
              staticClass: "popup_btn",
              attrs: { color: "primary" },
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v(" 닫기 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }