var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      staticClass: "v-dialog wrapper",
      attrs: { persistent: "", "max-width": "480px" },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "py-7 px-8" },
        [
          _c(
            "v-card-title",
            { staticClass: "pa-0 font-size24 surface800 font-weight900" },
            [
              _c("span", [_vm._v(_vm._s(_vm.titleString))]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "mt-n2 me-n3",
                  attrs: { icon: "" },
                  on: { click: _vm.close },
                },
                [
                  _c("v-icon", { attrs: { size: "25" } }, [
                    _vm._v(" " + _vm._s(_vm.icons.mdiWindowClose) + " "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-text",
            { staticClass: "pa-0 pb-5" },
            [
              _c("v-text-field", {
                ref: "reasonRef",
                staticClass: "remove-spacing",
                attrs: {
                  rules: _vm.rules.reasonMessage,
                  maxlength: "100",
                  label: "변경 사유",
                  placeholder: "변경 사유",
                  autofocus: "",
                  outlined: "",
                  "persistent-placeholder": "",
                  required: "",
                },
                model: {
                  value: _vm.reason,
                  callback: function ($$v) {
                    _vm.reason = $$v
                  },
                  expression: "reason",
                },
              }),
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-center pa-0" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "popup_btn",
                  attrs: { disabled: !_vm.reasonLength, color: "primary" },
                  on: { click: _vm.onClickStatusChanged },
                },
                [_vm._v(" 확인 ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "popup_btn cancel_btn",
                  on: { click: _vm.close },
                },
                [_vm._v(" 취소 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }