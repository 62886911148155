var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "552px", height: "300px" },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "transparent" },
        [
          _c(
            "v-card-text",
            {
              staticClass: "text-center",
              attrs: { color: "rgb(255, 255, 255, 0.2)" },
            },
            [
              _c("v-progress-circular", {
                attrs: {
                  color: "itgoWhite",
                  indeterminate: "",
                  width: 4,
                  size: 65,
                },
              }),
            ],
            1
          ),
          _c("v-card-text", { staticClass: "text-center" }, [
            _c("span", {
              staticClass: "font-size20 itgoWhite--text",
              domProps: { innerHTML: _vm._s(_vm.contentText) },
            }),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }