var logi = logi ?? {};
logi['maps'] = logi['maps'] ?? {};
import a7_0xa8f7d from './logi-maps-types.js';
logi['maps']['OBJEVENT'] = a7_0xa8f7d['OBJEVENT'];
import a7_0x11c59e from './logi-maps-bridge.js';
logi['maps']['Bridge'] = a7_0x11c59e, logi['maps']['Object'] = class {
    static ['OBJTYPE'] = {
        'image': 'image',
        'label': 'label',
        'line': 'line',
        'polygon': 'polygon',
        'route': 'route',
        'evcs': 'evcs'
    };
    #map = null;
    #objKey;
    #objType;
    #objClass;
    #zIndex;
    #baseLayer;
    #isVisible;
    constructor(_0x3e0062, _0x487e08, _0x2b2739 = '', _0x1927d2 = 0x0, _0x21f306) {
        this.#objKey = _0x3e0062, this.#objType = _0x487e08, this.#objClass = _0x2b2739, this.#zIndex = _0x1927d2, this.#isVisible = !![], this['eventHandlers'] = {};
        for (const _0x1a75bf in logi['maps']['OBJEVENT']) {
            this['eventHandlers'][_0x1a75bf] = [];
        }
        this['setLayer'](_0x21f306);
    }
    ['setMap'](_0x58e4ac) {
        this.#map?.['removeObject'](this), this.#map = _0x58e4ac, this.#map?.['addObject'](this);
    }
    ['getMap']() {
        return this.#map;
    }
    ['addEventListener'](_0x4e2021, _0x3558c5) {
        let _0x369858 = this['eventHandlers'][_0x4e2021];
        _0x369858 !== undefined && (_0x369858['forEach']((_0x17e914, _0x157a5a) => {
            if (_0x17e914 == _0x3558c5)
                return;
        }), _0x369858['push'](_0x3558c5));
    }
    ['removeEventListener'](_0x44c576, _0x4c724d) {
        let _0xcc1a8c = this['eventHandlers'][_0x44c576];
        _0xcc1a8c !== undefined && _0xcc1a8c['forEach']((_0x25c577, _0x391fbb) => {
            if (_0x25c577 == _0x4c724d) {
                _0xcc1a8c['splice'](_0x391fbb, 0x1);
                return;
            }
        });
    }
    #sendBridgeEvent(_0x5ddcac) {
        logi['maps']['Bridge']['onObjEvent'](_0x5ddcac);
    }
    ['setBridgeEvent'](_0xf36f68, _0x36823d) {
        _0x36823d ? this['addEventListener'](_0xf36f68, this.#sendBridgeEvent) : this['removeEventListener'](_0xf36f68, this.#sendBridgeEvent);
    }
    ['setVisible'](_0x5b39a3) {
        this.#isVisible != _0x5b39a3 && (this.#isVisible = _0x5b39a3, this['updateObj']());
    }
    ['getVisible']() {
        return this.#isVisible;
    }
    get ['key']() {
        return this.#objKey;
    }
    ['getKey']() {
        return this.#objKey;
    }
    ['getType']() {
        return this.#objType;
    }
    ['getClass']() {
        return this.#objClass;
    }
    get ['zIndex']() {
        return this.#zIndex;
    }
    ['getZIndex']() {
        return this.#zIndex;
    }
    ['updateObj']() {
        this.#baseLayer?.['updateLayer']();
    }
    ['setLayer'](_0x175bd4) {
        this.#baseLayer != _0x175bd4 && (this.#baseLayer = _0x175bd4, this['updateObj']());
    }
    ['getLayer']() {
        return this.#baseLayer;
    }
    ['getCanvas']() {
        return this.#baseLayer?.['getCanvas']();
    }
    ['getContext']() {
        return this.#baseLayer?.['getContext2D']();
    }
    ['getDevicePixelRatio']() {
        return this.#baseLayer?.['getDevicePixelRatio']();
    }
    ['getMapCoord']() {
        return this.#baseLayer?.['getMapCoord']();
    }
    ['show']() {
        this.#isVisible != !![] && (this.#isVisible = !![], this['updateObj']());
    }
    ['hide']() {
        this.#isVisible != ![] && (this.#isVisible = ![], this['updateObj']());
    }
    ['on'](_0x36a4e3, _0xacdbfd) {
        let _0x829023 = this['eventHandlers'][_0x36a4e3];
        _0x829023 && (_0x829023 = [], _0x829023['push'](_0xacdbfd));
    }
};
export default logi['maps']['Object'];