































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { mdiChevronLeft } from '@mdi/js'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import FreightInfoItem from '@/views/freightHistory/FreightInfoItem.vue'
import FreightHistory from '@/views/freightHistory/FreightHistory.vue'
import Freight from '@/views/icons/leftPanel/freight.vue'
import {
  formatBizno,
  formatDate,
  formatPhoneNumber, formatPhoneNumberIntegrated,
} from '@/utils/business.util'
import CallerService from '@/services-itgo/caller.service'
import DriverService from '@/services-itgo/driver.service'
import BrokerService from '@/services-itgo/broker.service'
import USER_STATUS from '@/views/constant/user.status'
import ChangeBrokerCompanyPopup from '@/views/management/ChangeBrokerCompanyPopup.vue'
import driverService from '@/services-itgo/driver.service'
import v1AxiosService from '@/services-itgo/v1Axios.service'
import StringUtils from '@/utils/string.utils'
import _ from 'lodash'

@Component({
  components: {
    Freight,
    PerfectScrollbar,
    FreightInfoItem,
    FreightHistory,
    ChangeBrokerCompanyPopup,
  },

  mounted() {
    /** 주선사 회사일 경우 */
    if (this.type === USER_STATUS.USER_TYPE_BROKER && !this.isBroker) {
      this.userInfo = this.item
      return
    }
    this.fetchUserInfo()
  },
})
export default class UserInfoTab extends Vue {
  @Prop(Number) id
  @Prop(Number) type
  @Prop(Object) item
  @Prop(Boolean) isBroker

  icons = {
    mdiChevronLeft,
  }

  userInfo: any = null

  get personalInfo() {
    return [
      {
        title: '개인 정보',
        data: [
          {
            title: '회원명',
            content: this.userInfo?.name || '-',
            show: true,
            btnShow: false,
          },
          {
            title: '전화번호',
            content: formatPhoneNumber(this.userInfo?.mobile),
            show: true,
            btnShow: false,
          },
          {
            title: '생년월일',
            content: formatDate(this.userInfo?.birthDt, 'YYYY.MM.DD'),
            show: true,
            btnShow: false,
          },
          {
            title: '아이디',
            content: this.userInfo?.loginId || '-',
            show: true,
            btnShow: false,
          },
          {
            title: '소셜계정',
            content: this.userInfo?.connectedSocialList,
            show: this.type === USER_STATUS.USER_TYPE_DRIVER && this.userInfo?.connectedSocialList?.length,
            btnShow: false,
          },
          {
            title: '가입일',
            content: formatDate(this.userInfo?.confirmDt, 'YYYY-MM-DD'),
            show: true,
            btnShow: false,
          },
          {
            title: '탈퇴일',
            content: formatDate(this.userInfo?.withdrawalDt, 'YYYY-MM-DD'),
            show: true,
            btnShow: false,
          },
        ],
        show:
          (this.type === USER_STATUS.USER_TYPE_BROKER && this.isBroker) ||
          this.type === USER_STATUS.USER_TYPE_DRIVER ||
          this.type === USER_STATUS.USER_TYPE_CALLER,
      },
    ]
  }

  get businessInfo() {
    return [
      {
        title: '사업자 정보',
        data: [
          {
            title: '대표자명',
            content:
              this.userInfo?.representative ||
              this.userInfo?.brokerCompanyRepresentative ||
              this.userInfo?.driverCompanyRepresentative ||
              this.userInfo?.callerCompanyRepresentative ||
              '-',
            show: true,
            btnShow: false,
          },
          {
            title: '사업자 등록 번호',
            content: formatBizno(
              this.userInfo?.businessRegNum ||
              this.userInfo?.brokerCompanyBusinessRegNum ||
                this.userInfo?.driverCompanyBusinessRegNum ||
                this.userInfo?.callerCompanyBusinessRegNum,
            ),
            show: true,
            btnShow: false,
          },
          {
            title: '상호명',
            content:
              this.userInfo?.companyName ||
              this.userInfo?.brokerCompanyName ||
              this.userInfo?.driverCompanyName ||
              this.userInfo?.callerCompanyName ||
              '-',
            show: true,
            btnShow: false,
          },
          {
            title: '주소',
            content:
              this.userInfo?.address ||
              this.userInfo?.brokerCompanyAddress ||
              this.userInfo?.driverCompanyAddress ||
              this.userInfo?.callerCompanyAddress ||
              '-',
            show: true,
            btnShow: false,
          },
          {
            title: '상세주소',
            content:
              this.userInfo?.addressDetail ||
              this.userInfo?.brokerCompanyAddressDetail ||
              this.userInfo?.driverCompanyAddressDetail ||
              this.userInfo?.callerCompanyAddressDetail ||
              '-',
            show: true,
            btnShow: false,
          },
          {
            title: '회계 담당자명',
            content: this.userInfo?.brokerCompanyContactName || this.userInfo?.contactName || '-',
            show: this.type === USER_STATUS.USER_TYPE_BROKER,
            btnShow: false,
          },
          {
            title: '이메일',
            content:
              this.userInfo?.email ||
              this.userInfo?.driverCompanyEmail ||
              this.userInfo?.callerCompanyEmail ||
              this.userInfo?.brokerCompanyEmail ||
              '-',
            show:
              this.type === USER_STATUS.USER_TYPE_DRIVER ||
              this.type === USER_STATUS.USER_TYPE_BROKER,
            btnShow: false,
          },
          {
            title: '은행 정보',
            content: this.userInfo?.bankName || '-',
            show:
              this.type === USER_STATUS.USER_TYPE_DRIVER || 
              this.type === USER_STATUS.USER_TYPE_BROKER && this.isBroker,
            btnShow: false,
          },
          {
            title: '계좌 번호',
            content: this.userInfo?.accountNumber || '-',
            show: 
              this.type === USER_STATUS.USER_TYPE_DRIVER || 
              this.type === USER_STATUS.USER_TYPE_BROKER && this.isBroker,
            btnShow: false,
          },
          {
            title: '배차 수신 번호',
            content: formatPhoneNumberIntegrated(this.userInfo?.telephone),
            show: this.type === USER_STATUS.USER_TYPE_BROKER && this.isBroker,
            btnShow: false,
          },
        ],
        show: true,
      },
    ]
  }

  get vehicleInfo() {
    return [
      {
        title: '차량 정보',
        data: [
          {
            title: '차량 번호',
            content: this.userInfo?.registrationNumber || '-',
            show: true,
            btnShow: false,
          },
          {
            title: '차량 톤수',
            content: this.userInfo?.weight ? `${this.userInfo?.weight}톤` : '-',
            show: true,
            btnShow: false,
          },
          {
            title: '차량 종류',
            content: this.userInfo?.vehicleType || '-',
            show: true,
            btnShow: false,
          },
          {
            title: '차량 옵션',
            content: this.userInfo?.vehicleOption || '-',
            show: true,
            btnShow: false,
          },
          // {
          //   title: '적재 중량',
          //   content: this.userInfo?.maxPayload
          //     ? `${this.userInfo?.maxPayload}t`
          //     : '-',
          //   show: true,
          //   btnShow: false,
          // },
          {
            title: '총 중량',
            content: this.userInfo?.totalWeight
              ? `${this.userInfo?.totalWeight} kg`
              : '-',
            show: true,
            btnShow: false,
          },
          {
            title: '적재 높이',
            content: this.userInfo?.loadingHeight
              ? `${this.userInfo?.loadingHeight}m`
              : '-',
            show: true,
            btnShow: false,
          },
        ],
        show: this.type === USER_STATUS.USER_TYPE_DRIVER,
      },
    ]
  }

  get brokerInfo() {
    return [
      {
        title: '추천인 정보',
        data: [
          {
            title: '주선사명',
            content: this.userInfo?.recommendName || '-',
            show: true,
            btnShow: true,
          },
          {
            title: '사업자 등록 번호',
            content: formatBizno(this.userInfo?.recommendBusinessRegNum),
            show: true,
            btnShow: false,
          },
        ],
        show: this.type === USER_STATUS.USER_TYPE_DRIVER,
      },
    ]
  }

  async fetchUserInfo() {
    try {
      if (!this.id) return

      this.userInfo = null

      const fetchMethod =
        this.type === USER_STATUS.USER_TYPE_CALLER
          ? this.getCallerInfo
          : this.type === USER_STATUS.USER_TYPE_DRIVER
          ? this.getDriverInfo
          : this.getBrokerInfo

      const data = await fetchMethod()

      this.userInfo = data
      console.log(this.userInfo)
      this.$emit("userInfoDetail", this.userInfo)

    } catch (error: any) {
      this.$alert(error.response.data.errMsg)
    }
  }

  async getCallerInfo() {
    return CallerService.getCallerUserInfo(this.id)
  }

  async getDriverInfo() {
    if(_.isEqual(`${process.env.VUE_APP_REST_API_HOST}`, 'https://itgo-tms-admin-dev.logisteq.com'))
      return DriverService.getDriverUserInfo(this.id)
    else {
      const result = await v1AxiosService.getDriverInfoWithDriverGrade(this.id)
      return StringUtils.snakeToCamelCase(result)
    }
  }

  async getBrokerInfo() {
    return BrokerService.getBrokerUserInfo(this.id)
  }

  openChangeBrokerCompany = false
  openChangedKey = 0

  onClickChangeBrokerCompany() {
    this.openChangeBrokerCompany = true
    this.openChangedKey = this.openChangedKey + 1
  }

  async modifyRecommendBroker(recommendId) {
    try {
      const params = {
        driverId: this.item.driverId,
        recommendId,
      }

      return driverService.modifyRecommendBroker(params)
    } catch (error: any) {
      this.$alert(error.response.data.errMsg)
    }
  }

  async onChangeBrokerCompany(companyId) {
    await this.modifyRecommendBroker(companyId)

    this.openChangeBrokerCompany = false

    this.fetchUserInfo()
  }

  onCloseChangeBrokerCompany() {
    this.openChangeBrokerCompany = false
  }
}
