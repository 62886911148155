<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="viewBox"
  >
    <g
      id="그룹_41658"
      data-name="그룹 41658"
      transform="translate(-1488.5 -149.5)"
    >
      <g
        id="그룹_40927"
        data-name="그룹 40927"
        transform="translate(1418 -200)"
      >
        <g
          transform="matrix(1, 0, 0, 1, 70.5, 349.5)"
          filter="url(#패스_29526)"
        >
          <path
            id="패스_29526-2"
            data-name="패스 29526"
            d="M4,0H30a4,4,0,0,1,4,4V30a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4V4A4,4,0,0,1,4,0Z"
            transform="translate(4.5 4.5)"
            fill="#fff"
            opacity="0.9"
          />
        </g>
      </g>
      <g
        id="그룹_40926"
        data-name="그룹 40926"
        transform="translate(1401 -242.279)"
      >
        <g id="그룹_40887" data-name="그룹 40887" transform="translate(0.886)">
          <path
            id="패스_29500"
            data-name="패스 29500"
            d="M-14962.472-13477.722h5.172v5.437"
            transform="translate(-14845.771 -13068.006) rotate(-180)"
            fill="none"
            stroke="#2f2f2f"
            stroke-linejoin="round"
            stroke-width="1.6"
          />
          <path
            id="패스_29501"
            data-name="패스 29501"
            d="M0,5.437H5.172V0"
            transform="translate(99.528 404.279)"
            fill="none"
            stroke="#2f2f2f"
            stroke-linejoin="round"
            stroke-width="1.6"
          />
        </g>
        <g
          id="그룹_40888"
          data-name="그룹 40888"
          transform="translate(217.114 824.995) rotate(180)"
        >
          <path
            id="패스_29500-2"
            data-name="패스 29500"
            d="M-14962.472-13477.722h5.172v5.437"
            transform="translate(-14845.771 -13068.006) rotate(-180)"
            fill="none"
            stroke="#2f2f2f"
            stroke-linejoin="round"
            stroke-width="1.6"
          />
          <path
            id="패스_29501-2"
            data-name="패스 29501"
            d="M0,5.437H5.172V0"
            transform="translate(99.528 404.279)"
            fill="none"
            stroke="#2f2f2f"
            stroke-linejoin="round"
            stroke-width="1.6"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'small_icon',

  props: {
    viewBox: { type: String, default: '0 0 43 43' },
    width: { type: [Number, String], default: 43 },
    height: { type: [Number, String], default: 43 },
  },
}
</script>

<style scoped></style>
