






































































































































































































































































































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import DatePicker from '@/views/utils/DatePicker.vue'
import Pagination from '@/views/utils/Pagination.vue'
import {
  mdiMagnify,
  mdiChevronUp,
  mdiChevronDown,
  mdiChevronLeft,
} from '@mdi/js'
import vocService from '@/services-itgo/voc.service'
import LoadPopup from '@/views/popup/551X792/LoadPopup.vue'
import UserFindPopup from '@/views/popup/551X792/UserFindPopup.vue'
import DatetimeUtils from '@/utils/datetime.utils'
import StringUtils from '@/utils/string.utils'
//@ts-ignore
import Vue2DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ko'
import _ from 'lodash'
import { maskingName } from '@/utils/business.util'

function getDefaultCategory() {
  return [{ text: '전체', value: '' }]
}

function getDefaultStatus() {
  return [
    { text: '처리 중', value: 0 },
    { text: '처리 완료', value: 1 },
  ]
}

@Component({
  methods: { maskingName },
  components: {
    DatePicker,
    Pagination,
    LoadPopup,
    UserFindPopup,
    Vue2DatePicker,
  },

  mounted() {
    this.fetchCategories()
    this.fetchPageList()
  },
})
export default class vocManagement extends Vue {
  togglevoc = 1

  backToList() {
    this.togglevoc = 1
    this.fetchPageList()
    this.clearCreateContent()
  }

  async backKeyToList() {
    if (this.isChangedContents) {
      const result = await this.$confirm({
        title: '종료 안내',
        content:
          '변경 또는 작성 중인 내용이 있습니다.<br/> 팝업을 닫으면 변경 또는 작성 중인<br/> 내용은 저장되지 않습니다',
      })
      if (result) {
        this.togglevoc = 1
        await this.fetchPageList()
        this.clearCreateContent()
      }
    } else {
      this.togglevoc = 1
      await this.fetchPageList()
      this.clearCreateContent()
    }
  }
  onCreate() {
    this.fetchTemporaryPageList()
    this.togglevoc = 0
    this.vocMode = 'create'
  }

  clearCreateContent() {
    this.vocContents.answer = ''
    this.vocContents.question = ''
    this.vocContents.categoryCreate = ''
    this.vocContents.vocCreate = 0
    this.vocContents.customerName = ''
    this.vocContents.customerMobile = ''
    this.vocContents.customerEmail = ''
    this.vocContents.customerUserId = ''

    // 수정 시
    this.editVocId = null
  }

  public vocMode = 'create'

  editVocId = null
  async onEdit(item) {
    this.vocMode = 'edit'

    try {
      this.fetchTemporaryPageList()

      const params = {
        vocId: item.vocId,
      }
      const result = await vocService.getVocDetails(params)

      console.log(result)
      this.togglevoc = 0
      this.editVocId = item.vocId

      this.vocContents.question = result.question
      this.vocContents.answer = result.answer
      this.vocContents.categoryCreate = result.category
      this.vocContents.vocCreate = result.vocTarget
      this.vocContents.customerName = result.customerName
      this.vocContents.customerMobile = result.customerMobile
      this.vocContents.customerEmail = result.customerEmail
      this.vocContents.customerUserId = result.customerUserId
      this.vocContents.statusCreate = result.vocStatus
    } catch (e) {
      console.log(e)
    }
  }

  /** voc - 메인 */

  icons: any = {
    mdiMagnify,
    mdiChevronDown,
    mdiChevronLeft,
  }

  keyWord = ''

  vocTarget = null
  vocTargetCreateItems = [
    { text: '공통', value: 0 },
    { text: '화주', value: 1 },
    { text: '차주', value: 2 },
    { text: '주선사', value: 3 },
    // { text: '관리자', value: 4 },
  ]
  vocTargetItems = [{ text: '전체', value: null }, ...this.vocTargetCreateItems]

  getTarget(value) {
    return this.vocTargetItems.find((item) => item.value === value)?.text
  }

  getTargetValue(text) {
    return this.vocTargetCreateItems.find((x) => x.text === text)?.value
  }

  category = ''
  categoryItems = getDefaultCategory()
  categoryCreateItems: any = []

  status = null
  statusCreateItems: any = getDefaultStatus()
  statusItems = [{ text: '전체', value: null }, ...getDefaultStatus()]

  date: any = []
  selectedItems: any = []

  @Watch('date')
  changeDates(value) {
    if (value?.length > 1) {
      this.set(value)
    }
  }

  previousDayDate: Date[] | null = null

  async set(value) {
    const temp = [...this.date]
    temp.sort((a, b) => new Date(a).getTime() - new Date(b).getTime())

    if (temp.length === 2) {
      if (DatetimeUtils.asDays(temp[0], temp[1]) >= 30) {
        this.date = this.previousDayDate
        await this.$alert({
          title: '기간 설정 오류',
          content: '기간 설정은 최대 30일까지 가능합니다.',
        })
      } else {
        this.previousDayDate = [...this.date]
      }
    }
  }

  vocInfo = {
    loading: false,
    headers: [
      // 대상, voc유형, 문의자명, 문의내용, 등록일시, 작성자, 상태
      { text: '대상', align: 'center', value: 'vocTarget', width: '5%' },
      { text: 'VOC 유형', align: 'center', value: 'category', width: '10%' },
      {
        text: '문의자명',
        align: 'center',
        value: 'customerName',
        width: '10%',
      },
      {
        text: '문의 내용',
        align: 'start',
        value: 'question',
        width: '40%',
      },
      { text: '등록 일자', align: 'center', value: 'regDt', width: '16%' },
      { text: '작성자', align: 'center', value: 'userName', width: '7%' },
      { text: '상태', align: 'center', value: 'vocStatus', width: '7%' },
      { text: '', align: 'end', value: 'answer', width: '5%' },
    ],
    items: [],
    serverItemsLength: 10,
  }

  isSearched = false

  get totalPageCount() {
    const length = Math.ceil(this.vocInfo.serverItemsLength / this.pageSize)
    return length === 0 ? 1 : length
  }

  pageSizes = [10, 20, 30]
  pageSize = 10
  pageNum = 1

  /**
   * pageSize: 페이지 크기
   * pageNum: 페이지 번호
   */
  @Watch('pageSize')
  onChangePageSize() {
    this.pageNum = 1
    this.fetchPageList()
  }

  @Watch('pageNum')
  onChangePageNum() {
    this.fetchPageList()
  }

  expanded: any = []
  vocDetail: any = {}

  async onRowClicked(item) {
    if (this.expanded.length > 0 && item.vocId === this.expanded[0].vocId) {
      this.expanded = []
    } else {
      try {
        const params = {
          vocId: item.vocId,
        }
        this.vocDetail = await vocService.getVocDetails(params)
        this.expanded = [item]
      } catch (e) {
        console.log(e)
      }
    }
  }

  get vocDetailAnswer() {
    return this.vocDetail || null
  }

  async fetchCategories() {
    try {
      const result = await vocService.getCategories()

      this.categoryItems = [
        ...getDefaultCategory(),
        ...result.map((category) => ({
          text: category,
          value: category,
        })),
      ]

      this.categoryCreateItems = [
        ...result.map((category) => ({
          text: category,
          value: category,
        })),
      ]
    } catch (error) {
      console.error(error)
    }
  }

  async fetchPageList() {
    try {
      this.vocInfo.loading = true

      this.expanded = []
      this.vocInfo.items = []

      const data = await vocService.getPageList({
        vocTargetList: this.vocTarget,
        category: _.isEmpty(this.category) ? null : this.category,
        vocStatusList: this.status,
        keyword: _.isEmpty(this.keyWord) ? null : this.keyWord,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        fromDt: this.date[0]
          ? DatetimeUtils.setTypeStartDate(this.date[0], 'hour')
          : null,
        toDt: this.date[1]
          ? DatetimeUtils.setTypeEndDate(this.date[1], 'hour')
          : null,
        temporary: 0,
      })
      console.log(data)
      this.vocInfo.items = data.content.map((item, index) => ({
        ...item,
        vocTarget:
          this.vocTargetItems.find((_item) => _item.value === item.vocTarget)
            ?.text || '-',
        vocStatus:
          this.statusItems.find((_item) => _item.value === item.vocStatus)
            ?.text || '-',
        key: `${item.question}_${index}`,
      }))

      console.log(this.vocInfo.items)

      this.vocInfo.serverItemsLength = data.totalElementCount
    } catch (err) {
      console.error(err)
    } finally {
      this.vocInfo.loading = false
    }
  }

  onSearch() {
    this.isSearched = true
    this.pageNum = 1
    this.fetchPageList()
  }

  async onClickDeletevoc() {
    if (this.selectedItems.length > 0) {
      try {
        const res = await this.$confirm('해당 VOC를 삭제하시겠습니까?')
        if (res) {
          const vocIds = this.selectedItems.map((x) => x.vocId).join(',')
          await vocService.deleteVoc(vocIds)
          this.selectedItems = []
          await this.fetchPageList()
          this.$toast({ type: 'notice', content: '게시글이 삭제되었습니다.' })
        }
      } catch (e: any) {
        await this.$alert({
          title: 'VOC 삭제 실패',
          content: e.response.data.errMsg,
        })
      }
    }
  }

  formattedText(content) {
    return StringUtils.formattedContentText(content)
  }

  /**
   * voc - 관리자 - 작성
   */

  vocContents = {
    customerName: '',
    customerMobile: '',
    customerEmail: '',
    customerUserId: '',
    question: '',
    answer: '',
    categoryCreate: '',
    vocCreate: 0,
    statusCreate: 0,
  }

  isChangedContents: boolean = false

  @Watch('vocContents', { deep: true })
  changedVocContents(value) {
    console.log(value)
    if (this.togglevoc === 0) this.isChangedContents = false
    else this.isChangedContents = true
    console.log(this.isChangedContents)
  }

  temporaryItems = [] as any
  isLoadPopup = false

  toggleLoadPopup() {
    this.isLoadPopup = !this.isLoadPopup
  }

  loadPopupClose() {
    this.isLoadPopup = false
    this.fetchPageList()
  }

  // 불러오기 삭제
  async deleteItems(payload) {
    if (payload.length > 0) {
      try {
        const vocIds = payload.map((x) => x.vocId).join(',')
        await vocService.deleteVoc(vocIds)
        await this.fetchTemporaryPageList()
        this.toggleLoadPopup()
      } catch (e: any) {
        await this.$alert({
          title: 'voc 불러오기 삭제 실패',
          content: e.response.data.errMsg,
        })
      }
    }
  }

  // 불러오기
  // async fetchCategoryDetail(vocId) {
  //   try {
  //     const res = await vocService.getVocDetails(vocId)
  //     return res
  //   } catch (e) {
  //     console.error(e)
  //   }
  // }

  async fetchTemporaryPageList() {
    try {
      const params = {
        temporary: true,
      }
      const res = await vocService.getPageList(params)
      this.temporaryItems = res.content.map((x) => ({
        ...x,
        title: x.question,
      }))
    } catch (err) {
      console.error(err)
    } finally {
    }
  }

  async loadItem(payload) {
    if (payload) {
      this.vocContents.question = payload.question
      this.vocContents.answer = payload.answer
      this.vocContents.categoryCreate = payload.category
      this.vocContents.vocCreate = payload.vocTarget
      this.vocContents.customerName = payload.customerName
      this.vocContents.customerMobile = payload.customerMobile
      this.vocContents.customerEmail = payload.customerEmail
      this.vocContents.customerUserId = payload.customerUserId
      this.vocContents.statusCreate = payload.vocStatus
    }
  }

  // 등록하기
  public isRegisterLoading: boolean = false

  async savevoc(temporary) {
    const params = {
      vocId: this.editVocId,
      temporary: temporary,
      vocTarget: this.vocContents.vocCreate,
      vocStatus: this.vocContents.statusCreate,
      category: this.vocContents.categoryCreate,
      customerName: this.vocContents.customerName,
      customerMobile: this.vocContents.customerMobile,
      customerEmail: this.vocContents.customerEmail,
      customerUserId: this.vocContents.customerUserId,
      question: this.vocContents.question,
      answer: this.vocContents.answer,
    }

    try {
      if (this.editVocId) {
        if (temporary) {
          this.isRegisterLoading = true
          const temporaryStorage = await this.$confirm({
            title: '임시저장 안내',
            content: '작성하신 내용을 임시 저장하시겠습니까?',
          })
          if (temporaryStorage) {
            await vocService.postRegisterVoc(params)
            await this.fetchTemporaryPageList()

            await this.$toast({
              type: 'notice',
              content: '임시 저장 되었습니다',
            })
          }
          this.isRegisterLoading = false
        } else {
          this.isRegisterLoading = true
          await vocService.putEditVoc(params)
          await this.$toast({
            type: 'notice',
            content: `VOC가 수정 되었습니다.`,
          })
          this.isRegisterLoading = false
          this.backToList()
        }
      } else {
        if (!temporary) {
          this.isRegisterLoading = true
          const result = await this.$confirm({
            title: 'VOC 등록',
            content: '작성하신 내용으로 VOC를<br/> 등록 하시겠습니까?',
          })
          if (result) {
            await vocService.postRegisterVoc(params)
            await this.$toast({
              type: 'notice',
              content: `VOC가 등록 되었습니다.`,
            })
            this.backToList()
          }
          this.isRegisterLoading = false
        } else {
          this.isRegisterLoading = true
          const temporaryStorage = await this.$confirm({
            title: '임시저장 안내',
            content: '작성하신 내용을 임시 저장하시겠습니까?',
          })
          if (temporaryStorage) {
            await vocService.postRegisterVoc(params)
            await this.fetchTemporaryPageList()
            await this.$toast({
              type: 'notice',
              content: '임시 저장 되었습니다',
            })
          }
          this.isRegisterLoading = false
        }
        // await vocService.postRegisterVoc(params)
      }
    } catch (e: any) {
      this.isRegisterLoading = false
      const errCode = e.response.data.errCode
      let errContent = ''

      if (errCode === 'E0010')
        errContent = '미입력 또는 미선택한 항목이 존재합니다.'
      else errContent = e.response.data.errMsg

      await this.$alert({
        title: 'VOC 등록/수정 실패',
        content: errContent,
      })
    } finally {
      this.isRegisterLoading = false
    }
  }

  /**
   * voc - 회원찾기 관련
   */

  name = ''
  email = ''
  mobileNo = ''

  user = {
    name: '',
    email: '',
    mobileNo: '',
  }
  isFindUserPopup = false
  toggleFindUserPopup() {
    this.isFindUserPopup = !this.isFindUserPopup
  }

  updateUser(payload) {
    this.vocContents.customerName = payload.name
    this.vocContents.customerEmail = payload.email
    this.vocContents.customerMobile = payload.mobile
    this.vocContents.customerUserId = payload.userId
  }

  findUserPopupClose() {
    this.isFindUserPopup = false
    this.fetchPageList()
  }

  getExpansionStatus(item) {
    if (!this.expanded.length) return false

    return Boolean(this.expanded?.find((_item) => _item.vocId === item.vocId))
  }
}
