var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "d-flex flex-column",
      attrs: { height: "792px", width: "551px" },
    },
    [
      _c(
        "v-card-title",
        {
          staticClass:
            "font-size24 surface800 font-weight900 d-flex justify-space-between",
        },
        [
          _c("span", [_vm._v(_vm._s(_vm.state) + " 전송")]),
          _c(
            "v-btn",
            {
              staticClass: "mt-n2 me-n3",
              attrs: { icon: "" },
              on: {
                click: function ($event) {
                  return _vm.cancelBtn()
                },
              },
            },
            [
              _c("v-icon", { attrs: { size: "25" } }, [
                _vm._v(" " + _vm._s(_vm.icons.mdiWindowClose) + " "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-card-text", { staticClass: "mt-n4 pb-0" }, [_c("v-divider")], 1),
      _c(
        "v-card-text",
        [
          _c("v-text-field", {
            attrs: {
              label: "제목",
              placeholder: "제목",
              "persistent-placeholder": "",
              outlined: "",
              "no-resize": "",
              dense: "",
            },
            model: {
              value: _vm.title,
              callback: function ($$v) {
                _vm.title = $$v
              },
              expression: "title",
            },
          }),
          _c("div", { staticClass: "d-flex justify-end" }, [
            _vm._v(_vm._s(_vm.title.length) + "/40"),
          ]),
        ],
        1
      ),
      _c(
        "v-card-text",
        [
          _c("v-textarea", {
            attrs: {
              label: "내용",
              placeholder: "내용",
              "persistent-placeholder": "",
              outlined: "",
              "no-resize": "",
              rows: "10",
            },
            model: {
              value: _vm.content,
              callback: function ($$v) {
                _vm.content = $$v
              },
              expression: "content",
            },
          }),
          _c("div", { staticClass: "d-flex justify-end" }, [
            _vm._v(
              " " +
                _vm._s(_vm.content.length) +
                "/" +
                _vm._s(_vm.contentLimit) +
                " "
            ),
          ]),
        ],
        1
      ),
      _c("v-spacer"),
      _c("v-card-text", { staticClass: "mt-n4 pb-0" }, [_c("v-divider")], 1),
      _c(
        "v-card-actions",
        { staticClass: "d-flex justify-center mb-5" },
        [
          _c(
            "v-btn",
            {
              staticClass: "popup_btn",
              attrs: { color: "primary" },
              on: { click: _vm.confirmBtn },
            },
            [_vm._v("확인")]
          ),
          _c(
            "v-btn",
            { staticClass: "popup_btn", on: { click: _vm.cancelBtn } },
            [_vm._v("취소")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }