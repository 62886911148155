



















































































































































































































































































import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { State } from 'vuex-class'
import _ from 'lodash'

import Pagination from '@/views/utils/Pagination.vue'
import Vue2DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ko'
import DatetimeUtils from '@/utils/datetime.utils'

import {
  mdiMagnify,
  mdiChevronUp,
  mdiChevronDown,
  mdiCircleOutline,
  mdiCircleSlice8,
} from '@mdi/js'
import { SVC_DATA } from '@/services-itgo/services-constant/svc.constant'
import SvcService from '@/services-itgo/svc.service'
import callRequestService from '@/services-itgo/call.request.service'
import CircularProgressPopup from '@/views/popup/CircularProgressPopup.vue'

@Component({
  components: {
    CircularProgressPopup,
    Pagination,
    Vue2DatePicker,
  },

  mounted() {
    this.dayDate = this.baseDate
    this.selectDateType.dayType = true
    this.getUsageHistory()
  },
})
export default class BrokerUtilization extends Vue {
  @State((state) => state.auth.user) loginUser

  icons: any = {
    mdiMagnify,
    mdiChevronUp,
    mdiChevronDown,
    mdiCircleOutline,
    mdiCircleSlice8,
  }

  selectDateType = {
    dayType: false,
    monthType: false,
    yearType: false,
  }

  dayDate: any = [new Date(), new Date()]
  monthDate: any = DatetimeUtils.getMonth()
  yearDate: any = DatetimeUtils.getYear()

  get baseDate() {
    const today = new Date()
    const beforeWeek = new Date(today)
    beforeWeek.setDate(today.getDate())
    return [beforeWeek, today]
  }

  @Watch('dayDate')
  changeDates(value) {
    if (value?.length > 1) {
      this.set(value)
    }
  }

  previousDayDate: Date[] | null = null

  async set(value) {
    const temp = [...this.dayDate]
    temp.sort((a, b) => new Date(a).getTime() - new Date(b).getTime())

    if (temp.length === 2) {
      if (DatetimeUtils.asDays(temp[0], temp[1]) >= 30) {
        this.dayDate = this.previousDayDate || this.baseDate
        await this.$alert({
          title: '기간 설정 오류',
          content: '기간 설정은 최대 30일까지 가능합니다.',
        })
      } else {
        this.previousDayDate = [...this.dayDate]
      }
    }
  }

  dateTypeSelect(type) {
    switch (type) {
      case 'day':
        if (this.monthDate === null) this.monthDate = DatetimeUtils.getMonth()
        if (this.yearDate === null) this.yearDate = DatetimeUtils.getYear()
        this.selectDateType.monthType = false
        this.selectDateType.yearType = false
        this.selectDateType.dayType = true
        break
      case 'month':
        if (this.dayDate[0] === null && this.dayDate[1] === null) {
          this.dayDate = [new Date(), new Date()]
        }
        if (this.yearDate === null) this.yearDate = DatetimeUtils.getYear()
        this.selectDateType.dayType = false
        this.selectDateType.yearType = false
        this.selectDateType.monthType = true
        break
      case 'year':
        if (this.dayDate[0] === null && this.dayDate[1] === null) {
          this.dayDate = [new Date(), new Date()]
        }
        if (this.monthDate === null) this.monthDate = DatetimeUtils.getMonth()
        this.selectDateType.dayType = false
        this.selectDateType.monthType = false
        this.selectDateType.yearType = true
        break
      default:
        break
    }
  }

  keyword = ''

  onSearch() {
    this.queryParams.pageNum = 1
    this.setInputDate()
  }

  async setInputDate() {
    if (this.selectDateType.dayType) {
      if (this.dayDate[0] === null && this.dayDate[1] === null) {
        await this.$alert({
          title: '기간 설정 오류',
          content: '검색 기간을 설정해 주세요.',
        })
        this.dayDate = [new Date(), new Date()]
      } else {
        if (DatetimeUtils.asDays(this.dayDate[0], this.dayDate[1]) > 30) {
          await this.$alert({
            title: '기간 설정 오류',
            content: '기간 설정은 최대 30일까지 가능합니다.',
          })
        } else {
          this.queryParams.fromDt = DatetimeUtils.stringDate(
            this.dayDate[0],
            'YYYY-MM-DD',
          )
          this.queryParams.toDt = DatetimeUtils.stringDate(
            this.dayDate[1],
            'YYYY-MM-DD',
          )
          this.queryParams.interval = 'daily'
          await this.getUsageHistory()
        }
      }
    } else if (this.selectDateType.monthType) {
      if (this.monthDate === null) {
        await this.$alert({
          title: '기간 설정 오류',
          content: '검색 기간을 설정해 주세요.',
        })
        this.monthDate = DatetimeUtils.getMonth()
      } else {
        this.queryParams.fromDt = DatetimeUtils.stringDate(
          this.monthDate,
          'YYYY-MM',
        )
        this.queryParams.toDt = DatetimeUtils.stringDate(
          this.monthDate,
          'YYYY-MM',
        )
        this.queryParams.interval = 'monthly'
        await this.getUsageHistory()
      }
    } else {
      if (this.yearDate === null) {
        await this.$alert({
          title: '기간 설정 오류',
          content: '검색 기간을 설정해 주세요.',
        })
        this.yearDate = DatetimeUtils.getYear()
      } else {
        this.queryParams.fromDt = DatetimeUtils.stringDate(
          this.yearDate,
          'YYYY',
        )
        this.queryParams.toDt = DatetimeUtils.stringDate(this.yearDate, 'YYYY')
        this.queryParams.interval = 'yearly'
        await this.getUsageHistory()
      }
    }
  }

  public currentTab: number = 0

  public tabs = [
    { name: '차주앱_주요화면', label: '차주앱_주요화면' },
    {
      name: '차주앱_운송기능',
      label: '차주앱_운송기능',
    },
    {
      name: '주선사웹_주요화면',
      label: '주선사웹_주요화면',
    },
    {
      name: '주선사웹_화물등록',
      label: '주선사웹_화물등록',
    },
    { name: '화주앱_주요화면', label: '화주앱_주요화면' },
    { name: '화주앱_화물', label: '화주앱_화물' },
  ]

  queryParams: any = {
    domain: SVC_DATA.NAME_SVC_DOMAIN_STRING(0),
    interval: 'daily',
    fromDt: DatetimeUtils.now_format('YYYY-MM-DD'),
    toDt: DatetimeUtils.now_format('YYYY-MM-DD'),
    companyNameKeyword: null,
    pageNum: 1,
    pageSize: 10,
  }

  queryScenarioParams: any = {
    domain: SVC_DATA.NAME_SVC_DOMAIN_STRING(0),
    interval: 'daily',
    dt: null,
    companyId: null,
  }

  excelParams: any = {
    domain: SVC_DATA.NAME_SVC_DOMAIN_STRING(0),
    interval: 'daily',
    fromDt: DatetimeUtils.now_format('YYYY-MM-DD'),
    toDt: DatetimeUtils.now_format('YYYY-MM-DD'),
    companyNameKeyword: null,
  }

  get usageHistoryInfo() {
    return this.tabData
  }

  @Watch('currentTab')
  getCurrentTabData() {
    this.initQueryParams()
    this.getTableHeaders()
    this.getUsageHistory()
  }

  initQueryParams() {
    this.dayDate = [new Date(), new Date()]
    this.monthDate = DatetimeUtils.getMonth()
    this.yearDate = DatetimeUtils.getYear()
    this.selectDateType.dayType = true
    this.selectDateType.monthType = false
    this.selectDateType.yearType = false
    this.queryParams.interval = 'daily'
    this.queryParams.fromDt = DatetimeUtils.now_format('YYYY-MM-DD')
    this.queryParams.toDt = DatetimeUtils.now_format('YYYY-MM-DD')
    this.queryParams.companyNameKeyword = null
    this.queryParams.pageNum = 1
    this.queryParams.pageSize = 10
    this.keyword = ''
    this.expanded = []
  }

  get isBroker() {
    return (
      this.queryParams.domain === 'broker_main' ||
      this.queryParams.domain === 'broker_call'
    )
  }

  getTableHeaders() {
    this.queryParams.domain = SVC_DATA.NAME_SVC_DOMAIN_STRING(this.currentTab)
    this.tabData.headers = []

    if (this.isBroker) {
      this.tabData.headers = [
        {
          text: '주선사명',
          align: 'center',
          value: 'companyName',
          width: '10%',
        },
        { text: '날짜', align: 'center', value: 'period', width: '10%' },
        {
          text: '총 방문자 수',
          align: 'left',
          value: 'periodUv',
          width: '20%',
        },
        {
          text: '총 페이지뷰 수',
          align: 'left',
          value: 'periodPv',
          width: '55%',
        },
        { text: '', align: 'center', value: 'answer', width: '5%' },
      ]
    } else {
      this.tabData.headers = [
        { text: '날짜', align: 'center', value: 'period', width: '10%' },
        {
          text: '총 방문자 수',
          align: 'left',
          value: 'periodUv',
          width: '20%',
        },
        {
          text: '총 페이지뷰 수',
          align: 'left',
          value: 'periodPv',
          width: '65%',
        },
        { text: '', align: 'center', value: 'answer', width: '5%' },
      ]
    }
  }

  isCircularLoading = false
  strContentText = ''

  async getUsageHistory() {
    try {
      this.isCircularLoading = true
      this.strContentText = '조회 중입니다...'
      this.queryParams.companyNameKeyword = this.keyword || null
      const result = await SvcService.getSvcPeriodList(this.queryParams)
      this.tabData.items = result.content.map((x) => ({
        ...x,
        key: x.companyId ? x.companyId + x.period : x.period,
      }))
      this.totalPageCount = result.totalPageCount

      this.setTableHeaders()
    } catch (e) {
      await this.$alert({
        title: '사용 통계 실패',
        content: e.response.data.errMsg,
      })
    }finally {
      this.isCircularLoading = false
    }
  }

  tabData: any = {
    headers: [
      { text: '날짜', align: 'center', value: 'period', width: '10%' },
      {
        text: '총 방문자 수',
        align: 'left',
        value: 'periodUv',
        width: '20%',
      },
      {
        text: '총 페이지뷰 수',
        align: 'left',
        value: 'periodPv',
        width: '65%',
      },
      { text: '', align: 'center', value: 'answer', width: '5%' },
    ],
    items: [],
    extends: [],
  }

  historyContentsInfo: any = {
    headers: [
      { text: '구분', align: 'center', value: 'categoryName', width: '20%' },
      {
        text: '시나리오명',
        align: 'start',
        value: 'scenarioName',
        width: '50%',
      },
      { text: 'UV', align: 'center', value: 'scenarioUv', width: '15%' },
      { text: 'PV', align: 'center', value: 'scenarioPv', width: '15%' },
    ],
    items: [],
  }

  setTableHeaders() {
    if (this.selectDateType.yearType) {
      if (this.isBroker) {
        this.tabData.headers = [
          {
            text: '주선사명',
            align: 'center',
            value: 'companyName',
            width: '10%',
          },
          { text: '날짜', align: 'center', value: 'period', width: '10%' },
          {
            text: '총 페이지뷰 수',
            align: 'left',
            value: 'periodPv',
            width: '75%',
          },
          { text: '', align: 'center', value: 'answer', width: '5%' },
        ]
      } else {
        this.tabData.headers = [
          { text: '날짜', align: 'center', value: 'period', width: '10%' },
          {
            text: '총 페이지뷰 수',
            align: 'left',
            value: 'periodPv',
            width: '85%',
          },
          { text: '', align: 'center', value: 'answer', width: '5%' },
        ]
      }

      this.historyContentsInfo.headers = [
        { text: '구분', align: 'center', value: 'categoryName', width: '20%' },
        {
          text: '시나리오명',
          align: 'start',
          value: 'scenarioName',
          width: '50%',
        },
        { text: 'PV', align: 'center', value: 'scenarioPv', width: '15%' },
      ]
    } else {
      if (this.isBroker) {
        this.tabData.headers = [
          {
            text: '주선사명',
            align: 'center',
            value: 'companyName',
            width: '10%',
          },
          { text: '날짜', align: 'center', value: 'period', width: '10%' },
          {
            text: '총 방문자 수',
            align: 'left',
            value: 'periodUv',
            width: '20%',
          },
          {
            text: '총 페이지뷰 수',
            align: 'left',
            value: 'periodPv',
            width: '55%',
          },
          { text: '', align: 'center', value: 'answer', width: '5%' },
        ]
      } else {
        this.tabData.headers = [
          { text: '날짜', align: 'center', value: 'period', width: '10%' },
          {
            text: '총 방문자 수',
            align: 'left',
            value: 'periodUv',
            width: '20%',
          },
          {
            text: '총 페이지뷰 수',
            align: 'left',
            value: 'periodPv',
            width: '65%',
          },
          { text: '', align: 'center', value: 'answer', width: '5%' },
        ]
      }

      this.historyContentsInfo.headers = [
        { text: '구분', align: 'center', value: 'categoryName', width: '20%' },
        {
          text: '시나리오명',
          align: 'start',
          value: 'scenarioName',
          width: '50%',
        },
        { text: 'UV', align: 'center', value: 'scenarioUv', width: '15%' },
        { text: 'PV', align: 'center', value: 'scenarioPv', width: '15%' },
      ]
    }
  }

  expanded: any = []

  isExpanded(key) {
    if (!this.expanded.length) return

    return this.expanded?.find((item) => item.key === key)
  }

  async onRowClicked(item) {
    try{
      this.isCircularLoading = true
      this.strContentText = '조회 중입니다...'
      this.historyContentsInfo.items = []
      if (this.expanded.length > 0 && item.key === this.expanded[0].key) {
        this.expanded = []
      } else {
        this.expanded = [item]

        this.queryScenarioParams.domain = this.queryParams.domain
        this.queryScenarioParams.interval = this.queryParams.interval
        this.queryScenarioParams.dt = item.period
        if (item.companyId) this.queryScenarioParams.companyId = item.companyId
        else this.queryScenarioParams.companyId = null

        console.log(this.queryScenarioParams)
        const result = await SvcService.getSvcScenarioList(
          this.queryScenarioParams,
        )

        this.historyContentsInfo.items = result.content
        console.log(this.historyContentsInfo.items)
      }
    }catch (e) {
      await this.$alert({
        title: '사용 통계 실패',
        content: e.response.data.errMsg,
      })
    }finally {
      this.isCircularLoading = false
    }
  }

  get historyDetail() {
    return this.historyContentsInfo
  }

  get historyDetailCount() {
    return _.size(this.historyContentsInfo.items)
  }

  totalPageCount = 1
  pageSizes = [10, 20, 30]

  @Watch('queryParams.pageSize')
  onChangePageSize() {
    this.queryParams.pageNum = 1
    this.getUsageHistory()
  }

  @Watch('queryParams.pageNum')
  onChangePageNum() {
    this.getUsageHistory()
  }

  async onClickDownloadExcel() {
    try {
      this.isCircularLoading = true
      this.strContentText = '다운로드 진행 중입니다...'
      this.excelParams.domain = this.queryParams.domain
      this.excelParams.interval = this.queryParams.interval
      this.excelParams.fromDt = this.queryParams.fromDt
      this.excelParams.toDt = this.queryParams.toDt
      this.excelParams.companyNameKeyword =
        this.queryParams.companyNameKeyword || null

      let dateExcel = ''
      if (this.selectDateType.dayType) {
        dateExcel = `${DatetimeUtils.stringDate(
          this.dayDate[0],
          'YYYY-MM-DD',
        )}~${DatetimeUtils.stringDate(this.dayDate[1], 'YYYY-MM-DD')}`
      } else if (this.selectDateType.monthType) {
        dateExcel = DatetimeUtils.stringDate(this.monthDate, 'YYYY-MM')
      } else if (this.selectDateType.yearType) {
        dateExcel = DatetimeUtils.stringDate(this.yearDate, 'YYYY')
      }

      const nameExcel =
        SVC_DATA.NAME_SVC_DOMAIN_KOR_STRING(this.excelParams.domain) +
        dateExcel +
        '.xlsx'

      const data = await SvcService.getSvcExcelDownload(this.excelParams)

      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      const link = document.createElement('a')
      const blobUrl = URL.createObjectURL(blob)
      link.href = blobUrl
      link.download = nameExcel
      link.click()
      URL.revokeObjectURL(blobUrl)
    } catch (e) {
      await this.$alert({
        title: '엑셀 생성 실패',
        content: e.response.data.errMsg,
      })
    } finally {
      this.isCircularLoading = false
    }
  }
}
