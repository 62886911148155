<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="viewBox"
  >
    <g id="ic_event" transform="translate(14347 14004)">
      <g id="그룹_41722" data-name="그룹 41722" transform="translate(-5.682 -2.344)">
        <path id="Icon_feather-star" data-name="Icon feather-star" d="M6.949,3,8.17,5.472l2.729.4L8.924,7.795l.466,2.717L6.949,9.228,4.509,10.512l.466-2.717L3,5.871l2.729-.4Z" transform="translate(-14334.266 -13992.205)" fill="none" :stroke="iconColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"/>
        <path id="패스_29564" data-name="패스 29564" d="M18.462,4.145H8.154V3H7.009V4.145H4.145A1.145,1.145,0,0,0,3,5.291V21.326a1.145,1.145,0,0,0,1.145,1.145H22.471a1.145,1.145,0,0,0,1.145-1.145V5.291a1.145,1.145,0,0,0-1.145-1.145H19.608V3H18.462ZM7.009,5.291H4.145V7.581H22.471V5.291H7.009ZM22.471,8.727v12.6H4.145V8.727Z" transform="translate(-14340.626 -14000.392)" :fill="iconColor" :stroke="iconColor" stroke-width="0.5" fill-rule="evenodd"/>
      </g>
      <rect id="사각형_31853" data-name="사각형 31853" width="28" height="28" transform="translate(-14347 -14004)" fill="none"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'event',

  props: {
    viewBox: { type: String, default: '0 0 28 28' },
    iconName: { type: String, default: '' },
    width: { type: [Number, String], default: 28 },
    height: { type: [Number, String], default: 28 },
    iconColor: { type: String, default: '#979eaa' },
  },
}
</script>

<style scoped></style>
