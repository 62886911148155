var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-card-title",
        { staticClass: "font-size24 surface800 popup-title" },
        [
          _c("span", [_vm._v("변경 이력")]),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { icon: "" },
              on: {
                click: function ($event) {
                  return _vm.$emit("historyPopupHide")
                },
              },
            },
            [
              _c("v-icon", { attrs: { size: "25" } }, [
                _vm._v(" " + _vm._s(_vm.icons.mdiWindowClose) + " "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card-text",
        { staticClass: "py-0 my-0" },
        [_c("v-divider", { staticClass: "pa-0 ma-0" })],
        1
      ),
      _c(
        "v-card-text",
        {
          staticClass: "field-54",
          staticStyle: { "padding-bottom": "0 !important" },
        },
        [
          _c("v-card", { attrs: { outlined: "", color: "transparent" } }, [
            _c(
              "div",
              [
                _c(
                  "v-list",
                  [
                    _vm._l(_vm.termsHistoryData, function (item) {
                      return [
                        _c(
                          "v-card",
                          { staticClass: "my-2", attrs: { outlined: "" } },
                          [
                            _c(
                              "v-card-text",
                              {
                                staticClass:
                                  "d-flex justify-space-between align-center",
                              },
                              [
                                _c("span", { staticClass: "font-weight700" }, [
                                  _vm._v(_vm._s(item.termsStatus) + " "),
                                ]),
                                _c("span", [_vm._v(_vm._s(item.regDt))]),
                              ]
                            ),
                            _c(
                              "v-card-text",
                              { staticClass: "my-0 px-0" },
                              [
                                _c("v-divider", {
                                  staticStyle: { margin: "0 !important" },
                                }),
                              ],
                              1
                            ),
                            _c("v-card-text", [
                              _c("div", [
                                _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(item.changedType) +
                                      " | " +
                                      _vm._s(item.changedName) +
                                      " "
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "mt-2" }, [
                                _c("span", [
                                  _vm._v(
                                    " " + _vm._s(item.changedContent) + " "
                                  ),
                                ]),
                              ]),
                            ]),
                          ],
                          1
                        ),
                      ]
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "v-card-actions",
        { staticStyle: { padding: "8px 0 24px 0 !important" } },
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              staticClass: "popup_btn",
              attrs: { color: "primary" },
              on: {
                click: function ($event) {
                  return _vm.$emit("historyPopupHide")
                },
              },
            },
            [_vm._v(" 닫기 ")]
          ),
          _c("v-spacer"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }