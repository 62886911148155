































import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import DatetimeUtils from '@/utils/datetime.utils'
import { mdiCheckboxBlank } from '@mdi/js'
import { formatDate, formatPhoneNumber } from '@/utils/business.util'
import CallRequestService from '@/services-itgo/call.request.service'
import TodayListItem from '@/views/dashBoards/TodayListItem.vue'
import ChartjsComponentLocalDoughnutChart from '@/views/chartjs/charts-components/ChartjsComponentLocalDoughnutChart.vue'

@Component({
  name: "TodaySummary",
  components: { ChartjsComponentLocalDoughnutChart, TodayListItem },
})
export default class TodaySummary extends Vue {
  public icons: any = {
    mdiCheckboxBlank,
  }

  public todayData: any = null

  public locale: any = {
    count: 0,
    totalCount: 0,
  }

  mounted() {
    this.todaySummary()
    this.todayString()
  }

  todayString() {
    const today = DatetimeUtils.now_format('YYYY년 MM월 DD일')
    return today
  }

  getPercentage = (locale) => {
    return locale.count === 0 ? 0 : (locale.count / locale.totalCount) * 100
  }

  async todaySummary() {
    try {
      const data = await CallRequestService.getTodayCount()
      this.locale.totalCount = data?.new || 0
      this.locale.count = data?.assigned || 0
      console.log(data)
      this.todayData = data
    } catch (e) {
      console.log(e)
    }
  }

  get todayRequestCount() {
    return [
      {
        title: '화물 등록',
        content: this.todayData?.new || 0,
        show: true,
      },
      {
        title: '화물 배차 건수',
        content: this.todayData?.assigned || 0,
        show: true,
      },
      {
        title: '화물 배차 취소',
        content: this.todayData?.cancel || 0,
        show: true,
      },
    ]
  }

  setInputDate(item) {
    console.log(item)
    const baseData: any = []
    baseData.push(item.count)
    baseData.push(item.totalCount - item.count)
    console.log(baseData)
    return baseData
  }
}
