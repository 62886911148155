var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-wrapper content_body" },
    [
      _c(
        "div",
        { staticClass: "content-inner" },
        [
          _c(
            "v-card",
            {
              staticClass: "d-flex flex-column justify-space-between",
              staticStyle: { "border-radius": "10px", "min-height": "1073px" },
            },
            [
              _c(
                "div",
                [
                  _c(
                    "div",
                    { staticClass: "content-header" },
                    [
                      _c(
                        "v-card-title",
                        {
                          staticClass:
                            "d-flex justify-space-between align-center px-6 py-7",
                        },
                        [
                          _c("div", [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "font-size26 font-weight700 surface800 mr-5",
                              },
                              [_vm._v(" 통계 ")]
                            ),
                            _c("span", { staticClass: "font-size20" }, [
                              _vm._v("화물 조건별 이용"),
                            ]),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "d-flex justify-end",
                              staticStyle: { width: "20%", gap: "10px" },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "px-2 font-size14",
                                  attrs: {
                                    color: "#183256",
                                    dense: "",
                                    outlined: "",
                                    disabled: !_vm.usageList.items.length,
                                  },
                                  on: { click: _vm.onClickDownloadExcel },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/icons/download.svg"),
                                    },
                                  }),
                                  _c("span", { staticClass: "pl-1" }, [
                                    _vm._v("엑셀 다운로드"),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c("v-card-title", { staticClass: "p-0" }, [
                        _c(
                          "div",
                          { staticClass: "flex-start-contents w-100 pa-6" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "pr-2",
                                staticStyle: { width: "15%" },
                              },
                              [
                                _c("v-select", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    items: _vm.typeItems,
                                    label: "차량 종류",
                                    "item-text": "text",
                                    "item-value": "value",
                                    dense: "",
                                    outlined: "",
                                    "hide-details": "",
                                    "menu-props": {
                                      bottom: true,
                                      offsetY: true,
                                    },
                                  },
                                  model: {
                                    value: _vm.type,
                                    callback: function ($$v) {
                                      _vm.type = $$v
                                    },
                                    expression: "type",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "pr-2",
                                staticStyle: { width: "15%" },
                              },
                              [
                                _c("v-select", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    items: _vm.tonItems,
                                    label: "차량 톤수",
                                    "item-text": "text",
                                    "item-value": "value",
                                    dense: "",
                                    outlined: "",
                                    "hide-details": "",
                                    "menu-props": {
                                      bottom: true,
                                      offsetY: true,
                                    },
                                  },
                                  model: {
                                    value: _vm.ton,
                                    callback: function ($$v) {
                                      _vm.ton = $$v
                                    },
                                    expression: "ton",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "pr-2",
                                staticStyle: { width: "15%" },
                              },
                              [
                                _c("v-select", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    items: _vm.distanceItems,
                                    label: "거리",
                                    "item-text": "text",
                                    "item-value": "value",
                                    dense: "",
                                    outlined: "",
                                    "hide-details": "",
                                    "menu-props": {
                                      bottom: true,
                                      offsetY: true,
                                    },
                                  },
                                  model: {
                                    value: _vm.distance,
                                    callback: function ($$v) {
                                      _vm.distance = $$v
                                    },
                                    expression: "distance",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "pr-2",
                                staticStyle: { width: "15%" },
                              },
                              [
                                _c("v-select", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    items: _vm.paymentItems,
                                    label: "결제 방식",
                                    "item-text": "text",
                                    "item-value": "value",
                                    dense: "",
                                    outlined: "",
                                    "hide-details": "",
                                    "menu-props": {
                                      bottom: true,
                                      offsetY: true,
                                    },
                                  },
                                  model: {
                                    value: _vm.payment,
                                    callback: function ($$v) {
                                      _vm.payment = $$v
                                    },
                                    expression: "payment",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c("hr", { staticClass: "divider" }),
                        _c(
                          "div",
                          {
                            staticClass: "flex-start-contents w-100 pa-6 pb-0",
                          },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass:
                                  "itgoWhite--text px-3 font-size18 region-btn",
                                attrs: { color: "itgoInfo" },
                                on: { click: _vm.onClickSetRegion },
                              },
                              [_vm._v(" 지역 설정 ")]
                            ),
                            _c(
                              "div",
                              { staticClass: "region-box" },
                              _vm._l(_vm.selectRegion, function (item, idx) {
                                return _c(
                                  "span",
                                  {
                                    key: idx,
                                    staticClass: "select-region-btn",
                                  },
                                  [
                                    _vm._v(" " + _vm._s(item) + " "),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { icon: "", "x-small": "" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.onRemoveResion(idx)
                                          },
                                        },
                                      },
                                      [
                                        _c("v-icon", { attrs: { small: "" } }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.icons.mdiClose) +
                                              " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }),
                              0
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "flex-start-contents w-100 pa-6" },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: { width: "25px" },
                                attrs: { clase: "pr-2 d-flex align-center" },
                              },
                              [
                                _c("v-checkbox", {
                                  staticClass: "mt-0 v-check-customer",
                                  attrs: {
                                    dense: "",
                                    "hide-details": "",
                                    color: "accent",
                                    readonly: _vm.selectDateType.dayType,
                                    "on-icon": _vm.icons.mdiCircleSlice8,
                                    "off-icon": _vm.icons.mdiCircleOutline,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.dateTypeSelect("day")
                                    },
                                  },
                                  model: {
                                    value: _vm.selectDateType.dayType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.selectDateType,
                                        "dayType",
                                        $$v
                                      )
                                    },
                                    expression: "selectDateType.dayType",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "pb-1 mr-4",
                                staticStyle: { width: "30px" },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "font-size14 surface800" },
                                  [_vm._v("일별")]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "mr-8",
                                staticStyle: { width: "249px" },
                              },
                              [
                                _c("vue2-date-picker", {
                                  staticClass: "management",
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    disabled: !_vm.selectDateType.dayType,
                                    type: "date",
                                    range: "",
                                    lang: "ko",
                                  },
                                  model: {
                                    value: _vm.dayDate,
                                    callback: function ($$v) {
                                      _vm.dayDate = $$v
                                    },
                                    expression: "dayDate",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: { width: "25px" },
                                attrs: { clase: "pr-2 d-flex align-center" },
                              },
                              [
                                _c("v-checkbox", {
                                  staticClass: "mt-0 v-check-customer",
                                  attrs: {
                                    dense: "",
                                    "hide-details": "",
                                    color: "accent",
                                    readonly: _vm.selectDateType.monthType,
                                    "on-icon": _vm.icons.mdiCircleSlice8,
                                    "off-icon": _vm.icons.mdiCircleOutline,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.dateTypeSelect("month")
                                    },
                                  },
                                  model: {
                                    value: _vm.selectDateType.monthType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.selectDateType,
                                        "monthType",
                                        $$v
                                      )
                                    },
                                    expression: "selectDateType.monthType",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "pb-1 mr-4",
                                staticStyle: { width: "30px" },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "font-size14 surface800" },
                                  [_vm._v("월별")]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "mr-8",
                                staticStyle: { width: "175px" },
                              },
                              [
                                _c("vue2-date-picker", {
                                  staticClass: "management",
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    disabled: !_vm.selectDateType.monthType,
                                    "default-value": _vm.monthDate,
                                    type: "month",
                                    lang: "ko",
                                  },
                                  model: {
                                    value: _vm.monthDate,
                                    callback: function ($$v) {
                                      _vm.monthDate = $$v
                                    },
                                    expression: "monthDate",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: { width: "25px" },
                                attrs: { clase: "pr-2 d-flex align-center" },
                              },
                              [
                                _c("v-checkbox", {
                                  staticClass: "mt-0 v-check-customer",
                                  attrs: {
                                    dense: "",
                                    "hide-details": "",
                                    color: "accent",
                                    readonly: _vm.selectDateType.yearType,
                                    "on-icon": _vm.icons.mdiCircleSlice8,
                                    "off-icon": _vm.icons.mdiCircleOutline,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.dateTypeSelect("year")
                                    },
                                  },
                                  model: {
                                    value: _vm.selectDateType.yearType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.selectDateType,
                                        "yearType",
                                        $$v
                                      )
                                    },
                                    expression: "selectDateType.yearType",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "pb-1 mr-4",
                                staticStyle: { width: "30px" },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "font-size14 surface800" },
                                  [_vm._v("연별")]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "pr-2",
                                staticStyle: { width: "175px" },
                              },
                              [
                                _c("vue2-date-picker", {
                                  staticClass: "management",
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    disabled: !_vm.selectDateType.yearType,
                                    type: "year",
                                    "default-value": _vm.yearDate,
                                    lang: "ko",
                                  },
                                  model: {
                                    value: _vm.yearDate,
                                    callback: function ($$v) {
                                      _vm.yearDate = $$v
                                    },
                                    expression: "yearDate",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]),
                      _c("v-divider", { staticClass: "ma-0 pa-0" }),
                      _c("v-card-title", { staticClass: "px-6 py-6" }, [
                        _c(
                          "div",
                          { staticClass: "flex-start-contents w-100" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "pr-2",
                                staticStyle: { width: "30%" },
                              },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    label: "주선사명으로 검색",
                                    "append-icon": _vm.icons.mdiMagnify,
                                    dense: "",
                                    clearable: "",
                                    "hide-details": "",
                                    outlined: "",
                                  },
                                  on: {
                                    keypress: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      return _vm.onSearch.apply(null, arguments)
                                    },
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "append",
                                      fn: function () {
                                        return [
                                          _c(
                                            "v-icon",
                                            { on: { click: _vm.onSearch } },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.icons.mdiMagnify) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                  model: {
                                    value: _vm.keyword,
                                    callback: function ($$v) {
                                      _vm.keyword = $$v
                                    },
                                    expression: "keyword",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "pr-2",
                                staticStyle: {
                                  width: "12%",
                                  "margin-top": "4px",
                                },
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass:
                                      "itgoWhite--text px-2 mt-n1 font-size18 search-btn",
                                    attrs: { color: "itgoInfo" },
                                    on: { click: _vm.onSearch },
                                  },
                                  [_vm._v(" 조회 ")]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-data-table",
                    _vm._b(
                      {
                        staticStyle: { "min-height": "540px !important" },
                        attrs: {
                          page: _vm.pageNum,
                          "item-key": "key",
                          "hide-default-footer": "",
                          "disable-sort": "",
                          "item-class": function () {
                            return "row-style"
                          },
                        },
                        on: {
                          "update:page": function ($event) {
                            _vm.pageNum = $event
                          },
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "no-data",
                            fn: function () {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.keyword
                                        ? "검색 결과가 없습니다."
                                        : "화물 조건별 이용 내역이 없습니다."
                                    ) +
                                    " "
                                ),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "item.driverVehicleWeight",
                            fn: function (ref) {
                              var value = ref.value
                              return [_vm._v(" " + _vm._s(value || 0) + "톤 ")]
                            },
                          },
                          {
                            key: "item.expectedDistance",
                            fn: function (ref) {
                              var value = ref.value
                              return [_vm._v(" " + _vm._s(value || 0) + "km ")]
                            },
                          },
                        ]),
                      },
                      "v-data-table",
                      _vm.usageList,
                      false
                    )
                  ),
                ],
                1
              ),
              _c("Pagination", {
                attrs: {
                  pageSizes: _vm.pageSizes,
                  totalPageCount: _vm.totalPageCount,
                  pageSize: _vm.pageSize,
                  pageNum: _vm.pageNum,
                },
                on: {
                  "update:pageSize": function ($event) {
                    _vm.pageSize = $event
                  },
                  "update:page-size": function ($event) {
                    _vm.pageSize = $event
                  },
                  "update:pageNum": function ($event) {
                    _vm.pageNum = $event
                  },
                  "update:page-num": function ($event) {
                    _vm.pageNum = $event
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("freight-use-popup", {
        attrs: { item: _vm.selectRegion },
        on: { close: _vm.onCloseSetRegion },
        model: {
          value: _vm.openFreightUsePopup,
          callback: function ($$v) {
            _vm.openFreightUsePopup = $$v
          },
          expression: "openFreightUsePopup",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }