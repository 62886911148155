





































import { Component, Prop, Vue } from 'vue-property-decorator'
import { mdiWindowClose } from '@mdi/js'
import driverService from '@/services-itgo/driver.service'
import externalService from '@/services-itgo/external.service'

@Component
export default class LocationsCargoAroundPopup extends Vue {
  icons: any = {
    mdiWindowClose,
  }

  @Prop({ required: true }) driverId!: number

  public lastUpdateTime: string = ''
  public lastLocation: string = ''

  mounted() {
    this.aroundCargoInfo()
  }

  async aroundCargoInfo() {
    try {
      const data = await driverService.getDriverLocation(this.driverId)
      this.lastUpdateTime = data.updateDt

      const xCoord = data.point.x.toFixed(7)
      const yCoord = data.point.y.toFixed(7)

      const addressData = await externalService.extSearchCoord(xCoord, yCoord)
      this.lastLocation =
        addressData.searchcoord[0].intersectinglocality ||
        `경도: ${xCoord}, 위도: ${yCoord}`
    } catch (error) {
      console.error(error)
    }
  }
}
