var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout-content-vertical-nav",
    {
      attrs: { "nav-menu-items": _vm.masterMenuItems },
      scopedSlots: _vm._u([
        {
          key: "navbar",
          fn: function (ref) {
            var toggleVerticalNavMenuActive = ref.toggleVerticalNavMenuActive
            return [
              _c("div", { staticClass: "navbar-content-container" }, [
                _c(
                  "div",
                  { staticClass: "d-flex align-lg-start" },
                  [
                    _vm.$vuetify.breakpoint.mdAndDown
                      ? _c(
                          "v-icon",
                          {
                            staticClass: "me-3 ml-3",
                            on: { click: toggleVerticalNavMenuActive },
                          },
                          [_vm._v(" " + _vm._s(_vm.icons.mdiMenu) + " ")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "d-flex align-center right-row" },
                  [
                    _c("app-bar-notification"),
                    _c("app-bar-user-menu"),
                    _c("app-bar-company"),
                  ],
                  1
                ),
              ]),
            ]
          },
        },
      ]),
    },
    [_c("router-view", { key: _vm.$route.fullPath })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }