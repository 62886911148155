
export default {
  props: {
    value: { type: Boolean },
    width: { type: [String, Number], default: 480 },
    height: { type: [String, Number], default: 280 },
    title: { type: String, default: '' },
    content: { type: String, default: '' },
    persistent: { type: Boolean, default: true },
    confirmText: { type: String, default: '확인' },
  },
  data() {
    return {}
  },
  computed: {},
  mounted() {},
  methods: {
    onClose() {
      this.$emit('close')
    },

    onClickConfirm() {
      this.$emit('confirm')
    },
  },
}
