var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: _vm.width,
        height: _vm.height,
        viewBox: _vm.viewBox,
      },
    },
    [
      _c(
        "g",
        {
          attrs: {
            id: "그룹_40431",
            "data-name": "그룹 40431",
            transform: "translate(34 -303)",
          },
        },
        [
          _c(
            "g",
            {
              attrs: {
                id: "volume_up",
                transform: "translate(-31.193 307.307)",
              },
            },
            [
              _c("path", {
                attrs: {
                  id: "패스_26776",
                  "data-name": "패스 26776",
                  d: "M21.311,13.749A4.311,4.311,0,0,1,17,18.06V19.5A5.749,5.749,0,1,0,17,8V9.437A4.311,4.311,0,0,1,21.311,13.749Z",
                  transform: "translate(-0.362 -4.055)",
                  fill: _vm.iconColor,
                  stroke: _vm.iconColor,
                  "stroke-width": "0.4",
                },
              }),
              _c("path", {
                attrs: {
                  id: "패스_26777",
                  "data-name": "패스 26777",
                  d: "M17,14.67A2.335,2.335,0,1,0,17,10v1.168A1.168,1.168,0,1,1,17,13.5Z",
                  transform: "translate(-0.362 -2.642)",
                  fill: _vm.iconColor,
                  stroke: _vm.iconColor,
                  "stroke-width": "0.4",
                },
              }),
              _c("path", {
                attrs: {
                  id: "패스_26778",
                  "data-name": "패스 26778",
                  d: "M7.087,16.894V9.889H4.168v7.006Zm1.168.156V9.732l8.757-4.865V21.916ZM7.67,8.721l8.774-4.874a1.168,1.168,0,0,1,1.735,1.021V21.916a1.168,1.168,0,0,1-1.735,1.021L7.67,18.062h-3.5A1.168,1.168,0,0,1,3,16.894V9.889A1.168,1.168,0,0,1,4.168,8.721Z",
                  transform: "translate(-3 -3.698)",
                  fill: _vm.iconColor,
                  stroke: _vm.iconColor,
                  "stroke-width": "0.5",
                  "fill-rule": "evenodd",
                },
              }),
            ]
          ),
          _c("rect", {
            attrs: {
              id: "사각형_31090",
              "data-name": "사각형 31090",
              width: "28",
              height: "28",
              transform: "translate(-34 303)",
              fill: "none",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }