

































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import DatePicker from '@/views/utils/DatePicker.vue'
import Pagination from '@/views/utils/Pagination.vue'
import MainView from '@/views/statistics/view/MainView.vue'
import MainFeature from '@/views/statistics/view/MainFeature.vue'
import { mdiMagnify, mdiChevronDown } from '@mdi/js'
import { State } from 'vuex-class'
import gradeService from '@/services-itgo/grade.service'
import USER_STATUS from '@/views/constant/user.status'
import VEHICLE_TYPE from '@/views/constant/vehicle.type.constant'
import TON_SELECT from '@/views/constant/freight.weight.type.constant'
import PAYMENT_METHOD from '@/views/constant/freight.payment.type.constant'

@Component({
  components: {
    DatePicker,
    Pagination,
    MainView,
    MainFeature
  },

  mounted() {
    this.fetchGradeList()
  },
})
export default class DriverApp extends Vue {
  @State((state) => state.auth.user) loginUser

  icons: any = {
    mdiMagnify,
    mdiChevronDown,
  }

  tab = null
  tabItems = [{ title: '주요 기능' }, { title: '주요 화면' }]

  date = ['', '']

  type = null
  typeItems = [
    { text: '전체', value: null },
    { text: VEHICLE_TYPE.CARGO_CAR_STRING, value: VEHICLE_TYPE.CARGO_CAR },
    { text: VEHICLE_TYPE.TOP_CAR_STRING, value: VEHICLE_TYPE.TOP_CAR },
    { text: VEHICLE_TYPE.WING_CAR_STRING, value: VEHICLE_TYPE.WING_CAR },
    { text: VEHICLE_TYPE.TRAILER_CAR_STRING, value: VEHICLE_TYPE.TRAILER_CAR },
    { text: VEHICLE_TYPE.HORU_CAR_STRING, value: VEHICLE_TYPE.HORU_CAR },
    {
      text: VEHICLE_TYPE.BANGTONG_CAR_STRING,
      value: VEHICLE_TYPE.BANGTONG_CAR,
    },
  ]

  ton = null
  tonItems = [
    { text: '전체', value: null },
    ...TON_SELECT.TON_TOTAL_LIST.map((item) => ({
      text: item.name,
      value: item.value,
    })),
  ]

  sido = null
  sidoItems = [{ text: '전체', value: null }]

  sigungu = null
  sigunguItems = [{ text: '전체', value: null }]

  emd = null
  emdItems = [{ text: '전체', value: null }]

  distance = null
  distanceItems = [
    { text: '전체', value: null },
    { text: '10km', value: 10 },
    { text: '30km', value: 30 },
    { text: '50km', value: 50 },
    { text: '100km', value: 100 },
    { text: '200km', value: 200 },
    { text: '500km', value: 500 },
  ]

  payment = null
  paymentItems = [
    {
      text: PAYMENT_METHOD.TOTAL_STRING,
      value: PAYMENT_METHOD.TOTAL,
    },
    // {
    //   text: PAYMENT_METHOD.PREPAYMENT_STRING,
    //   value: PAYMENT_METHOD.PREPAYMENT,
    // },
    // {
    //   text: PAYMENT_METHOD.DEFERRED_STRING,
    //   value: PAYMENT_METHOD.DEFERRED,
    // },
    {
      text: PAYMENT_METHOD.INVOICE_STRING,
      value: PAYMENT_METHOD.INVOICE,
    },
    {
      text: PAYMENT_METHOD.CARD_STRING,
      value: PAYMENT_METHOD.CARD,
    },
    {
      text: PAYMENT_METHOD.VIRTUAL_ACCOUNT_STRING,
      value: PAYMENT_METHOD.VIRTUAL_ACCOUNT,
    },
  ]

  keyword = ''

  gradeList = {
    loading: false,
    headers: [
      { text: '날짜', value: 'date', align: 'center', width: '15%' },
      { text: '주선사명', value: 'brokerName', align: 'center', width: '12%' },
      {
        text: '차량 종류',
        value: 'vehicleType',
        align: 'center',
        width: '12%',
      },
      { text: '차량 톤수', value: 'ton', align: 'center', width: '10%' },
      { text: '지역', value: 'address', align: 'center', width: '20%' },
      {
        text: '거리',
        value: 'distance',
        align: 'center',
        width: '10%',
      },
      { text: '결제 방식', value: 'payment', align: 'center', width: '10%' },
      { text: 'PV', value: 'PV', align: 'center', width: '12%' },
    ],
    items: [
      {
        date: '2023-04-05',
        brokerName: '강동물류',
        vehicleType: '윙바디',
        ton: 5,
        address: '서울특별시 강서구 화곡동',
        distance: 10,
        payment: '선불',
        PV: 100,
      },
      {
        date: '2023-04-05',
        brokerName: '강동물류',
        vehicleType: '윙바디',
        ton: 5,
        address: '서울특별시 금천구 가산동',
        distance: 50,
        payment: '착불',
        PV: 200,
      },
      {
        date: '2023-04-05',
        brokerName: '강동물류',
        vehicleType: '윙바디',
        ton: 5,
        address: '서울특별시 강서구 화곡동',
        distance: 30,
        payment: '카드 결제',
        PV: 200,
      },
    ],
    serverItemsLength: 3,
  }

  get totalPageCount() {
    const length = Math.ceil(this.gradeList.serverItemsLength / this.pageSize)
    return length === 0 ? 1 : length
  }

  pageSizes = [10, 20, 30]
  pageSize = 10
  pageNum = 1

  /**
   * pageSize: 페이지 크기
   * pageNum: 페이지 번호
   */
  @Watch('pageSize')
  onChangePageSize() {
    this.pageNum = 1
    this.fetchGradeList()
  }

  @Watch('pageNum')
  onChangePageNum() {
    this.fetchGradeList()
  }

  async fetchGradeList() {
    try {
      console.log('fetchGradeList')

      // this.gradeList.loading = true
      // this.gradeList.items = []
      // this.gradeList.serverItemsLength = 0

      // const params = {
      //   userType: this.type,
      //   keyword: this.keyword,
      //   pageNum: this.pageNum,
      //   pageSize: this.pageSize,
      // }

      // const data = await gradeService.getGradeList(params)

      // this.gradeList.items = data.userList.map((item, index) => ({
      //   ...item,
      //   type: USER_STATUS.GET_USER_TYPE(item.userType),
      //   company:
      //     item.userType === USER_STATUS.USER_TYPE_DRIVER || !item.companyName
      //       ? '-'
      //       : item.companyName,
      //   key: `${item.name}_${item.mobile}_${index}`,
      // }))
      // this.gradeList.serverItemsLength = data.totalCount
    } catch (error: any) {
      // this.$alert(error.response.data.errMsg)
    } finally {
      // this.gradeList.loading = false
    }
  }

  onSearch() {
    this.pageNum = 1
    this.fetchGradeList()
  }

  setDate(value) {
    this.date = [value?.[0], value?.[1]]
  }

  onClickDownloadExcel() {
    console.log('onClickDownloadExcel')
  }
}
