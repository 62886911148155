import _ from 'lodash'

/**
 * 결제 이력 상태
  0 : 결제 승인 요청
  1 : 결제 승인 완료
  2 : 결제 승인 실패
  3 : 결제 취소 요청
  4 : 결제 취소 승인
  5 : 결제 취소 거절
  6 : 결제 취소 완료
  7 : 결제 취소 실패
 */
export = (() => {
  const _PAYMENT_HISTORY_STATUS: any = {
    PAYMENT_REQUEST: 0,
    PAYMENT_COMPLETED: 1,
    PAYMENT_FAIL: 2,
    PAYMENT_CANCEL_REQUEST: 3,
    PAYMENT_CANCEL_APPROVAL: 4,
    PAYMENT_CANCEL_REJECT: 5,
    PAYMENT_CANCEL_COMPLETED: 6,
    PAYMENT_CANCEL_FAIL: 7
  }

  const _PAYMENT_HISTORY_STATUS_STRING: any = {
    PAYMENT_REQUEST: '결제',
    PAYMENT_COMPLETED: '결제 완료',
    PAYMENT_FAIL: '결제 실패',
    PAYMENT_CANCEL_REQUEST: '결제 취소 요청',
    PAYMENT_CANCEL_APPROVAL: '결제 취소 승인',
    PAYMENT_CANCEL_REJECT: '결제 취소 거절',
    PAYMENT_CANCEL_COMPLETED: '결제 취소 완료',
    PAYMENT_CANCEL_FAIL: '결제 취소 실패'
  }

  Object.freeze(_PAYMENT_HISTORY_STATUS)
  Object.freeze(_PAYMENT_HISTORY_STATUS_STRING)

  const _PAYMENT_HISTORY_STATUS_WEB_NAME: Map<number, string> = new Map<
    number,
    string
  >([
    [_PAYMENT_HISTORY_STATUS.PAYMENT_REQUEST, _PAYMENT_HISTORY_STATUS_STRING.PAYMENT_REQUEST],
    [_PAYMENT_HISTORY_STATUS.PAYMENT_COMPLETED, _PAYMENT_HISTORY_STATUS_STRING.PAYMENT_COMPLETED],
    [_PAYMENT_HISTORY_STATUS.PAYMENT_FAIL, _PAYMENT_HISTORY_STATUS_STRING.PAYMENT_FAIL],
    [_PAYMENT_HISTORY_STATUS.PAYMENT_CANCEL_REQUEST, _PAYMENT_HISTORY_STATUS_STRING.PAYMENT_CANCEL_REQUEST],
    [_PAYMENT_HISTORY_STATUS.PAYMENT_CANCEL_APPROVAL, _PAYMENT_HISTORY_STATUS_STRING.PAYMENT_CANCEL_APPROVAL],
    [_PAYMENT_HISTORY_STATUS.PAYMENT_CANCEL_REJECT, _PAYMENT_HISTORY_STATUS_STRING.PAYMENT_CANCEL_REJECT],
    [_PAYMENT_HISTORY_STATUS.PAYMENT_CANCEL_COMPLETED, _PAYMENT_HISTORY_STATUS_STRING.PAYMENT_CANCEL_COMPLETED],
    [_PAYMENT_HISTORY_STATUS.PAYMENT_CANCEL_FAIL, _PAYMENT_HISTORY_STATUS_STRING.PAYMENT_CANCEL_FAIL],
  ])

  return Object.freeze({
    PAYMENT_HISTORY_STATUS_WEB_NAME: (num: number): string => {
      const paymentHistoryStatusName: string | undefined =
      _PAYMENT_HISTORY_STATUS_WEB_NAME.get(num)

      return paymentHistoryStatusName ? paymentHistoryStatusName : '-'
    },
  })
})()
