import _ from 'lodash'

/**
 * 차량 정보
 */
export = (() => {
  const _FREIGHT_DATE_TYPE: any = {
    TODAY: 0, // 당상
    TOMORROW: 1, // 내상
    MONDAY: 2, // 월상
    NIGHT_TIME: 3, // 야상
    SPECIFY_DATE: 4, // 날짜 지정
  }

  const _FREIGHT_PICKUP_DATE_TYPE_STRING: any = {
    TODAY: '당상',
    TOMORROW: '내상',
    MONDAY: '월상',
    NIGHT_TIME: '야상',
    SPECIFY_DATE: '날짜 지정',
  }

  const _FREIGHT_DROPOFF_DATE_TYPE_STRING: any = {
    TODAY: '당착',
    TOMORROW: '내착',
    MONDAY: '월착',
    NIGHT_TIME: '야착',
    SPECIFY_DATE: '날짜 지정',
  }

  Object.freeze(_FREIGHT_DATE_TYPE)
  Object.freeze(_FREIGHT_PICKUP_DATE_TYPE_STRING)

  const _FREIGHT_PICKUP_DATE_TYPE_WEB_NAME: Map<number, string> = new Map<
    number,
    string
  >([
    [_FREIGHT_DATE_TYPE.TODAY, _FREIGHT_PICKUP_DATE_TYPE_STRING.TODAY],
    [_FREIGHT_DATE_TYPE.TOMORROW, _FREIGHT_PICKUP_DATE_TYPE_STRING.TOMORROW],
    [_FREIGHT_DATE_TYPE.MONDAY, _FREIGHT_PICKUP_DATE_TYPE_STRING.MONDAY],
    [
      _FREIGHT_DATE_TYPE.NIGHT_TIME,
      _FREIGHT_PICKUP_DATE_TYPE_STRING.NIGHT_TIME,
    ],
    [
      _FREIGHT_DATE_TYPE.SPECIFY_DATE,
      _FREIGHT_PICKUP_DATE_TYPE_STRING.SPECIFY_DATE,
    ],
  ])

  const _FREIGHT_DROPOFF_DATE_TYPE_WEB_NAME: Map<number, string> = new Map<
    number,
    string
  >([
    [_FREIGHT_DATE_TYPE.TODAY, _FREIGHT_DROPOFF_DATE_TYPE_STRING.TODAY],
    [_FREIGHT_DATE_TYPE.TOMORROW, _FREIGHT_DROPOFF_DATE_TYPE_STRING.TOMORROW],
    [_FREIGHT_DATE_TYPE.MONDAY, _FREIGHT_DROPOFF_DATE_TYPE_STRING.MONDAY],
    [
      _FREIGHT_DATE_TYPE.NIGHT_TIME,
      _FREIGHT_DROPOFF_DATE_TYPE_STRING.NIGHT_TIME,
    ],
    [
      _FREIGHT_DATE_TYPE.SPECIFY_DATE,
      _FREIGHT_DROPOFF_DATE_TYPE_STRING.SPECIFY_DATE,
    ],
  ])

  return Object.freeze({
    TODAY: _FREIGHT_DATE_TYPE.TODAY,
    TOMORROW: _FREIGHT_DATE_TYPE.TOMORROW,
    MONDAY: _FREIGHT_DATE_TYPE.MONDAY,
    TRAILER_CAR: _FREIGHT_DATE_TYPE.TRAILER_CAR,
    SPECIFY_DATE: _FREIGHT_DATE_TYPE.SPECIFY_DATE,

    TODAY_STRING: _FREIGHT_PICKUP_DATE_TYPE_STRING.TODAY,
    TOMORROW_STRING: _FREIGHT_PICKUP_DATE_TYPE_STRING.TOMORROW,
    MONDAY_STRING: _FREIGHT_PICKUP_DATE_TYPE_STRING.MONDAY,
    TRAILER_CAR_STRING: _FREIGHT_PICKUP_DATE_TYPE_STRING.TRAILER_CAR,
    SPECIFY_DATE_STRING: _FREIGHT_PICKUP_DATE_TYPE_STRING.SPECIFY_DATE,

    FREIGHT_PICKUP_DATE_NAME_WEB: (vehicleIndex: number): string => {
      const vehicleName: string | undefined =
        _FREIGHT_PICKUP_DATE_TYPE_WEB_NAME.get(vehicleIndex)
      return vehicleName ? vehicleName : '-'
    },

    FREIGHT_DROPOFF_DATE_NAME_WEB: (vehicleIndex: number): string => {
      const vehicleName: string | undefined =
        _FREIGHT_DROPOFF_DATE_TYPE_WEB_NAME.get(vehicleIndex)
      return vehicleName ? vehicleName : '-'
    },
  })
})()
