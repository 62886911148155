var logi = logi ?? {};
logi['maps'] = logi['maps'] ?? {};
import a18_0x2ab95f from './base/logi-maps-types.js';
logi['maps']['ObjectsInTile'] = a18_0x2ab95f['ObjectsInTile'], logi['maps']['OBJEVENT'] = a18_0x2ab95f['OBJEVENT'], logi['maps']['ALIGN'] = a18_0x2ab95f['ALIGN'];
import a18_0xf94811 from './base/logi-maps-utils.js';
logi['maps']['Utils'] = a18_0xf94811;
import a18_0x1b6c8b from './base/logi-maps-object.js';
logi['maps']['Object'] = a18_0x1b6c8b;
import a18_0x243fe1 from './base/logi-maps-layer.js';
logi['maps']['Layer'] = a18_0x243fe1;
import a18_0x1851fc from './base/logi-maps-boundarydata.js';
logi['maps']['BoundaryData'] = a18_0x1851fc;
import a18_0x305d87 from './base/logi-maps-boundarychecker.js';
logi['maps']['BoundaryChecker'] = a18_0x305d87;
import a18_0x36b632 from './logi-maps-evcsinter.js';
logi['maps']['EvcsInter'] = a18_0x36b632;
import a18_0xeb61f3 from './logi-maps-evcs.js';
logi['maps']['Evcs'] = a18_0xeb61f3, logi['maps']['ObjLayer'] = class extends logi['maps']['Layer'] {
    #imageObjects;
    #tiledImageCacheMap;
    #tiledImageInRect;
    #labelObjects;
    #tiledLabelCacheMap;
    #tiledLabelInRect;
    #lineObjects;
    #polygonObjects;
    #routeObjects;
    #MAX_EVCHARGER_DEL_DELAY_TIME = 0x1f40;
    #MAX_EVCHARGER_CNT = 0x40;
    #evChargerInter;
    #evChargerVisibility;
    #evChargerVisibilityLevels = {
        'min': 0x10,
        'max': 0x12
    };
    #evLoopUpdateId = 0x0;
    #evChargerTiles;
    #evChargerObjects;
    #tiledEvChargerCacheMap;
    #tiledEvChargerInRect;
    #boundaryChecker;
    #freezeCanvas;
    #tapChecker;
    #tapTwice;
    #clickChecker;
    #clickTwice;
    constructor(_0x52e73a, _0x583249) {
        const _0x2abd9f = Math['random']()['toString'](0x24)['slice'](-0x8);
        super(_0x52e73a, _0x583249, 'objlayer_' + _0x2abd9f), this.#imageObjects = new Map(), this.#tiledImageCacheMap = new Map(), this.#tiledImageInRect = new Map(), this.#labelObjects = new Map(), this.#tiledLabelCacheMap = new Map(), this.#tiledLabelInRect = new Map(), this.#lineObjects = new Map(), this.#polygonObjects = new Map(), this.#routeObjects = new Map(), this.#evChargerInter = new logi['maps']['EvcsInter'](), this.#evChargerVisibility = ![], this.#evChargerTiles = new Map(), this.#evChargerObjects = new Map(), this.#tiledEvChargerCacheMap = new Map(), this.#tiledEvChargerInRect = new Map(), this.#boundaryChecker = new logi['maps']['BoundaryChecker'](), this.#freezeCanvas = document['createElement']('canvas'), this.#tapChecker = {
            'tapped': ![],
            'point': {
                'x': 0x0,
                'y': 0x0
            }
        }, this.#tapTwice = ![], this.#clickChecker = {
            'clicked': ![],
            'point': {
                'x': 0x0,
                'y': 0x0
            }
        }, this.#clickTwice = ![];
    }
    #getTouchPoint(_0x1a4252, _0xbedc0a) {
        return {
            'x': _0xbedc0a[0x0]['clientX'] - _0x1a4252['left'],
            'y': _0xbedc0a[0x0]['clientY'] - _0x1a4252['top']
        };
    }
    ['triggerEvent'](_0x4267b8) {
        let _0x456639 = {
            'type': '',
            'point': {
                'x': 0x0,
                'y': 0x0
            }
        };
        switch (_0x4267b8['type']) {
        case 'touchstart': {
                if (_0x4267b8['targetTouches']['length'] == 0x1) {
                    let _0xa6ac2c = this.#getTouchPoint(_0x4267b8['target']['getBoundingClientRect'](), _0x4267b8['targetTouches']);
                    this.#tapChecker['tapped'] = !![], this.#tapChecker['point']['x'] = _0xa6ac2c['x'], this.#tapChecker['point']['y'] = _0xa6ac2c['y'];
                } else
                    this.#tapChecker['tapped'] = ![];
            }
            break;
        case 'touchend': {
                if (this.#tapChecker['tapped'] == !![]) {
                    let _0x4e0fee = this.#getTouchPoint(_0x4267b8['target']['getBoundingClientRect'](), _0x4267b8['changedTouches']);
                    if (Math['abs'](this.#tapChecker['point']['x'] - _0x4e0fee['x']) <= 0x2 && Math['abs'](this.#tapChecker['point']['y'] - _0x4e0fee['y']) <= 0x2) {
                        if (!this.#tapTwice) {
                            this.#tapTwice = !![];
                            const _0x54165b = this;
                            setTimeout(function () {
                                _0x54165b.#tapTwice == !![] && (_0x456639['type'] = logi['maps']['OBJEVENT']['touch'], _0x456639['point']['x'] = _0x4e0fee['x'], _0x456639['point']['y'] = _0x4e0fee['y'], _0x54165b.#sendEvent(_0x456639)), _0x54165b.#tapTwice = ![];
                            }, 0xc8);
                        } else
                            this.#tapTwice = ![], _0x456639['type'] = logi['maps']['OBJEVENT']['dbltouch'], _0x456639['point']['x'] = _0x4e0fee['x'], _0x456639['point']['y'] = _0x4e0fee['y'], this.#sendEvent(_0x456639);
                    }
                }
            }
            break;
        case 'mousedown': {
                let _0x44769a = {
                    'x': _0x4267b8['offsetX'],
                    'y': _0x4267b8['offsetY']
                };
                this.#clickChecker['clicked'] = !![], this.#clickChecker['point']['x'] = _0x44769a['x'], this.#clickChecker['point']['y'] = _0x44769a['y'];
            }
            break;
        case 'mouseup':
            if (this.#clickChecker['clicked'] == !![]) {
                let _0x4e6816 = {
                    'x': _0x4267b8['offsetX'],
                    'y': _0x4267b8['offsetY']
                };
                if (Math['abs'](this.#clickChecker['point']['x'] - _0x4e6816['x']) <= 0x2 && Math['abs'](this.#clickChecker['point']['y'] - _0x4e6816['y']) <= 0x2) {
                    if (!this.#clickTwice) {
                        this.#clickTwice = !![];
                        const _0x1e34ea = this;
                        setTimeout(function () {
                            _0x1e34ea.#clickTwice == !![] && (_0x456639['type'] = logi['maps']['OBJEVENT']['click'], _0x456639['point']['x'] = _0x4e6816['x'], _0x456639['point']['y'] = _0x4e6816['y'], _0x1e34ea.#sendEvent(_0x456639)), _0x1e34ea.#clickTwice = ![];
                        }, 0xc8);
                    } else
                        this.#clickTwice = ![], _0x456639['type'] = logi['maps']['OBJEVENT']['dblclick'], _0x456639['point']['x'] = _0x4e6816['x'], _0x456639['point']['y'] = _0x4e6816['y'], this.#sendEvent(_0x456639);
                }
            }
            break;
        default:
        }
    }
    #sendEvent(_0x43e53c) {
        for (const [_0x2b91ce, _0x5646b7] of this.#tiledLabelInRect) {
            for (const [_0x4667ab, _0x139abc] of _0x5646b7['objects']) {
                const _0x126407 = _0x139abc?.['eventHandlers'][_0x43e53c['type']];
                _0x126407?.['length'] > 0x0 && (_0x139abc['isHit'](_0x43e53c['point']) == !![] && (_0x43e53c['source'] = _0x139abc, _0x126407['forEach']((_0x3ebde7, _0x5ebf5c) => {
                    _0x3ebde7?.(_0x43e53c);
                })));
            }
        }
        for (const [_0x362645, _0x175d0d] of this.#tiledImageInRect) {
            for (const [_0x1c4e94, _0x48871c] of _0x175d0d['objects']) {
                const _0x4e9618 = _0x48871c?.['eventHandlers'][_0x43e53c['type']];
                _0x4e9618?.['length'] > 0x0 && (_0x48871c['isHit'](_0x43e53c['point']) == !![] && (_0x43e53c['source'] = _0x48871c, _0x4e9618['forEach']((_0x5d0920, _0x2b222e) => {
                    _0x5d0920?.(_0x43e53c);
                })));
            }
        }
        for (const [_0x30dfd2, _0x5782e5] of this.#lineObjects) {
            const _0x409c33 = _0x5782e5?.['eventHandlers'][_0x43e53c['type']];
            _0x409c33?.['length'] > 0x0 && (_0x5782e5['isHit'](_0x43e53c['point']) == !![] && (_0x43e53c['source'] = _0x5782e5, _0x409c33['forEach']((_0x41fd13, _0x1c4175) => {
                _0x41fd13?.(_0x43e53c);
            })));
        }
        for (const [_0x43bd9d, _0x10b109] of this.#polygonObjects) {
            const _0x2a7d12 = _0x10b109?.['eventHandlers'][_0x43e53c['type']];
            _0x2a7d12?.['length'] > 0x0 && (_0x10b109['isHit'](_0x43e53c['point']) == !![] && (_0x43e53c['source'] = _0x10b109, _0x2a7d12['forEach']((_0x1e4ecd, _0x5b9688) => {
                _0x1e4ecd?.(_0x43e53c);
            })));
        }
        for (const [_0x14a063, _0x3c35ab] of this.#routeObjects) {
            const _0x4497fa = _0x3c35ab?.['eventHandlers'][_0x43e53c['type']];
            _0x4497fa?.['length'] > 0x0 && (_0x3c35ab['isHit'](_0x43e53c['point']) == !![] && (_0x43e53c['source'] = _0x3c35ab, _0x4497fa['forEach']((_0x17cbdf, _0x5df0f0) => {
                _0x17cbdf?.(_0x43e53c);
            })));
        }
        for (const [_0x12f81f, _0x392548] of this.#tiledEvChargerInRect) {
            for (const [_0x265eb6, _0x6f7123] of _0x392548['objects']) {
                const _0x1dda95 = _0x6f7123?.['eventHandlers'][_0x43e53c['type']];
                _0x1dda95?.['length'] > 0x0 && (_0x6f7123['isHit'](_0x43e53c['point']) == !![] && (_0x43e53c['source'] = _0x6f7123, _0x1dda95['forEach']((_0x52b0e4, _0x44a6fd) => {
                    _0x52b0e4?.(_0x43e53c);
                })));
            }
        }
    }
    ['hitObj'](_0x541d21, _0x2d9174) {
        var _0x34f6b4;
        _0x34f6b4 = this['hitLabel'](_0x541d21, _0x2d9174);
        if (_0x34f6b4 !== null)
            return _0x34f6b4;
        _0x34f6b4 = this['hitImage'](_0x541d21, _0x2d9174);
        if (_0x34f6b4 !== null)
            return _0x34f6b4;
        _0x34f6b4 = this['hitLine'](_0x541d21, _0x2d9174);
        if (_0x34f6b4 !== null)
            return _0x34f6b4;
        _0x34f6b4 = this['hitPolygon'](_0x541d21, _0x2d9174);
        if (_0x34f6b4 !== null)
            return _0x34f6b4;
        _0x34f6b4 = this['hitRoute'](_0x541d21, _0x2d9174);
        if (_0x34f6b4 !== null)
            return _0x34f6b4;
        _0x34f6b4 = this['hitEvCharger'](_0x541d21, _0x2d9174);
        if (_0x34f6b4 !== null)
            return _0x34f6b4;
        return null;
    }
    ['hitLabel'](_0x25dcec, _0x3399d6) {
        const _0x1060de = {
            'x': _0x25dcec,
            'y': _0x3399d6
        };
        for (const [_0x63608e, _0x35e6e6] of this.#tiledLabelInRect) {
            for (const [_0xa4e46d, _0x4490c3] of _0x35e6e6['objects']) {
                if (_0x4490c3?.['isHit'](_0x1060de) == !![])
                    return _0x4490c3;
            }
        }
        return null;
    }
    ['hitImage'](_0x4399b6, _0x7e5161) {
        const _0x9041ac = {
            'x': _0x4399b6,
            'y': _0x7e5161
        };
        for (const [_0xa3b6bf, _0x306a51] of this.#tiledImageInRect) {
            for (const [_0x1e084c, _0x30babd] of _0x306a51['objects']) {
                if (_0x30babd?.['isHit'](_0x9041ac) == !![])
                    return _0x30babd;
            }
        }
        return null;
    }
    ['hitLine'](_0x1d7914, _0x13c684) {
        const _0x4d77f0 = {
            'x': _0x1d7914,
            'y': _0x13c684
        };
        for (const [_0x461a1b, _0x312fde] of this.#lineObjects) {
            if (_0x312fde?.['isHit'](_0x4d77f0) == !![])
                return _0x312fde;
        }
        return null;
    }
    ['hitPolygon'](_0x45012f, _0x32943f) {
        const _0x2323e9 = {
            'x': _0x45012f,
            'y': _0x32943f
        };
        for (const [_0x25018b, _0x4c0e8e] of this.#polygonObjects) {
            if (_0x4c0e8e?.['isHit'](_0x2323e9) == !![])
                return _0x4c0e8e;
        }
        return null;
    }
    ['hitRoute'](_0x1c2199, _0xeb3f87) {
        const _0x120db8 = {
            'x': _0x1c2199,
            'y': _0xeb3f87
        };
        for (const [_0x1d3368, _0x57b14c] of this.#routeObjects) {
            if (_0x57b14c?.['isHit'](_0x120db8) == !![])
                return _0x57b14c;
        }
        return null;
    }
    ['hitEvCharger'](_0x3b2ece, _0x2b3dc5) {
        const _0xcba3b1 = {
            'x': _0x3b2ece,
            'y': _0x2b3dc5
        };
        for (const [_0x21f359, _0x5e341b] of this.#tiledEvChargerInRect) {
            for (const [_0x5c91cd, _0x5c2af8] of _0x5e341b['objects']) {
                if (_0x5c2af8?.['isHit'](_0xcba3b1) == !![])
                    return _0x5c2af8;
            }
        }
        return null;
    }
    ['findObj'](_0x3f0539) {
        var _0x3283f7;
        _0x3283f7 = this['findLabel'](_0x3f0539);
        if (_0x3283f7 !== null)
            return _0x3283f7;
        _0x3283f7 = this['findImage'](_0x3f0539);
        if (_0x3283f7 !== null)
            return _0x3283f7;
        _0x3283f7 = this['findLine'](_0x3f0539);
        if (_0x3283f7 !== null)
            return _0x3283f7;
        _0x3283f7 = this['findPolygon'](_0x3f0539);
        if (_0x3283f7 !== null)
            return _0x3283f7;
        _0x3283f7 = this['findRoute'](_0x3f0539);
        if (_0x3283f7 !== null)
            return _0x3283f7;
        return null;
    }
    ['findImage'](_0x53c0d6) {
        return this.#imageObjects['get'](_0x53c0d6) ?? null;
    }
    ['findLabel'](_0x3c6c9f) {
        return this.#labelObjects['get'](_0x3c6c9f) ?? null;
    }
    ['findLine'](_0x49895f) {
        return this.#lineObjects['get'](_0x49895f) ?? null;
    }
    ['findPolygon'](_0x35cefc) {
        return this.#polygonObjects['get'](_0x35cefc) ?? null;
    }
    ['findRoute'](_0x24d057) {
        return this.#routeObjects['get'](_0x24d057) ?? null;
    }
    ['draw']() {
        this.#boundaryCheck(), this['clear'](), this.#drawObjects();
    }
    ['captureFreezeCanvas']() {
        this.#freezeCanvas['width'] = this['getCanvasWidth'](), this.#freezeCanvas['height'] = this['getCanvasHeight']();
        const _0x4791e3 = this.#freezeCanvas['getContext']('2d');
        _0x4791e3['clearRect'](0x0, 0x0, this['getCanvasWidth'](), this['getCanvasHeight']()), _0x4791e3['drawImage'](this['getCanvas'](), 0x0, 0x0);
    }
    ['drawFreezeCanvas'](_0xf0e573, _0x4f19b0) {
        const _0x5a56e6 = this['getDevicePixelRatio'](), _0x42e6da = this['getContext2D']();
        _0x42e6da['clearRect'](0x0, 0x0, this['getCanvasWidth'](), this['getCanvasHeight']()), _0x42e6da['drawImage'](this.#freezeCanvas, _0xf0e573 * _0x5a56e6, _0x4f19b0 * _0x5a56e6);
    }
    ['addImage'](_0x5be74c) {
        const _0x22e224 = _0x5be74c['getKey']();
        if (this.#imageObjects['has'](_0x22e224)) {
            console['warn']('[logi.maps]\x20' + _0x22e224 + '\x20이미지가\x20이미\x20추가되어\x20있습니다.');
            return;
        }
        return _0x5be74c['getLayer']() && _0x5be74c['getLayer']() != this && (_0x5be74c['getLayer']()['removeImage'](_0x22e224), this.#delTiledImageCacheMap(_0x5be74c['tileInfo']['tileId'], _0x22e224)), _0x5be74c['setLayer'](this), _0x5be74c['resetBoundary'](), this.#imageObjects['set'](_0x22e224, _0x5be74c), this.#addTiledImageCacheMap(_0x5be74c), this['updateLayer'](), _0x5be74c;
    }
    ['isExistImage'](_0x4a58c6) {
        return this.#imageObjects['has'](_0x4a58c6);
    }
    ['removeImage'](_0x4ade7e) {
        if (_0x4ade7e['class']) {
            const _0xbd3455 = _0x4ade7e['class'];
            for (const [_0x455801, _0x48968b] of this.#imageObjects) {
                if (_0x48968b['getClass']() == _0xbd3455) {
                    const _0x5b0c1c = _0x48968b['tileInfo']['tileId'];
                    _0x48968b['setLayer'](null), this.#imageObjects['delete'](_0x455801), this.#delTiledImageCacheMap(_0x5b0c1c, _0x455801);
                }
            }
        } else {
            const _0x59d4e7 = _0x4ade7e['key'] ?? _0x4ade7e, _0x33925c = this.#imageObjects['get'](_0x59d4e7);
            if (_0x33925c) {
                const _0x58bed5 = _0x33925c['tileInfo']['tileId'];
                _0x33925c['setLayer'](null), this.#imageObjects['delete'](_0x59d4e7), this.#delTiledImageCacheMap(_0x58bed5, _0x59d4e7);
            }
        }
        this['updateLayer']();
    }
    ['removeImageAll'](_0x51f30f = []) {
        if (_0x51f30f['length'] == 0x0) {
            for (const [_0x348477, _0x4703c1] of this.#imageObjects) {
                _0x4703c1['expiredTileId'] = !![], _0x4703c1['tileInfo']['tileId'] = 0x0, _0x4703c1['setLayer'](null);
            }
            this.#imageObjects['clear'](), this.#tiledImageCacheMap['clear'](), this.#tiledImageInRect['clear']();
        } else
            for (const _0x4a3ec8 of this.#imageObjects['keys']()) {
                !_0x51f30f['includes'](_0x4a3ec8) && this['removeImage'](_0x4a3ec8);
            }
        this['updateLayer']();
    }
    ['addLabel'](_0x237e3b) {
        const _0x167d17 = _0x237e3b['getKey']();
        if (this.#labelObjects['has'](_0x167d17)) {
            console['warn']('[logi.maps]\x20' + _0x167d17 + '\x20라벨이\x20이미\x20추가되어\x20있습니다.');
            return;
        }
        return _0x237e3b['getLayer']() && _0x237e3b['getLayer']() != this && (_0x237e3b['getLayer']()['removeLabel'](_0x167d17), this.#delTiledLabelCacheMap(_0x237e3b['tileInfo']['tileId'], _0x167d17)), _0x237e3b['setLayer'](this), _0x237e3b['resetBoundary'](), this.#labelObjects['set'](_0x167d17, _0x237e3b), this.#addTiledLabelCacheMap(_0x237e3b), this['updateLayer'](), _0x237e3b;
    }
    ['isExistLabel'](_0x4e3fd1) {
        return this.#labelObjects['has'](_0x4e3fd1);
    }
    ['removeLabel'](_0x357e25) {
        if (_0x357e25['class']) {
            const _0x4a257a = _0x357e25['class'];
            for (const [_0x5ecdce, _0xcf25cf] of this.#labelObjects) {
                if (_0xcf25cf['getClass']() == _0x4a257a) {
                    const _0x2de760 = _0xcf25cf['tileInfo']['tileId'];
                    _0xcf25cf['setLayer'](null), this.#labelObjects['delete'](_0x5ecdce), this.#delTiledLabelCacheMap(_0x2de760, _0x5ecdce);
                }
            }
        } else {
            const _0x2b99dc = _0x357e25['key'] ?? _0x357e25, _0x23be8f = this.#labelObjects['get'](_0x2b99dc);
            if (_0x23be8f) {
                const _0x371b28 = _0x23be8f['tileInfo']['tileId'];
                _0x23be8f['setLayer'](null), this.#labelObjects['delete'](_0x2b99dc), this.#delTiledLabelCacheMap(_0x371b28, _0x2b99dc);
            }
        }
        this['updateLayer']();
    }
    ['removeLabelAll'](_0x35cc7e = []) {
        if (_0x35cc7e['length'] == 0x0) {
            for (const [_0x503f95, _0x395221] of this.#labelObjects) {
                _0x395221['expiredTileId'] = !![], _0x395221['tileInfo']['tileId'] = 0x0, _0x395221['setLayer'](null);
            }
            this.#labelObjects['clear'](), this.#tiledLabelCacheMap['clear'](), this.#tiledLabelInRect['clear']();
        } else
            for (const _0x3b4495 of this.#labelObjects['keys']()) {
                !_0x35cc7e['includes'](_0x3b4495) && this['removeLabel'](_0x3b4495);
            }
        this['updateLayer']();
    }
    ['addLine'](_0x2413c8) {
        const _0x4a0165 = _0x2413c8['getKey']();
        if (this.#lineObjects['has'](_0x4a0165)) {
            console['warn']('[logi.maps]\x20' + _0x4a0165 + '\x20라인이\x20이미\x20추가되어\x20있습니다.');
            return;
        }
        return _0x2413c8['getLayer']() && _0x2413c8['getLayer']() != this && _0x2413c8['getLayer']()['removeImage'](_0x4a0165), _0x2413c8['setLayer'](this), this.#lineObjects['set'](_0x4a0165, _0x2413c8), this['updateLayer'](), _0x2413c8;
    }
    ['isExistLine'](_0x1b8b3e) {
        return this.#lineObjects['has'](_0x1b8b3e);
    }
    ['removeLine'](_0x5b7e20) {
        if (_0x5b7e20['class']) {
            const _0x402bcd = _0x5b7e20['class'];
            for (const [_0x1b5383, _0x249954] of this.#lineObjects) {
                _0x249954['getClass']() == _0x402bcd && (_0x249954['setLayer'](null), this.#lineObjects['delete'](_0x1b5383));
            }
        } else {
            const _0xfbe05 = _0x5b7e20['key'] ?? _0x5b7e20, _0x2ed5f8 = this.#lineObjects['get'](_0xfbe05);
            _0x2ed5f8 && (_0x2ed5f8['setLayer'](null), this.#lineObjects['delete'](_0xfbe05));
        }
        this['updateLayer']();
    }
    ['removeLineAll'](_0x31cada = []) {
        if (_0x31cada['length'] == 0x0) {
            for (const [_0x3ad138, _0xb94d15] of this.#lineObjects) {
                _0xb94d15['setLayer'](null);
            }
            this.#lineObjects['clear']();
        } else
            for (const _0x3ce898 of this.#lineObjects['keys']()) {
                !_0x31cada['includes'](_0x3ce898) && this['removeLine'](_0x3ce898);
            }
        this['updateLayer']();
    }
    ['addPolygon'](_0x994607) {
        const _0x17ac28 = _0x994607['getKey']();
        if (this.#polygonObjects['has'](_0x17ac28)) {
            console['warn']('[logi.maps]\x20' + _0x17ac28 + '\x20폴리곤이\x20이미\x20추가되어\x20있습니다.');
            return;
        }
        return _0x994607['getLayer']() && _0x994607['getLayer']() != this && _0x994607['getLayer']()['removeImage'](_0x17ac28), _0x994607['setLayer'](this), this.#polygonObjects['set'](_0x17ac28, _0x994607), this['updateLayer'](), _0x994607;
    }
    ['isExistPolygon'](_0x438276) {
        return this.#polygonObjects['has'](_0x438276);
    }
    ['removePolygon'](_0x53181d) {
        if (_0x53181d['class']) {
            const _0xcfcda2 = _0x53181d['class'];
            for (const [_0x4afda8, _0x39a79e] of this.#polygonObjects) {
                _0x39a79e['getClass']() == _0xcfcda2 && (_0x39a79e['setLayer'](null), this.#polygonObjects['delete'](_0x4afda8));
            }
        } else {
            const _0x36973a = _0x53181d['key'] ?? _0x53181d, _0x14ae8b = this.#polygonObjects['get'](_0x36973a);
            _0x14ae8b && (_0x14ae8b['setLayer'](null), this.#polygonObjects['delete'](_0x36973a));
        }
        this['updateLayer']();
    }
    ['removePolygonAll'](_0x46601b = []) {
        if (_0x46601b['length'] == 0x0) {
            for (const [_0xadfb5e, _0x1a4787] of this.#polygonObjects) {
                _0x1a4787['setLayer'](null);
            }
            this.#polygonObjects['clear']();
        } else
            for (const _0x4abf18 of this.#polygonObjects['keys']()) {
                !_0x46601b['includes'](_0x4abf18) && this['removePolygon'](_0x4abf18);
            }
        this['updateLayer']();
    }
    ['addRoute'](_0x40cc5e) {
        const _0x186361 = _0x40cc5e['getKey']();
        if (this.#routeObjects['has'](_0x186361)) {
            console['warn']('[logi.maps]\x20' + _0x186361 + '\x20경로가\x20이미\x20추가되어\x20있습니다.');
            return;
        }
        return _0x40cc5e['getLayer']() && _0x40cc5e['getLayer']() != this && _0x40cc5e['getLayer']()['removeImage'](_0x186361), _0x40cc5e['setLayer'](this), this.#routeObjects['set'](_0x186361, _0x40cc5e), this['updateLayer'](), _0x40cc5e;
    }
    ['isExistRoute'](_0x579092) {
        return this.#routeObjects['has'](_0x579092);
    }
    ['removeRoute'](_0x137c33) {
        if (_0x137c33['class']) {
            const _0x21ccfc = _0x137c33['class'];
            for (const [_0x246be2, _0x50d303] of this.#routeObjects) {
                _0x50d303['getClass']() == _0x21ccfc && (_0x50d303['setLayer'](null), this.#routeObjects['delete'](_0x246be2));
            }
        } else {
            const _0x4128c6 = _0x137c33['key'] ?? _0x137c33, _0xf763af = this.#routeObjects['get'](_0x4128c6);
            _0xf763af && (_0xf763af['setLayer'](null), this.#routeObjects['delete'](_0x4128c6));
        }
        this['updateLayer']();
    }
    ['removeRouteAll'](_0x4de78f = []) {
        if (_0x4de78f['length'] == 0x0) {
            for (const [_0xe88593, _0x5355aa] of this.#routeObjects) {
                _0x5355aa['setLayer'](null);
            }
            this.#routeObjects['clear']();
        } else
            for (const _0x2a5a1b of this.#routeObjects['keys']()) {
                !_0x4de78f['includes'](_0x2a5a1b) && this['removeRoute'](_0x2a5a1b);
            }
        this['updateLayer']();
    }
    ['removeAll'](_0x4d5125 = []) {
        this['removeImageAll'](_0x4d5125), this['removeLabelAll'](_0x4d5125), this['removeLineAll'](_0x4d5125), this['removePolygonAll'](_0x4d5125), this['removeRouteAll'](_0x4d5125);
    }
    ['setEvChargerVisibility'](_0xd9e9c8) {
        this.#evChargerVisibility != _0xd9e9c8 && (this.#evChargerVisibility = _0xd9e9c8), _0xd9e9c8 ? this.#evLoopUpdateId == 0x0 && (this.#evLoopUpdateId = setInterval(() => {
            this.#updateEvCharger(), this['updateLayer']();
        }, 0x7d0)) : this.#evLoopUpdateId != 0x0 && (clearInterval(this.#evLoopUpdateId), this.#evLoopUpdateId = 0x0, this.#updateEvCharger(), this['updateLayer']());
    }
    #updateEvCharger() {
        const _0x3dc306 = this['getMapCoord'](), _0xb6c576 = _0x3dc306['getLevel']();
        if (!this.#evChargerVisibility || !(_0xb6c576 >= this.#evChargerVisibilityLevels['min'] && _0xb6c576 <= this.#evChargerVisibilityLevels['max'])) {
            this.#evChargerObjects['size'] > 0x0 && (this.#evChargerTiles = new Map(), this['removeEvChargerAll']());
            return;
        }
        const _0x427a4d = _0x3dc306['getMapRect'](), _0x68dcf1 = _0x3dc306['getTilesInfo'](_0x427a4d['xMin'], _0x427a4d['xMax'], _0x427a4d['yMax'], _0x427a4d['yMin'], this.#evChargerVisibilityLevels['min']);
        this.#evChargerInter['purge']();
        const _0x5ce347 = logi['maps']['Utils']['getCurTick'](), _0x261e03 = this.#evChargerTiles['size'];
        for (const [_0x127360, _0x12e5ac] of this.#evChargerTiles) {
            _0x68dcf1['includes'](_0x127360) ? _0x12e5ac['lastUsedTime'] = _0x5ce347 : _0x261e03 > this.#MAX_EVCHARGER_CNT && (_0x5ce347 > _0x12e5ac['lastUsedTime'] + this.#MAX_EVCHARGER_DEL_DELAY_TIME && (this.#evChargerTiles['delete'](_0x127360), this['removeEvChargers'](_0x127360)));
        }
        for (const _0x3f862f of _0x68dcf1) {
            this.#requestEvChargerQuery(_0x3f862f);
        }
    }
    #requestEvChargerQuery(_0x1df094) {
        this.#evChargerInter['requestQuery'](_0x1df094)['then'](({
            items: _0x5d28a9,
            modifiedTime: _0x2ed20c
        }) => {
            if (_0x5d28a9) {
                const _0x519105 = this.#evChargerTiles['get'](_0x1df094);
                if (_0x519105?.['modifiedTime'] != _0x2ed20c) {
                    const _0x4abeda = logi['maps']['Utils']['getCurTick']();
                    this.#evChargerTiles['set'](_0x1df094, {
                        'modifiedTime': _0x2ed20c,
                        'lastUsedTime': _0x4abeda
                    }), this['removeEvChargers'](_0x1df094);
                    for (const _0x8cef4a of _0x5d28a9) {
                        const _0x51e679 = _0x8cef4a['loc_uid'];
                        let _0x4a5cf0 = this.#evChargerObjects['get'](_0x51e679);
                        if (!_0x4a5cf0) {
                            const _0x14ad8f = {
                                    'lat': parseFloat(_0x8cef4a['latitude']),
                                    'lng': parseFloat(_0x8cef4a['longitude'])
                                }, _0x2eabc6 = new logi['maps']['Evcs']('/img/charger_icon_0.png', _0x14ad8f, {
                                    'key': _0x51e679,
                                    'align': logi['maps']['ALIGN']['CM'],
                                    'tileID': _0x1df094,
                                    'groupId': -0x18
                                });
                            _0x2eabc6['setEvIconSrc']('/img/charger_icon_0.png', '/img/charger_icon_1.png', '/img/charger_icon_2.png'), _0x2eabc6['setInfo'](_0x8cef4a), this['addEvCharger'](_0x2eabc6);
                        }
                    }
                }
            }
        });
    }
    ['addEvCharger'](_0x239c35) {
        const _0x2af8d7 = _0x239c35['getKey']();
        if (this.#evChargerObjects['has'](_0x2af8d7)) {
            console['warn']('[logi.maps]\x20' + _0x2af8d7 + '\x20EvCharger가\x20이미\x20추가되어\x20있습니다.');
            return;
        }
        return _0x239c35['getLayer']() && _0x239c35['getLayer']() != this && (_0x239c35['getLayer']()['removeEvCharger'](_0x2af8d7), this.#delTiledEvChargerCacheMap(_0x239c35['tileInfo']['tileId'], _0x2af8d7)), _0x239c35['setLayer'](this), _0x239c35['resetBoundary'](), this.#evChargerObjects['set'](_0x2af8d7, _0x239c35), this.#addTiledEvChargerCacheMap(_0x239c35), this['updateLayer'](), _0x239c35;
    }
    ['removeEvCharger'](_0x56b4df) {
        let _0x29609e = this.#evChargerObjects['get'](_0x56b4df);
        if (_0x29609e) {
            const _0x48172b = _0x29609e['tileInfo']['tileId'];
            _0x29609e?.['setLayer'](null), this.#evChargerObjects['delete'](_0x56b4df), this.#delTiledEvChargerCacheMap(_0x48172b, _0x56b4df), this['updateLayer']();
        }
    }
    ['removeEvChargers'](_0x257b26) {
        if (this.#evChargerObjects) {
            for (const [_0x2ee39b, _0x11b584] of this.#evChargerObjects) {
                if (_0x257b26 == _0x11b584['getTileId']()) {
                    const _0xb03664 = _0x11b584['tileInfo']['tileId'];
                    _0x11b584['setLayer'](null), this.#evChargerObjects['delete'](_0x2ee39b), this.#delTiledEvChargerCacheMap(_0xb03664, _0x2ee39b);
                }
            }
            this['updateLayer']();
        }
    }
    ['removeEvChargerAll']() {
        for (const [_0x25e890, _0x1428ae] of this.#evChargerObjects) {
            _0x1428ae['expiredTileId'] = !![], _0x1428ae['tileInfo']['tileId'] = 0x0, _0x1428ae['setLayer'](null);
        }
        this.#evChargerObjects['clear'](), this.#tiledEvChargerCacheMap['clear'](), this.#tiledEvChargerInRect['clear'](), this['updateLayer']();
    }
    #drawObjects = async function () {
        const _0x272d92 = this['getMapCoord']()['getLevel']();
        {
            const _0x1bf490 = [];
            for (const [_0x494ff4, _0x19267d] of this.#routeObjects) {
                _0x1bf490['push'](_0x19267d);
            }
            const _0x28b07f = _0x1bf490['sort']((_0x258ae7, _0x3f1208) => _0x258ae7['zIndex'] - _0x3f1208['zIndex']);
            for (const _0x44e7d1 of _0x28b07f) {
                _0x44e7d1['draw']();
            }
        }
        {
            const _0x17d7b0 = [];
            if (_0x272d92 >= this.#evChargerVisibilityLevels['min'] && _0x272d92 <= this.#evChargerVisibilityLevels['max'])
                for (const [_0x56fdad, _0x120425] of this.#tiledEvChargerInRect) {
                    for (const [_0xc45bef, _0x5a6073] of _0x120425['objects']) {
                        _0x5a6073['boundaryData']['bLoad'] === logi['maps']['BoundaryData']['STATUS']['LOAD'] && _0x17d7b0['push'](_0x5a6073);
                    }
                }
            const _0x4ce2b5 = _0x17d7b0['sort']((_0x47ff85, _0x556bc2) => _0x47ff85['zIndex'] - _0x556bc2['zIndex']);
            for (const _0x125d9b of _0x4ce2b5) {
                _0x125d9b['draw']();
            }
        }
        {
            const _0x3963ae = [];
            for (const [_0x3f72e4, _0x6e5355] of this.#lineObjects) {
                _0x3963ae['push'](_0x6e5355);
            }
            const _0xfa99f5 = _0x3963ae['sort']((_0x34bb09, _0x469e5a) => _0x34bb09['zIndex'] - _0x469e5a['zIndex']);
            for (const _0x2699a9 of _0xfa99f5) {
                _0x2699a9['draw']();
            }
        }
        {
            const _0x1beac0 = [];
            for (const [_0x41cce3, _0x231721] of this.#polygonObjects) {
                _0x1beac0['push'](_0x231721);
            }
            const _0x193fa7 = _0x1beac0['sort']((_0x57713f, _0x129a6a) => _0x57713f['zIndex'] - _0x129a6a['zIndex']);
            for (const _0x378b09 of _0x193fa7) {
                _0x378b09['draw']();
            }
        }
        {
            const _0x4d9782 = [];
            for (const [_0x35a9cc, _0x5c9075] of this.#tiledImageInRect) {
                for (const [_0x3ada8d, _0x57f8f2] of _0x5c9075['objects']) {
                    _0x57f8f2['boundaryData']['bLoad'] === logi['maps']['BoundaryData']['STATUS']['LOAD'] && _0x4d9782['push'](_0x57f8f2);
                }
            }
            const _0x370ca4 = _0x4d9782['sort']((_0x9f3ade, _0x36472d) => _0x9f3ade['zIndex'] - _0x36472d['zIndex']);
            for (const _0x2af89b of _0x370ca4) {
                _0x2af89b['draw']();
            }
        }
        {
            const _0x663177 = [];
            for (const [_0x57b1c1, _0x32560a] of this.#tiledLabelInRect) {
                for (const [_0x33002e, _0x2a80d7] of _0x32560a['objects']) {
                    _0x2a80d7['boundaryData']['bLoad'] === logi['maps']['BoundaryData']['STATUS']['LOAD'] && _0x663177['push'](_0x2a80d7);
                }
            }
            const _0x1464d9 = _0x663177['sort']((_0x434bf7, _0x58ecfa) => _0x434bf7['zIndex'] - _0x58ecfa['zIndex']);
            for (const _0x3e4838 of _0x1464d9) {
                _0x3e4838['draw']();
            }
        }
    };
    #boundaryCheck = function () {
        const _0x490cc9 = this['getMapCoord']()['getMapRect']();
        this.#boundaryChecker['cleanBoundary']();
        {
            let _0x14a212 = new Array();
            for (const [_0x590ccf, _0x4dce5b] of this.#tiledImageCacheMap) {
                for (const [_0x866a60, _0x446708] of _0x4dce5b['objects']) {
                    _0x446708['boundaryData']['overlapCnt'] = 0x0, _0x446708['expiredTileId'] == !![] && _0x14a212['push'](_0x446708);
                }
            }
            for (const _0x477cb4 of _0x14a212) {
                this.#delTiledImageCacheMap(_0x477cb4['tileInfo']['tileId'], _0x477cb4['getKey']()), this.#addTiledImageCacheMap(_0x477cb4);
            }
            for (const [_0x10eb71, _0x992e73] of this.#tiledImageInRect) {
                !logi['maps']['Utils']['rectOnRect'](_0x992e73['tileInfo']['boundary']['xMin'], _0x992e73['tileInfo']['boundary']['yMin'], _0x992e73['tileInfo']['boundary']['xMax'], _0x992e73['tileInfo']['boundary']['yMax'], _0x490cc9['xMin'], _0x490cc9['yMin'], _0x490cc9['xMax'], _0x490cc9['yMax']) && this.#tiledImageInRect['delete'](_0x10eb71);
            }
        }
        {
            let _0x4c9a18 = new Array();
            for (const [_0x2467a7, _0x4bb8a0] of this.#tiledLabelCacheMap) {
                for (const [_0xbeff10, _0xfb9997] of _0x4bb8a0['objects']) {
                    _0xfb9997['boundaryData']['overlapCnt'] = 0x0, _0xfb9997['expiredTileId'] == !![] && _0x4c9a18['push'](_0xfb9997);
                }
            }
            for (const _0x51d88 of _0x4c9a18) {
                this.#delTiledLabelCacheMap(_0x51d88['tileInfo']['tileId'], _0x51d88['getKey']()), this.#addTiledLabelCacheMap(_0x51d88);
            }
            for (const [_0x330104, _0x308a11] of this.#tiledLabelInRect) {
                !logi['maps']['Utils']['rectOnRect'](_0x308a11['tileInfo']['boundary']['xMin'], _0x308a11['tileInfo']['boundary']['yMin'], _0x308a11['tileInfo']['boundary']['xMax'], _0x308a11['tileInfo']['boundary']['yMax'], _0x490cc9['xMin'], _0x490cc9['yMin'], _0x490cc9['xMax'], _0x490cc9['yMax']) && this.#tiledLabelInRect['delete'](_0x330104);
            }
        }
        {
            let _0x2f2021 = new Array();
            for (const [_0x14a0a6, _0x4777d4] of this.#tiledEvChargerCacheMap) {
                for (const [_0x30939d, _0xabe8e2] of _0x4777d4['objects']) {
                    _0xabe8e2['boundaryData']['overlapCnt'] = 0x0, _0xabe8e2['expiredTileId'] == !![] && _0x2f2021['push'](_0xabe8e2);
                }
            }
            for (const _0x285ae5 of _0x2f2021) {
                this.#delTiledEvChargerCacheMap(_0x285ae5['tileInfo']['tileId'], _0x285ae5['getKey']()), this.#addTiledEvChargerCacheMap(_0x285ae5);
            }
            for (const [_0x5dace8, _0x4e59f6] of this.#tiledEvChargerInRect) {
                !logi['maps']['Utils']['rectOnRect'](_0x4e59f6['tileInfo']['boundary']['xMin'], _0x4e59f6['tileInfo']['boundary']['yMin'], _0x4e59f6['tileInfo']['boundary']['xMax'], _0x4e59f6['tileInfo']['boundary']['yMax'], _0x490cc9['xMin'], _0x490cc9['yMin'], _0x490cc9['xMax'], _0x490cc9['yMax']) && this.#tiledEvChargerInRect['delete'](_0x5dace8);
            }
        }
        this.#updateOverlapCheck1st(this.#tiledImageInRect, this.#boundaryChecker), this.#updateOverlapCheck1st(this.#tiledLabelInRect, this.#boundaryChecker), this.#updateOverlapCheck1st(this.#tiledEvChargerInRect, this.#boundaryChecker), this.#updateOverlapCheck2nd(this.#tiledImageCacheMap, this.#tiledImageInRect, this.#boundaryChecker), this.#updateOverlapCheck2nd(this.#tiledLabelCacheMap, this.#tiledLabelInRect, this.#boundaryChecker), this.#updateOverlapCheck2nd(this.#tiledEvChargerCacheMap, this.#tiledEvChargerInRect, this.#boundaryChecker);
    };
    #updateOverlapCheck1st = function (_0x1f27ac, _0x4da2e2) {
        const _0x424f7 = this['getMapCoord']()['getMapRect']();
        for (const [_0x3c77b0, _0x28ac2f] of _0x1f27ac) {
            for (const [_0x111d98, _0x41f20e] of _0x28ac2f['objects']) {
                if (_0x41f20e['getVisible']() == ![]) {
                    _0x41f20e['boundaryData']['bLoad'] = logi['maps']['BoundaryData']['STATUS']['NOT_LOAD'], _0x41f20e['boundaryData']['creationTick'] = logi['maps']['Utils']['getCurTick']();
                    continue;
                }
                const _0x241a45 = _0x41f20e['getPosition']();
                if (!logi['maps']['Utils']['pointInRect'](_0x241a45['lng'], _0x241a45['lat'], _0x424f7['xMin'], _0x424f7['yMin'], _0x424f7['xMax'], _0x424f7['yMax'])) {
                    if (_0x41f20e['boundaryData']['bLoad'] == logi['maps']['BoundaryData']['STATUS']['LOAD'] || _0x41f20e['boundaryData']['bLoad'] == logi['maps']['BoundaryData']['STATUS']['LOAD_OUTSIDE'])
                        _0x41f20e['boundaryData']['bLoad'] = logi['maps']['BoundaryData']['STATUS']['LOAD_OUTSIDE'];
                    else
                        _0x41f20e['boundaryData']['bLoad'] == logi['maps']['BoundaryData']['STATUS']['OVERLAP'] || _0x41f20e['boundaryData']['bLoad'] == logi['maps']['BoundaryData']['STATUS']['OVERLAP_OUTSIDE'] ? (_0x41f20e['boundaryData']['bLoad'] = logi['maps']['BoundaryData']['STATUS']['OVERLAP_OUTSIDE'], _0x41f20e['boundaryData']['creationTick'] = logi['maps']['Utils']['getCurTick']()) : (_0x41f20e['boundaryData']['bLoad'] = logi['maps']['BoundaryData']['STATUS']['NOT_LOAD'], _0x41f20e['boundaryData']['creationTick'] = logi['maps']['Utils']['getCurTick']());
                    continue;
                }
                _0x41f20e['updateBoundary'](), _0x4da2e2['isBoundaryOverlapped'](_0x41f20e['boundaryData']) == ![] ? (_0x41f20e['boundaryData']['bLoad'] = logi['maps']['BoundaryData']['STATUS']['LOAD'], _0x4da2e2['addBoundary'](_0x41f20e['boundaryData'], ![])) : (_0x41f20e['boundaryData']['bLoad'] = logi['maps']['BoundaryData']['STATUS']['OVERLAP'], _0x41f20e['boundaryData']['creationTick'] = logi['maps']['Utils']['getCurTick']());
            }
        }
    };
    #updateOverlapCheck2nd = function (_0x127c25, _0x205c68, _0x2bbcd5) {
        const _0x25cff1 = this['getMapCoord']()['getMapRect']();
        for (const [_0x24cabe, _0x31ed4f] of _0x127c25) {
            if (!logi['maps']['Utils']['rectOnRect'](_0x31ed4f['tileInfo']['boundary']['xMin'], _0x31ed4f['tileInfo']['boundary']['yMin'], _0x31ed4f['tileInfo']['boundary']['xMax'], _0x31ed4f['tileInfo']['boundary']['yMax'], _0x25cff1['xMin'], _0x25cff1['yMin'], _0x25cff1['xMax'], _0x25cff1['yMax']))
                continue;
            let _0x3cd618 = _0x205c68['get'](_0x24cabe);
            _0x3cd618 === undefined && (_0x205c68['set'](_0x24cabe, new logi['maps']['ObjectsInTile']()), _0x3cd618 = _0x205c68['get'](_0x24cabe), _0x3cd618['tileInfo']['tileId'] = _0x31ed4f['tileInfo']['tileId'], _0x3cd618['tileInfo']['boundary']['xMin'] = _0x31ed4f['tileInfo']['boundary']['xMin'], _0x3cd618['tileInfo']['boundary']['yMin'] = _0x31ed4f['tileInfo']['boundary']['yMin'], _0x3cd618['tileInfo']['boundary']['xMax'] = _0x31ed4f['tileInfo']['boundary']['xMax'], _0x3cd618['tileInfo']['boundary']['yMax'] = _0x31ed4f['tileInfo']['boundary']['yMax']);
            for (const [_0x462b77, _0xbf4dcd] of _0x31ed4f['objects']) {
                if (_0xbf4dcd['getVisible']() == ![]) {
                    _0xbf4dcd['boundaryData']['bLoad'] = logi['maps']['BoundaryData']['STATUS']['NOT_LOAD'], _0xbf4dcd['boundaryData']['creationTick'] = logi['maps']['Utils']['getCurTick']();
                    continue;
                }
                const _0x21c1f7 = _0xbf4dcd['getPosition']();
                if (!logi['maps']['Utils']['pointInRect'](_0x21c1f7['lng'], _0x21c1f7['lat'], _0x25cff1['xMin'], _0x25cff1['yMin'], _0x25cff1['xMax'], _0x25cff1['yMax'])) {
                    if (_0xbf4dcd['boundaryData']['bLoad'] == logi['maps']['BoundaryData']['STATUS']['LOAD'] || _0xbf4dcd['boundaryData']['bLoad'] == logi['maps']['BoundaryData']['STATUS']['LOAD_OUTSIDE'])
                        _0xbf4dcd['boundaryData']['bLoad'] = logi['maps']['BoundaryData']['STATUS']['LOAD_OUTSIDE'];
                    else
                        _0xbf4dcd['boundaryData']['bLoad'] == logi['maps']['BoundaryData']['STATUS']['OVERLAP'] || _0xbf4dcd['boundaryData']['bLoad'] == logi['maps']['BoundaryData']['STATUS']['OVERLAP_OUTSIDE'] ? (_0xbf4dcd['boundaryData']['bLoad'] = logi['maps']['BoundaryData']['STATUS']['OVERLAP_OUTSIDE'], _0xbf4dcd['boundaryData']['creationTick'] = logi['maps']['Utils']['getCurTick']()) : (_0xbf4dcd['boundaryData']['bLoad'] = logi['maps']['BoundaryData']['STATUS']['NOT_LOAD'], _0xbf4dcd['boundaryData']['creationTick'] = logi['maps']['Utils']['getCurTick']());
                    continue;
                }
                let _0x51ab01 = _0x3cd618['objects']['get'](_0x462b77);
                _0x51ab01 === undefined && (_0xbf4dcd['boundaryData']['bLoad'] = logi['maps']['BoundaryData']['STATUS']['NOT_LOAD'], _0xbf4dcd['boundaryData']['creationTick'] = logi['maps']['Utils']['getCurTick'](), _0xbf4dcd['updateBoundary'](), _0x2bbcd5['isBoundaryOverlapped'](_0xbf4dcd['boundaryData']) == ![] ? (_0xbf4dcd['boundaryData']['bLoad'] = logi['maps']['BoundaryData']['STATUS']['LOAD'], _0x2bbcd5['addBoundary'](_0xbf4dcd['boundaryData'], ![])) : (_0xbf4dcd['boundaryData']['bLoad'] = logi['maps']['BoundaryData']['STATUS']['OVERLAP'], _0xbf4dcd['boundaryData']['creationTick'] = logi['maps']['Utils']['getCurTick']()), _0x3cd618['objects']['set'](_0x462b77, _0xbf4dcd));
            }
        }
    };
    ['refreshTiledImageByLevel']() {
        this.#tiledImageCacheMap['clear'](), this.#tiledImageInRect['clear']();
        if (this.#imageObjects)
            for (const [_0x4a7b4a, _0x6299a2] of this.#imageObjects) {
                _0x6299a2['expiredTileId'] = !![], this.#addTiledImageCacheMap(_0x6299a2);
            }
        this['updateLayer']();
    }
    ['refreshTiledLabelByLevel']() {
        this.#tiledLabelCacheMap['clear'](), this.#tiledLabelInRect['clear']();
        if (this.#labelObjects)
            for (const [_0x238071, _0x1f0ce1] of this.#labelObjects) {
                _0x1f0ce1['expiredTileId'] = !![], this.#addTiledLabelCacheMap(_0x1f0ce1);
            }
        this['updateLayer']();
    }
    ['refreshTiledEvChargerByLevel']() {
        this.#tiledEvChargerCacheMap['clear'](), this.#tiledEvChargerInRect['clear']();
        if (this.#evChargerObjects)
            for (const [_0x3c5da8, _0x4b2058] of this.#evChargerObjects) {
                _0x4b2058['expiredTileId'] = !![], this.#addTiledEvChargerCacheMap(_0x4b2058);
            }
        this['updateLayer']();
    }
    #addTiledImageCacheMap = function (_0x33897d) {
        if (_0x33897d['expiredTileId'] == !![]) {
            const _0x181dcf = this['getMapCoord'](), _0x571ec1 = _0x33897d['getPosition'](), _0x156288 = _0x181dcf['getLevel'](), _0xb4d6e5 = _0x181dcf['getTileInfo'](_0x571ec1['lng'], _0x571ec1['lat'], _0x156288);
            _0x33897d['expiredTileId'] = ![], _0x33897d['tileInfo']['tileId'] = _0xb4d6e5['tileId'], _0x33897d['tileInfo']['boundary']['xMin'] = _0xb4d6e5['boundary']['xMin'], _0x33897d['tileInfo']['boundary']['yMin'] = _0xb4d6e5['boundary']['yMin'], _0x33897d['tileInfo']['boundary']['xMax'] = _0xb4d6e5['boundary']['xMax'], _0x33897d['tileInfo']['boundary']['yMax'] = _0xb4d6e5['boundary']['yMax'];
        }
        let _0x1978bd = this.#tiledImageCacheMap['get'](_0x33897d['tileInfo']['tileId']);
        _0x1978bd === undefined && (this.#tiledImageCacheMap['set'](_0x33897d['tileInfo']['tileId'], new logi['maps']['ObjectsInTile']()), _0x1978bd = this.#tiledImageCacheMap['get'](_0x33897d['tileInfo']['tileId']), _0x1978bd['tileInfo']['tileId'] = _0x33897d['tileInfo']['tileId'], _0x1978bd['tileInfo']['boundary']['xMin'] = _0x33897d['tileInfo']['boundary']['xMin'], _0x1978bd['tileInfo']['boundary']['yMin'] = _0x33897d['tileInfo']['boundary']['yMin'], _0x1978bd['tileInfo']['boundary']['xMax'] = _0x33897d['tileInfo']['boundary']['xMax'], _0x1978bd['tileInfo']['boundary']['yMax'] = _0x33897d['tileInfo']['boundary']['yMax']), _0x1978bd['objects']['set'](_0x33897d['getKey'](), _0x33897d), this['updateLayer']();
    };
    #addTiledLabelCacheMap = function (_0x2e9258) {
        if (_0x2e9258['expiredTileId'] == !![]) {
            const _0x4b2963 = this['getMapCoord'](), _0x1f81e2 = _0x2e9258['getPosition'](), _0x2ff158 = _0x4b2963['getLevel'](), _0x34e2bf = _0x4b2963['getTileInfo'](_0x1f81e2['lng'], _0x1f81e2['lat'], _0x2ff158);
            _0x2e9258['expiredTileId'] = ![], _0x2e9258['tileInfo']['tileId'] = _0x34e2bf['tileId'], _0x2e9258['tileInfo']['boundary']['xMin'] = _0x34e2bf['boundary']['xMin'], _0x2e9258['tileInfo']['boundary']['yMin'] = _0x34e2bf['boundary']['yMin'], _0x2e9258['tileInfo']['boundary']['xMax'] = _0x34e2bf['boundary']['xMax'], _0x2e9258['tileInfo']['boundary']['yMax'] = _0x34e2bf['boundary']['yMax'];
        }
        let _0x43c6b6 = this.#tiledLabelCacheMap['get'](_0x2e9258['tileInfo']['tileId']);
        _0x43c6b6 === undefined && (this.#tiledLabelCacheMap['set'](_0x2e9258['tileInfo']['tileId'], new logi['maps']['ObjectsInTile']()), _0x43c6b6 = this.#tiledLabelCacheMap['get'](_0x2e9258['tileInfo']['tileId']), _0x43c6b6['tileInfo']['tileId'] = _0x2e9258['tileInfo']['tileId'], _0x43c6b6['tileInfo']['boundary']['xMin'] = _0x2e9258['tileInfo']['boundary']['xMin'], _0x43c6b6['tileInfo']['boundary']['yMin'] = _0x2e9258['tileInfo']['boundary']['yMin'], _0x43c6b6['tileInfo']['boundary']['xMax'] = _0x2e9258['tileInfo']['boundary']['xMax'], _0x43c6b6['tileInfo']['boundary']['yMax'] = _0x2e9258['tileInfo']['boundary']['yMax']), _0x43c6b6['objects']['set'](_0x2e9258['getKey'](), _0x2e9258), this['updateLayer']();
    };
    #addTiledEvChargerCacheMap = function (_0x3a76c8) {
        if (_0x3a76c8['expiredTileId'] == !![]) {
            const _0x3a1528 = this['getMapCoord'](), _0x586d68 = _0x3a76c8['getPosition'](), _0x1e7362 = _0x3a1528['getLevel'](), _0x3c5d37 = _0x3a1528['getTileInfo'](_0x586d68['lng'], _0x586d68['lat'], _0x1e7362);
            _0x3a76c8['expiredTileId'] = ![], _0x3a76c8['tileInfo']['tileId'] = _0x3c5d37['tileId'], _0x3a76c8['tileInfo']['boundary']['xMin'] = _0x3c5d37['boundary']['xMin'], _0x3a76c8['tileInfo']['boundary']['yMin'] = _0x3c5d37['boundary']['yMin'], _0x3a76c8['tileInfo']['boundary']['xMax'] = _0x3c5d37['boundary']['xMax'], _0x3a76c8['tileInfo']['boundary']['yMax'] = _0x3c5d37['boundary']['yMax'];
        }
        let _0x17f12f = this.#tiledEvChargerCacheMap['get'](_0x3a76c8['tileInfo']['tileId']);
        _0x17f12f === undefined && (this.#tiledEvChargerCacheMap['set'](_0x3a76c8['tileInfo']['tileId'], new logi['maps']['ObjectsInTile']()), _0x17f12f = this.#tiledEvChargerCacheMap['get'](_0x3a76c8['tileInfo']['tileId']), _0x17f12f['tileInfo']['tileId'] = _0x3a76c8['tileInfo']['tileId'], _0x17f12f['tileInfo']['boundary']['xMin'] = _0x3a76c8['tileInfo']['boundary']['xMin'], _0x17f12f['tileInfo']['boundary']['yMin'] = _0x3a76c8['tileInfo']['boundary']['yMin'], _0x17f12f['tileInfo']['boundary']['xMax'] = _0x3a76c8['tileInfo']['boundary']['xMax'], _0x17f12f['tileInfo']['boundary']['yMax'] = _0x3a76c8['tileInfo']['boundary']['yMax']), _0x17f12f['objects']['set'](_0x3a76c8['getKey'](), _0x3a76c8), this['updateLayer']();
    };
    #delTiledImageCacheMap = function (_0x3e8770, _0x4ced18) {
        let _0x2e5130 = this.#tiledImageCacheMap['get'](_0x3e8770);
        _0x2e5130 !== undefined && (_0x2e5130['objects']['delete'](_0x4ced18), _0x2e5130['objects']['size'] == 0x0 && this.#tiledImageCacheMap['delete'](_0x3e8770));
        let _0x317f41 = this.#tiledImageInRect['get'](_0x3e8770);
        _0x317f41 !== undefined && (_0x317f41['objects']['delete'](_0x4ced18), _0x317f41['objects']['size'] == 0x0 && this.#tiledImageInRect['delete'](_0x3e8770)), this['updateLayer']();
    };
    #delTiledLabelCacheMap = function (_0x3df6be, _0x2b1654) {
        let _0x1b6ef5 = this.#tiledLabelCacheMap['get'](_0x3df6be);
        _0x1b6ef5 !== undefined && (_0x1b6ef5['objects']['delete'](_0x2b1654), _0x1b6ef5['objects']['size'] == 0x0 && this.#tiledLabelCacheMap['delete'](_0x3df6be));
        let _0x550ca5 = this.#tiledLabelInRect['get'](_0x3df6be);
        _0x550ca5 !== undefined && (_0x550ca5['objects']['delete'](_0x2b1654), _0x550ca5['objects']['size'] == 0x0 && this.#tiledLabelInRect['delete'](_0x3df6be)), this['updateLayer']();
    };
    #delTiledEvChargerCacheMap = function (_0x49cc6a, _0x23f777) {
        let _0x56605b = this.#tiledEvChargerCacheMap['get'](_0x49cc6a);
        _0x56605b !== undefined && (_0x56605b['objects']['delete'](_0x23f777), _0x56605b['objects']['size'] == 0x0 && this.#tiledEvChargerCacheMap['delete'](_0x49cc6a));
        let _0x192166 = this.#tiledEvChargerInRect['get'](_0x49cc6a);
        _0x192166 !== undefined && (_0x192166['objects']['delete'](_0x23f777), _0x192166['objects']['size'] == 0x0 && this.#tiledEvChargerInRect['delete'](_0x49cc6a)), this['updateLayer']();
    };
};
export default logi['maps']['ObjLayer'];