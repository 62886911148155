var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-card-title",
        { staticClass: "font-size24 surface800 font-weight900" },
        [
          _c("span", [
            _vm._v(_vm._s(_vm.preUserInfo ? "계정 변경" : "계정 생성")),
          ]),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              staticClass: "mt-n2 me-n3",
              attrs: { icon: "" },
              on: {
                click: function ($event) {
                  return _vm.cancelBtn()
                },
              },
            },
            [
              _c("v-icon", { attrs: { size: "25" } }, [
                _vm._v(" " + _vm._s(_vm.icons.mdiWindowClose) + " "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-card-text", { staticClass: "mt-n4 pb-0" }, [_c("v-divider")], 1),
      _c(
        "v-card-text",
        [
          _c(
            "v-form",
            {
              ref: "form",
              model: {
                value: _vm.valid,
                callback: function ($$v) {
                  _vm.valid = $$v
                },
                expression: "valid",
              },
            },
            [
              _c("v-text-field", {
                staticClass: "pb-2 remove-spacing",
                attrs: {
                  maxlength: "10",
                  outlined: "",
                  label: "사용자명",
                  placeholder: "사용자명",
                  "persistent-placeholder": "",
                  required: "",
                  clearable: "",
                  rules: _vm.rules.userNameRules,
                },
                model: {
                  value: _vm.userInfo.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.userInfo, "name", $$v)
                  },
                  expression: "userInfo.name",
                },
              }),
              _vm.preUserInfo
                ? _c("div", { staticClass: "pb-3" }, [
                    _c("div", { staticClass: "flex-start-contents" }, [
                      _c(
                        "div",
                        { staticStyle: { width: "70%" } },
                        [
                          _c("v-text-field", {
                            staticClass: "py-2 remove-spacing",
                            attrs: {
                              maxlength: "20",
                              outlined: "",
                              label: "사용자 ID",
                              placeholder: "6~20자 / 영문 소문자,숫자 조합",
                              "persistent-placeholder": "",
                              clearable: "",
                              disabled: true,
                            },
                            model: {
                              value: _vm.userInfo.loginId,
                              callback: function ($$v) {
                                _vm.$set(_vm.userInfo, "loginId", $$v)
                              },
                              expression: "userInfo.loginId",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "d-flex justify-end",
                          staticStyle: { width: "30%" },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "font-size18 popup_checkin_btn mt-n9",
                              attrs: {
                                color: "#196CE7",
                                outlined: "",
                                disabled: true,
                              },
                              on: { click: _vm.userExist },
                            },
                            [_vm._v(" 중복 확인 ")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _c("div", { staticClass: "pb-3" }, [
                    _c("div", { staticClass: "flex-start-contents" }, [
                      _c(
                        "div",
                        { staticStyle: { width: "70%" } },
                        [
                          _c("v-text-field", {
                            ref: "userLoginId",
                            staticClass: "py-2 remove-spacing",
                            attrs: {
                              maxlength: "20",
                              outlined: "",
                              label: "사용자 ID",
                              placeholder: "6~20자 / 영문 소문자,숫자 조합",
                              "persistent-placeholder": "",
                              clearable: "",
                              rules: _vm.rules.userIdRules,
                              disabled: _vm.userIdDisable,
                            },
                            on: { input: _vm.changedLoginId },
                            model: {
                              value: _vm.userInfo.loginId,
                              callback: function ($$v) {
                                _vm.$set(_vm.userInfo, "loginId", $$v)
                              },
                              expression: "userInfo.loginId",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "d-flex justify-end",
                          staticStyle: { width: "30%" },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "font-size18 popup_checkin_btn mt-n9",
                              attrs: {
                                color: "#196CE7",
                                outlined: "",
                                disabled: !_vm.existBtn,
                              },
                              on: { click: _vm.userExist },
                            },
                            [_vm._v(" 중복 확인 ")]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm.isUserExist && _vm.existBtn
                      ? _c(
                          "div",
                          { staticClass: "match-text ml-3 mt-n10 mb-5" },
                          [_vm._v(" 사용 가능한 아이디 입니다. ")]
                        )
                      : _vm._e(),
                  ]),
              _c("v-text-field", {
                staticClass: "two-line-password",
                attrs: {
                  maxlength: "20",
                  outlined: "",
                  label: "비밀번호",
                  placeholder:
                    "10~20자 / 영문 소문자,숫자,특수문자 2자 이상 조합",
                  clearable: "",
                  rules: _vm.rules.userPassWordRules,
                  "append-icon": _vm.isPasswordVisible
                    ? _vm.icons.mdiEyeOffOutline
                    : _vm.icons.mdiEyeOutline,
                  type: _vm.isPasswordVisible ? "text" : "password",
                },
                on: {
                  "click:append": function ($event) {
                    _vm.isPasswordVisible = !_vm.isPasswordVisible
                  },
                  input: _vm.checkingPassword,
                  blur: _vm.checkingPassword,
                },
                model: {
                  value: _vm.userInfo.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.userInfo, "password", $$v)
                  },
                  expression: "userInfo.password",
                },
              }),
              _c("v-text-field", {
                ref: "rePassword",
                staticClass: "py-2 remove-spacing",
                attrs: {
                  maxlength: "20",
                  outlined: "",
                  label: "비밀번호 재확인",
                  placeholder:
                    "10~20자 / 영문 소문자,숫자,특수문자 2자 이상 조합",
                  clearable: "",
                  rules: _vm.rules.userRePassWordRules,
                  "append-icon": _vm.isRePasswordVisible
                    ? _vm.icons.mdiEyeOffOutline
                    : _vm.icons.mdiEyeOutline,
                  type: _vm.isRePasswordVisible ? "text" : "password",
                },
                on: {
                  "click:append": function ($event) {
                    _vm.isRePasswordVisible = !_vm.isRePasswordVisible
                  },
                  input: _vm.checkingPassword,
                },
                model: {
                  value: _vm.userInfo.rePassword,
                  callback: function ($$v) {
                    _vm.$set(_vm.userInfo, "rePassword", $$v)
                  },
                  expression: "userInfo.rePassword",
                },
              }),
              _vm.isMatchPassword
                ? _c("div", { staticClass: "match-text ml-3 mt-n10 mb-5" }, [
                    _vm._v(" 비밀번호가 일치합니다. "),
                  ])
                : _vm._e(),
              _c("v-select", {
                attrs: {
                  dense: "",
                  label: "권한",
                  items: _vm.getListItems(),
                  "item-text": "name",
                  "item-value": "value",
                  outlined: "",
                  rules: _vm.rules.controllerRoleRules,
                  "menu-props": { bottom: true, offsetY: true },
                },
                model: {
                  value: _vm.userInfo.controllerRole,
                  callback: function ($$v) {
                    _vm.$set(_vm.userInfo, "controllerRole", $$v)
                  },
                  expression: "userInfo.controllerRole",
                },
              }),
              _c("v-text-field", {
                staticClass: "pb-2 remove-spacing",
                attrs: {
                  maxlength: "100",
                  outlined: "",
                  label: _vm.preUserInfo ? "변경 사유" : "생성 사유",
                  placeholder: "사유 작성",
                  "persistent-placeholder": "",
                  required: "",
                  clearable: "",
                  rules: _vm.rules.reasonMessage,
                },
                model: {
                  value: _vm.userInfo.reason,
                  callback: function ($$v) {
                    _vm.$set(_vm.userInfo, "reason", $$v)
                  },
                  expression: "userInfo.reason",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-card-text", { staticClass: "mt-n4 pb-0" }, [_c("v-divider")], 1),
      _c(
        "v-card-actions",
        { staticClass: "pt-4 flex-center-contents" },
        [
          _c(
            "v-btn",
            {
              staticClass: "popup_btn",
              attrs: { color: "primary", disabled: _vm.isBtn },
              on: { click: _vm.userAddEditBtn },
            },
            [_vm._v(" " + _vm._s(_vm.preUserInfo ? "변경" : "추가") + " ")]
          ),
          _c(
            "v-btn",
            {
              staticClass: "popup_btn cancel_btn",
              on: { click: _vm.cancelBtn },
            },
            [_vm._v("취소")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }