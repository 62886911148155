<script>
import { Line } from 'vue-chartjs'
import { chartColors } from '@/views/constant/ChartData'

export default {
  name: 'ChartjsComponentLineChart',
  extends: Line,
  props: {
    baseLabels: {
      type: Array,
      default: null,
    },
    baseData: {
      type: Array,
      default: null,
    },
    maxValue: {
      type: Number,
      default: 0,
    },
    lineTension: {
      type: Number,
      default: 0.5,
    },
  },
  mounted() {
    this.renderLine()
  },
  watch: {
    baseLabels: function () {
      this.renderLine()
    },
    baseData: function () {
      this.renderLine()
    },
    maxValue: function () {
      this.renderLine()
    },
  },

  methods: {
    renderLine: function () {
      this.renderChart(
        {
          labels: this.baseLabels,
          datasets: this.baseData
        },
        {
          responsive: true,
          maintainAspectRatio: false,
          backgroundColor: false,
          hover: {
            mode: 'label',
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: chartColors.tooltipShadow,
          },
          layout: {
            padding: {
              top: -15,
              bottom: -25,
              left: -15,
            },
          },
          scales: {
            xAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                },
                gridLines: {
                  display: true,
                  color: chartColors.gridLineColor,
                  zeroLineColor: chartColors.gridLineColor,
                },
                ticks: {
                  fontColor: chartColors.labelColor,
                },
              },
            ],
            yAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                },
                ticks: {
                  stepSize: 20,
                  min: 0,
                  max: this.maxValue,
                  fontColor: chartColors.labelColor,
                  callback: function (tick) {
                    return tick.toLocaleString()
                  },
                },
                gridLines: {
                  display: true,
                  color: chartColors.gridLineColor,
                  zeroLineColor: chartColors.gridLineColor,
                },
              },
            ],
          },
          legend: {
            display: false,
          },
        },
        this.plugins,
        this.styles,
      )
    },
  },
}
</script>
