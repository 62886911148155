import AssociateMemberReg from '@/views/memberReg/AssociateMemberReg.vue'

export const pageFilter = {
  namespaced: true,
  state: {
    status: {
      isMemberReg: false, // 회원가입 - 회원가입 심사 리스트
      isMemberDoc: false, // 회원가입 - 서류 심사 리스트
      isAssociateMemberReg: false, // 준회원
      isForcedCustomer: false, // 강제 전환 고객
      isBrokerMng: false, // 회원관리 - 주선사 리스트
      isDriverMng: false, // 회원관리 - 차주 리스트
      isCallerMng: false, // 회원관리 - 화주 리스트
      isFreightTab: false, // 회원관리 - 운송이력 리스트
      isFreightHistory: false, // 화물이력 리스트
    },
    filter: {
      memberRegFilter: {}, // 회원가입 - 회원가입 심사 리스트
      memberDocFilter: {}, // 회원가입 - 서류 심사 리스트
      associateMemberFilter: {}, // 준회원
      forcedCustomerFilter: {}, // 강제 전환 고객
      brokerMngFilter: {}, // 회원관리 - 주선사 리스트
      driverMngFilter: {}, // 회원관리 - 차주 리스트
      callerMngFilter: {}, // 회원관리 - 화주 리스트
      freightTabFilter: {}, // 회원관리 - 운송이력 리스트
      freightHistoryFilter: {}, // 화물이력 리스트
    },
  },

  actions: {},

  mutations: {
    /**
     * @param params
     * type : memberReg / memberDoc
     * data : true/ false
     */
    setIsMemberFilterStatus(state, params) {
      if (params.type === 'memberReg') {
        state.status.isMemberReg = params.data
      } else if (params.type === 'memberDoc') {
        state.status.isMemberDoc = params.data
      } else if (params.type === 'associateMember') {
        state.status.isAssociateMemberReg = params.data
      } else if (params.type === 'forcedCustomer') {
        state.status.isForcedCustomer = params.data
      }
    },
    /**
     * @param params
     * type : brokerMng / driverMng / callerMng /freightTab
     * data : true/ false
     */
    setIsManageFilterStatus(state, params) {
      if (params.type === 'brokerMng') {
        state.status.isBrokerMng = params.data
      } else if (params.type === 'driverMng') {
        state.status.isDriverMng = params.data
      } else if (params.type === 'callerMng') {
        state.status.isCallerMng = params.data
      } else if (params.type === 'freightTab') {
        state.status.isFreightTab = params.data
      }
    },

    /**
     * @param params
     * true/ false
     */
    setIsFreightHistoryFilterStatus(state, params) {
      state.status.isFreightHistory = params
    },
    /**
     * @param params
     * type : memberReg / memberDoc
     * data : filterData
     */
    setMemberFilterData(state, params) {
      if (params.type === 'memberReg') {
        state.filter.memberRegFilter = params.data
      } else if (params.type === 'memberDoc') {
        state.filter.memberDocFilter = params.data
      } else if (params.type === 'associateMember') {
        state.filter.associateMemberFilter = params.data
      } else if (params.type === 'forcedCustomer') {
        state.filter.forcedCustomerFilter = params.data
      }
    },

    /**
     * @param params
     * type : brokerMng / driverMng / callerMng /freightTab
     * data : filterData
     */
    setManageFilterData(state, params) {
      if (params.type === 'brokerMng') {
        state.filter.brokerMngFilter = params.data
      } else if (params.type === 'driverMng') {
        state.filter.driverMngFilter = params.data
      } else if (params.type === 'callerMng') {
        state.filter.callerMngFilter = params.data
      } else if (params.type === 'freightTab') {
        state.filter.freightTabFilter = params.data
      }
    },
    /**
     * @param params
      [Object] filterData
    */
    setFreightHistoryFilterData(state, params) {
      state.filter.freightHistoryFilter = params
    },
  },

  getters: {},
}
