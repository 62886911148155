var logi = logi ?? {};
logi['maps'] = logi['maps'] ?? {};
import a16_0x3f7aea from './base/logi-maps-types.js';
logi['maps']['LINETYPE'] = a16_0x3f7aea['LINETYPE'];
import a16_0x26532c from './base/logi-maps-object.js';
logi['maps']['Object'] = a16_0x26532c;
import a16_0x3b0895 from './base/logi-maps-utils.js';
logi['maps']['Utils'] = a16_0x3b0895, logi['maps']['Line'] = class extends logi['maps']['Object'] {
    #type = logi['maps']['LINETYPE']['STRAIGHT'];
    #info = {};
    #screenCoord = {
        'origin': {
            'x': 0x0,
            'y': 0x0
        },
        'points': new Array(),
        'baseLayer': null,
        'level': null
    };
    #boundaryWidth = 0x0;
    constructor(_0x534d1c, _0x51fa7a) {
        const _0x21fbe2 = _0x51fa7a?.['key'] ?? 'ln_' + Math['random']()['toString'](0x24)['slice'](-0x8), _0x1f514e = _0x51fa7a?.['class'] ?? '', _0x5b4419 = _0x51fa7a?.['zIndex'] ?? 0x0;
        super(_0x21fbe2, logi['maps']['Object']['OBJTYPE']['line'], _0x1f514e, _0x5b4419), this.#type = _0x534d1c;
        switch (_0x534d1c) {
        case logi['maps']['LINETYPE']['STRAIGHT']:
            this.#info['latlngs'] = new Array(), this.#info['latlngs']['push']({
                'lng': _0x51fa7a?.['fromLatLng']?.['lng'] ?? 0x0,
                'lat': _0x51fa7a?.['fromLatLng']?.['lat'] ?? 0x0
            }), this.#info['latlngs']['push']({
                'lng': _0x51fa7a?.['toLatLng']?.['lng'] ?? 0x0,
                'lat': _0x51fa7a?.['toLatLng']?.['lat'] ?? 0x0
            }), this.#info['width'] = _0x51fa7a?.['width'] ?? 0x0, this.#info['color'] = _0x51fa7a?.['color'] ?? '#000000', this.#info['strokeWidth'] = _0x51fa7a?.['strokeWidth'] ?? 0x0, this.#info['strokeColor'] = _0x51fa7a?.['strokeColor'] ?? '#000000';
            break;
        case logi['maps']['LINETYPE']['POLY']:
            this.#info['latlngs'] = logi['maps']['Utils']['getRoughLatLngs'](_0x51fa7a?.['latlngs'] ?? []), this.#info['width'] = _0x51fa7a?.['width'] ?? 0x0, this.#info['color'] = _0x51fa7a?.['color'] ?? '#000000', this.#info['strokeWidth'] = _0x51fa7a?.['strokeWidth'] ?? 0x0, this.#info['strokeColor'] = _0x51fa7a?.['strokeColor'] ?? '#000000';
            break;
        case logi['maps']['LINETYPE']['DOT']:
            const _0x48af8c = _0x51fa7a?.['dotRadius'] * 0x2 ?? 0x0;
            this.#info['latlngs'] = logi['maps']['Utils']['getRoughLatLngs'](_0x51fa7a?.['latlngs'] ?? []), this.#info['dotRadius'] = _0x51fa7a?.['dotRadius'] ?? 0x0, this.#info['dotGap'] = _0x51fa7a?.['dotGap'] ?? _0x48af8c, this.#info['color'] = _0x51fa7a?.['color'] ?? '#000000', this.#info['strokeWidth'] = _0x51fa7a?.['strokeWidth'] ?? 0x0, this.#info['strokeColor'] = _0x51fa7a?.['strokeColor'] ?? '#000000';
            break;
        case logi['maps']['LINETYPE']['DASH']:
            const _0x41534b = _0x51fa7a?.['dashLength'] ?? 0x0;
            this.#info['latlngs'] = logi['maps']['Utils']['getRoughLatLngs'](_0x51fa7a?.['latlngs'] ?? []), this.#info['width'] = _0x51fa7a?.['width'] ?? 0x0, this.#info['dashLength'] = _0x51fa7a?.['dashLength'] ?? 0x0, this.#info['dashSpace'] = _0x51fa7a?.['dashSpace'] ?? _0x41534b, this.#info['color'] = _0x51fa7a?.['color'] ?? '#000000', this.#info['strokeWidth'] = _0x51fa7a?.['strokeWidth'] ?? 0x0, this.#info['strokeColor'] = _0x51fa7a?.['strokeColor'] ?? '#000000';
            break;
        default:
        }
        this['setMap'](_0x51fa7a?.['map']);
    }
    ['isHit'](_0x769f91) {
        return this.#pointOnLine(_0x769f91);
    }
    ['draw']() {
        if (!this['getLayer']() || !this['getVisible']() || !this.#info)
            this.#screenCoord = {
                'origin': {
                    'x': 0x0,
                    'y': 0x0
                },
                'points': new Array(),
                'baseLayer': null,
                'level': null
            }, this.#boundaryWidth = 0x0;
        else {
            const _0x105940 = this['getMapCoord'](), _0x4dfa09 = this['getDevicePixelRatio'](), _0x2c2a98 = _0x105940['getAdditionalScale']();
            if (this.#screenCoord['baseLayer'] != this['getLayer']() || this.#screenCoord['level'] != _0x105940['getLevel']()) {
                this.#screenCoord['points'] = new Array(), this.#boundaryWidth = 0x0;
                if (this.#info['latlngs']['length'] >= 0x2) {
                    const _0x482e9e = _0x105940['world2screen'](this.#info['latlngs'][0x0]['lng'], this.#info['latlngs'][0x0]['lat'], { 'additionalScale': 0x1 });
                    let _0x327b75 = {
                            'x': null,
                            'y': null
                        }, _0x5c8581 = {
                            'x': null,
                            'y': null
                        };
                    for (let _0x309955 of this.#info['latlngs']) {
                        _0x327b75 = _0x105940['world2screen'](_0x309955['lng'], _0x309955['lat'], { 'additionalScale': 0x1 }), _0x327b75['x'] = _0x327b75['x'] - _0x482e9e['x'], _0x327b75['y'] = _0x327b75['y'] - _0x482e9e['y'], (_0x5c8581['x'] != _0x327b75['x'] || _0x5c8581['y'] != _0x327b75['y']) && (this.#screenCoord['points']['push'](_0x327b75), _0x5c8581 = _0x327b75);
                    }
                }
                this.#screenCoord['baseLayer'] = this['getLayer'](), this.#screenCoord['level'] = _0x105940['getLevel']();
            }
            if (this.#screenCoord['points']['length'] >= 0x2) {
                this.#screenCoord['origin'] = _0x105940['world2screen'](this.#info['latlngs'][0x0]['lng'], this.#info['latlngs'][0x0]['lat']);
                const _0x3f2706 = this['getContext']();
                _0x3f2706['save'](), _0x3f2706['scale'](_0x4dfa09, _0x4dfa09), _0x3f2706['translate'](this.#screenCoord['origin']['x'], this.#screenCoord['origin']['y']), _0x3f2706['scale'](_0x2c2a98, _0x2c2a98);
                switch (this.#type) {
                case logi['maps']['LINETYPE']['STRAIGHT']: {
                        if (this.#screenCoord['points'][0x0]['x'] != this.#screenCoord['points'][0x1]['x'] || this.#screenCoord['points'][0x0]['y'] != this.#screenCoord['points'][0x1]['y']) {
                            const _0x35f569 = this.#info['width'];
                            this.#boundaryWidth = _0x35f569;
                            if (this.#info['strokeWidth'] > 0x0) {
                                const _0x57a41d = _0x35f569 + this.#info['strokeWidth'] * 0x2;
                                this.#boundaryWidth = _0x57a41d, logi['maps']['Line']['drawLine'](_0x3f2706, this.#screenCoord['points'][0x0], this.#screenCoord['points'][0x1], _0x57a41d, this.#info['strokeColor']);
                            }
                            logi['maps']['Line']['drawLine'](_0x3f2706, this.#screenCoord['points'][0x0], this.#screenCoord['points'][0x1], _0x35f569, this.#info['color']);
                        }
                    }
                    break;
                case logi['maps']['LINETYPE']['POLY']: {
                        const _0x3d424d = this.#info['width'];
                        this.#boundaryWidth = _0x3d424d;
                        if (this.#info['strokeWidth'] > 0x0) {
                            const _0x3b5a75 = _0x3d424d + this.#info['strokeWidth'] * 0x2;
                            this.#boundaryWidth = _0x3b5a75, logi['maps']['Line']['drawPolyLine'](_0x3f2706, this.#screenCoord['points'], _0x3b5a75, this.#info['strokeColor'], this.#info['offsetX'], this.#info['offsetY']);
                        }
                        logi['maps']['Line']['drawPolyLine'](_0x3f2706, this.#screenCoord['points'], _0x3d424d, this.#info['color'], this.#info['offsetX'], this.#info['offsetY']);
                    }
                    break;
                case logi['maps']['LINETYPE']['DOT']: {
                        const _0x4e9c18 = this.#info['dotRadius'];
                        let _0x334193 = this.#info['dotGap'] + _0x4e9c18 * 0x2;
                        this.#boundaryWidth = _0x4e9c18 * 0x2;
                        if (this.#info['strokeWidth'] > 0x0) {
                            const _0x5084ec = _0x4e9c18 + this.#info['strokeWidth'];
                            _0x334193 = this.#info['dotGap'] + _0x5084ec * 0x2, this.#boundaryWidth = _0x5084ec * 0x2, logi['maps']['Line']['drawDottedLine'](_0x3f2706, this.#screenCoord['points'], _0x5084ec, _0x334193, this.#info['strokeColor']);
                        }
                        logi['maps']['Line']['drawDottedLine'](_0x3f2706, this.#screenCoord['points'], _0x4e9c18, _0x334193, this.#info['color']);
                    }
                    break;
                case logi['maps']['LINETYPE']['DASH']: {
                        const _0x4b8de7 = this.#info['width'];
                        let _0xb3e474 = this.#info['dashSpace'] + _0x4b8de7;
                        this.#boundaryWidth = _0x4b8de7;
                        if (this.#info['strokeWidth'] > 0x0) {
                            const _0x3d59e1 = _0x4b8de7 + this.#info['strokeWidth'] * 0x2;
                            _0xb3e474 = this.#info['dashSpace'] + _0x3d59e1, this.#boundaryWidth = _0x3d59e1, logi['maps']['Line']['drawPolyDashedLine'](_0x3f2706, this.#screenCoord['points'], _0x3d59e1, this.#info['dashLength'], _0xb3e474, this.#info['strokeColor'], this.#info['offsetX'], this.#info['offsetY']);
                        }
                        logi['maps']['Line']['drawPolyDashedLine'](_0x3f2706, this.#screenCoord['points'], _0x4b8de7, this.#info['dashLength'], _0xb3e474, this.#info['color'], this.#info['offsetX'], this.#info['offsetY']);
                    }
                    break;
                }
                _0x3f2706['restore']();
            }
        }
    }
    static ['drawLine'](_0x8d0710, _0x1aba3a, _0x223843, _0x268bf2, _0x146623) {
        _0x8d0710['beginPath'](), _0x8d0710['lineWidth'] = _0x268bf2, _0x8d0710['strokeStyle'] = _0x146623, _0x8d0710['lineCap'] = 'round', _0x8d0710['lineJoin'] = 'round', _0x8d0710['moveTo'](_0x1aba3a['x'], _0x1aba3a['y']), _0x8d0710['lineTo'](_0x223843['x'], _0x223843['y']), _0x8d0710['stroke']();
    }
    static ['drawPolyLine'](_0x100ff3, _0x3bc6aa, _0x50d2ba, _0xc66411, _0x3046a4 = 0x0, _0x283479 = 0x0) {
        if (_0x3bc6aa?.['length'] >= 0x2) {
            _0x100ff3['beginPath'](), _0x100ff3['lineWidth'] = _0x50d2ba, _0x100ff3['strokeStyle'] = _0xc66411, _0x100ff3['lineCap'] = 'round', _0x100ff3['lineJoin'] = 'round', _0x100ff3['moveTo'](_0x3bc6aa[0x0]['x'] + _0x3046a4, _0x3bc6aa[0x0]['y'] + _0x283479);
            for (let _0x31bfac of _0x3bc6aa) {
                _0x100ff3['lineTo'](_0x31bfac['x'] + _0x3046a4, _0x31bfac['y'] + _0x283479);
            }
            _0x100ff3['stroke']();
        }
    }
    static ['drawDottedLine'](_0x1abb1f, _0x149136, _0x152b8e, _0x3582a7, _0x5cadf7) {
        if (_0x149136?.['length'] >= 0x2) {
            _0x1abb1f['setLineDash']([
                0x0,
                _0x3582a7
            ]), _0x1abb1f['beginPath'](), _0x1abb1f['lineWidth'] = _0x152b8e * 0x2, _0x1abb1f['strokeStyle'] = _0x5cadf7, _0x1abb1f['lineCap'] = 'round', _0x1abb1f['lineJoin'] = 'round', _0x1abb1f['moveTo'](_0x149136[0x0]['x'], _0x149136[0x0]['y']);
            for (let _0x7e293c of _0x149136) {
                _0x1abb1f['lineTo'](_0x7e293c['x'], _0x7e293c['y']);
            }
            _0x1abb1f['stroke'](), _0x1abb1f['setLineDash']([]);
        }
    }
    static ['drawPolyDashedLine'](_0x2b63e1, _0x4be5c4, _0x22b3ea, _0x34d10d, _0x35d3b4, _0x1a3a07, _0x309553 = 0x0, _0x51d690 = 0x0) {
        if (_0x4be5c4?.['length'] >= 0x2) {
            _0x2b63e1['setLineDash']([
                _0x34d10d,
                _0x35d3b4
            ]), _0x2b63e1['beginPath'](), _0x2b63e1['lineWidth'] = _0x22b3ea, _0x2b63e1['strokeStyle'] = _0x1a3a07, _0x2b63e1['lineCap'] = 'round', _0x2b63e1['lineJoin'] = 'round', _0x2b63e1['moveTo'](_0x4be5c4[0x0]['x'] + _0x309553, _0x4be5c4[0x0]['y'] + _0x51d690);
            for (let _0x4a6b0c of _0x4be5c4) {
                _0x2b63e1['lineTo'](_0x4a6b0c['x'] + _0x309553, _0x4a6b0c['y'] + _0x51d690);
            }
            _0x2b63e1['stroke'](), _0x2b63e1['setLineDash']([]);
        }
    }
    static ['drawDot'](_0x2a9e88, _0x2a044f, _0x408847, _0x464439, _0x2aea39) {
        _0x2a9e88['beginPath'](), _0x2a9e88['arc'](_0x2a044f, _0x408847, _0x464439, 0x0, 0x2 * Math['PI'], ![]), _0x2a9e88['fillStyle'] = _0x2aea39, _0x2a9e88['fill']();
    }
    #pointOnLine(_0x588458) {
        if (!this['getLayer']() || !this['getVisible']())
            return ![];
        if (this.#screenCoord['points']['length'] >= 0x2 && this.#boundaryWidth > 0x0) {
            const _0x295b36 = this['getMapCoord'](), _0x519dbb = {
                    'x': 0x0,
                    'y': 0x0
                };
            if (_0x295b36) {
                const _0x131e6c = _0x295b36['getAdditionalScale']();
                _0x519dbb['x'] = _0x588458['x'] / _0x131e6c - this.#screenCoord['origin']['x'], _0x519dbb['y'] = _0x588458['y'] / _0x131e6c - this.#screenCoord['origin']['y'];
                const _0x5e0fa4 = this.#boundaryWidth * 0.5 + 0x2;
                let _0x357137 = null;
                for (let _0x2b72de of this.#screenCoord['points']) {
                    if (_0x357137 != null) {
                        const _0x37a3c7 = this.#getPerpendicularPoint(_0x357137, _0x2b72de, _0x519dbb);
                        if (_0x37a3c7['distance'] <= _0x5e0fa4)
                            return !![];
                    }
                    _0x357137 = _0x2b72de;
                }
            }
        }
        return ![];
    }
    #getPerpendicularPoint(_0x21e04d, _0x56c272, _0x445b04) {
        let _0x59a49f = {
            'x': 0x0,
            'y': 0x0,
            'distance': 0x0,
            'result': 0x0
        };
        const _0x5e4548 = _0x21e04d['x'] > _0x56c272['x'] ? _0x56c272['x'] : _0x21e04d['x'], _0x59c7fe = _0x21e04d['y'] > _0x56c272['y'] ? _0x56c272['y'] : _0x21e04d['y'], _0x1630e7 = _0x21e04d['x'] > _0x56c272['x'] ? _0x21e04d['x'] : _0x56c272['x'], _0x513b2 = _0x21e04d['y'] > _0x56c272['y'] ? _0x21e04d['y'] : _0x56c272['y'];
        if (_0x21e04d['x'] === _0x56c272['x'] && _0x21e04d['y'] === _0x56c272['y'])
            _0x59a49f['x'] = _0x21e04d['x'], _0x59a49f['y'] = _0x21e04d['y'], _0x59a49f['distance'] = this.#getPointDistance(_0x21e04d, _0x445b04), _0x59a49f['result'] = 0x1;
        else {
            if (_0x21e04d['x'] === _0x56c272['x'])
                _0x445b04['y'] >= _0x59c7fe && _0x445b04['y'] <= _0x513b2 ? (_0x59a49f['x'] = _0x21e04d['x'], _0x59a49f['y'] = _0x445b04['y'], _0x59a49f['distance'] = Math['abs'](_0x445b04['x'] - _0x21e04d['x']), _0x59a49f['result'] = 0x1) : (_0x59a49f['x'] = _0x21e04d['x'], Math['abs'](_0x21e04d['y'] - _0x445b04['y']) < Math['abs'](_0x56c272['y'] - _0x445b04['y']) ? _0x59a49f['y'] = _0x21e04d['y'] : _0x59a49f['y'] = _0x56c272['y'], _0x59a49f['distance'] = this.#getPointDistance(_0x445b04, _0x59a49f), _0x59a49f['result'] = 0x2);
            else {
                if (_0x21e04d['y'] === _0x56c272['y'])
                    _0x445b04['x'] >= _0x5e4548 && _0x445b04['x'] <= _0x1630e7 ? (_0x59a49f['x'] = _0x445b04['x'], _0x59a49f['y'] = _0x21e04d['y'], _0x59a49f['distance'] = Math['abs'](_0x445b04['y'] - _0x21e04d['y']), _0x59a49f['result'] = 0x1) : (_0x59a49f['y'] = _0x21e04d['y'], Math['abs'](_0x21e04d['x'] - _0x445b04['x']) < Math['abs'](_0x56c272['x'] - _0x445b04['x']) ? _0x59a49f['x'] = _0x21e04d['x'] : _0x59a49f['x'] = _0x56c272['x'], _0x59a49f['distance'] = this.#getPointDistance(_0x445b04, _0x59a49f), _0x59a49f['result'] = 0x2);
                else {
                    let _0x4d1419 = (_0x56c272['y'] - _0x21e04d['y']) / (_0x56c272['x'] - _0x21e04d['x']), _0x240cfc = -0x1 / _0x4d1419, _0x44f72b = (_0x445b04['y'] - _0x240cfc * _0x445b04['x'] - _0x21e04d['y'] + _0x4d1419 * _0x21e04d['x']) / (_0x4d1419 - _0x240cfc), _0x232a91 = _0x4d1419 * (_0x44f72b - _0x21e04d['x']) + _0x21e04d['y'];
                    _0x59a49f['x'] = _0x44f72b, _0x59a49f['y'] = _0x232a91, _0x59a49f['x'] >= _0x5e4548 && _0x59a49f['x'] <= _0x1630e7 && _0x59a49f['y'] >= _0x59c7fe && _0x59a49f['y'] <= _0x513b2 ? (_0x59a49f['distance'] = this.#getPointDistance(_0x445b04, _0x59a49f), _0x59a49f['result'] = 0x1) : (this.#getPointDistance(_0x21e04d, _0x59a49f) < this.#getPointDistance(_0x56c272, _0x59a49f) ? (_0x59a49f['x'] = _0x21e04d['x'], _0x59a49f['y'] = _0x21e04d['y']) : (_0x59a49f['x'] = _0x56c272['x'], _0x59a49f['y'] = _0x56c272['y']), _0x59a49f['distance'] = this.#getPointDistance(_0x445b04, _0x59a49f), _0x59a49f['result'] = 0x2);
                }
            }
        }
        return _0x59a49f;
    }
    #getPointDistance(_0x8141cf, _0x19c2b3) {
        let _0xcaa9ed, _0xbaf14;
        return _0xcaa9ed = _0x19c2b3['x'] - _0x8141cf['x'], _0xbaf14 = _0x19c2b3['y'] - _0x8141cf['y'], Math['sqrt'](_0xcaa9ed * _0xcaa9ed + _0xbaf14 * _0xbaf14);
    }
};
export default logi['maps']['Line'];