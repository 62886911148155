<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="viewBox"
  >
    <g id="ic_report" transform="translate(424 -455)">
      <g id="infographic" transform="translate(-423 456)">
        <path id="패스_29440" data-name="패스 29440" d="M21.5,4.167H5.167V22.833H21.5ZM5.167,3A1.167,1.167,0,0,0,4,4.167V22.833A1.167,1.167,0,0,0,5.167,24H21.5a1.167,1.167,0,0,0,1.167-1.167V4.167A1.167,1.167,0,0,0,21.5,3Z" transform="translate(0)" :fill="iconColor" :stroke="iconColor" stroke-width="0.5" fill-rule="evenodd"/>
        <path id="패스_29441" data-name="패스 29441" d="M8.167,16H7v3.5H8.167Z" transform="translate(0.5 2.167)" :fill="iconColor" :stroke="iconColor" stroke-width="0.5" fill-rule="evenodd"/>
        <path id="패스_29442" data-name="패스 29442" d="M11.167,15H10v4.975h1.167Z" transform="translate(1 1.692)" :fill="iconColor" :stroke="iconColor" stroke-width="0.5" fill-rule="evenodd"/>
        <path id="패스_29443" data-name="패스 29443" d="M14.167,17H13v2.974h1.167Z" transform="translate(1.5 1.692)" :fill="iconColor" :stroke="iconColor" stroke-width="0.5" fill-rule="evenodd"/>
        <path id="패스_29444" data-name="패스 29444" d="M17.167,16H16v4.084h1.167Z" transform="translate(2 1.582)" :fill="iconColor" :stroke="iconColor" stroke-width="0.5" fill-rule="evenodd"/>
        <path id="패스_29445" data-name="패스 29445" d="M12,6.035a4.084,4.084,0,1,0,4.625,4.625H15.442A2.917,2.917,0,1,1,12,7.219Z" transform="translate(0.75 0.506)" :fill="iconColor" :stroke="iconColor" stroke-width="0.5"/>
        <path id="패스_29446" data-name="패스 29446" d="M12.5,5h.539A3.771,3.771,0,0,1,16.81,8.771V9.31H12.5Zm1.078,1.131v2.1h2.1A2.7,2.7,0,0,0,13.578,6.131Z" transform="translate(1.773 0.333)" :fill="iconColor" :stroke="iconColor" stroke-width="0.5" fill-rule="evenodd"/>
      </g>
      <rect id="사각형_31282" data-name="사각형 31282" width="28" height="28" transform="translate(-424 455)" fill="none"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'report',

  props: {
    viewBox: { type: String, default: '0 0 28 28' },
    iconName: { type: String, default: '' },
    width: { type: [Number, String], default: 28 },
    height: { type: [Number, String], default: 28 },
    iconColor: { type: String, default: '#979eaa' },
  },
}
</script>

<style scoped></style>
