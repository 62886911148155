import { v4 as uuid } from 'uuid'

export interface Popup {
  type?: 'alert' | 'confirm'
  title?: string
  content?: string
  confirmText?: string
  cancelText?: string
  persistent?: boolean
  onClose?: (response?: boolean) => void
}

export interface Toast {
  type?: 'success' | 'error' | 'notice' | 'warning'
  key?: string
  content?: string
  duration?: number
  onClose?: () => void
}

const defaultPopup: Popup = {
  type: 'alert',
  cancelText: '취소',
  confirmText: '확인',
  persistent: true,
}

const defaultToast: Toast = {
  type: 'notice',
  key: '',
  content: '',
  duration: 3000,
}

export const popup = {
  namespaced: true,
  state: {
    popupItems: [],
    toastItems: [],
  },
  actions: {
    showNextPopup({ commit }) {
      commit('SHIFT_POPUP')
    },

    showNextToast({ commit }) {
      commit('SHIFT_TOAST')
    },

    alert({ commit, dispatch }, popup: string | Popup) {
      return new Promise((resolve) => {
        function onClose(response?: boolean) {
          resolve(response)
          dispatch('showNextPopup')
        }

        const _popup: Popup =
          typeof popup === 'string'
            ? { ...defaultPopup, type: 'alert', content: popup, onClose }
            : { ...defaultPopup, ...popup, type: 'alert', onClose }

        commit('ADD_POPUP_ITEM', _popup)
      })
    },

    confirm({ commit, dispatch }, popup: string | Popup) {
      return new Promise((resolve) => {
        function onClose(response?: boolean) {
          resolve(response)
          dispatch('showNextPopup')
        }

        const _popup: Popup =
          typeof popup === 'string'
            ? { ...defaultPopup, type: 'confirm', content: popup, onClose }
            : { ...defaultPopup, ...popup, type: 'confirm', onClose }

        commit('ADD_POPUP_ITEM', _popup)
      })
    },

    toast({ commit, dispatch }, toast: string | Toast) {
      console.log('toast', toast)

      return new Promise((resolve) => {
        function onClose() {
          resolve(null)
          dispatch('showNextToast')
        }

        const key = uuid()

        const _toast: Toast =
          typeof toast === 'string'
            ? { ...defaultToast, content: toast, onClose, key }
            : { ...defaultToast, ...toast, onClose, key }

        commit('ADD_TOAST_ITEM', _toast)
      })
    },

    watchCurrentToast({ getters, commit }) {
      this.watch(
        () => getters.getCurrentToast,
        (newToast, oldToast) => {
          console.log(`Popup 변경: ${oldToast} => ${newToast}`, newToast)
          if (!newToast) return

          setTimeout(() => {
            newToast?.onClose?.()
            // dispatch('showNextToast')
          }, newToast.duration)
        },
      )
    },
  },

  mutations: {
    ADD_POPUP_ITEM(state, popup) {
      state.popupItems.push(popup)
    },
    SHIFT_POPUP(state) {
      state.popupItems.shift()
    },
    ADD_TOAST_ITEM(state, toast) {
      state.toastItems.push(toast)
    },
    SHIFT_TOAST(state) {
      state.toastItems.shift()
    },
  },

  getters: {
    getPopupItems(state) {
      return state.popupItems
    },
    getCurrentPopup(state) {
      return state.popupItems[0]
    },
    getToastItems(state) {
      return state.toastItems
    },
    getCurrentToast(state) {
      return state.toastItems[0]
    },
  },
}
