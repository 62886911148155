


















































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { mdiCircleOutline, mdiCircleSlice8, mdiWindowClose } from '@mdi/js'
import userService from '@/services-itgo/user.service'
import { Action, Getter } from 'vuex-class'
import USER_STATUS from '@/views/constant/user.status'

@Component({
  components: {},
})
export default class EditPopup extends Vue {
  @Prop(String) termsType
  @Prop(Number) userId
  @Prop(Number) type

  public icons: any = {
    mdiWindowClose,
    mdiCircleOutline,
    mdiCircleSlice8,
  }

  termsData = {
    isAgrees: false,
    isNotAgrees: false,
    reasonContent: '',
  }

  public reasonContent: string = ''

  mounted(){
    console.log(this.type)
  }

  setIsAddition() {
    if (this.termsData.isNotAgrees) this.termsData.isNotAgrees = false
    this.termsData.isAgrees = true
  }

  setIsCut() {
    if (this.termsData.isAgrees) this.termsData.isAgrees = false
    this.termsData.isNotAgrees = true
  }

  async setModifyChoiceTerms(){
    try {
      const body = {
        userId : this.userId,
        userType : USER_STATUS.USER_TYPE_MANAGER,
        onYn : this.termsData.isAgrees ? 'Y' : 'N',
        terms : this.termsType,
        reason: this.termsData.reasonContent,
      }
      await userService.setModifyChoiceTerms(body)
      this.$emit("updateUserInfo")
    }catch (e) {

    }finally {

    }
  }

  get isSubmitBtn(){
    if((this.termsData.isAgrees || this.termsData.isNotAgrees) && this.termsData.reasonContent){
      return false
    }else
      return true
  }

  async editPopupHideBtn(){
    if(this.termsData.isAgrees || this.termsData.isNotAgrees || this.termsData.reasonContent){
      const result = await this.$confirm({
        title : '종료 안내',
        content : `변경 또는 작성 중인 내용이 있습니다. 팝업을 닫으면 <br/> 변경 또는 작성 중인 내용은 저장되지 않습니다.`
      })
      if(result){
        this.$emit('editPopupHide')
      }
    }else {
      this.$emit('editPopupHide')
    }
  }
}
