















































































































import { Vue, Component } from 'vue-property-decorator'
import DatePicker from '@/views/utils/DatePicker.vue'
import {
  mdiMagnify,
  mdiChevronDown,
  mdiMicrosoftExcel,
  mdiCircleOutline,
  mdiCircleSlice8,
} from '@mdi/js'
import { Getter, State } from 'vuex-class'
import Vue2DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ko'
import adminService from '@/services-itgo/admin.service'
import DatetimeUtils from '@/utils/datetime.utils'

@Component({
  components: {
    DatePicker,
    Vue2DatePicker,
  },

  mounted() {
    this.setMonthDate()
  },
})
export default class Testimonials extends Vue {
  @State((state) => state.auth.user) loginUser
  @Getter('auth/getUserName') userName

  icons: any = {
    mdiMagnify,
    mdiChevronDown,
    mdiMicrosoftExcel,
    mdiCircleOutline,
    mdiCircleSlice8,
  }

  checkboxList = {
    public: false,
    other: false,
  }

  monthDate: any = null

  get excelBtnDisable() {
    if ((this.checkboxList.other || this.checkboxList.public) && this.monthDate)
      return false
    else return true
  }

  setMonthDate() {
    const now = new Date()
    this.monthDate = new Date(now.getFullYear(), now.getMonth() - 1, 1)
  }

  notBeforeMonth() {
    const now = new Date()
    return this.monthDate < new Date(now.getFullYear(), now.getMonth() - 1, 1)
  }

  checkPublic() {
    if (this.checkboxList.public) this.checkboxList.other = false
    console.log(this.checkboxList)
  }

  checkOther() {
    if (this.checkboxList.other) this.checkboxList.public = false
    console.log(this.checkboxList)
  }

  async onClickDownloadExcel() {
    try {
      console.log(this.monthDate)
      console.log(DatetimeUtils.stringDate(this.monthDate, 'YYYY-MM'))
      const body = {
        month: DatetimeUtils.stringDate(this.monthDate, 'YYYY-MM'),
        purpose: this.checkboxList.public
          ? 1
          : this.checkboxList.other
          ? 2
          : null,
      }

      let dateExcel = ''
      if (this.monthDate) {
        dateExcel = DatetimeUtils.stringDate(this.monthDate, 'YYYY-MM')
      }

      const nameExcel = `화물잇고_화물차주월간이용내역_${dateExcel}.xlsx`

      const result = await adminService.getDriverReport(body)
      console.log(result)
      const blob = new Blob([result], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      const link = document.createElement('a')
      const blobUrl = URL.createObjectURL(blob)
      link.href = blobUrl
      link.download = nameExcel

      link.click()
      URL.revokeObjectURL(blobUrl)
    } catch (e) {
      console.log(e)
    } finally {
    }
  }

  // vue2-date-picker
  notBeforeToday(date) {
    let now = new Date()
    return date > new Date(new Date(now.setMonth(now.getMonth() - 1)))
  }
  notBeforeTodayTwelveOClock(date) {
    return date > new Date(new Date().setHours(12, 0, 0, 0))
  }
}
