








import axios from 'axios'
import AuthService from './services-itgo/auth.service'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter, Mutation } from 'vuex-class'
import _ from 'lodash'
import GlobalPopup from './containers/GlobalPopup.vue'
import FcmService from './services-itgo/fcm.service'
import AxiosService from '@/services-itgo/axios.service'
import configService from '@/services-itgo/config.service'

@Component({
  components: {
    GlobalPopup,
  },
})
export default class App extends Vue {
  @Action('auth/refresh') actionAuthRefresh
  @Action('vehicle/getVehicleList') getVehicleList
  @Action('auth/getUser') getUser
  @Getter('auth/getNiceData') getNiceData
  @Getter('auth/getEncData') getEncData
  @Getter('auth/getIsAutoLogout') getAutoLogout

  @Mutation('auth/signout') signOut
  @Mutation('auth/saveAutoLogout') saveAutoLogout

  @Watch('getAutoLogout')
  async onGetAutoLogout() {
    console.log(this.getAutoLogout)
    if (this.getAutoLogout) {
      try {
        await this.$store.dispatch('auth/signout', 1)
        const result = await this.$alert({
          title: '자동 로그아웃 안내',
          content: `로그인 후 20분 동안 서비스 이용이 없어 <br/> 개인 정보 보호를 위해 자동 로그아웃 되었습니다.`,
        })
        if (result) location.reload()
      } catch (e) {
      } finally {
      }
    }
  }

  public unsubscribe: any = null

  async mounted() {
    //    window.addEventListener('beforeunload', this.handleBeforeUnload)

    // 이벤트 설정 (for 초기 데이터 로딩)
    this.$root.$on('load-init-data', this.loadInitData)

    // 푸쉬 연결
    //MqttService.connect();

    //FCM 연결
    // connect()

    //공사중 페이지
    await this.getServerInspection()

    //pass 인증을 위해서 사용함.
    if (this.getNiceData) {
      return
    }

    // const token = this.$store.state.auth.token
    // if (!token || !token.refresh_token) {
    //   console.log(`로그인이 필요 합니다.`)
    //   return
    // }

    const accessToken = window.sessionStorage.getItem('accessToken')
    const refreshToken = window.sessionStorage.getItem('refreshToken')

    if (!accessToken && !refreshToken) {
      console.log(`로그인이 필요 합니다.`)
      return
    }

    try {
      const newToken = await this.actionAuthRefresh(refreshToken)
      console.log(`앱 실행 - 접근키 갱신 완료 : ${newToken}`)
      axios.defaults.headers.common['Authorization'] =
        'Bearer ' + newToken.access_token
      // 앱 초기 데이터 로딩
      setTimeout(this.loadInitData, 500)
    } catch (error) {
      console.error(`앱 실행 - 접근키 갱신 실패 ${error}`)
      this.signOut(false)
      this.$router.push('/login')
    }
  }

  destroyed() {
    //    window.removeEventListener('beforeunload', this.handleBeforeUnload)
  }

  // async handleBeforeUnload(event) {
  //   // 이벤트 핸들러에서 확인 메시지를 정의합니다.
  //   await this.$store.dispatch('auth/signout', 3)
  // }

  async loadInitData() {
    console.log(`초기 데이터 로딩!!!!`)

    // 인증 토큰 갱신 스케줄러 시작
    AuthService.startRefreshTokenScheduler()

    // 자동 로그 아웃 기능 위한 스케줄러 생성
    this.saveAutoLogout(false)
    AuthService.startAutoLogoutListenerScheduler()

    // 인증 사용자 정보 조회
    const user = await this.getUser()

    console.log('user===============', user)
    FcmService.connect(user.controllerId)

    //자동 로그 아웃을 위해 스케줄 starting 함.
    //AuthService.startAutoLogoutScheduler()

    //interceptors 기능 구현하여 자동 로그 아웃 기능 구현이 필요함.
    AxiosService.requestInterceptors()
    AxiosService.responseInterceptors()

    //server config
    await this.postServerConfig()
  }

  async postServerConfig() {
    try {
      await this.$store.dispatch('serverConfig/setCurrentConfig')
    } catch (e) {
      console.log('서버 정보 가지고 오지 못함.')
    } finally {
    }
  }

  async getServerInspection() {
    try {
      const result = await configService.getServerInspection()
      console.log(result)
      if (result.unavailable) {
        await this.$router.push({
          name: 'renewal',
        })
      }
    } catch (e) {
    } finally {
    }
  }
}
