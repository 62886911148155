var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-wrapper content_body" },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c(
                "v-icon",
                {
                  staticClass: "surface800 pt-1",
                  attrs: { size: "35" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.back()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.icons.mdiChevronLeft) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }