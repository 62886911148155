var logi = logi ?? {};
logi['maps'] = logi['maps'] ?? {};
import a11_0x17b5e1 from './base/logi-maps-types.js';
logi['maps']['EVENT'] = a11_0x17b5e1['EVENT'], logi['maps']['OBJEVENT'] = a11_0x17b5e1['OBJEVENT'], logi['maps']['BRIDGE_MAPEVENT'] = a11_0x17b5e1['BRIDGE_MAPEVENT'], logi['maps']['ALIGN'] = a11_0x17b5e1['ALIGN'], logi['maps']['LINETYPE'] = a11_0x17b5e1['LINETYPE'], logi['maps']['LatLng'] = a11_0x17b5e1['LatLng'], logi['maps']['Point'] = a11_0x17b5e1['Point'], logi['maps']['LatLngBounds'] = a11_0x17b5e1['LatLngBounds'];
import a11_0xaa027f from './base/logi-maps-utils.js';
logi['maps']['Utils'] = a11_0xaa027f;
import a11_0x5ef7fe from './logi-maps-textinfo.js';
logi['maps']['TextInfo'] = a11_0x5ef7fe;
import a11_0x32a35b from './logi-maps-image.js';
logi['maps']['Image'] = a11_0x32a35b;
import a11_0x36ba04 from './logi-maps-label.js';
logi['maps']['Label'] = a11_0x36ba04;
import a11_0x456302 from './logi-maps-polygon.js';
logi['maps']['Polygon'] = a11_0x456302;
import a11_0x4b72e6 from './logi-maps-line.js';
logi['maps']['Line'] = a11_0x4b72e6;
import a11_0x471551 from './logi-maps-route.js';
logi['maps']['Route'] = a11_0x471551;
import a11_0x278cf7 from './logi-maps-map.js';
logi['maps']['Map'] = a11_0x278cf7;
export default logi;