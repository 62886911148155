import axios from 'axios'

class UserService {
  private apiUrl: string

  constructor() {
    this.apiUrl = '/user'
  }

  /**
   * 중복 아이디 조회
   */
  getUserExist(loginId) {
    const body: any = {
      loginId: loginId,
    }

    return axios.post(this.apiUrl + '/exist', body).then((res) => {
      return Promise.resolve(res.data)
    })
  }

  /**
   * 아이디 찾기
   */
  // getUserFindUserId(mobile) {
  //   const params: any = {
  //     mobile: mobile,
  //   }

  //   return axios
  //     .get(this.apiUrl + `/findUserId`, { params: params })
  //     .then((res) => {
  //       return Promise.resolve(res.data)
  //     })
  // }

  /**
   * 비밀번호 재설정
   * {
   *     "password": "123456",
   *     "mobile" : "01022221113"
   * }
   */
  // setUserPasswordReset(body) {
  //   return axios.post(this.apiUrl + `/passwordReset`, body).then((res) => {
  //     return Promise.resolve(res.data)
  //   })
  // }

  /**
   * 전체 회원 가입 승인 목록
   */
  getUserReviewList(params) {
    return axios
      .post(this.apiUrl + `/reviewList`, {}, { params })
      .then((res) => res.data)
  }

  /**
   * 전체 회원 서류 변경 목록
   * *차주 차량등록증, 주선사 주선허가증 만 서류 변경할 수 있고 서류 변경 중에는 화물 등록 및 수주가 제한된다.
   */
  getUserChangeList(params) {
    return axios
      .post(this.apiUrl + `/changeList`, {}, { params })
      .then((res) => res.data)
  }

  /**
   * 회원 가입 심사 상태 조회
   */
  // getUserReviewStatus() {
  //   return axios.get(this.apiUrl + `/reviewStatus`).then((res) => res.data)
  // }

  /**
   * 회원 가입 승인 총 개수 조회
   */
  getUserReviewCount() {
    return axios.get(this.apiUrl + `/reviewCount`).then((res) => res.data)
  }

  /**
   * VOC용 회원찾기
   */
  getUserList(params) {
    return axios
      .get(this.apiUrl + '/userList', { params })
      .then((res) => res.data)
  }

  /**
   * 사용자 정보 사진 보기
   * @param params
   */
  getUserFile(params) {
    return axios
      .get(this.apiUrl + '/file', {
        responseType: 'blob',
        params: params,
      })
      .then((res) => res.data)
  }

  postGetUserTerms(body) {
    return axios.post(this.apiUrl + '/user-terms', body).then((res) => res.data)
  }
  /**
   * 회원 선택 약관 동의 변경
   */
  setModifyChoiceTerms(body) {
    return axios
      .put(this.apiUrl + '/modify-choice-terms', body)
      .then((res) => res.data)
  }

  /**
   * 준 회원 목록
   */
  getAssociateUserList(params) {
    return axios
      .post(this.apiUrl + '/associateList', {}, { params })
      .then((res) => res.data)
  }

  getUserInfo(body) {
    return axios.post(this.apiUrl + '/user-info', body).then((res) => res.data)
  }

  getUserSendMMS(body) {
    return axios
      .post(this.apiUrl + '/request-mms', body)
      .then((res) => res.data)
  }

  /**
   * 강제 정회원 전환 차주 목록
   */
  forcedApprovalList(params) {
    return axios
      .post(this.apiUrl + '/forcedApprovalList', {}, { params })
      .then((res) => res.data)
  }
}

export default new UserService()
