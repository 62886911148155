































import LayoutContentVerticalNav from '../../@core/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'
import userMenuItems from '../../navigation/vertical/user/'
import managerMenuItems from '../../navigation/vertical/manager/'
import adminMenuItems from '../../navigation/vertical/admin/'
import superAdminMenuItems from '../../navigation/vertical/superAdmin/'
import AppBarNotification from '@/views/layouts/app-bar/AppBarNotification.vue'
import { mdiMenu, mdiHeartOutline } from '@mdi/js'
import AppBarUserMenu from '@/views/layouts/app-bar/AppBarUserMenu.vue'
import _ from 'lodash'
import { Vue, Component, Watch } from 'vue-property-decorator'
import { State } from 'vuex-class'
import AppBarCompany from '@/views/layouts/app-bar/AppBarCompany.vue'
// import AppBarUserManager from '@/views/layouts/app-bar/AppBarUserManager.vue'
// import AppBarMapUpload from '@/views/layouts/app-bar/AppBarMapUpload.vue'
import { CONTROLLER_ROLE } from '@/views/constant/user.role.constant'

@Component({
  name: 'LayoutContentVerticalNavUser',
  components: {
//    AppBarUserManager,
    AppBarCompany,
    LayoutContentVerticalNav,
    AppBarNotification,
    AppBarUserMenu,
//    AppBarMapUpload,
  },
})
export default class LayoutContentVerticalNavUser extends Vue {
  @State((state) => state.auth.user) loginUser

  public masterMenuItems = []

  public icons = {
    mdiMenu,
    mdiHeartOutline,
  }

  public unsubscribe: any = null

  // @State(state => state.auth.user) loginUser;
  // @State(state => state.notifications.userInvoicesCount) userInvoicesCount;
  // @State(state => state.notifications.vehicleSubInvoicesCount) vehicleSubInvoicesCount;
  // @State(state => state.notifications.vehicleSafetyInvoicesCount) vehicleSafetyInvoicesCount;
  // @State(state => state.notifications.callStatusCountList) callStatusCountList;
  // @State(state => state.notifications.subPassbookInvoicesCount) subPassbookInvoicesCount;

  setMenuContent() {

    if(CONTROLLER_ROLE.isSuperAdmin(this.loginUser.controllerRole))
      this.masterMenuItems = superAdminMenuItems
    else if (CONTROLLER_ROLE.isAdmin(this.loginUser.controllerRole))
      this.masterMenuItems = adminMenuItems
    else if (CONTROLLER_ROLE.isManager(this.loginUser.controllerRole))
      this.masterMenuItems = managerMenuItems
    else this.masterMenuItems = userMenuItems
  }

  created() {
    // if (USER_ROLE.isSuperControllerRole(this.loginUser.controllerRole)) {
    //     this.$store.dispatch('notifications/getUserReviewCount');
    //     this.$store.dispatch('notifications/getDriverForSubVehicleReviewListTotalCount');
    //     this.$store.dispatch('notifications/getSafetyVehicleStatusCount');
    //     this.$store.dispatch('notifications/getCallsStatusCount');
    // }

    this.setMenuContent()
    console.log(this.masterMenuItems)
    console.log(this.loginUser)

    ///////////////////////////////////////////////////////////
    // 저장소 구독하여 변경 사항이 발생시 이벤트 발생
    // if (USER_ROLE.isSuperControllerRole(this.loginUser.controllerRole)) {
    //     this.unsubscribe = this.$store.subscribe((mutation, state) => {
    //         if (mutation.type === 'notifications/getUserReviewCount') {
    //             if (USER_ROLE.isManagerControllerRole(this.loginUser.controllerRole)) {
    //                 this.notificationsMembersCount();
    //             }
    //         } else if (mutation.type === 'notifications/getDriverForSubVehicleReviewListTotalCount') {
    //             if (USER_ROLE.isManagerControllerRole(this.loginUser.controllerRole)) {
    //                 this.notificationsSubVehicleCount();
    //             }
    //         } else if (mutation.type === 'notifications/getSafetyVehicleStatusCount') {
    //             if (USER_ROLE.isManagerControllerRole(this.loginUser.controllerRole)) {
    //                 this.notificationsSafetyVehicleCount();
    //             }
    //         } else if (mutation.type === 'notifications/getCallsStatusCount') {
    //             if (USER_ROLE.isManagerControllerRole(this.loginUser.controllerRole)) {
    //                 this.notificationsInvoiceCount();
    //             }
    //         }
    //         // else if (mutation.type === 'notifications/getSubPassbookStatusCount') {
    //         //     this.getSubPassbookStatusCount();
    //         // }
    //
    //     });
    // }
    ///////////////////////////////////////////////////////////
  }

  update() {}

  beforeDestroy() {
    this.unsubscribe()
  }

  //        @Watch('masterMenuItems', {deep: true})
  //        public changedMenuItems(value) {
  //            console.log(value);
  //        }

  //         async getSubDriverVehicleCount() {
  //             const results = await DriverService.getDriverSubVehicleAllList('', 1, 10);
  //             let sum = 0;
  //             _.forEach(results.vehicleList, vehicle => {
  //                 sum = sum + _.get(vehicle, 'count');
  //             })
  //
  //             const copy = _.cloneDeep(this.masterMenuItems);
  //
  //             let find: any = _.find(copy, {'title': 'DriverSubVehicleApproval'});
  // //            find.badge = sum;
  //             find.badge = '1/1/1';
  //             find.badgeColor = 'info';
  //
  //             this.masterMenuItems = copy;
  //         }
  //
  //         notificationsMembersCount() {
  //             const copy = _.cloneDeep(this.masterMenuItems);
  //
  //             //사용자 가입 심사 badge
  //             let find: any = _.find(copy, {'title': 'MembershipReview'});
  //             let findChildren: any = _.find(find.children, {'title': 'MembershipReviewInvoice'});
  //             findChildren.badge = this.userInvoicesCount.waiting + this.userInvoicesCount.reRequest;
  //             findChildren.badgeColor = 'info';
  //
  //             this.masterMenuItems = copy;
  //
  //         }
  //
  //         notificationsSubVehicleCount() {
  //             const copy = _.cloneDeep(this.masterMenuItems);
  //
  //             //사용자 가입 심사 badge
  //             let find: any = _.find(copy, {'title': 'MembershipReview'});
  //             let findChildren: any = _.find(find.children, {'title': 'DriverSubVehicleApproval'});
  //             findChildren.badge = this.vehicleSubInvoicesCount.waiting + this.vehicleSubInvoicesCount.reRequest;
  //             findChildren.badgeColor = 'info';
  //
  //             this.masterMenuItems = copy;
  //         }
  //
  //         notificationsSafetyVehicleCount() {
  //             const copy = _.cloneDeep(this.masterMenuItems);
  //
  //             //사용자 가입 심사 badge
  //             let find: any = _.find(copy, {'title': 'MembershipReview'});
  //             let findChildren: any = _.find(find.children, {'title': 'DriverSafetyApproval'});
  //             findChildren.badge = this.vehicleSafetyInvoicesCount.waiting + this.vehicleSafetyInvoicesCount.reRequest;
  //             findChildren.badgeColor = 'info';
  //
  //             this.masterMenuItems = copy;
  //         }
  //
  //         notificationsInvoiceCount() {
  //             const copy = _.cloneDeep(this.masterMenuItems);
  //
  //             //송장 승인 심사 badge 갯수
  //             let count = NumberUtil.getSumCount(CALL_STATUS.WEB_INVOICE_WAITING_STATUS_LIST(), this.callStatusCountList);
  //
  //             let find: any = _.find(copy, {'title': 'InvoiceApproval'});
  //             find.badge = count;
  //             find.badgeColor = 'info';
  //
  //             this.masterMenuItems = copy;
  //         }

  // getSubPassbookStatusCount() {
  //     const copy = _.cloneDeep(this.masterMenuItems);
  //
  //     let find: any = _.find(copy, {'title': 'MembershipReview'});
  //     let findChildren: any = _.find(find.children, {'title': 'SubPassbookInvoice'});
  //     findChildren.badge = this.subPassbookInvoicesCount.waiting + this.subPassbookInvoicesCount.failed + this.subPassbookInvoicesCount.reRequest;
  //     findChildren.badgeColor = 'info';
  //
  //     this.masterMenuItems = copy;
  // }
}
