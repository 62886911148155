<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="viewBox"
  >
    <g id="그룹_41336" data-name="그룹 41336" transform="translate(-1321 -540)">
      <rect
        id="사각형_31690"
        data-name="사각형 31690"
        width="70"
        height="70"
        transform="translate(1321 540)"
        fill="none"
      />
      <g id="ic_noorder" transform="translate(1327.389 555.746)">
        <path
          id="패스_27333"
          data-name="패스 27333"
          d="M34.6,6.024v18.87H3.145A3.145,3.145,0,0,0,0,28.039v6.29a3.146,3.146,0,0,0,3.145,3.146H6.29V34.329H3.145v-6.29H34.6v6.29H22.015v3.146H37.741V21.749H53.466V37.475a3.146,3.146,0,0,0,3.145-3.146V18.6L47.176,6.024ZM52.68,18.6,45.6,9.169H37.741V18.6Z"
          fill="#bdbdbd"
          fill-rule="evenodd"
        />
        <line
          id="선_907"
          data-name="선 907"
          y1="18.924"
          transform="translate(1.794 3.75)"
          fill="none"
          stroke="#bdbdbd"
          stroke-linejoin="round"
          stroke-width="2.5"
          stroke-dasharray="2.653 2.653"
        />
        <path
          id="패스_27337"
          data-name="패스 27337"
          d="M2.794,2.5V1.25h1.25"
          transform="translate(-1)"
          fill="none"
          stroke="#bdbdbd"
          stroke-linejoin="round"
          stroke-width="2.5"
        />
        <line
          id="선_908"
          data-name="선 908"
          x2="22.415"
          transform="translate(5.403 1.25)"
          fill="none"
          stroke="#bdbdbd"
          stroke-linejoin="round"
          stroke-width="2.5"
          stroke-dasharray="2.359 2.359"
        />
        <path
          id="패스_27338"
          data-name="패스 27338"
          d="M30,1.25h1.25V2.5"
          transform="translate(-1)"
          fill="none"
          stroke="#bdbdbd"
          stroke-linejoin="round"
          stroke-width="2.5"
        />
        <line
          id="선_909"
          data-name="선 909"
          y2="20"
          transform="translate(30.248 3.75)"
          fill="none"
          stroke="#bdbdbd"
          stroke-linejoin="round"
          stroke-width="2.5"
          stroke-dasharray="2.653 2.653"
        />
        <path
          id="빼기_1"
          data-name="빼기 1"
          d="M3624.222,19043.746h0l-18.211,0a7,7,0,1,0-13.786,0h-3.266a2.031,2.031,0,0,1-1.956-2.094v-7.607a2.031,2.031,0,0,1,1.956-2.094h32.256l.139-5.7h18.593v16.061a1.436,1.436,0,0,1-1.434,1.434h-.5a7,7,0,1,0-13.791,0Z"
          transform="translate(-3585.115 -19006.27)"
          fill="#bdbdbd"
        />
        <g
          id="타원_2566"
          data-name="타원 2566"
          transform="translate(9.001 31.254)"
          fill="none"
          stroke="#bdbdbd"
          stroke-width="2"
        >
          <circle cx="5" cy="5" r="5" stroke="none" />
          <circle cx="5" cy="5" r="4" fill="none" />
        </g>
        <g
          id="타원_2571"
          data-name="타원 2571"
          transform="translate(41.001 31.254)"
          fill="none"
          stroke="#bdbdbd"
          stroke-width="2"
        >
          <circle cx="5" cy="5" r="5" stroke="none" />
          <circle cx="5" cy="5" r="4" fill="none" />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'truck_70x70_disable',

  props: {
    viewBox: { type: String, default: '0 0 70 70' },
    iconName: { type: String, default: '' },
    width: { type: [Number, String], default: 70 },
    height: { type: [Number, String], default: 70 },
    iconColor: { type: String, default: '#ffffff' },
  },
}
</script>

<style scoped></style>
