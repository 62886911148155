























































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { mdiMenuDown, mdiMenuUp, mdiMinus } from '@mdi/js'
import _ from 'lodash'

@Component({
  name: 'DashBoardSimpleTable',
  components: {},
})
export default class DashBoardSimpleTable extends Vue {
  @Prop(Array) rankingData
  @Prop(Array) regDispatchData

  public icons: any = {
    mdiMenuUp,
    mdiMenuDown,
    mdiMinus,
  }

  noDataString = ''

  get simpleTableData() {
    if (this.rankingData?.length) {
      return this.rankingData
    } else if (this.regDispatchData?.length) {
      const result = _.filter(this.regDispatchData, (item) => item.count > 0)

      return _.map(result, (item) => {
        const listItem: any = {
          companyName: item.company?.name || '',
          confirmCount: item.count,
          diffRank: item.beforeRank - item.rank,
        }
        return listItem
      })
    } else {
      this.noDataString = "순위가 존재하지 않습니다."
      return null
    }
  }

  incrementCalculate(item) {
    if (item.diffRank > 0) {
      return '+'
    } else if (item.diffRank < 0) {
      return '-'
    } else {
      return null
    }
  }

  incrementCalculateCount(item) {
    if (item.diffRank === 0) {
      return null
    } else {
      return Math.abs(item.diffRank)
    }
  }

  isFirstItem(index) {
    if (index === 0) return true
    else return false
  }
}
