<script>
import { Doughnut } from 'vue-chartjs'
import {
  chartColors,
  doughnutColors,
  doughnutColors_local,
  doughnutColors_settlement,
} from '@/views/constant/ChartData'
import _ from 'lodash'
import 'chartjs-plugin-doughnutlabel'

export default {
  name: 'ChartjsComponentLocalDoughnutChart',
  extends: Doughnut,
  props: {
    baseLabels: {
      type: Array,
      default: null,
    },
    baseData: {
      type: Array,
      default: null,
    },
    totalCount: {
      type: Number,
      default: 0,
    },
    textLabels: {
      type: String,
      default: '',
    },
    searchDate: {
      type: String,
      default: '',
    },
    percent: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    this.renderDoughnut()
  },

  watch: {
    baseLabels: function () {
      this.renderDoughnut()
    },
    baseData: function () {
      this.renderDoughnut()
    },
    totalCount: function () {
      this.renderDoughnut()
    },
    percent: function () {
      this.renderDoughnut()
    },
  },

  methods: {
    renderDoughnut: function () {
      this.renderChart(
        {
          labels: this.baseLabels,
          datasets: [
            {
              labels: this.baseLabels,
              data: this.baseData,
              backgroundColor: doughnutColors_local,
              borderWidth: 1,
              pointStyle: 'rectRounded',
              totalCount: this.totalCount,
            },
          ],
        },
        {
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          cutoutPercentage: 60,
          rotation: 2.63,
          circumference: Math.PI*4/3,
          legend: {
            display: false,
          },
          tooltips: {
            enabled: false,
          },
          plugins: {
            doughnutlabel: {
              labels: [
                {
                  fontStyle: 'bold',
                  text: '',
                  font: {
                    size: 40,
                  },
                },
                {
                  fontStyle: 'bold',
                  text: '총'+this.totalCount.toLocaleString()+'개',
                  color: '#2F2F2F',
                  font: {
                    size: 13,
                  },
                },
                {
                  fontStyle: 'bold',
                  text: '배차 성공률',
                  color: '#2F2F2F',
                  font: {
                    size: 16,
                  },
                },
                {
                  fontStyle: 'bold',
                  text: this.percent.toLocaleString()+'%',
                  color: '#2F2F2F',
                  font: {
                    size: 22,
                  },
                },
              ],
            },
          },
        },
      )
    },
  },
}
</script>
