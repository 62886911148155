import _ from 'lodash'

/**
 * 차량 정보
 */
export = (() => {
  const _CARGO_LOADING_TYPE: any = {
    ALONE: 'alone', // 독차
    MIXED: 'mixed', // 혼적
  }

  const _CARGO_LOADING_TYPE_STRING: any = {
    ALONE: '독차',
    MIXED: '혼적',
  }

  Object.freeze(_CARGO_LOADING_TYPE)
  Object.freeze(_CARGO_LOADING_TYPE_STRING)

  const _CARGO_LOADING_TYPE_WEB_NAME: Map<number, string> = new Map<
    number,
    string
  >([
    [_CARGO_LOADING_TYPE.ALONE, _CARGO_LOADING_TYPE_STRING.ALONE],
    [_CARGO_LOADING_TYPE.MIXED, _CARGO_LOADING_TYPE_STRING.MIXED],
  ])

  return Object.freeze({
    ALONE: _CARGO_LOADING_TYPE.ALONE,
    MIXED: _CARGO_LOADING_TYPE.MIXED,

    ALONE_STRING: _CARGO_LOADING_TYPE_STRING.ALONE,
    MIXED_STRING: _CARGO_LOADING_TYPE_STRING.MIXED,

    CARGO_LOADING_TYPE_NAME_WEB: (index: number): string => {
      const cargoLoadingTypeName: string | undefined =
        _CARGO_LOADING_TYPE_WEB_NAME.get(index)
      return cargoLoadingTypeName ? cargoLoadingTypeName : '-'
    },
  })
})()
