var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-wrapper content_body" },
    [
      _c(
        "div",
        { staticClass: "content-inner" },
        [
          _vm.toggleNotice === 0
            ? _c(
                "v-card",
                {
                  staticClass: "d-flex flex-column justify-space-between",
                  staticStyle: {
                    "border-radius": "10px",
                    "min-height": "878px",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "notice-list" },
                    [
                      _c("v-card-title", { staticClass: "pt-8 px-6" }, [
                        _c(
                          "span",
                          {
                            staticClass:
                              "font-size26 font-weight700 surface800",
                          },
                          [_vm._v("공지사항")]
                        ),
                      ]),
                      _c("v-card-title", { staticClass: "mt-10 px-6 pb-5" }, [
                        _c("div", { staticClass: "flex-start-contents" }, [
                          _c(
                            "div",
                            { staticStyle: { width: "20%" } },
                            [
                              _c("v-select", {
                                staticClass: "pr-2",
                                staticStyle: { width: "100%" },
                                attrs: {
                                  dense: "",
                                  label: "공지 대상",
                                  items: _vm.noticeItems,
                                  "item-text": "name",
                                  "item-value": "value",
                                  outlined: "",
                                  "hide-details": "",
                                  "menu-props": { bottom: true, offsetY: true },
                                },
                                model: {
                                  value: _vm.notice,
                                  callback: function ($$v) {
                                    _vm.notice = $$v
                                  },
                                  expression: "notice",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticStyle: { width: "20%" } },
                            [
                              _c("v-select", {
                                staticClass: "pr-2",
                                staticStyle: { width: "100%" },
                                attrs: {
                                  dense: "",
                                  label: "공지 유형",
                                  items: _vm.categoryItems,
                                  "item-text": "name",
                                  "item-value": "value",
                                  outlined: "",
                                  "hide-details": "",
                                  "menu-props": { bottom: true, offsetY: true },
                                },
                                model: {
                                  value: _vm.category,
                                  callback: function ($$v) {
                                    _vm.category = $$v
                                  },
                                  expression: "category",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticStyle: { width: "20%" } },
                            [
                              _c("vue2-date-picker", {
                                staticClass: "management pr-2",
                                staticStyle: { width: "100%" },
                                attrs: {
                                  "value-type": "format",
                                  format: "YYYY-MM-DD",
                                  range: "",
                                  placeholder: "등록일",
                                  lang: "ko",
                                },
                                model: {
                                  value: _vm.date,
                                  callback: function ($$v) {
                                    _vm.date = $$v
                                  },
                                  expression: "date",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticStyle: { width: "35%" } },
                            [
                              _c("v-text-field", {
                                staticClass: "pr-2",
                                attrs: {
                                  label: "제목, 작성자로 검색",
                                  "append-icon": _vm.icons.mdiMagnify,
                                  outlined: "",
                                  dense: "",
                                  "hide-details": "",
                                  clearable: "",
                                  maxLength: "40",
                                },
                                on: {
                                  keypress: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.onSearch.apply(null, arguments)
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "append",
                                      fn: function () {
                                        return [
                                          _c(
                                            "v-icon",
                                            { on: { click: _vm.onSearch } },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.icons.mdiMagnify) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  582925168
                                ),
                                model: {
                                  value: _vm.keyWord,
                                  callback: function ($$v) {
                                    _vm.keyWord = $$v
                                  },
                                  expression: "keyWord",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "pr-2" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "itgoWhite--text px-2 font-size18 search-btn",
                                  attrs: { color: "itgoInfo" },
                                  on: { click: _vm.onSearch },
                                },
                                [_vm._v(" 조회 ")]
                              ),
                            ],
                            1
                          ),
                          _vm.isBulletinWritable
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "font-size18 search-btn",
                                      attrs: { dense: "", outlined: "" },
                                      on: { click: _vm.onCreate },
                                    },
                                    [_vm._v(" 작성하기 ")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                      ]),
                      _c(
                        "v-data-table",
                        _vm._b(
                          {
                            staticStyle: { "min-height": "540px !important" },
                            attrs: {
                              "item-key": "noticeId",
                              "checkbox-color": "#E5318E",
                              "items-per-page": _vm.pageSize,
                              page: _vm.pageNum,
                              expanded: _vm.expanded,
                              "hide-default-footer": "",
                              "disable-sort": "",
                              "item-class": function () {
                                return "row-style"
                              },
                              "show-select": _vm.isBulletinWritable,
                            },
                            on: {
                              "update:page": function ($event) {
                                _vm.pageNum = $event
                              },
                              "update:expanded": function ($event) {
                                _vm.expanded = $event
                              },
                              "click:row": _vm.onRowClicked,
                            },
                            scopedSlots: _vm._u(
                              [
                                _vm.isBulletinWritable
                                  ? {
                                      key: "top",
                                      fn: function () {
                                        return [
                                          _c(
                                            "v-card-text",
                                            { staticClass: "table-top" },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.selectedItems.length
                                                  )
                                                ),
                                              ]),
                                              _c(
                                                "span",
                                                {
                                                  class: {
                                                    disabled:
                                                      !_vm.selectedItems.length,
                                                  },
                                                  style:
                                                    _vm.selectedItems.length &&
                                                    "cursor: pointer",
                                                  on: { click: _vm.onDelete },
                                                },
                                                [_vm._v(" 삭제 ")]
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    }
                                  : null,
                                {
                                  key: "no-data",
                                  fn: function () {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.isSearched
                                              ? "검색 결과가 없습니다."
                                              : "등록된 게시글이 없습니다."
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "item.noticeId",
                                  fn: function (ref) {
                                    var value = ref.value
                                    return [
                                      _vm._v(" " + _vm._s(value || "-") + " "),
                                    ]
                                  },
                                },
                                {
                                  key: "item.category",
                                  fn: function (ref) {
                                    var value = ref.value
                                    return [
                                      _vm._v(" " + _vm._s(value || "-") + " "),
                                    ]
                                  },
                                },
                                {
                                  key: "item.userName",
                                  fn: function (ref) {
                                    var value = ref.value
                                    return [
                                      _vm._v(" " + _vm._s(value || "-") + " "),
                                    ]
                                  },
                                },
                                {
                                  key: "item.appPushedDt",
                                  fn: function (ref) {
                                    var value = ref.value
                                    return [
                                      _vm._v(
                                        " " + _vm._s(_vm.checkSent(value)) + " "
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "item.regDt",
                                  fn: function (ref) {
                                    var value = ref.value
                                    return [
                                      _vm._v(" " + _vm._s(value || "-") + " "),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                            model: {
                              value: _vm.selectedItems,
                              callback: function ($$v) {
                                _vm.selectedItems = $$v
                              },
                              expression: "selectedItems",
                            },
                          },
                          "v-data-table",
                          _vm.noticeInfo,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                  _c("Pagination", {
                    attrs: {
                      pageSizes: _vm.pageSizes,
                      totalPageCount: _vm.totalPageCount,
                      pageSize: _vm.pageSize,
                      pageNum: _vm.pageNum,
                    },
                    on: {
                      "update:pageSize": function ($event) {
                        _vm.pageSize = $event
                      },
                      "update:page-size": function ($event) {
                        _vm.pageSize = $event
                      },
                      "update:pageNum": function ($event) {
                        _vm.pageNum = $event
                      },
                      "update:page-num": function ($event) {
                        _vm.pageNum = $event
                      },
                    },
                  }),
                ],
                1
              )
            : _vm.toggleNotice === 1
            ? _c(
                "v-card",
                {
                  staticStyle: {
                    "border-radius": "10px",
                    "min-height": "878px",
                  },
                },
                [
                  _c(
                    "v-card-title",
                    {
                      staticClass: "pr-6",
                      staticStyle: {
                        display: "flex",
                        "justify-content": "space-between",
                        "align-items": "center",
                      },
                    },
                    [
                      _c("div", [
                        _c("div", { staticClass: "flex-start-contents" }, [
                          _c(
                            "div",
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "pr-2",
                                  attrs: { size: "50" },
                                  on: { click: _vm.backToList },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.icons.mdiChevronLeft) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c("div", [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "font-size26 font-weight700 surface800",
                              },
                              [_vm._v(" 공지사항 ")]
                            ),
                          ]),
                        ]),
                      ]),
                      _vm.isBulletinWritable
                        ? _c(
                            "div",
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "font-size18 push-btn",
                                  attrs: {
                                    dense: "",
                                    outlined: "",
                                    disabled: _vm.setPushBtnIsActive,
                                  },
                                  on: { click: _vm.pushApp },
                                },
                                [_vm._v(" PUSH ")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                  _c("v-card-text", { staticClass: "mt-10 pb-5 px-8" }, [
                    _c(
                      "div",
                      { staticClass: "font-size24 font-weight700 surface800" },
                      [_vm._v(" " + _vm._s(_vm.selected.title) + " ")]
                    ),
                    _c("div", { staticClass: "font-size16 surface500 mt-3" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.selected.regDt || "-") +
                          " | " +
                          _vm._s(_vm.selected.category || "-") +
                          " "
                      ),
                    ]),
                    _c("div", { staticClass: "font-size16 surface500 mt-3" }, [
                      _vm._v(" " + _vm._s(_vm.noticeTypeString) + " "),
                    ]),
                    _c("div", { staticClass: "font-size16 surface500 mt-3" }, [
                      _vm._v(" " + _vm._s(_vm.exposureString) + " "),
                    ]),
                  ]),
                  _c("v-divider", { staticClass: "mx-8 mt-2 mb-0" }),
                  _c(
                    "v-card-text",
                    { staticClass: "px-8 pt-6" },
                    [
                      _c("v-textarea", {
                        staticClass:
                          "detail-content font-size18 surface800 mt-0 pt-0",
                        attrs: { "no-resize": "", readonly: "", rows: "10" },
                        model: {
                          value: _vm.categoryDetail.content,
                          callback: function ($$v) {
                            _vm.$set(_vm.categoryDetail, "content", $$v)
                          },
                          expression: "categoryDetail.content",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "v-card",
                {
                  staticStyle: {
                    "border-radius": "10px",
                    "min-height": "878px",
                  },
                },
                [
                  _c(
                    "v-card-title",
                    {
                      staticClass: "pr-6",
                      staticStyle: {
                        display: "flex",
                        "justify-content": "space-between",
                        "align-items": "center",
                      },
                    },
                    [
                      _c("div", [
                        _c("div", { staticClass: "flex-start-contents" }, [
                          _c(
                            "div",
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "pr-2",
                                  attrs: { size: "50" },
                                  on: { click: _vm.backKeyToList },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.icons.mdiChevronLeft) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c("div", [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "font-size26 font-weight700 surface800",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.noticeMode === "create"
                                        ? "공지사항 작성"
                                        : "공지사항 수정"
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ]),
                      _c(
                        "div",
                        [
                          _vm.noticeMode === "create"
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "font-size18 search-btn mr-2",
                                  attrs: { dense: "", outlined: "" },
                                  on: { click: _vm.toggleLoadPopup },
                                },
                                [_vm._v(" 불러오기 ")]
                              )
                            : _vm._e(),
                          _vm.noticeMode === "create"
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "font-size18 search-btn mr-2",
                                  attrs: {
                                    dense: "",
                                    outlined: "",
                                    disabled: _vm.isRegisterLoading,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.saveNotice(true)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " 임시 저장 | " +
                                      _vm._s(_vm.temporaryItems.length) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "itgoWhite--text px-2 font-size18 search-btn",
                              attrs: {
                                color: "itgoInfo",
                                disabled: _vm.isRegisterLoading,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.saveNotice(false)
                                },
                              },
                            },
                            [_vm._v(" 등록 ")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c("v-card-title", { staticClass: "mt-10" }, [
                    _c("div", { staticClass: "flex-start-contents" }, [
                      _c(
                        "div",
                        { staticStyle: { width: "150px" } },
                        [
                          _c("v-select", {
                            staticClass: "ml-3",
                            staticStyle: { width: "100%" },
                            attrs: {
                              dense: "",
                              label: "공지 대상",
                              "persistent-placeholder": "",
                              items: _vm.noticeTargetCreateItems,
                              "item-text": "name",
                              "item-value": "value",
                              outlined: "",
                              "hide-details": "",
                              "menu-props": { bottom: true, offsetY: true },
                            },
                            model: {
                              value: _vm.noticeContents.noticeTargetCreate,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.noticeContents,
                                  "noticeTargetCreate",
                                  $$v
                                )
                              },
                              expression: "noticeContents.noticeTargetCreate",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { width: "150px" } },
                        [
                          _c("v-select", {
                            staticClass: "ml-7",
                            staticStyle: { width: "100%" },
                            attrs: {
                              dense: "",
                              label: "공지 유형",
                              "persistent-placeholder": "",
                              placeholder: "선택",
                              items: _vm.categoryCreateItems,
                              "item-text": "name",
                              "item-value": "value",
                              outlined: "",
                              "hide-details": "",
                              "menu-props": { bottom: true, offsetY: true },
                            },
                            model: {
                              value: _vm.noticeContents.categoryCreate,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.noticeContents,
                                  "categoryCreate",
                                  $$v
                                )
                              },
                              expression: "noticeContents.categoryCreate",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "datepicker-sec" }, [
                      _c(
                        "div",
                        {
                          staticClass: "datepicker-part",
                          staticStyle: { margin: "20px 0 0 8px !important" },
                        },
                        [
                          _c(
                            "h2",
                            { staticClass: "font-size16 surface800 mb-0 mr-5" },
                            [_vm._v("메인노출 설정")]
                          ),
                          _c("v-checkbox", {
                            staticClass: "ma-0 pa-0 pr-4 checkbox-style",
                            staticStyle: { "border-right": "1px solid #000" },
                            attrs: { color: "accent" },
                            on: {
                              change: function ($event) {
                                return _vm.checkboxChange("default")
                              },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _c(
                                      "span",
                                      { staticClass: "font-size16 surface800" },
                                      [_vm._v("상시 노출")]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.noticeContents.defaultCheck,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.noticeContents,
                                  "defaultCheck",
                                  $$v
                                )
                              },
                              expression: "noticeContents.defaultCheck",
                            },
                          }),
                          _c(
                            "div",
                            { staticClass: "ml-4 mr-4" },
                            [
                              _c("v-checkbox", {
                                staticClass: "ma-0 pa-0 checkbox-style",
                                attrs: { color: "accent" },
                                on: {
                                  change: function ($event) {
                                    return _vm.checkboxChange("reserve")
                                  },
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "label",
                                    fn: function () {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "font-size16 surface800",
                                          },
                                          [_vm._v("노출 예약")]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                                model: {
                                  value: _vm.noticeContents.isReserve,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.noticeContents,
                                      "isReserve",
                                      $$v
                                    )
                                  },
                                  expression: "noticeContents.isReserve",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "mr-5",
                              staticStyle: { width: "140px" },
                            },
                            [
                              _c("vue2-date-picker", {
                                staticClass: "management",
                                staticStyle: { width: "100%" },
                                attrs: {
                                  disabled: !_vm.noticeContents.isReserve,
                                  "default-value": "",
                                  format: "YYYY-MM-DD",
                                  placeholder: "전송 날짜",
                                  lang: "ko",
                                },
                                model: {
                                  value: _vm.noticeContents.openDate.createDate,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.noticeContents.openDate,
                                      "createDate",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "noticeContents.openDate.createDate",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticStyle: { width: "140px" } },
                            [
                              _c("vue2-date-picker", {
                                staticClass: "management",
                                staticStyle: { width: "100%" },
                                attrs: {
                                  "time-picker-options": _vm.pickUpConfigTime,
                                  format: "HH:mm",
                                  type: "time",
                                  placeholder: "HH:mm",
                                  disabled: !_vm.noticeContents.isReserve,
                                  lang: "ko",
                                },
                                model: {
                                  value:
                                    _vm.noticeContents.openDate.selectedTime,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.noticeContents.openDate,
                                      "selectedTime",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "noticeContents.openDate.selectedTime",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("em", { staticClass: "px-3" }, [_vm._v("~")]),
                          _c(
                            "div",
                            {
                              staticClass: "mr-5",
                              staticStyle: { width: "140px" },
                            },
                            [
                              _c("vue2-date-picker", {
                                staticClass: "management",
                                staticStyle: { width: "100%" },
                                attrs: {
                                  disabled: !_vm.noticeContents.isReserve,
                                  "default-value": "",
                                  format: "YYYY-MM-DD",
                                  placeholder: "전송 날짜",
                                  lang: "ko",
                                },
                                model: {
                                  value:
                                    _vm.noticeContents.closeDate.createDate,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.noticeContents.closeDate,
                                      "createDate",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "noticeContents.closeDate.createDate",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "font-size16 font-weight500",
                              staticStyle: { width: "140px" },
                            },
                            [
                              _c("vue2-date-picker", {
                                staticClass: "management",
                                staticStyle: { width: "100%" },
                                attrs: {
                                  "time-picker-options": _vm.pickUpConfigTime,
                                  format: "HH:mm",
                                  type: "time",
                                  placeholder: "HH:mm",
                                  disabled: !_vm.noticeContents.isReserve,
                                  lang: "ko",
                                },
                                model: {
                                  value:
                                    _vm.noticeContents.closeDate.selectedTime,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.noticeContents.closeDate,
                                      "selectedTime",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "noticeContents.closeDate.selectedTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c("v-divider", { staticClass: "mt-n2" }),
                  _c(
                    "v-card-text",
                    { staticClass: "px-6" },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "제목",
                          placeholder: "제목",
                          "persistent-placeholder": "",
                          outlined: "",
                          "no-resize": "",
                          "hide-details": "",
                          dense: "",
                          maxlength: "40",
                        },
                        model: {
                          value: _vm.noticeContents.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.noticeContents, "title", $$v)
                          },
                          expression: "noticeContents.title",
                        },
                      }),
                      _c("div", { staticClass: "d-flex justify-end mt-1" }, [
                        _vm._v(
                          " " + _vm._s(_vm.noticeContents.title.length) + "/40 "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    { staticClass: "px-6" },
                    [
                      _c("v-textarea", {
                        attrs: {
                          label: "내용",
                          placeholder: "내용",
                          "persistent-placeholder": "",
                          outlined: "",
                          "no-resize": "",
                          "hide-details": "",
                          rows: "10",
                          maxlength: "500",
                        },
                        model: {
                          value: _vm.noticeContents.content,
                          callback: function ($$v) {
                            _vm.$set(_vm.noticeContents, "content", $$v)
                          },
                          expression: "noticeContents.content",
                        },
                      }),
                      _c("div", { staticClass: "d-flex justify-end mt-1" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.noticeContents.content.length) +
                            "/500 "
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "551" },
          model: {
            value: _vm.isSendPopup,
            callback: function ($$v) {
              _vm.isSendPopup = $$v
            },
            expression: "isSendPopup",
          },
        },
        [
          _c("send-popup", {
            attrs: { state: _vm.pushState },
            on: {
              close: function ($event) {
                _vm.isSendPopup = false
              },
              confirmSend: _vm.sendContents,
            },
          }),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "551" },
          model: {
            value: _vm.isLoadPopup,
            callback: function ($$v) {
              _vm.isLoadPopup = $$v
            },
            expression: "isLoadPopup",
          },
        },
        [
          _c("load-popup", {
            attrs: { temporaryItems: _vm.temporaryItems },
            on: {
              delete: _vm.deleteItems,
              load: _vm.loadItem,
              close: _vm.loadPopupClose,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }