































































































import { mdiBellOutline, mdiChevronRight } from '@mdi/js'

// 3rd Party
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import { Component, Vue, Watch } from 'vue-property-decorator'
import PushService from '@/services-itgo/push.service'
import DatetimeUtils from '@/utils/datetime.utils'
import PUSH_TOPIC_TYPE from '@/views/constant/push.topic.type.constant'
import _ from 'lodash'
import { Getter, State } from 'vuex-class'
import { EventBus } from '@/utils/eventbus.util'

@Component({
  components: {
    // 3rd Party
    PerfectScrollbar,
  },
})
export default class AppBarNotification extends Vue {
  @Getter('notifications/getAlarmLists') getAlarmLists

  @Watch('getAlarmLists')
  setUserReviewCount() {
    this.setNotificationDate()
  }

  setNotificationDate() {
    this.notifications = _.filter(this.getAlarmLists, (item) => {
      return _.includes(
        PUSH_TOPIC_TYPE.ALARM_ADMIN_NOTIFICATION_LIST(),
        item.type,
      )
    })
  }

  public notifications: any = []

  public extCount: number = 0

  public perfectScrollbarOptions: any = {
    maxScrollbarLength: 60,
    wheelPropagation: false,
  }

  public icons: any = {
    mdiBellOutline,
    mdiChevronRight,
  }

  mounted() {
    console.log('AppBarNotification mounted')
    this.notificationAPI()
  }

  async notificationAPI() {
    try {
      const result = this.$store.dispatch('notifications/setAlarmLists')
      console.log(result)
    } catch (e) {
      console.log(e.response.data.errMsg)
    } finally {
    }
  }

  async actionFunction(notification) {
    try {
      const body = { pushId: notification.pushId.toString() }
      await PushService.readPushLog(body)
      await this.notificationAPI()

      console.log(notification)
      switch (notification.type) {
        case PUSH_TOPIC_TYPE.USER_REQUEST:
          await this.$router.push({
            name: 'memberReg',
          })
          break
        case PUSH_TOPIC_TYPE.USER_REREQUEST:
          await this.$router.push({
            name: 'memberDoc',
          })
          break
        default:
          break
      }
    } catch (e) {
    } finally {
    }
  }

  titleString(type) {
    return PUSH_TOPIC_TYPE.ALARM_LIST_STRING(type)
  }

  accessTimeStr(date) {
    let str: string = ''
    if (DatetimeUtils.getDaysDiffTime(date)) {
      str = date
    } else if (DatetimeUtils.getHoursDiffTime(date)) {
      str = DatetimeUtils.getHoursDiffTime(date) + '시간 전'
    } else if (DatetimeUtils.getMinutesDiffTime(date)) {
      if (parseInt(DatetimeUtils.getMinutesDiffTime(date)) < 10) str = '방금전'
      else str = DatetimeUtils.getMinutesDiffTime(date) + '분 전'
    } else if (DatetimeUtils.getSecondDiffTime(date)) {
      str = '방금전'
    } else {
      str = date
    }
    return str
  }
}
