import _ from 'lodash'

/**
 * 운행 상태 정의
 */
export = (() => {
  const _CALL_STATUS: any = {
    TOTAL : 0,
    ASSIGNABLE: 1,                                  // 의뢰등록 단계
    ASSIGNED: 3,                                    // 운행수주
    STARTED: 5,                                     // 운행시작
    ARRIVED_PICKUP: 21,                             // 상차지 도착
    PICKED_UP: 6,                                   // 상차완료
    ARRIVED_WAYPOINT: 30,                           // 경유지 도착
    ARRIVED_DROPOFF: 22,                            // 하차지 도착
    DROPPED_OFF: 2,                                 // 하차완료
    INVOICE_REGISTERED: 7,                          // 송장등록 후 대기
    LEFT_WAYPOINT: 23,                              // 경유지 상/하차 완료(경유지 범위 이탈)
    PICKUP_RETURNED: 24,                            // 상차지 복귀 완료(왕복인 경우)
    ARRIVED_PICKUP_RETURN: 25,                      // 상차지 복귀 도착(왕복인 경우)

    INVOICE_REJECTED: 17,                           // 송장승인거절
    INVOICE_REREGISTERED: 18,                       // 송장재등록 후 대기

    PAYMENT_WAIT: 32, // 결제대기 (송장승인 후 결제대기)
    PAYMENT_FAILED: 33, // 결제실패 (송장승인 후 결제실패)

    COMPLETED: 8,                                   // 운행완료 (화주의 승인)
    FAILED: 9,                                      // 운행실패 (이거 없어도 되지 않나?)
    HOLDING: 31,                                   // 관리자에 의한 보류중
    PRE_CANCELLED_BY_DRIVER: 4,                     // 차주에 의한 수주취소 (상차요청일시 이전)
    POST_CANCELLED_BY_DRIVER: 10,                   // 차주에 의한 수주취소 (상차요청일시 이후)  -> 상차요청일시 이후 기사가 취소할 수 없는 컨셉이어서 현재는 사용하지 않는다.
    PRE_CANCELLED_BY_CALLER: 11,                    // 화주에 의한 배차취소 (상차요청일시 이전)
    POST_CANCELLED_BY_CALLER: 12,                   // 화주에 의한 배차취소 (상차요청일시 이후)
    PRE_CANCELLED_BY_CONTROLLER_FOR_DRIVER: 13,     // 차주의 요청으로 관제에 의한 운행취소 (상차요청일시 이전)
    POST_CANCELLED_BY_CONTROLLER_FOR_DRIVER: 14,    // 차주의 요청으로 관제에 의한 운행취소 (상차요청일시 이후)
    PRE_CANCELLED_BY_CONTROLLER_FOR_CALLER: 15,     // 화주의 요청으로 관제에 의한 운행취소 (상차요청일시 이전)
    POST_CANCELLED_BY_CONTROLLER_FOR_CALLER: 16,    // 화주의 요청으로 관제에 의한 운행취소 (상차요청일시 이후)

    PRE_CANCELLED_BY_CONTROLLER_FOR_BROKER: 28,   // 주선사의 요청으로 관제에 의한 운행취소 (상차요청일시 이전) (TBD)
    POST_CANCELLED_BY_CONTROLLER_FOR_BROKER: 29,  // 주선사의 요청으로 관제에 의한 운행취소 (상차요청일시 이후) (TBD)
    PRE_CANCELLED_BY_BROKER: 26,                  // 주선사에 의한 배차취소 (상차요청일시 이전) (TBD)
    POST_CANCELLED_BY_BROKER: 27,                 // 주선사에 의한 배차취소 (상차요청일시 이후) (TBD)
    COMPLETED_ARBITRARY_BY_CALLER: 19,              // 화주에 의한 임의 운행완료
    COMPLETED_ARBITRARY_BY_CONTROLLER: 20,          // 관제에 의한 임의 운행완료
  }
  Object.freeze(_CALL_STATUS)

  const _CALL_STATUS_NAME: Map<number, string> = new Map<number, string>([
    [_CALL_STATUS.ASSIGNABLE, `의뢰등록(${_CALL_STATUS.ASSIGNABLE})`],
    [_CALL_STATUS.ASSIGNED, `운행수주(${_CALL_STATUS.ASSIGNED})`],
    [_CALL_STATUS.STARTED, `운행시작(${_CALL_STATUS.STARTED})`],
    [_CALL_STATUS.ARRIVED_PICKUP, `상차지도착(${_CALL_STATUS.ARRIVED_PICKUP})`],
    [_CALL_STATUS.PICKED_UP, `상차완료(${_CALL_STATUS.PICKED_UP})`],
    [_CALL_STATUS.HOLDING, `보류중(${_CALL_STATUS.HOLDING})`],
    [_CALL_STATUS.ARRIVED_WAYPOINT, `경유지도착(${_CALL_STATUS.ARRIVED_WAYPOINT})`],
    [_CALL_STATUS.ARRIVED_DROPOFF, `하차지도착(${_CALL_STATUS.ARRIVED_DROPOFF})`],
    [_CALL_STATUS.DROPPED_OFF, `하차완료(${_CALL_STATUS.DROPPED_OFF})`],
    [_CALL_STATUS.LEFT_WAYPOINT, `경유지상하차완료(${_CALL_STATUS.LEFT_WAYPOINT})`],
    [_CALL_STATUS.PICKUP_RETURNED, `상차지복귀완료(${_CALL_STATUS.PICKUP_RETURNED})`],
    [_CALL_STATUS.ARRIVED_PICKUP_RETURN, `상차지복귀도착(${_CALL_STATUS.ARRIVED_PICKUP_RETURN})`],
    [_CALL_STATUS.INVOICE_REGISTERED, `송장등록(${_CALL_STATUS.INVOICE_REGISTERED})`],
    [_CALL_STATUS.INVOICE_REJECTED, `송장거절(${_CALL_STATUS.INVOICE_REJECTED})`],
    [_CALL_STATUS.INVOICE_REREGISTERED, `송장재등록(${_CALL_STATUS.INVOICE_REREGISTERED})`],
    [_CALL_STATUS.COMPLETED, `운행완료(${_CALL_STATUS.COMPLETED})`],
    [_CALL_STATUS.COMPLETED_ARBITRARY_BY_CALLER, `화주임의운행완료(${_CALL_STATUS.COMPLETED_ARBITRARY_BY_CALLER})`],
    [_CALL_STATUS.COMPLETED_ARBITRARY_BY_CONTROLLER, `관제임의운행완료(${_CALL_STATUS.COMPLETED_ARBITRARY_BY_CONTROLLER})`],
    [_CALL_STATUS.FAILED, `운행실패(${_CALL_STATUS.FAILED})`],
    [_CALL_STATUS.PRE_CANCELLED_BY_DRIVER, `상차시각이전 차주수주취소(${_CALL_STATUS.PRE_CANCELLED_BY_DRIVER})`],
    [_CALL_STATUS.POST_CANCELLED_BY_DRIVER, `상차시각이후 차주수주취소(${_CALL_STATUS.POST_CANCELLED_BY_DRIVER})`],
    [_CALL_STATUS.PRE_CANCELLED_BY_CALLER, `상차시각이전 화주배차취소(${_CALL_STATUS.PRE_CANCELLED_BY_CALLER})`],
    [_CALL_STATUS.POST_CANCELLED_BY_CALLER, `상차시각이후 화주배차취소(${_CALL_STATUS.POST_CANCELLED_BY_CALLER})`],
    [_CALL_STATUS.PRE_CANCELLED_BY_BROKER, `상차시각이전 주선사배차취소(${_CALL_STATUS.PRE_CANCELLED_BY_BROKER})`],
    [_CALL_STATUS.POST_CANCELLED_BY_BROKER, `상차시각이후 주선사배차취소(${_CALL_STATUS.POST_CANCELLED_BY_BROKER})`],
    [_CALL_STATUS.PRE_CANCELLED_BY_CONTROLLER_FOR_DRIVER, `상차시각이전 차주관제운행취소(${_CALL_STATUS.PRE_CANCELLED_BY_CONTROLLER_FOR_DRIVER})`],
    [_CALL_STATUS.POST_CANCELLED_BY_CONTROLLER_FOR_DRIVER, `상차시각이후 차주관제운행취소(${_CALL_STATUS.POST_CANCELLED_BY_CONTROLLER_FOR_DRIVER})`],
    [_CALL_STATUS.PRE_CANCELLED_BY_CONTROLLER_FOR_CALLER, `상차시각이전 화주관제운행취소(${_CALL_STATUS.PRE_CANCELLED_BY_CONTROLLER_FOR_CALLER})`],
    [_CALL_STATUS.POST_CANCELLED_BY_CONTROLLER_FOR_CALLER, `상차시각이후 화주관제운행취소(${_CALL_STATUS.POST_CANCELLED_BY_CONTROLLER_FOR_CALLER})`],
    [_CALL_STATUS.PRE_CANCELLED_BY_CONTROLLER_FOR_BROKER, `상차시각이전 주선사관제운행취소(${_CALL_STATUS.PRE_CANCELLED_BY_CONTROLLER_FOR_BROKER})`],
    [_CALL_STATUS.POST_CANCELLED_BY_CONTROLLER_FOR_BROKER, `상차시각이후 주선사관제운행취소(${_CALL_STATUS.POST_CANCELLED_BY_CONTROLLER_FOR_BROKER})`],
  ]);

  const _CALL_STATUS_WEB_NAME: Map<number, string> = new Map<number, string>([
    [_CALL_STATUS.ASSIGNABLE, "의뢰등록"],
    [_CALL_STATUS.ASSIGNED, "운행수주"],
    [_CALL_STATUS.STARTED, "운행시작"],
    [_CALL_STATUS.ARRIVED_PICKUP, "상차지도착"],
    [_CALL_STATUS.PICKED_UP, "상차완료"],
    [_CALL_STATUS.HOLDING, "보류중"],
    [_CALL_STATUS.ARRIVED_WAYPOINT, "경유지도착"],
    [_CALL_STATUS.ARRIVED_DROPOFF, "하차지도착"],
    [_CALL_STATUS.DROPPED_OFF, "하차완료"],
    [_CALL_STATUS.LEFT_WAYPOINT, "경유지상하차완료"],
    [_CALL_STATUS.PICKUP_RETURNED, "상차지복귀완료"],
    [_CALL_STATUS.ARRIVED_PICKUP_RETURN, "상차지복귀도착"],
    [_CALL_STATUS.INVOICE_REGISTERED, "송장등록"],
    [_CALL_STATUS.INVOICE_REJECTED, "송장거절"],
    [_CALL_STATUS.INVOICE_REREGISTERED, "송장재등록"],
    [_CALL_STATUS.COMPLETED, "운행완료"],
    [_CALL_STATUS.COMPLETED_ARBITRARY_BY_CALLER, "운행완료"],
    [_CALL_STATUS.COMPLETED_ARBITRARY_BY_CONTROLLER, "운행완료"],
    [_CALL_STATUS.FAILED, "운행실패"],
    [_CALL_STATUS.PRE_CANCELLED_BY_DRIVER, "차주수주취소"],
    [_CALL_STATUS.POST_CANCELLED_BY_DRIVER, "차주수주취소"],
    [_CALL_STATUS.PRE_CANCELLED_BY_CALLER, "화주배차취소"],
    [_CALL_STATUS.POST_CANCELLED_BY_CALLER, "화주배차취소"],
    [_CALL_STATUS.PRE_CANCELLED_BY_BROKER, "주선사배차취소"],
    [_CALL_STATUS.POST_CANCELLED_BY_BROKER, "주선사배차취소"],
    [_CALL_STATUS.PRE_CANCELLED_BY_CONTROLLER_FOR_DRIVER, "차주관제운행취소"],
    [_CALL_STATUS.POST_CANCELLED_BY_CONTROLLER_FOR_DRIVER, "차주관제운행취소"],
    [_CALL_STATUS.PRE_CANCELLED_BY_CONTROLLER_FOR_CALLER, "화주관제운행취소"],
    [_CALL_STATUS.POST_CANCELLED_BY_CONTROLLER_FOR_CALLER, "화주관제운행취소"],
    [_CALL_STATUS.PRE_CANCELLED_BY_CONTROLLER_FOR_BROKER, "주선사관제운행취소"],
    [_CALL_STATUS.POST_CANCELLED_BY_CONTROLLER_FOR_BROKER, "주선사관제운행취소"],
  ]);

  const _CALL_STATUS_COLOR_NAME: Map<number, string> = new Map<number, string>([
    [_CALL_STATUS.ASSIGNABLE, "info"],
    [_CALL_STATUS.ASSIGNED, "info"],
    [_CALL_STATUS.STARTED, "primary"],
    [_CALL_STATUS.ARRIVED_PICKUP, "info"],
    [_CALL_STATUS.PICKED_UP, "info"],
    [_CALL_STATUS.ARRIVED_WAYPOINT, "info"],
    [_CALL_STATUS.ARRIVED_DROPOFF, "info"],
    [_CALL_STATUS.DROPPED_OFF, "info"],
    [_CALL_STATUS.LEFT_WAYPOINT, "info"],
    [_CALL_STATUS.PICKUP_RETURNED, "info"],
    [_CALL_STATUS.ARRIVED_PICKUP_RETURN, "info"],
    [_CALL_STATUS.INVOICE_REGISTERED, "info"],
    [_CALL_STATUS.INVOICE_REJECTED, "error"],
    [_CALL_STATUS.INVOICE_REREGISTERED, "warning"],
    [_CALL_STATUS.PAYMENT_WAIT, 'info'],
    [_CALL_STATUS.PAYMENT_FAILED, 'error'],
    [_CALL_STATUS.COMPLETED, "primary"],
    [_CALL_STATUS.COMPLETED_ARBITRARY_BY_CALLER, "primary"],
    [_CALL_STATUS.COMPLETED_ARBITRARY_BY_CONTROLLER, "primary"],
    [_CALL_STATUS.HOLDING, "info"],
    [_CALL_STATUS.FAILED, "error"],
    [_CALL_STATUS.PRE_CANCELLED_BY_DRIVER, "error"],
    [_CALL_STATUS.POST_CANCELLED_BY_DRIVER, "error"],
    [_CALL_STATUS.PRE_CANCELLED_BY_CALLER, "error"],
    [_CALL_STATUS.POST_CANCELLED_BY_CALLER, "error"],
    [_CALL_STATUS.PRE_CANCELLED_BY_BROKER, "error"],
    [_CALL_STATUS.POST_CANCELLED_BY_BROKER, "error"],
    [_CALL_STATUS.PRE_CANCELLED_BY_CONTROLLER_FOR_DRIVER, "error"],
    [_CALL_STATUS.POST_CANCELLED_BY_CONTROLLER_FOR_DRIVER, "error"],
    [_CALL_STATUS.PRE_CANCELLED_BY_CONTROLLER_FOR_CALLER, "error"],
    [_CALL_STATUS.POST_CANCELLED_BY_CONTROLLER_FOR_CALLER, "error"],
    [_CALL_STATUS.PRE_CANCELLED_BY_CONTROLLER_FOR_BROKER, "error"],
    [_CALL_STATUS.POST_CANCELLED_BY_CONTROLLER_FOR_BROKER, "error"],
  ]);

  const _CALL_STATUS_LIST_NAME: Map<number, string> = new Map<number, string>([
    [_CALL_STATUS.TOTAL, '전체'],
    [_CALL_STATUS.ASSIGNABLE, "배차 요청"],
    [_CALL_STATUS.ASSIGNED, "배차 완료"],

    [_CALL_STATUS.STARTED, "운행중"],
    [_CALL_STATUS.ARRIVED_PICKUP, "운행중"],
    [_CALL_STATUS.PICKED_UP, "운행중"],
    [_CALL_STATUS.ARRIVED_WAYPOINT, "운행중"],
    [_CALL_STATUS.ARRIVED_DROPOFF, "운행중"],
    [_CALL_STATUS.DROPPED_OFF, "운행중"],
    [_CALL_STATUS.LEFT_WAYPOINT, "운행중"],
    [_CALL_STATUS.PICKUP_RETURNED, "운행중"],
    [_CALL_STATUS.ARRIVED_PICKUP_RETURN, "운행중"],

    [_CALL_STATUS.INVOICE_REGISTERED, "송장 대기"],
    [_CALL_STATUS.INVOICE_REREGISTERED, "송장 대기"],

    [_CALL_STATUS.INVOICE_REJECTED, "송장 반려"],

    [_CALL_STATUS.COMPLETED, "운행 완료"],
    [_CALL_STATUS.COMPLETED_ARBITRARY_BY_CALLER, "운행 완료"],
    [_CALL_STATUS.COMPLETED_ARBITRARY_BY_CONTROLLER, "운행 완료"],

    [_CALL_STATUS.FAILED, "운행 실패"],

    [_CALL_STATUS.HOLDING, "보류중"],

    [_CALL_STATUS.PRE_CANCELLED_BY_DRIVER, "운행 취소"],
    [_CALL_STATUS.POST_CANCELLED_BY_DRIVER, "운행 취소"],
    [_CALL_STATUS.PRE_CANCELLED_BY_CALLER, "운행 취소"],
    [_CALL_STATUS.POST_CANCELLED_BY_CALLER, "운행 취소"],
    [_CALL_STATUS.PRE_CANCELLED_BY_BROKER, "운행 취소"],
    [_CALL_STATUS.POST_CANCELLED_BY_BROKER, "운행 취소"],
    [_CALL_STATUS.PRE_CANCELLED_BY_CONTROLLER_FOR_DRIVER, "운행 취소"],
    [_CALL_STATUS.POST_CANCELLED_BY_CONTROLLER_FOR_DRIVER, "운행 취소"],
    [_CALL_STATUS.PRE_CANCELLED_BY_CONTROLLER_FOR_CALLER, "운행 취소"],
    [_CALL_STATUS.POST_CANCELLED_BY_CONTROLLER_FOR_CALLER, "운행 취소"],
    [_CALL_STATUS.PRE_CANCELLED_BY_CONTROLLER_FOR_BROKER, "운행 취소"],
    [_CALL_STATUS.POST_CANCELLED_BY_CONTROLLER_FOR_BROKER, "운행 취소"],


  ])

  const _CALL_INVOICE_STATUS: Map<number, string> = new Map<number, string>([
    [_CALL_STATUS.INVOICE_REGISTERED, '송장승인대기'],
    [_CALL_STATUS.INVOICE_REREGISTERED, '송장재요청'],
    [_CALL_STATUS.INVOICE_REJECTED, '송장승인거절'],
    [_CALL_STATUS.COMPLETED, '송장승인완료'],
  ])

  const _CALL_STATUS_DETAIL_NAME: Map<number, string> = new Map<number, string>(
    [
      [_CALL_STATUS.ASSIGNED, '운송 대기 중'],
      [_CALL_STATUS.STARTED, '상차지로 이동 중'],
      [_CALL_STATUS.ARRIVED_PICKUP, '상차지도착'],
      [_CALL_STATUS.PICKED_UP, '하차지로 이동 중'],
      [_CALL_STATUS.INVOICE_REGISTERED, '하차 완료'],
      [_CALL_STATUS.DROPPED_OFF, '상차지로 복귀 중'],
      [_CALL_STATUS.ARRIVED_PICKUP_RETURN, '복귀 완료'],
      [_CALL_STATUS.PICKED_UP, '경유지로 이동 중'],
      [_CALL_STATUS.ARRIVED_WAYPOINT, '경유지 도착'],
      [_CALL_STATUS.LEFT_WAYPOINT, '경유 완료'],
      [_CALL_STATUS.COMPLETED, '운행 완료'],
    ],
  )

  const _DEFAULT_TRANSPORT_STATUS: Map<number, string> = new Map<
    number,
    string
  >([
    [_CALL_STATUS.ASSIGNED, '운송 대기 중'],
    [_CALL_STATUS.STARTED, '상차지로 이동 중'],
    [_CALL_STATUS.ARRIVED_PICKUP, '상차지도착'],
    [_CALL_STATUS.PICKED_UP, '하차지로 이동 중'],
    [_CALL_STATUS.ARRIVED_DROPOFF, '하차지도착'],
    [_CALL_STATUS.DROPPED_OFF, '하차 완료'],
    [_CALL_STATUS.INVOICE_REGISTERED, '하차 완료'],
    [_CALL_STATUS.COMPLETED, '운행 완료'],
  ])

  const _RETURN_TRANSPORT_STATUS: Map<number, string> = new Map<number, string>(
    [
      [_CALL_STATUS.ASSIGNED, '운송 대기 중'],
      [_CALL_STATUS.STARTED, '상차지로 이동 중'],
      [_CALL_STATUS.ARRIVED_PICKUP, '상차지도착'],
      [_CALL_STATUS.PICKED_UP, '하차지로 이동 중'],
      [_CALL_STATUS.ARRIVED_DROPOFF, '하차지도착'],
      [_CALL_STATUS.DROPPED_OFF, '상차지로 복귀 중'],
      [_CALL_STATUS.ARRIVED_PICKUP_RETURN, '복귀 완료'],
      [_CALL_STATUS.INVOICE_REGISTERED, '하차 완료'],
      [_CALL_STATUS.COMPLETED, '운행 완료'],
    ],
  )

  const _TRANSIT_TRANSPORT_STATUS: Map<number, string> = new Map<
    number,
    string
  >([
    [_CALL_STATUS.ASSIGNED, '운송 대기 중'],
    [_CALL_STATUS.STARTED, '상차지로 이동 중'],
    [_CALL_STATUS.ARRIVED_PICKUP, '상차지도착'],
    [_CALL_STATUS.PICKED_UP, '경유지로 이동 중'],
    [_CALL_STATUS.ARRIVED_WAYPOINT, '경유지 도착'],
    [_CALL_STATUS.LEFT_WAYPOINT, '하차지로 이동 중'],
    [_CALL_STATUS.ARRIVED_DROPOFF, '하차지도착'],
    [_CALL_STATUS.DROPPED_OFF, '하차 완료'],
    [_CALL_STATUS.INVOICE_REGISTERED, '하차 완료'],
    [_CALL_STATUS.COMPLETED, '운행 완료'],
  ])

  return Object.freeze({
    TOTAL: _CALL_STATUS.TOTAL,
    ASSIGNABLE: _CALL_STATUS.ASSIGNABLE,
    ASSIGNED: _CALL_STATUS.ASSIGNED,
    STARTED: _CALL_STATUS.STARTED,
    ARRIVED_PICKUP: _CALL_STATUS.ARRIVED_PICKUP,
    PICKED_UP: _CALL_STATUS.PICKED_UP,
    HOLDING: _CALL_STATUS.HOLDING,
    ARRIVED_WAYPOINT: _CALL_STATUS.ARRIVED_WAYPOINT,
    ARRIVED_DROPOFF: _CALL_STATUS.ARRIVED_DROPOFF,
    DROPPED_OFF: _CALL_STATUS.DROPPED_OFF,
    LEFT_WAYPOINT: _CALL_STATUS.LEFT_WAYPOINT,
    PICKUP_RETURNED: _CALL_STATUS.PICKUP_RETURNED,
    ARRIVED_PICKUP_RETURN: _CALL_STATUS.ARRIVED_PICKUP_RETURN,
    INVOICE_REGISTERED: _CALL_STATUS.INVOICE_REGISTERED,
    INVOICE_REJECTED: _CALL_STATUS.INVOICE_REJECTED,
    INVOICE_REREGISTERED: _CALL_STATUS.INVOICE_REREGISTERED,
    PAYMENT_WAIT: _CALL_STATUS.PAYMENT_WAIT,
    PAYMENT_FAILED: _CALL_STATUS.PAYMENT_FAILED,
    COMPLETED: _CALL_STATUS.COMPLETED,
    COMPLETED_ARBITRARY_BY_CALLER: _CALL_STATUS.COMPLETED_ARBITRARY_BY_CALLER,
    COMPLETED_ARBITRARY_BY_CONTROLLER: _CALL_STATUS.COMPLETED_ARBITRARY_BY_CONTROLLER,
    FAILED: _CALL_STATUS.FAILED,
    PRE_CANCELLED_BY_DRIVER: _CALL_STATUS.PRE_CANCELLED_BY_DRIVER,
    POST_CANCELLED_BY_DRIVER: _CALL_STATUS.POST_CANCELLED_BY_DRIVER,
    PRE_CANCELLED_BY_CALLER: _CALL_STATUS.PRE_CANCELLED_BY_CALLER,
    POST_CANCELLED_BY_CALLER: _CALL_STATUS.POST_CANCELLED_BY_CALLER,
    PRE_CANCELLED_BY_BROKER: _CALL_STATUS.PRE_CANCELLED_BY_BROKER,
    POST_CANCELLED_BY_BROKER: _CALL_STATUS.POST_CANCELLED_BY_BROKER,
    PRE_CANCELLED_BY_CONTROLLER_FOR_DRIVER: _CALL_STATUS.PRE_CANCELLED_BY_CONTROLLER_FOR_DRIVER,
    POST_CANCELLED_BY_CONTROLLER_FOR_DRIVER: _CALL_STATUS.POST_CANCELLED_BY_CONTROLLER_FOR_DRIVER,
    PRE_CANCELLED_BY_CONTROLLER_FOR_CALLER: _CALL_STATUS.PRE_CANCELLED_BY_CONTROLLER_FOR_CALLER,
    POST_CANCELLED_BY_CONTROLLER_FOR_CALLER: _CALL_STATUS.POST_CANCELLED_BY_CONTROLLER_FOR_CALLER,
    PRE_CANCELLED_BY_CONTROLLER_FOR_BROKER: _CALL_STATUS.PRE_CANCELLED_BY_CONTROLLER_FOR_BROKER,
    POST_CANCELLED_BY_CONTROLLER_FOR_BROKER: _CALL_STATUS.POST_CANCELLED_BY_CONTROLLER_FOR_BROKER,

    NAME: (callStatus: number): string => {
      const callStatusName: string | undefined =
        _CALL_STATUS_NAME.get(callStatus)
      return callStatusName ? callStatusName : '-'
    },

    NAME_WEB: (callStatus: number): string => {
      const callStatusName: string | undefined =
        _CALL_STATUS_WEB_NAME.get(callStatus)
      return callStatusName ? callStatusName : '-'
    },

    NAME_LIST_WEB: (callStatus: number): string => {
      const callStatusName: string | undefined =
        _CALL_STATUS_LIST_NAME.get(callStatus)
      return callStatusName ? callStatusName : '-'
    },

    NAME_WEB_INVOICE: (callStatus: number): string => {
      const callStatusName: string | undefined =
        _CALL_INVOICE_STATUS.get(callStatus)
      return callStatusName ? callStatusName : '-'
    },

    NAME_COLOR: (callStatus: number): string => {
      const callStatusName: string | undefined =
        _CALL_STATUS_COLOR_NAME.get(callStatus)
      return callStatusName ? callStatusName : 'error'
    },

    NAME_WEB_STATUS_DETAIL: (callStatus: number): string => {
      const detailName: string | undefined =
        _CALL_STATUS_DETAIL_NAME.get(callStatus)
      return detailName ? detailName : '-'
    },

    DEFAULT_TRANSPORT_STATUS: (callStatus: number): string => {
      const detailName: string | undefined =
        _DEFAULT_TRANSPORT_STATUS.get(callStatus)
      return detailName ? detailName : '-'
    },

    RETURN_TRANSPORT_STATUS: (callStatus: number): string => {
      const detailName: string | undefined =
        _RETURN_TRANSPORT_STATUS.get(callStatus)
      return detailName ? detailName : '-'
    },

    TRANSIT_TRANSPORT_STATUS: (callStatus: number): string => {
      const detailName: string | undefined =
        _TRANSIT_TRANSPORT_STATUS.get(callStatus)
      return detailName ? detailName : '-'
    },

    // 전체 운행 상태 목록
    ALL_LIST: (): number[] => [
      _CALL_STATUS.ASSIGNABLE,
      _CALL_STATUS.ASSIGNED,
      _CALL_STATUS.STARTED,
      _CALL_STATUS.ARRIVED_PICKUP,
      _CALL_STATUS.PICKED_UP,
      _CALL_STATUS.HOLDING,
      _CALL_STATUS.ARRIVED_WAYPOINT,
      _CALL_STATUS.ARRIVED_DROPOFF,
      _CALL_STATUS.DROPPED_OFF,
      _CALL_STATUS.LEFT_WAYPOINT,
      _CALL_STATUS.PICKUP_RETURNED,
      _CALL_STATUS.ARRIVED_PICKUP_RETURN,
      _CALL_STATUS.INVOICE_REGISTERED,
      _CALL_STATUS.INVOICE_REJECTED,
      _CALL_STATUS.INVOICE_REREGISTERED,
      _CALL_STATUS.PAYMENT_WAIT,
      _CALL_STATUS.PAYMENT_FAILED,
      _CALL_STATUS.COMPLETED,
      _CALL_STATUS.COMPLETED_ARBITRARY_BY_CALLER,
      _CALL_STATUS.COMPLETED_ARBITRARY_BY_CONTROLLER,
      _CALL_STATUS.FAILED,
      _CALL_STATUS.PRE_CANCELLED_BY_DRIVER,
      _CALL_STATUS.POST_CANCELLED_BY_DRIVER,
      _CALL_STATUS.PRE_CANCELLED_BY_CALLER,
      _CALL_STATUS.POST_CANCELLED_BY_CALLER,
      _CALL_STATUS.PRE_CANCELLED_BY_BROKER,
      _CALL_STATUS.POST_CANCELLED_BY_BROKER,
      _CALL_STATUS.PRE_CANCELLED_BY_CONTROLLER_FOR_DRIVER,
      _CALL_STATUS.POST_CANCELLED_BY_CONTROLLER_FOR_DRIVER,
      _CALL_STATUS.PRE_CANCELLED_BY_CONTROLLER_FOR_CALLER,
      _CALL_STATUS.POST_CANCELLED_BY_CONTROLLER_FOR_CALLER,
      _CALL_STATUS.PRE_CANCELLED_BY_CONTROLLER_FOR_BROKER,
      _CALL_STATUS.POST_CANCELLED_BY_CONTROLLER_FOR_BROKER,
    ],

    // 총 운행 상태 목록 (취소, 실패 제외)
    TOTAL_LIST: (): number[] => [
      _CALL_STATUS.ASSIGNABLE,
      _CALL_STATUS.ASSIGNED,
      _CALL_STATUS.STARTED,
      _CALL_STATUS.ARRIVED_PICKUP,
      _CALL_STATUS.PICKED_UP,
      _CALL_STATUS.HOLDING,
      _CALL_STATUS.ARRIVED_WAYPOINT,
      _CALL_STATUS.ARRIVED_DROPOFF,
      _CALL_STATUS.DROPPED_OFF,
      _CALL_STATUS.LEFT_WAYPOINT,
      _CALL_STATUS.PICKUP_RETURNED,
      _CALL_STATUS.ARRIVED_PICKUP_RETURN,
      _CALL_STATUS.INVOICE_REGISTERED,
      _CALL_STATUS.INVOICE_REJECTED,
      _CALL_STATUS.INVOICE_REREGISTERED,
      _CALL_STATUS.PAYMENT_WAIT,
      _CALL_STATUS.PAYMENT_FAILED,
      _CALL_STATUS.COMPLETED,
      _CALL_STATUS.COMPLETED_ARBITRARY_BY_CALLER,
      _CALL_STATUS.COMPLETED_ARBITRARY_BY_CONTROLLER,
    ],

    // 진행중 운행 상태 목록 (배정 포함)
    ON_GOING_STATUS_LIST: (): number[] => [
      _CALL_STATUS.ASSIGNED,
      _CALL_STATUS.STARTED,
      _CALL_STATUS.ARRIVED_PICKUP,
      _CALL_STATUS.PICKED_UP,
      _CALL_STATUS.ARRIVED_WAYPOINT,
      _CALL_STATUS.ARRIVED_DROPOFF,
      _CALL_STATUS.DROPPED_OFF,
      _CALL_STATUS.LEFT_WAYPOINT,
      _CALL_STATUS.PICKUP_RETURNED,
      _CALL_STATUS.ARRIVED_PICKUP_RETURN,
      _CALL_STATUS.INVOICE_REGISTERED,
      _CALL_STATUS.INVOICE_REJECTED,
      _CALL_STATUS.INVOICE_REREGISTERED,
      _CALL_STATUS.PAYMENT_WAIT,
      _CALL_STATUS.PAYMENT_FAILED,
    ],

    // 수주 혹은 주행중 운행 상태 목록
    ASSIGNED_OR_DRIVING_STATUS_LIST: (): number[] => [
      _CALL_STATUS.ASSIGNED,
      _CALL_STATUS.STARTED,
      _CALL_STATUS.ARRIVED_PICKUP,
      _CALL_STATUS.PICKED_UP,
      _CALL_STATUS.ARRIVED_WAYPOINT,
      _CALL_STATUS.ARRIVED_DROPOFF,
      _CALL_STATUS.DROPPED_OFF,
      _CALL_STATUS.LEFT_WAYPOINT,
      _CALL_STATUS.PICKUP_RETURNED,
      _CALL_STATUS.ARRIVED_PICKUP_RETURN,
    ],

    // 주행중 운행 상태 목록
    DRIVING_STATUS_LIST: (): number[] => [
      _CALL_STATUS.STARTED,
      _CALL_STATUS.ARRIVED_PICKUP,
      _CALL_STATUS.PICKED_UP,
      _CALL_STATUS.ARRIVED_WAYPOINT,
      _CALL_STATUS.ARRIVED_DROPOFF,
      _CALL_STATUS.DROPPED_OFF,
      _CALL_STATUS.LEFT_WAYPOINT,
      _CALL_STATUS.PICKUP_RETURNED,
      _CALL_STATUS.ARRIVED_PICKUP_RETURN,
    ],

    // 종료된 운행 상태 목록
    TERMINATED_STATUS_LIST: (): number[] => [
      _CALL_STATUS.COMPLETED,
      _CALL_STATUS.COMPLETED_ARBITRARY_BY_CALLER,
      _CALL_STATUS.COMPLETED_ARBITRARY_BY_CONTROLLER,
      _CALL_STATUS.FAILED,
      _CALL_STATUS.PRE_CANCELLED_BY_DRIVER,
      _CALL_STATUS.POST_CANCELLED_BY_DRIVER,
      _CALL_STATUS.PRE_CANCELLED_BY_CALLER,
      _CALL_STATUS.POST_CANCELLED_BY_CALLER,
      _CALL_STATUS.PRE_CANCELLED_BY_BROKER,
      _CALL_STATUS.POST_CANCELLED_BY_BROKER,
      _CALL_STATUS.PRE_CANCELLED_BY_CONTROLLER_FOR_DRIVER,
      _CALL_STATUS.POST_CANCELLED_BY_CONTROLLER_FOR_DRIVER,
      _CALL_STATUS.PRE_CANCELLED_BY_CONTROLLER_FOR_CALLER,
      _CALL_STATUS.POST_CANCELLED_BY_CONTROLLER_FOR_CALLER,
      _CALL_STATUS.PRE_CANCELLED_BY_CONTROLLER_FOR_BROKER,
      _CALL_STATUS.POST_CANCELLED_BY_CONTROLLER_FOR_BROKER,
      _CALL_STATUS.HOLDING,
    ],

    // 유효한 운행 상태 목록 (모든 상태에서 INACTIVE_STATUS_LIST를 제외한 상태 목록)
    ACTIVE_STATUS_LIST: (): number[] => [
      _CALL_STATUS.ASSIGNABLE,
      _CALL_STATUS.ASSIGNED,
      _CALL_STATUS.STARTED,
      _CALL_STATUS.ARRIVED_PICKUP,
      _CALL_STATUS.PICKED_UP,
      _CALL_STATUS.ARRIVED_WAYPOINT,
      _CALL_STATUS.ARRIVED_DROPOFF,
      _CALL_STATUS.DROPPED_OFF,
      _CALL_STATUS.LEFT_WAYPOINT,
      _CALL_STATUS.PICKUP_RETURNED,
      _CALL_STATUS.ARRIVED_PICKUP_RETURN,
      _CALL_STATUS.INVOICE_REGISTERED,
      _CALL_STATUS.INVOICE_REJECTED,
      _CALL_STATUS.INVOICE_REREGISTERED,
      _CALL_STATUS.PAYMENT_WAIT,
      _CALL_STATUS.PAYMENT_FAILED,
      _CALL_STATUS.COMPLETED,
      _CALL_STATUS.COMPLETED_ARBITRARY_BY_CALLER,
      _CALL_STATUS.COMPLETED_ARBITRARY_BY_CONTROLLER,
      _CALL_STATUS.FAILED,
    ],

    // 유효하지 않은 운행 상태 목록
    INACTIVE_STATUS_LIST: (): number[] => [
      _CALL_STATUS.HOLDING,
      _CALL_STATUS.PRE_CANCELLED_BY_DRIVER,
      _CALL_STATUS.POST_CANCELLED_BY_DRIVER,
      _CALL_STATUS.PRE_CANCELLED_BY_CALLER,
      _CALL_STATUS.POST_CANCELLED_BY_CALLER,
      _CALL_STATUS.PRE_CANCELLED_BY_BROKER,
      _CALL_STATUS.POST_CANCELLED_BY_BROKER,
      _CALL_STATUS.PRE_CANCELLED_BY_CONTROLLER_FOR_DRIVER,
      _CALL_STATUS.POST_CANCELLED_BY_CONTROLLER_FOR_DRIVER,
      _CALL_STATUS.PRE_CANCELLED_BY_CONTROLLER_FOR_CALLER,
      _CALL_STATUS.POST_CANCELLED_BY_CONTROLLER_FOR_CALLER,
      _CALL_STATUS.PRE_CANCELLED_BY_CONTROLLER_FOR_BROKER,
      _CALL_STATUS.POST_CANCELLED_BY_CONTROLLER_FOR_BROKER,
    ],

    // 취소된 운행 상태 목록
    CANCELLED_STATUS_LIST: (): number[] => [
      _CALL_STATUS.PRE_CANCELLED_BY_DRIVER,
      _CALL_STATUS.POST_CANCELLED_BY_DRIVER,
      _CALL_STATUS.PRE_CANCELLED_BY_CALLER,
      _CALL_STATUS.POST_CANCELLED_BY_CALLER,
      _CALL_STATUS.PRE_CANCELLED_BY_BROKER,
      _CALL_STATUS.POST_CANCELLED_BY_BROKER,
      _CALL_STATUS.PRE_CANCELLED_BY_CONTROLLER_FOR_DRIVER,
      _CALL_STATUS.POST_CANCELLED_BY_CONTROLLER_FOR_DRIVER,
      _CALL_STATUS.PRE_CANCELLED_BY_CONTROLLER_FOR_CALLER,
      _CALL_STATUS.POST_CANCELLED_BY_CONTROLLER_FOR_CALLER,
      _CALL_STATUS.PRE_CANCELLED_BY_CONTROLLER_FOR_BROKER,
      _CALL_STATUS.POST_CANCELLED_BY_CONTROLLER_FOR_BROKER,
    ],

    // 완료된 운행 상태 목록
    COMPLETED_STATUS_LIST: (): number[] => [
      _CALL_STATUS.COMPLETED,
      _CALL_STATUS.COMPLETED_ARBITRARY_BY_CALLER,
      _CALL_STATUS.COMPLETED_ARBITRARY_BY_CONTROLLER,
    ],

    // 정산,미정산에 포함할 의뢰 상태 목록
    ANY_PAYOUT_STATUS_LIST: (): number[] => [
      _CALL_STATUS.ASSIGNABLE,
      _CALL_STATUS.ASSIGNED,
      _CALL_STATUS.STARTED,
      _CALL_STATUS.ARRIVED_PICKUP,
      _CALL_STATUS.PICKED_UP,
      _CALL_STATUS.ARRIVED_WAYPOINT,
      _CALL_STATUS.ARRIVED_DROPOFF,
      _CALL_STATUS.DROPPED_OFF,
      _CALL_STATUS.INVOICE_REGISTERED,
      _CALL_STATUS.LEFT_WAYPOINT,
      _CALL_STATUS.PICKUP_RETURNED,
      _CALL_STATUS.ARRIVED_PICKUP_RETURN,
      _CALL_STATUS.INVOICE_REJECTED,
      _CALL_STATUS.INVOICE_REREGISTERED,
      _CALL_STATUS.PAYMENT_WAIT,
      _CALL_STATUS.PAYMENT_FAILED,
      _CALL_STATUS.COMPLETED,
      _CALL_STATUS.COMPLETED_ARBITRARY_BY_CALLER,
      _CALL_STATUS.COMPLETED_ARBITRARY_BY_CONTROLLER,
    ],

    // 송장 전체 목록
    INVOICE_ALL_STATUS: (): number[] => [
      _CALL_STATUS.INVOICE_REGISTERED,
      _CALL_STATUS.INVOICE_REJECTED,
      _CALL_STATUS.INVOICE_REREGISTERED,
    ],

    // 송장 대기중인 운행 상태 목록
    INVOICE_WAITING_STATUS_LIST: (): number[] => [
      _CALL_STATUS.INVOICE_REGISTERED,
      _CALL_STATUS.INVOICE_REREGISTERED,
    ],

    // 차주가 한 의뢰를 다시 수주할 수 없는 그 기사의 이전 운행 상태 목록
    // Phase 1에서는 한 의뢰를 한 기사가 중복 수주할 수 없었으나,
    // Phase 1.5에서 이전 운행 송장 업로드하면 그 의뢰의 다른 운행도 수주 가능하도록 변경
    DRIVER_CALL_REQUEST_UNASSIGNABLE_STATUS_LIST: (): number[] => [
      _CALL_STATUS.ASSIGNED,
      _CALL_STATUS.STARTED,
      _CALL_STATUS.ARRIVED_PICKUP,
      _CALL_STATUS.PICKED_UP,
      _CALL_STATUS.ARRIVED_WAYPOINT,
      _CALL_STATUS.ARRIVED_DROPOFF,
      _CALL_STATUS.DROPPED_OFF,
      _CALL_STATUS.LEFT_WAYPOINT,
      _CALL_STATUS.PICKUP_RETURNED,
      _CALL_STATUS.ARRIVED_PICKUP_RETURN,
      // _CALL_STATUS.INVOICE_REGISTERED,
      // _CALL_STATUS.INVOICE_REJECTED,
      // _CALL_STATUS.INVOICE_REREGISTERED,
      // _CALL_STATUS.COMPLETED,
      // _CALL_STATUS.COMPLETED_ARBITRARY_BY_CALLER,
      // _CALL_STATUS.COMPLETED_ARBITRARY_BY_CONTROLLER,
      // _CALL_STATUS.FAILED,
    ],

    // 차주가 수주취소한 운행 상태 목록
    DRIVER_CANCELLED_STATUS_LIST: (): number[] => [
      _CALL_STATUS.PRE_CANCELLED_BY_DRIVER,
      _CALL_STATUS.POST_CANCELLED_BY_DRIVER,
      _CALL_STATUS.PRE_CANCELLED_BY_CONTROLLER_FOR_DRIVER,
      _CALL_STATUS.POST_CANCELLED_BY_CONTROLLER_FOR_DRIVER,
    ],

    // 화주가 배차취소한 운행 상태 목록
    CALLER_CANCELLED_STATUS_LIST: (): number[] => [
      _CALL_STATUS.PRE_CANCELLED_BY_CALLER,
      _CALL_STATUS.POST_CANCELLED_BY_CALLER,
      _CALL_STATUS.PRE_CANCELLED_BY_CONTROLLER_FOR_CALLER,
      _CALL_STATUS.POST_CANCELLED_BY_CONTROLLER_FOR_CALLER,
    ],

    // 화주가 배차취소한 페널티 적용 운행 상태 목록
    CALLER_CANCELLED_PENALTY_STATUS_LIST: (): number[] => [
      _CALL_STATUS.PRE_CANCELLED_BY_CALLER,
      _CALL_STATUS.PRE_CANCELLED_BY_CONTROLLER_FOR_CALLER,
    ],

    // 주선사이 배차취소한 운행 상태 목록
    BROKER_CANCELLED_STATUS_LIST: (): number[] => [
      _CALL_STATUS.PRE_CANCELLED_BY_BROKER,
      _CALL_STATUS.POST_CANCELLED_BY_BROKER,
      _CALL_STATUS.PRE_CANCELLED_BY_CONTROLLER_FOR_BROKER,
      _CALL_STATUS.POST_CANCELLED_BY_CONTROLLER_FOR_BROKER,
    ],

    // 관제가 운행취소한 운행 상태 목록
    CONTROLLER_CANCELLED_STATUS_LIST: (): number[] => [
      _CALL_STATUS.PRE_CANCELLED_BY_CONTROLLER_FOR_DRIVER,
      _CALL_STATUS.POST_CANCELLED_BY_CONTROLLER_FOR_DRIVER,
      _CALL_STATUS.PRE_CANCELLED_BY_CONTROLLER_FOR_CALLER,
      _CALL_STATUS.POST_CANCELLED_BY_CONTROLLER_FOR_CALLER,
      _CALL_STATUS.PRE_CANCELLED_BY_CONTROLLER_FOR_BROKER,
      _CALL_STATUS.POST_CANCELLED_BY_CONTROLLER_FOR_BROKER,
    ],

    // 차주가 수주취소할 수 있는 운행 상태 목록
    DRIVER_CANCELABLE_STATUS_LIST: (): number[] => [
      _CALL_STATUS.ASSIGNED,
      _CALL_STATUS.STARTED,
      _CALL_STATUS.ARRIVED_PICKUP,
    ],

    // 화주가 배차취소할 수 있는 운행 상태 목록
    CALLER_CANCELABLE_STATUS_LIST: (): number[] => [
      _CALL_STATUS.ASSIGNED,
    ],

    // 주선사이 배차취소할 수 있는 운행 상태 목록
    BROKER_CANCELABLE_STATUS_LIST: (): number[] => [
      _CALL_STATUS.ASSIGNED,
    ],

    // 관제가 운행취소할 수 있는 운행 상태 목록
    CONTROLLER_CANCELABLE_STATUS_LIST: (): number[] => [
      _CALL_STATUS.ASSIGNED,
      _CALL_STATUS.STARTED,
      _CALL_STATUS.ARRIVED_PICKUP,
      _CALL_STATUS.PICKED_UP,
      _CALL_STATUS.ARRIVED_WAYPOINT,
      _CALL_STATUS.ARRIVED_DROPOFF,
      _CALL_STATUS.DROPPED_OFF,
      _CALL_STATUS.INVOICE_REGISTERED,
      _CALL_STATUS.INVOICE_REJECTED,
      _CALL_STATUS.INVOICE_REREGISTERED,
      _CALL_STATUS.HOLDING,
    ],

    // 정산 대상이 되는 운행 상태 목록
    PAYOUT_TARGET_STATUS_LIST: (): number[] => [
      _CALL_STATUS.ASSIGNED,
      _CALL_STATUS.STARTED,
      _CALL_STATUS.ARRIVED_PICKUP,
      _CALL_STATUS.PICKED_UP,
      _CALL_STATUS.ARRIVED_WAYPOINT,
      _CALL_STATUS.ARRIVED_DROPOFF,
      _CALL_STATUS.DROPPED_OFF,
      _CALL_STATUS.INVOICE_REGISTERED,
      _CALL_STATUS.INVOICE_REJECTED,
      _CALL_STATUS.INVOICE_REREGISTERED,
      _CALL_STATUS.PAYMENT_WAIT,
      _CALL_STATUS.PAYMENT_FAILED,
      _CALL_STATUS.COMPLETED,
      _CALL_STATUS.COMPLETED_ARBITRARY_BY_CALLER,
      _CALL_STATUS.COMPLETED_ARBITRARY_BY_CONTROLLER,
    ],

    // 미정산 대상이 되는 운행 상태 목록
    INCOMPLETED_PAYOUT_LIST: (): number[] => [
      _CALL_STATUS.ASSIGNED,
      _CALL_STATUS.STARTED,
      _CALL_STATUS.ARRIVED_PICKUP,
      _CALL_STATUS.PICKED_UP,
      _CALL_STATUS.ARRIVED_WAYPOINT,
      _CALL_STATUS.ARRIVED_DROPOFF,
      _CALL_STATUS.DROPPED_OFF,
      _CALL_STATUS.INVOICE_REGISTERED,
      _CALL_STATUS.INVOICE_REJECTED,
      _CALL_STATUS.INVOICE_REREGISTERED,
      _CALL_STATUS.PAYMENT_WAIT,
      _CALL_STATUS.PAYMENT_FAILED,
      _CALL_STATUS.LEFT_WAYPOINT,                 // 경유지 상/하차 완료(경유지 범위 이탈)
      _CALL_STATUS.PICKUP_RETURNED,               // 상차지 복귀 완료(왕복인 경우)
      _CALL_STATUS.ARRIVED_PICKUP_RETURN,         // 상차지 복귀 도착(왕복인 경우)
    ],

    // 관제웹에서 운행 예정 status 사용함
    WEB_ASSIGNED_STATUS_LIST: (): number[] => [
      _CALL_STATUS.ASSIGNED,
    ],

    // 관제웹에서 하차 완료 status 사용함
    WEB_DROPPED_OFF_STATUS_LIST: (): number[] => [
      _CALL_STATUS.DROPPED_OFF,
    ],

    // 관제웹에서 운행 완료 status 사용함
    WEB_COMPLETED_STATUS_LIST: (): number[] => [
      _CALL_STATUS.COMPLETED,
      _CALL_STATUS.COMPLETED_ARBITRARY_BY_CALLER,
      _CALL_STATUS.COMPLETED_ARBITRARY_BY_CONTROLLER,
    ],

    // 관제웹 진행중 운행 상태 목록 (배정 삭제)
    WEB_ON_GOING_STATUS_LIST: (): number[] => [
      _CALL_STATUS.ASSIGNABLE,
      _CALL_STATUS.STARTED,
      _CALL_STATUS.ARRIVED_PICKUP,
      _CALL_STATUS.PICKED_UP,
      _CALL_STATUS.ARRIVED_WAYPOINT,
      _CALL_STATUS.ARRIVED_DROPOFF,
      _CALL_STATUS.DROPPED_OFF,
      _CALL_STATUS.LEFT_WAYPOINT,
      _CALL_STATUS.PICKUP_RETURNED,
      _CALL_STATUS.ARRIVED_PICKUP_RETURN,
      _CALL_STATUS.INVOICE_REGISTERED,
      _CALL_STATUS.INVOICE_REJECTED,
      _CALL_STATUS.INVOICE_REREGISTERED,
      _CALL_STATUS.PAYMENT_WAIT,
      _CALL_STATUS.PAYMENT_FAILED,
    ],

    // 관제웹 송장 대기중인 운행 상태 목록(관제웹에서는 재요청도 함께 count 사용하기 위해서 추가함.)
    WEB_INVOICE_WAITING_STATUS_LIST: (): number[] => [
      _CALL_STATUS.INVOICE_REGISTERED,
      _CALL_STATUS.INVOICE_REREGISTERED,
    ],

    WEB_ON_GOING_ROUTE_STATUS_LIST: (): number[] => [
      _CALL_STATUS.STARTED,
      _CALL_STATUS.ARRIVED_PICKUP,
      _CALL_STATUS.PICKED_UP,
      _CALL_STATUS.ARRIVED_WAYPOINT,
      _CALL_STATUS.ARRIVED_DROPOFF,
      _CALL_STATUS.DROPPED_OFF,
      _CALL_STATUS.LEFT_WAYPOINT,
      _CALL_STATUS.PICKUP_RETURNED,
      _CALL_STATUS.ARRIVED_PICKUP_RETURN,
    ],

    // 관제웹 기사 실시간 위치 pickup 이전까지
    WEB_PICKUP_BEFORE_ROUTE_STATUS_LIST: (): number[] => [
      _CALL_STATUS.STARTED,
      _CALL_STATUS.ARRIVED_PICKUP,
    ],

    // 관제웹 기사 실시간 위치 pickup 이후까지
    WEB_PICKUP_AFTER_ROUTE_STATUS_LIST: (): number[] => [
      _CALL_STATUS.PICKED_UP,
      _CALL_STATUS.ARRIVED_WAYPOINT,
      _CALL_STATUS.ARRIVED_DROPOFF,
      _CALL_STATUS.DROPPED_OFF,
      _CALL_STATUS.LEFT_WAYPOINT,
      _CALL_STATUS.PICKUP_RETURNED,
      _CALL_STATUS.ARRIVED_PICKUP_RETURN,
    ],

    // 차주가 수주취소한 운행 상태 목록
    WEB_DRIVER_CANCELLED_STATUS_LIST: (): number[] => [
      _CALL_STATUS.PRE_CANCELLED_BY_DRIVER,
      _CALL_STATUS.POST_CANCELLED_BY_DRIVER,
    ],

    // 관제웹에서 차주 수주취소한 운행 상태 목록
    WEB_DRIVER_CANCELLED_CONTROLLER_STATUS_LIST: (): number[] => [
      _CALL_STATUS.PRE_CANCELLED_BY_CONTROLLER_FOR_DRIVER,
      _CALL_STATUS.POST_CANCELLED_BY_CONTROLLER_FOR_DRIVER,
    ],

    // 화주가 배차취소한 운행 상태 목록
    WEB_CALLER_CANCELLED_STATUS_LIST: (): number[] => [
      _CALL_STATUS.PRE_CANCELLED_BY_CALLER,
      _CALL_STATUS.POST_CANCELLED_BY_CALLER,
    ],

    // 관제웹에서 화주 배차취소한 운행 상태 목록
    WEB_CALLER_CANCELLED_CONTROLLER_STATUS_LIST: (): number[] => [
      _CALL_STATUS.PRE_CANCELLED_BY_CONTROLLER_FOR_CALLER,
      _CALL_STATUS.POST_CANCELLED_BY_CONTROLLER_FOR_CALLER,
    ],

    makeCallStatusNameCaseSql: (): string => {
      let sql: string = `(CASE callStatus `
      for (const entry of _CALL_STATUS_NAME) {
        sql += `WHEN ${entry[0]} THEN '${entry[1]}' `
      }
      sql += `ELSE '알 수 없는' END)`
      return sql
    },

    makeEachCallStatusCountSql: (): string => {
      return _.chain(_.values(_CALL_STATUS))
        .map((callStatus: number): string => {
          return `IFNULL(SUM(IF(ci.call_status = ${callStatus}, 1, 0)), 0) AS '${_CALL_STATUS_NAME.get(
            callStatus,
          )}'`
        })
        .join(',\n')
        .value()
    },

    makeEachCallStatusSparseCountSql: (
      statusList: number[] | undefined,
      tableName?: string,
    ): string => {
      const callStatusFieldName: string = `${
        !_.isEmpty(tableName) ? tableName + '.' : ''
      }call_status`

      if (_.isNil(statusList)) {
        return _.chain(_.values(_CALL_STATUS))
          .map((callStatus: number): string => {
            return `count(case when ${callStatusFieldName} = ${callStatus} then 1 end) as '${callStatus}'`
            // return `count(if(${callStatusFieldName} = ${callStatus}, 1, null)) as '${callStatus}'`;
          })
          .join(',\n')
          .value()
      } else if (_.isArray(statusList) && !_.isEmpty(statusList)) {
        return _.chain(statusList)
          .map((callStatus: number): string => {
            return `count(case when ${callStatusFieldName} = ${callStatus} then 1 end) as '${callStatus}'`
            // return `count(if(${callStatusFieldName} = ${callStatus}, 1, null)) as '${callStatus}'`;
          })
          .join(',\n')
          .value()
      } else {
        return '' // error
      }
    },

    /**
     * @deprecated
     * @param queryString
     */
    getCallStatusListFromQueryString: (queryString: string): any => {
      if (_.isNil(queryString)) {
        return queryString
      } else {
        const allStatusList: number[] = _.values(_CALL_STATUS)
        const filteredStatusList: number[] = _.chain(queryString)
          .split(',')
          .map(_.trim.bind(_))
          .map(_.toNumber.bind(_))
          .filter((callStatus: number): boolean => {
            return _.includes(allStatusList, callStatus)
          })
          .value()

        return filteredStatusList
      }
    },
  })
})()
