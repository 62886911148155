


































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { mdiClose } from '@mdi/js'

@Component({
  name: 'FreightStatusChangePopup',
  components: {},

  mounted() {},
})
export default class FreightStatusChangePopup extends Vue {
  @Prop(Boolean) value
  @Prop(String) id

  icons = { mdiClose }

  status = null
  statusItems = [
    { text: '완료 처리', value: 1 },
    { text: '취소 처리', value: 2 },
    { text: '보류 처리', value: 3 },
  ]

  perfectScrollbarOptions: any = {
    maxScrollbarLength: 30,
    wheelPropagation: false,
  }

  // async changeStatus() {
  //   try {
  //     console.log('changeStatus')
  //     const params = {
  //       state: this.status,
  //     }

  //     const data = await callRequestService.changeStatus(this.id, params)
  //     console.log('data', data)

  //     return data
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  async onClickConfirm() {
    console.log('onClickConfirm')

    // await this.changeStatus()

    const target = this.statusItems.find((item) => item.value === this.status)

    // this.$toast(`${status} 되었습니다. 주선사/차주에게 결과가 전송되었습니다.`)

    this.$emit('change', target)
    this.status = null
  }

  onClickClose() {
    this.$emit('close')
    this.status = null
  }
}
