import axios from 'axios'

class ConfigService {
  private apiUrl: string
  constructor() {
    this.apiUrl = '/userconfig'
  }

  // 사용자 config 전체 가지고 오기
  postServerConfig() {
    return axios.post(this.apiUrl + '/getconfig').then((res) => res.data)
  }

  // 서버 공사중 조회
  getServerInspection() {
    return axios.get(this.apiUrl + `/server-available`).then((res) => res.data)
  }
}

export default new ConfigService()
