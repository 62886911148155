
























































































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
// import DatePicker from '@/views/utils/DatePicker.vue'
import Vue2DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ko'
import DatetimeUtils from '@/utils/datetime.utils'
import Pagination from '@/views/utils/Pagination.vue'
import {
  mdiMagnify,
  mdiChevronDown,
  mdiClose,
  mdiCircleOutline,
  mdiCircleSlice8,
} from '@mdi/js'
import { State } from 'vuex-class'
import gradeService from '@/services-itgo/grade.service'
import USER_STATUS from '@/views/constant/user.status'
import VEHICLE_TYPE from '@/views/constant/vehicle.type.constant'
import TON_SELECT from '@/views/constant/freight.weight.type.constant'
import PAYMENT_METHOD from '@/views/constant/freight.payment.type.constant'
import miscService from '@/services-itgo/misc.service'
import FreightUsePopup from '@/views/statistics/FreightUsePopup.vue'
import callRequestService from '@/services-itgo/call.request.service'
import _ from 'lodash'
import Xlsx from '@/utils/xlsx'

type dateType = 'day' | 'month' | 'year'

@Component({
  components: {
    Vue2DatePicker,
    Pagination,
    FreightUsePopup,
  },

  mounted() {
    this.dayDate = this.baseDate
    this.selectDateType.dayType = true
    this.fetchUsageList()
  },
})
export default class FreightUse extends Vue {
  @State((state) => state.auth.user) loginUser

  icons: any = {
    mdiMagnify,
    mdiChevronDown,
    mdiClose,
    mdiCircleOutline,
    mdiCircleSlice8,
  }

  fromRequestDt = null
  toRequestDt = null

  type = null
  typeItems = [
    { text: '전체', value: null },
    { text: VEHICLE_TYPE.CARGO_CAR_STRING, value: VEHICLE_TYPE.CARGO_CAR },
    { text: VEHICLE_TYPE.TOP_CAR_STRING, value: VEHICLE_TYPE.TOP_CAR },
    { text: VEHICLE_TYPE.WING_CAR_STRING, value: VEHICLE_TYPE.WING_CAR },
    { text: VEHICLE_TYPE.TRAILER_CAR_STRING, value: VEHICLE_TYPE.TRAILER_CAR },
    { text: VEHICLE_TYPE.HORU_CAR_STRING, value: VEHICLE_TYPE.HORU_CAR },
    {
      text: VEHICLE_TYPE.BANGTONG_CAR_STRING,
      value: VEHICLE_TYPE.BANGTONG_CAR,
    },
  ]

  formatDateString = 'YYYY-MM-DD'

  dayDate : any = [new Date(), new Date()]
  monthDate : any = DatetimeUtils.getMonth(-1)
  yearDate : any = DatetimeUtils.getYear(-1)

  get baseDate() {
    const today = new Date()
    const beforeWeek = new Date(today)
    beforeWeek.setDate(today.getDate())
    return [beforeWeek, today]
  }

  @Watch('dayDate')
  changeDates(value) {
    if (value?.length > 1) {
      this.set(value)
    }
  }

  previousDayDate: Date[] | null = null

  async set(value) {
    const temp = [...this.dayDate]
    temp.sort((a, b) => new Date(a).getTime() - new Date(b).getTime())

    if (temp.length === 2) {
      if (DatetimeUtils.asDays(temp[0], temp[1]) >= 30) {
        this.dayDate = this.previousDayDate || this.baseDate
        await this.$alert({
          title: '기간 설정 오류',
          content: '기간 설정은 최대 30일까지 가능합니다.',
        })
      } else {
        this.previousDayDate = [...this.dayDate]
      }
    }
  }

  ton = null
  tonItems = [
    { text: '전체', value: null },
    ...TON_SELECT.TON_TOTAL_LIST.map((item) => ({
      text: item.name,
      value: item.value,
    })),
  ]

  selectDateType = {
    dayType: false,
    monthType: false,
    yearType: false,
  }
  setDateType: dateType = 'day'

  selectRegion = ['전체']

  emd = null
  emdItems = [{ text: '전체', value: null }]

  distance = null
  distanceItems = [
    { text: '전체', value: null },
    { text: '10km', value: 10000 },
    { text: '30km', value: 30000 },
    { text: '50km', value: 50000 },
    { text: '100km', value: 100000 },
    { text: '200km', value: 200000 },
    { text: '500km', value: 500000 },
  ]

  payment = null
  paymentItems = [
    {
      text: PAYMENT_METHOD.TOTAL_STRING,
      value: PAYMENT_METHOD.TOTAL,
    },
    // {
    //   text: PAYMENT_METHOD.PREPAYMENT_STRING,
    //   value: PAYMENT_METHOD.PREPAYMENT,
    // },
    // {
    //   text: PAYMENT_METHOD.DEFERRED_STRING,
    //   value: PAYMENT_METHOD.DEFERRED,
    // },
    {
      text: PAYMENT_METHOD.INVOICE_STRING,
      value: PAYMENT_METHOD.INVOICE,
    },
    {
      text: PAYMENT_METHOD.CARD_STRING,
      value: PAYMENT_METHOD.CARD,
    },
    {
      text: PAYMENT_METHOD.VIRTUAL_ACCOUNT_STRING,
      value: PAYMENT_METHOD.VIRTUAL_ACCOUNT,
    },
  ]

  keyword = ''

  usageList = {
    loading: false,
    headers: [
      { text: '날짜', value: 'requestDt', align: 'center', width: '15%' },
      {
        text: '주선사명',
        value: 'brokerCompanyName',
        align: 'center',
        width: '12%',
      },
      {
        text: '차량 종류',
        value: 'driverVehicleType',
        align: 'center',
        width: '12%',
      },
      {
        text: '차량 톤수',
        value: 'driverVehicleWeight',
        align: 'center',
        width: '10%',
      },
      { text: '지역', value: 'adminAddress', align: 'center', width: '20%' },
      {
        text: '거리',
        value: 'expectedDistance',
        align: 'center',
        width: '10%',
      },
      {
        text: '결제 방식',
        value: 'paymentMethod',
        align: 'center',
        width: '10%',
      },
      { text: 'PV', value: 'pageView', align: 'center', width: '12%' },
    ],
    items: [],
    serverItemsLength: 0,
  }

  public excelParams: any = null

  get totalPageCount() {
    const length = Math.ceil(this.usageList.serverItemsLength / this.pageSize)
    console.log(length)
    return length === 0 ? 1 : length
  }

  pageSizes = [10, 20, 30]
  pageSize = 10
  pageNum = 1

  /**
   * pageSize: 페이지 크기
   * pageNum: 페이지 번호
   */
  @Watch('pageSize')
  onChangePageSize() {
    this.pageNum = 1
    this.fetchUsageList()
  }

  @Watch('pageNum')
  onChangePageNum() {
    this.fetchUsageList()
  }

  async fetchUsageList() {
    if (
      (this.selectDateType.dayType && !this.dayDate[0] && !this.dayDate[1]) ||
      (this.selectDateType.monthType && !this.monthDate) ||
      (this.selectDateType.yearType && !this.yearDate)
    ) {
      await this.$alert({
        title: '기간 설정 오류',
        content: '검색 기간을 설정해 주세요.',
      })

      if(this.selectDateType.dayType && !this.dayDate[0] && !this.dayDate[1])
        this.dayDate = [new Date(), new Date()]
      else if(this.selectDateType.monthType && !this.monthDate)
        this.monthDate = DatetimeUtils.getMonth(-1)
      else
        this.yearDate = DatetimeUtils.getYear(-1)

      return
    }

    try {
      let adminAreaList: string = ''
      if (this.selectRegion[0] !== '전체') {
        adminAreaList = _.map(this.selectRegion, (region) =>
          region.includes('전체')
            ? region.replace(' 전체', '')
            : region.replace(' ', ','),
        ).join(';')
      }

      this.setInputDate()

      const params = {
        driverVehicleType: this.type,
        driverVehicleWeight: this.ton,
        expectedDistance: this.distance,
        paymentMethod: this.payment,
        adminAreaList: adminAreaList,
        fromRequestDt: this.fromRequestDt,
        toRequestDt: this.toRequestDt,
        keyword: this.keyword,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      }

      console.log(params)

      const data = await callRequestService.getCallRequestUsage(params)

      //data 만들어서 사용함.
      this.usageList.items = data.content.map((item, index) => ({
        ...item,
        requestDt: DatetimeUtils.stringDate(item.requestDt, 'YYYY-MM-DD'),
        driverVehicleType: VEHICLE_TYPE.VEHICLE_NAME_WEB(
          item.driverVehicleType,
        ),
        driverVehicleWeight: item.driverVehicleWeight / 1000,
        expectedDistance: item.expectedDistance / 1000,
        paymentMethod: PAYMENT_METHOD.PAYMENT_NAME_WEB(item.paymentMethod),
      }))

      this.usageList.serverItemsLength = data.totalElementCount
      //excelDownload 위한 params 기능 추가함.
      this.excelParams = params
    } catch (error: any) {
      this.$alert(error.response.data.errMsg)
    } finally {
      //      this.usageList.loading = false
    }
  }

  onSearch() {
    this.pageNum = 1
    this.fetchUsageList()
  }

  async onClickDownloadExcel() {
    try {
      console.log('onClickDownloadExcel')

      let dateExcel = ''
      if (this.selectDateType.dayType) {
        dateExcel = `${DatetimeUtils.stringDate(
          this.dayDate[0],
          'YYYY-MM-DD',
        )}~${DatetimeUtils.stringDate(this.dayDate[1], 'YYYY-MM-DD')}`
      } else if (this.selectDateType.monthType) {
        dateExcel = DatetimeUtils.stringDate(this.monthDate, 'YYYY-MM')
      } else if (this.selectDateType.yearType) {
        dateExcel = DatetimeUtils.stringDate(this.yearDate, 'YYYY')
      }

      const nameExcel = `화물조건별이용_${dateExcel}.xlsx`

      const data = await callRequestService.getCallRequestUsageExcel(
        this.excelParams,
      )
      console.log(data)
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      const link = document.createElement('a')
      const blobUrl = URL.createObjectURL(blob)
      link.href = blobUrl
      link.download = nameExcel
      link.click()
      URL.revokeObjectURL(blobUrl)
    } catch (e) {
      await this.$alert({
        title: '엑셀 생성 실패',
        content: e.response.data.errMsg,
      })
    } finally {
    }
  }

  dateTypeSelect(type) {
    this.setDateType = type
    this.selectDateType.dayType = false
    this.selectDateType.monthType = false
    this.selectDateType.yearType = false

    switch (type) {
      case 'day':
        if(this.monthDate === null)
          this.monthDate = DatetimeUtils.getMonth(-1)
        if(this.yearDate === null)
          this.yearDate = DatetimeUtils.getYear(-1)
        this.selectDateType.dayType = true
        break
      case 'month':
        if(this.dayDate[0] === null && this.dayDate[1] === null){
          this.dayDate = [new Date(), new Date()]
        }
        if(this.yearDate === null)
          this.yearDate = DatetimeUtils.getYear(-1)
        this.selectDateType.monthType = true
        break
      case 'year':
        if(this.dayDate[0] === null && this.dayDate[1] === null){
          this.dayDate = [new Date(), new Date()]
        }
        if(this.monthDate === null)
          this.monthDate = DatetimeUtils.getMonth(-1)
        this.selectDateType.yearType = true
        break
      default:
        break
    }
  }

  onRemoveResion(index: number) {
    this.selectRegion.splice(index, 1)
  }

  openFreightUsePopup = false
  onClickSetRegion() {
    this.openFreightUsePopup = true
  }

  onCloseSetRegion(item) {
    this.selectRegion = item
    this.openFreightUsePopup = false
  }

  setInputDate() {
    if (this.selectDateType.dayType) {
      this.fromRequestDt = DatetimeUtils.stringDate(
        this.dayDate[0],
        'YYYY-MM-DD',
      )
      this.toRequestDt = DatetimeUtils.stringDate(
        DatetimeUtils.plusDays(this.dayDate[1], 1),
        'YYYY-MM-DD',
      )
    } else if (this.selectDateType.monthType) {
      this.fromRequestDt = DatetimeUtils.setTypeStartDay(
        this.monthDate,
        'month',
      )
      this.toRequestDt = DatetimeUtils.setTypeEndDay(this.monthDate, 'month')
    } else {
      this.fromRequestDt = DatetimeUtils.setTypeStartDay(this.yearDate, 'year')
      this.toRequestDt = DatetimeUtils.setTypeEndDay(this.yearDate, 'year')
    }
  }
}
