<template>
  <svg
    id="그룹_41842"
    data-name="그룹 41842"
    xmlns="http://www.w3.org/2000/svg"
    width="70"
    height="70"
    viewBox="0 0 70 70"
  >
    <rect
      id="guide"
      width="60"
      height="44"
      transform="translate(5 13)"
      fill="none"
    />
    <rect
      id="사각형_31691"
      data-name="사각형 31691"
      width="70"
      height="70"
      fill="none"
    />
    <g id="ic_noorder" transform="translate(8.389 16.996)">
      <path
        id="패스_27333"
        data-name="패스 27333"
        d="M34.6,6.024v18.87H3.145A3.145,3.145,0,0,0,0,28.039v6.29a3.146,3.146,0,0,0,3.145,3.146H6.29V34.329H3.145v-6.29H34.6v6.29H22.015v3.146H37.741V21.749H53.466V37.475a3.146,3.146,0,0,0,3.145-3.146V18.6L47.176,6.024ZM52.68,18.6,45.6,9.169H37.741V18.6Z"
        transform="translate(0 -1.25)"
        fill="#bdbdbd"
        fill-rule="evenodd"
      />
      <line
        id="선_907"
        data-name="선 907"
        y1="23.004"
        transform="translate(1.794 -1.5)"
        fill="none"
        stroke="#bdbdbd"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <line
        id="선_908"
        data-name="선 908"
        x2="28"
        transform="translate(1.611 -0.496)"
        fill="none"
        stroke="#bdbdbd"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <line
        id="선_909"
        data-name="선 909"
        y2="22.996"
        transform="translate(30.248 -1.496)"
        fill="none"
        stroke="#bdbdbd"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <path
        id="빼기_1"
        data-name="빼기 1"
        d="M37.22,17.5h0l-18.211,0A7,7,0,1,0,5.115,16.275a7.077,7.077,0,0,0,.106,1.219H1.956A2.031,2.031,0,0,1,0,15.4V7.793A2.031,2.031,0,0,1,1.956,5.7H34.211L34.35,0H52.943V16.061a1.436,1.436,0,0,1-1.434,1.434h-.5a7,7,0,1,0-13.791,0Z"
        transform="translate(1.886 18.73)"
        fill="#bdbdbd"
      />
      <g
        id="타원_2566"
        data-name="타원 2566"
        transform="translate(9.001 30.004)"
        fill="none"
        stroke="#bdbdbd"
        stroke-width="2"
      >
        <circle cx="5" cy="5" r="5" stroke="none" />
        <circle cx="5" cy="5" r="4" fill="none" />
      </g>
      <g
        id="타원_2571"
        data-name="타원 2571"
        transform="translate(41.001 30.004)"
        fill="none"
        stroke="#bdbdbd"
        stroke-width="2"
      >
        <circle cx="5" cy="5" r="5" stroke="none" />
        <circle cx="5" cy="5" r="4" fill="none" />
      </g>
    </g>
    <circle
      id="타원_2685"
      data-name="타원 2685"
      cx="1.5"
      cy="1.5"
      r="1.5"
      transform="translate(17 28)"
      fill="#bdbdbd"
    />
    <circle
      id="타원_2686"
      data-name="타원 2686"
      cx="1.5"
      cy="1.5"
      r="1.5"
      transform="translate(23 28)"
      fill="#bdbdbd"
    />
    <circle
      id="타원_2687"
      data-name="타원 2687"
      cx="1.5"
      cy="1.5"
      r="1.5"
      transform="translate(29 28)"
      fill="#bdbdbd"
    />
  </svg>
</template>

<script>
export default {
  name: 'truck_70X70_cancel',

  props: {
    viewBox: { type: String, default: '0 0 70 70' },
    iconName: { type: String, default: '' },
    width: { type: [Number, String], default: 70 },
    height: { type: [Number, String], default: 70 },
    iconColor: { type: String, default: '#ffffff' },
  },
}
</script>

<style scoped></style>
