var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: _vm.width,
        height: _vm.height,
        viewBox: _vm.viewBox,
      },
    },
    [
      _c(
        "g",
        { attrs: { id: "ic_document", transform: "translate(-0.93 -1.991)" } },
        [
          _c("rect", {
            attrs: {
              id: "사각형_7",
              "data-name": "사각형 7",
              width: "28",
              height: "28",
              transform: "translate(0.93 1.991)",
              fill: "none",
            },
          }),
          _c("path", {
            attrs: {
              id: "card-account-details-outline_1_",
              "data-name": "card-account-details-outline (1)",
              d: "M21.437,3H1.949A2,2,0,0,0,0,4.9V18.228a2,2,0,0,0,1.949,1.9H21.437a2,2,0,0,0,1.949-1.9V4.9A2,2,0,0,0,21.437,3m0,15.228H1.949V4.9H21.437V18.228m-7.8-1.9v-1.19c0-1.58-3.254-2.379-4.872-2.379s-4.872.8-4.872,2.379v1.19h9.744M8.77,6.807a2.38,2.38,0,1,0,2.436,2.379A2.408,2.408,0,0,0,8.77,6.807m4.467-.023v1.79h6.4V6.784h-6.4m.093,3.321v1.46h4.629v-1.46",
              transform: "translate(3.238 4.425)",
              fill: _vm.iconColor,
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }