













































































































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { mdiChevronLeft } from '@mdi/js'

import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import FreightInfoItem from '@/views/freightHistory/FreightInfoItem.vue'
import Freight from '@/views/icons/leftPanel/freight.vue'
import FreightInfoTab from '@/views/freightHistory/FreightInfoTab.vue'
import FreightStatusChangePopup from '@/views/freightHistory/FreightStatusChangePopup.vue'
import {
  addCommas,
  formatBizno,
  formatDate,
  formatPhoneNumber,
} from '@/utils/business.util'
import CALL_REQUEST_STATUS from '@/views/constant/call.request.status'
import CALL_STATUS from '@/views/constant/call.status'
import CARGO_TYPE from '@/views/constant/cargo.type.constant'
import CARGO_CATEGORY from '@/views/constant/cargo.category.constant'
import CARGO_LOADING_TYPE from '@/views/constant/cargo.loading.type.constant'
import VEHICLE_TYPE from '@/views/constant/vehicle.type.constant'
import VEHICLE_OPTION_TYPE from '@/views/constant/vehicle.option.constant'
import FREIGHT_METHOD_TYPE from '@/views/constant/freight.method.type.constant'
import FREIGHT_DATE_TYPE from '@/views/constant/freight.date.type.constant'
import FreightHistoryPopup from '@/views/freightHistory/FreightHistoryPopup.vue'
import callRequestService from '@/services-itgo/call.request.service'
import callHistoryervice from '@/services-itgo/call.history.service'
import PAYMENT_METHOD from '@/views/constant/freight.payment.type.constant'
import { fcm } from '@/utils/fcm.util'
import _ from 'lodash'

@Component({
  name: 'FreightInfo',
  components: {
    FreightInfoTab,
    Freight,
    PerfectScrollbar,
    FreightInfoItem,
    FreightHistoryPopup,
    FreightStatusChangePopup,
  },

  created() {
    // console.log('=================created=================')
    // fcm.$on('fcm-topic-received', this.getFcmMessage)
  },

  async mounted() {
    const params = this.$route.params
    if (!this.id) return this.$router.push({ name: 'freightHistory' })
    if (params.history === 'management')
      this.history = { path: params.path, item: params.item }

    await this.fetchCallDetail()
    // await this.fetchCallHistory()

    // console.log('mounted=================')
    // fcm.$on('fcm-topic-received 222', (data) => {
    //   console.log('getFcmMessage 222', data)
    // })
  },
})
export default class FreightInfo extends Vue {
  icons: any = {
    mdiChevronLeft,
  }

  history: any = {}
  callDetail: any = {}

  get callRequestCode() {
    return this.callDetail?.callRequestCode
  }

  get pickUpInfo() {
    return (
      this.callDetail?.callDestinations?.find(
        (item) => item.type === 'PICKUP',
      ) || {}
    )
  }

  get dropOffInfo() {
    return (
      this.callDetail?.callDestinations?.find(
        (item) => item.type === 'DROPOFF',
      ) || {}
    )
  }

  get waypointInfo() {
    return (
      this.callDetail?.callDestinations?.find(
        (item) => item.type === 'WAYPOINT',
      ) || {}
    )
  }

  get callInfo() {
    return this.callDetail?.callInfos?.[0] || {}
  }

  get orderInfo() {
    return this.callDetail?.orderInfos?.[0] || {}
  }

  get info() {
    return [
      {
        title: '상/하차 정보',
        data: [
          {
            title: '상차지',
            content: this.getAddressString(this.pickUpInfo),
            show: true,
          },
          {
            title: '담당자 정보',
            content: formatPhoneNumber(this.pickUpInfo?.phone),
            show: true,
          },
          {
            title: this.callDetail?.callDestinations?.find(
              (item) => item.numOfOrder === 4,
            )
              ? '경유지(상차)'
              : '경유지(하차)',
            content: this.getAddressString(this.waypointInfo),
            show: this.callDetail?.callDestinations?.find(
              (item) => item.numOfOrder === 4 || item.numOfOrder === 5,
            ),
          },
          {
            title: '담당자 정보',
            content: formatPhoneNumber(this.waypointInfo?.phone),
            show: this.callDetail?.callDestinations?.find(
              (item) => item.numOfOrder === 4 || item.numOfOrder === 5,
            ),
          },
          {
            title: '하차지',
            content: this.getAddressString(this.dropOffInfo),
            show: true,
          },
          {
            title: '담당자 정보',
            content: formatPhoneNumber(this.dropOffInfo?.phone),
            show: true,
          },
        ],
      },
      {
        title: '상/하차 일시·방법',
        data: [
          {
            title: '상차 일시',
            content: `${FREIGHT_DATE_TYPE.FREIGHT_PICKUP_DATE_NAME_WEB(
              this.callInfo?.dateFilterPickup,
            )}, ${formatDate(this.callInfo?.pickupRequestDt)}`,
            show: true,
          },
          {
            title: '상차 방법',
            content: FREIGHT_METHOD_TYPE.METHOD_TOTAL_NAME_WEB(
              this.callInfo?.pickupMethod,
            ),
            show: true,
          },
          {
            title: '경유 일시',
            content: `${FREIGHT_DATE_TYPE.FREIGHT_PICKUP_DATE_NAME_WEB(
              this.callInfo?.dateFilterPickup,
            )}, ${formatDate(this.callInfo?.waypointRequestDt)}`,
            show: this.callDetail?.callDestinations?.find(
              (item) => item.numOfOrder === 4 || item.numOfOrder === 5,
            ),
          },
          {
            title: '경유 방법',
            content: FREIGHT_METHOD_TYPE.METHOD_TOTAL_NAME_WEB(
              this.callInfo?.dropoffMethod,
            ),
            show: this.callDetail?.callDestinations?.find(
              (item) => item.numOfOrder === 4 || item.numOfOrder === 5,
            ),
          },
          {
            title: '하차 일시',
            content: `${FREIGHT_DATE_TYPE.FREIGHT_DROPOFF_DATE_NAME_WEB(
              this.callInfo?.dateFilterPickup,
            )}, ${formatDate(this.callInfo?.dropoffArrivalDt)}`,
            show: true,
          },
          {
            title: '하차 방법',
            content: FREIGHT_METHOD_TYPE.METHOD_TOTAL_NAME_WEB(
              this.callInfo?.dropoffMethod,
            ),
            show: true,
          },
        ],
      },
      {
        title: '화물 정보',
        data: [
          {
            title: '적재 방법',
            content: CARGO_LOADING_TYPE.CARGO_LOADING_TYPE_NAME_WEB(
              this.orderInfo?.cargoLoadingType,
            ),
            show: true,
          },
          {
            title: '화물 중량',
            content: this.orderInfo?.cargoWeight
              ? `${this.orderInfo?.cargoWeight / 1000}톤`
              : '-',
            show: true,
          },
          {
            title: '화물 타입',
            content: CARGO_TYPE.CARGO_TYPE_NAME_WEB(this.orderInfo?.cargoType),
            show: true,
          },
          {
            title: '화물 구분',
            content: CARGO_CATEGORY.CARGO_CATEGORY_NAME_WEB(
              this.orderInfo?.cargoCategory,
            ),
            show: true,
          },
          {
            title: '화물 길이',
            content: this.orderInfo?.cargoLength
              ? `${this.orderInfo?.cargoLength / 100}m`
              : '-',
            show: true,
          },
          {
            title: '화물 부피',
            content: `${this.orderInfo?.cargoVolume / 1000000 || 0}m³ (가로 ${
              this.orderInfo?.cargoSizeW || 0
            }cm x 세로 ${this.orderInfo?.cargoSizeD || 0}cm x 높이 ${
              this.orderInfo?.cargoSizeH || 0
            }cm)`,
            show: true,
          },
          {
            title: '박스 개수',
            content: !this.orderInfo?.cargoBoxYesno
              ? '0개'
              : `${this.orderInfo?.cargoBoxCount}개`,
            show: true,
          },
          {
            title: '화물 내용',
            content: this.orderInfo?.cargoName || '-',
            show: true,
          },
        ],
      },
      {
        title: '차량 정보',
        data: [
          {
            title: '차량 종류',
            content: VEHICLE_TYPE.VEHICLE_NAME_WEB(
              this.orderInfo?.driverVehicleType,
            ),
            show: true,
          },
          {
            title: '차량 톤수',
            content: `${this.orderInfo?.driverVehicleWeight / 1000}톤`,
            show: true,
          },
          {
            title: '차량 옵션',
            content: VEHICLE_OPTION_TYPE.VEHICLE_OPTION_NAME_WEB(
              this.orderInfo?.driverVehicleOption,
            ),
            show: true,
          },
          {
            title: '차량 온도',
            content: this.orderInfo?.driverVehicleOptionTemp
              ? VEHICLE_OPTION_TYPE.VEHICLE_OPTION_TEMP_NAME_WEB(
                  this.orderInfo.driverVehicleOptionTemp,
                )
              : '-',
            show: true,
          },
        ],
      },
      {
        title: '화주 정보',
        data: [
          {
            title: '화주 정보',
            content: `${
              this.callDetail?.callerGroup?.callerName || ''
            } / ${formatBizno(this.callDetail?.callerGroup?.businessRegNum)}`,
            show: true,
          },
        ],
      },
      {
        title: '결제 정보',
        data: [
          {
            title: '결제 방식',
            content: PAYMENT_METHOD.PAYMENT_NAME_WEB(
              this.callDetail?.paymentMethod,
            ),
            show: true,
          },
          {
            title: '운송료 (VAT별도)',
            content: this.callDetail?.additionalCharge
              ? `${addCommas(
                  this.callDetail?.totalFreightCharge -
                    this.callDetail?.additionalCharge,
                )}원`
              : `${addCommas(this.callDetail?.totalFreightCharge)}원`,
            show: true,
          },
          // {
          //   title: '수수료',
          //   content: this.callDetail?.totalFreightFee
          //     ? `${addCommas(this.callDetail?.totalFreightFee)}원`
          //     : '-',
          //   show: true,
          // },
          {
            title: '합계 (VAT별도)',
            content: this.callDetail?.finalPaymentAmount
              ? `${addCommas(this.callDetail?.totalFreightCharge)}원`
              : '-',
            show: true,
          },
        ],
      },
      {
        title: '추천 차주 정보',
        data: this.callDetail.suggestionDriver?.length
          ? this.callDetail.suggestionDriver.map((item, index) => ({
              title: index === 0 ? '추천 차주' : '',
              content: `${item.userName}${
                item.mobile ? `, ${item.mobile}` : ''
              }`,
              show: true,
            }))
          : [
              {
                title: '추천 차주',
                content: '-',
                show: true,
              },
            ],
      },
      {
        title: '추가 운송료 정보',
        data: [
          {
            title: '운송료',
            content: this.callDetail?.additionalCharge
              ? `${addCommas(this.callDetail?.additionalCharge)}원`
              : '-',
            show: true,
          },
          {
            title: '내용',
            content: this.callDetail?.additionalChargeReason || '-',
            show: true,
          },
        ],
      },
    ]
  }

  async fetchCallDetail() {
    try {
      const data = await callRequestService.getCallDetail(this.id)

      this.callDetail = data
    } catch (error: any) {
      this.$alert(error.response.data.errMsg)
    }
  }

  get id() {
    return String(this.$route.params.id)
  }

  /**
   * * 비공개 상태인 경우 > 모두 비활성화
   * * 취소 상태인 경우 > 모두 비활성화
   *    - CANCELLED_BY_CALLER : 7
   *    - CANCELLED_BY_PICKUP_SCHEDULE : 9
   *    - CANCELLED_BY_BROKER : 11
   *    - DELETED_BY_CONTROLLER : 22
   */

  get disabledCanceldFreight(): boolean {
    return (
      this.callDetail?.callRequestStatus ===
        CALL_REQUEST_STATUS.CANCELLED_BY_CALLER ||
      this.callDetail?.callRequestStatus ===
        CALL_REQUEST_STATUS.CANCELLED_BY_PICKUP_SCHEDULE ||
      this.callDetail?.callRequestStatus ===
        CALL_REQUEST_STATUS.CANCELLED_BY_BROKER ||
      this.callDetail?.callRequestStatus ===
        CALL_REQUEST_STATUS.CANCELLED_BY_CONTROLLER_FOR_CALLER ||
      this.callDetail?.callRequestStatus ===
        CALL_REQUEST_STATUS.CANCELLED_BY_CONTROLLER_FOR_BROKER ||
      this.callDetail?.callRequestStatus ===
        CALL_REQUEST_STATUS.DELETED_BY_CONTROLLER ||
      this.callInfo.callStatus === CALL_STATUS.COMPLETED ||
      this.callInfo.callStatus === CALL_STATUS.COMPLETED_ARBITRARY_BY_CALLER ||
      this.callInfo.callStatus === CALL_STATUS.COMPLETED_ARBITRARY_BY_CONTROLLER
    )
  }

  get paymentComplete() {
    return Boolean(this.callDetail?.callInfos?.[0].settleChargeIssueDt) // 결제 완료일 경우 취소 버튼 비활성화
  }

  get isHolding() {
    return this.callDetail?.callRequestStatus === CALL_REQUEST_STATUS.HOLDING
  }

  // get isDisabled() {

  //   return this.callDetail?.callRequestStatus === CALL_REQUEST_STATUS.HOLDING ||
  //   this.callDetail?.callRequestStatus === CALL_REQUEST_STATUS.ASSIGNABLE ||
  //   this.callDetail?.callRequestStatus === CALL_REQUEST_STATUS.ASSIGN_REQUEST ||
  //   this.callDetail?.callRequestStatus === CALL_REQUEST_STATUS.COMPLETED
  // }

  perfectScrollbarOptions: any = {
    maxScrollbarLength: 60,
    wheelPropagation: false,
  }

  async changeStatus(value) {
    try {
      const params = {
        state: value,
      }

      const data = await callRequestService.changeStatus(this.id, params)

      this.$toast({
        content:
          value === 1
            ? `완료 처리 되었습니다. 주선사/차주에게 결과가 전송되었습니다.`
            : value === 2
            ? `취소 처리 되었습니다. 주선사/차주에게 결과가 전송되었습니다.`
            : `보류 처리 되었습니다. 주선사/차주에게 결과가 전송되었습니다.`,
      })
      return data
    } catch (error: any) {
      this.$alert(error.response.data.errMsg)
    }
  }

  async onClickCompleteFreight() {
    try {
      await this.changeStatus(1)
      this.onRefresh()
    } catch (err: any) {
      console.log(err.response.data.errMsg)
    }
  }

  async onClickCancelFreight() {
    try {
      await this.changeStatus(2)
      this.onRefresh()
    } catch (err: any) {
      console.log(err.response.data.errMsg)
    }
  }

  openChangeStatusPopup = false

  async onClickChangeStatus() {
    this.openChangeStatusPopup = true
  }

  async onChangeStatus(item) {
    await this.changeStatus(item.value)

    this.openChangeStatusPopup = false

    this.onRefresh()
  }

  onCloseChangeStatus() {
    this.openChangeStatusPopup = false
  }

  onRefresh() {
    this.fetchCallDetail()
  }

  openFreightHistoryPopup = false

  async onClickFreightHistory() {
    this.openFreightHistoryPopup = true
  }

  onCloseFreightHistory() {
    this.openFreightHistoryPopup = false
  }

  onClickBack() {
    return this.$route.params.history === 'management'
      ? this.$router.push({
          name: this.history.path,
          params: { item: this.history.item, backPath: 'freightInfo' },
        })
      : this.$router.push({ name: 'freightHistory' })
  }

  getAddressString(addressInfo) {
    if (
      addressInfo.addressDetail === 'null' ||
      _.isNil(addressInfo.addressDetail)
    ) {
      return addressInfo.address
    } else return addressInfo.address + ' ' + addressInfo.addressDetail
  }
}
