<template>
  <svg
    id="그룹_42916"
    data-name="그룹 42916"
    xmlns="http://www.w3.org/2000/svg"
    width="70"
    height="70"
    viewBox="0 0 70 70"
  >
    <rect
      id="guide"
      width="60"
      height="44"
      transform="translate(5 13)"
      fill="none"
    />
    <rect
      id="사각형_31691"
      data-name="사각형 31691"
      width="70"
      height="70"
      fill="none"
    />
    <g id="ic_noorder" transform="translate(8.389 16.996)">
      <path
        id="패스_27333"
        data-name="패스 27333"
        d="M34.6,6.024v18.87H3.145A3.145,3.145,0,0,0,0,28.039v6.29a3.146,3.146,0,0,0,3.145,3.146H6.29V34.329H3.145v-6.29H34.6v6.29H22.015v3.146H37.741V21.749H53.466V37.475a3.146,3.146,0,0,0,3.145-3.146V18.6L47.176,6.024ZM52.68,18.6,45.6,9.169H37.741V18.6Z"
        transform="translate(0 -1.25)"
        fill="#bdbdbd"
        fill-rule="evenodd"
      />
      <path
        id="빼기_1"
        data-name="빼기 1"
        d="M37.22,17.5h0l-18.211,0A7,7,0,1,0,5.115,16.275a7.077,7.077,0,0,0,.106,1.219H1.956A2.031,2.031,0,0,1,0,15.4V7.793A2.031,2.031,0,0,1,1.956,5.7H34.211L34.35,0H52.943V16.061a1.436,1.436,0,0,1-1.434,1.434h-.5a7,7,0,1,0-13.791,0Z"
        transform="translate(1.886 18.73)"
        fill="#bdbdbd"
      />
      <g
        id="타원_2566"
        data-name="타원 2566"
        transform="translate(9.001 30.004)"
        fill="none"
        stroke="#bdbdbd"
        stroke-width="2"
      >
        <circle cx="5" cy="5" r="5" stroke="none" />
        <circle cx="5" cy="5" r="4" fill="none" />
      </g>
      <g
        id="타원_2571"
        data-name="타원 2571"
        transform="translate(41.001 30.004)"
        fill="none"
        stroke="#bdbdbd"
        stroke-width="2"
      >
        <circle cx="5" cy="5" r="5" stroke="none" />
        <circle cx="5" cy="5" r="4" fill="none" />
      </g>
      <g
        id="그룹_41844"
        data-name="그룹 41844"
        transform="translate(-1506.68 -237.496)"
      >
        <line
          id="선_1489"
          data-name="선 1489"
          y1="23.004"
          transform="translate(1508.749 235.496)"
          fill="none"
          stroke="#bdbdbd"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <line
          id="선_1490"
          data-name="선 1490"
          x2="28"
          transform="translate(1508.566 236.5)"
          fill="none"
          stroke="#bdbdbd"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <line
          id="선_1491"
          data-name="선 1491"
          y2="22.996"
          transform="translate(1537.203 235.5)"
          fill="none"
          stroke="#bdbdbd"
          stroke-linejoin="round"
          stroke-width="2"
        />
      </g>
    </g>
    <g id="bt_check" transform="translate(17 20)">
      <g id="ic_check_act" transform="translate(-4 -4)">
        <rect
          id="guide-2"
          data-name="guide"
          width="16"
          height="16"
          transform="translate(20 4) rotate(90)"
          fill="none"
        />
        <path
          id="ic_check"
          d="M2,9.768l4.963,5.276L13.788,6"
          transform="translate(4.106 0.978)"
          fill="none"
          stroke="#bdbdbd"
          stroke-width="2"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'truck_70X70_cancel',

  props: {
    viewBox: { type: String, default: '0 0 70 70' },
    iconName: { type: String, default: '' },
    width: { type: [Number, String], default: 70 },
    height: { type: [Number, String], default: 70 },
    iconColor: { type: String, default: '#ffffff' },
  },
}
</script>

<style scoped></style>
