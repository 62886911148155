import PushService from '@/services-itgo/push.service'
import UserService from "@/services-itgo/user.service";

/**
 * 공지를 위한 모듈
 */
export const notifications = {
  namespaced: true,
  state: {
    userReviewCount : {
      "waiting": 0,
      "failed": 0,
      "reRequest": 0,
      "approved": 2,
      "total": 2
    },
    alarmLists: [],
  },
  actions: {
    setUserReviewCount({ commit }) {
      return UserService.getUserReviewCount().then((result) => {
        commit('setUserReviewCount', result)
        return Promise.resolve()
      })
    },
    setAlarmLists({ commit }) {
      return PushService.getPushLogList().then((result) => {
        console.log(result)
        commit('setAlarmLists', result)
      })
    },
  },

  mutations: {
    /**
     * 사용자의 가입심사 갯수 store 저장 시킴
     */
    setUserReviewCount(state, result) {
      state.userReviewCount = result
    },

    setAlarmLists(state, alarmLists) {
      state.alarmLists = alarmLists.map((item) => ({
        pushId: item.pushId,
        type: item.type,
        createdDt: item.createdDt,
      }))
    },
  },

  getters: {
    getUserReviewCount(state) {
      return state.userReviewCount
    },
    getAlarmLists(state) {
      return state.alarmLists
    },
  },
}
