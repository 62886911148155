import Vue from 'vue'
import App from './App.vue'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import router from './router'
import { i18n } from '@/plugins/i18n'
import '@/plugins/vue-composition-api'

import '@/@core/preset/preset/roof/roof.scss'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import vuetify from './plugins/vuetify'
import {
  formatBizno,
  formatPhoneNumber,
  addCommas,
  formatDate,
  maskingName,
} from './utils/business.util'
import { Popup, Toast } from './store/popup.module'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import AuthService from '@/services-itgo/auth.service'

// process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueAxios, axios)
Vue.use(Viewer)

Vue.filter('formatBizno', formatBizno)
Vue.filter('formatPhoneNumber', formatPhoneNumber)
Vue.filter('addCommas', addCommas)
Vue.filter('formatDate', formatDate)
Vue.filter('maskingName', maskingName)

Vue.config.productionTip = false

function resetLogoutTimer() {
  // console.log('mousemove 이벤트 발생 - 타이머 재설정')
  AuthService.startAutoLogoutListenerScheduler()
}

function resetTimer() {
  // console.log('mousemove 이벤트 발생 - 타이머 재설정')
  AuthService.resetTimer()
}

window.addEventListener('mousemove', resetLogoutTimer)
window.addEventListener('scroll', resetLogoutTimer)
window.addEventListener('keydown', resetLogoutTimer)
window.addEventListener('mousedown', resetLogoutTimer)

Vue.prototype.$alert = function (popup: string | Popup) {
  return store.dispatch('popup/alert', popup)
}

Vue.prototype.$confirm = function (popup: string | Popup) {
  return store.dispatch('popup/confirm', popup)
}

Vue.prototype.$toast = function (toast: string | Toast) {
  return store.dispatch('popup/toast', toast)
}

console.log(`process.env.NODE_ENV : ${process.env.NODE_ENV}`)
console.log(`process.env.VUE_HOST_NAME : ${process.env.VUE_APP_HOST_NAME}`)

// API 서버 주소
axios.defaults.baseURL = `${process.env.VUE_APP_REST_API_HOST}:${process.env.VUE_APP_REST_API_PORT}/api`
axios.defaults.timeout = 30000
console.log(
  `process.env.VUE_APP_REST_API_HOST : ${process.env.VUE_APP_REST_API_HOST}:${process.env.VUE_APP_REST_API_PORT}/api`,
)

Vue.config.productionTip = false

const AmoaWebApp: any = new Vue({
  store,
  router,
  i18n,
  vuetify,
  render: (h) => h(App),
  beforeDestroy() {
    window.removeEventListener('mousemove', resetTimer)
    window.removeEventListener('scroll', resetTimer)
    window.removeEventListener('keydown', resetTimer)
    window.removeEventListener('mousedown', resetTimer)
  },
}).$mount('#app')

;(window as any).AmoaWebApp = AmoaWebApp
