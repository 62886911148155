<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="viewBox"
  >
    <g id="ic_grup" transform="translate(398 -261)">
      <g id="people" transform="translate(-395.99 262.626)">
        <path id="패스_29459" data-name="패스 29459" d="M13.124,12.273a4.187,4.187,0,1,0,0-8.172,5.264,5.264,0,0,1,.917.946,3.14,3.14,0,0,1,0,6.28A5.267,5.267,0,0,1,13.124,12.273Z" transform="translate(0.566)" :fill="iconColor" :stroke="iconColor" stroke-width="0.5"/>
        <path id="패스_29460" data-name="패스 29460" d="M17.063,13.249a21.6,21.6,0,0,1,3.365.823,2.725,2.725,0,0,1,1.8,2.652v2.545a1.047,1.047,0,0,1-1.047,1.047H19.09V19.269h2.093V16.723A1.68,1.68,0,0,0,20.1,15.065c-.393-.131-.845-.267-1.347-.4A2.82,2.82,0,0,0,17.213,13.3Z" transform="translate(0.75 0.432)" :fill="iconColor" :stroke="iconColor" stroke-width="0.5"/>
        <path id="패스_29461" data-name="패스 29461" d="M10.187,11.327a3.14,3.14,0,1,0-3.14-3.14A3.14,3.14,0,0,0,10.187,11.327Zm4.187-3.14A4.187,4.187,0,1,1,10.187,4,4.187,4.187,0,0,1,14.374,8.187Z" transform="translate(-0.813)" :fill="iconColor" :stroke="iconColor" stroke-width="0.5" fill-rule="evenodd"/>
        <path id="패스_29462" data-name="패스 29462" d="M17.7,19.28V16.735a1.68,1.68,0,0,0-1.087-1.658,20.086,20.086,0,0,0-6.24-1.03,20.086,20.086,0,0,0-6.24,1.03,1.68,1.68,0,0,0-1.087,1.658V19.28Zm-13.9-5.2A2.725,2.725,0,0,0,2,16.735V19.28a1.047,1.047,0,0,0,1.047,1.047H17.7a1.047,1.047,0,0,0,1.047-1.047V16.735a2.725,2.725,0,0,0-1.8-2.652A21.131,21.131,0,0,0,10.374,13,21.132,21.132,0,0,0,3.8,14.083Z" transform="translate(-1 0.42)" :fill="iconColor" :stroke="iconColor" stroke-width="0.5" fill-rule="evenodd"/>
      </g>
      <rect id="사각형_31279" data-name="사각형 31279" width="28" height="28" transform="translate(-398 261)" fill="none"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'grup',

  props: {
    viewBox: { type: String, default: '0 0 28 28' },
    iconName: { type: String, default: '' },
    width: { type: [Number, String], default: 28 },
    height: { type: [Number, String], default: 28 },
    iconColor: { type: String, default: '#979eaa' },
  },
}
</script>

<style scoped></style>
