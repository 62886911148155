var logi = logi ?? {};
logi['maps'] = logi['maps'] ?? {};
import a0_0x550c7d from './logi-maps-utils.js';
logi['maps']['Utils'] = a0_0x550c7d;
import a0_0x5c9c44 from './logi-maps-boundarydata.js';
logi['maps']['BoundaryData'] = a0_0x5c9c44, logi['maps']['BoundaryChecker'] = class {
    #storedBoundary;
    constructor() {
        this.#storedBoundary = [];
    }
    ['cleanBoundary']() {
        if (this.#storedBoundary['length'] == 0x0)
            return ![];
        return this.#storedBoundary = [], !![];
    }
    ['addBoundary'](_0x185f1a, _0x156098) {
        this.#storedBoundary['push'](_0x185f1a), _0x156098 == !![] && this.#storedBoundary['sort'](logi['maps']['BoundaryData']['compare']);
    }
    ['isBoundaryOverlapped'](_0x4f840b) {
        let _0x1b39ca = new Array();
        if (_0x4f840b['groupId'] == 0x0)
            return ![];
        for (let _0x4cc048 of this.#storedBoundary) {
            if (_0x4f840b['groupId'] != _0x4cc048['groupId'])
                continue;
            if (_0x4cc048['bLoad'] != logi['maps']['BoundaryData']['STATUS']['LOAD'])
                continue;
            if (logi['maps']['BoundaryChecker']['circleOnRegion'](_0x4cc048['boundaryCircle'], _0x4f840b['boundaryCircle']) != !![])
                continue;
            if (logi['maps']['BoundaryChecker']['rectOnRegion'](_0x4cc048['boundaryRect'], _0x4f840b['boundaryRect']) != !![])
                continue;
            if (_0x4cc048['creationTick'] <= _0x4f840b['creationTick'])
                return _0x4cc048['overlapCnt'] += 0x1, !![];
            else {
                if (_0x4cc048['creationTick'] > _0x4f840b['creationTick']) {
                    _0x1b39ca['push'](_0x4cc048), _0x4f840b['overlapCnt'] += 0x1;
                    continue;
                }
            }
        }
        for (let _0x341513 of _0x1b39ca) {
            _0x341513['bLoad'] = logi['maps']['BoundaryData']['STATUS']['OVERLAP'], _0x341513['overlapCnt'] = 0x0, _0x341513['creationTick'] = logi['maps']['Utils']['getCurTick']();
        }
        return ![];
    }
    static #ORIENTATION = function (_0xcb7d8d, _0x44eb61, _0x137f8c, _0x44ff46, _0xd52d2c, _0x234be4) {
        return (_0xcb7d8d - _0xd52d2c) * (_0x44ff46 - _0x234be4) - (_0x44eb61 - _0x234be4) * (_0x137f8c - _0xd52d2c);
    };
    static ['pointInRegion'] = function (_0x31b8ed, _0x5b68dd) {
        let _0x4e32e9, _0x53f5bb, _0x556c23 = ![];
        const _0x19b6f8 = _0x5b68dd['length'];
        _0x53f5bb = _0x19b6f8 - 0x1;
        for (_0x4e32e9 = 0x0; _0x4e32e9 < _0x19b6f8; _0x4e32e9++) {
            (_0x5b68dd[_0x53f5bb]['y'] <= _0x31b8ed['y'] && _0x31b8ed['y'] < _0x5b68dd[_0x4e32e9]['y'] && this.#ORIENTATION(_0x5b68dd[_0x4e32e9]['x'], _0x5b68dd[_0x4e32e9]['y'], _0x5b68dd[_0x53f5bb]['x'], _0x5b68dd[_0x53f5bb]['y'], _0x31b8ed['x'], _0x31b8ed['y']) > 0x0 || _0x5b68dd[_0x4e32e9]['y'] <= _0x31b8ed['y'] && _0x31b8ed['y'] < _0x5b68dd[_0x53f5bb]['y'] && this.#ORIENTATION(_0x5b68dd[_0x53f5bb]['x'], _0x5b68dd[_0x53f5bb]['y'], _0x5b68dd[_0x4e32e9]['x'], _0x5b68dd[_0x4e32e9]['y'], _0x31b8ed['x'], _0x31b8ed['y']) > 0x0) && (_0x556c23 = !_0x556c23), _0x53f5bb = _0x4e32e9;
        }
        return _0x556c23;
    };
    static #onSegment = function (_0xf11d66, _0x2f79e3, _0x123ce3) {
        if (_0x2f79e3['x'] <= Math['max'](_0xf11d66['x'], _0x123ce3['x']) && _0x2f79e3['x'] >= Math['min'](_0xf11d66['x'], _0x123ce3['x']) && _0x2f79e3['y'] <= Math['max'](_0xf11d66['y'], _0x123ce3['y']) && _0x2f79e3['y'] >= Math['min'](_0xf11d66['y'], _0x123ce3['y']))
            return !![];
        return ![];
    };
    static #orientation = function (_0x14dbd5, _0x154ce0, _0x62e2e) {
        let _0x56556f = (_0x154ce0['y'] - _0x14dbd5['y']) * (_0x62e2e['x'] - _0x154ce0['x']) - (_0x154ce0['x'] - _0x14dbd5['x']) * (_0x62e2e['y'] - _0x154ce0['y']);
        if (_0x56556f == 0x0)
            return 0x0;
        return _0x56556f > 0x0 ? 0x1 : 0x2;
    };
    static #isLineIntersect = function (_0x3049e3, _0x2e0e8c, _0x55512d, _0x588480) {
        let _0x21416 = this.#orientation(_0x3049e3, _0x2e0e8c, _0x55512d), _0x11ec95 = this.#orientation(_0x3049e3, _0x2e0e8c, _0x588480), _0x2da77d = this.#orientation(_0x55512d, _0x588480, _0x3049e3), _0x2848fd = this.#orientation(_0x55512d, _0x588480, _0x2e0e8c);
        if (_0x21416 != _0x11ec95 && _0x2da77d != _0x2848fd)
            return !![];
        if (_0x21416 == 0x0 && this.#onSegment(_0x3049e3, _0x55512d, _0x2e0e8c))
            return !![];
        if (_0x11ec95 == 0x0 && this.#onSegment(_0x3049e3, _0x588480, _0x2e0e8c))
            return !![];
        if (_0x2da77d == 0x0 && this.#onSegment(_0x55512d, _0x3049e3, _0x588480))
            return !![];
        if (_0x2848fd == 0x0 && this.#onSegment(_0x55512d, _0x2e0e8c, _0x588480))
            return !![];
        return ![];
    };
    static ['circleOnRegion'](_0x354868, _0x53db13) {
        const _0x19adc3 = _0x354868['center']['x'] - _0x53db13['center']['x'], _0xf605c1 = _0x354868['center']['y'] - _0x53db13['center']['y'], _0x5cd972 = Math['sqrt'](_0x19adc3 * _0x19adc3 + _0xf605c1 * _0xf605c1);
        if (_0x5cd972 > _0x354868['maxR'] + _0x53db13['maxR'])
            return ![];
        return !![];
    }
    static ['rectOnRegion'](_0x22c376, _0x5ac813) {
        let _0x41193e = 0x0, _0x284820 = 0x0, _0x376c34 = 0x0, _0xe5f5ea = 0x0;
        const _0x24e76d = _0x22c376['length'] < _0x5ac813['length'] ? _0x22c376['length'] : _0x5ac813['length'];
        for (_0x41193e = 0x0; _0x41193e < _0x24e76d - 0x1; _0x41193e++) {
            if (logi['maps']['BoundaryChecker']['pointInRegion'](_0x22c376[_0x41193e], _0x5ac813))
                return !![];
        }
        for (_0x41193e = 0x0; _0x41193e < _0x24e76d - 0x1; _0x41193e++) {
            if (logi['maps']['BoundaryChecker']['pointInRegion'](_0x5ac813[_0x41193e], _0x22c376))
                return !![];
        }
        for (_0x41193e = 0x0; _0x41193e < _0x24e76d; _0x41193e++) {
            _0x376c34 = _0x41193e + 0x1;
            _0x376c34 == _0x24e76d && (_0x376c34 = 0x0);
            for (_0x284820 = 0x0; _0x284820 < _0x24e76d; _0x284820++) {
                _0xe5f5ea = _0x284820 + 0x1;
                _0xe5f5ea == _0x24e76d && (_0xe5f5ea = 0x0);
                if (this.#isLineIntersect(_0x5ac813[_0x41193e], _0x5ac813[_0x376c34], _0x22c376[_0x284820], _0x22c376[_0xe5f5ea]) == !![])
                    return !![];
            }
        }
        return ![];
    }
};
export default logi['maps']['BoundaryChecker'];