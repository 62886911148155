var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content-wrapper content_body" }, [
    _c(
      "div",
      { staticClass: "content-inner" },
      [
        _c(
          "v-card",
          { staticStyle: { "min-height": "777px", "border-radius": "10px" } },
          [
            _c(
              "v-card-title",
              [
                _c("div", [
                  _c(
                    "span",
                    { staticClass: "font-size26 font-weight700 surface800" },
                    [_vm._v(" Map Upload ")]
                  ),
                ]),
                _c("v-spacer"),
                _c(
                  "v-btn",
                  {
                    staticClass: "mt-n1 font-size14 upload-btn mr-2",
                    attrs: { dense: "", outlined: "" },
                    on: { click: _vm.downloadReset },
                  },
                  [
                    _c("v-icon", { attrs: { left: "" } }, [
                      _vm._v(" " + _vm._s(_vm.icons.mdiUpload) + " "),
                    ]),
                    _vm._v(" 취소하기 "),
                  ],
                  1
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "mt-n1 font-size14 upload-btn",
                    attrs: {
                      dense: "",
                      outlined: "",
                      disabled: !_vm.isDownloadFlag,
                    },
                    on: { click: _vm.downloadRunning },
                  },
                  [
                    _c("v-icon", { attrs: { left: "" } }, [
                      _vm._v(" " + _vm._s(_vm.icons.mdiUpload) + " "),
                    ]),
                    _vm._v(" 다운로드 "),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("v-card-subtitle", { staticClass: "mt-0" }, [
              _c("span", { staticClass: "font-size16" }, [
                _vm._v("버전: " + _vm._s(_vm.version)),
              ]),
            ]),
            _c(
              "v-card-text",
              { staticClass: "mt-10" },
              [_c("file-upload", { attrs: { "map-info": _vm.mapInfo } })],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }