var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "dash-board-card-contents2" },
    [
      _c("v-card-title", { staticClass: "align-start" }, [
        _c(
          "span",
          { staticClass: "font-size19 font-weight-semibold surface800" },
          [_vm._v(" 차주 가입·탈퇴 현황 ")]
        ),
      ]),
      _c("div", { staticStyle: { padding: "0 16px" } }, [
        _c("span", { staticClass: "font-size13 flex-center" }, [
          _vm._v(" " + _vm._s(this.monthAccumulation) + "월 누적 "),
        ]),
      ]),
      _c(
        "v-card-text",
        {
          staticClass: "flex-center",
          staticStyle: { "justify-content": "space-between" },
        },
        [
          _c(
            "div",
            { staticStyle: { display: "flex", "align-items": "flex-end" } },
            [
              _c("div", [
                _c("span", { staticClass: "fonts-size16 font-weight500" }, [
                  _vm._v("총"),
                ]),
                _c(
                  "span",
                  {
                    staticClass: "font-size26 font-weight700",
                    staticStyle: {
                      display: "inline-block",
                      "margin-left": "6px",
                    },
                  },
                  [_vm._v(" " + _vm._s(this.cumulative) + " ")]
                ),
                _c("span", { staticClass: "fonts-size16 font-weight500" }, [
                  _vm._v("건"),
                ]),
              ]),
              _c(
                "div",
                {
                  staticClass: "flex-center",
                  staticStyle: {
                    color: "#2f2f2f",
                    background: "#f9fafa",
                    "margin-left": "21px",
                    padding: "5px 11px",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "flex-center" },
                    [
                      _c("IcGrup", { staticStyle: { "margin-right": "19px" } }),
                      _vm._v(" 가입 "),
                      _c("span", { staticStyle: { "margin-left": "19px" } }, [
                        _vm._v(_vm._s(this.signUp)),
                      ]),
                      _vm._v(" 명 "),
                    ],
                    1
                  ),
                  _c("v-divider", {
                    staticStyle: {
                      height: "15px",
                      "padding-left": "10px",
                      "margin-top": "6.5px",
                    },
                    attrs: { vertical: "" },
                  }),
                  _c("div", { staticStyle: { "margin-left": "19px" } }, [
                    _vm._v(" 탈퇴 "),
                    _c("span", { staticStyle: { "margin-left": "19px" } }, [
                      _vm._v(_vm._s(this.withdrawal)),
                    ]),
                    _vm._v(" 명 "),
                  ]),
                ],
                1
              ),
            ]
          ),
          _c("div", { staticClass: "flex-center" }, [
            _c(
              "div",
              {
                staticClass: "flex-center",
                staticStyle: { "margin-right": "15px" },
              },
              [
                _c("div", { staticClass: "circle-red" }),
                _c("span", { staticClass: "font-size13" }, [_vm._v("가입")]),
              ]
            ),
            _c("div", { staticClass: "flex-center" }, [
              _c("div", { staticClass: "circle-blue" }),
              _c("span", { staticClass: "font-size13" }, [_vm._v("탈퇴")]),
            ]),
          ]),
        ]
      ),
      _c(
        "v-card-text",
        { staticClass: "mt-6" },
        [
          _c("chartjs-component-line-chart", {
            attrs: {
              height: 250,
              "base-labels": _vm.baseLabels,
              "base-data": _vm.baseData,
              "max-value": _vm.maxValue,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }