var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0" },
    [
      _c(
        "v-tabs",
        {
          attrs: { color: "accent" },
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        [
          _c("v-tabs-slider", { attrs: { color: "accent" } }),
          _vm._l(_vm.tabItems, function (item, index) {
            return _c(
              "v-tab",
              {
                key: index,
                staticClass: "font-size18 v-tab-size",
                attrs: {
                  disabled:
                    (index !== 0 && _vm.disabledTab) ||
                    (index === 2 && _vm.paidByInvoice),
                },
              },
              [_vm._v(" " + _vm._s(item.title) + " ")]
            )
          }),
        ],
        2
      ),
      _c(
        "v-tabs-items",
        {
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        _vm._l(_vm.tabItems, function (item, index) {
          return _c(
            "v-tab-item",
            { key: index },
            [
              _vm.tab === 0
                ? _c(
                    "v-card",
                    [
                      _vm.itemDetail.callRequestStatus ===
                        _vm.callRequestStatus.ASSIGN_REQUEST ||
                      _vm.itemDetail.callRequestStatus ===
                        _vm.callRequestStatus.ASSIGNABLE ||
                      _vm.canceledItems.includes(
                        _vm.itemDetail.callRequestStatus
                      ) ||
                      _vm.itemDetail.callRequestStatus ===
                        _vm.callRequestStatus.HIDDEN ||
                      _vm.itemDetail.callRequestStatus ===
                        _vm.callRequestStatus.HOLDING
                        ? _c(
                            "v-card",
                            {
                              staticClass: "card-bg-disabled",
                              attrs: { "min-height": "212px" },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "image-center pt-14" },
                                [
                                  _vm.itemDetail.callRequestStatus ===
                                    _vm.callRequestStatus.ASSIGN_REQUEST ||
                                  _vm.itemDetail.callRequestStatus ===
                                    _vm.callRequestStatus.COMPLETED ||
                                  _vm.itemDetail.callRequestStatus ===
                                    _vm.callRequestStatus.ASSIGNABLE
                                    ? _c("truck_70x70_disable")
                                    : _c("truck_70x70_cancel"),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "image-center font-size16 surface2000",
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.itemDetail.callRequestStatus ===
                                            _vm.callRequestStatus.COMPLETED
                                            ? "운송이 완료된 화물입니다."
                                            : _vm.itemDetail
                                                .callRequestStatus ===
                                              _vm.callRequestStatus.ASSIGNABLE
                                            ? "배차 대기 중입니다."
                                            : _vm.itemDetail
                                                .callRequestStatus ===
                                              _vm.callRequestStatus
                                                .ASSIGN_REQUEST
                                            ? "배차 요청 중입니다."
                                            : _vm.canceledItems.includes(
                                                _vm.itemDetail.callRequestStatus
                                              )
                                            ? "취소된 화물입니다."
                                            : _vm.itemDetail
                                                .callRequestStatus ===
                                              _vm.callRequestStatus.HIDDEN
                                            ? "비공개 처리된 화물입니다."
                                            : "관리자에 의해서 보류 처리된 화물입니다."
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          )
                        : (_vm.itemDetail.orderInfos &&
                            _vm.itemDetail.orderInfos[0].deliveryRound ===
                              "return") ||
                          (_vm.itemDetail.orderInfos &&
                            _vm.itemDetail.orderInfos[0].deliveryRound ===
                              "oneway" &&
                            _vm.itemDetail.callDestinations.find(function (
                              item
                            ) {
                              return (
                                item.numOfOrder === 4 || item.numOfOrder === 5
                              )
                            })) ||
                          _vm.isRequesting ||
                          _vm.itemDetail.callRequestStatus ===
                            _vm.callRequestStatus.ASSIGNED ||
                          _vm.itemDetail.callRequestStatus ===
                            _vm.callRequestStatus.TRANSPORTING ||
                          _vm.itemDetail.callRequestStatus ===
                            _vm.callRequestStatus.COMPLETED
                        ? _c(
                            "v-card",
                            {
                              staticClass: "d-flex flex-column",
                              attrs: { "min-height": "720px" },
                            },
                            [
                              _vm.isRequesting
                                ? _c(
                                    "v-card",
                                    {
                                      staticClass: "card-bg-disabled",
                                      attrs: { "min-height": "126px" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "px-11 pt-8 font-size20 surface800",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.driverInfo
                                                  ? _vm.driverInfo.userName
                                                  : ""
                                              ) +
                                              " 기사님이 배차 요청을 하였습니다. "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.timeLimit,
                                              expression: "timeLimit",
                                            },
                                          ],
                                          staticClass:
                                            "px-11 pt-2 font-size16 accent--text",
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.timeLimit) +
                                                " 후 자동 배차 취소 처리 됩니다."
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm.itemDetail.orderInfos[0].deliveryRound ===
                                    "return" ||
                                  (_vm.itemDetail.orderInfos[0]
                                    .deliveryRound === "oneway" &&
                                    _vm.itemDetail.callDestinations.find(
                                      function (item) {
                                        return (
                                          item.numOfOrder === 4 ||
                                          item.numOfOrder === 5
                                        )
                                      }
                                    ))
                                ? _c(
                                    "v-card",
                                    {
                                      staticClass: "card-bg-disabled",
                                      attrs: { "min-height": "126px" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "px-7 pt-8 font-size20 surface800",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.itemDetail.orderInfos[0]
                                                  .deliveryRound === "return"
                                                  ? _vm.returnCaseMessage
                                                  : _vm.transitCaseMessage
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c("div", { staticClass: "px-7 py-8" }, [
                                        _c("img", {
                                          attrs: {
                                            src: _vm.threeStepImage,
                                            width: "100%",
                                          },
                                        }),
                                      ]),
                                    ]
                                  )
                                : _vm.registered ||
                                  _vm.itemDetail.callRequestStatus ===
                                    _vm.callRequestStatus.ASSIGNED ||
                                  _vm.itemDetail.callRequestStatus ===
                                    _vm.callRequestStatus.TRANSPORTING ||
                                  _vm.itemDetail.callRequestStatus ===
                                    _vm.callRequestStatus.COMPLETED
                                ? _c(
                                    "v-card",
                                    {
                                      staticClass: "card-bg-disabled",
                                      attrs: { "min-height": "126px" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "px-7 pt-8 font-size20 surface800",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.statusMessage) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c("div", { staticClass: "px-7 py-8" }, [
                                        _c("img", {
                                          attrs: {
                                            src: _vm.statusImage,
                                            width: "100%",
                                          },
                                        }),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "v-card",
                                {
                                  staticClass:
                                    "center-content font-size16 transparent",
                                  attrs: {
                                    outlined: "",
                                    "min-height": "272px ",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "px-5 pt-6 py-5 surface800",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.driverInfo
                                              ? _vm.driverInfo.userName
                                              : ""
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _vm._l(_vm.info, function (item, i) {
                                    return _c(
                                      "div",
                                      {
                                        key: i,
                                        staticClass:
                                          "px-5 py-1 flex-start-contents",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "surface500",
                                            staticStyle: { width: "20%" },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.title) + " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "surface800",
                                            staticStyle: { width: "65%" },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.content) + " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm.tab === 1
                ? _c(
                    "v-card",
                    { staticClass: "card-with-scroll" },
                    [
                      _c(
                        "perfect-scrollbar",
                        { staticClass: "ps-user-notifications" },
                        [
                          _c(
                            "v-card",
                            { staticClass: "d-flex justify-center pt-6" },
                            [
                              _c(
                                "div",
                                { staticClass: "scroll-content pa-1" },
                                [
                                  _c("div", { staticClass: "invoice" }, [
                                    _c("img", {
                                      directives: [
                                        { name: "viewer", rawName: "v-viewer" },
                                      ],
                                      staticClass: "cursor-pointer",
                                      class: {
                                        admin: _vm.changedInvoiceByAdmin,
                                      },
                                      attrs: {
                                        src: _vm.invoiceUrlString,
                                        width: "100%",
                                      },
                                    }),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                          _vm.changedInvoiceByAdmin
                            ? _c(
                                "v-card",
                                {
                                  staticClass: "d-flex flex-column text-center",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "pt-10 font-size13 surface500",
                                    },
                                    [
                                      _vm._v(
                                        " 관리자에 의해서 대체된 이미지 입니다 "
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm.approved || _vm.rejected
                            ? _c(
                                "v-card",
                                {
                                  staticClass: "d-flex flex-column text-center",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "pt-10 font-size18 surface800",
                                    },
                                    [
                                      _vm._v(
                                        " 인수증 " +
                                          _vm._s(
                                            _vm.approved ? "승인" : "반려"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c("div", {
                                    staticClass: "py-2 font-size13 surface500",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.approved
                                          ? "인수증 승인이 완료 되었습니다."
                                          : "인수증이 반려\n              되었습니다.\n              <br />\n              반려사유 : " +
                                              _vm.rejectionReason
                                      ),
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm.tab === 2
                ? _c(
                    "v-card",
                    [
                      !_vm.paymentComplete && !_vm.paymentCancel
                        ? _c("card-payment-init", {
                            attrs: { "item-detail": _vm.itemDetail },
                          })
                        : _vm._e(),
                      _vm.paymentComplete && _vm.paymentCancelInit
                        ? _c("card-payment-completed", {
                            attrs: { "item-detail": _vm.itemDetail },
                          })
                        : _vm._e(),
                      _vm.paymentCancelWait || _vm.paymentCancelFailure
                        ? _c("card-payment-canceled", {
                            attrs: { "item-detail": _vm.itemDetail },
                          })
                        : _vm._e(),
                      _vm.paymentCancelReject
                        ? _c("card-payment-canceled-reject", {
                            attrs: { "item-detail": _vm.itemDetail },
                          })
                        : _vm._e(),
                      _vm.paymentCancelCompleted
                        ? _c("card-payment-canceled-completed", {
                            attrs: { "item-detail": _vm.itemDetail },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }