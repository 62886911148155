var logi = logi ?? {};
logi['maps'] = logi['maps'] ?? {};
import a8_0x100861 from './logi-maps-defines.js';
logi['maps']['Defines'] = a8_0x100861, logi['maps']['Resource'] = class {
    static #MAX_IMAGE_CACHE_CNT = 0x80;
    static #imageCache = new Map();
    static #imageCalls = new Map();
    #updateFlag = ![];
    #resourcePath = '';
    #country = logi['maps']['Defines']['COUNTRY_KOR'];
    #resourceTheme = '';
    #symbols = new Map();
    #mapStyle = null;
    #alignCodeDic = new Map([
        [
            0xb,
            'left-top'
        ],
        [
            0x15,
            'center-top'
        ],
        [
            0x1f,
            'right-top'
        ],
        [
            0xc,
            'left-middle'
        ],
        [
            0x16,
            'center-middle'
        ],
        [
            0x20,
            'right-middle'
        ],
        [
            0xd,
            'left-bottom'
        ],
        [
            0x17,
            'center-bottom'
        ],
        [
            0x21,
            'right-bottom'
        ]
    ]);
    constructor(_0x4cbe84, _0x293fec) {
        this.#resourcePath = _0x4cbe84?.['slice'](-0x1) == '/' ? _0x4cbe84['slice'](0x0, -0x1) : _0x4cbe84 ?? '', this.#country = _0x293fec ?? logi['maps']['Defines']['COUNTRY_KOR'];
    }
    ['setUpdateFlag'](_0x2b5739) {
        this.#updateFlag = _0x2b5739;
    }
    ['getUpdateFlag']() {
        return this.#updateFlag;
    }
    static ['getImage'](_0x2005ae) {
        const _0x2abcfc = this;
        if (_0x2abcfc.#imageCache['size'] >= _0x2abcfc.#MAX_IMAGE_CACHE_CNT) {
            let _0x34cf62 = _0x2abcfc.#MAX_IMAGE_CACHE_CNT * 0.5;
            for (const [_0x494751, _0x262e63] of _0x2abcfc.#imageCache) {
                this.#imageCache['delete'](_0x494751);
                if (--_0x34cf62 <= 0x0)
                    break;
            }
            this.#imageCalls['clear']();
        }
        return new Promise((_0x574d54, _0x50840a) => {
            function _0x558f82(_0x84e9dc) {
                if (_0x84e9dc > 0x0 && _0x2abcfc.#imageCalls['get'](_0x2005ae))
                    setTimeout(() => {
                        _0x558f82(--_0x84e9dc);
                    }, 0x1f4);
                else {
                    if (!_0x2005ae || _0x2005ae == '')
                        _0x2abcfc.#imageCalls['delete'](_0x2005ae), _0x50840a();
                    else {
                        const _0x6c733d = _0x2abcfc.#imageCache['get'](_0x2005ae);
                        _0x6c733d ? (_0x2abcfc.#imageCalls['get'](_0x2005ae) && _0x2abcfc.#imageCalls['delete'](_0x2005ae), _0x574d54(_0x6c733d)) : (_0x2abcfc.#imageCalls['set'](_0x2005ae, 'ready'), _0x2abcfc.#requestImage(_0x2005ae, 0x2)['then'](_0x177d2a => {
                            _0x2abcfc.#imageCache['set'](_0x2005ae, _0x177d2a), _0x2abcfc.#imageCalls['delete'](_0x2005ae), _0x574d54(_0x177d2a);
                        })['catch'](_0x2651c5 => {
                            _0x2abcfc.#imageCalls['delete'](_0x2005ae), _0x50840a(_0x2651c5);
                        }));
                    }
                }
            }
            setTimeout(() => {
                _0x558f82(0x4);
            }, 0x0);
        });
    }
    static #requestImage(_0x24d0b2, _0x25fbc0) {
        const _0x4bb118 = this;
        return new Promise((_0x2aad74, _0x45531b) => {
            const _0x5d60b3 = new Image();
            _0x5d60b3['crossOrigin'] = 'Anonymous', _0x5d60b3['onload'] = () => {
                _0x2aad74(_0x5d60b3);
            }, _0x5d60b3['onerror'] = _0x5c6cbd => {
                _0x25fbc0 > 0x0 ? setTimeout(function () {
                    _0x4bb118.#requestImage(_0x24d0b2, --_0x25fbc0)['then'](_0x1e23a7 => {
                        _0x2aad74(_0x1e23a7);
                    })['catch'](_0x34e68d => {
                        _0x45531b(_0x34e68d);
                    });
                }, 0x3e8) : _0x45531b(_0x5c6cbd);
            }, _0x5d60b3['src'] = _0x24d0b2;
        });
    }
    ['setTheme'](_0x338bda) {
        const _0x1f9f82 = [];
        _0x338bda = _0x338bda ?? 'default';
        if (this.#resourceTheme != _0x338bda) {
            this.#resourceTheme = _0x338bda;
            const _0x1093bc = this.#resourcePath + '/' + this.#country + '/' + this.#resourceTheme;
            if (this.#country == logi['maps']['Defines']['COUNTRY_KOR']) {
                const _0x4b5092 = 'NotoSansKR-Bold-Hestia', _0x645d3e = 'url(' + _0x1093bc + '/fonts/NotoSansKR-Bold-Hestia.ttf)';
                _0x1f9f82['push'](new Promise((_0x2a8258, _0x5dc66d) => {
                    this['addFont'](_0x4b5092, _0x645d3e)['then'](_0x1a36da => {
                        _0x2a8258();
                    })['catch'](_0x4355dd => {
                        _0x5dc66d(_0x4355dd);
                    });
                }));
                const _0x223234 = _0x1093bc + '/symbol', _0x27dd55 = [];
                for (let _0xd29a87 = 0x1; _0xd29a87 <= 0x41; ++_0xd29a87) {
                    _0xd29a87 != 0x3e && _0x27dd55['push'](_0xd29a87);
                }
                _0x1f9f82['push'](new Promise((_0x187da6, _0x13a14a) => {
                    this.#loadSymbol(_0x223234, _0x27dd55)['then'](_0xb6e62e => {
                        _0x187da6();
                    })['catch'](_0x546521 => {
                        _0x13a14a(_0x546521);
                    });
                }));
                const _0x1c0a79 = _0x1093bc + '/stylesheet/map_style.xml';
                _0x1f9f82['push'](new Promise((_0x5d93ce, _0x5bfbd3) => {
                    this.#loadStyle(_0x1c0a79)['then'](_0x264419 => {
                        _0x5d93ce();
                    })['catch'](_0x12694f => {
                        _0x5bfbd3(_0x12694f);
                    });
                }));
            } else {
            }
        }
        return new Promise((_0xf24600, _0x17983e) => {
            Promise['all'](_0x1f9f82)['then'](_0x307426 => {
                _0xf24600();
            })['catch'](_0x3fa9c8 => {
                _0x17983e(_0x3fa9c8);
            });
        });
    }
    ['getTheme']() {
        return this.#resourceTheme;
    }
    ['addFont'](_0x47629c, _0x38b5b6) {
        return new Promise((_0xf89ba0, _0x2dcf30) => {
            if (document['fonts']) {
                const _0x12760b = document['fonts']['check']('1em\x20' + _0x47629c);
                if (_0x12760b)
                    _0xf89ba0();
                else {
                    const _0x3adfc4 = new FontFace(_0x47629c, _0x38b5b6), _0x320865 = this;
                    _0x3adfc4['load']()['then'](_0x20ab72 => {
                        document['fonts']['add'](_0x3adfc4), this.#updateFlag = !![], _0xf89ba0();
                    })['catch'](_0x43df01 => {
                        console['log']('[logi.maps]\x20Load\x20font\x20failed.\x20(' + _0x47629c + ',\x20' + _0x43df01 + ')'), _0x2dcf30(_0x43df01);
                    });
                }
            } else
                console['log']('[logi.maps]\x20Load\x20font\x20failed.\x20(document.fonts\x20is\x20not\x20supported)'), _0x2dcf30('document.fonts\x20is\x20not\x20supported');
        });
    }
    #loadSymbol(_0x193682, _0x4abc65) {
        function _0x5532ad(_0x499248) {
            return new Promise((_0xbd19, _0x128c39) => {
                let _0x3819d1 = new Image();
                _0x3819d1['crossOrigin'] = 'Anonymous', _0x3819d1['onload'] = () => _0xbd19(_0x3819d1), _0x3819d1['onerror'] = _0x128c39, _0x3819d1['src'] = _0x499248;
            });
        }
        const _0x53395a = [];
        this.#symbols = new Map();
        for (const _0x128c6f of _0x4abc65) {
            const _0x4bf3b2 = _0x193682 + '/' + _0x128c6f + '.png', _0x2d7a88 = new Promise((_0xa7f00a, _0x3a26ed) => {
                    _0x5532ad(_0x4bf3b2)['then'](_0x317dfe => {
                        this.#symbols['set'](_0x128c6f, _0x317dfe), this.#updateFlag = !![], _0xa7f00a();
                    })['catch'](_0x595781 => {
                        console['log']('[logi.maps]\x20Load\x20symbol\x20failed.\x20(' + _0x595781 + ')'), _0x3a26ed(_0x595781);
                    });
                });
            _0x53395a['push'](_0x2d7a88);
        }
        return new Promise((_0x1998b8, _0x13623a) => {
            Promise['all'](_0x53395a)['then'](_0x54c691 => {
                _0x1998b8();
            })['catch'](_0x2c19d6 => {
                _0x13623a(_0x2c19d6);
            });
        });
    }
    ['getSymbol'](_0x1ffa38) {
        return this.#symbols['get'](_0x1ffa38);
    }
    #loadStyle(_0x244fd4) {
        return new Promise((_0xfa30f8, _0x3452d4) => {
            fetch(_0x244fd4)['then'](_0x1a14a4 => _0x1a14a4['text']())['then'](_0x51a7de => {
                const _0x4e1f95 = new DOMParser(), _0x157a97 = _0x4e1f95['parseFromString'](_0x51a7de, 'application/xml'), _0x31b66e = _0x157a97['getElementsByTagName']('data')[0x0], _0x4eba97 = new Object();
                _0x4eba97['colorMap'] = new Map(), _0x4eba97['polygonMap'] = new Map(), _0x4eba97['polylineMap'] = new Map(), _0x4eba97['symbolMap'] = new Map(), _0x4eba97['textMap'] = new Map();
                for (const _0x510d06 of _0x31b66e['getElementsByTagName']('COLORS')) {
                    const _0xc6a470 = _0x510d06['getAttribute']('layer');
                    let _0x1e56d8 = _0x4eba97['colorMap']['get'](_0xc6a470);
                    !_0x1e56d8 && (_0x4eba97['colorMap']['set'](_0xc6a470, new Array()), _0x1e56d8 = _0x4eba97['colorMap']['get'](_0xc6a470));
                    for (const _0x224dff of _0x510d06['getElementsByTagName']('COLOR')) {
                        const _0x431301 = parseInt(_0x224dff['getAttribute']('CATEGORY')), _0x13c9f6 = parseInt(_0x224dff['getAttribute']('LEVEL')), _0x1afe22 = parseInt(_0x224dff['getAttribute']('R'))['toString'](0x10)['padStart'](0x2, '0'), _0x372d16 = parseInt(_0x224dff['getAttribute']('G'))['toString'](0x10)['padStart'](0x2, '0'), _0x55ac7a = parseInt(_0x224dff['getAttribute']('B'))['toString'](0x10)['padStart'](0x2, '0'), _0x544b9b = '#' + _0x1afe22 + _0x372d16 + _0x55ac7a;
                        _0x1e56d8['push']({
                            'categoryId': _0x431301,
                            'level': _0x13c9f6,
                            'color': _0x544b9b
                        });
                    }
                }
                for (const _0x333846 of _0x31b66e['getElementsByTagName']('POLYGONS')) {
                    const _0x2bc640 = _0x333846['getAttribute']('layer');
                    let _0x23c5c9 = _0x4eba97['polygonMap']['get'](_0x2bc640);
                    !_0x23c5c9 && (_0x4eba97['polygonMap']['set'](_0x2bc640, new Array()), _0x23c5c9 = _0x4eba97['polygonMap']['get'](_0x2bc640));
                    for (const _0xac57d1 of _0x333846['getElementsByTagName']('POLYGON')) {
                        const _0x45c774 = parseInt(_0xac57d1['getAttribute']('CATEGORY')), _0x5cc6b2 = parseInt(_0xac57d1['getAttribute']('LEVEL')), _0x30f9bd = _0xac57d1['getAttribute']('FILL_COLOR')['split'](','), _0xd8d491 = parseInt(_0x30f9bd[0x0] ?? 0x0)['toString'](0x10)['padStart'](0x2, '0'), _0x3b93cc = parseInt(_0x30f9bd[0x1] ?? 0x0)['toString'](0x10)['padStart'](0x2, '0'), _0x58a703 = parseInt(_0x30f9bd[0x2] ?? 0x0)['toString'](0x10)['padStart'](0x2, '0'), _0x31b157 = '#' + _0xd8d491 + _0x3b93cc + _0x58a703;
                        _0x23c5c9['push']({
                            'categoryId': _0x45c774,
                            'level': _0x5cc6b2,
                            'fillColor': _0x31b157
                        });
                    }
                }
                for (const _0x337272 of _0x31b66e['getElementsByTagName']('POLYLINES')) {
                    const _0x1e48e9 = _0x337272['getAttribute']('layer');
                    let _0x5113d8 = _0x4eba97['polylineMap']['get'](_0x1e48e9);
                    !_0x5113d8 && (_0x4eba97['polylineMap']['set'](_0x1e48e9, new Array()), _0x5113d8 = _0x4eba97['polylineMap']['get'](_0x1e48e9));
                    for (const _0x52537a of _0x337272['getElementsByTagName']('POLYLINE')) {
                        const _0xa44756 = parseInt(_0x52537a['getAttribute']('CATEGORY')), _0xc81b7c = parseInt(_0x52537a['getAttribute']('LEVEL')), _0x38d6c7 = parseFloat(_0x52537a['getAttribute']('WIDTH')), _0x1182e3 = _0x52537a['getAttribute']('COLOR')['split'](','), _0x48cc63 = parseInt(_0x1182e3[0x0] ?? 0x0)['toString'](0x10)['padStart'](0x2, '0'), _0x47700f = parseInt(_0x1182e3[0x1] ?? 0x0)['toString'](0x10)['padStart'](0x2, '0'), _0x56610e = parseInt(_0x1182e3[0x2] ?? 0x0)['toString'](0x10)['padStart'](0x2, '0'), _0x2bd2cf = '#' + _0x48cc63 + _0x47700f + _0x56610e;
                        _0x5113d8['push']({
                            'categoryId': _0xa44756,
                            'level': _0xc81b7c,
                            'width': _0x38d6c7,
                            'color': _0x2bd2cf
                        });
                    }
                }
                for (const _0x536d93 of _0x31b66e['getElementsByTagName']('SYMBOLS')) {
                    const _0x279a19 = _0x536d93['getAttribute']('layer');
                    let _0x26034a = _0x4eba97['symbolMap']['get'](_0x279a19);
                    !_0x26034a && (_0x4eba97['symbolMap']['set'](_0x279a19, new Array()), _0x26034a = _0x4eba97['symbolMap']['get'](_0x279a19));
                    for (const _0x32812d of _0x536d93['getElementsByTagName']('SYMBOL')) {
                        const _0x5c5eac = parseInt(_0x32812d['getAttribute']('CATEGORY')), _0x1c816f = parseInt(_0x32812d['getAttribute']('LEVEL')), _0x21f953 = _0x32812d['getAttribute']('FONT_FAMILY'), _0x1a4f2b = parseInt(_0x32812d['getAttribute']('FONT_SIZE')), _0x5c1ec9 = _0x32812d['getAttribute']('TEXT_COLOR')['split'](','), _0x4bca1d = parseInt(_0x5c1ec9[0x0] ?? 0x0)['toString'](0x10)['padStart'](0x2, '0'), _0x91ff0b = parseInt(_0x5c1ec9[0x1] ?? 0x0)['toString'](0x10)['padStart'](0x2, '0'), _0x4f073d = parseInt(_0x5c1ec9[0x2] ?? 0x0)['toString'](0x10)['padStart'](0x2, '0'), _0x44f5d4 = '#' + _0x4bca1d + _0x91ff0b + _0x4f073d, _0x4c5df2 = parseInt(_0x32812d['getAttribute']('STROKE')), _0x316f5f = _0x32812d['getAttribute']('STROKE_COLOR')['split'](','), _0x40df9d = parseInt(_0x316f5f[0x0] ?? 0x0)['toString'](0x10)['padStart'](0x2, '0'), _0x2406b2 = parseInt(_0x316f5f[0x1] ?? 0x0)['toString'](0x10)['padStart'](0x2, '0'), _0x40b708 = parseInt(_0x316f5f[0x2] ?? 0x0)['toString'](0x10)['padStart'](0x2, '0'), _0x21ab8c = '#' + _0x40df9d + _0x2406b2 + _0x40b708, _0x490072 = this.#alignCodeDic['get'](parseInt(_0x32812d['getAttribute']('TEXT_ALIGN'))) ?? 'center-middle';
                        _0x26034a['push']({
                            'categoryId': _0x5c5eac,
                            'level': _0x1c816f,
                            'fontFamily': _0x21f953,
                            'fontSize': _0x1a4f2b,
                            'textColor': _0x44f5d4,
                            'stroke': _0x4c5df2,
                            'strokeColor': _0x21ab8c,
                            'textAlign': _0x490072
                        });
                    }
                }
                for (const _0x1b5b21 of _0x31b66e['getElementsByTagName']('TEXTS')) {
                    const _0x3f7bfa = _0x1b5b21['getAttribute']('layer');
                    let _0x46a300 = _0x4eba97['textMap']['get'](_0x3f7bfa);
                    !_0x46a300 && (_0x4eba97['textMap']['set'](_0x3f7bfa, new Array()), _0x46a300 = _0x4eba97['textMap']['get'](_0x3f7bfa));
                    for (const _0x464549 of _0x1b5b21['getElementsByTagName']('TEXT')) {
                        const _0x50a615 = parseInt(_0x464549['getAttribute']('CATEGORY')), _0x3eafbf = parseInt(_0x464549['getAttribute']('LEVEL')), _0x28e969 = _0x464549['getAttribute']('FONT_FAMILY'), _0x4972be = parseInt(_0x464549['getAttribute']('FONT_SIZE')), _0x530708 = _0x464549['getAttribute']('TEXT_COLOR')['split'](','), _0x4e27a8 = parseInt(_0x530708[0x0] ?? 0x0)['toString'](0x10)['padStart'](0x2, '0'), _0x5e12e5 = parseInt(_0x530708[0x1] ?? 0x0)['toString'](0x10)['padStart'](0x2, '0'), _0x2bf6d5 = parseInt(_0x530708[0x2] ?? 0x0)['toString'](0x10)['padStart'](0x2, '0'), _0x4ec3fc = '#' + _0x4e27a8 + _0x5e12e5 + _0x2bf6d5, _0x54917a = parseInt(_0x464549['getAttribute']('STROKE')), _0x51c9ad = _0x464549['getAttribute']('STROKE_COLOR')['split'](','), _0x46fad1 = parseInt(_0x51c9ad[0x0] ?? 0x0)['toString'](0x10)['padStart'](0x2, '0'), _0x123cb1 = parseInt(_0x51c9ad[0x1] ?? 0x0)['toString'](0x10)['padStart'](0x2, '0'), _0x3bd0bf = parseInt(_0x51c9ad[0x2] ?? 0x0)['toString'](0x10)['padStart'](0x2, '0'), _0x9005ab = '#' + _0x46fad1 + _0x123cb1 + _0x3bd0bf, _0x222cc4 = this.#alignCodeDic['get'](parseInt(_0x464549['getAttribute']('TEXT_ALIGN'))) ?? 'center-middle';
                        _0x46a300['push']({
                            'categoryId': _0x50a615,
                            'level': _0x3eafbf,
                            'fontFamily': _0x28e969,
                            'fontSize': _0x4972be,
                            'textColor': _0x4ec3fc,
                            'stroke': _0x54917a,
                            'strokeColor': _0x9005ab,
                            'textAlign': _0x222cc4
                        });
                    }
                }
                this.#mapStyle = _0x4eba97, this.#updateFlag = !![], _0xfa30f8();
            });
        });
    }
    ['getColorStyle'](_0x50aed5, _0x1b9c2b, _0x5813ea) {
        const _0x3ae7e0 = this.#mapStyle?.['colorMap']['get'](_0x50aed5);
        if (_0x3ae7e0) {
            for (const _0x2e36e4 of _0x3ae7e0) {
                if (_0x2e36e4['categoryId'] == _0x1b9c2b && _0x2e36e4['level'] == _0x5813ea)
                    return _0x2e36e4;
            }
            for (const _0x14125c of _0x3ae7e0) {
                if (_0x14125c['categoryId'] == _0x1b9c2b && _0x14125c['level'] == 0x0)
                    return _0x14125c;
            }
            for (const _0x468db7 of _0x3ae7e0) {
                if (_0x468db7['categoryId'] == 0x0 && _0x468db7['level'] == _0x5813ea)
                    return _0x468db7;
            }
            for (const _0x1c7a61 of _0x3ae7e0) {
                if (_0x1c7a61['categoryId'] == 0x0 && _0x1c7a61['level'] == 0x0)
                    return _0x1c7a61;
            }
        }
        return null;
    }
    ['getPolygonStyle'](_0x3f552c, _0x44faec, _0x1c14ee) {
        const _0x328bfc = this.#mapStyle?.['polygonMap']['get'](_0x3f552c);
        if (_0x328bfc) {
            for (const _0x9921de of _0x328bfc) {
                if (_0x9921de['categoryId'] == _0x44faec && _0x9921de['level'] == _0x1c14ee)
                    return _0x9921de;
            }
            for (const _0x3c9973 of _0x328bfc) {
                if (_0x3c9973['categoryId'] == _0x44faec && _0x3c9973['level'] == 0x0)
                    return _0x3c9973;
            }
            for (const _0x15b8ff of _0x328bfc) {
                if (_0x15b8ff['categoryId'] == 0x0 && _0x15b8ff['level'] == _0x1c14ee)
                    return _0x15b8ff;
            }
            for (const _0x3711d1 of _0x328bfc) {
                if (_0x3711d1['categoryId'] == 0x0 && _0x3711d1['level'] == 0x0)
                    return _0x3711d1;
            }
        }
        return null;
    }
    ['getPolylineStyle'](_0x22a312, _0xf8e888, _0x280ada) {
        const _0x52d222 = this.#mapStyle?.['polylineMap']['get'](_0x22a312);
        if (_0x52d222) {
            for (const _0x1708ad of _0x52d222) {
                if (_0x1708ad['categoryId'] == _0xf8e888 && _0x1708ad['level'] == _0x280ada)
                    return _0x1708ad;
            }
            for (const _0x416ea9 of _0x52d222) {
                if (_0x416ea9['categoryId'] == _0xf8e888 && _0x416ea9['level'] == 0x0)
                    return _0x416ea9;
            }
            for (const _0x999857 of _0x52d222) {
                if (_0x999857['categoryId'] == 0x0 && _0x999857['level'] == _0x280ada)
                    return _0x999857;
            }
            for (const _0x5ad72d of _0x52d222) {
                if (_0x5ad72d['categoryId'] == 0x0 && _0x5ad72d['level'] == 0x0)
                    return _0x5ad72d;
            }
        }
        return null;
    }
    ['getSymbolStyle'](_0x45f742, _0x52ed5d, _0x4e6d08) {
        const _0x2c1eb4 = this.#mapStyle?.['symbolMap']['get'](_0x45f742);
        if (_0x2c1eb4) {
            for (const _0x456593 of _0x2c1eb4) {
                if (_0x456593['categoryId'] == _0x52ed5d && _0x456593['level'] == _0x4e6d08)
                    return _0x456593;
            }
            for (const _0x165735 of _0x2c1eb4) {
                if (_0x165735['categoryId'] == _0x52ed5d && _0x165735['level'] == 0x0)
                    return _0x165735;
            }
            for (const _0x5c2bb5 of _0x2c1eb4) {
                if (_0x5c2bb5['categoryId'] == 0x0 && _0x5c2bb5['level'] == _0x4e6d08)
                    return _0x5c2bb5;
            }
            for (const _0x555a9e of _0x2c1eb4) {
                if (_0x555a9e['categoryId'] == 0x0 && _0x555a9e['level'] == 0x0)
                    return _0x555a9e;
            }
        }
        return null;
    }
    ['getTextStyle'](_0x5a8c25, _0x562452, _0x43139f) {
        const _0x17d813 = this.#mapStyle?.['textMap']['get'](_0x5a8c25);
        if (_0x17d813) {
            for (const _0x3f76ab of _0x17d813) {
                if (_0x3f76ab['categoryId'] == _0x562452 && _0x3f76ab['level'] == _0x43139f)
                    return _0x3f76ab;
            }
            for (const _0x48f536 of _0x17d813) {
                if (_0x48f536['categoryId'] == _0x562452 && _0x48f536['level'] == 0x0)
                    return _0x48f536;
            }
            for (const _0x2ed87c of _0x17d813) {
                if (_0x2ed87c['categoryId'] == 0x0 && _0x2ed87c['level'] == _0x43139f)
                    return _0x2ed87c;
            }
            for (const _0x18a1eb of _0x17d813) {
                if (_0x18a1eb['categoryId'] == 0x0 && _0x18a1eb['level'] == 0x0)
                    return _0x18a1eb;
            }
        }
        return null;
    }
};
export default logi['maps']['Resource'];