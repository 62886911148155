<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="viewBox"
  >
    <g id="그룹_40428" data-name="그룹 40428" transform="translate(-46 -412)">
      <rect
        id="사각형_31201"
        data-name="사각형 31201"
        width="28"
        height="28"
        transform="translate(46 412)"
        fill="none"
      />
      <g id="clipboard" transform="translate(50.667 414.333)">
        <path
          id="패스_29341"
          data-name="패스 29341"
          d="M13.771,2a2.443,2.443,0,0,0-2.443,2.443H5.221A1.221,1.221,0,0,0,4,5.664V23.985a1.221,1.221,0,0,0,1.221,1.221h17.1a1.221,1.221,0,0,0,1.221-1.221V5.664a1.221,1.221,0,0,0-1.221-1.221H16.214A2.443,2.443,0,0,0,13.771,2Zm1.221,2.443a1.221,1.221,0,1,0-2.443,0V5.664H10.107V8.107h7.328V5.664H14.992ZM8.886,8.107V5.664H5.221V23.985h17.1V5.664H18.657V8.107a1.221,1.221,0,0,1-1.221,1.221H10.107A1.221,1.221,0,0,1,8.886,8.107Z"
          transform="translate(-4 -2)"
          :fill="iconColor"
          :stroke="iconColor"
          stroke-width="0.4"
          fill-rule="evenodd"
        />
        <path
          id="패스_29342"
          data-name="패스 29342"
          d="M19.214,11.221H7V10H19.214Z"
          transform="translate(-3.336 -0.229)"
          :fill="iconColor"
          :stroke="iconColor"
          stroke-width="0.4"
          fill-rule="evenodd"
        />
        <path
          id="패스_29343"
          data-name="패스 29343"
          d="M19.214,14.221H7V13H19.214Z"
          transform="translate(-3.336 0.435)"
          :fill="iconColor"
          :stroke="iconColor"
          stroke-width="0.4"
          fill-rule="evenodd"
        />
        <path
          id="패스_29344"
          data-name="패스 29344"
          d="M14.328,17.221H7V16h7.328Z"
          transform="translate(-3.336 1.099)"
          :fill="iconColor"
          :stroke="iconColor"
          stroke-width="0.4"
          fill-rule="evenodd"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'shipper',

  props: {
    viewBox: { type: String, default: '0 0 28 28' },
    iconName: { type: String, default: '' },
    width: { type: [Number, String], default: 28 },
    height: { type: [Number, String], default: 28 },
    iconColor: { type: String, default: '#ffffff' },
  },
}
</script>

<style scoped></style>
