var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-wrapper content_body" },
    [
      _c(
        "div",
        { staticClass: "content-inner" },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "pt-8 pb-0" },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "surface800 pt-1",
                      attrs: { size: "35" },
                      on: { click: _vm.onClickBack },
                    },
                    [_vm._v(" " + _vm._s(_vm.icons.mdiChevronLeft) + " ")]
                  ),
                  _c(
                    "span",
                    { staticClass: "font-size26 font-weight700 surface800" },
                    [_vm._v(" 화물 상세 정보 ")]
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "px-8 pb-8 pt-12" },
                [
                  _c(
                    "v-col",
                    { staticClass: "pa-0", attrs: { cols: "12", sm: "12" } },
                    [
                      _c(
                        "v-row",
                        { staticClass: "ma-0" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pa-0",
                              attrs: { cols: "12", md: "6", sm: "12" },
                            },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "transparent",
                                  attrs: { outlined: "" },
                                },
                                [
                                  _c(
                                    "v-card",
                                    {
                                      staticStyle: {
                                        "border-radius": "0 !important",
                                      },
                                      attrs: { outlined: "" },
                                    },
                                    [
                                      _c(
                                        "v-card-text",
                                        { staticClass: "pa-0" },
                                        [
                                          _c(
                                            "v-card",
                                            {
                                              staticClass:
                                                "my-card card-with-scroll",
                                            },
                                            [
                                              _c(
                                                "perfect-scrollbar",
                                                {
                                                  staticClass:
                                                    "ps-user-notifications",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "scroll-content",
                                                    },
                                                    [
                                                      _c("v-card-title", [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "flex-start-contents",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "font-size16 surface800 font-weight700",
                                                                staticStyle: {
                                                                  width: "30%",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " 화물 번호 "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "font-size16 surface800 mr-5",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.callRequestCode
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    staticClass:
                                                                      "ma-0 pa-0 font-size14 surface800 detail-btn",
                                                                    attrs: {
                                                                      color:
                                                                        "#D5D5D5",
                                                                      outlined:
                                                                        "",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        _vm.onClickFreightHistory,
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " 상세 이력 "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                      ]),
                                                      _vm._l(
                                                        _vm.info,
                                                        function (item, index) {
                                                          return [
                                                            _c(
                                                              "v-card-title",
                                                              {
                                                                key:
                                                                  "title_" +
                                                                  index,
                                                                staticClass:
                                                                  "font-size16 surface800 font-weight700",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.title
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-card-text",
                                                              _vm._l(
                                                                item.data,
                                                                function (
                                                                  _item,
                                                                  i
                                                                ) {
                                                                  return _item.show
                                                                    ? _c(
                                                                        "freight-info-item",
                                                                        {
                                                                          key:
                                                                            "data_" +
                                                                            index +
                                                                            "-" +
                                                                            i,
                                                                          attrs:
                                                                            {
                                                                              title:
                                                                                _item.title,
                                                                              content:
                                                                                _item.content,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _vm._e()
                                                                }
                                                              ),
                                                              1
                                                            ),
                                                          ]
                                                        }
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      !_vm.isHolding
                                        ? _c(
                                            "v-card-actions",
                                            {
                                              staticClass:
                                                "mt-4 d-flex justify-center",
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "px-2 font-size18 popup-btn1",
                                                  attrs: {
                                                    color: "#183256",
                                                    outlined: "",
                                                    disabled:
                                                      _vm.disabledCanceldFreight,
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.onClickChangeStatus,
                                                  },
                                                },
                                                [
                                                  _c("img", {
                                                    staticClass: "pr-1",
                                                    attrs: {
                                                      src: require("@/assets/icons/ic_setting.svg"),
                                                      width: "24",
                                                    },
                                                  }),
                                                  _vm._v(" 화물 상태 처리 "),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _c(
                                            "v-card-actions",
                                            { staticClass: "mt-4 btn-wrapper" },
                                            [
                                              _c("v-spacer"),
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "itgoWhite--text px-2 font-size18 popup-btn1",
                                                  attrs: {
                                                    depressed: "",
                                                    color: "itgoInfo",
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.onClickCompleteFreight,
                                                  },
                                                },
                                                [_vm._v(" 완료 처리 ")]
                                              ),
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "primary--text px-2 font-size18 popup-btn1",
                                                  attrs: {
                                                    depressed: "",
                                                    color: "itgoCancle",
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.onClickCancelFreight,
                                                  },
                                                },
                                                [_vm._v(" 취소 처리 ")]
                                              ),
                                              _c("v-spacer"),
                                            ],
                                            1
                                          ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pa-0",
                              attrs: { cols: "12", md: "6", sm: "12" },
                            },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "transparent",
                                  attrs: { outlined: "" },
                                },
                                [
                                  _c(
                                    "v-card-text",
                                    { staticClass: "pl-6 pr-0 pt-6" },
                                    [
                                      _c("freight-info-tab", {
                                        attrs: {
                                          "item-detail": _vm.callDetail,
                                        },
                                        on: { refresh: _vm.onRefresh },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("freight-history-popup", {
        attrs: { item: _vm.callDetail },
        on: { close: _vm.onCloseFreightHistory },
        model: {
          value: _vm.openFreightHistoryPopup,
          callback: function ($$v) {
            _vm.openFreightHistoryPopup = $$v
          },
          expression: "openFreightHistoryPopup",
        },
      }),
      _c("freight-status-change-popup", {
        attrs: { id: String(_vm.callDetail.callRequestId) },
        on: { change: _vm.onChangeStatus, close: _vm.onCloseChangeStatus },
        model: {
          value: _vm.openChangeStatusPopup,
          callback: function ($$v) {
            _vm.openChangeStatusPopup = $$v
          },
          expression: "openChangeStatusPopup",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }