var logi = logi ?? {};
logi['maps'] = logi['maps'] ?? {};
import a2_0x4a9226 from './logi-maps-utils.js';
logi['maps']['Utils'] = a2_0x4a9226, logi['maps']['Bridge'] = class {
    static ['loadFinished']() {
        if (logi['maps']['Utils']['isAndroid']())
            window['BRIDGE']['loadFinished']();
        else
            logi['maps']['Utils']['isIOS']() && window['webkit']['messageHandlers']['BRIDGE']['postMessage']('loadFinished');
    }
    static ['onMapEvent'](_0x5ef4e0, _0x5aead2 = -0x1, _0x144e5c = -0x1) {
        if (logi['maps']['Utils']['isAndroid']())
            window['BRIDGE']['onMapEvent'](_0x5ef4e0, _0x5aead2, _0x144e5c);
        else {
            if (logi['maps']['Utils']['isIOS']()) {
                const _0x520123 = {
                    'message': 'onMapEvent',
                    'type': _0x5ef4e0,
                    'pointX': _0x5aead2,
                    'pointY': _0x144e5c
                };
                window['webkit']['messageHandlers']['BRIDGE']['postMessage'](JSON['stringify'](_0x520123));
            }
        }
    }
    static ['onObjEvent'](_0x54a586) {
        if (logi['maps']['Utils']['isAndroid']())
            window['BRIDGE']['onObjEvent'](_0x54a586['type'], _0x54a586['source']['getKey'](), _0x54a586['point']['x'], _0x54a586['point']['y']);
        else {
            if (logi['maps']['Utils']['isIOS']()) {
                const _0x2bedf9 = {
                    'message': 'onObjEvent',
                    'type': _0x54a586['type'],
                    'key': _0x54a586['source']['getKey'](),
                    'pointX': _0x54a586['point']['x'],
                    'pointY': _0x54a586['point']['y']
                };
                window['webkit']['messageHandlers']['BRIDGE']['postMessage'](JSON['stringify'](_0x2bedf9));
            }
        }
    }
};
export default logi['maps']['Bridge'];