import _ from 'lodash'

/**
 * 차량 정보
 */
export = (() => {
  const _VEHICLE_TYPE: any = {
    CARGO_CAR: 1, // 카고
    TOP_CAR: 2, // 탑차
    WING_CAR: 3, // 윙바디
    TRAILER_CAR: 4, // 트레일러
    HORU_CAR: 5, // 호루
    BANGTONG_CAR: 6, // 방통차
    CARGO_WING_CAR: 7, // 카고/윙바디
  }

  const _VEHICLE_TYPE_STRING: any = {
    CARGO_CAR: '카고',
    TOP_CAR: '탑차',
    WING_CAR: '윙바디',
    TRAILER_CAR: '트레일러',
    HORU_CAR: '호루',
    BANGTONG_CAR: '방통차',
    CARGO_WING_CAR: '카고/윙바디',
  }

  Object.freeze(_VEHICLE_TYPE)
  Object.freeze(_VEHICLE_TYPE_STRING)

  const _VEHICLE_TYPE_WEB_NAME: Map<number, string> = new Map<number, string>([
    [_VEHICLE_TYPE.CARGO_CAR, _VEHICLE_TYPE_STRING.CARGO_CAR],
    [_VEHICLE_TYPE.TOP_CAR, _VEHICLE_TYPE_STRING.TOP_CAR],
    [_VEHICLE_TYPE.WING_CAR, _VEHICLE_TYPE_STRING.WING_CAR],
    [_VEHICLE_TYPE.TRAILER_CAR, _VEHICLE_TYPE_STRING.TRAILER_CAR],
    [_VEHICLE_TYPE.HORU_CAR, _VEHICLE_TYPE_STRING.HORU_CAR],
    [_VEHICLE_TYPE.BANGTONG_CAR, _VEHICLE_TYPE_STRING.BANGTONG_CAR],
    [_VEHICLE_TYPE.CARGO_WING_CAR, _VEHICLE_TYPE_STRING.CARGO_WING_CAR],
  ])

  return Object.freeze({
    CARGO_CAR: _VEHICLE_TYPE.CARGO_CAR,
    TOP_CAR: _VEHICLE_TYPE.TOP_CAR,
    WING_CAR: _VEHICLE_TYPE.WING_CAR,
    TRAILER_CAR: _VEHICLE_TYPE.TRAILER_CAR,
    HORU_CAR: _VEHICLE_TYPE.HORU_CAR,
    BANGTONG_CAR: _VEHICLE_TYPE.BANGTONG_CAR,
    CARGO_WING_CAR: _VEHICLE_TYPE.CARGO_WING_CAR,

    CARGO_CAR_STRING: _VEHICLE_TYPE_STRING.CARGO_CAR,
    TOP_CAR_STRING: _VEHICLE_TYPE_STRING.TOP_CAR,
    WING_CAR_STRING: _VEHICLE_TYPE_STRING.WING_CAR,
    TRAILER_CAR_STRING: _VEHICLE_TYPE_STRING.TRAILER_CAR,
    HORU_CAR_STRING: _VEHICLE_TYPE_STRING.HORU_CAR,
    BANGTONG_CAR_STRING: _VEHICLE_TYPE_STRING.BANGTONG_CAR,
    CARGO_WING_CAR_STRING: _VEHICLE_TYPE_STRING.CARGO_WING_CAR,

    VEHICLE_NAME_WEB: (vehicleIndex: number): string => {
      const vehicleName: string | undefined =
        _VEHICLE_TYPE_WEB_NAME.get(vehicleIndex)
      return vehicleName ? vehicleName : '-'
    },
  })
})()
