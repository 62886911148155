<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="viewBox"
  >
    <g id="그룹_40424" data-name="그룹 40424" transform="translate(-29 -135)">
      <g
        id="사각형_31249"
        data-name="사각형 31249"
        transform="translate(32 139.063)"
        fill="none"
        :stroke="iconColor"
        stroke-width="1.6"
      >
        <rect width="22" height="16" rx="1" stroke="none" />
        <rect x="0.8" y="0.8" width="20.4" height="14.4" rx="0.2" fill="none" />
      </g>
      <path
        id="패스_29412"
        data-name="패스 29412"
        d="M-1964,176.559v4.082"
        transform="translate(2006.644 -22.183)"
        :fill="iconColor"
        :stroke="iconColor"
        stroke-width="1.6"
      />
      <path
        id="패스_29413"
        data-name="패스 29413"
        d="M0,0V11.151"
        transform="translate(48.22 158.937) rotate(90)"
        :fill="iconColor"
        :stroke="iconColor"
        stroke-width="1.6"
      />
      <rect
        id="사각형_31250"
        data-name="사각형 31250"
        width="28"
        height="28"
        transform="translate(29 135)"
        fill="none"
      />
      <g
        id="사각형_31252"
        data-name="사각형 31252"
        transform="translate(42.551 142.656)"
        fill="none"
        :stroke="iconColor"
        stroke-linejoin="round"
        stroke-width="1.6"
      >
        <rect width="7.56" height="8.814" stroke="none" />
        <rect x="0.8" y="0.8" width="5.96" height="7.214" fill="none" />
      </g>
      <path
        id="패스_29419"
        data-name="패스 29419"
        d="M.024,1.6H4.711"
        transform="translate(36.252 142.656)"
        :fill="iconColor"
        :stroke="iconColor"
        stroke-width="1.6"
      />
      <g
        id="사각형_31255"
        data-name="사각형 31255"
        transform="translate(36.3 146.5)"
        fill="none"
        :stroke="iconColor"
        stroke-linejoin="round"
        stroke-width="1.6"
      >
        <rect width="4.7" height="5" stroke="none" />
        <rect x="0.8" y="0.8" width="3.1" height="3.4" fill="none" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'dashboard',

  props: {
    viewBox: { type: String, default: '0 0 28 28' },
    iconName: { type: String, default: '' },
    width: { type: [Number, String], default: 28 },
    height: { type: [Number, String], default: 28 },
    iconColor: { type: String, default: '#ffffff' },
  },
}
</script>

<style scoped></style>
