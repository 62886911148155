var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: _vm.width,
        height: _vm.height,
        viewBox: _vm.viewBox,
      },
    },
    [
      _c(
        "g",
        {
          attrs: {
            id: "그룹_40429",
            "data-name": "그룹 40429",
            transform: "translate(-46 -452)",
          },
        },
        [
          _c("rect", {
            attrs: {
              id: "사각형_31089",
              "data-name": "사각형 31089",
              width: "28",
              height: "28",
              transform: "translate(46 452)",
              fill: "none",
            },
          }),
          _c(
            "g",
            {
              attrs: {
                id: "그룹_40209",
                "data-name": "그룹 40209",
                transform: "translate(48.333 454.333)",
              },
            },
            [
              _c(
                "g",
                {
                  attrs: {
                    id: "패스_29420",
                    "data-name": "패스 29420",
                    transform: "translate(-0.333 -0.333)",
                    fill: "none",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M12,0A12,12,0,1,1,0,12,12,12,0,0,1,12,0Z",
                      stroke: "none",
                    },
                  }),
                  _c("path", {
                    attrs: {
                      d: "M 12 1.600000381469727 C 6.265420913696289 1.600000381469727 1.600000381469727 6.265420913696289 1.600000381469727 12 C 1.600000381469727 17.73458099365234 6.265420913696289 22.39999961853027 12 22.39999961853027 C 17.73458099365234 22.39999961853027 22.39999961853027 17.73458099365234 22.39999961853027 12 C 22.39999961853027 6.265420913696289 17.73458099365234 1.600000381469727 12 1.600000381469727 M 12 0 C 18.62742042541504 0 24 5.372579574584961 24 12 C 24 18.62742042541504 18.62742042541504 24 12 24 C 5.372579574584961 24 0 18.62742042541504 0 12 C 0 5.372579574584961 5.372579574584961 0 12 0 Z",
                      stroke: "none",
                      fill: _vm.iconColor,
                    },
                  }),
                ]
              ),
              _c(
                "g",
                {
                  attrs: {
                    id: "그룹_40207",
                    "data-name": "그룹 40207",
                    transform: "translate(7.12 6.98)",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      id: "패스_29342",
                      "data-name": "패스 29342",
                      d: "M7.4,0H0V1.3H7.4Z",
                      transform: "translate(7.794 9.443) rotate(-90)",
                      fill: _vm.iconColor,
                      stroke: _vm.iconColor,
                      "stroke-width": "0.4",
                      "fill-rule": "evenodd",
                    },
                  }),
                  _c("path", {
                    attrs: {
                      id: "패스_29343",
                      "data-name": "패스 29343",
                      d: "M10.393,0H0V1.3H10.393Z",
                      transform: "translate(3.897 9.443) rotate(-90)",
                      fill: _vm.iconColor,
                      stroke: _vm.iconColor,
                      "stroke-width": "0.4",
                      "fill-rule": "evenodd",
                    },
                  }),
                  _c("path", {
                    attrs: {
                      id: "패스_29344",
                      "data-name": "패스 29344",
                      d: "M5.2,0H0V1.3H5.2Z",
                      transform: "translate(0 9.443) rotate(-90)",
                      fill: _vm.iconColor,
                      stroke: _vm.iconColor,
                      "stroke-width": "0.4",
                      "fill-rule": "evenodd",
                    },
                  }),
                ]
              ),
              _c(
                "g",
                {
                  attrs: {
                    id: "그룹_40208",
                    "data-name": "그룹 40208",
                    transform: "translate(14 12.833)",
                  },
                },
                [
                  _c("line", {
                    attrs: {
                      id: "선_926",
                      "data-name": "선 926",
                      x2: "2.383",
                      y2: "2.67",
                      transform: "translate(7.534 8.413)",
                      fill: "none",
                      stroke: _vm.iconColor,
                      "stroke-linejoin": "round",
                      "stroke-width": "1.6",
                    },
                  }),
                  _c(
                    "g",
                    {
                      attrs: {
                        id: "타원_2608",
                        "data-name": "타원 2608",
                        transform: "translate(-0.333 -0.167)",
                        fill: "#081A38",
                        stroke: _vm.iconColor,
                        "stroke-width": "1.5",
                      },
                    },
                    [
                      _c("circle", {
                        attrs: {
                          cx: "5.5",
                          cy: "5.5",
                          r: "5.5",
                          stroke: "none",
                        },
                      }),
                      _c("circle", {
                        attrs: {
                          cx: "5.5",
                          cy: "5.5",
                          r: "4.75",
                          fill: "none",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }