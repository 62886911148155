import axios from 'axios'
import _ from 'lodash'

class ExternalService {
  // reverseGeocoding(x, y) {
  //   const REVERSE_GEOCODING_URL = '/external/reverse-geocoding?'

  //   const params = {
  //     xpos: x,
  //     ypos: y,
  //   }

  //   return axios
  //     .get(REVERSE_GEOCODING_URL, { params: params })
  //     .then((response) => response.data)
  //     .then((result) => {
  //       return result.searchcoord[0]
  //     })
  // }

  extSearchCoord(xpos, ypos) {
    const EXT_SEARCH_COORD_URL = '/external/extsearchcoord?'

    const params: any = _.omitBy(
      {
        xpos: xpos,
        ypos: ypos,
      },
      _.isNil.bind(_),
    )

    return axios
      .get(EXT_SEARCH_COORD_URL, { params: params })
      .then((response) => {
        return Promise.resolve(response.data)
      })
  }

  extSearch(xpos, ypos, keyword, sort) {
    const EXT_SEARCH_URL = '/external/extsearch?'

    const params: any = _.omitBy(
      {
        xpos: xpos,
        ypos: ypos,
        keyword: keyword,
        sort: sort,
      },
      _.isNil.bind(_),
    )

    return axios.get(EXT_SEARCH_URL, { params: params }).then((response) => {
      return Promise.resolve(response.data)
    })
  }

  route(startPoint, endPoint) {
    const body = {
      source: startPoint,
      destinations: endPoint,
    }

    const EXT_ROUTE_URL = '/external/route'

    return axios.post(EXT_ROUTE_URL, body).then((response) => {
      return Promise.resolve(response.data)
    })
  }
}
export default new ExternalService()

function ce() {
  throw new Error('Function not implemented.')
}
