



























import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { mdiMenuUp, mdiMenuDown, mdiMinus } from '@mdi/js'
import Vue2DatePicker from 'vue2-datepicker'
import DriverService from '@/services-itgo/driver.service'
import DateMonthPicker from '@/views/utils/DateMonthPicker.vue'
import DashBoardSimpleTable from '@/views/dashBoards/DashBoardSimpleTable.vue'
import DatetimeUtils from '@/utils/datetime.utils'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ko'
import moment from 'moment'
import _ from 'lodash'

@Component({
  name: "BrokerDriverRegRanking",
  components: {
    DashBoardSimpleTable,
    Vue2DatePicker,
  },
})
export default class BrokerDriverRegRanking extends Vue {
  public icons: any = {
    mdiMenuUp,
    mdiMenuDown,
    mdiMinus,
  }

  public brokerRankingData: any = []

  month: any = null
  monthDate = new Date()

  mounted() {
    this.month = DatetimeUtils.now_format('YYYY-MM')
    this.updateBrokerDriverRankingList()
  }

  // vue2-date-picker
  notBeforeToday(date) {
    return date > new Date(new Date().setHours(0, 0, 0, 0))
  }
  notBeforeTodayTwelveOClock(date) {
    return date > new Date(new Date().setHours(12, 0, 0, 0))
  }

  async updateBrokerDriverRankingList() {
    try {
      const params = {
        month: this.month,
      }
      const result = await DriverService.getDriverMemberOrder(params)
      this.brokerRankingData = _.slice(result,0,9)
      console.log(result)
    } catch (e) {
      console.log(e)
    }
  }

  setInputDate(value) {
    this.month = moment(value).format('YYYY-MM')
    this.updateBrokerDriverRankingList()
  }
}
