/**
 * 결제 수단 (카드, 가상계좌, 후불)
 */
const SVC_DATA: any = (function () {
  enum _SVC_DOMAIN {
    DRIVER_MAIN = 0,
    DRIVER_CALL = 1,
    BROKER_MAIN = 2,
    BROKER_CALL = 3,
    CALLER_MAIN = 4,
    CALLER_CALL = 5,
  }

  const _SVC_DOMAIN_STRING : any = {
    DRIVER_MAIN : 'driver_main',
    DRIVER_CALL : 'driver_call',
    BROKER_MAIN : 'broker_main',
    BROKER_CALL : 'broker_call',
    CALLER_MAIN : 'caller_main',
    CALLER_CALL : 'caller_call',
  }

  const _SCV_DOMAIN_STRING_NAME : Map<number, string> = new Map<number, string>(
    [
      [_SVC_DOMAIN.DRIVER_MAIN, _SVC_DOMAIN_STRING.DRIVER_MAIN],
      [_SVC_DOMAIN.DRIVER_CALL, _SVC_DOMAIN_STRING.DRIVER_CALL],
      [_SVC_DOMAIN.BROKER_MAIN, _SVC_DOMAIN_STRING.BROKER_MAIN],
      [_SVC_DOMAIN.BROKER_CALL, _SVC_DOMAIN_STRING.BROKER_CALL],
      [_SVC_DOMAIN.CALLER_MAIN, _SVC_DOMAIN_STRING.CALLER_MAIN],
      [_SVC_DOMAIN.CALLER_CALL, _SVC_DOMAIN_STRING.CALLER_CALL],
    ]
  )

  const _SCV_DOMAIN_KOR_STRING_NAME : Map<string, string> = new Map<string, string>(
    [
      [_SVC_DOMAIN_STRING.DRIVER_MAIN, '차주앱_주요화면'],
      [_SVC_DOMAIN_STRING.DRIVER_CALL, '차주앱_운송기능'],
      [_SVC_DOMAIN_STRING.BROKER_MAIN, '주선사웹_주요화면'],
      [_SVC_DOMAIN_STRING.BROKER_CALL, '주선사웹_화물등록'],
      [_SVC_DOMAIN_STRING.CALLER_MAIN, '화주앱_주요화면'],
      [_SVC_DOMAIN_STRING.CALLER_CALL, '화주앱_화물'],
    ]
  )

  return {

    NAME_SVC_DOMAIN_STRING: (domainStatus : number) : string => {
      const domainString : string | undefined =
        _SCV_DOMAIN_STRING_NAME.get(domainStatus)
      return domainString ? domainString : 'driver_main'
    },

    NAME_SVC_DOMAIN_KOR_STRING: (domainStatus : string) : string => {
      const domainString : string | undefined =
        _SCV_DOMAIN_KOR_STRING_NAME.get(domainStatus)
      return domainString ? domainString : '차주앱_주요화면'
    }

  }
})()

export { SVC_DATA }