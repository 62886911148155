



























import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import { mdiCloseCircle } from '@mdi/js'

@Component({
  name: 'PdfViewPopup',
  components: {
    VuePdfEmbed,
    PerfectScrollbar },
})
export default class PdfViewPopup extends Vue {
  @Prop(Boolean) value
  @Prop(String) pdfUrl

  public icons: any = { mdiCloseCircle }

  cancelBtn() {
    this.$emit('closePdfView', null)
  }

  perfectScrollbarOptions: any = {
    maxScrollbarLength: 30,
    wheelPropagation: false,
  }
}
