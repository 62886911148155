




















































































import { Vue, Component, Watch } from 'vue-property-decorator'
import Pagination from '@/views/utils/Pagination.vue'
import { mdiMagnify, mdiChevronDown } from '@mdi/js'
import { State, Mutation } from 'vuex-class'
import callerService from '@/services-itgo/caller.service'
import USER_STATUS from '@/views/constant/user.status'

@Component({
  components: {
    Pagination,
  },
  created(){
    this.$router.beforeEach((from, to ,next)=>{
      if(from.name === 'callerInfo' && to.name === 'callerManagement'){
        this.setIsManageFilterStatus({type: 'callerMng', data: true})
      }
      next()
    })
  },
  mounted() {
    if (this.isCallerMng) {
      this.keyword = this.filterData.keyword
      this.pageNum = this.filterData.pageNum
      this.pageSize = this.filterData.pageSize
      this.setIsManageFilterStatus({type: 'callerMng', data: false})
    }

    this.fetchCallerList()
  },
})
export default class CallerManagement extends Vue {
  @State((state) => state.auth.user) loginUser
  @State((state) => state.pageFilter.status.isCallerMng) isCallerMng
  @State((state) => state.pageFilter.filter.callerMngFilter) filterData
  @Mutation('pageFilter/setIsManageFilterStatus') setIsManageFilterStatus
  @Mutation('pageFilter/setManageFilterData') setManageFilterData

  icons: any = {
    mdiMagnify,
    mdiChevronDown,
  }

  keyword = ''

  callerList = {
    loading: false,
    headers: [
      { text: '화주명', value: 'name', align: 'center', width: '10%' },
      { text: '전화번호', value: 'mobile', align: 'center', width: '10%' },
      { text: 'ID', value: 'loginId', align: 'center', width: '10%' },
      { text: '총 화물', value: 'total', align: 'center', width: '15%' },
      {
        text: '배차 진행',
        value: 'assignable',
        align: 'center',
        width: '10%',
      },
      {
        text: '배차 완료',
        value: 'assigned',
        align: 'center',
        width: '8%',
      },
      {
        text: '운송 중',
        value: 'transporting',
        align: 'center',
        width: '8%',
      },
      { text: '운송 완료', value: 'complete', align: 'center', width: '5%' },
      { text: '상태', value: 'usetStatus', align: 'center', width: '10%' },
    ],
    items: [],
    serverItemsLength: 0,
  }

  get totalPageCount() {
    const length = Math.ceil(this.callerList.serverItemsLength / this.pageSize)
    return length === 0 ? 1 : length
  }

  pageSizes = [10, 20, 30]
  pageSize = 10
  pageNum = 1

  /**
   * pageSize: 페이지 크기
   * pageNum: 페이지 번호
   */
  @Watch('pageSize')
  onChangePageSize() {
    this.pageNum = 1
    this.fetchCallerList()
  }

  @Watch('pageNum')
  onChangePageNum() {
    this.fetchCallerList()
  }

  async fetchCallerList() {
    try {
      this.callerList.loading = true
      this.callerList.items = []
      //this.callerList.serverItemsLength = 0

      const params = {
        keyword: this.keyword,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      }

      this.setManageFilterData({ type :'callerMng', data: params })

      const data = await callerService.getCallerList(params)

      this.callerList.items = data.callerList.map((item, index) => ({
        ...item,
        usetStatus: USER_STATUS.GET_USER_STATUS(item.status),
        key: `${item.name}_${item.mobile}_${index}`,
      }))
      this.callerList.serverItemsLength = data.totalCount
    } catch (error: any) {
      this.$alert(error.response.data.errMsg)
    } finally {
      this.callerList.loading = false
    }
  }

  onSearch() {
    this.pageNum = 1
    this.fetchCallerList()
  }

  onKeyupKeyword() {
    if (this.validateName(this.keyword)) return

    this.keyword = this.keyword
      .replace(/[^ㄱ-ㅎ가-힣0-9]/g, '')
      .replace(/\s/g, '')
  }

  validateName(value: string) {
    const regExp = /^[ㄱ-ㅎ|가-힣|0-9]+$/

    return regExp.test(value)
  }

  onClickRow(item) {
    this.$router.push({
      name: 'callerInfo',
      params: { item },
    })
  }
}
