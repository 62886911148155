var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex-between-contents terms-title" },
    [
      _c("div", { staticClass: "flex-between-contents" }, [
        _c("div", { staticClass: "font-size16 surface800" }, [
          _vm._v(" " + _vm._s(_vm.title) + " "),
        ]),
        _c("div", { staticClass: "flex-end-contents-basic" }, [
          _c("div", { staticClass: "font-size16 surface500" }, [
            _vm._v(" " + _vm._s(_vm.content) + " "),
          ]),
          _vm.isBtn
            ? _c(
                "div",
                { staticClass: "ml-4" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "terms-btn",
                      staticStyle: { "box-shadow": "none !important" },
                      attrs: { small: "" },
                      on: { click: _vm.editPopup },
                    },
                    [_vm._v(" 변경 ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "terms-btn ml-2",
                      staticStyle: { "box-shadow": "none !important" },
                      attrs: { small: "" },
                      on: { click: _vm.historyPopup },
                    },
                    [_vm._v(" 변경이력 ")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
      _vm._t("default"),
      _c(
        "v-dialog",
        {
          key: _vm.componentKey.termsEditPopupKey,
          staticClass: "v-dialog",
          attrs: { "max-width": "551", persistent: "" },
          model: {
            value: _vm.termsEditPopup,
            callback: function ($$v) {
              _vm.termsEditPopup = $$v
            },
            expression: "termsEditPopup",
          },
        },
        [
          _c("terms-edit-popup", {
            attrs: {
              "terms-type": _vm.termsType,
              "user-id": _vm.userId,
              type: _vm.type,
            },
            on: {
              editPopupHide: _vm.editPopupHide,
              updateUserInfo: _vm.updateUserInfo,
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }