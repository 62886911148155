var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "find" }, [
    _c(
      "div",
      { staticClass: "find__inner" },
      [
        _vm._m(0),
        _vm._m(1),
        _c("v-simple-table", {
          staticClass: "justify-center",
          staticStyle: {
            background: "#f9fafa",
            "border-radius": "10px",
            width: "100%",
            padding: "12px 32px",
            "margin-top": "55px",
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function () {
                return [
                  _c(
                    "tbody",
                    _vm._l(_vm.desserts, function (item) {
                      return _c(
                        "tr",
                        {
                          key: item.name,
                          staticStyle: { height: "30px !important" },
                        },
                        [
                          _c("td", { staticStyle: { width: "30%" } }, [
                            _vm._v(_vm._s(item.title)),
                          ]),
                          _c("td", { staticStyle: { width: "70%" } }, [
                            _vm._v(_vm._s(item.info)),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        }),
        _c(
          "div",
          { staticClass: "find__btn-fixed" },
          [_c("v-btn", { staticClass: "btnNavy-48" }, [_vm._v("돌아가기")])],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logo__inner" }, [
      _c("div", { staticClass: "logo" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "find-text" }, [
      _c("h2", [_vm._v("아이디 찾기")]),
      _c("p", [_vm._v("아이디 찾기가 완료되었습니다.")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }