


















import { Vue, Component, Watch, Prop } from 'vue-property-decorator'

@Component({
  name: 'FreightMemoPopup',
  components: {},
})
export default class CircularProgressPopup extends Vue {
  @Prop(Boolean) value
  @Prop(String) contentText
}
