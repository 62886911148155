

































































































































































































































































































































































































































































































































































































import { Vue, Component, Watch, Ref } from 'vue-property-decorator'
import DatePicker from '@/views/utils/DatePicker.vue'
import Pagination from '@/views/utils/Pagination.vue'
import { mdiMagnify, mdiChevronDown, mdiChevronLeft, mdiImage } from '@mdi/js'
import eventService from '@/services-itgo/event.service'
import SendPopup from '@/views/popup/551X792/SendPopup.vue'
import LoadPopup from '@/views/popup/551X792/LoadPopup.vue'
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
import DateTimeUtil from '@/utils/datetime.utils'
import DatetimeUtils from '@/utils/datetime.utils'
import StringUtils from '@/utils/string.utils'
//@ts-ignore
import Vue2DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ko'
import moment from 'moment'
import { CONTROLLER_ROLE } from '@/views/constant/user.role.constant'
import { State } from 'vuex-class'
import _ from 'lodash'

@Component({
  name: 'EventManagement',
  components: {
    DatePicker,
    Pagination,
    SendPopup,
    LoadPopup,
    VueTimepicker,
    Vue2DatePicker,
  },
  beforeRouteLeave(to, from, next) {
    // 현재 라우트가 공지사항 상세보기(toggleEvent === 1)일 때만 확인
    if (this.toggleEvent === 1) {
      // toggleEvent를 0으로 설정하고 목록 라우트로 이동
      this.toggleEvent = 0
      next(false) // 이동을 경로 막기
    } else {
      next() // 다른 상태에서는 그대로 라우트 변경을 허용
    }
  },
})
export default class EventManagement extends Vue {
  @State((state) => state.auth.user) loginUser
  created() {
    this.fetchPageList()
  }

  public icons: any = {
    mdiMagnify,
    mdiChevronDown,
    mdiChevronLeft,
    mdiImage,
  }

  keyWord = ''

  eventTarget = null
  eventTargetCreateItems: any = [
    { name: '공통', value: 0 },
    { name: '화주', value: 1 },
    { name: '차주', value: 2 },
    { name: '주선사', value: 3 },
  ]
  eventTargetItems: any = [
    { name: '전체', value: null },
    ...this.eventTargetCreateItems,
  ]

  date: any = []

  @Watch('date')
  changeDates(value) {
    if (value?.length > 1) {
      this.set(value)
    }
  }

  previousDayDate: Date[] | null = null

  async set(value) {
    const temp = [...this.date]
    temp.sort((a, b) => new Date(a).getTime() - new Date(b).getTime())

    if (temp.length === 2) {
      if (DatetimeUtils.asDays(temp[0], temp[1]) >= 30) {
        this.date = this.previousDayDate
        await this.$alert({
          title: '기간 설정 오류',
          content: '기간 설정은 최대 30일까지 가능합니다.',
        })
      } else {
        this.previousDayDate = [...this.date]
      }
    }
  }

  get pageLength() {
    const length = Math.ceil(this.eventInfo.serverItemsLength / this.pageSize)
    return length === 0 ? 1 : length
  }

  isSearched = false

  totalPageCount = 1
  pageSizes = [10, 20, 30]
  pageSize = 10
  pageNum = 1

  public pickUpConfigTime: any = {
    start: '00:30',
    step: '00:30',
    end: '23:30',
  }

  /**
   * pageSize: 페이지 크기
   * pageNum: 페이지 번호
   */
  @Watch('pageSize')
  onChangePageSize() {
    this.pageNum = 1
    this.fetchPageList()
  }

  @Watch('pageNum')
  onChangePageNum() {
    this.fetchPageList()
  }

  expanded: any = []

  eventInfo = {
    loading: false,
    headers: [
      { text: '번호', align: 'center', value: 'eventId', width: '10%' },
      { text: '이벤트 대상', align: 'center', value: 'target', width: '10%' },
      { text: '제목', align: 'start', value: 'title', width: '40%' },
      { text: '작성자', align: 'center', value: 'userName', width: '10%' },
      { text: 'PUSH', align: 'center', value: 'appPushedDt', width: '10%' },
      // MODIFY: SMS 연동 시 복구
      // { text: 'SMS', align: 'center', value: 'smsPushedDt', width: '10%' },
      { text: '등록일시', align: 'center', value: 'regDt', width: '15%' },
    ],
    items: [] as any[],
    serverItemsLength: 10,
  }

  onSearch() {
    this.isSearched = true
    this.pageNum = 1
    this.fetchPageList()
  }

  async fetchPageList() {
    try {
      this.eventInfo.loading = true

      const params = {
        eventTargetList: this.eventTarget,
        keyword: _.isEmpty(this.keyWord) ? null : this.keyWord,
        fromDt: this.date[0]
          ? DatetimeUtils.setTypeStartDate(this.date[0], 'hour')
          : null,
        toDt: this.date[1]
          ? DatetimeUtils.setTypeEndDate(this.date[1], 'hour')
          : null,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        temporary: 0,
      }

      const res = await eventService.getPageList(params)

      this.eventInfo.items = res.content.map((x) => ({
        ...x,
        target:
          this.eventTargetItems.find((y) => y.value === x.eventTarget)?.name ||
          '-',
      }))

      this.eventInfo.serverItemsLength = res.totalElementCount
      this.totalPageCount = this.pageLength
    } catch (err) {
      console.error(err)
    } finally {
      this.eventInfo.loading = false
    }
  }

  /**
   * 이벤트사항 - 새 페이지
   */

  selected: any = {}
  categoryDetail: any = {}
  eventTargetCreate = 0

  // 리스트: 0, 내용: 1, 작성: 2
  toggleEvent = 0

  backToList() {
    this.toggleEvent = 0
    this.selected = {}
    this.file = {}
    this.clearCreateContent()
    this.fetchPageList()
  }

  async backKeyToList() {
    console.log(this.isChangedContents)
    if (this.isChangedContents) {
      const result = await this.$confirm({
        title: '종료 안내',
        content:
          '변경 또는 작성 중인 내용이 있습니다.<br/> 팝업을 닫으면 변경 또는 작성 중인<br/> 내용은 저장되지 않습니다',
      })
      if (result) {
        this.toggleEvent = 0
        this.selected = {}
        this.file = {}
        this.clearCreateContent()
        await this.fetchPageList()
      }
    } else {
      this.toggleEvent = 0
      await this.fetchPageList()
      this.clearCreateContent()
    }
  }

  addOneMonth(date = new Date()) {
    date.setDate(date.getDate() + 30)
    return date
  }

  eventContents = {
    title: '',
    content: '',
    eventTargetCreate: 0,
    defaultCheck: true,
    isOpenReserve: false,
    isPushReserve: false,
    openDate: {
      createDate: new Date(),
      selectedTime: this.setSelectTime,
    },
    closeDate: {
      createDate: new Date(),
      selectedTime: this.setEndDateTime,
    },
    pushStartDate: {
      createDate: new Date(),
      selectedTime: this.setSelectTime,
    },
    pushEndDate: {
      createDate: this.addOneMonth(),
      selectedTime: this.setEndDateTime,
    },
    promotionUrl: '',
  }

  formattedText(content) {
    return StringUtils.formattedContentText(content)
  }

  get setSelectTime() {
    let currentDate = new Date()
    let currentMinutes = currentDate.getMinutes()
    let adjustedMinutes = Math.floor(currentMinutes / 30) * 30 + 30
    currentDate.setMinutes(adjustedMinutes)
    return currentDate
  }

  get setEndDateTime() {
    let currentDate = new Date(Date.now() + 60 * 60 * 1000)
    let currentMinutes = currentDate.getMinutes()
    let adjustedMinutes = Math.floor(currentMinutes / 30) * 30 + 30
    currentDate.setMinutes(adjustedMinutes)
    return currentDate
  }

  get formattedOpenDateTime() {
    const date = DatetimeUtils.stringDate(
      this.eventContents.openDate.createDate,
      'YYYY-MM-DD',
    )
    const time = DatetimeUtils.stringTimerDate(
      this.eventContents.openDate.selectedTime,
    )

    return `${date} ${time}`
  }

  get formattedCloseDateTime() {
    const date = DatetimeUtils.stringDate(
      this.eventContents.closeDate.createDate,
      'YYYY-MM-DD',
    )
    const time = DatetimeUtils.stringTimerDate(
      this.eventContents.closeDate.selectedTime,
    )

    return `${date} ${time}`
  }

  get formattedPushStartDateTime() {
    const date = DatetimeUtils.stringDate(
      this.eventContents.pushStartDate.createDate,
      'YYYY-MM-DD',
    )
    const time = DatetimeUtils.stringTimerDate(
      this.eventContents.pushStartDate.selectedTime,
    )

    return `${date} ${time}`
  }

  get formattedPushEndDateTime() {
    const date = DatetimeUtils.stringDate(
      this.eventContents.pushEndDate.createDate,
      'YYYY-MM-DD',
    )
    const time = DatetimeUtils.stringTimerDate(
      this.eventContents.pushEndDate.selectedTime,
    )

    return `${date} ${time}`
  }

  isChangedContents: boolean = false

  @Watch('eventContents.openDate', { deep: true })
  changeOpenDate(value) {
    const currentDiff =
      DatetimeUtils.stringDate(value.createDate, 'YYYY-MM-DD') ===
      DatetimeUtils.now_format('YYYY-MM-DD')

    const durationDiff =
      DatetimeUtils.stringDate(value.createDate, 'YYYY-MM-DD') ===
      DatetimeUtils.stringDate(
        this.eventContents.closeDate.createDate,
        'YYYY-MM-DD',
      )

    if (!this.editMode) {
      if (moment().diff(value.createDate, 'days') > 0) {
        // 현재보다 과거 날짜 선택시
        this.$toast({
          type: 'error',
          content: '발송날짜/시간을 다시 확인해 주세요',
        })
        this.clearDatePicker('openDate')
      } else if (
        currentDiff &&
        moment().diff(value.selectedTime, 'minutes') > 0
      ) {
        // 현재 날짜에 과거 시간 선택시
        this.$toast({
          type: 'error',
          content: '발송날짜/시간을 다시 확인해 주세요',
        })
        this.clearDatePicker('openDate')
      } else if (
        moment(this.eventContents.closeDate.createDate).diff(
          value.createDate,
          'days',
        ) < 0
      ) {
        // closeDate보다 미래날짜를 선택 시
        this.$toast({
          type: 'error',
          content: '설정 날짜/시간을 다시 확인해 주세요',
        })
        this.clearDatePicker('openDate')
      } else if (
        durationDiff &&
        moment(this.eventContents.closeDate.selectedTime).diff(
          value.selectedTime,
          'minutes',
        ) < 0
      ) {
        // closeDate와 같은 날짜에 미래 시간 선택시
        this.$toast({
          type: 'error',
          content: '설정 날짜/시간을 다시 확인해 주세요',
        })
        this.clearDatePicker('openDate')
      }
    }
  }

  @Watch('eventContents.closeDate', { deep: true })
  changeCloseDate(value) {
    const currentDiff =
      DatetimeUtils.stringDate(value.createDate, 'YYYY-MM-DD') ===
      DatetimeUtils.now_format('YYYY-MM-DD')
    const durationDiff =
      DatetimeUtils.stringDate(value.createDate, 'YYYY-MM-DD') ===
      DatetimeUtils.stringDate(
        this.eventContents.openDate.createDate,
        'YYYY-MM-DD',
      )

    if (!this.editMode) {
      if (moment().diff(value.createDate, 'days') > 0) {
        // 현재보다 과거 날짜 선택시
        this.$toast({
          type: 'error',
          content: '설정 날짜/시간을 다시 확인해 주세요',
        })
        this.clearDatePicker('closeDate')
        return
      } else if (
        currentDiff &&
        moment().diff(value.selectedTime, 'minutes') > 0
      ) {
        // 현재 날짜에 과거 시간 선택시
        this.$toast({
          type: 'error',
          content: '설정 날짜/시간을 다시 확인해 주세요',
        })
        this.clearDatePicker('closeDate')
      } else if (
        moment(value.createDate).diff(
          this.eventContents.openDate.createDate,
          'days',
        ) < 0
      ) {
        // openDate보다 과거날짜를 선택 시
        this.$toast({
          type: 'error',
          content: '설정 날짜/시간을 다시 확인해 주세요',
        })
        this.clearDatePicker('closeDate')
      } else if (
        durationDiff &&
        moment(value.selectedTime).diff(
          this.eventContents.openDate.selectedTime,
          'minutes',
        ) < 0
      ) {
        // openDate와 같은 날짜에 과거 시간 선택시
        this.$toast({
          type: 'error',
          content: '설정 날짜/시간을 다시 확인해 주세요',
        })
        this.clearDatePicker('closeDate')
      }
    }
  }

  @Watch('eventContents.pushStartDate', { deep: true })
  changePushStartDate(value) {
    const currentDiff =
      DatetimeUtils.stringDate(value.createDate, 'YYYY-MM-DD') ===
      DatetimeUtils.now_format('YYYY-MM-DD')

    const durationDiff =
      DatetimeUtils.stringDate(value.createDate, 'YYYY-MM-DD') ===
      DatetimeUtils.stringDate(
        this.eventContents.pushEndDate.createDate,
        'YYYY-MM-DD',
      )

    if (!this.editMode) {
      if (moment().diff(value.createDate, 'days') > 0) {
        // 현재보다 과거 날짜 선택시
        this.$toast({
          type: 'error',
          content: '설정 날짜/시간을 다시 확인해 주세요',
        })
        this.clearDatePicker('pushStartDate')
      } else if (
        currentDiff &&
        moment().diff(value.selectedTime, 'minutes') > 0
      ) {
        // 현재 날짜에 과거 시간 선택시
        this.$toast({
          type: 'error',
          content: '설정 날짜/시간을 다시 확인해 주세요',
        })
        this.clearDatePicker('pushStartDate')
      } else if (
        moment(this.eventContents.pushEndDate.createDate).diff(
          value.createDate,
          'days',
        ) < 0
      ) {
        // endDate보다 미래날짜를 선택 시
        this.$toast({
          type: 'error',
          content: '설정 날짜/시간을 다시 확인해 주세요',
        })
        this.clearDatePicker('pushStartDate')
      } else if (
        durationDiff &&
        moment(this.eventContents.pushEndDate.selectedTime).diff(
          value.selectedTime,
          'minutes',
        ) < 0
      ) {
        // endDate와 같은 날짜에 미래 시간 선택시
        this.$toast({
          type: 'error',
          content: '설정 날짜/시간을 다시 확인해 주세요',
        })
        this.clearDatePicker('pushStartDate')
      }
    }
  }

  @Watch('eventContents.pushEndDate', { deep: true })
  changePushSEndDate(value) {
    const currentDiff =
      DatetimeUtils.stringDate(value.createDate, 'YYYY-MM-DD') ===
      DatetimeUtils.now_format('YYYY-MM-DD')
    const durationDiff =
      DatetimeUtils.stringDate(value.createDate, 'YYYY-MM-DD') ===
      DatetimeUtils.stringDate(
        this.eventContents.pushStartDate.createDate,
        'YYYY-MM-DD',
      )

    if (!this.editMode) {
      if (moment().diff(value.createDate, 'days') > 0) {
        // 현재보다 과거 날짜 선택시
        this.$toast({
          type: 'error',
          content: '설정 날짜/시간을 다시 확인해 주세요',
        })
        this.clearDatePicker('pushEndDate')
        return
      } else if (
        currentDiff &&
        moment().diff(value.selectedTime, 'minutes') > 0
      ) {
        // 현재 날짜에 과거 시간 선택시
        this.$toast({
          type: 'error',
          content: '설정 날짜/시간을 다시 확인해 주세요',
        })
        this.clearDatePicker('pushEndDate')
      } else if (
        moment(value.createDate).diff(
          this.eventContents.pushStartDate.createDate,
          'days',
        ) < 0
      ) {
        // startDate보다 과거날짜를 선택 시
        this.$toast({
          type: 'error',
          content: '설정 날짜/시간을 다시 확인해 주세요',
        })
        this.clearDatePicker('pushEndDate')
      } else if (
        durationDiff &&
        moment(value.selectedTime).diff(
          this.eventContents.pushStartDate.selectedTime,
          'minutes',
        ) < 0
      ) {
        // startDate와 같은 날짜에 과거 시간 선택시
        this.$toast({
          type: 'error',
          content: '설정 날짜/시간을 다시 확인해 주세요',
        })
        this.clearDatePicker('pushEndDate')
      }
    }
  }

  @Watch('eventContents', { deep: true })
  changedEventContents(value) {
    console.log(value)
    console.log(this.toggleEvent)
    if (this.toggleEvent === 0) this.isChangedContents = false
    else this.isChangedContents = true
    console.log(this.isChangedContents)
  }

  get isBulletinWritable() {
    if (CONTROLLER_ROLE.isBulletinWritable(this.loginUser.controllerRole))
      return true
    else return false
  }

  clearCreateContent() {
    this.eventContents.defaultCheck = true
    this.eventContents.title = ''
    this.eventContents.content = ''
    this.eventContents.eventTargetCreate = 0
    ;(this.eventContents.isOpenReserve = false),
      (this.eventContents.isPushReserve = false),
      (this.eventContents.openDate.selectedTime = this.setSelectTime),
      (this.eventContents.closeDate.selectedTime = this.setSelectTime)
    this.eventContents.openDate.createDate = new Date()
    this.eventContents.closeDate.createDate = new Date()
    this.eventContents.pushStartDate.selectedTime = this.setSelectTime
    this.eventContents.pushStartDate.createDate = new Date()
    this.eventContents.pushEndDate.selectedTime = this.setEndDateTime
    this.eventContents.pushEndDate.createDate = this.addOneMonth()
    this.eventContents.promotionUrl = ''

    // 수정 시
    this.editEventId = null
  }

  public eventMode = 'create'

  editEventId = null
  editMode = false

  async onEdit(item) {
    this.eventMode = 'edit'
    this.editMode = true

    try {
      this.editEventId = item.eventId
      const eventId = item.eventId
      const result = await eventService.getEventDetails(eventId)

      if (result.postStartDt == null && result.postEndDt == null) {
        this.eventContents.defaultCheck = true
        this.eventContents.isOpenReserve = false
      } else {
        this.eventContents.defaultCheck = false
        this.eventContents.isOpenReserve = true
      }

      if (result.files && result.files.length > 0) {
        const fileData = result.files[0]
        const blob = await this.downloadFile(fileData.location)
        this.file = new File([blob], fileData.name, {
          type: fileData.mimetype,
        })
      }

      this.toggleEvent = 2
      this.eventContents.title = result.title
      this.eventContents.content = result.content
      this.eventContents.eventTargetCreate = result.eventTarget

      if (!_.isNil(result.postStartDt)) {
        this.eventContents.defaultCheck = false
        this.eventContents.isOpenReserve = true
        this.eventContents.openDate.createDate = new Date(result.postStartDt)
        this.eventContents.openDate.selectedTime = new Date(result.postStartDt)
      }

      if (!_.isNil(result.postEndDt)) {
        this.eventContents.closeDate.createDate = new Date(result.postEndDt)
        this.eventContents.closeDate.selectedTime = new Date(result.postEndDt)
      }

      if (!_.isNil(result.popupStartDt)) {
        this.eventContents.isPushReserve = true
        console.log(new Date(result.popupStartDt))
        this.eventContents.pushStartDate.createDate = new Date(
          result.popupStartDt,
        )
        this.eventContents.pushStartDate.selectedTime = new Date(
          result.popupStartDt,
        )
      }

      if (!_.isNil(result.popupEndDt)) {
        this.eventContents.pushEndDate.createDate = new Date(result.popupEndDt)
        this.eventContents.pushEndDate.selectedTime = new Date(
          result.popupEndDt,
        )
      }

      if (!_.isNil(result.files[0].promotionUrl))
        this.eventContents.promotionUrl = result.files[0].promotionUrl
      else this.eventContents.promotionUrl = ''

      if (!_.isNil(result.files)) this.file.name = result.files
    } catch (e) {
      console.log(e)
    }
  }

  clearDatePicker(type = 'openDate') {
    switch (type) {
      case 'openDate':
        this.eventContents.openDate.createDate = new Date()
        this.eventContents.openDate.selectedTime = this.setSelectTime
        break
      case 'closeDate':
        this.eventContents.closeDate.createDate = new Date()
        this.eventContents.closeDate.selectedTime = this.setEndDateTime
        break
      case 'pushStartDate':
        this.eventContents.pushStartDate.createDate = new Date()
        this.eventContents.pushStartDate.selectedTime = this.setSelectTime
        break
      case 'pushEndDate':
        this.eventContents.pushEndDate.createDate = this.addOneMonth()
        this.eventContents.pushEndDate.selectedTime = this.setEndDateTime
        break
      default:
        ''
    }
  }

  public eventImageURLString: string = ''

  async onRowClicked(item) {
    this.selected = item
    this.categoryDetail = await this.fetchCategoryDetail(item.eventId)

    const result = await this.getEventFileURL(this.categoryDetail.files[0])
    this.eventImageURLString = URL.createObjectURL(result)
    console.log(result)

    this.toggleEvent = 1
  }

  async fetchCategoryDetail(eventId) {
    try {
      const res = await eventService.getEventDetails(eventId)
      return res
    } catch (e) {
      console.error(e)
    }
  }

  async getEventFileURL(item) {
    let imageURL: string = ''
    try {
      console.log(item)
      const params = {
        eventId: this.categoryDetail.eventId,
        filename: item.name,
      }
      imageURL = await eventService.getEventFile(params)
    } catch (e) {
      console.log(e)
    }
    return imageURL
  }
  /**
   * 이벤트사항 - 관리자 - 발송
   */

  isSendPopup = false
  pushState = ''

  pushSMS() {
    this.isSendPopup = true
    this.pushState = 'SMS'
  }

  pushApp() {
    this.pushState = 'PUSH'
    this.sendContents({
      title: this.selected.title,
      content: '자세한 내용은 눌러서 확인 하세요',
    })
  }

  async sendContents(value) {
    try {
      let status
      if (this.pushState === 'SMS') {
        status = await eventService.postSmsPush({
          title: value.title,
          content: value.content,
          id: this.selected.eventId,
        })
      } else {
        status = await eventService.postAppPush({
          title: value.title,
          content: value.content,
          id: this.selected.eventId,
        })
      }

      if (status == 200) {
        await this.$toast(`${this.pushState} 발송 완료`)
      }
    } catch (e: any) {
      await this.$alert({ title: '발송 실패', content: e.response.data.errMsg })
    }
  }

  /**
   * 이벤트사항 - 관리자 - 작성
   */

  temporaryItems = [] as any
  isLoadPopup = false

  // 체크박스 변경
  checkboxChange(type) {
    if (type === 'default' && this.eventContents.defaultCheck) {
      this.eventContents.isOpenReserve = false
    } else if (type === 'reserve' && this.eventContents.isOpenReserve) {
      this.eventContents.defaultCheck = false
    }
  }

  toggleLoadPopup() {
    this.isLoadPopup = !this.isLoadPopup
  }

  loadPopupClose() {
    this.isLoadPopup = false
    this.fetchPageList()
  }

  // 불러오기 삭제
  async deleteItems(payload) {
    if (payload.length > 0) {
      try {
        const eventIds = payload.map((x) => x.eventId).join(',')
        await eventService.deleteEvent(eventIds)
        await this.fetchTemporaryPageList()
        this.toggleLoadPopup()
      } catch (e: any) {
        await this.$alert({
          title: 'Event 불러오기 삭제 실패',
          content: e.response.data.errMsg,
        })
      }
    }
  }

  // 불러오기
  async loadItem(payload) {
    if (payload) {
      const res = await this.fetchCategoryDetail(payload.eventId)
      console.log(res)
      this.eventContents.title = res.title
      this.eventContents.content = res.content
      this.eventContents.eventTargetCreate = res.eventTarget
      this.eventContents.isOpenReserve =
        res.postStartDt || res.postEndDt ? true : false
      this.eventContents.defaultCheck = !this.eventContents.isOpenReserve
      if (res.postStartDt) {
        this.eventContents.openDate.createDate = DatetimeUtils.stringDate(
          res.postStartDt,
          'YYYY-MM-DD',
        )
        this.eventContents.openDate.selectedTime = new Date(res.postStartDt)
      }
      if (res.postEndDt) {
        this.eventContents.closeDate.createDate = DatetimeUtils.stringDate(
          res.postEndDt,
          'YYYY-MM-DD',
        )
        this.eventContents.closeDate.selectedTime = new Date(res.postEndDt)
      }
      this.eventContents.isPushReserve =
        res.popupStartDt || res.popupEndDt ? true : false
      if (res.popupStartDt) {
        this.eventContents.pushStartDate.createDate = DatetimeUtils.stringDate(
          res.popupStartDt,
          'YYYY-MM-DD',
        )
        this.eventContents.openDate.selectedTime = new Date(res.popupStartDt)
      }
      if (res.popupEndDt) {
        this.eventContents.pushEndDate.createDate = DatetimeUtils.stringDate(
          res.popupEndDt,
          'YYYY-MM-DD',
        )
        this.eventContents.pushEndDate.selectedTime = new Date(res.popupEndDt)
      }
      if (res.files.length) {
        console.log(res.files[0])
        const downloadedBlob = await this.downloadFile(res.files[0].location)
        this.file = new File([downloadedBlob], res.files[0].name, {
          type: res.files[0].mimetype,
        })

        if (!_.isNil(res.files[0].promotionUrl))
          this.eventContents.promotionUrl = res.files[0].promotionUrl
        else this.eventContents.promotionUrl = ''
      }
    }
  }

  downloadFile(url) {
    return fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        // 다운로드된 Blob 데이터를 변수에 저장
        return blob
      })
  }

  async fetchTemporaryPageList() {
    try {
      const params = {
        temporary: true,
      }
      const res = await eventService.getPageList(params)
      this.temporaryItems = res.content
    } catch (err) {
      console.error(err)
    } finally {
    }
  }

  // 등록하기
  public isRegisterLoading: boolean = false

  async saveEvent(temporary) {
    const formData = new FormData()

    console.log(this.file)
    if (this.file.name) {
      formData.append('files', this.file)

      const maxSize = 10 * 1024 * 1024 // 10MB를 바이트로 변환
      if (this.file.type !== 'image/png' && this.file.type !== 'image/jpeg') {
        return await this.$alert({
          title: '이미지 업로드 실패',
          content:
            '지원하지 않는 파일입니다.<br/> 10MB 이하의 이미지(png, jpg)만 <br/> 첨부할 수 있습니다.',
        })
      } else if (this.file.size > maxSize) {
        return await this.$alert({
          title: '이미지 업로드 실패',
          content:
            '파일 용량이 초과되었습니다.<br/> 10MB 이하의 이미지(png, jpg)만 <br/> 첨부할 수 있습니다.',
        })
      }
    } else {
      return this.$alert({
        title: '이벤트 등록 실패',
        content: '이미지 파일을 첨부해주세요.',
      })
    }

    formData.append('temporary', temporary)
    formData.append('eventTarget', `${this.eventContents.eventTargetCreate}`)
    formData.append('category', '프로모션')
    formData.append('title', this.eventContents.title)
    formData.append('content', this.eventContents.content)

    if (this.editEventId !== null) {
      formData.append('eventId', String(this.editEventId))
    }

    if (this.eventContents.isOpenReserve) {
      formData.append('postStartDt', this.formattedOpenDateTime)
      formData.append('postEndDt', this.formattedCloseDateTime)
    }

    if (this.eventContents.isPushReserve) {
      formData.append('popupStartDt', this.formattedPushStartDateTime)
      formData.append('popupEndDt', this.formattedPushEndDateTime)
    }

    //프로모션 기능 추가함.
    if (this.eventContents.promotionUrl.length)
      formData.append('files.promotionUrl', this.eventContents.promotionUrl)

    try {
      if (this.editEventId) {
        if (temporary) {
          this.isRegisterLoading = true
          const temporaryStorage = await this.$confirm({
            title: '임시저장 안내',
            content: '작성하신 내용을 임시 저장하시겠습니까?',
          })
          if (temporaryStorage) {
            await eventService.postRegisterevent(formData)
            await eventService.postRegisterevent(formData)
            await this.fetchTemporaryPageList()
            await this.$toast({
              type: 'notice',
              content: '임시 저장 되었습니다',
            })
          }
          this.isRegisterLoading = false
        } else {
          this.isRegisterLoading = true
          await eventService.putEditEvent(formData)
          await this.$toast({
            type: 'notice',
            content: `이벤트가 수정 되었습니다.`,
          })
          this.isRegisterLoading = false
          this.backToList()
        }
      } else {
        this.isRegisterLoading = true
        if (!temporary) {
          const result = await this.$confirm({
            title: '이벤트 등록',
            content: '작성하신 내용으로 이벤트를 <br/> 등록 하시겠습니까?',
          })
          if (result) {
            await eventService.postRegisterevent(formData)
            this.isRegisterLoading = false
            await this.$toast('이벤트가 등록 되었습니다.')
            this.backToList()
          } else {
            this.isRegisterLoading = false
          }
        } else {
          const temporaryStorage = await this.$confirm({
            title: '임시저장 안내',
            content: '작성하신 내용을 임시 저장하시겠습니까?',
          })
          if (temporaryStorage) {
            await eventService.postRegisterevent(formData)
            await this.fetchTemporaryPageList()
            this.isRegisterLoading = false
            await this.$toast('임시 저장 되었습니다')
          } else {
            this.isRegisterLoading = false
          }
        }
      }
    } catch (e: any) {
      this.isRegisterLoading = false
      let errContent = ''

      if (e.response && e.response.data) {
        const errCode = e.response.data.errCode
        const errMsg = e.response.data.errMsg

        if (errCode === 'E0010') {
          errContent = '미입력 또는 미선택한 항목이 존재합니다.'
        } else {
          errContent = errMsg || '유효하지 않은 이미지 형태입니다.'
        }
      } else {
        errContent = '유효하지 않은 이미지 형태입니다.'
      }

      await this.$alert({
        title: '이벤트 등록 실패',
        content: errContent,
      })
    }
  }

  onCreate() {
    this.fetchTemporaryPageList()
    this.toggleEvent = 2
    this.eventMode = 'create'
  }

  /**
   * 이벤트 사항 - 이미지 업로드
   */
  file: any = {}
  @Ref() fileInput

  openFileInput() {
    this.fileInput.click()
  }

  handleFileUpload(event) {
    this.file = event.target.files[0]
  }

  handleDrop(event) {
    const files = event.dataTransfer.files
    if (files.length > 0) {
      const file = files[0]
      this.file = file
      this.uploadFile()
    }
  }

  uploadFile() {
    if (
      this.file &&
      (this.file.type === 'image/png' || this.file.type === 'image/jpeg')
    ) {
    } else {
    }
  }

  /**
   * 이벤트사항 - 관리자 관련
   */

  selectedItems: any = []

  checkSent(v) {
    return v ? '전송완료' : '미전송'
  }

  async onDelete() {
    if (this.selectedItems.length > 0) {
      try {
        const res = await this.$confirm('해당 이벤트를 삭제하시겠습니까?')
        if (res) {
          const eventIds = this.selectedItems.map((x) => x.eventId).join(',')
          await eventService.deleteEvent(eventIds)
          this.selectedItems = []
        }
        this.selectedItems = []
        this.fetchPageList()
      } catch (e: any) {
        await this.$alert({
          title: '이벤트 삭제 실패',
          content: e.response.data.errMsg,
        })
      }
    }
  }

  get setPushBtnIsActive() {
    if (this.selected?.appPushedDt) return true
    else if (
      DatetimeUtils.asDays(
        DatetimeUtils.stringDate(this.selected?.regDt, 'YYYY-MM-DD'),
        DatetimeUtils.now_format('YYYY-MM-DD'),
      ) > 4
    )
      return true
    else return false
  }

  isPromotionUrl(promotionUrl) {
    console.log(this.selected)
    if (!_.isNil(promotionUrl)) return true
    else return false
  }

  get exposureString() {
    if (
      _.isNil(this.selected.postStartDt) &&
      _.isNil(this.selected.postEndDt)
    ) {
      return '상시 노출'
    } else {
      return (
        '노출 예약 : ' +
        this.getDtString(this.selected.postStartDt) +
        ' ~ ' +
        this.getDtString(this.selected.postEndDt)
      )
    }
  }

  get popupString() {
    if (
      _.isNil(this.selected.popupStartDt) &&
      _.isNil(this.selected.popupEndDt)
    ) {
      return '-'
    } else {
      return (
        '팝업 설정 : ' +
        this.getDtString(this.selected.popupStartDt) +
        ' ~ ' +
        this.getDtString(this.selected.popupEndDt)
      )
    }
  }

  getDtString(date) {
    if (_.isNil(date)) return ' '
    else return DateTimeUtil.stringDate(date, 'YYYY-MM-DD HH:mm')
  }

  get eventTypeString() {
    const nameString = this.eventTargetItems.find(
      (y) => y.value === this.selected.eventTarget,
    )?.name
    console.log(nameString)
    return '이벤트 대상 : ' + nameString
  }
}
