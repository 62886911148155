class RegexUtil {
  /**
   * 6~12자 / 영문 소문자, 숫자 조합
   */
  userIdRules(id) {
    if (/^(?=.*[a-z])(?=.*\d)[a-z\d]{6,12}$/.test(id)) return true
    else return false
  }

  /**
   * 6~20자 / 영문 소문자, 숫자 조합
   */
  userIdManagerRules(id) {
    if (/^(?=.*[a-z])(?=.*\d)[a-z\d]{6,20}$/.test(id)) return true
    else return false
  }

  /**
   * 영문 소문, 숫자, 특수문자 중 2종류 이상 포함 되고  최소 10자리에서 최대 20자리 까지
   * @param id
   */
  userPassWordRules(id) {
    if (
      /^(?=(.*[a-z]))?(?=(.*\d))?(?=(.*[\{\}\[\]\/\? .,;:|\)*~`!^-_+<>@#$%&\\\=\(ʻ”]))?([a-z\d\{\}\[\]\/\? .,;:|\)*~`!^-_+<>@#$%&\\\=\(ʻ”]){10,20}$/.test(
        id,
      )
    )
      return true
    else return false
  }

  isCheckEmailRules(id) {
    if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(id)) return true
    else return false
  }

  isFloatNumberRules(number) {
    if (/^-?\d+(\.\d+)?$/.test(number)) {
      console.log('값은 float입니다.')
      return true
    } else {
      return false
    }
  }
}

export default new RegexUtil()
