var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "dash-board-card-contents1" },
    [
      _c(
        "v-card-title",
        [
          _c(
            "span",
            { staticClass: "font-size19 font-weight-semibold surface800" },
            [_vm._v(" 오늘의 화물 등록·배차 현황 ")]
          ),
          _c("v-spacer"),
          _c("span", { staticClass: "font-size12 surface800" }, [
            _vm._v(_vm._s(_vm.todayString())),
          ]),
        ],
        1
      ),
      _c(
        "v-card-text",
        { staticClass: "pt-5" },
        [
          _c("chartjs-component-local-doughnut-chart", {
            attrs: {
              height: 160,
              baseData: _vm.setInputDate(_vm.locale),
              totalCount: _vm.locale.totalCount,
              percent: _vm.getPercentage(_vm.locale),
            },
          }),
        ],
        1
      ),
      _c(
        "v-card-text",
        { staticClass: "px-5 pt-5" },
        _vm._l(_vm.todayRequestCount, function (_item, i) {
          return _item.show
            ? _c("today-list-item", {
                key: i,
                attrs: { title: _item.title, content: _item.content },
              })
            : _vm._e()
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }