


















































export default {
  name: 'FindPasswordReset',
  data() {
    return {}
  },
  methods: {},
  mounted() {},
}
