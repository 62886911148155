import _ from 'lodash'

/**
 * 결재 방식
 */
export = (() => {
  const _PAYMENT_TYPE: any = {
    TOTAL: null,
    VIRTUAL_ACCOUNT: 1, // 가상계좌
    CARD: 2, // 카드
    INVOICE: 3, // 인수증
    // DEFERRED : 2, // 후불
    // PREPAYMENT : 1, // 선불
  }

  const _PAYMENT_TYPE_STRING: any = {
    TOTAL: '전체',
    VIRTUAL_ACCOUNT: '가상계좌',
    CARD: '카드결제',
    INVOICE: '인수증',
    // DEFERRED: '후불',
    // PREPAYMENT: '선불',
  }

  Object.freeze(_PAYMENT_TYPE)
  Object.freeze(_PAYMENT_TYPE_STRING)

  const _METHOD_TYPE_WEB_NAME: Map<number, string> = new Map<number, string>([
    [_PAYMENT_TYPE.TOTAL, _PAYMENT_TYPE_STRING.TOTAL],
    [_PAYMENT_TYPE.VIRTUAL_ACCOUNT, _PAYMENT_TYPE_STRING.VIRTUAL_ACCOUNT],
    [_PAYMENT_TYPE.CARD, _PAYMENT_TYPE_STRING.CARD],
    [_PAYMENT_TYPE.INVOICE, _PAYMENT_TYPE_STRING.INVOICE],
    [_PAYMENT_TYPE.DEFERRED, _PAYMENT_TYPE_STRING.DEFERRED],
    [_PAYMENT_TYPE.PREPAYMENT, _PAYMENT_TYPE_STRING.PREPAYMENT],
  ])

  return Object.freeze({
    TOTAL: _PAYMENT_TYPE.TOTAL,
    VIRTUAL_ACCOUNT: _PAYMENT_TYPE.VIRTUAL_ACCOUNT,
    CARD: _PAYMENT_TYPE.CARD,
    INVOICE: _PAYMENT_TYPE.INVOICE,
    DEFERRED: _PAYMENT_TYPE.DEFERRED,
    PREPAYMENT: _PAYMENT_TYPE.PREPAYMENT,

    TOTAL_STRING: _PAYMENT_TYPE_STRING.TOTAL,
    VIRTUAL_ACCOUNT_STRING: _PAYMENT_TYPE_STRING.VIRTUAL_ACCOUNT,
    CARD_STRING: _PAYMENT_TYPE_STRING.CARD,
    INVOICE_STRING: _PAYMENT_TYPE_STRING.INVOICE,
    DEFERRED_STRING: _PAYMENT_TYPE_STRING.DEFERRED,
    PREPAYMENT_STRING: _PAYMENT_TYPE_STRING.PREPAYMENT,

    PAYMENT_NAME_WEB: (paymentIndex: number): string => {
      const paymentName: string | undefined =
        _METHOD_TYPE_WEB_NAME.get(paymentIndex)
      return paymentName ? paymentName : '-'
    },
  })
})()
