import axios from 'axios'

class CallerService {
  public apiUrl: string

  constructor() {
    this.apiUrl = '/caller'
  }

  /**
   * 화주 목록 조회
   */
  getCallerList(params) {
    return axios.post(this.apiUrl + '/page',{}, { params }).then((res) => res.data)
  }

  /**
   * 화주 정보 조회
   */
  getCallerUserInfo(id) {
    const body : any = {
      userId : id
    }
    return axios.post(this.apiUrl + `/user-info`, body).then((res) => res.data)
  }
}

export default new CallerService()
