import inspectionService from '@/services-itgo/config.service'

/**
 * serverConfig 모듈
 */
export const serverConfig = {
  namespaced: true,
  state: {
    config: null,
  },

  actions: {
    setCurrentConfig({ commit }) {
      return inspectionService.postServerConfig().then((config) => {
        commit('setCurrentConfig', config)
        return Promise.resolve(config)
      })
    },
  },

  mutations: {
    setCurrentConfig(state, config) {
      state.config = config
    },
  },

  getters: {
    getCurrentConfigRefresh(state) {
      return state.config.CONFIG_REALTIME_SEARCH_PERIOD
    },
  },
}
