<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="viewBox"
  >
    <g id="ic_record" transform="translate(417 -317)">
      <g id="mail_open" transform="translate(-416 318)">
        <path id="패스_29447" data-name="패스 29447" d="M15.6,7.124H8V6h7.6Z" transform="translate(1.698 0.5)" :fill="iconColor" :stroke="iconColor" stroke-width="0.5" fill-rule="evenodd"/>
        <path id="패스_29448" data-name="패스 29448" d="M12.806,9.12H8V8h4.806Z" transform="translate(1.698 1.767)" :fill="iconColor" :stroke="iconColor" stroke-width="0.5" fill-rule="evenodd"/>
        <path id="패스_29449" data-name="패스 29449" d="M3,8.971V22.25a1.167,1.167,0,0,0,1.167,1.167H22.833A1.167,1.167,0,0,0,24,22.25V8.971a1.167,1.167,0,0,0-.645-1.044L20.5,6.5V7.8l2.333,1.167v.964L13.5,14.083,4.167,9.935V8.971L6.5,7.8V6.5L3.645,7.928A1.167,1.167,0,0,0,3,8.971ZM13.5,15.36l9.333-4.148V22.25H4.167V11.212Z" transform="translate(0 0.583)" :fill="iconColor" :stroke="iconColor" stroke-width="0.5" fill-rule="evenodd"/>
        <path id="패스_29450" data-name="패스 29450" d="M7.167,4.167H18.833v7.907L20,11.556V4.167A1.167,1.167,0,0,0,18.833,3H7.167A1.167,1.167,0,0,0,6,4.167v7.389l1.167.519Z" transform="translate(0.5)" :fill="iconColor" :stroke="iconColor" stroke-width="0.5"/>
      </g>
      <rect id="사각형_31280" data-name="사각형 31280" width="28" height="28" transform="translate(-417 317)" fill="none"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'record',

  props: {
    viewBox: { type: String, default: '0 0 28 28' },
    iconName: { type: String, default: '' },
    width: { type: [Number, String], default: 28 },
    height: { type: [Number, String], default: 28 },
    iconColor: { type: String, default: '#ffffff' },
  },
}
</script>

<style scoped></style>
