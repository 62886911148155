






















































import { Component, Prop, Ref, Vue } from 'vue-property-decorator'
import { mdiCalendarMonth } from '@mdi/js'

@Component
export default class DatePicker extends Vue {
  @Prop(String) label
  @Prop(String) placeholder
  @Prop({ type: Boolean, default: false }) disabled

  @Ref() datePicker

  public dates = []
  public menu = false

  public icons = {
    mdiCalendarMonth,
  }

  set() {
    this.menu = false
    this.dates.sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
    this.datePicker.save(this.dates)
    this.$emit('setInputDate', this.dates)
  }

  onClearClicked() {
    this.dates = []
    this.$emit('setInputDate', this.dates)
  }
}
