var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.canViewVerticalNavMenuLink(_vm.item)
    ? _c(
        "v-list-item",
        _vm._b(
          {
            staticClass: "vertical-nav-menu-link",
            class: { "bg-gradient-primary white--text": _vm.isActive },
            attrs: { "active-class": "bg-gradient-primary" },
          },
          "v-list-item",
          _vm.linkProps,
          false
        ),
        [
          _c(
            "v-list-item-icon",
            [
              _vm.item.title === "Dashboards"
                ? _c("dashboard", {
                    attrs: {
                      "icon-color": _vm.isActiveMenu(_vm.isActive, _vm.item),
                    },
                  })
                : _vm._e(),
              _vm.item.title === "FreightHistory"
                ? _c("record", {
                    attrs: {
                      "icon-color": _vm.isActiveMenu(_vm.isActive, _vm.item),
                    },
                  })
                : _vm.item.title === "NoticeManagement"
                ? _c("noti", {
                    attrs: {
                      "icon-color": _vm.isActiveMenu(_vm.isActive, _vm.item),
                    },
                  })
                : _vm.item.title === "FAQManagement"
                ? _c("faq", {
                    attrs: {
                      "icon-color": _vm.isActiveMenu(_vm.isActive, _vm.item),
                    },
                  })
                : _vm.item.title === "VOCManagement"
                ? _c("customervoice", {
                    attrs: {
                      "icon-color": _vm.isActiveMenu(_vm.isActive, _vm.item),
                    },
                  })
                : _vm.item.title === "EventManagement"
                ? _c("event", {
                    attrs: {
                      "icon-color": _vm.isActiveMenu(_vm.isActive, _vm.item),
                    },
                  })
                : _vm.item.title === "AdminManagement"
                ? _c("system", {
                    attrs: {
                      "icon-color": _vm.isActiveMenu(_vm.isActive, _vm.item),
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c("v-list-item-title", { class: { "white--text": _vm.isActive } }, [
            _c("span", { staticClass: "text-base" }, [
              _vm._v(_vm._s(_vm.t(_vm.item.title))),
            ]),
          ]),
          _vm.item.badge
            ? _c(
                "v-list-item-action",
                { staticClass: "flex-shrink-0" },
                [
                  _c("v-badge", {
                    attrs: {
                      color: _vm.item.badgeColor,
                      inline: "",
                      content: _vm.item.badge,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }