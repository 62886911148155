import axios from 'axios'

class PayoutService {
  private apiUrl: string
  constructor() {
    this.apiUrl = '/payout'
  }

  //주선사 정산 list
  getPayoutList(params) {
    return axios
      .get(this.apiUrl + `/admin-brokers-list`, { params })
      .then((res) => res.data)
  }

  //주선사 정산 list excel
  getPayoutListExcel(params) {
    return axios
      .get(this.apiUrl + '/admin-brokers-list-excel', {
        params: params,
        responseType: 'blob',
      })
      .then((res) => res.data)
  }
}

export default new PayoutService()
