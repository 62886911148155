var logi = logi ?? {};
logi['maps'] = logi['maps'] ?? {};
import a17_0x319fdf from './config/lbsconfig.js';
logi['maps']['Config'] = a17_0x319fdf;
import a17_0x1e7635 from './base/logi-maps-defines.js';
logi['maps']['Defines'] = a17_0x1e7635;
import a17_0x56ec29 from './base/logi-maps-types.js';
logi['maps']['Size'] = a17_0x56ec29['Size'], logi['maps']['BRIDGE_MAPEVENT'] = a17_0x56ec29['BRIDGE_MAPEVENT'], logi['maps']['ALIGN'] = a17_0x56ec29['ALIGN'];
import a17_0x250a1e from './base/logi-maps-resource.js';
logi['maps']['Resource'] = a17_0x250a1e;
import a17_0x17046b from './base/logi-maps-object.js';
logi['maps']['Object'] = a17_0x17046b;
import a17_0x419124 from './base/logi-maps-bridge.js';
logi['maps']['Bridge'] = a17_0x419124;
import a17_0x174ceb from './base/logi-maps-coord.js';
logi['maps']['Coord'] = a17_0x174ceb;
import a17_0x3e3012 from './base/logi-maps-utils.js';
logi['maps']['Utils'] = a17_0x3e3012;
import a17_0x5eb328 from './base/logi-maps-database.js';
logi['maps']['Database'] = a17_0x5eb328;
import a17_0x5cd102 from './logi-maps-objlayer.js';
logi['maps']['ObjLayer'] = a17_0x5cd102;
import a17_0x48ad7e from './logi-maps-uilayer.js';
logi['maps']['UiLayer'] = a17_0x48ad7e, logi['maps']['Map'] = class {
    #mapTileDataType = logi['maps']['Defines']['MAP_TILE_DATA_PNG'];
    #country;
    #surfaceCanvas;
    #mapCoord;
    #mapDiv;
    #mapRootDiv;
    #mapAreaDiv;
    #mapTilesCanvas;
    #mapNotationCanvas;
    #mapScreenSize = new logi['maps']['Size']();
    #devicePixelRatio = 0x1;
    #mapDatabase;
    #mapResource;
    #loopRenderId = 0x0;
    #currentFps = 0x0;
    #lastRenderTick = 0x0;
    #updateFlag = !![];
    #freezeModeOnMoving = !![];
    #canvasWorldPosition = {
        'lng': 0x0,
        'lat': 0x0
    };
    #isMobileEnv = ![];
    #remoteServerUrl = '';
    #tileMapCache = new Map();
    #MAX_TILE_MAP_CACHE_SIZE = 0x100;
    #tileOffCanvasPixelRatio = 0x1;
    #tileOffCanvasCache = new Array();
    #tileMapsWaitingForResponse = new Map();
    #MAX_WAITING_TILE_MAPS_SIZE = 0x20;
    #MAX_WAITING_TILE_MAPS_TIME = 0x1388;
    #notFoundTileMaps = new Map();
    #MAX_NOT_FOUND_TILE_MAPS_TIME = 0x1388;
    #isDragging = ![];
    #lastAdditionalScale = 0x1;
    #multiTouchActive = ![];
    #touchSingleDownStatus = ![];
    #touchStartPoint = {
        'x': 0x0,
        'y': 0x0
    };
    #touchPrevPoints = [];
    #touchPrevPoint = {
        'x': 0x0,
        'y': 0x0
    };
    #mouseLButtonDownStatus = ![];
    #mousePrevPoint = {
        'x': 0x0,
        'y': 0x0
    };
    #wheelEventCheck = !![];
    #lastWheelTime = 0x0;
    #bridgeMapEvents = {};
    #mapKind = 0x0;
    #mapLayers = [];
    #objLayer = null;
    #uiLayer = null;
    constructor(_0x239403, _0x224b41) {
        this.#loadServerUrl(_0x224b41?.['serverUrl']);
        const _0x1bdf5b = _0x224b41?.['mapType'];
        switch (_0x1bdf5b) {
        case logi['maps']['Defines']['MAP_TILE_DATA_PNG']:
        case logi['maps']['Defines']['MAP_TILE_DATA_SVG']:
        case logi['maps']['Defines']['MAP_TILE_DATA_XVG']:
            this.#mapTileDataType = _0x1bdf5b;
            break;
        }
        console['log']('[logi.maps]\x20tile\x20type:\x20' + this.#mapTileDataType), this.#country = _0x224b41?.['country'] ?? logi['maps']['Defines']['COUNTRY_KOR'], this.#mapDatabase = new logi['maps']['Database'](this.#mapTileDataType), this.#mapResource = new logi['maps']['Resource'](_0x224b41?.['resource']?.['path'], this.#country), this['setTheme'](_0x224b41?.['resource']?.['theme']);
        const _0x9a8c62 = this.#getMapDataVersionUrl(this.#mapKind);
        _0x9a8c62 !== '' && fetch(_0x9a8c62)['then'](_0x4d37c6 => {
            return _0x4d37c6['text']();
        })['then'](_0x41f020 => {
            _0x41f020 = _0x41f020 ?? '000000R00', console['log']('[logi.maps]\x20api\x20version:\x20' + logi['maps']['Config']['api_version']), console['log']('[logi.maps]\x20map\x20version:\x20' + _0x41f020), this.#mapDatabase['initDatabase']()['then'](() => {
                this.#mapDatabase['checkMapVersion'](_0x41f020)['then'](_0x5982df => {
                    _0x5982df && (this.#clearCache(), this['updateMap']());
                });
            })['catch'](_0x5e3085 => {
                this.#mapDatabase['initDatabase']()['then'](() => {
                    this.#mapDatabase['checkMapVersion'](_0x41f020)['then'](_0x5b3906 => {
                        _0x5b3906 && (this.#clearCache(), this['updateMap']());
                    });
                });
            });
        });
        this.#mapCoord = new logi['maps']['Coord'](this.#country, _0x224b41['levelRange']), this.#mapDiv = document['getElementById'](_0x239403), this.#mapRootDiv = document['createElement']('div'), this.#mapRootDiv['id'] = _0x239403 + '_map_root', this.#mapRootDiv['style'] = 'position:relative;\x20width:100%;\x20height:100%;\x20overflow-y:hidden;\x20overflow-x:hidden;', this.#mapDiv['appendChild'](this.#mapRootDiv), this.#mapAreaDiv = document['createElement']('div'), this.#mapAreaDiv['id'] = _0x239403 + '_map_area', this.#mapAreaDiv['style'] = 'position:absolute;\x20width:100%;\x20height:100%;\x20overflow-y:hidden;\x20overflow-x:hidden;', this.#mapRootDiv['appendChild'](this.#mapAreaDiv), this.#mapTilesCanvas = document['createElement']('canvas'), this.#mapTilesCanvas['id'] = _0x239403 + '_map_tiles', this.#mapTilesCanvas['style'] = 'position:absolute;\x20width:100%;\x20height:100%;\x20overflow-y:hidden;\x20overflow-x:hidden;', this.#mapAreaDiv['appendChild'](this.#mapTilesCanvas), this.#mapNotationCanvas = document['createElement']('canvas'), this.#mapNotationCanvas['id'] = _0x239403 + '_map_notation', this.#mapNotationCanvas['style'] = 'position:absolute;\x20width:100%;\x20height:100%;\x20overflow-y:hidden;\x20overflow-x:hidden;', this.#mapAreaDiv['appendChild'](this.#mapNotationCanvas);
        this.#checkUseMobile() && (this.#isMobileEnv = !![]);
        this['OnDoubleClick'] = _0x224b41['OnDoubleClick'], this['OnMouseDown'] = _0x224b41['OnMouseDown'], this['OnMouseUp'] = _0x224b41['OnMouseUp'], this['OnMouseMove'] = _0x224b41['OnMouseMove'], this['OnMapWheel'] = _0x224b41['OnMapWheel'], this['OnDraw'] = _0x224b41['OnDraw'], this.#bridgeMapEvents = {};
        for (const _0x3c7a2b in logi['maps']['BRIDGE_MAPEVENT']) {
            this.#bridgeMapEvents[_0x3c7a2b] = ![];
        }
        this.#objLayer = new logi['maps']['ObjLayer'](this.#mapRootDiv, this.#mapCoord), this.#uiLayer = new logi['maps']['UiLayer'](this.#mapRootDiv, this.#mapCoord), this.#mapLayers['push'](this.#objLayer), this.#mapLayers['push'](this.#uiLayer), this.#surfaceCanvas = this.#uiLayer['getCanvas'](), this['resize']();
        const _0x522262 = this;
        window['addEventListener']('resize', () => {
            _0x522262['resize']();
        }), this.#initEvent(), this.#setRenderFrame(logi['maps']['Defines']['FPS_MEDIUM']);
        _0x224b41['center'] && this['setCenter'](_0x224b41['center']);
        _0x224b41['level'] && this['setLevel'](_0x224b41['level']);
        if (this.#mapTileDataType == logi['maps']['Defines']['MAP_TILE_DATA_SVG'] || this.#mapTileDataType == logi['maps']['Defines']['MAP_TILE_DATA_XVG']) {
            this.#tileOffCanvasPixelRatio = this.#getWindowDevicePixelRatio();
            for (let _0x4bc760 = 0x0; _0x4bc760 < parseInt(this.#MAX_TILE_MAP_CACHE_SIZE * 1.25); ++_0x4bc760) {
                this.#tileOffCanvasCache['push'](new OffscreenCanvas(logi['maps']['Defines']['TILE_W'] * this.#devicePixelRatio, logi['maps']['Defines']['TILE_H'] * this.#devicePixelRatio));
            }
        }
    }
    ['setDebugMode'](_0x3aef8d) {
        logi['maps']['Defines']['DEBUG_MODE'] = _0x3aef8d;
    }
    ['setLogMode'](_0x34ef5e) {
        logi['maps']['Defines']['LOG_MODE'] = _0x34ef5e;
    }
    ['setEvChargerVisibility'](_0x2e3569) {
        this.#objLayer['setEvChargerVisibility'](_0x2e3569);
    }
    ['setTheme'](_0x5a285c) {
        _0x5a285c && (this.#mapTileDataType == logi['maps']['Defines']['MAP_TILE_DATA_SVG'] || this.#mapTileDataType == logi['maps']['Defines']['MAP_TILE_DATA_XVG'] ? this.#mapResource['setTheme'](_0x5a285c)['then'](_0x13f821 => {
            this.#clearCache(), this['updateMap']();
        })['catch'](_0x7f05e4 => {
            console['log']('[logi.maps]\x20setTheme\x20failed.\x20(' + _0x7f05e4 + ')');
        }) : console['log']('[logi.maps]\x20Image\x20map\x20tiles\x20do\x20not\x20support\x20style.'));
    }
    ['getTheme']() {
        return this.#mapTileDataType == logi['maps']['Defines']['MAP_TILE_DATA_SVG'] || this.#mapTileDataType == logi['maps']['Defines']['MAP_TILE_DATA_XVG'] ? this.#mapResource['getTheme']() : (console['log']('[logi.maps]\x20Image\x20map\x20tiles\x20do\x20not\x20support\x20style.'), null);
    }
    ['addFont'](_0x55a28c, _0x4a2128) {
        this.#mapResource['addFont'](_0x55a28c, _0x4a2128);
    }
    #getWindowDevicePixelRatio() {
        return window['devicePixelRatio'];
    }
    #clearCache() {
        for (const [_0x4e350f, _0xa93292] of this.#tileMapCache) {
            this.#tileOffCanvasCache['push'](_0xa93292['map']), this.#tileMapCache['delete'](_0x4e350f);
        }
    }
    ['setScreenSize'](_0x1b1f5b, _0x35322b) {
        if (_0x1b1f5b['width'] == undefined || _0x1b1f5b['height'] == undefined || _0x35322b == undefined)
            return;
        if (this.#mapScreenSize['width'] != _0x1b1f5b['width'] || this.#mapScreenSize['height'] != _0x1b1f5b['height'] || this.#devicePixelRatio != _0x35322b) {
            this.#mapScreenSize['width'] = _0x1b1f5b['width'], this.#mapScreenSize['height'] = _0x1b1f5b['height'], this.#devicePixelRatio = _0x35322b, this.#mapTilesCanvas['width'] = this.#mapScreenSize['width'] * this.#devicePixelRatio, this.#mapTilesCanvas['height'] = this.#mapScreenSize['height'] * this.#devicePixelRatio, this.#mapNotationCanvas['width'] = this.#mapScreenSize['width'] * this.#devicePixelRatio, this.#mapNotationCanvas['height'] = this.#mapScreenSize['height'] * this.#devicePixelRatio;
            for (let _0x4080eb of this.#mapLayers) {
                _0x4080eb['setScreenSize'](this.#mapScreenSize['width'], this.#mapScreenSize['height'], this.#devicePixelRatio);
            }
            this.#mapCoord['setScreenSize'](this.#mapScreenSize['width'], this.#mapScreenSize['height'], this.#devicePixelRatio), this.#mapCoord['adjustMapBoundRect'](), this['updateMap']();
        }
    }
    ['getScreenSize']() {
        return {
            'width': this.#mapScreenSize['width'],
            'height': this.#mapScreenSize['height']
        };
    }
    ['resize']() {
        const _0x3abf53 = this.#getWindowDevicePixelRatio();
        this['setScreenSize']({
            'width': this.#mapDiv['clientWidth'],
            'height': this.#mapDiv['clientHeight']
        }, _0x3abf53);
    }
    ['getMapCoord']() {
        return this.#mapCoord;
    }
    ['enableWheelEvent']() {
        this.#wheelEventCheck = !![];
    }
    ['disableWheelEvent']() {
        this.#wheelEventCheck = ![];
    }
    ['addEventListener'](_0x212969, _0x309f92, _0x25d6d5 = ![]) {
        this.#surfaceCanvas['addEventListener'](_0x212969, _0x309f92, _0x25d6d5);
    }
    ['removeEventListener'](_0x592a67, _0x286519, _0x3eaf4d = ![]) {
        this.#surfaceCanvas['removeEventListener'](_0x592a67, _0x286519, _0x3eaf4d);
    }
    ['setBridgeEvent'](_0x5abdbf, _0x45da08) {
        this.#bridgeMapEvents[_0x5abdbf] !== undefined && (this.#bridgeMapEvents[_0x5abdbf] = _0x45da08);
    }
    ['setFreezeModeOnMoving'](_0xbed7f5) {
        this.#freezeModeOnMoving = _0xbed7f5;
    }
    ['setCenterMark'](_0x52aec8, _0x3761c3, _0x526570) {
        this.#uiLayer['setCenterMark'](_0x52aec8, _0x3761c3, _0x526570);
    }
    ['screen2world'](_0x38dd4b, _0x4f3923 = -0x1) {
        return _0x4f3923 >= 0x0 ? this.#mapCoord['screen2world'](_0x38dd4b['x'], _0x38dd4b['y'], { 'level': _0x4f3923 }) : this.#mapCoord['screen2world'](_0x38dd4b['x'], _0x38dd4b['y']);
    }
    ['world2screen'](_0x440e6f, _0x32a72c = -0x1) {
        return _0x32a72c >= 0x0 ? this.#mapCoord['world2screen'](_0x440e6f['lng'], _0x440e6f['lat'], { 'level': _0x32a72c }) : this.#mapCoord['world2screen'](_0x440e6f['lng'], _0x440e6f['lat']);
    }
    ['getRealDistance'](_0x3334a4, _0x49f0a9) {
        const _0x1d83e9 = 0x6136b8, _0xf9d20a = logi['maps']['Utils']['degToRad'](_0x3334a4['lat']), _0x54030c = logi['maps']['Utils']['degToRad'](_0x49f0a9['lat']), _0x317400 = logi['maps']['Utils']['degToRad'](_0x49f0a9['lat'] - _0x3334a4['lat']), _0x3dadfe = logi['maps']['Utils']['degToRad'](_0x49f0a9['lng'] - _0x3334a4['lng']), _0xeeb054 = Math['sin'](_0x317400 * 0.5), _0x2354c7 = Math['sin'](_0x3dadfe * 0.5), _0x5ec3b4 = _0xeeb054 * _0xeeb054 + Math['cos'](_0xf9d20a) * Math['cos'](_0x54030c) * _0x2354c7 * _0x2354c7, _0x53219c = 0x2 * Math['atan2'](Math['sqrt'](_0x5ec3b4), Math['sqrt'](0x1 - _0x5ec3b4));
        return _0x1d83e9 * _0x53219c;
    }
    ['updateMap']() {
        this.#updateFlag = !![];
        for (let _0x231a50 of this.#mapLayers) {
            _0x231a50['updateLayer']();
        }
    }
    ['setLevel'](_0x16cf04) {
        this.#lastAdditionalScale = 0x1, this.#mapCoord['setAdditionalScale'](0x1), this.#mapCoord['setLevel'](_0x16cf04), this.#mapCoord['adjustMapBoundRect'](), this.#objLayer['refreshTiledImageByLevel'](), this.#objLayer['refreshTiledLabelByLevel'](), this.#objLayer['refreshTiledEvChargerByLevel'](), this['updateMap']();
    }
    ['getLevel']() {
        return this.#mapCoord['getLevel']();
    }
    ['isZoomInMax']() {
        return this.#mapCoord['isZoomInMax']();
    }
    ['zoomIn'](_0x58184f = !![]) {
        this['isZoomInMax']() == ![] && (_0x58184f && (this.#lastAdditionalScale = 0x1, this.#mapCoord['setAdditionalScale'](0x1)), this.#mapCoord['zoomIn'](), this.#mapCoord['adjustMapBoundRect'](), this.#objLayer['refreshTiledImageByLevel'](), this.#objLayer['refreshTiledLabelByLevel'](), this.#objLayer['refreshTiledEvChargerByLevel'](), this['updateMap']());
    }
    ['isZoomOutMax']() {
        return this.#mapCoord['isZoomOutMax']();
    }
    ['zoomOut'](_0x2f80c2 = !![]) {
        this['isZoomOutMax']() == ![] && (_0x2f80c2 && (this.#lastAdditionalScale = 0x1, this.#mapCoord['setAdditionalScale'](0x1)), this.#mapCoord['zoomOut'](), this.#mapCoord['adjustMapBoundRect'](), this.#objLayer['refreshTiledImageByLevel'](), this.#objLayer['refreshTiledLabelByLevel'](), this.#objLayer['refreshTiledEvChargerByLevel'](), this['updateMap']());
    }
    ['setCenter'](_0x21f583) {
        this.#isDragging = ![], this.#mapCoord['setCenter'](_0x21f583['lng'], _0x21f583['lat']), this.#mapCoord['adjustMapBoundRect'](), this['updateMap']();
    }
    ['getCenter']() {
        return this.#mapCoord['getCenter']();
    }
    ['setBounds'](_0x5218d7, _0x94a91d) {
        this.#isDragging = ![], this.#lastAdditionalScale = 0x1, this.#mapCoord['setAdditionalScale'](0x1), this.#mapCoord['setBounds'](_0x5218d7['min']['lng'], _0x5218d7['min']['lat'], _0x5218d7['max']['lng'], _0x5218d7['max']['lat'], _0x94a91d?.['left'] ?? 0x0, _0x94a91d?.['top'] ?? 0x0, _0x94a91d?.['right'] ?? 0x0, _0x94a91d?.['bottom'] ?? 0x0), this.#mapCoord['adjustMapBoundRect'](), this.#objLayer['refreshTiledImageByLevel'](), this.#objLayer['refreshTiledLabelByLevel'](), this.#objLayer['refreshTiledEvChargerByLevel'](), this['updateMap']();
    }
    ['addObject'](_0x5d70dd) {
        let _0x2c9b43 = _0x5d70dd?.['getType']?.();
        if (_0x5d70dd)
            switch (_0x2c9b43) {
            case logi['maps']['Object']['OBJTYPE']['image']:
                this.#objLayer['addImage'](_0x5d70dd);
                break;
            case logi['maps']['Object']['OBJTYPE']['label']:
                this.#objLayer['addLabel'](_0x5d70dd);
                break;
            case logi['maps']['Object']['OBJTYPE']['line']:
                this.#objLayer['addLine'](_0x5d70dd);
                break;
            case logi['maps']['Object']['OBJTYPE']['polygon']:
                this.#objLayer['addPolygon'](_0x5d70dd);
                break;
            case logi['maps']['Object']['OBJTYPE']['route']:
                this.#objLayer['addRoute'](_0x5d70dd);
                break;
            default:
                break;
            }
    }
    ['removeObject'](_0x9feb93) {
        let _0x2af4dd = _0x9feb93?.['getType']?.();
        if (_0x9feb93)
            switch (_0x2af4dd) {
            case logi['maps']['Object']['OBJTYPE']['image']:
                this.#objLayer['removeImage'](_0x9feb93['getKey']());
                break;
            case logi['maps']['Object']['OBJTYPE']['label']:
                this.#objLayer['removeLabel'](_0x9feb93['getKey']());
                break;
            case logi['maps']['Object']['OBJTYPE']['line']:
                this.#objLayer['removeLine'](_0x9feb93['getKey']());
                break;
            case logi['maps']['Object']['OBJTYPE']['polygon']:
                this.#objLayer['removePolygon'](_0x9feb93['getKey']());
                break;
            case logi['maps']['Object']['OBJTYPE']['route']:
                this.#objLayer['removeRoute'](_0x9feb93['getKey']());
                break;
            default:
                break;
            }
    }
    ['addImage'](_0x16c236) {
        return logi['maps']['Defines']['LOG_MODE'] && console['log']('[logi.maps]\x20[addImage]\x20key:' + _0x16c236['getKey']() + '\x20position:\x20' + _0x16c236['getPosition']()['lng'] + ',\x20' + _0x16c236['getPosition']()['lat']), this.#objLayer['addImage'](_0x16c236);
    }
    ['addLabel'](_0x4ffab2) {
        return logi['maps']['Defines']['LOG_MODE'] && console['log']('[logi.maps]\x20[addLabel]\x20key:' + _0x4ffab2['getKey']() + '\x20position:\x20' + _0x4ffab2['getPosition']()?.['lng'] + ',\x20' + _0x4ffab2['getPosition']()?.['lat']), this.#objLayer['addLabel'](_0x4ffab2);
    }
    ['addLine'](_0x317d0e) {
        return logi['maps']['Defines']['LOG_MODE'] && console['log']('[logi.maps]\x20[addLine]\x20key:' + _0x317d0e['getKey']()), this.#objLayer['addLine'](_0x317d0e);
    }
    ['addPolygon'](_0x5b3509) {
        return logi['maps']['Defines']['LOG_MODE'] && console['log']('[logi.maps]\x20[addPolygon]\x20key:' + _0x5b3509['getKey']()), this.#objLayer['addPolygon'](_0x5b3509);
    }
    ['addRoute'](_0x19de22) {
        return logi['maps']['Defines']['LOG_MODE'] && console['log']('[logi.maps]\x20[addRoute]\x20key:' + _0x19de22['getKey']()), this.#objLayer['addRoute'](_0x19de22);
    }
    ['isExistImage'](_0x17d740) {
        return this.#objLayer['isExistImage'](_0x17d740);
    }
    ['isExistLabel'](_0x5add8d) {
        return this.#objLayer['isExistLabel'](_0x5add8d);
    }
    ['isExistLine'](_0xbd3da8) {
        return this.#objLayer['isExistLine'](_0xbd3da8);
    }
    ['isExistPolygon'](_0x2bc59f) {
        return this.#objLayer['isExistPolygon'](_0x2bc59f);
    }
    ['isExistRoute'](_0x15afde) {
        return this.#objLayer['isExistRoute'](_0x15afde);
    }
    ['findImage'](_0x406732) {
        return this.#objLayer['findImage'](_0x406732);
    }
    ['findLabel'](_0x1423f9) {
        return this.#objLayer['findLabel'](_0x1423f9);
    }
    ['findLine'](_0x1c1cdf) {
        return this.#objLayer['findLine'](_0x1c1cdf);
    }
    ['findPolygon'](_0x2c6a23) {
        return this.#objLayer['findPolygon'](_0x2c6a23);
    }
    ['findRoute'](_0x4eb6ea) {
        return this.#objLayer['findRoute'](_0x4eb6ea);
    }
    ['removeImage'](_0x5cb3fa) {
        logi['maps']['Defines']['LOG_MODE'] && console['log']('[logi.maps]\x20[removeImage]\x20keyword:' + _0x5cb3fa), this.#objLayer['removeImage'](_0x5cb3fa);
    }
    ['removeImageAll'](_0x27a8fe) {
        logi['maps']['Defines']['LOG_MODE'] && console['log']('[logi.maps]\x20[removeImageAll]\x20excludedKeys:' + _0x27a8fe), this.#objLayer['removeImageAll'](_0x27a8fe);
    }
    ['removeLabel'](_0x20c1a4) {
        logi['maps']['Defines']['LOG_MODE'] && console['log']('[logi.maps]\x20[removeLabel]\x20keyword:' + _0x20c1a4), this.#objLayer['removeLabel'](_0x20c1a4);
    }
    ['removeLabelAll'](_0x2335cf) {
        logi['maps']['Defines']['LOG_MODE'] && console['log']('[logi.maps]\x20[removeLabelAll]\x20excludedKeys:' + _0x2335cf), this.#objLayer['removeLabelAll'](_0x2335cf);
    }
    ['removeLine'](_0x10e24f) {
        logi['maps']['Defines']['LOG_MODE'] && console['log']('[logi.maps]\x20[removeLine]\x20keyword:' + _0x10e24f), this.#objLayer['removeLine'](_0x10e24f);
    }
    ['removeLineAll'](_0x230a88) {
        logi['maps']['Defines']['LOG_MODE'] && console['log']('[logi.maps]\x20[removeLineAll]\x20excludedKeys:' + _0x230a88), this.#objLayer['removeLineAll'](_0x230a88);
    }
    ['removePolygon'](_0x26e6a0) {
        logi['maps']['Defines']['LOG_MODE'] && console['log']('[logi.maps]\x20[removePolygon]\x20keyword:' + _0x26e6a0), this.#objLayer['removePolygon'](_0x26e6a0);
    }
    ['removePolygonAll'](_0x33dd06) {
        logi['maps']['Defines']['LOG_MODE'] && console['log']('[logi.maps]\x20[removePolygonAll]\x20excludedKeys:' + _0x33dd06), this.#objLayer['removePolygonAll'](_0x33dd06);
    }
    ['removeRoute'](_0x489d05) {
        logi['maps']['Defines']['LOG_MODE'] && console['log']('[logi.maps]\x20[removeRoute]\x20keyword:' + _0x489d05), this.#objLayer['removeRoute'](_0x489d05);
    }
    ['removeRouteAll'](_0x227f8d) {
        logi['maps']['Defines']['LOG_MODE'] && console['log']('[logi.maps]\x20[removeRouteAll]\x20excludedKeys:' + _0x227f8d), this.#objLayer['removeRouteAll'](_0x227f8d);
    }
    ['removeAll'](_0x1632e1) {
        logi['maps']['Defines']['LOG_MODE'] && console['log']('[logi.maps]\x20[removeAll]\x20excludedKeys:' + _0x1632e1), this.#objLayer['removeAll'](_0x1632e1);
    }
    ['hitObj'](_0x356ca1, _0xa6f854) {
        return this.#objLayer['hitObj'](_0x356ca1, _0xa6f854);
    }
    ['hitImage'](_0x27924a, _0x1b619e) {
        return this.#objLayer['hitImage'](_0x27924a, _0x1b619e);
    }
    ['hitImageKey'](_0x409768, _0x2b0141) {
        const _0x263262 = this.#objLayer['hitImage'](_0x409768, _0x2b0141);
        if (_0x263262)
            return _0x263262['getKey']();
        return '';
    }
    #initEvent = function () {
        this.#isMobileEnv && (this.#surfaceCanvas['addEventListener']('touchstart', _0x4ed7d2 => this.#motionEventHandler(_0x4ed7d2)), this.#surfaceCanvas['addEventListener']('touchmove', _0x3a13c3 => this.#motionEventHandler(_0x3a13c3)), this.#surfaceCanvas['addEventListener']('touchend', _0x1f0065 => this.#motionEventHandler(_0x1f0065))), this.#surfaceCanvas['addEventListener']('contextmenu', _0x32c9e2 => _0x32c9e2['preventDefault']()), this.#surfaceCanvas['addEventListener']('dblclick', _0x4dcb4d => this.#motionEventHandler(_0x4dcb4d)), this.#surfaceCanvas['addEventListener']('mousedown', _0x2b738b => this.#motionEventHandler(_0x2b738b)), this.#surfaceCanvas['addEventListener']('mouseup', _0x4dd89c => this.#motionEventHandler(_0x4dd89c)), this.#surfaceCanvas['addEventListener']('mousemove', _0x1e69c8 => this.#motionEventHandler(_0x1e69c8)), this.#surfaceCanvas['addEventListener']('wheel', _0x5b3a48 => this.#motionEventHandler(_0x5b3a48));
    };
    #getCurTick = function () {
        let _0x316df4 = new Date();
        return _0x316df4['getTime']();
    };
    #getTouchPoints(_0x5ef015, _0x417d9a) {
        let _0x4e9da8 = new Array();
        for (let _0x56f5a4 of _0x417d9a) {
            _0x4e9da8['push']({
                'x': _0x56f5a4['clientX'] - _0x5ef015['left'],
                'y': _0x56f5a4['clientY'] - _0x5ef015['top']
            });
        }
        return _0x4e9da8;
    }
    #panMap = function (_0x595d96, _0x1c624d, _0x444b79, _0x994bc) {
        this.#mapCoord['panMap'](_0x595d96, _0x1c624d, _0x444b79, _0x994bc), this.#mapCoord['adjustMapBoundRect'](), this['updateMap']();
    };
    #motionEventHandler = function (_0x334369) {
        if (_0x334369['type'] == 'touchstart' || _0x334369['type'] == 'touchmove' || _0x334369['type'] == 'touchend') {
            _0x334369['targetTouches']['length'] <= 0x1 || _0x334369['type'] == 'touchend' ? this.#multiTouchActive = ![] : (this.#multiTouchActive = !![], this.#isDragging = ![]);
            if (_0x334369['type'] == 'touchstart') {
                this.#mapAreaDiv['focus']();
                let _0x4ce630 = this.#getTouchPoints(_0x334369['target']['getBoundingClientRect'](), _0x334369['targetTouches']);
                this.#multiTouchActive ? (this.#touchSingleDownStatus = ![], this.#touchPrevPoints = _0x4ce630, this.#lastAdditionalScale = this.#mapCoord['getAdditionalScale']()) : this.#touchSingleDown(_0x4ce630[0x0]['x'], _0x4ce630[0x0]['y']);
            } else {
                if (_0x334369['type'] == 'touchmove') {
                    let _0x13607d = this.#getTouchPoints(_0x334369['target']['getBoundingClientRect'](), _0x334369['targetTouches']);
                    this.#multiTouchActive ? (this.#touchSingleDownStatus = ![], this.#touchMultiScale(_0x13607d)) : this.#touchSingleMove(_0x13607d[0x0]['x'], _0x13607d[0x0]['y']);
                } else {
                    if (_0x334369['type'] == 'touchend') {
                        let _0x242cce = this.#getTouchPoints(_0x334369['target']['getBoundingClientRect'](), _0x334369['changedTouches']);
                        this.#touchUp(_0x242cce[0x0]['x'], _0x242cce[0x0]['y']);
                    }
                }
            }
        } else {
            if (_0x334369['type'] == 'dblclick')
                this['OnDoubleClick']?.(_0x334369);
            else {
                if (_0x334369['type'] == 'mousedown')
                    this.#mapAreaDiv['focus'](), _0x334369['buttons'] == 0x1 ? this.#mouseLButtonDown(_0x334369['offsetX'], _0x334369['offsetY']) : this.#mouseLButtonDownStatus = ![], this['OnMouseDown']?.(_0x334369);
                else {
                    if (_0x334369['type'] == 'mouseup')
                        this.#mouseLButtonUp(this.#mousePrevPoint['x'], this.#mousePrevPoint['y']), this['OnMouseUp']?.(_0x334369);
                    else {
                        if (_0x334369['type'] == 'mousemove') {
                            if (_0x334369['buttons'] == 0x1)
                                this.#mouseMove(_0x334369['offsetX'], _0x334369['offsetY']);
                            else
                                this.#mouseLButtonDownStatus == !![] && this.#mouseLButtonUp(this.#mousePrevPoint['x'], this.#mousePrevPoint['y']);
                            this['OnMouseMove']?.(_0x334369);
                        } else
                            _0x334369['type'] == 'wheel' && (this.#wheelEventCheck == !![] && (this.#mouseWheel(_0x334369['deltaY']), this['OnMapWheel']?.(_0x334369)));
                    }
                }
            }
        }
        this.#objLayer['triggerEvent'](_0x334369), this.#isMobileEnv && _0x334369['preventDefault']();
    };
    #touchSingleDown = function (_0xe3fee8, _0x5472e3) {
        this.#isDragging = ![], this.#captureFreezeCanvas(), this.#touchSingleDownStatus = !![], this.#touchStartPoint['x'] = _0xe3fee8, this.#touchStartPoint['y'] = _0x5472e3, this.#touchPrevPoint['x'] = _0xe3fee8, this.#touchPrevPoint['y'] = _0x5472e3, this.#bridgeMapEvents[logi['maps']['BRIDGE_MAPEVENT']['movestarted']] && logi['maps']['Bridge']['onMapEvent'](logi['maps']['BRIDGE_MAPEVENT']['movestarted']), this['updateMap']();
    };
    #touchUp = function (_0x6d02e2, _0x5f3344) {
        this.#isDragging = ![], this.#bridgeMapEvents[logi['maps']['BRIDGE_MAPEVENT']['movefinished']] && logi['maps']['Bridge']['onMapEvent'](logi['maps']['BRIDGE_MAPEVENT']['movefinished']), this.#touchSingleDownStatus == !![] && (Math['abs'](this.#touchStartPoint['x'] - _0x6d02e2) <= 0x2 && Math['abs'](this.#touchStartPoint['y'] - _0x5f3344) <= 0x2 && (this.#bridgeMapEvents[logi['maps']['BRIDGE_MAPEVENT']['touch']] && logi['maps']['Bridge']['onMapEvent'](logi['maps']['BRIDGE_MAPEVENT']['touch'], _0x6d02e2, _0x5f3344))), this.#touchSingleDownStatus = ![], this['updateMap']();
    };
    #touchSingleMove = function (_0x246733, _0x2eb7a9) {
        this.#touchSingleDownStatus == !![] && ((Math['abs'](this.#touchPrevPoint['x'] - _0x246733) > 0x4 || Math['abs'](this.#touchPrevPoint['y'] - _0x2eb7a9) > 0x4) && (this.#isDragging = !![], this.#panMap(this.#touchPrevPoint['x'], this.#touchPrevPoint['y'], _0x246733, _0x2eb7a9), this.#touchPrevPoint['x'] = _0x246733, this.#touchPrevPoint['y'] = _0x2eb7a9, this.#bridgeMapEvents[logi['maps']['BRIDGE_MAPEVENT']['moved']] && logi['maps']['Bridge']['onMapEvent'](logi['maps']['BRIDGE_MAPEVENT']['moved']), this['updateMap']()));
    };
    #mouseLButtonDown = function (_0x377224, _0x4dd370) {
        this.#isDragging = ![], this.#captureFreezeCanvas(), this.#mouseLButtonDownStatus = !![], this.#mousePrevPoint['x'] = _0x377224, this.#mousePrevPoint['y'] = _0x4dd370, this.#bridgeMapEvents[logi['maps']['BRIDGE_MAPEVENT']['movestarted']] && logi['maps']['Bridge']['onMapEvent'](logi['maps']['BRIDGE_MAPEVENT']['movestarted']), this['updateMap']();
    };
    #mouseLButtonUp = function (_0x554a75, _0x16f496) {
        this.#isDragging = ![], this.#mouseLButtonDownStatus = ![], this.#bridgeMapEvents[logi['maps']['BRIDGE_MAPEVENT']['movefinished']] && logi['maps']['Bridge']['onMapEvent'](logi['maps']['BRIDGE_MAPEVENT']['movefinished']), this['updateMap']();
    };
    #mouseMove = function (_0x1067fa, _0x480739) {
        this.#mouseLButtonDownStatus != !![] && (this.#isDragging = ![], this.#captureFreezeCanvas(), this.#mouseLButtonDownStatus = !![], this.#mousePrevPoint['x'] = _0x1067fa, this.#mousePrevPoint['y'] = _0x480739), (Math['abs'](this.#mousePrevPoint['x'] - _0x1067fa) > 0x4 || Math['abs'](this.#mousePrevPoint['y'] - _0x480739) > 0x4) && (this.#isDragging = !![], this.#panMap(this.#mousePrevPoint['x'], this.#mousePrevPoint['y'], _0x1067fa, _0x480739), this.#mousePrevPoint['x'] = _0x1067fa, this.#mousePrevPoint['y'] = _0x480739, this.#bridgeMapEvents[logi['maps']['BRIDGE_MAPEVENT']['moved']] && logi['maps']['Bridge']['onMapEvent'](logi['maps']['BRIDGE_MAPEVENT']['moved']), this['updateMap']());
    };
    #setAdditionalScale = function (_0x4035d6) {
        let _0x38ebb8 = null, _0x2afefd = 1.3, _0x5c1190 = 0.85;
        this.#mapTileDataType == logi['maps']['Defines']['MAP_TILE_DATA_SVG'] || this.#mapTileDataType == logi['maps']['Defines']['MAP_TILE_DATA_XVG'] ? (_0x2afefd = 1.5, _0x5c1190 = 0.75) : (_0x2afefd = 1.3, _0x5c1190 = 0.85);
        if (_0x4035d6 >= 0x2)
            _0x38ebb8 = 'zoomIn', _0x4035d6 = 0x1;
        else {
            if (_0x4035d6 <= 0.5)
                _0x38ebb8 = 'zoomOut', _0x4035d6 = 0x1;
            else {
                if (_0x4035d6 > _0x2afefd)
                    _0x38ebb8 = 'zoomIn', _0x4035d6 = _0x5c1190;
                else
                    _0x4035d6 < _0x5c1190 && (_0x38ebb8 = 'zoomOut', _0x4035d6 = _0x2afefd);
            }
        }
        let _0x2cf566 = Math['floor'](logi['maps']['Defines']['TILE_W'] * _0x4035d6);
        _0x2cf566 % 0x2 != 0x0 && (_0x2cf566 += 0x1);
        _0x4035d6 = _0x2cf566 / logi['maps']['Defines']['TILE_W'];
        _0x38ebb8 != null && (this.#lastAdditionalScale = _0x4035d6);
        const _0x24098f = this.#mapCoord['getAdditionalScale']();
        if (_0x38ebb8 != null || _0x24098f != _0x4035d6) {
            if (_0x38ebb8 == 'zoomIn')
                this.#mapCoord['setAdditionalScale'](_0x4035d6), this['zoomIn'](![]);
            else
                _0x38ebb8 == 'zoomOut' ? (this.#mapCoord['setAdditionalScale'](_0x4035d6), this['zoomOut'](![])) : (this.#mapCoord['setAdditionalScale'](_0x4035d6), this.#mapCoord['adjustMapBoundRect']());
            this.#renderMap(!![]);
        }
        return _0x38ebb8 != null;
    };
    #mouseWheel = function (_0x14aa25) {
        const _0x540afe = this.#getCurTick();
        if (this.#lastWheelTime + 0xc8 > _0x540afe)
            return;
        this.#lastWheelTime = _0x540afe;
        if (_0x14aa25 > 0x0)
            this['zoomOut']();
        else
            _0x14aa25 < 0x0 && this['zoomIn']();
    };
    #touchMultiScale = function (_0x409e43) {
        if (this.#touchPrevPoints['length'] >= 0x2 && _0x409e43['length'] >= 0x2) {
            const _0x2c63b2 = Math['sqrt'](Math['pow'](this.#touchPrevPoints[0x0]['x'] - this.#touchPrevPoints[0x1]['x'], 0x2) + Math['pow'](this.#touchPrevPoints[0x0]['y'] - this.#touchPrevPoints[0x1]['y'], 0x2)), _0xf9950b = Math['sqrt'](Math['pow'](_0x409e43[0x0]['x'] - _0x409e43[0x1]['x'], 0x2) + Math['pow'](_0x409e43[0x0]['y'] - _0x409e43[0x1]['y'], 0x2));
            let _0x4e21db = ![];
            const _0x4d0189 = 0x28;
            let _0x2b65a7 = parseInt((_0x2c63b2 - _0xf9950b) * 0.1), _0xaceede = _0x2b65a7 / _0x4d0189;
            if (this.#lastAdditionalScale > 0x1)
                _0xaceede += (this.#lastAdditionalScale - 0x1) / -0x1;
            else
                this.#lastAdditionalScale < 0x1 && (_0xaceede += (this.#lastAdditionalScale - 0x1) / -0.5);
            if (_0xaceede < 0x0)
                this['isZoomInMax']() ? (_0x4e21db = !![], this.#lastAdditionalScale = 0x1) : _0x4e21db = this.#setAdditionalScale(0x1 + -_0xaceede * 0x1);
            else
                _0xaceede > 0x0 && (this['isZoomOutMax']() ? (_0x4e21db = !![], this.#lastAdditionalScale = 0x1) : _0x4e21db = this.#setAdditionalScale(0x1 + -_0xaceede * 0.5));
            _0x4e21db == !![] && (this.#touchPrevPoints = _0x409e43);
        }
    };
    #checkUseMobile = function () {
        const _0x32da8a = 'win16|win32|win64|mac|macintel|navigator.platform|linux\x20x86_64';
        if (navigator['platform'])
            return 0x0 > _0x32da8a['indexOf'](navigator['platform']['toLowerCase']());
        return ![];
    };
    #loadServerUrl = function (_0x373cfa) {
        this.#remoteServerUrl = logi['maps']['Config']['server_url'], _0x373cfa ? this.#remoteServerUrl = _0x373cfa : typeof process !== 'undefined' && process?.['env']?.['NODE_ENV'] !== undefined && (process['env']['NODE_ENV'] === 'prod' ? this.#remoteServerUrl = logi['maps']['Config']['prod_server_url'] : this.#remoteServerUrl = logi['maps']['Config']['dev_server_url']);
    };
    #getMapDataVersionUrl = function (_0x4e917c) {
        if (_0x4e917c === 0x0 && this.#remoteServerUrl !== '')
            return this.#remoteServerUrl + '/getmapversion';
        return '';
    };
    #getMapDataUrl = function (_0x35e423, _0x573a5e, _0x1b1587, _0x13a1aa) {
        if (_0x35e423 === 0x0 && this.#remoteServerUrl !== '') {
            if (this.#mapTileDataType == logi['maps']['Defines']['MAP_TILE_DATA_SVG'])
                return this.#remoteServerUrl + '/svg?zoom=' + _0x573a5e + '&tilex=' + _0x1b1587 + '&tiley=' + _0x13a1aa;
            else
                return this.#mapTileDataType == logi['maps']['Defines']['MAP_TILE_DATA_XVG'] ? this.#remoteServerUrl + '/xvg?zoom=' + _0x573a5e + '&tilex=' + _0x1b1587 + '&tiley=' + _0x13a1aa : this.#remoteServerUrl + '/png?zoom=' + _0x573a5e + '&tilex=' + _0x1b1587 + '&tiley=' + _0x13a1aa;
        } else {
            if (_0x35e423 === 0x1)
                return 'https://mt.google.com/vt/lyrs=m&x=' + _0x1b1587 + '&y=' + _0x13a1aa + '&z=' + _0x573a5e;
            else {
                if (_0x35e423 === 0x2)
                    return 'https://maps.wikimedia.org/osm-intl/' + _0x573a5e + '/' + _0x1b1587 + '/' + _0x13a1aa + '.png';
                else {
                    if (_0x35e423 === 0x3)
                        return 'http://c.tile.stamen.com/watercolor/' + _0x573a5e + '/' + _0x1b1587 + '/' + _0x13a1aa + '.png';
                    else {
                        if (_0x35e423 === 0x4)
                            return 'http://a.tile.stamen.com/toner/' + _0x573a5e + '/' + _0x1b1587 + '/' + _0x13a1aa + '.png';
                    }
                }
            }
        }
        return '';
    };
    #setRenderFrame(_0x181098) {
        this.#currentFps != _0x181098 && (this.#loopRenderId != 0x0 && (clearInterval(this.#loopRenderId), this.#loopRenderId = 0x0), this.#currentFps = _0x181098, this.#loopRenderId = setInterval(() => {
            this.#renderMap();
        }, Math['round'](0x3e8 / _0x181098)));
    }
    #renderMap(_0x1f5422 = ![]) {
        const _0x3f143a = this.#getWindowDevicePixelRatio();
        (this.#mapScreenSize['width'] != this.#mapDiv['clientWidth'] || this.#mapScreenSize['height'] != this.#mapDiv['clientHeight'] || this.#devicePixelRatio != _0x3f143a) && this['setScreenSize']({
            'width': this.#mapDiv['clientWidth'],
            'height': this.#mapDiv['clientHeight']
        }, _0x3f143a);
        if (_0x1f5422) {
            this.#updateFlag = !![];
            for (let _0x5578a8 of this.#mapLayers) {
                _0x5578a8['setUpdateFlag'](!![]);
            }
        }
        if (this.#tileMapsWaitingForResponse['size'] >= this.#MAX_WAITING_TILE_MAPS_SIZE) {
            const _0x90e4c3 = this.#getCurTick();
            for (const [_0x20768b, _0x3970ab] of this.#tileMapsWaitingForResponse) {
                _0x90e4c3 > _0x3970ab + this.#MAX_WAITING_TILE_MAPS_TIME && this.#tileMapsWaitingForResponse['delete'](_0x20768b);
            }
            return;
        }
        this.#updateFlag |= this.#mapResource['getUpdateFlag'](), this.#mapResource['setUpdateFlag'](![]);
        let _0x4a846d = ![];
        this.#updateFlag ? _0x4a846d = !![] : _0x4a846d = this.#mapLayers['filter'](function (_0x12dad0) {
            return _0x12dad0['getUpdateFlag']() == !![];
        })['length'] > 0x0;
        if (_0x4a846d)
            this.#drawMap(), this.#setRenderFrame(logi['maps']['Defines']['FPS_MEDIUM']), this.#lastRenderTick = logi['maps']['Utils']['getCurTick']();
        else {
            if (this.#currentFps > logi['maps']['Defines']['FPS_LOW'])
                logi['maps']['Utils']['getCurTick']() - this.#lastRenderTick >= logi['maps']['Defines']['SLOWMODE_TIME'] && this.#setRenderFrame(logi['maps']['Defines']['FPS_LOW']);
            else
                this.#currentFps > logi['maps']['Defines']['FPS_SLEEP'] && (logi['maps']['Utils']['getCurTick']() - this.#lastRenderTick >= logi['maps']['Defines']['SLEEPMODE_TIME'] && this.#setRenderFrame(logi['maps']['Defines']['FPS_SLEEP']));
        }
        this.#mapDatabase['putMapTileDatas'](), this.#mapDatabase['resizeDatabase']();
    }
    #captureFreezeCanvas = function () {
        this.#canvasWorldPosition = this['screen2world']({
            'x': this.#mapScreenSize['width'] * 0.5,
            'y': this.#mapScreenSize['height'] * 0.5
        });
        for (let _0x5075ad of this.#mapLayers) {
            _0x5075ad['captureFreezeCanvas']?.();
        }
    };
    #drawMap = function () {
        try {
            const _0x38b0fc = this;
            if (this.#updateFlag) {
                const _0x5886d0 = this.#mapCoord['getTilesOnScreen'](), _0x249f26 = new Array(), _0x145f97 = this.#mapTilesCanvas['getContext']('2d');
                _0x145f97['clearRect'](0x0, 0x0, this.#mapTilesCanvas['width'], this.#mapTilesCanvas['height']), this.#clearBackground(_0x145f97, this.#mapTilesCanvas['width'], this.#mapTilesCanvas['height']);
                const _0x277c00 = this.#mapNotationCanvas['getContext']('2d');
                _0x277c00['clearRect'](0x0, 0x0, this.#mapNotationCanvas['width'], this.#mapNotationCanvas['height']);
                for (let _0x4401ec of _0x5886d0) {
                    const _0x596384 = this.#tileMapCache['get'](_0x4401ec['getId']());
                    if (_0x596384)
                        this.#drawTileOnCanvas(_0x4401ec, _0x596384);
                    else {
                        const _0x58a452 = this.#tileMapsWaitingForResponse['get'](_0x4401ec['getId']()), _0x5ebb83 = this.#notFoundTileMaps['get'](_0x4401ec['getId']());
                        !_0x58a452 && !_0x5ebb83 && (this.#tileMapsWaitingForResponse['set'](_0x4401ec['getId'](), this.#getCurTick()), _0x249f26['push'](_0x4401ec));
                    }
                }
                const _0x43eb8a = this.#mapDatabase['getMapTileTasks'](_0x249f26);
                for (let _0xd1369 of _0x43eb8a) {
                    _0xd1369['then'](_0x3cc868 => {
                        if (this.#mapTileDataType == logi['maps']['Defines']['MAP_TILE_DATA_SVG'] || this.#mapTileDataType == logi['maps']['Defines']['MAP_TILE_DATA_XVG']) {
                            const _0x191108 = _0x3cc868['tile'], _0x2ffb62 = _0x3cc868['data'];
                            if (_0x2ffb62) {
                                const _0x33d65e = _0x38b0fc.#getMapTile(_0x2ffb62, _0x191108['getLevel']());
                                _0x38b0fc.#drawSvgOnCanvas(_0x191108, _0x33d65e);
                            } else {
                                const _0x1b4373 = _0x38b0fc.#getMapDataUrl(_0x38b0fc.#mapKind, _0x191108['getLevel'](), _0x191108['getTileX'](), _0x191108['getTileY']());
                                fetch(_0x1b4373)['then'](_0xdd753b => {
                                    const _0x1dfbed = parseInt(_0xdd753b['headers']['get']('content-length') ?? 0x3e7);
                                    return _0xdd753b['ok'] && _0x1dfbed > 0x0 ? _0xdd753b['text']() : (console['log']('[logi.maps]\x20ERROR:\x20' + _0xdd753b['status'] + ',\x20' + _0xdd753b['statusText']), '');
                                })['then'](_0x242e98 => {
                                    if (_0x242e98['length'] <= 0x20)
                                        console['log']('[logi.maps]\x20MapTile(' + _0x191108['getLevel']() + ',\x20' + _0x191108['getTileX']() + ',\x20' + _0x191108['getTileY']() + ')\x20>>\x20(' + _0x242e98 + ')'), _0x38b0fc.#tileMapsWaitingForResponse['delete'](_0x191108['getId']()), _0x38b0fc.#notFoundTileMaps['set'](_0x191108['getId'](), _0x38b0fc.#getCurTick());
                                    else {
                                        const _0xb55dec = this.#mapTileDataType == logi['maps']['Defines']['MAP_TILE_DATA_XVG'] ? logi['maps']['Utils']['decompressFromUTF16'](_0x242e98) : _0x242e98, _0x844418 = _0x38b0fc.#parseSvgTile(_0xb55dec);
                                        _0x38b0fc.#mapDatabase['addMapTileData'](_0x191108['getId'](), _0x844418);
                                        const _0x1154f1 = _0x38b0fc.#getMapTile(_0x844418, _0x191108['getLevel']());
                                        _0x38b0fc.#drawSvgOnCanvas(_0x191108, _0x1154f1);
                                    }
                                });
                            }
                        } else {
                            const _0x4e1d09 = _0x3cc868['tile'], _0x2e96df = _0x3cc868['data'];
                            if (_0x2e96df)
                                _0x38b0fc.#drawPngOnCanvas(_0x4e1d09, _0x2e96df);
                            else {
                                const _0x58f6f3 = _0x38b0fc.#getMapDataUrl(_0x38b0fc.#mapKind, _0x4e1d09['getLevel'](), _0x4e1d09['getTileX'](), _0x4e1d09['getTileY']());
                                fetch(_0x58f6f3)['then'](_0x46bf4a => {
                                    const _0x40e0c4 = parseInt(_0x46bf4a['headers']['get']('content-length') ?? 0x3e7);
                                    return _0x46bf4a['ok'] && _0x40e0c4 > 0x0 ? _0x46bf4a['blob']() : (console['log']('[logi.maps]\x20ERROR:\x20' + _0x46bf4a['status'] + ',\x20' + _0x46bf4a['statusText']), new Blob([]));
                                })['then'](_0x569f10 => {
                                    _0x569f10['size'] <= 0x20 ? _0x569f10['text']()['then'](_0x133841 => {
                                        console['log']('[logi.maps]\x20MapTile(' + _0x4e1d09['getLevel']() + ',\x20' + _0x4e1d09['getTileX']() + ',\x20' + _0x4e1d09['getTileY']() + ')\x20>>\x20(' + _0x133841 + ')'), _0x38b0fc.#tileMapsWaitingForResponse['delete'](_0x4e1d09['getId']()), _0x38b0fc.#notFoundTileMaps['set'](_0x4e1d09['getId'](), _0x38b0fc.#getCurTick());
                                    }) : (_0x38b0fc.#mapDatabase['addMapTileData'](_0x4e1d09['getId'](), _0x569f10), _0x38b0fc.#drawPngOnCanvas(_0x4e1d09, _0x569f10));
                                });
                            }
                        }
                    })['catch'](_0x47d584 => {
                        console['log']('[logi.maps]\x20ERROR:\x20' + _0x47d584);
                    });
                }
                {
                    const _0x187e6e = this.#getCurTick();
                    for (const [_0x5883dd, _0x286098] of this.#tileMapsWaitingForResponse) {
                        _0x187e6e > _0x286098 + this.#MAX_WAITING_TILE_MAPS_TIME && this.#tileMapsWaitingForResponse['delete'](_0x5883dd);
                    }
                    for (const [_0x47a5d7, _0x192a7f] of this.#notFoundTileMaps) {
                        _0x187e6e > _0x192a7f + this.#MAX_NOT_FOUND_TILE_MAPS_TIME && this.#notFoundTileMaps['delete'](_0x47a5d7);
                    }
                }
                if (this.#tileMapCache['size'] > this.#MAX_TILE_MAP_CACHE_SIZE) {
                    let _0x4dbf5d = parseInt(this.#MAX_TILE_MAP_CACHE_SIZE * 0.5);
                    for (const [_0x1fd3db, _0x4e0a26] of this.#tileMapCache) {
                        if (_0x4dbf5d <= 0x0)
                            break;
                        _0x4dbf5d -= 0x1, this.#mapTileDataType == logi['maps']['Defines']['MAP_TILE_DATA_SVG'] || this.#mapTileDataType == logi['maps']['Defines']['MAP_TILE_DATA_XVG'] ? this.#tileOffCanvasCache['push'](_0x4e0a26['map']) : URL['revokeObjectURL'](_0x4e0a26['map']['src']), this.#tileMapCache['delete'](_0x1fd3db);
                    }
                }
            }
            for (let _0x287836 of this.#mapLayers) {
                if (_0x287836['getUpdateFlag']()) {
                    if (this.#isDragging && this.#freezeModeOnMoving && _0x287836['drawFreezeCanvas']) {
                        let _0x54eb81 = this['world2screen'](this.#canvasWorldPosition);
                        _0x54eb81['x'] -= this.#mapScreenSize['width'] * 0.5, _0x54eb81['y'] -= this.#mapScreenSize['height'] * 0.5, _0x287836['drawFreezeCanvas'](_0x54eb81['x'], _0x54eb81['y']);
                    } else
                        _0x287836['draw']();
                }
            }
            this['OnDraw'] && this['OnDraw']();
        } catch (_0x4dbf87) {
            console['log']('[logi.maps]\x20Failed\x20to\x20drawMap\x20(' + _0x4dbf87 + ')');
        } finally {
            for (let _0xf9109a of this.#mapLayers) {
                _0xf9109a['setUpdateFlag'](![]);
            }
            this.#updateFlag = ![];
        }
    };
    #drawPngOnCanvas = function (_0x50dde9, _0x22996f) {
        const _0x3fbc91 = this;
        let _0x5f1aee = new Image();
        _0x5f1aee['src'] = URL['createObjectURL'](_0x22996f), _0x5f1aee['onload'] = ((_0x1ad658, _0x2e970f) => {
            return () => {
                let _0x4ad17f = {
                    'country': null,
                    'map': _0x1ad658,
                    'notation': null
                };
                _0x2e970f['getLevel']() == _0x3fbc91['getLevel']() && _0x3fbc91.#drawTileOnCanvas(_0x2e970f, _0x4ad17f), _0x3fbc91.#tileMapCache['set'](_0x2e970f['getId'](), _0x4ad17f), _0x3fbc91.#tileMapsWaitingForResponse['delete'](_0x2e970f['getId']());
            };
        })(_0x5f1aee, _0x50dde9);
    };
    #parseSvgTile = function (_0x3e8655) {
        const _0x498b38 = function (_0xc1a53) {
                const _0x35df6c = _0xc1a53['getAttribute']('dtype'), _0x243d22 = _0xc1a53['getAttribute']('layer'), _0x35ee5a = new Array();
                for (const _0x2aeab2 of _0xc1a53['children']) {
                    _0x35ee5a['push']({
                        'categoryId': parseInt(_0x2aeab2['getAttribute']('cat')),
                        'pathData': _0x2aeab2['getAttribute']('d')
                    });
                }
                return {
                    'drawType': _0x35df6c,
                    'layerName': _0x243d22,
                    'fixedScale': ![],
                    'itemElems': _0x35ee5a
                };
            }, _0x1332b0 = function (_0x24f244) {
                const _0x5b81da = _0x24f244['getAttribute']('dtype'), _0xc22db3 = _0x24f244['getAttribute']('layer'), _0x5a5650 = _0x24f244['getAttribute']('fixed'), _0x1fa16 = new Array();
                for (const _0x21d955 of _0x24f244['children']) {
                    const _0xb18df5 = {
                        'categoryId': 0x0,
                        'transform': null,
                        'image': {
                            'x': 0x0,
                            'y': 0x0,
                            'transform': null
                        },
                        'text': {
                            'x': 0x0,
                            'y': 0x0,
                            'textContent': ''
                        }
                    };
                    _0xb18df5['categoryId'] = parseInt(_0x21d955['getAttribute']('cat')), _0xb18df5['transform'] = _0x21d955['getAttribute']('transform');
                    for (const _0x5eb21d of _0x21d955['children']) {
                        _0x5eb21d['tagName'] == 'image' && (_0xb18df5['image']['x'] = parseFloat(_0x5eb21d['getAttribute']('x')), _0xb18df5['image']['y'] = parseFloat(_0x5eb21d['getAttribute']('y')), _0xb18df5['image']['transform'] = _0x5eb21d['getAttribute']('transform')), _0x5eb21d['tagName'] == 'text' && (_0xb18df5['text']['x'] = parseFloat(_0x5eb21d['getAttribute']('x')), _0xb18df5['text']['y'] = parseFloat(_0x5eb21d['getAttribute']('y')), _0xb18df5['text']['textContent'] = _0x5eb21d['textContent']);
                    }
                    _0x1fa16['push'](_0xb18df5);
                }
                return {
                    'drawType': _0x5b81da,
                    'layerName': _0xc22db3,
                    'fixedScale': _0x5a5650 == '1',
                    'itemElems': _0x1fa16
                };
            }, _0x2f5c09 = function (_0x207b01) {
                const _0x295398 = _0x207b01['getAttribute']('dtype'), _0x2029fb = _0x207b01['getAttribute']('layer'), _0x5059b0 = _0x207b01['getAttribute']('fixed'), _0xfec4d8 = new Array();
                for (const _0x1c36dd of _0x207b01['children']) {
                    _0xfec4d8['push']({
                        'categoryId': parseInt(_0x1c36dd['getAttribute']('cat')),
                        'x': parseFloat(_0x1c36dd['getAttribute']('x')),
                        'y': parseFloat(_0x1c36dd['getAttribute']('y')),
                        'transform': _0x1c36dd['getAttribute']('transform'),
                        'textContent': _0x1c36dd['textContent']
                    });
                }
                return {
                    'drawType': _0x295398,
                    'layerName': _0x2029fb,
                    'fixedScale': _0x5059b0 == '1',
                    'itemElems': _0xfec4d8
                };
            }, _0x4764f9 = new DOMParser(), _0x4af972 = _0x4764f9['parseFromString'](_0x3e8655, 'image/svg+xml'), _0x425390 = _0x4af972['documentElement'], _0x38f715 = _0x4af972['getElementsByTagName']('svg')[0x0], _0x5595c2 = parseInt(_0x38f715?.['getAttribute']('width') ?? logi['maps']['Defines']['TILE_W']), _0x3b3716 = parseInt(_0x38f715?.['getAttribute']('height') ?? logi['maps']['Defines']['TILE_H']), _0x4d65a6 = _0x38f715?.['getAttribute']('xc') ?? logi['maps']['Defines']['COUNTRY_KOR'], _0x174dfb = {
                'country': _0x4d65a6,
                'width': _0x5595c2,
                'height': _0x3b3716,
                'elemGroups': new Array()
            };
        for (const _0x70a960 of _0x425390['children']) {
            const _0x9b8828 = _0x70a960['getAttribute']('dtype');
            let _0x15ddae = null;
            if (_0x70a960['tagName'] == 'use') {
                let _0x4c834a = _0x70a960['getAttribute']('href');
                _0x4c834a = _0x4c834a['startsWith']('#') ? _0x4c834a['substring'](0x1) : _0x4c834a;
                for (const _0x5ef9b0 of _0x425390['children']) {
                    if (_0x5ef9b0['getAttribute']('id') == _0x4c834a) {
                        _0x15ddae = _0x5ef9b0;
                        break;
                    }
                }
            } else
                _0x15ddae = _0x70a960;
            if (_0x15ddae)
                switch (_0x9b8828) {
                case logi['maps']['Defines']['MAP_DRAW_TYPE_POLYGON']:
                    _0x174dfb['elemGroups']['push'](_0x498b38(_0x15ddae));
                    break;
                case logi['maps']['Defines']['MAP_DRAW_TYPE_POLYLINE']:
                    _0x174dfb['elemGroups']['push'](_0x498b38(_0x15ddae));
                    break;
                case logi['maps']['Defines']['MAP_DRAW_TYPE_SYMBOL']:
                    _0x174dfb['elemGroups']['push'](_0x1332b0(_0x15ddae));
                    break;
                case logi['maps']['Defines']['MAP_DRAW_TYPE_TEXT']:
                    _0x174dfb['elemGroups']['push'](_0x2f5c09(_0x15ddae));
                    break;
                }
        }
        return _0x174dfb;
    };
    #getMapTile = function (_0x2b38c2, _0x532bab) {
        const _0x5ca0f2 = this.#tileOffCanvasCache['length'] > 0x0 ? this.#tileOffCanvasCache['pop']() : new OffscreenCanvas(logi['maps']['Defines']['TILE_W'] * this.#tileOffCanvasPixelRatio, logi['maps']['Defines']['TILE_H'] * this.#tileOffCanvasPixelRatio), _0x50de02 = _0x5ca0f2['getContext']('2d');
        _0x50de02['save'](), _0x50de02['scale'](this.#tileOffCanvasPixelRatio, this.#tileOffCanvasPixelRatio), _0x50de02['scale'](logi['maps']['Defines']['TILE_W'] / _0x2b38c2['width'], logi['maps']['Defines']['TILE_H'] / _0x2b38c2['height']);
        const _0x2e2562 = _0x2b38c2['country'], _0x45b5ed = {
                'symbols': [],
                'texts': []
            };
        _0x50de02['clearRect'](0x0, 0x0, logi['maps']['Defines']['TILE_W'], logi['maps']['Defines']['TILE_H']);
        for (const _0x32b23c of _0x2b38c2['elemGroups']) {
            const _0x39553b = _0x32b23c['drawType'], _0x5e38ea = _0x32b23c['layerName'], _0x1c1488 = _0x32b23c['fixedScale'], _0x27402c = _0x32b23c['itemElems'];
            switch (_0x39553b) {
            case logi['maps']['Defines']['MAP_DRAW_TYPE_POLYGON']:
                this.#drawPolygonElems(_0x50de02, _0x5e38ea, _0x532bab, _0x27402c);
                break;
            case logi['maps']['Defines']['MAP_DRAW_TYPE_POLYLINE']:
                this.#drawPolylineElems(_0x50de02, _0x5e38ea, _0x532bab, _0x27402c);
                break;
            case logi['maps']['Defines']['MAP_DRAW_TYPE_SYMBOL']:
                _0x1c1488 == !![] ? _0x45b5ed['symbols']['push']({
                    'layerName': _0x5e38ea,
                    'itemElems': _0x27402c
                }) : this.#drawSymbolElems(_0x50de02, _0x5e38ea, _0x532bab, _0x27402c);
                break;
            case logi['maps']['Defines']['MAP_DRAW_TYPE_TEXT']:
                _0x1c1488 == !![] ? _0x45b5ed['texts']['push']({
                    'layerName': _0x5e38ea,
                    'itemElems': _0x27402c
                }) : this.#drawTextElems(_0x50de02, _0x5e38ea, _0x532bab, _0x27402c);
                break;
            }
        }
        return _0x50de02['restore'](), {
            'country': _0x2e2562,
            'map': _0x5ca0f2,
            'notation': _0x45b5ed
        };
    };
    #drawSvgOnCanvas = function (_0xa4e261, _0x5248ee) {
        _0xa4e261['getLevel']() == this['getLevel']() && this.#drawTileOnCanvas(_0xa4e261, _0x5248ee), this.#tileMapCache['set'](_0xa4e261['getId'](), _0x5248ee), this.#tileMapsWaitingForResponse['delete'](_0xa4e261['getId']());
    };
    #drawTileOnCanvas = function (_0x8a73e0, _0x2188c1) {
        const _0x351341 = this.#mapCoord['tileXY2screen'](_0x8a73e0['getTileX'](), _0x8a73e0['getTileY'](), _0x8a73e0['getLevel']()), _0x4d8792 = this.#mapCoord['getAdditionalScale'](), _0x50d4c6 = _0x8a73e0['getLevel']();
        if (_0x2188c1['map']) {
            const _0x383c84 = this.#mapTilesCanvas['getContext']('2d'), _0x10173e = this.#devicePixelRatio == parseInt(this.#devicePixelRatio) ? 0x0 : 0.5;
            _0x383c84['save'](), _0x383c84['scale'](this.#devicePixelRatio, this.#devicePixelRatio);
            const _0x402e85 = _0x351341['x'] - _0x10173e, _0x3b0c0b = _0x351341['y'] - _0x10173e, _0x199323 = logi['maps']['Defines']['TILE_W'] * _0x4d8792 + _0x10173e, _0x4ba59d = logi['maps']['Defines']['TILE_H'] * _0x4d8792 + _0x10173e;
            _0x383c84['drawImage'](_0x2188c1['map'], _0x402e85, _0x3b0c0b, _0x199323, _0x4ba59d), _0x383c84['restore']();
        }
        if (_0x2188c1['notation']) {
            const _0x2698e8 = this.#mapNotationCanvas['getContext']('2d');
            _0x2698e8['save'](), _0x2698e8['scale'](this.#devicePixelRatio, this.#devicePixelRatio), _0x2698e8['translate'](_0x351341['x'], _0x351341['y']);
            for (const _0x567148 of _0x2188c1['notation']['texts']) {
                this.#drawTextElems(_0x2698e8, _0x567148['layerName'], _0x50d4c6, _0x567148['itemElems'], _0x4d8792);
            }
            for (const _0x5ddf26 of _0x2188c1['notation']['symbols']) {
                this.#drawSymbolElems(_0x2698e8, _0x5ddf26['layerName'], _0x50d4c6, _0x5ddf26['itemElems'], _0x4d8792);
            }
            _0x2698e8['restore']();
        }
    };
    #drawPolygonElems(_0x4f469b, _0x23878d, _0x895b37, _0x1631fe) {
        for (const _0x1a62cf of _0x1631fe) {
            const _0x532227 = this.#mapResource['getPolygonStyle'](_0x23878d, _0x1a62cf['categoryId'], _0x895b37);
            _0x532227 && _0x1a62cf['pathData'] && (_0x1a62cf['pathData'] instanceof Path2D ? this.#drawPolygon(_0x4f469b, _0x1a62cf['pathData'], _0x532227['fillColor'] ?? '#000000', 0x0, '#000000') : this.#drawPolygon(_0x4f469b, new Path2D(_0x1a62cf['pathData']), _0x532227['fillColor'] ?? '#000000', 0x0, '#000000'));
        }
    }
    #drawPolylineElems(_0x21467c, _0x1ffe7c, _0x3cb0bb, _0x4a89dd) {
        for (const _0x57673e of _0x4a89dd) {
            const _0x2df94b = this.#mapResource['getPolylineStyle'](_0x1ffe7c, _0x57673e['categoryId'], _0x3cb0bb);
            _0x2df94b && _0x57673e['pathData'] && (_0x57673e['pathData'] instanceof Path2D ? this.#drawPolyline(_0x21467c, _0x57673e['pathData'], _0x2df94b['width'], _0x2df94b['color']) : this.#drawPolyline(_0x21467c, new Path2D(_0x57673e['pathData']), _0x2df94b['width'], _0x2df94b['color']));
        }
    }
    #drawSymbolElems(_0xfa6d66, _0x2a766f, _0x4d372f, _0x3bdb2a, _0x1ad35e = 0x1) {
        let _0x212584 = undefined, _0x265cee = undefined;
        for (const _0x57f372 of _0x3bdb2a) {
            _0x265cee != _0x57f372['categoryId'] && (_0x212584 = this.#mapResource['getSymbolStyle'](_0x2a766f, _0x57f372['categoryId'], _0x4d372f), _0x265cee = _0x57f372['categoryId']);
            if (_0x212584) {
                const _0x215428 = this.#mapResource['getSymbol'](_0x57f372['categoryId']);
                this.#drawSymbol(_0xfa6d66, _0x215428, _0x57f372['transform'], _0x57f372['image']['x'], _0x57f372['image']['y'], _0x57f372['image']['transform'], _0x57f372['text']['textContent'], _0x57f372['text']['x'], _0x57f372['text']['y'], _0x212584['fontFamily'], _0x212584['fontSize'], _0x212584['textColor'], _0x212584['textAlign'], _0x212584['stroke'], _0x212584['strokeColor'], _0x1ad35e);
            }
        }
    }
    #drawTextElems(_0x52aa3d, _0x5019aa, _0x22fd5a, _0x2818ed, _0xb3d65f = 0x1) {
        let _0x1b0c1e = undefined, _0x1b507d = undefined;
        for (const _0x31dfad of _0x2818ed) {
            _0x1b507d != _0x31dfad['categoryId'] && (_0x1b0c1e = this.#mapResource['getTextStyle'](_0x5019aa, _0x31dfad['categoryId'], _0x22fd5a), _0x1b507d = _0x31dfad['categoryId']), _0x1b0c1e && this.#drawText(_0x52aa3d, _0x31dfad['textContent'], _0x31dfad['x'], _0x31dfad['y'], _0x31dfad['transform'], _0x1b0c1e['fontFamily'], _0x1b0c1e['fontSize'], _0x1b0c1e['textColor'], _0x1b0c1e['textAlign'], _0x1b0c1e['stroke'], _0x1b0c1e['strokeColor'], _0xb3d65f);
        }
    }
    #clearBackground(_0x32ea76, _0x590f01, _0x309e18) {
        const _0x14964a = this.#mapResource['getColorStyle']('bg', 0x0, 0x0);
        _0x14964a && (_0x32ea76['fillStyle'] = _0x14964a['color'], _0x32ea76['fillRect'](0x0, 0x0, _0x590f01, _0x309e18));
    }
    #drawPolygon(_0x2d83a9, _0x2551fb, _0x4b6a43, _0x304834, _0x49dcc7) {
        _0x2d83a9['save'](), _0x2d83a9['fillStyle'] = _0x4b6a43, _0x2d83a9['fill'](_0x2551fb), _0x304834 > 0x0 && (_0x2d83a9['lineWidth'] = _0x304834, _0x2d83a9['strokeStyle'] = _0x49dcc7, _0x2d83a9['stroke'](_0x2551fb)), _0x2d83a9['restore']();
    }
    #drawPolyline(_0x30813f, _0x3174ed, _0x1dfecf, _0x1c386e) {
        _0x30813f['save'](), _0x30813f['lineWidth'] = _0x1dfecf, _0x30813f['strokeStyle'] = _0x1c386e, _0x30813f['stroke'](_0x3174ed), _0x30813f['restore']();
    }
    #applyTransform(_0x37145d, _0x25ade2, _0x30a8a6 = 0x1) {
        const _0x4fd10 = _0x25ade2?.['match'](/(\w+\([^)]+\))/g) || [];
        _0x4fd10['forEach'](function (_0x1043f3) {
            const _0x4bf1fb = _0x1043f3['split']('('), _0x202ce0 = _0x4bf1fb[0x0], _0x15fde7 = _0x4bf1fb[0x1]['split'](')')[0x0]['split'](',');
            if (_0x15fde7['length'] >= 0x1)
                switch (_0x202ce0) {
                case 'rotate': {
                        if (_0x15fde7['length'] == 0x3) {
                            var _0x3348f3 = parseFloat(_0x15fde7[0x0]), _0x4cb4e5 = parseFloat(_0x15fde7[0x1]), _0x291c37 = parseFloat(_0x15fde7[0x2]);
                            _0x37145d['translate'](_0x4cb4e5 * _0x30a8a6, _0x291c37 * _0x30a8a6), _0x37145d['rotate'](logi['maps']['Utils']['degToRad'](_0x3348f3)), _0x37145d['translate'](-_0x4cb4e5 * _0x30a8a6, -_0x291c37 * _0x30a8a6);
                        } else {
                            var _0x3348f3 = parseFloat(_0x15fde7[0x0]);
                            _0x37145d['rotate'](logi['maps']['Utils']['degToRad'](_0x3348f3));
                        }
                    }
                    break;
                case 'scale': {
                        var _0x39a8e7 = parseFloat(_0x15fde7[0x0]), _0x36d421 = _0x15fde7['length'] > 0x1 ? parseFloat(_0x15fde7[0x1]) : _0x39a8e7;
                        _0x37145d['scale'](_0x39a8e7, _0x36d421);
                    }
                    break;
                case 'translate': {
                        var _0x391005 = parseFloat(_0x15fde7[0x0]), _0x53722a = _0x15fde7['length'] > 0x1 ? parseFloat(_0x15fde7[0x1]) : 0x0;
                        _0x37145d['translate'](_0x391005 * _0x30a8a6, _0x53722a * _0x30a8a6);
                    }
                    break;
                }
        });
    }
    #drawSymbol = function (_0x674b8a, _0x191cc2, _0x382aa4, _0x4f01d1, _0x49e787, _0x13beb5, _0x34d078, _0x4051ec, _0x10e8c1, _0x355674, _0x3a55cd, _0x23e84e, _0x4e8a25, _0x3a5365, _0x4e419b, _0x4708bb) {
        _0x674b8a['save'](), this.#applyTransform(_0x674b8a, _0x382aa4, _0x4708bb), _0x191cc2 && (_0x674b8a['save'](), this.#applyTransform(_0x674b8a, _0x13beb5), _0x674b8a['translate'](_0x4f01d1, _0x49e787), _0x674b8a['drawImage'](_0x191cc2, 0x0, 0x0), _0x674b8a['restore']()), _0x355674 != '' && document['fonts'] && !document['fonts']['check']('1em\x20' + _0x355674) && (_0x355674 = ''), _0x355674 == '' ? _0x674b8a['font'] = _0x3a55cd + ('px\x20' + logi['maps']['Defines']['DEFAULT_FONT']) : _0x674b8a['font'] = _0x3a55cd + ('px\x20' + _0x355674), _0x674b8a['textAlign'] = logi['maps']['Utils']['convertToTextAlign'](_0x4e8a25), _0x674b8a['textBaseline'] = logi['maps']['Utils']['convertToTextBaseline'](_0x4e8a25), _0x674b8a['translate'](_0x4051ec, _0x10e8c1), _0x3a5365 > 0x0 && (_0x674b8a['strokeStyle'] = _0x4e419b, _0x674b8a['lineWidth'] = _0x3a5365, _0x674b8a['strokeText'](_0x34d078, 0x0, 0x0)), _0x674b8a['fillStyle'] = _0x23e84e, _0x674b8a['fillText'](_0x34d078, 0x0, 0x0), _0x674b8a['restore']();
    };
    #drawText = function (_0x7e10de, _0x3c20a0, _0x56b1ab, _0xa35df5, _0x4c468d, _0x37705c, _0x2aad44, _0x215077, _0x66efbd, _0x407f83, _0x199221, _0xb05774) {
        _0x7e10de['save'](), _0x37705c != '' && document['fonts'] && !document['fonts']['check']('1em\x20' + _0x37705c) && (_0x37705c = ''), _0x37705c == '' ? _0x7e10de['font'] = _0x2aad44 + ('px\x20' + logi['maps']['Defines']['DEFAULT_FONT']) : _0x7e10de['font'] = _0x2aad44 + ('px\x20' + _0x37705c), _0x7e10de['textAlign'] = logi['maps']['Utils']['convertToTextAlign'](_0x66efbd), _0x7e10de['textBaseline'] = logi['maps']['Utils']['convertToTextBaseline'](_0x66efbd), this.#applyTransform(_0x7e10de, _0x4c468d, _0xb05774), _0x7e10de['translate'](_0x56b1ab * _0xb05774, _0xa35df5 * _0xb05774), _0x407f83 > 0x0 && (_0x7e10de['strokeStyle'] = _0x199221, _0x7e10de['lineWidth'] = _0x407f83, _0x7e10de['strokeText'](_0x3c20a0, 0x0, 0x0)), _0x7e10de['fillStyle'] = _0x215077, _0x7e10de['fillText'](_0x3c20a0, 0x0, 0x0), _0x7e10de['restore']();
    };
};
export default logi['maps']['Map'];