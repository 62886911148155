var logi = logi ?? {};
logi['maps'] = logi['maps'] ?? {};
import a14_0x37dc64 from './base/logi-maps-defines.js';
logi['maps']['Defines'] = a14_0x37dc64;
import a14_0x5e3d9e from './base/logi-maps-types.js';
logi['maps']['TileInfo'] = a14_0x5e3d9e['TileInfo'], logi['maps']['ALIGN'] = a14_0x5e3d9e['ALIGN'];
import a14_0x1c22b4 from './base/logi-maps-utils.js';
logi['maps']['Utils'] = a14_0x1c22b4;
import a14_0x159742 from './base/logi-maps-resource.js';
logi['maps']['Resource'] = a14_0x159742;
import a14_0x2f55d0 from './base/logi-maps-object.js';
logi['maps']['Object'] = a14_0x2f55d0;
import a14_0x3ee55a from './base/logi-maps-boundarydata.js';
logi['maps']['BoundaryData'] = a14_0x3ee55a;
import a14_0x2e60a6 from './base/logi-maps-boundarychecker.js';
logi['maps']['BoundaryChecker'] = a14_0x2e60a6;
import a14_0x3cbbd5 from './logi-maps-textinfo.js';
logi['maps']['TextInfo'] = a14_0x3cbbd5, logi['maps']['Image'] = class extends logi['maps']['Object'] {
    #src = '';
    #position = {
        'lng': 0x0,
        'lat': 0x0
    };
    #offsetX;
    #offsetY;
    #angle;
    #align;
    #image = null;
    #withTextInfos = new Array();
    #tileInfo = new logi['maps']['TileInfo']();
    #expiredTileId = !![];
    #boundaryData = new logi['maps']['BoundaryData']();
    constructor(_0x316ace, _0x49d361, _0x38c44b) {
        const _0x49baab = _0x38c44b?.['key'] ?? 'im_' + Math['random']()['toString'](0x24)['slice'](-0x8), _0x109b54 = _0x38c44b?.['class'] ?? '', _0x50436f = _0x38c44b?.['zIndex'] ?? 0x0;
        super(_0x49baab, logi['maps']['Object']['OBJTYPE']['image'], _0x109b54, _0x50436f), this['setImageSrc'](_0x316ace ?? ''), this.#position['lng'] = _0x49d361?.['lng'] ?? 0x0, this.#position['lat'] = _0x49d361?.['lat'] ?? 0x0, this.#offsetX = _0x38c44b?.['offsetX'] ?? 0x0, this.#offsetY = _0x38c44b?.['offsetY'] ?? 0x0, this.#angle = _0x38c44b?.['angle'] ?? 0x0, this.#align = _0x38c44b?.['align'] ?? logi['maps']['ALIGN']['CM'], this['boundaryData']['groupId'] = _0x38c44b?.['groupId'] ?? 0x0, this['resetBoundary'](), _0x38c44b?.['textInfo'] && (this['textInfo'] = _0x38c44b['textInfo']), this['setMap'](_0x38c44b?.['map']);
    }
    ['setImageSrc'](_0x1f4911) {
        this.#src = _0x1f4911 ?? '';
        const _0x2c877b = this;
        logi['maps']['Resource']['getImage'](_0x2c877b.#src)['then'](_0x46b4cf => {
            _0x2c877b.#image = _0x46b4cf, _0x2c877b['updateObj']();
        })['catch'](_0x451fd9 => {
            _0x2c877b.#image = null, _0x2c877b['updateObj'](), console['log'](_0x451fd9);
        });
    }
    ['getImageSrc']() {
        return this.#src;
    }
    ['setPosition'](_0xb45047) {
        (this.#position['lng'] !== _0xb45047['lng'] || this.#position['lat'] !== _0xb45047['lat']) && (this.#position['lng'] = _0xb45047['lng'], this.#position['lat'] = _0xb45047['lat'], (this.#position['lng'] < this['tileInfo']['boundary']['xMin'] || this.#position['lng'] > this['tileInfo']['boundary']['xMax'] || this.#position['lat'] < this['tileInfo']['boundary']['yMin'] || this.#position['lat'] > this['tileInfo']['boundary']['yMax']) && (this['expiredTileId'] = !![]), this['updateObj']());
    }
    ['getPosition']() {
        return {
            'lng': this.#position['lng'],
            'lat': this.#position['lat']
        };
    }
    ['setOffsetX'](_0x5bbaf1) {
        _0x5bbaf1 = _0x5bbaf1 ?? 0x0, this.#offsetX !== _0x5bbaf1 && (this.#offsetX = _0x5bbaf1, this['updateObj']());
    }
    ['getOffsetX']() {
        return this.#offsetX;
    }
    ['setOffsetY'](_0x20f1f6) {
        _0x20f1f6 = _0x20f1f6 ?? 0x0, this.#offsetY !== _0x20f1f6 && (this.#offsetY = _0x20f1f6, this['updateObj']());
    }
    ['getOffsetY']() {
        return this.#offsetY;
    }
    ['setAngle'](_0x3b3158) {
        this.#angle !== _0x3b3158 && (this.#angle = _0x3b3158, this['updateObj']());
    }
    ['getAngle']() {
        return this.#angle;
    }
    ['move'](_0x5b6d1f, _0x386357) {
        if (this.#position['lng'] !== _0x5b6d1f['lng'] || this.#position['lat'] !== _0x5b6d1f['lat'] || this.#angle !== _0x386357)
            return this['setPosition'](_0x5b6d1f), this['setAngle'](_0x386357), !![];
        return ![];
    }
    set ['textInfo'](_0x59c6d8) {
        this.#setTextInfo(_0x59c6d8);
    }
    get ['textInfo']() {
        return !this.#withTextInfos[0x0] && (this.#withTextInfos[0x0] = new logi['maps']['TextInfo'](this)), this.#withTextInfos[0x0];
    }
    ['addTextInfo'](_0x2999cf) {
        this.#setTextInfo(_0x2999cf, this.#withTextInfos['length']);
    }
    ['getTextInfo'](_0x2bcdec) {
        if (_0x2bcdec >= this.#withTextInfos['length'])
            return null;
        return this.#withTextInfos[_0x2bcdec];
    }
    ['removeTextInfo'](_0x51e207) {
        this.#withTextInfos['splice'](_0x51e207, 0x1);
    }
    ['removeTextInfos']() {
        this.#withTextInfos = new Array();
    }
    get ['tileInfo']() {
        return this.#tileInfo;
    }
    set ['expiredTileId'](_0xe2df37) {
        this.#expiredTileId = _0xe2df37;
    }
    get ['expiredTileId']() {
        return this.#expiredTileId;
    }
    get ['boundaryData']() {
        return this.#boundaryData;
    }
    ['resetBoundary']() {
        this['boundaryData']['bLoad'] = logi['maps']['BoundaryData']['STATUS']['NOT_LOAD'], this['boundaryData']['tagName'] = this['getKey'](), this['boundaryData']['boundaryCircle'] = {
            'center': {
                'x': 0x0,
                'y': 0x0
            },
            'maxR': 0x0
        }, this['boundaryData']['boundaryRect'] = [
            {
                'x': 0x0,
                'y': 0x0
            },
            {
                'x': 0x0,
                'y': 0x0
            },
            {
                'x': 0x0,
                'y': 0x0
            },
            {
                'x': 0x0,
                'y': 0x0
            }
        ], this['boundaryData']['creationTick'] = logi['maps']['Utils']['getCurTick'](), this['boundaryData']['overlapCnt'] = 0x0;
    }
    ['updateBoundary']() {
        let _0x1ac434 = [
                {
                    'x': 0x0,
                    'y': 0x0
                },
                {
                    'x': 0x0,
                    'y': 0x0
                },
                {
                    'x': 0x0,
                    'y': 0x0
                },
                {
                    'x': 0x0,
                    'y': 0x0
                }
            ], _0x331b33 = {
                'center': {
                    'x': 0x0,
                    'y': 0x0
                },
                'maxR': 0x0
            }, _0x3ea8e1 = this['getMapCoord'](), _0x575698 = _0x3ea8e1['world2screen'](this.#position['lng'], this.#position['lat']);
        if (this.#image) {
            let _0xa80140 = {
                'x': 0x0,
                'y': 0x0
            };
            _0xa80140['x'] = _0x575698['x'] + this.#offsetX, _0xa80140['y'] = _0x575698['y'] + this.#offsetY;
            const _0x4888b0 = this.#image?.['naturalWidth'], _0x33e3a7 = this.#image?.['naturalHeight'];
            _0x1ac434 = logi['maps']['Utils']['getBoundaryBox'](_0xa80140, _0x4888b0, _0x33e3a7, this.#align, this.#angle), _0x331b33 = logi['maps']['Utils']['getBoundaryCircle'](_0x1ac434);
        }
        this['boundaryData']['setBoundary'](_0x331b33, _0x1ac434);
    }
    ['changeImage'](_0x5457f4) {
        this['setImageSrc'](_0x5457f4);
    }
    ['createImage']() {
    }
    ['changeTextBgImage']() {
    }
    ['getWidth']() {
        if (this.#image)
            return this.#image['naturalWidth'];
        return 0x0;
    }
    ['getHeight']() {
        if (this.#image)
            return this.#image['naturalHeight'];
        return 0x0;
    }
    ['getAlign']() {
        return this.#align;
    }
    ['setText'](_0x439d47) {
        this['textInfo'] = _0x439d47;
    }
    ['isHit'](_0x23c2bb) {
        if (!this['getLayer']() || !this['getVisible']())
            return ![];
        if (this['boundaryData']['bLoad'] != logi['maps']['BoundaryData']['STATUS']['LOAD'])
            return ![];
        return logi['maps']['BoundaryChecker']['pointInRegion'](_0x23c2bb, this['boundaryData']['boundaryRect'], this['boundaryData']['boundaryRect']['length']);
    }
    ['draw']() {
        const _0x52a003 = this['getMapCoord'](), _0x54be79 = this['getDevicePixelRatio'](), _0x3b9b23 = this['getContext']();
        _0x3b9b23['save'](), _0x3b9b23['scale'](_0x54be79, _0x54be79);
        if (this['getLayer']() && this['getVisible']()) {
            let _0x2ec024 = _0x52a003['world2screen'](this.#position['lng'], this.#position['lat']);
            _0x2ec024['x'] += this.#offsetX, _0x2ec024['y'] += this.#offsetY;
            if (this.#image) {
                const _0x58593 = this.#image?.['naturalWidth'], _0x6a66bd = this.#image?.['naturalHeight'];
                let _0xe23d1c = logi['maps']['Utils']['getAlignPosition'](_0x2ec024['x'], _0x2ec024['y'], this.#align, _0x58593, _0x6a66bd), _0x53fd7d = logi['maps']['Utils']['getPivotPoint'](this.#align, _0x58593, _0x6a66bd);
                this.#angle == 0x0 ? _0x3b9b23['drawImage'](this.#image, _0xe23d1c['x'], _0xe23d1c['y']) : (_0x3b9b23['save'](), _0x3b9b23['translate'](_0xe23d1c['x'] + _0x53fd7d['x'], _0xe23d1c['y'] + _0x53fd7d['y']), _0x3b9b23['rotate'](logi['maps']['Utils']['degToRad'](this.#angle)), _0x3b9b23['drawImage'](this.#image, -_0x53fd7d['x'], -_0x53fd7d['y']), _0x3b9b23['restore']());
            }
            for (let _0x45f96c = 0x0; _0x45f96c < this.#withTextInfos['length']; ++_0x45f96c) {
                let _0x136584 = this.#withTextInfos[_0x45f96c];
                _0x136584?.['text'] && _0x136584?.['text'] !== '' && _0x136584['draw'](_0x2ec024['x'], _0x2ec024['y']);
            }
        }
        logi['maps']['Defines']['DEBUG_MODE'] && this.#drawBoundaryLine(_0x3b9b23, this['boundaryData']['boundaryRect']), _0x3b9b23['restore']();
    }
    #setTextInfo(_0x36ea8d, _0x34856e = 0x0) {
        _0x36ea8d == null ? this.#withTextInfos = new Array() : (!this.#withTextInfos[_0x34856e] && (this.#withTextInfos[_0x34856e] = new logi['maps']['TextInfo'](this)), _0x36ea8d['offsetX'] && (this.#withTextInfos[_0x34856e]['offsetX'] = _0x36ea8d['offsetX']), _0x36ea8d['offsetY'] && (this.#withTextInfos[_0x34856e]['offsetY'] = _0x36ea8d['offsetY']), _0x36ea8d['text'] && (this.#withTextInfos[_0x34856e]['text'] = _0x36ea8d['text']), _0x36ea8d['fontFamily'] && (this.#withTextInfos[_0x34856e]['fontFamily'] = _0x36ea8d['fontFamily']), _0x36ea8d['textColor'] && (this.#withTextInfos[_0x34856e]['textColor'] = _0x36ea8d['textColor']), _0x36ea8d['fontSize'] && (this.#withTextInfos[_0x34856e]['fontSize'] = _0x36ea8d['fontSize']), _0x36ea8d['textAlign'] && (this.#withTextInfos[_0x34856e]['textAlign'] = _0x36ea8d['textAlign']), this.#withTextInfos[_0x34856e]['bgImgSrc'] = _0x36ea8d['bgImgSrc'] ?? _0x36ea8d['bgImg'], _0x36ea8d['bgImgAlign'] && (this.#withTextInfos[_0x34856e]['bgImgAlign'] = _0x36ea8d['bgImgAlign']), _0x36ea8d['bgImgOffsetX'] && (this.#withTextInfos[_0x34856e]['bgImgOffsetX'] = _0x36ea8d['bgImgOffsetX']), _0x36ea8d['bgImgOffsetY'] && (this.#withTextInfos[_0x34856e]['bgImgOffsetY'] = _0x36ea8d['bgImgOffsetY']));
    }
    #drawBoundaryLine(_0xc624bd, _0x72bc0) {
        if (_0x72bc0?.['length'] >= 0x2) {
            _0xc624bd['beginPath'](), _0xc624bd['lineWidth'] = 0x2, _0xc624bd['strokeStyle'] = 'red', _0xc624bd['lineCap'] = 'round', _0xc624bd['lineJoin'] = 'round', _0xc624bd['moveTo'](_0x72bc0[0x0]['x'], _0x72bc0[0x0]['y']);
            for (let _0xc8a84e of _0x72bc0) {
                _0xc624bd['lineTo'](_0xc8a84e['x'], _0xc8a84e['y']);
            }
            _0xc624bd['stroke']();
        }
    }
};
export default logi['maps']['Image'];