import axios from 'axios'

class SystemService {
  private apiUrl: string
  constructor() {
    this.apiUrl = '/system'
  }

  // 시스템 정보 조회
  getSystemInfo() {
    return axios.get(this.apiUrl + `/version`).then((res) => res.data)
  }
}

export default new SystemService()
