var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "elevation-0 pa-0 ma-0" },
    [
      _c("v-card-title", { staticClass: "px-6 pt-10 pb-7" }, [
        _c("div", { staticClass: "flex-start-contents" }, [
          _vm.isDriver
            ? _c(
                "div",
                { staticClass: "pr-2", staticStyle: { width: "20%" } },
                [
                  _c("call-status-type", {
                    attrs: { value: _vm.callStatus },
                    on: { setInputStatusItem: _vm.setCallStatus },
                  }),
                ],
                1
              )
            : _c(
                "div",
                { staticClass: "pr-2", staticStyle: { width: "20%" } },
                [
                  _c("freight-status-type", {
                    attrs: { value: _vm.status },
                    on: { setInputStatusItem: _vm.setStatus },
                  }),
                ],
                1
              ),
          _c(
            "div",
            { staticClass: "pr-2", staticStyle: { width: "20%" } },
            [
              _c("freight-payment-type", {
                attrs: { value: _vm.payment },
                on: { setInputPaymentItem: _vm.setPayment },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "pr-2", staticStyle: { width: "20%" } },
            [
              _c("vue2-date-picker", {
                staticClass: "management",
                staticStyle: { width: "100%" },
                attrs: {
                  type: "date",
                  editable: false,
                  placeholder: "날짜",
                  range: "",
                  lang: "ko",
                },
                model: {
                  value: _vm.date,
                  callback: function ($$v) {
                    _vm.date = $$v
                  },
                  expression: "date",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "pr-2", staticStyle: { width: "35%" } },
            [
              _c("v-text-field", {
                staticStyle: { width: "100%" },
                attrs: {
                  label: !_vm.isDriver
                    ? "화물번호,차주명으로 검색"
                    : "화물번호로 검색",
                  "append-icon": _vm.icons.mdiMagnify,
                  maxlength: "20",
                  dense: "",
                  outlined: "",
                  "hide-details": "",
                  clearable: "",
                },
                on: {
                  keyup: [
                    _vm.onKeyupKeyword,
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.onSearch.apply(null, arguments)
                    },
                  ],
                },
                scopedSlots: _vm._u([
                  {
                    key: "append",
                    fn: function () {
                      return [
                        _c("v-icon", { on: { click: _vm.onSearch } }, [
                          _vm._v(" " + _vm._s(_vm.icons.mdiMagnify) + " "),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.keyword,
                  callback: function ($$v) {
                    _vm.keyword = $$v
                  },
                  expression: "keyword",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { width: "20%" } },
            [
              _c(
                "v-btn",
                {
                  staticClass:
                    "itgoWhite--text px-2 mt-n1 font-size18 search-btn",
                  attrs: { color: "itgoInfo" },
                  on: { click: _vm.onSearch },
                },
                [_vm._v(" 조회 ")]
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "v-data-table",
        _vm._b(
          {
            staticClass: "row-pointer",
            staticStyle: { "min-height": "540px !important" },
            attrs: {
              "single-expand": "",
              "items-per-page": _vm.pageSize,
              page: _vm.pageNum,
              expanded: _vm.expanded,
              "item-key": "key",
              "hide-default-footer": "",
              "disable-sort": "",
              "item-class": function (item) {
                return item.callInfos[0].freightChargeIssueDt ? "isInvoice" : ""
              },
            },
            on: {
              "update:page": function ($event) {
                _vm.pageNum = $event
              },
              "update:expanded": function ($event) {
                _vm.expanded = $event
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "no-data",
                  fn: function () {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.isSearched
                              ? "검색 결과가 없습니다."
                              : "등록된 화물이 없습니다."
                          ) +
                          " "
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "item.badge",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _vm.showBadge(item)
                        ? _c("v-badge", {
                            staticClass: "pl-1 pb-1",
                            attrs: { dot: "", color: "#E5318E" },
                          })
                        : _vm._e(),
                    ]
                  },
                },
                {
                  key: "item.callRequestStatus",
                  fn: function (ref) {
                    var item = ref.item
                    var value = ref.value
                    return [
                      _c(
                        "v-chip",
                        {
                          staticClass: "ma-2 custom-chip",
                          attrs: {
                            small: "",
                            color: _vm.getColor(value),
                            outlined: _vm.getOulinedValue(item),
                            "text-color": _vm.getTextColor(value),
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.getStatus(value)) + " ")]
                      ),
                    ]
                  },
                },
                {
                  key: "item.callStatus",
                  fn: function (ref) {
                    var item = ref.item
                    return [_vm._v(" " + _vm._s(_vm.getCallStatus(item)) + " ")]
                  },
                },
                {
                  key: "item.paymentMethod",
                  fn: function (ref) {
                    var value = ref.value
                    return [
                      _vm._v(
                        " " +
                          _vm._s(_vm.PAYMENT_METHOD.PAYMENT_NAME_WEB(value)) +
                          " "
                      ),
                    ]
                  },
                },
                {
                  key: "item.callRequestCode",
                  fn: function (ref) {
                    var item = ref.item
                    var value = ref.value
                    return [
                      _c(
                        "span",
                        {
                          staticClass:
                            "cursor-pointer text-decoration-underline",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.onClickCallRequestCode(item)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(value) + " ")]
                      ),
                    ]
                  },
                },
                {
                  key: "item.requestDt",
                  fn: function (ref) {
                    var value = ref.value
                    return [
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("formatDate")(value, "YYYY-MM-DD")) +
                          " "
                      ),
                    ]
                  },
                },
                {
                  key: "item.pickUp",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _vm._v(
                        " " + _vm._s(_vm.getAddress(item, "PICKUP")) + " "
                      ),
                    ]
                  },
                },
                {
                  key: "item.dropOff",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _vm._v(
                        " " + _vm._s(_vm.getAddress(item, "DROPOFF")) + " "
                      ),
                    ]
                  },
                },
                {
                  key: "item.driverName",
                  fn: function (ref) {
                    var value = ref.value
                    return [
                      _vm._v(" " + _vm._s(_vm._f("maskingName")(value)) + " "),
                    ]
                  },
                },
                {
                  key: "item.finalPaymentAmount",
                  fn: function (ref) {
                    var value = ref.value
                    return [
                      _vm._v(" " + _vm._s(_vm._f("addCommas")(value)) + "원 "),
                    ]
                  },
                },
                {
                  key: "item.vehicle",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _vm._v(" " + _vm._s(_vm.getVehicleInfo(item)) + " "),
                    ]
                  },
                },
                {
                  key: "item.totalFreightFee",
                  fn: function (ref) {
                    var value = ref.value
                    return [
                      _vm._v(" " + _vm._s(_vm._f("addCommas")(value)) + "원 "),
                    ]
                  },
                },
                {
                  key: "item.invoiceUrl",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              item.callInfos[0].freightChargeIssueDt
                                ? "발행"
                                : "미발행"
                            ) +
                            " "
                        ),
                      ]),
                    ]
                  },
                },
                {
                  key: "item.detail",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/icons/ic_return.svg"),
                          width: "24",
                        },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.onClickFreightHistory(item)
                          },
                        },
                      }),
                    ]
                  },
                },
                {
                  key: "item.point",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _c(
                        "v-row",
                        { staticClass: "justify-center" },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-icon",
                                          _vm._g(
                                            {
                                              attrs: { size: "24" },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.onRowClicked(item)
                                                },
                                              },
                                            },
                                            on
                                          ),
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.getExpansionStatus(item)
                                                    ? _vm.icons.mdiChevronUp
                                                    : _vm.icons.mdiChevronDown
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [_c("span", [_vm._v("경로확인")])]
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "expanded-item",
                  fn: function (ref) {
                    var headers = ref.headers
                    var item = ref.item
                    return [
                      _c(
                        "td",
                        { attrs: { colspan: headers.length } },
                        [
                          _c(
                            "v-container",
                            {
                              staticClass: "px-6 py-0 ma-0",
                              style: { height: _vm.displayMapHeight },
                              attrs: { fluid: "" },
                            },
                            [
                              _c("MapCard", {
                                ref: "MapCard",
                                attrs: { "call-request-info": item },
                                on: {
                                  mapSizePlus: _vm.mapSizePlus,
                                  mapSizeMinus: _vm.mapSizeMinus,
                                  currentAddress: _vm.setCurrentAddress,
                                },
                              }),
                            ],
                            1
                          ),
                          _vm.isSimpleTableView(item.callRequestStatus)
                            ? _c(
                                "div",
                                { staticClass: "px-6 py-0 ma-0" },
                                [
                                  _c("v-simple-table", {
                                    attrs: { dense: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "flex-space-between-contents my-5",
                                                  staticStyle: {
                                                    height: "20px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "text-center font-size14 surface800 etc1-2 font-weight700",
                                                      staticStyle: {
                                                        width: "10%",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.setCallStatusStr(
                                                              item
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c("v-divider", {
                                                    attrs: { vertical: "" },
                                                  }),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "text-center font-size14 surface800",
                                                      staticStyle: {
                                                        width: "15%",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "font-weight700",
                                                        },
                                                        [_vm._v("차량번호 :")]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "font-weight400",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.registrationNumber ||
                                                                  "-"
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c("v-divider", {
                                                    attrs: { vertical: "" },
                                                  }),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "text-center font-size14 surface800",
                                                      staticStyle: {
                                                        width: "30%",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "font-weight700",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "기사현재위치 :"
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "font-weight400",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.currentAddress
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c("v-divider", {
                                                    attrs: { vertical: "" },
                                                  }),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "text-center font-size14 surface800",
                                                      staticStyle: {
                                                        width: "30%",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "font-weight700",
                                                        },
                                                        [_vm._v("상차지 :")]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "font-weight400",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.setPickupAddress(
                                                                  item
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c("v-divider", {
                                                    attrs: { vertical: "" },
                                                  }),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "text-center font-size14 surface800",
                                                      staticStyle: {
                                                        width: "15%",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "font-weight700",
                                                        },
                                                        [_vm._v("상차시간 :")]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "font-weight400",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.setPickupTime(
                                                                  item
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          },
          "v-data-table",
          _vm.callInfo,
          false
        )
      ),
      _c("Pagination", {
        attrs: {
          pageSizes: _vm.pageSizes,
          totalPageCount: _vm.totalPageCount,
          pageSize: _vm.pageSize,
          pageNum: _vm.pageNum,
        },
        on: {
          "update:pageSize": function ($event) {
            _vm.pageSize = $event
          },
          "update:page-size": function ($event) {
            _vm.pageSize = $event
          },
          "update:pageNum": function ($event) {
            _vm.pageNum = $event
          },
          "update:page-num": function ($event) {
            _vm.pageNum = $event
          },
        },
      }),
      _c("freight-history-popup", {
        attrs: { item: _vm.selectedItem },
        on: { close: _vm.onCloseFreightHistory },
        model: {
          value: _vm.openFreightHistoryPopup,
          callback: function ($$v) {
            _vm.openFreightHistoryPopup = $$v
          },
          expression: "openFreightHistoryPopup",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }