

























































































































































































































































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import FreightStatusType from '@/views/freightHistory/FreightStatusType.vue'
import FreightPaymentType from '@/views/freightHistory/FreightPaymentType.vue'
import Vue2DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ko'
import DatetimeUtils from '@/utils/datetime.utils'
import Pagination from '@/views/utils/Pagination.vue'
import { mdiMagnify, mdiChevronUp, mdiChevronDown } from '@mdi/js'
import CALL_STATUS from '@/views/constant/call.status'
import FREIGHT_STATUS from '@/views/constant/freight.status'
import CALL_REQUEST_STATUS from '@/views/constant/call.request.status'
import MapCard from '@/views/map/MapCard.vue'
import { State, Mutation } from 'vuex-class'
import callRequestService from '@/services-itgo/call.request.service'
import FreightHistoryPopup from '@/views/freightHistory/FreightHistoryPopup.vue'
import PAYMENT_METHOD from '@/views/constant/freight.payment.type.constant'
import USER_STATUS from '@/views/constant/user.status'
import VEHICLE_TYPE from '@/views/constant/vehicle.type.constant'
import _ from 'lodash'
import datetimeUtils from '@/utils/datetime.utils'
import brokerService from '@/services-itgo/broker.service'
import CallStatusType from '@/views/freightHistory/CallStatusType.vue'

@Component({
  components: {
    CallStatusType,
    Vue2DatePicker,
    FreightStatusType,
    FreightPaymentType,
    MapCard,
    Pagination,
    FreightHistoryPopup,
  },
  created() {
    this.$router.beforeEach((from, to, next) => {
      if (
        (to.name === 'brokerInfo' ||
          to.name === 'driverInfo' ||
          to.name === 'callerInfo') &&
        from.name === 'freightInfo'
      ) {
        this.setIsManageFilterStatus({ type: 'freightTab', data: true })
      }
      next()
    })
  },
  async mounted() {
    if (this.isFreightTab) {
      this.keyword = this.filterData.keyword
      this.status = this.filterData.statusList
      this.date = [this.filterData.fromRequestDt, this.filterData.toRequestDt]
      this.callStatus = this.filterData.callStatusList
      this.payment = this.filterData.paymentMethod
      this.pageNum = this.filterData.pageNum
      this.pageSize = this.filterData.pageSize
      this.setIsManageFilterStatus({ type: 'freightTab', data: false })
    }
    this.fetchCallList()
  },
})
export default class FreightHistoryTab extends Vue {
  @Prop(Number) type
  @Prop(Object) item
  @Prop(Number) id
  @State((state) => state.pageFilter.status.isFreightTab) isFreightTab
  @State((state) => state.pageFilter.filter.freightTabFilter) filterData
  @Mutation('pageFilter/setIsManageFilterStatus') setIsManageFilterStatus
  @Mutation('pageFilter/setManageFilterData') setManageFilterData

  @State((state) => state.auth.user) loginUser

  icons: any = {
    mdiMagnify,
    mdiChevronUp,
    mdiChevronDown,
  }

  get isBroker() {
    return this.type === USER_STATUS.USER_TYPE_BROKER
  }

  get isDriver() {
    return this.type === USER_STATUS.USER_TYPE_DRIVER
  }

  get isCaller() {
    return this.type === USER_STATUS.USER_TYPE_CALLER
  }

  status = null
  callStatus = null
  payment = null
  date = [new Date(), new Date()]

  @Watch('date')
  changeDates(value) {
    if (value?.length > 1) {
      this.set(value)
    }
  }

  previousDayDate: Date[] | null = null

  async set(value) {
    const temp = [...this.date]
    temp.sort((a, b) => new Date(a).getTime() - new Date(b).getTime())

    if (temp.length === 2) {
      if (DatetimeUtils.asDays(temp[0], temp[1]) >= 30) {
        await this.$alert({
          title: '기간 설정 오류',
          content: '기간 설정은 최대 30일까지 가능합니다.',
        })
      } else {
        this.previousDayDate = [...this.date]
      }
    }
  }

  keyword: string = ''

  onKeyupKeyword() {
    if (this.validateName(this.keyword)) return

    this.keyword = this.keyword
      .replace(/[^ㄱ-ㅎ가-힣0-9-]/g, '')
      .replace(/\s/g, '')
  }

  validateName(value: string) {
    const regExp = /^[0-9]+$/

    return regExp.test(value)
  }

  PAYMENT_METHOD = PAYMENT_METHOD

  get headers() {
    return [
      { text: '', align: 'center', value: 'badge', show: true, width: '1%' },
      {
        text: this.isDriver ? '운송 상태' : '화물 상태',
        align: 'center',
        value: this.isDriver ? 'callStatus' : 'callRequestStatus',
        show: true,
        width: '7%',
      },
      {
        text: '결제 방식',
        align: 'center',
        value: 'paymentMethod',
        show: true,
        width: '7%',
      },
      {
        text: '화물 번호',
        align: 'center',
        value: 'callRequestCode',
        show: true,
        width: '12%',
      },
      {
        text: '등록 일자',
        align: 'center',
        value: 'requestDt',
        show: true,
        width: '8%',
      },
      {
        text: '상차지',
        align: 'center',
        value: 'pickUp',
        show: true,
        width: '12%',
      },
      {
        text: '하차지',
        align: 'center',
        value: 'dropOff',
        show: true,
        width: '12%',
      }, // 58 + 10
      {
        text: '차주명',
        align: 'center',
        value: 'driverName',
        show:
          this.type === USER_STATUS.USER_TYPE_BROKER ||
          this.type === USER_STATUS.USER_TYPE_CALLER,
        width: '8%',
      },
      {
        text: '운송료 (VAT별도)',
        align: 'center',
        value: 'finalPaymentAmount',
        show:
          this.type === USER_STATUS.USER_TYPE_BROKER ||
          this.type === USER_STATUS.USER_TYPE_DRIVER,
        width: '8%',
      },
      {
        text: '차량',
        align: 'center',
        value: 'vehicle',
        show:
          this.type === USER_STATUS.USER_TYPE_BROKER ||
          this.type === USER_STATUS.USER_TYPE_CALLER,
        width: '8%',
      },
      {
        text: '수수료',
        align: 'center',
        value: 'totalFreightFee',
        show: this.type === USER_STATUS.USER_TYPE_DRIVER,

        width: '8%',
      },
      // {
      //   text: '결제 상태',
      //   align: 'center',
      //   value: 'paymentStatus',
      //   show: this.type === USER_STATUS.USER_TYPE_DRIVER,

      //   width: '7%',
      // },
      {
        text: '세금계산서',
        align: 'center',
        value: 'invoiceUrl',
        show:
          this.type === USER_STATUS.USER_TYPE_BROKER ||
          this.type === USER_STATUS.USER_TYPE_DRIVER,
        width: '7%',
      },
      {
        text: '이력',
        align: 'center',
        value: 'detail',
        show: true,
        width: '5%',
      },
      {
        text: '위치 확인',
        align: 'center',
        value: 'point',
        show: true,
        width: '5%',
      },
    ]
  }

  callInfo = {
    loading: false,
    headers: this.headers.filter((item) => item.show),
    items: [],
    serverItemsLength: 0,
  }

  isSearched = false

  get totalPageCount() {
    const length = Math.ceil(this.callInfo.serverItemsLength / this.pageSize)
    return length === 0 ? 1 : length
  }

  /**
   * Pagiation 관련
   */
  pageSizes = [10, 20, 30]
  pageSize = 10
  pageNum = 1

  @Watch('pageSize')
  onChangePageSize() {
    this.pageNum = 1
    this.fetchCallList()
  }

  @Watch('pageNum')
  onChangePageNum() {
    this.fetchCallList()
  }

  expanded: any = []
  expandItem: any = {}

  displayMapHeight = '450px'

  async fetchCallList() {
    try {
      this.callInfo.loading = true
      this.callInfo.items = []
      //this.callInfo.serverItemsLength = 0

      const fromDate = this.date[0]
        ? DatetimeUtils.stringDate(this.date[0], 'YYYY-MM-DD')
        : ''
      const toDate = this.date[1]
        ? DatetimeUtils.datePlusDays(this.date[1], 1)
        : ''

      const params = {
        keyword: this.keyword,
        type: this.isBroker ? 0 : this.isDriver ? 1 : this.isCaller ? 2 : null,
        memberId: this.id,
        statusList: this.status,
        callStatusList: this.callStatus,
        fromRequestDt: fromDate,
        toRequestDt: toDate,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        paymentMethod: this.payment,
        searchType: 4,
      }

      this.setManageFilterData({
        type: 'freightTab',
        data: {
          ...params,
          fromRequestDt: this.date[0],
          toRequestDt: this.date[1],
        },
      })

      const idParam = {
        memberId: this.isBroker
          ? this.item.brokerId
          : this.isDriver
          ? this.item.driverId
          : this.item.callerId,
      }

      const data = await callRequestService.getCallAdminList({
        ...params,
        ...idParam,
      })

      this.callInfo.items = data.content.map((item) => ({
        ...item,
        key: item.callRequestId,
      }))
      this.callInfo.serverItemsLength = data.totalElementCount
    } catch (error: any) {
      this.$alert(error.response.data.errMsg)
    } finally {
      this.callInfo.loading = false
    }
  }

  onSearch() {
    this.isSearched = true
    this.pageNum = 1
    this.fetchCallList()
  }

  showBadge(item) {
    return (
      item.callRequestStatus === CALL_REQUEST_STATUS.ASSIGN_REQUEST ||
      item.callInfos[0].callStatus === CALL_STATUS.INVOICE_REGISTERED ||
      item.callInfos[0].callStatus === CALL_STATUS.INVOICE_REREGISTERED
    )
  }

  getColor(value) {
    return FREIGHT_STATUS.NAME_BG_COLOR(value)
  }

  getTextColor(value) {
    return FREIGHT_STATUS.NAME_COLOR(value)
  }

  getStatus(value) {
    return FREIGHT_STATUS.NAME_WEB(value)
  }

  getCallStatus(item) {
    console.log(item.callInfos[0].callStatus)
    return CALL_STATUS.NAME_LIST_WEB(item.callInfos[0].callStatus)
  }

  getAddress(item: any, type: string) {
    const address = item.callDestinations.find((item) => item.type == type)

    return address
      ? address.adminAreaAbbr +
          ' ' +
          address.subAdminAreaAbbr +
          ' ' +
          address.localityAbbr?.slice(0, 2)
      : '-'
  }

  getVehicleInfo(item) {
    const front = item.vehicleWeight ? `${item.vehicleWeight}톤` : '-'
    const end = VEHICLE_TYPE.VEHICLE_NAME_WEB(item.vehicleType)

    return `${front}/${end}`
  }

  getPaymentStatus(item) {
    //TODO: 재확인 필요
    if (!item) return '-'

    // 배차 대기/배차 요청/배차 완료/운송 중 일 경우
    if (
      item.callRequestStatus === CALL_REQUEST_STATUS.ASSIGNABLE ||
      item.callRequestStatus === CALL_REQUEST_STATUS.ASSIGN_REQUEST ||
      item.callRequestStatus === CALL_REQUEST_STATUS.ASSIGNED ||
      item.callRequestStatus === CALL_REQUEST_STATUS.TRANSPORTING
    )
      return '-'

    if (item.callInfos[0].settleChargeIssueDt) return '완료'
    else if (item.callInfos[0].callStatus === CALL_STATUS.COMPLETED)
      return '대기'
    // else if (item.callInfos[0].settleChargeIssueDt) return '실패'

    return '-'
  }

  mapSizePlus() {
    this.displayMapHeight = '850px'
  }

  mapSizeMinus() {
    this.displayMapHeight = '450px'
  }

  currentAddress: string = ''

  setCurrentAddress(address) {
    this.currentAddress = address
  }

  getExpansionStatus(item) {
    if (!this.expanded.length) return false

    return Boolean(
      this.expanded?.find(
        (_item) => _item.callRequestId === item.callRequestId,
      ),
    )
  }

  onRowClicked(item) {
    if (!this.expanded.length) return this.expanded.push(item)

    this.expanded = this.getExpansionStatus(item) ? [] : [item]
  }

  onClickCallRequestCode(item) {
    const path = this.isBroker
      ? 'brokerInfo'
      : this.isCaller
      ? 'callerInfo'
      : 'driverInfo'

    this.$router.push({
      name: 'freightInfo',
      params: {
        id: String(item.callRequestId),
        history: 'management',
        path: path,
        item: this.item,
      },
    })
  }

  getOulinedValue(item) {
    if (
      item.callRequestStatus === FREIGHT_STATUS.FAILED ||
      item.callRequestStatus === FREIGHT_STATUS.CANCELLED_BY_CALLER ||
      item.callRequestStatus === FREIGHT_STATUS.CANCELLED_BY_BROKER ||
      item.callRequestStatus ===
        FREIGHT_STATUS.CANCELLED_BY_CONTROLLER_FOR_CALLER ||
      item.callRequestStatus ===
        FREIGHT_STATUS.CANCELLED_BY_CONTROLLER_FOR_BROKER ||
      item.callRequestStatus === FREIGHT_STATUS.DELETED_BY_CONTROLLER ||
      item.callRequestStatus === FREIGHT_STATUS.HIDDEN ||
      item.callRequestStatus === FREIGHT_STATUS.HOLDING ||
      item.callRequestStatus === FREIGHT_STATUS.CANCELLED_BY_PICKUP_SCHEDULE
    )
      return true
    else return false
  }

  setStatus(status) {
    if (status.length === 1 && status[0] === 0) this.status = null
    else this.status = status
  }

  setCallStatus(status) {
    if (status.length === 1 && status[0] === 0) this.callStatus = null
    else this.callStatus = status
  }

  setPayment(payment) {
    this.payment = payment
  }

  setDate(dates) {
    this.date = dates
  }

  isSimpleTableView(requestStatus) {
    if (
      _.includes(
        CALL_REQUEST_STATUS.DRIVER_INACTIVE_STATUS_LIST(),
        requestStatus,
      )
    ) {
      return false
    } else {
      return true
    }
  }

  setCallStatusStr(item) {
    return CALL_STATUS.NAME_WEB_STATUS_DETAIL(item.callInfos[0].callStatus)
  }

  setPickupAddress(item) {
    const pickAddress = _.find(item.callDestinations, {
      type: 'PICKUP',
    })
    return pickAddress.address
  }

  setPickupTime(item) {
    const time = datetimeUtils.stringDate(item.pickupRequestDt, 'HH:mm')
    return time
  }

  selectedItem = null

  openFreightHistoryPopup = false

  async onClickFreightHistory(item) {
    this.selectedItem = item
    this.openFreightHistoryPopup = true
  }

  onCloseFreightHistory() {
    this.openFreightHistoryPopup = false
  }

  // To do : 세금계산서 팝업/ List Row click (LG 보안 이슈로 임시적으로 기능 삭제)
  async onClickShowDetail(item) {
    try {
      if (!item.callInfos[0].freightChargeIssueDt) return

      const brokerId = this.loginUser.brokerId
      const data = await brokerService.getTaxInvoiceUrl(
        brokerId,
        item.callInfos[0].callId,
      )

      if (data.resCode < 0) return this.$alert(data.resMsg)

      if (!data.resMsg) return

      const left = screen.width / 2 - 500 / 2
      const top = screen.height / 2 - 800 / 2
      const option = `status=no, menubar=no, toolbar=no, resizable=no, width=1000, height=800, left=${left}, top=${top}`

      window.open(data.resMsg, 'invoicePopup', option)
    } catch (error: any) {
      this.$alert(error.response.data.errMsg)
    }
  }
}
