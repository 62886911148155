






























































































































































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import DatePicker from '@/views/utils/DatePicker.vue'
import Pagination from '@/views/utils/Pagination.vue'
import { mdiMagnify, mdiChevronDown, mdiChevronLeft } from '@mdi/js'
import faqService from '@/services-itgo/faq.service'
import LoadPopup from '../popup/551X792/LoadPopup.vue'
import { State } from 'vuex-class'
import { CONTROLLER_ROLE } from '@/views/constant/user.role.constant'
import DatetimeUtils from '@/utils/datetime.utils'
import StringUtils from '@/utils/string.utils'
//@ts-ignore
import Vue2DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ko'
import _ from 'lodash'

function getDefualtCategory() {
  return [{ text: '전체', value: '' }]
}

@Component({
  name: 'FaqManagement',
  components: {
    DatePicker,
    Pagination,
    LoadPopup,
    Vue2DatePicker,
  },

  mounted() {
    this.fetchCategories()
    this.fetchPageList()
  },
})
export default class FaqManagement extends Vue {
  @State((state) => state.auth.user) loginUser

  toggleFaq = 1

  backToList() {
    this.toggleFaq = 1
    this.fetchPageList()
    this.clearCreateContent()
  }
  async backKeyToList() {
    if (this.isChangedContents) {
      const result = await this.$confirm({
        title: '종료 안내',
        content:
          '변경 또는 작성 중인 내용이 있습니다.<br/> 팝업을 닫으면 변경 또는 작성 중인<br/> 내용은 저장되지 않습니다',
      })
      if (result) {
        this.toggleFaq = 1
        await this.fetchPageList()
        this.clearCreateContent()
      }
    } else {
      this.toggleFaq = 1
      await this.fetchPageList()
      this.clearCreateContent()
    }
  }

  clearCreateContent() {
    this.faqContents.answer = ''
    this.faqContents.question = ''
    this.faqContents.categoryCreate = ''
    this.faqContents.faqCreate = 0
    // 수정 시
    this.editFaqId = null
  }

  public faqMode = 'create'

  onCreate() {
    this.fetchTemporaryPageList()
    this.toggleFaq = 0
    this.faqMode = 'create'
  }

  editFaqId = null

  onEdit(item) {
    this.faqMode = 'edit'

    this.fetchTemporaryPageList()
    this.toggleFaq = 0
    this.editFaqId = item.faqId

    this.faqContents.question = item.question
    this.faqContents.answer = item.answer
    this.faqContents.categoryCreate = item.category
    this.faqContents.faqCreate = item.faqTarget
  }

  /** FAQ - 메인 */

  icons: any = {
    mdiMagnify,
    mdiChevronDown,
    mdiChevronLeft,
  }

  keyWord = ''

  faqTarget = null
  faqTargetCreateItems = [
    { text: '공통', value: 0 },
    { text: '주선사', value: 3 },
    { text: '화주', value: 1 },
    { text: '차주', value: 2 },
    // { text: '관리자', value: 4 },
  ]
  faqTargetItems = [{ text: '전체', value: null }, ...this.faqTargetCreateItems]

  getTarget(value) {
    return this.faqTargetItems.find((item) => item.value === value)?.text
  }

  category = ''
  categoryItems = getDefualtCategory()
  categoryCreateItems: any = []

  date: any = []
  selectedItems: any = []

  @Watch('date')
  changeDates(value) {
    if (value?.length > 1) {
      this.set(value)
    }
  }

  previousDayDate: Date[] | null = null

  async set(value) {
    const temp = [...this.date]
    temp.sort((a, b) => new Date(a).getTime() - new Date(b).getTime())

    if (temp.length === 2) {
      if (DatetimeUtils.asDays(temp[0], temp[1]) >= 30) {
        this.date = this.previousDayDate
        await this.$alert({
          title: '기간 설정 오류',
          content: '기간 설정은 최대 30일까지 가능합니다.',
        })
      } else {
        this.previousDayDate = [...this.date]
      }
    }
  }

  faqInfo = {
    loading: false,
    headers: [
      { text: '번호', align: 'center', value: 'faqId', width: '10%' },
      { text: '대상', align: 'center', value: 'target', width: '10%' },
      { text: '질문 유형', align: 'center', value: 'category', width: '15%' },
      {
        text: '질문 내용',
        align: 'center',
        value: 'question',
        width: '35%',
      },
      { text: '등록 일자', align: 'center', value: 'regDt', width: '20%' },
      { text: '', align: 'center', value: 'answer', width: '5%' },
    ],
    items: [],
    serverItemsLength: 10,
  }

  isSearched = false

  get totalPageCount() {
    const length = Math.ceil(this.faqInfo.serverItemsLength / this.pageSize)
    return length === 0 ? 1 : length
  }

  pageSizes = [10, 20, 30]
  pageSize = 10
  pageNum = 1

  /**
   * pageSize: 페이지 크기
   * pageNum: 페이지 번호
   */
  @Watch('pageSize')
  onChangePageSize() {
    this.pageNum = 1
    this.fetchPageList()
  }

  @Watch('pageNum')
  onChangePageNum() {
    this.fetchPageList()
  }

  get isBulletinWritable() {
    if (CONTROLLER_ROLE.isBulletinWritable(this.loginUser.controllerRole))
      return true
    else return false
  }

  expanded: any = []

  onRowClicked(item) {
    if (this.expanded.length > 0 && item.faqId === this.expanded[0].faqId) {
      this.expanded = []
    } else {
      this.expanded = [item]
    }
  }

  async fetchCategories() {
    try {
      const result = await faqService.getCategories()

      this.categoryItems = [
        ...getDefualtCategory(),
        ...result.map((category) => ({
          text: category,
          value: category,
        })),
      ]

      this.categoryCreateItems = [
        ...result.map((category) => ({
          text: category,
          value: category,
        })),
      ]
    } catch (error) {
      console.error(error)
    }
  }

  async fetchPageList() {
    try {
      this.faqInfo.loading = true

      this.expanded = []
      this.faqInfo.items = []

      const data = await faqService.getPageList({
        faqTargetList: this.faqTarget,
        category: _.isEmpty(this.category) ? null : this.category,
        keyword: _.isEmpty(this.keyWord) ? null : this.keyWord,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        fromDt: this.date[0]
          ? DatetimeUtils.setTypeStartDate(this.date[0], 'hour')
          : null,
        toDt: this.date[1]
          ? DatetimeUtils.setTypeEndDate(this.date[1], 'hour')
          : null,
        temporary: 0,
      })

      this.faqInfo.items = data.content.map((item, index) => ({
        ...item,
        target:
          this.faqTargetItems.find((_item) => _item.value === item.faqTarget)
            ?.text || '-',
        key: `${item.question}_${index}`,
      }))

      this.faqInfo.serverItemsLength = data.totalElementCount
    } catch (err) {
      console.error(err)
    } finally {
      this.faqInfo.loading = false
    }
  }
  onSearch() {
    this.isSearched = true
    this.pageNum = 1
    this.fetchPageList()
  }

  async onClickDeleteFaq() {
    if (this.selectedItems.length > 0) {
      try {
        const res = await this.$confirm('해당 FAQ를 삭제하시겠습니까?')
        if (res) {
          const faqIds = this.selectedItems.map((x) => x.faqId).join(',')
          await faqService.deleteFaq(faqIds)
          this.selectedItems = []
          await this.fetchPageList()
        }
      } catch (e: any) {
        await this.$alert({
          title: 'FAQ 삭제 실패',
          content: e.response.data.errMsg,
        })
      }
    }
  }

  formattedText(content) {
    return StringUtils.formattedContentText(content)
  }

  /**
   * FAQ - 관리자 - 작성
   */
  faqContents = {
    question: '',
    answer: '',
    categoryCreate: '',
    faqCreate: 0,
  }

  isChangedContents: boolean = false

  @Watch('faqContents', { deep: true })
  changedFaqContents(value) {
    console.log(value)
    if (this.toggleFaq === 1) this.isChangedContents = false
    else this.isChangedContents = true
    console.log(this.isChangedContents)
  }

  // question = ''
  // answer = ''
  // categoryCreate = ''
  // faqCreate = 0

  temporaryItems = [] as any
  isLoadPopup = false
  toggleLoadPopup() {
    this.isLoadPopup = !this.isLoadPopup
  }
  loadPopupClose() {
    this.isLoadPopup = false
    this.fetchPageList()
  }

  // 불러오기 삭제
  async deleteItems(payload) {
    if (payload.length > 0) {
      try {
        const faqIds = payload.map((x) => x.faqId).join(',')
        await faqService.deleteFaq(faqIds)
        await this.fetchTemporaryPageList()
        this.toggleLoadPopup()
      } catch (e: any) {
        await this.$alert({
          title: 'FAQ 불러오기 삭제 실패',
          content: e.response.data.errMsg,
        })
      }
    }
  }

  // 불러오기
  // async fetchCategoryDetail(faqId) {
  //   try {
  //     const res = await faqService.getFaqDetails(faqId)
  //     return res
  //   } catch (e) {
  //     console.error(e)
  //   }
  // }

  async fetchTemporaryPageList() {
    try {
      const params = {
        temporary: true,
      }
      const res = await faqService.getPageList(params)
      this.temporaryItems = res.content.map((x) => ({
        ...x,
        title: x.question,
      }))
    } catch (err) {
      console.error(err)
    } finally {
    }
  }

  async loadItem(payload) {
    if (payload) {
      this.faqContents.question = payload.question
      this.faqContents.answer = payload.answer
      this.faqContents.categoryCreate = payload.category
      this.faqContents.faqCreate = payload.faqTarget
    }
  }

  // 등록하기
  public isRegisterLoading: boolean = false

  async saveFaq(temporary) {
    const body = {
      faqId: this.editFaqId,
      temporary: temporary,
      faqTarget: this.faqContents.faqCreate,
      category: this.faqContents.categoryCreate,
      question: this.faqContents.question,
      answer: this.faqContents.answer,
    }

    try {
      if (this.editFaqId) {
        this.isRegisterLoading = true
        if (temporary) {
          const temporaryStorage = await this.$confirm({
            title: '임시저장 안내',
            content: '작성하신 내용을 임시 저장하시겠습니까?',
          })
          if (temporaryStorage) {
            await faqService.postRegisterFaq(body)
            this.isRegisterLoading = false
            await this.fetchTemporaryPageList()
            await this.$toast('임시 저장 되었습니다')
          } else {
            this.isRegisterLoading = false
          }
        } else {
          await faqService.putEditFaq(body)
          this.isRegisterLoading = false
          await this.$toast(`FAQ가 수정 되었습니다.`)
          this.backToList()
        }
      } else {
        this.isRegisterLoading = true
        if (!temporary) {
          const result = await this.$confirm({
            title: 'FAQ 등록',
            content: '작성하신 내용으로 FAQ를<br/> 등록 하시겠습니까?',
          })
          if (result) {
            await faqService.postRegisterFaq(body)
            this.isRegisterLoading = false
            await this.$toast(`FAQ가 등록 되었습니다.`)
            this.backToList()
          } else {
            this.isRegisterLoading = false
          }
        } else {
          const temporaryStorage = await this.$confirm({
            title: '임시저장 안내',
            content: '작성하신 내용을 임시 저장하시겠습니까?',
          })
          if (temporaryStorage) {
            await faqService.postRegisterFaq(body)
            this.isRegisterLoading = false
            await this.fetchTemporaryPageList()
            await this.$toast('임시 저장 되었습니다')
          } else {
            this.isRegisterLoading = false
          }
        }
      }
    } catch (e: any) {
      this.isRegisterLoading = false
      const errCode = e.response.data.errCode
      let errContent = ''

      if (errCode === 'E0010')
        errContent = '미입력 또는 미선택한 항목이 존재합니다.'
      else errContent = e.response.data.errMsg

      await this.$alert({
        title: 'FAQ 등록 실패',
        content: errContent,
      })
    }
  }
}
