





















import {Vue, Component, Watch, Prop} from 'vue-property-decorator'
import {} from '@mdi/js'

@Component({
  name: "TodayListItem",
  components: {},
})
export default class TodayListItem extends Vue {
  @Prop(String) title
  @Prop(Number) content
}
