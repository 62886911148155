
















import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import CALL_STATUS from '@/views/constant/call.status'

@Component({
  name :'CallStatusType'
})

export default class CallStatusType extends Vue {
  @Prop(Array) value

  status = [CALL_STATUS.TOTAL]
  statusItems = [
    { text: CALL_STATUS.NAME_LIST_WEB(CALL_STATUS.TOTAL), value: [CALL_STATUS.TOTAL] },
    { text: "배차 요청", value: [CALL_STATUS.ASSIGNABLE] },
    { text: "배차 완료", value: [CALL_STATUS.ASSIGNED] },
    { text: "운송중", value: [
      CALL_STATUS.STARTED,
      CALL_STATUS.ARRIVED_PICKUP,
      CALL_STATUS.PICKED_UP,
      CALL_STATUS.ARRIVED_WAYPOINT,
      CALL_STATUS.ARRIVED_DROPOFF,
      CALL_STATUS.DROPPED_OFF,
      CALL_STATUS.LEFT_WAYPOINT,
      CALL_STATUS.PICKUP_RETURNED,
      CALL_STATUS.ARRIVED_PICKUP_RETURN,
      ] },
    { text: "운송 완료", value: [
        CALL_STATUS.COMPLETED,
        CALL_STATUS.COMPLETED_ARBITRARY_BY_CALLER,
        CALL_STATUS.COMPLETED_ARBITRARY_BY_CONTROLLER
      ] },
    { text: "승인 대기", value: [
      CALL_STATUS.INVOICE_REGISTERED,
      CALL_STATUS.INVOICE_REREGISTERED
      ] },
    { text: "승인 반려", value: [CALL_STATUS.INVOICE_REJECTED] },
    { text: "운행 취소", value: [
      CALL_STATUS.PRE_CANCELLED_BY_DRIVER,
      CALL_STATUS.POST_CANCELLED_BY_DRIVER,
      CALL_STATUS.PRE_CANCELLED_BY_CALLER,
      CALL_STATUS.POST_CANCELLED_BY_CALLER,
      CALL_STATUS.PRE_CANCELLED_BY_BROKER,
      CALL_STATUS.POST_CANCELLED_BY_BROKER,
      CALL_STATUS.PRE_CANCELLED_BY_CONTROLLER_FOR_DRIVER,
      CALL_STATUS.POST_CANCELLED_BY_CONTROLLER_FOR_DRIVER,
      CALL_STATUS.PRE_CANCELLED_BY_CONTROLLER_FOR_CALLER,
      CALL_STATUS.POST_CANCELLED_BY_CONTROLLER_FOR_CALLER,
      CALL_STATUS.PRE_CANCELLED_BY_CONTROLLER_FOR_BROKER,
      CALL_STATUS.POST_CANCELLED_BY_CONTROLLER_FOR_BROKER,
      ]
    },
    { text: "보류중", value: [CALL_STATUS.HOLDING] },
  ]

  @Watch('value')
  onChangeValue(value){
    this.status = value 
  }

  changeItem(value) {
    console.log(value)
    this.$emit('setInputStatusItem', value)
  }
}
