var logi = logi ?? {};
logi['maps'] = logi['maps'] ?? {};
import a20_0x309264 from './base/logi-maps-types.js';
logi['maps']['LINETYPE'] = a20_0x309264['LINETYPE'];
import a20_0x55d0c2 from './base/logi-maps-object.js';
logi['maps']['Object'] = a20_0x55d0c2;
import a20_0x31bf70 from './base/logi-maps-utils.js';
logi['maps']['Utils'] = a20_0x31bf70;
import a20_0x471e99 from './logi-maps-line.js';
logi['maps']['Line'] = a20_0x471e99, logi['maps']['Route'] = class extends logi['maps']['Object'] {
    #routeLatlngs = new Array();
    #routeLine = {};
    #passedLatlngs = new Array();
    #passedLine = {};
    #pastRecordLatlngs = new Array();
    #pastRecordLine = {};
    #cutPoint = {
        'lng': 0x0,
        'lat': 0x0
    };
    #cutDegree = 0x0;
    #mode = '';
    #screenPoints = new Array();
    #boundaryWidth = 0x0;
    constructor(_0x4e4aa1, _0xfdefb5) {
        const _0x18c2d7 = _0xfdefb5?.['key'] ?? 'rt_' + Math['random']()['toString'](0x24)['slice'](-0x8), _0x458924 = _0xfdefb5?.['class'] ?? '', _0x3350bb = _0xfdefb5?.['zIndex'] ?? 0x0;
        super(_0x18c2d7, logi['maps']['Object']['OBJTYPE']['route'], _0x458924, _0x3350bb), this.#routeLatlngs = logi['maps']['Utils']['getRoughLatLngs'](_0x4e4aa1 ?? []), this.#routeLine['width'] = _0xfdefb5?.['routeLine']?.['width'] ?? 0x4, this.#routeLine['color'] = _0xfdefb5?.['routeLine']?.['color'] ?? '#0088FF', this.#routeLine['strokeWidth'] = _0xfdefb5?.['routeLine']?.['strokeWidth'] ?? 0x0, this.#routeLine['strokeColor'] = _0xfdefb5?.['routeLine']?.['strokeColor'] ?? '#000000', this.#passedLine['width'] = _0xfdefb5?.['passedLine']?.['width'] ?? this.#routeLine['width'], this.#passedLine['color'] = _0xfdefb5?.['passedLine']?.['color'] ?? '#999999', this.#passedLine['strokeWidth'] = _0xfdefb5?.['passedLine']?.['strokeWidth'] ?? 0x0, this.#passedLine['strokeColor'] = _0xfdefb5?.['passedLine']?.['strokeColor'] ?? '#959595', this.#pastRecordLine['dotRadius'] = _0xfdefb5?.['pastRecordLine']?.['dotRadius'] ?? this.#routeLine['width'] * 0.5, this.#pastRecordLine['dotGap'] = _0xfdefb5?.['pastRecordLine']?.['dotGap'] ?? this.#routeLine['width'] * 0.5, this.#pastRecordLine['color'] = _0xfdefb5?.['pastRecordLine']?.['color'] ?? this.#passedLine['color'], this.#pastRecordLine['strokeWidth'] = _0xfdefb5?.['pastRecordLine']?.['strokeWidth'] ?? 0x0, this.#pastRecordLine['strokeColor'] = _0xfdefb5?.['pastRecordLine']?.['strokeColor'] ?? this.#passedLine['strokeColor'], this.#mode = _0xfdefb5?.['mode'] ?? 'PASSED_LINE', this['setMap'](_0xfdefb5?.['map']);
    }
    ['isHit'](_0x21dda0) {
        return this.#pointOnLine(_0x21dda0);
    }
    ['setRouteLine'](_0x4e62a4) {
        this.#routeLatlngs = logi['maps']['Utils']['getRoughLatLngs'](_0x4e62a4), this['updateObj']();
    }
    ['setPastRecordLine'](_0x263390) {
        this.#pastRecordLatlngs = logi['maps']['Utils']['getRoughLatLngs'](_0x263390), this['updateObj']();
    }
    ['setPassedLine'](_0x3c822d) {
        this.#mode === 'PASSED_LINE' && (this.#passedLatlngs = logi['maps']['Utils']['getRoughLatLngs'](_0x3c822d), this['updateObj']());
    }
    ['addPassedPoint'](_0x336048) {
        this.#mode === 'PASSED_LINE' && (logi['maps']['Utils']['addRoughLatLngs'](this.#passedLatlngs, _0x336048) == !![] && this['updateObj']());
    }
    ['setCutPoint'](_0xfd730d, _0x48602f) {
        if (this.#mode === 'CUT_POINT') {
            if (this.#cutPoint['lng'] !== _0xfd730d['lng'] || this.#cutPoint['lat'] !== _0xfd730d['lat'] || this.#cutDegree !== _0x48602f) {
                this.#cutPoint['lng'] = _0xfd730d['lng'], this.#cutPoint['lat'] = _0xfd730d['lat'], this.#cutDegree = _0x48602f;
                const _0x7001cc = this.#getSnappedLine(this.#routeLatlngs, this.#cutPoint, this.#cutDegree);
                this.#passedLatlngs = _0x7001cc['passed'], this['updateObj']();
            }
        }
    }
    ['draw']() {
        this.#screenPoints = new Array(), this.#boundaryWidth = 0x0;
        if (this['getLayer']() && this['getVisible']()) {
            const _0x590fbe = this['getMapCoord'](), _0x2b7d50 = this['getDevicePixelRatio'](), _0x109dbc = this['getContext']();
            _0x109dbc['save'](), _0x109dbc['scale'](_0x2b7d50, _0x2b7d50);
            {
                let _0x5c5983 = new Array(), _0x138742 = {
                        'x': null,
                        'y': null
                    }, _0x4af62d = {
                        'x': null,
                        'y': null
                    };
                for (let _0x2c0863 of this.#pastRecordLatlngs) {
                    _0x138742 = _0x590fbe['world2screen'](_0x2c0863['lng'], _0x2c0863['lat']), (_0x4af62d['x'] != _0x138742['x'] || _0x4af62d['y'] != _0x138742['y']) && (_0x5c5983['push'](_0x138742), _0x4af62d = _0x138742);
                }
                if (_0x5c5983['length'] >= 0x2) {
                    const _0x27023e = this.#pastRecordLine['dotRadius'];
                    let _0x3cc88b = this.#pastRecordLine['dotGap'] + _0x27023e * 0x2;
                    if (this.#pastRecordLine['strokeWidth'] > 0x0) {
                        const _0x521f71 = _0x27023e + this.#pastRecordLine['strokeWidth'];
                        _0x3cc88b = this.#pastRecordLine['dotGap'] + _0x521f71 * 0x2, logi['maps']['Line']['drawDottedLine'](_0x109dbc, _0x5c5983, _0x521f71, _0x3cc88b, this.#pastRecordLine['strokeColor']);
                    }
                    logi['maps']['Line']['drawDottedLine'](_0x109dbc, _0x5c5983, _0x27023e, _0x3cc88b, this.#pastRecordLine['color']);
                }
            }
            {
                let _0x4a3646 = {
                        'x': null,
                        'y': null
                    }, _0x3dd273 = {
                        'x': null,
                        'y': null
                    };
                for (let _0x13f8ea of this.#routeLatlngs) {
                    _0x4a3646 = _0x590fbe['world2screen'](_0x13f8ea['lng'], _0x13f8ea['lat']), (_0x3dd273['x'] != _0x4a3646['x'] || _0x3dd273['y'] != _0x4a3646['y']) && (this.#screenPoints['push'](_0x4a3646), _0x3dd273 = _0x4a3646);
                }
                if (this.#screenPoints['length'] >= 0x2) {
                    const _0x3e4409 = this.#routeLine['width'];
                    this.#boundaryWidth = _0x3e4409;
                    if (this.#routeLine['strokeWidth'] > 0x0) {
                        const _0x265355 = _0x3e4409 + this.#routeLine['strokeWidth'] * 0x2;
                        this.#boundaryWidth = _0x265355, logi['maps']['Line']['drawPolyLine'](_0x109dbc, this.#screenPoints, _0x265355, this.#routeLine['strokeColor']);
                    }
                    logi['maps']['Line']['drawPolyLine'](_0x109dbc, this.#screenPoints, _0x3e4409, this.#routeLine['color']);
                }
            }
            {
                let _0x302013 = new Array(), _0x5f98d6 = {
                        'x': null,
                        'y': null
                    }, _0x28e4aa = {
                        'x': null,
                        'y': null
                    };
                for (let _0x1ad460 of this.#passedLatlngs) {
                    _0x5f98d6 = _0x590fbe['world2screen'](_0x1ad460['lng'], _0x1ad460['lat']), (_0x28e4aa['x'] != _0x5f98d6['x'] || _0x28e4aa['y'] != _0x5f98d6['y']) && (_0x302013['push'](_0x5f98d6), _0x28e4aa = _0x5f98d6);
                }
                if (_0x302013['length'] >= 0x2) {
                    const _0x3606b8 = this.#passedLine['width'];
                    if (this.#passedLine['strokeWidth'] > 0x0) {
                        const _0x7c3b5a = _0x3606b8 + this.#passedLine['strokeWidth'] * 0x2;
                        logi['maps']['Line']['drawPolyLine'](_0x109dbc, _0x302013, _0x7c3b5a, this.#passedLine['strokeColor']);
                    }
                    logi['maps']['Line']['drawPolyLine'](_0x109dbc, _0x302013, _0x3606b8, this.#passedLine['color']);
                }
            }
            _0x109dbc['restore']();
        }
    }
    static ['drawPolyLine'](_0x5bf545, _0x4a56a8, _0x4e64f5, _0x1f673c) {
        if (_0x4a56a8?.['length'] >= 0x2) {
            _0x5bf545['beginPath'](), _0x5bf545['lineWidth'] = _0x4e64f5, _0x5bf545['strokeStyle'] = _0x1f673c, _0x5bf545['lineCap'] = 'round', _0x5bf545['lineJoin'] = 'round', _0x5bf545['moveTo'](_0x4a56a8[0x0]['x'], _0x4a56a8[0x0]['y']);
            for (let _0x1206a4 of _0x4a56a8) {
                _0x5bf545['lineTo'](_0x1206a4['x'], _0x1206a4['y']);
            }
            _0x5bf545['stroke']();
        }
    }
    static ['drawDottedLine'](_0x170239, _0x1bfe0d, _0x2e5184, _0x533504, _0x1aab92) {
        if (_0x1bfe0d?.['length'] >= 0x2) {
            _0x170239['setLineDash']([
                0x0,
                _0x533504
            ]), _0x170239['beginPath'](), _0x170239['lineWidth'] = _0x2e5184 * 0x2, _0x170239['strokeStyle'] = _0x1aab92, _0x170239['lineCap'] = 'round', _0x170239['lineJoin'] = 'round', _0x170239['moveTo'](_0x1bfe0d[0x0]['x'], _0x1bfe0d[0x0]['y']);
            for (let _0x47ae31 of _0x1bfe0d) {
                _0x170239['lineTo'](_0x47ae31['x'], _0x47ae31['y']);
            }
            _0x170239['stroke'](), _0x170239['setLineDash']([]);
        }
    }
    #pointOnLine(_0xd755e3) {
        if (!this['getLayer']() || !this['getVisible']())
            return ![];
        if (this.#screenPoints['length'] >= 0x2 && this.#boundaryWidth > 0x0) {
            const _0x5b58b1 = this.#boundaryWidth * 0.5 + 0x2;
            let _0x3abdf8 = null;
            for (let _0x59151c of this.#screenPoints) {
                if (_0x3abdf8 != null) {
                    const _0xd74458 = this.#getPerpendicularPoint(_0x3abdf8, _0x59151c, _0xd755e3);
                    if (_0xd74458['distance'] <= _0x5b58b1)
                        return !![];
                }
                _0x3abdf8 = _0x59151c;
            }
        }
        return ![];
    }
    #getSnappedLine(_0x599ad8, _0x4d0b41, _0x24b4ab) {
        const _0x4ce7e2 = this.#nearestPointOnLine(_0x599ad8, _0x4d0b41, _0x24b4ab), _0xb8eeb7 = {
                'lng': _0x4ce7e2['lng'],
                'lat': _0x4ce7e2['lat']
            }, _0xc3b267 = _0x4ce7e2['index'];
        let _0x18a49a = _0x599ad8['slice'](0x0, _0xc3b267 + 0x1);
        _0x18a49a['push'](_0xb8eeb7);
        let _0x1c6f6c = _0x599ad8['slice'](_0xc3b267);
        return _0x1c6f6c[0x0] = _0xb8eeb7, {
            'passed': _0x18a49a,
            'passing': _0x1c6f6c
        };
    }
    #nearestPointOnLine(_0x421ee4, _0x45393c, _0x4b3517) {
        let _0x208ad7 = {
                'lng': 0x0,
                'lat': 0x0,
                'distance': Infinity,
                'index': 0x0
            }, _0x553d44 = {
                'lng': 0x0,
                'lat': 0x0,
                'distance': Infinity,
                'index': 0x0
            };
        _0x4b3517 && (_0x4b3517 = logi['maps']['Utils']['generalizeDegree'](_0x4b3517));
        let _0x5e3447 = 0x0, _0x1e4f3d = null;
        for (let _0x48f40a of _0x421ee4) {
            if (_0x1e4f3d != null) {
                const _0x4be54f = this.#getPerpendicularLatLng(_0x1e4f3d, _0x48f40a, _0x45393c);
                let _0x210cd7 = ![];
                if (_0x4b3517) {
                    let _0x51c48b = {
                            'x': _0x48f40a['lng'] - _0x1e4f3d['lng'],
                            'y': _0x48f40a['lat'] - _0x1e4f3d['lat']
                        }, _0x45b011 = {
                            'x': 0x0,
                            'y': 0x1
                        };
                    _0x51c48b = logi['maps']['Utils']['getVectorNormalize'](_0x51c48b), _0x45b011 = logi['maps']['Utils']['getVectorNormalize'](_0x45b011);
                    const _0x5c4adf = logi['maps']['Utils']['getDegreeBetweenVectors'](_0x51c48b, _0x45b011), _0xac9407 = _0x5c4adf - _0x4b3517;
                    _0x210cd7 = _0xac9407 < -0x87 || _0xac9407 > 0x87;
                }
                _0x210cd7 === ![] ? _0x4be54f['distance'] < _0x208ad7['distance'] && (_0x208ad7['lng'] = _0x4be54f['lng'], _0x208ad7['lat'] = _0x4be54f['lat'], _0x208ad7['distance'] = _0x4be54f['distance'], _0x208ad7['index'] = _0x5e3447 - 0x1) : _0x4be54f['distance'] < _0x553d44['distance'] && (_0x553d44['lng'] = _0x4be54f['lng'], _0x553d44['lat'] = _0x4be54f['lat'], _0x553d44['distance'] = _0x4be54f['distance'], _0x553d44['index'] = _0x5e3447 - 0x1);
            }
            _0x1e4f3d = _0x48f40a, _0x5e3447 += 0x1;
        }
        return _0x208ad7['distance'] < Infinity ? _0x208ad7 : _0x553d44;
    }
    #getPerpendicularLatLng(_0x1db628, _0x13f981, _0x51feb2) {
        let _0x3bff2d = {
            'lng': 0x0,
            'lat': 0x0,
            'distance': 0x0,
            'result': 0x0
        };
        const _0x594169 = _0x1db628['lng'] > _0x13f981['lng'] ? _0x13f981['lng'] : _0x1db628['lng'], _0x26e4a4 = _0x1db628['lat'] > _0x13f981['lat'] ? _0x13f981['lat'] : _0x1db628['lat'], _0x16dd5f = _0x1db628['lng'] > _0x13f981['lng'] ? _0x1db628['lng'] : _0x13f981['lng'], _0x503fe4 = _0x1db628['lat'] > _0x13f981['lat'] ? _0x1db628['lat'] : _0x13f981['lat'];
        if (_0x1db628['lng'] === _0x13f981['lng'] && _0x1db628['lat'] === _0x13f981['lat'])
            _0x3bff2d['lng'] = _0x1db628['lng'], _0x3bff2d['lat'] = _0x1db628['lat'], _0x3bff2d['distance'] = this.#getLatLngDistance(_0x1db628, _0x51feb2), _0x3bff2d['result'] = 0x1;
        else {
            if (_0x1db628['lng'] === _0x13f981['lng'])
                _0x51feb2['lat'] >= _0x26e4a4 && _0x51feb2['lat'] <= _0x503fe4 ? (_0x3bff2d['lng'] = _0x1db628['lng'], _0x3bff2d['lat'] = _0x51feb2['lat'], _0x3bff2d['distance'] = Math['abs'](_0x51feb2['lng'] - _0x1db628['lng']), _0x3bff2d['result'] = 0x1) : (_0x3bff2d['lng'] = _0x1db628['lng'], Math['abs'](_0x1db628['lat'] - _0x51feb2['lat']) < Math['abs'](_0x13f981['lat'] - _0x51feb2['lat']) ? _0x3bff2d['lat'] = _0x1db628['lat'] : _0x3bff2d['lat'] = _0x13f981['lat'], _0x3bff2d['distance'] = this.#getLatLngDistance(_0x51feb2, _0x3bff2d), _0x3bff2d['result'] = 0x2);
            else {
                if (_0x1db628['lat'] === _0x13f981['lat'])
                    _0x51feb2['lng'] >= _0x594169 && _0x51feb2['lng'] <= _0x16dd5f ? (_0x3bff2d['lng'] = _0x51feb2['lng'], _0x3bff2d['lat'] = _0x1db628['lat'], _0x3bff2d['distance'] = Math['abs'](_0x51feb2['lat'] - _0x1db628['lat']), _0x3bff2d['result'] = 0x1) : (_0x3bff2d['lat'] = _0x1db628['lat'], Math['abs'](_0x1db628['lng'] - _0x51feb2['lng']) < Math['abs'](_0x13f981['lng'] - _0x51feb2['lng']) ? _0x3bff2d['lng'] = _0x1db628['lng'] : _0x3bff2d['lng'] = _0x13f981['lng'], _0x3bff2d['distance'] = this.#getLatLngDistance(_0x51feb2, _0x3bff2d), _0x3bff2d['result'] = 0x2);
                else {
                    let _0x68b0a = (_0x13f981['lat'] - _0x1db628['lat']) / (_0x13f981['lng'] - _0x1db628['lng']), _0x1afccc = -0x1 / _0x68b0a, _0x50df1a = (_0x51feb2['lat'] - _0x1afccc * _0x51feb2['lng'] - _0x1db628['lat'] + _0x68b0a * _0x1db628['lng']) / (_0x68b0a - _0x1afccc), _0x3fa7af = _0x68b0a * (_0x50df1a - _0x1db628['lng']) + _0x1db628['lat'];
                    _0x3bff2d['lng'] = _0x50df1a, _0x3bff2d['lat'] = _0x3fa7af, _0x3bff2d['lng'] >= _0x594169 && _0x3bff2d['lng'] <= _0x16dd5f && _0x3bff2d['lat'] >= _0x26e4a4 && _0x3bff2d['lat'] <= _0x503fe4 ? (_0x3bff2d['distance'] = this.#getLatLngDistance(_0x51feb2, _0x3bff2d), _0x3bff2d['result'] = 0x1) : (this.#getLatLngDistance(_0x1db628, _0x3bff2d) < this.#getLatLngDistance(_0x13f981, _0x3bff2d) ? (_0x3bff2d['lng'] = _0x1db628['lng'], _0x3bff2d['lat'] = _0x1db628['lat']) : (_0x3bff2d['lng'] = _0x13f981['lng'], _0x3bff2d['lat'] = _0x13f981['lat']), _0x3bff2d['distance'] = this.#getLatLngDistance(_0x51feb2, _0x3bff2d), _0x3bff2d['result'] = 0x2);
                }
            }
        }
        return _0x3bff2d;
    }
    #getPerpendicularPoint(_0xc32daf, _0x5576b3, _0x22e9c0) {
        let _0x184e01 = {
            'x': 0x0,
            'y': 0x0,
            'distance': 0x0,
            'result': 0x0
        };
        const _0x462177 = _0xc32daf['x'] > _0x5576b3['x'] ? _0x5576b3['x'] : _0xc32daf['x'], _0x3b0a8a = _0xc32daf['y'] > _0x5576b3['y'] ? _0x5576b3['y'] : _0xc32daf['y'], _0x4208c9 = _0xc32daf['x'] > _0x5576b3['x'] ? _0xc32daf['x'] : _0x5576b3['x'], _0x44d1d7 = _0xc32daf['y'] > _0x5576b3['y'] ? _0xc32daf['y'] : _0x5576b3['y'];
        if (_0xc32daf['x'] === _0x5576b3['x'] && _0xc32daf['y'] === _0x5576b3['y'])
            _0x184e01['x'] = _0xc32daf['x'], _0x184e01['y'] = _0xc32daf['y'], _0x184e01['distance'] = this.#getPointDistance(_0xc32daf, _0x22e9c0), _0x184e01['result'] = 0x1;
        else {
            if (_0xc32daf['x'] === _0x5576b3['x'])
                _0x22e9c0['y'] >= _0x3b0a8a && _0x22e9c0['y'] <= _0x44d1d7 ? (_0x184e01['x'] = _0xc32daf['x'], _0x184e01['y'] = _0x22e9c0['y'], _0x184e01['distance'] = Math['abs'](_0x22e9c0['x'] - _0xc32daf['x']), _0x184e01['result'] = 0x1) : (_0x184e01['x'] = _0xc32daf['x'], Math['abs'](_0xc32daf['y'] - _0x22e9c0['y']) < Math['abs'](_0x5576b3['y'] - _0x22e9c0['y']) ? _0x184e01['y'] = _0xc32daf['y'] : _0x184e01['y'] = _0x5576b3['y'], _0x184e01['distance'] = this.#getPointDistance(_0x22e9c0, _0x184e01), _0x184e01['result'] = 0x2);
            else {
                if (_0xc32daf['y'] === _0x5576b3['y'])
                    _0x22e9c0['x'] >= _0x462177 && _0x22e9c0['x'] <= _0x4208c9 ? (_0x184e01['x'] = _0x22e9c0['x'], _0x184e01['y'] = _0xc32daf['y'], _0x184e01['distance'] = Math['abs'](_0x22e9c0['y'] - _0xc32daf['y']), _0x184e01['result'] = 0x1) : (_0x184e01['y'] = _0xc32daf['y'], Math['abs'](_0xc32daf['x'] - _0x22e9c0['x']) < Math['abs'](_0x5576b3['x'] - _0x22e9c0['x']) ? _0x184e01['x'] = _0xc32daf['x'] : _0x184e01['x'] = _0x5576b3['x'], _0x184e01['distance'] = this.#getPointDistance(_0x22e9c0, _0x184e01), _0x184e01['result'] = 0x2);
                else {
                    let _0x25f0a4 = (_0x5576b3['y'] - _0xc32daf['y']) / (_0x5576b3['x'] - _0xc32daf['x']), _0x882b29 = -0x1 / _0x25f0a4, _0x48da27 = (_0x22e9c0['y'] - _0x882b29 * _0x22e9c0['x'] - _0xc32daf['y'] + _0x25f0a4 * _0xc32daf['x']) / (_0x25f0a4 - _0x882b29), _0x43c979 = _0x25f0a4 * (_0x48da27 - _0xc32daf['x']) + _0xc32daf['y'];
                    _0x184e01['x'] = _0x48da27, _0x184e01['y'] = _0x43c979, _0x184e01['x'] >= _0x462177 && _0x184e01['x'] <= _0x4208c9 && _0x184e01['y'] >= _0x3b0a8a && _0x184e01['y'] <= _0x44d1d7 ? (_0x184e01['distance'] = this.#getPointDistance(_0x22e9c0, _0x184e01), _0x184e01['result'] = 0x1) : (this.#getPointDistance(_0xc32daf, _0x184e01) < this.#getPointDistance(_0x5576b3, _0x184e01) ? (_0x184e01['x'] = _0xc32daf['x'], _0x184e01['y'] = _0xc32daf['y']) : (_0x184e01['x'] = _0x5576b3['x'], _0x184e01['y'] = _0x5576b3['y']), _0x184e01['distance'] = this.#getPointDistance(_0x22e9c0, _0x184e01), _0x184e01['result'] = 0x2);
                }
            }
        }
        return _0x184e01;
    }
    #getLatLngDistance(_0x246cdc, _0x35ce74) {
        let _0x2f09d9, _0x11fd6a;
        return _0x2f09d9 = _0x35ce74['lng'] - _0x246cdc['lng'], _0x11fd6a = _0x35ce74['lat'] - _0x246cdc['lat'], Math['sqrt'](_0x2f09d9 * _0x2f09d9 + _0x11fd6a * _0x11fd6a);
    }
    #getPointDistance(_0x116617, _0x39e635) {
        let _0x2457e3, _0x5b879e;
        return _0x2457e3 = _0x39e635['x'] - _0x116617['x'], _0x5b879e = _0x39e635['y'] - _0x116617['y'], Math['sqrt'](_0x2457e3 * _0x2457e3 + _0x5b879e * _0x5b879e);
    }
};
export default logi['maps']['Route'];