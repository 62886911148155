var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pagination" },
    [
      _c("v-col", { attrs: { cols: "2" } }, [
        _c(
          "div",
          { staticClass: "d-flex align-center" },
          [
            _c(
              "div",
              { staticClass: "font-size13 surface700 flex-none mr-2" },
              [_vm._v(" 페이지당 리스트 갯수 ")]
            ),
            _c("v-select", {
              staticClass: "select-slot",
              staticStyle: {
                "min-width": "80px !important",
                "max-width": "80px !important",
              },
              attrs: {
                items: _vm.pageSizes,
                value: _vm.syncedPageSize,
                dense: "",
                outlined: "",
                "hide-details": "",
                "menu-props": { bottom: true, offsetY: true },
              },
              on: { input: _vm.handlePageSizeChange },
            }),
          ],
          1
        ),
      ]),
      _c(
        "v-col",
        { attrs: { cols: "9" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "8" } },
            [
              _c("v-pagination", {
                attrs: {
                  circle: "",
                  color: "primary",
                  value: _vm.pageNum,
                  "total-visible": 7,
                  length: _vm.totalPageCount,
                },
                on: { input: _vm.handlePageNumChange },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }