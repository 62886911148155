var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-card-title",
        { staticClass: "font-size24 surface800 popup-title" },
        [
          _c("span", [_vm._v("동의 상태 변경")]),
          _c("v-spacer"),
          _c(
            "v-btn",
            { attrs: { icon: "" }, on: { click: _vm.editPopupHideBtn } },
            [
              _c("v-icon", { attrs: { size: "25" } }, [
                _vm._v(" " + _vm._s(_vm.icons.mdiWindowClose) + " "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card-text",
        {},
        [_c("v-divider", { staticStyle: { margin: "0 !important" } })],
        1
      ),
      _c(
        "v-card-text",
        {
          staticClass: "field-54",
          staticStyle: { "padding-bottom": "0 !important" },
        },
        [
          _c("v-card", { attrs: { outlined: "", color: "transparent" } }, [
            _c("div", [
              _c("span", { staticClass: "font-size16 font-weight700" }, [
                _vm._v("상태 선택"),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "d-flex align-center res-font-size16 surface800" },
              [
                _c("v-checkbox", {
                  staticClass: "v-check-customer",
                  attrs: {
                    dense: "",
                    "hide-details": "",
                    color: "accent",
                    "on-icon": _vm.icons.mdiCircleSlice8,
                    "off-icon": _vm.icons.mdiCircleOutline,
                  },
                  on: { click: _vm.setIsAddition },
                  model: {
                    value: _vm.termsData.isAgrees,
                    callback: function ($$v) {
                      _vm.$set(_vm.termsData, "isAgrees", $$v)
                    },
                    expression: "termsData.isAgrees",
                  },
                }),
                _c("span", { staticClass: "flex-none mr-10 mt-2" }, [
                  _vm._v("동의"),
                ]),
                _c("v-checkbox", {
                  staticClass: "v-check-customer ml-1",
                  attrs: {
                    dense: "",
                    "hide-details": "",
                    color: "accent",
                    "on-icon": _vm.icons.mdiCircleSlice8,
                    "off-icon": _vm.icons.mdiCircleOutline,
                  },
                  on: { click: _vm.setIsCut },
                  model: {
                    value: _vm.termsData.isNotAgrees,
                    callback: function ($$v) {
                      _vm.$set(_vm.termsData, "isNotAgrees", $$v)
                    },
                    expression: "termsData.isNotAgrees",
                  },
                }),
                _c("span", { staticClass: "flex-none mr-1 mt-2" }, [
                  _vm._v("비동의"),
                ]),
              ],
              1
            ),
            _c("div", { staticClass: "mt-8" }, [
              _c("span", { staticClass: "font-size16 font-weight700" }, [
                _vm._v("변경 사유"),
              ]),
            ]),
            _c(
              "div",
              [
                _c("v-text-field", {
                  staticClass: "mt-2",
                  attrs: {
                    maxlength: "40",
                    outlined: "",
                    dense: "",
                    placeholder: "사유를 입력해 주세요",
                  },
                  model: {
                    value: _vm.termsData.reasonContent,
                    callback: function ($$v) {
                      _vm.$set(_vm.termsData, "reasonContent", $$v)
                    },
                    expression: "termsData.reasonContent",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "v-card-actions",
        { staticStyle: { padding: "8px 0 24px 0 !important" } },
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              staticClass: "popup_btn",
              attrs: { color: "primary", disabled: _vm.isSubmitBtn },
              on: { click: _vm.setModifyChoiceTerms },
            },
            [_vm._v(" 확인 ")]
          ),
          _c(
            "v-btn",
            {
              staticClass: "popup_btn cancel_btn",
              on: { click: _vm.editPopupHideBtn },
            },
            [_vm._v(" 취소 ")]
          ),
          _c("v-spacer"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }