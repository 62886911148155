export const SYSTEM_INFO: any = (function () {
  // Semantic Versioning: Major.Minor.Revision.Build
  // - Major: 서비스 전체 플랫폼 버전을 나타냄
  // - Minor: Revision이 10이 되면 Minor 증가
  // - Revision: 변경/수정사항 발행 시 증가, Revision이 10이 되면 Minor 증가 후 0으로 초기화
  // - Build: 기존 발행했던 버전을 유지한 채 Hotfix 버전을 발행하는 경우 또는 내부 검증용 버전 추가발행 시 증가
  enum _SYSTEM_INFO_WEB_VERSION {
    WEB_VERSION_NODE_ENV_LOCAL = 'v0.0.0.1',
    WEB_VERSION_NODE_ENV_QA = 'v0.0.0.1',
    WEB_VERSION_NODE_ENV_PROD = 'v0.0.0.1',
  }

  return {
    WEB_VERSION_NODE_ENV_LOCAL: process.env.VUE_APP_VERSION ||_SYSTEM_INFO_WEB_VERSION.WEB_VERSION_NODE_ENV_LOCAL,
    WEB_VERSION_NODE_ENV_QA:    process.env.VUE_APP_VERSION ||_SYSTEM_INFO_WEB_VERSION.WEB_VERSION_NODE_ENV_QA,
    WEB_VERSION_NODE_ENV_PROD:  process.env.VUE_APP_VERSION ||_SYSTEM_INFO_WEB_VERSION.WEB_VERSION_NODE_ENV_PROD,
  }
})()
