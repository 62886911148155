import axios from 'axios'

class SmartroService {
  private apiUrl: string
  constructor() {
    this.apiUrl = '/smartro'
  }

  /**
   * 카드 결제 승인/취소 이력 리스트
   * @param params
   * callRequestId (필수): 화물 id
   */
    getPaymentHistoryList(params) {
    return axios
      .get(this.apiUrl + `/billing/payment/history`, {params})
      .then((res) => res.data)
  }
}

export default new SmartroService()
