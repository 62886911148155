































































import { Vue, Component, Prop } from 'vue-property-decorator'
import {} from '@mdi/js'
import TermsInfoItem from '@/views/management/TermsInfoItem.vue'
import DatetimeUtils from '@/utils/datetime.utils'
import USER_STATUS from '@/views/constant/user.status'
import userService from '@/services-itgo/user.service'
import FreightInfoItem from '@/views/freightHistory/FreightInfoItem.vue'
import TermsHistoryPopup from '@/views/management/TermsHistoryPopup.vue'
import moment from 'moment/moment'
import _ from 'lodash'

@Component({
  components: { TermsHistoryPopup, FreightInfoItem, TermsInfoItem },
})
export default class TermsConditionsHistoryTab extends Vue {
  @Prop(Number) id
  @Prop(Number) type

  mounted() {
    console.log(this.id)
    console.log(this.type)
    this.getUserTermsInfo()
  }

  userTermsData: any = null
  async getUserTermsInfo() {
    try {
      const body = {
        userId: this.id,
      }
      this.userTermsData = await userService.postGetUserTerms(body)
      console.log(this.userTermsData)
    }catch (e) {
      console.log(e)
    }
  }

  get isBroker() {
    return this.type === USER_STATUS.USER_TYPE_BROKER
  }

  get isDriver() {
    return this.type === USER_STATUS.USER_TYPE_DRIVER
  }

  get isCaller() {
    return this.type === USER_STATUS.USER_TYPE_CALLER
  }

  get userdata() {
    return this.userTermsData
  }

  get requiredInfo() {
    return [
      {
        title: '필수약관',
        data: [
          {
            title: '[필수] 화물잇고 서비스 이용 약관 동의',
            content: this.createdTermsDtDateString(
              this.userTermsData?.itgoTermsDt,
              true,
            ),
            show: true,
            btnShow: false,
            termsType: '',
          },
          {
            title: '[필수] 위치기반 서비스 이용 약관 동의',
            content: this.createdTermsDtDateString(
              this.userTermsData?.locationTermsDt,
              true,
            ),
            show: this.isDriver,
            btnShow: false,
            termsType: '',
          },
          {
            title: '[필수] 개인위치정보 제3자 제공',
            content: this.createdTermsDtDateString(
              this.userTermsData?.location3TermsDt,
              true,
            ),
            show: this.isDriver,
            btnShow: false,
            termsType: '',
          },
          {
            title: '[필수] 개인 정보 수집 이용 동의',
            content: this.createdTermsDtDateString(
              this.userTermsData?.privacyTermsDt,
              true,
            ),
            show: true,
            btnShow: false,
            termsType: '',
          },
          {
            title: '[필수] 개인정보 국외이전에 관한 동의',
            content: this.createdTermsDtDateString(
              this.userTermsData?.overseasTermsDt,
              true,
            ),
            show: true,
            btnShow: false,
            termsType: '',
          },
          {
            title: '[필수] 개인정보 제3자 제공에 관한 동의',
            content: this.createdTermsDtDateString(
              this.userTermsData?.privacy3TermsDt,
              true,
            ),
            show: this.isDriver,
            btnShow: false,
            termsType: '',
          },
        ],
        show: true,
      },
    ]
  }

  get choiceInfo() {
    return [
      {
        title: '선택약관',
        data: [
          {
            title: '[선택] 마케팅 활용을 위한 개인정보 수집 및 이용 동의',
            content: this.checkingDtDate(
              this.userTermsData?.choiceTermsDt,
              this.userTermsData?.choiceDisagreeDt,
            )
              ? this.createdTermsDtDateString(
                  this.userTermsData?.choiceTermsDt,
                  true,
                )
              : this.createdTermsDtDateString(
                  this.userTermsData?.choiceDisagreeDt,
                  false,
                ),
            termsType: 'marketing',
            show: true,
            btnShow: true,
          },
          {
            title: '[선택] 광고성 정보 수신 동의',
            content: this.checkingDtDate(
              this.userTermsData?.choiceBlurbTermsDt,
              this.userTermsData?.choiceBlurbDisagreeDt,
            )
              ? this.createdTermsDtDateString(
                  this.userTermsData?.choiceBlurbTermsDt,
                  true,
                )
              : this.createdTermsDtDateString(
                  this.userTermsData?.choiceBlurbDisagreeDt,
                  false,
                ),
            termsType: 'blurb',
            show: true,
            btnShow: true,
          },
        ],
        show: true,
      },
    ]
  }

  get cardInfo() {
    return [
      {
        title: '스마트로 & 신한카드 약관',
        data: [
          {
            title: '[필수] 화물운송료 전자고지결제서비스 이용약관',
            content: this.createdTermsDtDateString(
              this.userTermsData?.paymentTermsDt,
              true,
            ),
            show: this.isBroker,
            btnShow: false,
            termsType: '',
          },
          {
            title: '[필수] 엘지유플러스 고객정보 보안관리 약정 동의',
            content: this.createdTermsDtDateString(
              this.userTermsData?.lguSecurityTermsDt,
              true,
            ),
            show: this.isDriver,
            btnShow: false,
            termsType: '',
          },
          {
            title: '[필수] 신한카드 화물 운송료 전자고지 결제 서비스 정보 동의',
            content: this.createdTermsDtDateString(
              this.userTermsData?.shinhanPaymentTermsDt,
              true,
            ),
            show: this.isDriver,
            btnShow: false,
            termsType: '',
          },
          {
            title: '[필수] 스마트로 전자지급결제대행 서비스 이용 약관 동의',
            content: this.createdTermsDtDateString(
              this.userTermsData?.smartroPaymentTermsDt,
              true,
            ),
            show: this.isDriver,
            btnShow: false,
            termsType: '',
          },
          {
            title: '[필수] 플러그인 개인정보 수집 이용 동의',
            content: this.createdTermsDtDateString(
              this.userTermsData?.pluginCollectionTermsDt,
              true,
            ),
            show: this.isDriver,
            btnShow: false,
            termsType: '',
          },
          {
            title: '[필수] 플러그인 개인정보 제공 위탁 동의',
            content: this.createdTermsDtDateString(
              this.userTermsData?.pluginEntrustedTermsDt,
              true,
            ),
            show: this.isDriver,
            btnShow: false,
            termsType: '',
          },
          {
            title: '[필수] 플러그인 고유식별정보 수집 이용 동의',
            content: this.createdTermsDtDateString(
              this.userTermsData?.pluginIdentificationTermsDt,
              true,
            ),
            show: this.isDriver,
            btnShow: false,
            termsType: '',
          },
        ],
        show: this.isDriver || this.isBroker,
      },
    ]
  }

  createdTermsDtDateString(dt, isAgrees) {
    if (isAgrees) {
      return `동의 (${DatetimeUtils.setKoreaDate(dt, 'full')})`
    } else {
      return `비동의 (${DatetimeUtils.setKoreaDate(dt, 'full')})`
    }
  }

  checkingDtDate(agreesDt, disagreeDt) {
    if (agreesDt && disagreeDt === null) return true
    else if (disagreeDt && agreesDt === null) return false
    else {
      return moment(agreesDt).isAfter(disagreeDt)
    }
  }

  updateUserInfo() {
    this.getUserTermsInfo()
  }

  termsHistoryPopup: boolean = false

  componentKey: any = {
    termsHistoryPopupKey: 0,
  }

  showHistory(value) {
    console.log(value)
    this.showHistoryData(value)
    this.termsHistoryPopup = true
    this.componentKey.termsHistoryPopupKey++
  }

  historyPopupHide() {
    this.termsHistoryPopup = false
  }

  userTermHistoryData: any = []
  showHistoryData(value) {
    console.log(this.userTermsData)
    this.userTermHistoryData = []
    if (value === 'marketing') {
      console.log(value)
      if (this.userTermsData.choiceTermsDt) {
        let makeData_1: any = {
          termsStatus: '동의',
          regDt: this.userTermsData.choiceTermsDt,
          changedType: USER_STATUS.GET_USER_STRING_TYPE(
            this.userTermsData.choiceTermsUserType,
          ),
          changedContent: this.userTermsData.choiceTermsReason,
          changedName: this.userTermsData.choiceTermsUserId,
        }
        console.log(makeData_1)
        this.userTermHistoryData.push(makeData_1)
      }
      if (this.userTermsData.choiceDisagreeDt) {
        let makeData_2: any = {
          termsStatus: '비동의',
          regDt: this.userTermsData.choiceDisagreeDt,
          changedType: USER_STATUS.GET_USER_STRING_TYPE(
            this.userTermsData.choiceDisagreeUserType,
          ),
          changedContent: this.userTermsData.choiceDisagreeReason,
          changedName: this.userTermsData.choiceDisagreeUserId,
        }
        console.log(makeData_2)
        this.userTermHistoryData.push(makeData_2)
      }
      console.log(this.userTermHistoryData)
    } else if (value === 'blurb') {
      console.log(value)
      if (this.userTermsData.choiceBlurbTermsDt) {
        let makeData_1: any = {
          termsStatus: '동의',
          regDt: this.userTermsData.choiceBlurbTermsDt,
          changedType: USER_STATUS.GET_USER_STRING_TYPE(
            this.userTermsData.choiceBlurbTermsUserType,
          ),
          changedContent: this.userTermsData.choiceBlurbTermsReason,
          changedName: this.userTermsData.choiceBlurbTermsUserId,
        }
        console.log(makeData_1)
        this.userTermHistoryData.push(makeData_1)
      }
      if (this.userTermsData.choiceBlurbDisagreeDt) {
        let makeData_2: any = {
          termsStatus: '비동의',
          regDt: this.userTermsData.choiceBlurbDisagreeDt,
          changedType: USER_STATUS.GET_USER_STRING_TYPE(
            this.userTermsData.choiceBlurbDisagreeUserType,
          ),
          changedContent: this.userTermsData.choiceBlurbDisagreeReason,
          changedName: this.userTermsData.choiceBlurbDisagreeUserId,
        }
        console.log(makeData_2)
        this.userTermHistoryData.push(makeData_2)
      }
      console.log(this.userTermHistoryData)
    }

    if (_.size(this.userTermHistoryData)) {
      this.userTermHistoryData = _.sortBy(this.userTermHistoryData, 'regDt')
    }
  }
}
