<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="viewBox"
  >
    <g id="ic_account" transform="translate(323 -375)">
      <g id="wallet" transform="translate(-322.429 376)">
        <path id="패스_29457" data-name="패스 29457" d="M19.318,13.159A1.159,1.159,0,1,1,18.159,12,1.159,1.159,0,0,1,19.318,13.159Z" transform="translate(2.223 1.112)" :fill="iconColor" :stroke="iconColor" stroke-width="0.5" fill-rule="evenodd"/>
        <path id="패스_29458" data-name="패스 29458" d="M21.541,6.159A1.159,1.159,0,0,0,20.382,5H4.738A1.738,1.738,0,0,0,3,6.738V20.065a1.159,1.159,0,0,0,1.159,1.159H22.7a1.159,1.159,0,0,0,1.159-1.159V8.476A1.159,1.159,0,0,0,22.7,7.318H4.735a.579.579,0,0,1,0-1.159ZM4.159,8.476H22.7V20.065H4.159Z" :fill="iconColor" :stroke="iconColor" stroke-width="0.5" fill-rule="evenodd"/>
      </g>
      <rect id="사각형_31281" data-name="사각형 31281" width="28" height="28" transform="translate(-323 375)" fill="none"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'account',

  props: {
    viewBox: { type: String, default: '0 0 28 28' },
    iconName: { type: String, default: '' },
    width: { type: [Number, String], default: 28 },
    height: { type: [Number, String], default: 28 },
    iconColor: { type: String, default: '#979eaa' },
  },
}
</script>

<style scoped></style>
