var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "112",
        height: "45",
        viewBox: "0 0 112 45",
        fill: "none",
      },
    },
    [
      _c("g", { attrs: { "clip-path": "url(#clip0_1845_21487)" } }, [
        _c("path", {
          attrs: {
            d: "M89.9047 22.8555H83.7334V37.7107H103.866L108.798 32.314H89.9047V22.8555Z",
            fill: "#0B335A",
          },
        }),
        _c("path", {
          attrs: {
            d: "M62.5773 10.6903C61.2039 10.6903 60.0851 11.8149 60.0851 13.1932C60.0851 14.5716 61.2016 15.6962 62.5773 15.6962C63.953 15.6962 65.0695 14.5738 65.0695 13.1932C65.0695 11.8126 63.9507 10.6903 62.5773 10.6903ZM62.5773 21.0952C58.2433 21.0952 54.7188 17.5499 54.7188 13.1955C54.7188 8.84113 58.2455 5.2959 62.5773 5.2959C66.909 5.2959 70.4358 8.84113 70.4358 13.1955C70.4358 17.5499 66.9113 21.0952 62.5773 21.0952Z",
            fill: "#0B335A",
          },
        }),
        _c("path", {
          attrs: {
            d: "M79.77 20.9309L74.4014 26.6225V2.64014H79.77V20.9309Z",
            fill: "#0B335A",
          },
        }),
        _c("path", {
          attrs: {
            d: "M49.5752 0H32.0322V15.9204H49.5752V0ZM44.2066 10.5237H37.3986V5.39671H44.2066V10.5237Z",
            fill: "#0B335A",
          },
        }),
        _c("path", {
          attrs: {
            d: "M35.4248 37.8091H50.3505V26.4465H35.5635V24.1379H50.0322L54.6004 18.7412H35.1565L30.0562 24.5265V31.1552H44.9819V32.977H30.0562V45.0002H50.0458L54.298 39.8183H35.4248V37.8091Z",
            fill: "#0B335A",
          },
        }),
        _c("path", {
          attrs: {
            d: "M23.9394 15.9206V2.64258H18.5708V31.6536H10.544V27.4592C13.5592 26.3529 15.7193 23.4523 15.7193 20.0442C15.7193 15.6875 12.1948 12.1422 7.86081 12.1422C3.52679 12.1422 0 15.6852 0 20.0419C0 23.45 2.16019 26.3506 5.17535 27.4569V31.6513H0.354725V37.048H19.2871L23.9371 32.0468V21.3196H27.1501L32.0276 15.9229H23.9371L23.9394 15.9206ZM10.3507 20.0419C10.3507 21.4225 9.23196 22.5448 7.85853 22.5448C6.48511 22.5448 5.36863 21.4225 5.36863 20.0419C5.36863 18.6613 6.48511 17.5367 7.85853 17.5367C9.23196 17.5367 10.3507 18.659 10.3507 20.0419ZM0.354725 10.8485H15.3623V5.45179H10.5417V2.64486H5.17308V5.45179H0.354725V10.8485Z",
            fill: "#0B335A",
          },
        }),
        _c("path", {
          attrs: {
            d: "M56.2036 37.7106H63.7506L68.6622 31.6648L73.5738 37.7106H81.1208L68.6645 22.9331L68.6622 22.9354L56.2036 37.7106Z",
            fill: "#0B335A",
          },
        }),
        _c("path", {
          attrs: {
            d: "M112 3.31201H96.0148L91.5762 7.97499H106.813L112 3.31201Z",
            fill: "#F7008F",
          },
        }),
        _c("path", {
          attrs: {
            d: "M106.813 7.97499V22.5559L112 17.2689V3.31201L106.813 7.97499Z",
            fill: "#B9005F",
          },
        }),
        _c("path", {
          attrs: {
            d: "M102.995 11.479H88.2948L83.2422 16.8117H97.1606L102.995 11.479Z",
            fill: "#1A69A9",
          },
        }),
        _c("path", {
          attrs: {
            d: "M97.1606 16.8117V32.1995L102.995 26.2885V11.479L97.1606 16.8117Z",
            fill: "#0B335A",
          },
        }),
      ]),
      _c("defs", [
        _c("clipPath", { attrs: { id: "clip0_1845_21487" } }, [
          _c("rect", { attrs: { width: "112", height: "45", fill: "white" } }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }