





































































































































import { Vue, Component } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { SYSTEM_INFO } from '@/views/constant/system.constant'
import SystemService from '@/services-itgo/system.service'
import { mdiWeb, mdiClose, mdiServer } from '@mdi/js'
import PasswordEditPopup from '@/views/layouts/app-bar/PasswordEditPopup.vue'

import _ from 'lodash'

@Component({
  components: {
    PasswordEditPopup,
  },

  mounted() {
    this.fetchSystemInfo()
  },
})
export default class TempPage extends Vue {
  @Getter('auth/getUserName') userName
  @Getter('auth/getUserLoginId') email
  @Action('auth/getUser') getUser

  icons = {
    mdiWeb,
    mdiClose,
    mdiServer,
  }

  componentKey = {
    passwordPopupKey: 0,
    messageKey: 0,
    errorMessageKey: 0,
  }

  removeLastSection(str: string) {
    let lastIndex = str.lastIndexOf('.')
    return str.substring(0, lastIndex)
  }

  get webVersion() {
    return process.env.NODE_ENV === 'local' || process.env.NODE_ENV === 'dev'
      ? SYSTEM_INFO.WEB_VERSION_NODE_ENV_LOCAL
      : process.env.NODE_ENV === 'qa'
      ? SYSTEM_INFO.WEB_VERSION_NODE_ENV_QA
      : process.env.NODE_ENV === 'prod'
      ? this.removeLastSection(SYSTEM_INFO.WEB_VERSION_NODE_ENV_PROD)
      : ''
  }

  get backendVersion() {
    return process.env.NODE_ENV === 'prod'
      ? this.removeLastSection(this.systemInfo.tmsVersion)
      : this.systemInfo.tmsVersion
  }

  systemInfo = {
    tmsVersion: '',
    routeVersion: '',
    searchVersion: '',
    mapVersion: '',
  }

  async fetchSystemInfo() {
    try {
      const data = await SystemService.getSystemInfo()
      this.systemInfo = data
    } catch (error) {
      console.log(error)
    }
  }

  openPasswordPopup = false

  onClickPasswordPopup() {
    this.componentKey.passwordPopupKey++
    this.openPasswordPopup = true
  }

  closePasswordPopup() {
    this.openPasswordPopup = false
  }

  openVersionInfo = false

  onClickVersionInfo() {
    this.openVersionInfo = true
  }

  closeVersionInfo() {
    this.openVersionInfo = false
  }

  async onClickLogout() {
    await this.$store.dispatch('auth/signout', 0)
  }
}
