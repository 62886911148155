

































































































import { Vue, Component, Watch, Prop, Ref } from 'vue-property-decorator'
import { mdiEyeOffOutline, mdiEyeOutline, mdiWindowClose } from '@mdi/js'
import RegexUtils from '@/utils/regex.utils'
import AdminService from '@/services-itgo/admin.service'
import _ from 'lodash'
import { State } from 'vuex-class'

@Component({
  name: 'PasswordChangedPopup',
})

export default class PasswordChangedPopup extends Vue {
  @Prop(String) loginId
  @Ref() form

  @Ref() password
  @Ref() rePassword

  public isPasswordVisible = false
  public isRePasswordVisible = false

  mounted() {}

  get userInfo() {
    return {
      password: null,
      newPassword: null,
      newRePassword: null,
    }
  }

  public valid: boolean = false

  // 유효성 검사
  public rules: any = {
    passWordRules: [
      (v) =>
        !!v ||
        '비밀번호 조건을 다시 확인해 주세요. \n 10자 이상 20자 이내 영문 소문자, 숫자, 특수문자 2자 이상 조합',
      (v) =>
        RegexUtils.userPassWordRules(v) ||
        `비밀번호 조건을 다시 확인해 주세요. \n 10자 이상 20자 이내 영문 소문자, 숫자, 특수문자 2자 이상 조합`,
    ],
    userPassWordRules: [
      (v) =>
        !!v ||
        '비밀번호 조건을 다시 확인해 주세요. \n 10자 이상 20자 이내 영문 소문자, 숫자, 특수문자 2자 이상 조합',
      (v) =>
        RegexUtils.userPassWordRules(v) ||
        `비밀번호 조건을 다시 확인해 주세요. \n 10자 이상 20자 이내 영문 소문자, 숫자, 특수문자 2자 이상 조합`,
      (v) =>
        this.isPasswordEqualChecking() ||
        `현재 비밀번호와 다른 비밀번호를 입력해 주세요.`,
      (v) =>
        this.isLoginIdEqualChecking() ||
        `입력하신 비밀번호가 아이디와 일치합니다`,
    ],
    userRePassWordRules: [
      (v) => this.passwordChecking() || `비밀번호가 일치하지 않습니다.`,
    ],
    userEmailRules: [
      (v) => !!v || '유효한 이메일 형식이 아닙니다.',
      (v) =>
        RegexUtils.isCheckEmailRules(v) || `유효한 이메일 형식이 아닙니다.`,
    ],
  }

  icons: any = {
    mdiWindowClose,
    mdiEyeOutline,
    mdiEyeOffOutline,
  }

  public existBtn: boolean = false

  async cancelBtn() {
    this.$emit('close', false)
  }

  async userAddEditBtn() {
    try {
      console.log(this.userInfo)
      const body = {
        loginId: this.loginId,
        currentPassword: this.userInfo.password,
        password: this.userInfo.newPassword,
      }
      await AdminService.setUserPasswordReset(body)

      await this.$alert({
        title: '비밀번호 변경 완료',
        content:
          '비밀번호 변경이 완료 되었습니다.<br/> 새로운 비밀번호로 로그인해주세요',
      })
      this.$emit('close', false)
    } catch (e) {
      await this.$alert({
        title: '패스워드 변경 실패',
        content: e.response.data.errMsg,
      })
    } finally {
    }
  }

  isMatchPassword: boolean = false
  checkingPassword() {
    if (!_.isEmpty(this.userInfo.newPassword)) {
      if (_.isEqual(this.userInfo.newPassword, this.userInfo.newRePassword)) {
        this.isMatchPassword = true
      } else {
        this.isMatchPassword = false
      }
      this.rePassword.validate()
    } else {
      this.isMatchPassword = false
    }
  }

  get isBtn() {
    return !(this.valid && this.isMatchPassword)
  }

  isPasswordEqualChecking() {
    if (this.userInfo.newPassword === this.userInfo.password) return false
    else return true
  }

  isLoginIdEqualChecking() {
    if (this.userInfo.newPassword === this.loginId) return false
    else return true
  }
  passwordChecking() {
    if (this.userInfo.newPassword !== this.userInfo.newRePassword) return false
    else return true
  }
}
