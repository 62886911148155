





















































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import Vue2DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ko'
import DatetimeUtils from '@/utils/datetime.utils'
import Pagination from '@/views/utils/Pagination.vue'
import {
  mdiMagnify,
  mdiChevronUp,
  mdiChevronDown,
  mdiCircleSlice8,
  mdiCircleOutline,
} from '@mdi/js'
import { State } from 'vuex-class'
import _ from 'lodash'

@Component({
  components: {
    Vue2DatePicker,
    Pagination,
  },

  async mounted() {
    this.selectDateType.dayType = true
    await this.fetchFeatureList()
  },
})
export default class MainFeature extends Vue {
  icons: any = {
    mdiMagnify,
    mdiChevronUp,
    mdiChevronDown,
    mdiCircleSlice8,
    mdiCircleOutline,
  }

  dayDate = [new Date(), new Date()]
  monthDate = new Date()
  yearDate = new Date()

  selectDateType = {
    dayType: false,
    monthType: false,
    yearType: false,
  }

  featureInfo = {
    loading: false,
    headers: [
      {
        text: '날짜',
        align: 'center',
        value: 'date',
        show: true,
        width: '25%',
      },
      {
        text: '총 페이지뷰 수',
        align: 'center',
        value: 'totalViewCount',
        show: true,
        width: '30%',
      },
      {
        text: '총 방문자 수',
        align: 'center',
        value: 'totalVisitorCount',
        show: true,
        width: '30%',
      },
      {
        text: '',
        align: 'center',
        value: 'featureId',
        show: true,
        width: '5%',
      },
    ],
    items: [],
    serverItemsLength: 10,
  }

  featureDetailHeader = [
    {
      text: '구분',
      align: 'center',
      value: 'type',
      show: true,
      width: '15%',
    },
    {
      text: '회면명',
      align: 'center',
      value: 'view',
      show: true,
      width: '15%',
    },
    {
      text: '메뉴 ID',
      align: 'center',
      value: 'menuID',
      show: true,
      width: '15%',
    },
    {
      text: 'UV',
      align: 'center',
      value: 'uv',
      show: true,
      width: '10%',
    },
    {
      text: 'PV',
      align: 'center',
      value: 'pv',
      show: true,
      width: '10%',
    },
  ]

  /**
   * Pagiation 관련
   */

  pageSizes = [10, 20, 30]
  pageSize = 10
  pageNum = 1

  @Watch('pageSize')
  onChangePageSize() {
    this.pageNum = 1
    this.fetchFeatureList()
  }

  @Watch('pageNum')
  onChangePageNum() {
    this.fetchFeatureList()
  }

  get totalPageCount() {
    const length = Math.ceil(this.featureInfo.serverItemsLength / this.pageSize)
    return length === 0 ? 1 : length
  }

  async fetchFeatureList() {
    let dummyData: any = []

    for (let i = 0; i < 10; i++) {
      dummyData.push({
        key: `${i}_${i}`,
        featureId: i,
        date: DatetimeUtils.now_format('YYYY-MM-DD'),
        totalViewCount: Math.floor(Math.random() * 1000),
        totalVisitorCount: Math.floor(Math.random() * 1000),
        detailList: [
          {
            detailId: i,
            type: '로그인',
            view: '로그인',
            menuID: `COM-LO-${Math.floor(Math.random() * 1000)}`,
            uv: Math.floor(Math.random() * 1000),
            pv: Math.floor(Math.random() * 1000),
          },
        ],
      })
    }

    this.featureInfo.items = dummyData

    // this.featureInfo.items
    // try {

    //   this.featureInfo.loading = true
    //   this.featureInfo.items = []
    //   this.featureInfo.serverItemsLength = 0

    //   const params = {
    //     // fromRequestDt: fromDate,
    //     // toRequestDt: toDate,
    //     pageNum: this.pageNum,
    //     pageSize: this.pageSize,
    //   }

    //   const data = await callRequestService.getCallAdminList(params)

    //   this.featureInfo.items = data.content.map((item) => ({
    //     ...item,
    //     key: item.callRequestId,
    //   }))
    //   this.featureInfo.serverItemsLength = data.totalElementCount
    // } catch (error: any) {
    //   this.$alert(error.response.data.errMsg)
    // } finally {
    //   this.featureInfo.loading = false
    // }
  }

  async fetchFeatureDetailList(featureId) {
    if (!featureId) return []

    // return brokerService.getBrokerList({ companyId })
  }

  onSearch() {
    this.pageNum = 1
    this.fetchFeatureList()
  }

  expanded: any = []

  isExpanded(featureId) {
    if (!this.expanded.length) return
    return this.expanded?.find((item) => item.featureId === featureId)
  }

  onRowClicked(item) {
    if (
      this.expanded.length > 0 &&
      item.featureId === this.expanded[0].featureId
    ) {
      this.expanded = []
    } else {
      this.expanded = [item]
    }
  }

  dateTypeSelect(type) {
    console.log(this.selectDateType)
    console.log(type)

    switch (type) {
      case 'day':
        this.selectDateType.monthType = false
        this.selectDateType.yearType = false
        break
      case 'month':
        this.selectDateType.dayType = false
        this.selectDateType.yearType = false
        break
      case 'year':
        this.selectDateType.dayType = false
        this.selectDateType.monthType = false
        break
      default:
        break
    }
  }
}
