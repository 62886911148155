


















































































































































































































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { mdiChevronLeft } from '@mdi/js'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import FreightInfoItem from '@/views/freightHistory/FreightInfoItem.vue'
import Freight from '@/views/icons/leftPanel/freight.vue'
import {
  formatBizno,
  formatDate,
  formatPhoneNumber,
  formatPhoneNumberIntegrated,
} from '@/utils/business.util'
import CallerService from '@/services-itgo/caller.service'
import DriverService from '@/services-itgo/driver.service'
import BrokerService from '@/services-itgo/broker.service'
import AdminService from '@/services-itgo/admin.service'
import USER_STATUS from '@/views/constant/user.status'
import _, { before } from 'lodash'
import { axiosApiUrl } from '@/utils/axios.utils'
import userService from '@/services-itgo/user.service'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import PdfViewPopup from '@/views/popup/PdfViewPopup.vue'
import NoneDoc from '@/components/NoneDoc.vue'

@Component({
  name: 'MemberInfo',
  components: {
    PdfViewPopup,
    Freight,
    PerfectScrollbar,
    FreightInfoItem,
    NoneDoc,
    VuePdfEmbed,
  },

  mounted() {
    if (!this.reviewInfo) {
      const name =
        this.reviewType === 'changeDocument' ? 'memberDoc' : 'memberReg'
      return this.$router.push({ name })
    }

    this.fetchUserInfo()
  },
})
export default class FreightInfo extends Vue {
  icons = {
    mdiChevronLeft,
  }

  get reviewInfo(): any {
    return this.$route.params.reviewInfo
  }

  /**
   * reviewType
   *  * 회원가입 심사 : userReview
   *  * 서류변경 심사 : changeDocument
   */
  get reviewType(): any {
    return this.$route.params.reviewType
  }

  get id() {
    return this.reviewInfo?.userId
  }

  get name() {
    return this.reviewInfo?.name || ''
  }

  get type() {
    return this.reviewInfo?.type || ''
  }

  get isDriver() {
    return this.reviewInfo?.userType === USER_STATUS.USER_TYPE_DRIVER
  }

  get disabledPrevButton() {
    return this.step === 0
  }

  tab = null
  tabItems = [
    { title: '회원 정보' },
    { title: '사업자 정보' },
    { title: '차량 정보' },
    { title: '추가 서류' },
  ]

  step = 0

  audit: any = null
  reason = ''

  get progressBarImage() {
    const arr = this.auditItems.map((item) => {
      if (_.includes(USER_STATUS.USER_APPROVAL_STATUS_LIST(), item?.audit))
        return 't'
      else if (item?.audit === USER_STATUS.APPROVAL_STATUS_STRING_DENIED)
        return 'f'
      else if (item?.audit === USER_STATUS.APPROVAL_STATUS_STRING_REREQUEST)
        return 'f'
      else return 'n'
    })

    let current = this.setAudit()

    this.audit = current === 'n' ? null : current === 't' ? true : false

    let before = arr.slice(0, this.step).join('')
    let after = arr.slice(this.step + 1).join('')
    let name = before.concat(current, after)

    const prefix =
      this.reviewInfo?.userType === USER_STATUS.USER_TYPE_DRIVER
        ? 'driver'
        : this.reviewInfo?.userType === USER_STATUS.USER_TYPE_CALLER
        ? 'caller'
        : this.reviewInfo?.userType === USER_STATUS.USER_TYPE_BROKER
        ? 'broker'
        : ''

    const suffix = this.reviewType === 'changeDocument' ? '_changed' : ''

    return prefix ? require(`@/assets/imgs/${prefix}_${name}${suffix}.svg`) : ''
  }

  setAudit() {
    if (
      _.includes(
        USER_STATUS.USER_APPROVAL_STATUS_LIST(),
        this.auditItems[this.step]?.audit,
      )
    ) {
      this.audit = true
      return 't'
    } else if (
      this.auditItems[this.step].audit ===
      USER_STATUS.APPROVAL_STATUS_STRING_DENIED
    ) {
      this.audit = false
      return 'f'
    } else if (
      this.auditItems[this.step].audit ===
      USER_STATUS.APPROVAL_STATUS_STRING_REREQUEST
    ) {
      this.audit = false
      return 'f'
    } else {
      this.audit = null
      return 'n'
    }
  }

  setAuditValue(value) {
    if (value)
      this.auditItems[this.step].audit =
        USER_STATUS.APPROVAL_STATUS_STRING_APPROVAL
    else
      this.auditItems[this.step].audit =
        USER_STATUS.APPROVAL_STATUS_STRING_DENIED
  }

  public currentStage = 0

  // getStageClass(stage) {
  //   const auditStatus = this.auditItems[stage]?.audit

  //   switch (auditStatus) {
  //     case USER_STATUS.APPROVAL_STATUS_STRING_APPROVAL:
  //       return 't'
  //     case USER_STATUS.APPROVAL_STATUS_STRING_DENIED:
  //       return 'f'
  //     case USER_STATUS.APPROVAL_STATUS_STRING_REREQUEST:
  //       return 'r'
  //     default:
  //       return 'd'
  //   }
  // }

  // get progressStages() {
  //   switch (this.reviewInfo?.userType) {
  //     case USER_STATUS.USER_TYPE_BROKER:
  //       return [
  //         { name: '사업자 등록증', class: this.getStageClass(0) },
  //         { name: '주선 허가증', class: this.getStageClass(1) },
  //       ]
  //     case USER_STATUS.USER_TYPE_CALLER:
  //       return [
  //         { name: '사업자 등록증', class: this.getStageClass(0) },
  //         { name: '', class: 'n' },
  //       ]
  //     case USER_STATUS.USER_TYPE_DRIVER:
  //       return [
  //         { name: '사업자 등록증', class: this.getStageClass(0) },
  //         { name: '차량 등록증', class: this.getStageClass(1) },
  //         { name: '화물 운송 자격증', class: this.getStageClass(2) },
  //         {
  //           name: `추가 서류(임대계약서/가입동의서)`,
  //           class: this.getStageClass(3),
  //         },
  //       ]
  //     default:
  //       return []
  //   }
  // }

  get documentImage() {
    return this.auditItems[this.step]?.url || ''
  }

  get beDocumentType() {
    return this.auditItems[this.step]?.type === 'application/pdf'
  }

  get auditItems() {
    return this.reviewType === 'userReview'
      ? this.reviewInfo?.userType === USER_STATUS.USER_TYPE_DRIVER
        ? [
            {
              text: USER_STATUS.DOCUMENT_TYPE_STRING_BUSINESS_REGISTRATION,
              value: USER_STATUS.DOCUMENT_TYPE_BUSINESS_REGISTRATION,
              url: this.imageURL.getBusinessRegImgUrl || '',
              type: this.imageURL.getBusinessRegImgType || '',
              audit: this.userInfo?.businessStatus || '',
              reason: '',
            },
            {
              text: USER_STATUS.DOCUMENT_TYPE_STRING_VEHICLE_REGISTRATION,
              value: USER_STATUS.DOCUMENT_TYPE_VEHICLE_REGISTRATION,
              url: this.imageURL.getVehicleRegImgUrl || '',
              type: this.imageURL.getVehicleRegImgType || '',
              audit: this.userInfo?.vehicleStatus || '',
              reason: '',
            },
            {
              text: USER_STATUS.DOCUMENT_TYPE_STRING_TRANSPORT_LICENCE,
              value: USER_STATUS.DOCUMENT_TYPE_TRANSPORT_LICENCE,
              url: this.imageURL.getTransportLicenseImgUrl || '',
              type: this.imageURL.getTransportLicenseImgType || '',
              audit: this.userInfo?.transportStatus || '',
              reason: '',
            },
          ]
        : this.reviewInfo?.userType === USER_STATUS.USER_TYPE_BROKER
        ? [
            {
              text: USER_STATUS.DOCUMENT_TYPE_STRING_BUSINESS_REGISTRATION,
              value: USER_STATUS.DOCUMENT_TYPE_BUSINESS_REGISTRATION,
              url: this.imageURL.getBusinessRegImgUrl || '',
              type: this.imageURL.getBusinessRegImgType || '',
              audit: this.userInfo?.businessStatus || '',
              reason: '',
            },
            {
              text: USER_STATUS.DOCUMENT_TYPE_STRING_BROKER_PERMIT,
              value: USER_STATUS.DOCUMENT_TYPE_BROKER_PERMIT,
              url: this.imageURL.getBrokerPermitImgUrl || '',
              type: this.imageURL.getBrokerPermitImgType || '',
              audit: this.userInfo?.brokerPermitStatus || '',
              reason: '',
            },
          ]
        : this.reviewInfo?.userType === USER_STATUS.USER_TYPE_CALLER
        ? [
            {
              text: USER_STATUS.DOCUMENT_TYPE_STRING_BUSINESS_REGISTRATION,
              value: USER_STATUS.DOCUMENT_TYPE_BUSINESS_REGISTRATION,
              url: this.imageURL.getBusinessRegImgUrl || '',
              type: this.imageURL.getBusinessRegImgType || '',
              audit: this.userInfo?.businessStatus,
              reason: '',
            },
          ]
        : []
      : this.reviewInfo?.userType === USER_STATUS.USER_TYPE_DRIVER
      ? [
          {
            text: USER_STATUS.DOCUMENT_TYPE_STRING_VEHICLE_REGISTRATION,
            value: USER_STATUS.DOCUMENT_TYPE_VEHICLE_REGISTRATION,
            url: this.imageURL.getVehicleRegImgUrl || '',
            type: this.imageURL.getVehicleRegImgType || '',
            audit: this.userInfo?.vehicleStatus || '',
            reason: '',
          },
        ]
      : this.reviewInfo?.userType === USER_STATUS.USER_TYPE_BROKER
      ? [
          {
            text: USER_STATUS.DOCUMENT_TYPE_STRING_BROKER_PERMIT,
            value: USER_STATUS.DOCUMENT_TYPE_BROKER_PERMIT,
            url: this.imageURL.getBrokerPermitImgUrl || '',
            type: this.imageURL.getBrokerPermitImgType || '',
            audit: this.userInfo?.brokerPermitStatus || '',
            reason: '',
          },
        ]
      : []
  }

  get reasonItems() {
    return [
      {
        text: '사진 불명확',
        value: '사진이 잘 보이지 않습니다.',
        show: true,
      },
      {
        text: '내용 불일치',
        value:
          this.auditItems[this.step]?.value ===
          USER_STATUS.DOCUMENT_TYPE_BUSINESS_REGISTRATION
            ? `입력하신 정보와 사업자등록증의 내용이 일치하지 않습니다.`
            : this.auditItems[this.step]?.value ===
              USER_STATUS.DOCUMENT_TYPE_BROKER_PERMIT
            ? `입력하신 정보와 주선허가증의 내용이 일치하지 않습니다.`
            : this.auditItems[this.step]?.value ===
              USER_STATUS.DOCUMENT_TYPE_VEHICLE_REGISTRATION
            ? `입력하신 정보와 차량등록증의 내용이 일치하지 않습니다.`
            : '',
        show:
          this.auditItems[this.step]?.value ===
            USER_STATUS.DOCUMENT_TYPE_BUSINESS_REGISTRATION ||
          this.auditItems[this.step]?.value ===
            USER_STATUS.DOCUMENT_TYPE_BROKER_PERMIT ||
          this.auditItems[this.step]?.value ===
            USER_STATUS.DOCUMENT_TYPE_VEHICLE_REGISTRATION,
      },
      {
        text: '명의 불일치',
        value: '명의 불일치',
        show:
          this.reviewInfo?.userType === USER_STATUS.USER_TYPE_DRIVER &&
          (this.auditItems[this.step]?.value ===
            USER_STATUS.DOCUMENT_TYPE_BUSINESS_REGISTRATION ||
            this.auditItems[this.step]?.value ===
              USER_STATUS.DOCUMENT_TYPE_VEHICLE_REGISTRATION),
      },
      // {
      //   text: '정보 불일치',
      //   value: '정보 불일치',
      //   show:
      //     this.isDriver &&
      //     this.auditItems[this.step]?.value ===
      //     USER_STATUS.DOCUMENT_TYPE_VEHICLE_REGISTRATION,
      // },
    ]
  }

  get showReasonItems() {
    return this.reasonItems.filter((item) => item.show)
  }

  get showReason() {
    return this.audit === false
  }

  userInfo: any = null
  userRejectInfo: any = null
  isUserSendReject: boolean = false

  get approvalDone() {
    return (
      this.userInfo?.approvalStatus ===
        USER_STATUS.APPROVAL_STATUS_STRING_APPROVAL ||
      this.userInfo?.approvalStatus ===
        USER_STATUS.APPROVAL_STATUS_STRING_CHANGEAPPROVAL
    )
  }

  get info() {
    return this.tab === 0
      ? this.personalInfo
      : this.tab === 1
      ? this.businessInfo.filter((item) => item.show)
      : this.tab === 2
      ? this.vehicleInfo.filter((item) => item.show)
      : this.addDocInfo.filter((item) => item.show)
  }

  get personalInfo() {
    return [
      {
        title: '개인 정보',
        data: [
          {
            title: '회원명',
            content: this.userInfo?.name || '-',
            show: true,
          },
          {
            title: '전화번호',
            content: formatPhoneNumber(this.userInfo?.mobile),
            show: true,
          },
          {
            title: '생년월일',
            content: formatDate(this.userInfo?.birthDt, 'YYYY.MM.DD'),
            show: true,
          },
        ],
        show: true,
      },
    ]
  }

  get businessInfo() {
    return [
      {
        title: '사업자 정보',
        data: [
          {
            title: '사업자 등록번호',
            content: this.getCompanyBusinessRegNum,
            show: true,
          },
          {
            title: '상호명',
            content: this.getCompanyName,
            show: true,
          },
          {
            title: '대표자명',
            content: this.getCompanyRepresentative,
            show: true,
          },
          {
            title: '사업장 주소',
            content: this.getCompanyAddress,
            show: true,
          },
          {
            title: '상세주소',
            content: this.getCompanyAddressDetail,
            show: true,
          },
          {
            title: '회계 담당자명',
            content: this.userInfo?.brokerCompanyRepresentative || '-',
            show: this.reviewInfo?.userType === USER_STATUS.USER_TYPE_BROKER,
          },
          {
            title: '이메일',
            content: this.getCompanyEmail,
            show:
              this.reviewInfo?.userType === USER_STATUS.USER_TYPE_BROKER ||
              this.reviewInfo?.userType === USER_STATUS.USER_TYPE_DRIVER,
          },
          {
            title: '배차 수신 번호',
            content: formatPhoneNumberIntegrated(this.userInfo?.telephone),
            show: this.reviewInfo?.userType === USER_STATUS.USER_TYPE_BROKER,
          },
        ],
        show: true,
      },
      {
        title: '계좌 정보',
        data: [
          {
            title: '은행명',
            content: this.userInfo?.bankName || '-',
            show: true,
          },
          {
            title: '예금주명',
            content: this.userInfo?.accountName || '-',
            show: true,
          },
          {
            title: '계좌 번호',
            content: this.userInfo?.accountNumber || '-',
            show: true,
          },
        ],
        show:
          this.reviewInfo?.userType === USER_STATUS.USER_TYPE_BROKER ||
          this.reviewInfo?.userType === USER_STATUS.USER_TYPE_DRIVER,
      },
    ]
  }

  get vehicleInfo() {
    return [
      {
        title: '차량 정보',
        data: [
          {
            title: '차량 번호',
            content: this.userInfo?.registrationNumber || '-',
            show: true,
          },
          {
            title: '차량 톤수',
            content: this.userInfo?.weight ? `${this.userInfo?.weight}톤` : '-',
            show: true,
          },
          {
            title: '차량 종류',
            content: this.userInfo?.vehicleType || '-',
            show: true,
          },
          {
            title: '차량 옵션',
            content: this.userInfo?.vehicleOption || '-',
            show: true,
          },
          // {
          //   title: '적재 중량',
          //   content: this.userInfo?.maxPayload
          //     ? `${this.userInfo?.maxPayload}t`
          //     : '-',
          //   show: true,
          // },
          {
            title: '적재 높이',
            content: this.userInfo?.loadingHeight
              ? `${this.userInfo?.loadingHeight}m`
              : '-',
            show: true,
          },
          {
            title: '총 중량',
            content: this.userInfo?.totalWeight
              ? `${this.userInfo?.totalWeight}t`
              : '-',
            show: true,
          },
        ],
        show: this.reviewInfo?.userType === USER_STATUS.USER_TYPE_DRIVER,
      },
    ]
  }

  get addDocInfo() {
    return [
      {
        title: '추가 서류',
        show: this.reviewInfo?.userType === USER_STATUS.USER_TYPE_DRIVER,
      },
    ]
  }

  get getCompanyBusinessRegNum() {
    if (this.reviewInfo?.userType === USER_STATUS.USER_TYPE_CALLER) {
      return formatBizno(this.userInfo?.callerCompanyBusinessRegNum)
    } else if (this.reviewInfo?.userType === USER_STATUS.USER_TYPE_DRIVER) {
      return formatBizno(this.userInfo?.driverCompanyBusinessRegNum)
    } else {
      return formatBizno(this.userInfo?.brokerCompanyBusinessRegNum)
    }
  }

  get getCompanyName() {
    if (this.reviewInfo?.userType === USER_STATUS.USER_TYPE_CALLER) {
      return this.userInfo?.callerCompanyName || '-'
    } else if (this.reviewInfo?.userType === USER_STATUS.USER_TYPE_DRIVER) {
      return this.userInfo?.driverCompanyName || '-'
    } else {
      return this.userInfo?.brokerCompanyName || '-'
    }
  }

  get getCompanyRepresentative() {
    if (this.reviewInfo?.userType === USER_STATUS.USER_TYPE_CALLER) {
      return this.userInfo?.callerCompanyRepresentative || '-'
    } else if (this.reviewInfo?.userType === USER_STATUS.USER_TYPE_DRIVER) {
      return this.userInfo?.driverCompanyRepresentative || '-'
    } else {
      return this.userInfo?.brokerCompanyRepresentative || '-'
    }
  }

  get getCompanyAddress() {
    if (this.reviewInfo?.userType === USER_STATUS.USER_TYPE_CALLER) {
      return this.userInfo?.callerCompanyAddress || '-'
    } else if (this.reviewInfo?.userType === USER_STATUS.USER_TYPE_DRIVER) {
      return this.userInfo?.driverCompanyAddress || '-'
    } else {
      return this.userInfo?.brokerCompanyAddress || '-'
    }
  }

  get getCompanyAddressDetail() {
    if (this.reviewInfo?.userType === USER_STATUS.USER_TYPE_CALLER) {
      return this.userInfo?.callerCompanyAddressDetail || '-'
    } else if (this.reviewInfo?.userType === USER_STATUS.USER_TYPE_DRIVER) {
      return this.userInfo?.driverCompanyAddressDetail || '-'
    } else {
      return this.userInfo?.brokerCompanyAddressDetail || '-'
    }
  }

  get getCompanyEmail() {
    if (this.reviewInfo?.userType === USER_STATUS.USER_TYPE_CALLER) {
      return this.userInfo?.callerCompanyEmail || '-'
    } else if (this.reviewInfo?.userType === USER_STATUS.USER_TYPE_DRIVER) {
      return this.userInfo?.driverCompanyEmail || '-'
    } else {
      return this.userInfo?.brokerCompanyEmail || '-'
    }
  }

  async fetchUserInfo() {
    try {
      if (!this.reviewInfo || !this.type) return
      this.userInfo = null
      const fetchMethod =
        this.reviewInfo?.userType === USER_STATUS.USER_TYPE_CALLER
          ? this.getCallerInfo
          : this.reviewInfo?.userType === USER_STATUS.USER_TYPE_DRIVER
          ? this.getDriverInfo
          : this.getBrokerInfo

      const data = await fetchMethod()
      this.userInfo = data

      if (this.reviewInfo.userType === USER_STATUS.USER_TYPE_CALLER) {
        await this.getUserImgUrl(1)
      } else if (this.reviewInfo.userType === USER_STATUS.USER_TYPE_DRIVER) {
        if (this.reviewType === 'userReview') {

          if (this.userInfo.additionalImgUrl) {
            const result = _.split(this.userInfo.additionalImgUrl, ',')
            this.paramsArray = _.range(1, result.length + 1)
            console.log(this.paramsArray)
          } else {
            this.paramsArray = []
          }

          await this.getUserImgUrl(1)
          await this.getUserImgUrl(3)
        }
        await this.getUserImgUrl(4)
      } else {
        if (this.reviewType === 'userReview') await this.getUserImgUrl(1)
        await this.getUserImgUrl(2)
      }
      this.auditItems

      //심사 거절 일 경우 사유를 찾아서 가지고 와야함.
      if (
        this.userInfo.approvalStatus ===
        USER_STATUS.APPROVAL_STATUS_STRING_DENIED
      ) {
        const params: any = {
          userId: this.userInfo.userId,
        }
        this.userRejectInfo = await AdminService.getRejectUserIdReview(params)
        _.forEach(this.userRejectInfo, (item) => {
          const element: any = _.find(this.auditItems, {
            value: USER_STATUS.GET_DOCUMENT_TYPE_NUMBER(item.rejectType),
          })
          element.reason = item.content
        })

        if (this.auditItems[this.step].reason.length)
          this.reason = this.auditItems[this.step].reason
      }

      if (
        _.includes(
          USER_STATUS.USER_APPROVAL_STATUS_LIST(),
          this.auditItems[this.step].audit,
        )
      ) {
        this.audit = true
      } else if (
        this.auditItems[this.step].audit ===
        USER_STATUS.APPROVAL_STATUS_STRING_DENIED
      ) {
        this.audit = false
      }
    } catch (error: any) {
      this.$alert(error.response.data.errMsg)
    }
  }

  async getCallerInfo() {
    return CallerService.getCallerUserInfo(this.id)
  }

  async getDriverInfo() {
    return DriverService.getDriverUserInfo(this.id)
  }

  async getBrokerInfo() {
    return BrokerService.getBrokerUserInfo(this.id)
  }

  get isLastStep() {
    return this.step === this.auditItems.length - 1
  }

  get userType() {
    if (this.reviewInfo?.userType === USER_STATUS.USER_TYPE_CALLER)
      return USER_STATUS.USER_TYPE_CALLER
    else if (this.reviewInfo?.userType === USER_STATUS.USER_TYPE_DRIVER)
      return USER_STATUS.USER_TYPE_DRIVER
    else if (this.reviewInfo?.userType === USER_STATUS.USER_TYPE_BROKER)
      return USER_STATUS.USER_TYPE_BROKER
  }

  public additionalType = 1

  public imageURL: any = {
    getBusinessRegImgUrl: null,
    getBusinessRegImgType: '',
    getBrokerPermitImgUrl: null,
    getBrokerPermitImgType: '',
    getTransportLicenseImgUrl: null,
    getTransportLicenseImgType: '',
    getVehicleRegImgUrl: null,
    getVehicleRegImgType: '',
    additionalImgUrl: [],
    additionalImgUrlData: [],
  }

  async getUserImgUrl(fileType) {
    try {
        if (fileType === 5) {
          const paramsArray = [1,2,3,4,5]
          for await  ( const param of paramsArray){
            const params = {
              userId: this.userInfo.userId,
              userType: this.userType,
              fileType: fileType,
              additionalType : param,
              date : new Date(),
            }
              const result = await userService.getUserFile(params)
              this.imageURL.additionalImgUrl.push(URL.createObjectURL(result))
          }
        } else {
          const params = {
            userId: this.userInfo.userId,
            userType: this.userType,
            fileType: fileType,
            date : new Date(),
          }
          const result = await userService.getUserFile(params)
          switch (fileType) {
            case 1:
              this.imageURL.getBusinessRegImgUrl = URL.createObjectURL(result)
              this.imageURL.getBusinessRegImgType = result.type
              break
            case 2:
              this.imageURL.getBrokerPermitImgUrl = URL.createObjectURL(result)
              this.imageURL.getBrokerPermitImgType = result.type
              break
            case 3:
              this.imageURL.getTransportLicenseImgUrl =
                URL.createObjectURL(result)
              this.imageURL.getTransportLicenseImgType = result.type
              break
            case 4:
              this.imageURL.getVehicleRegImgUrl = URL.createObjectURL(result)
              this.imageURL.getVehicleRegImgType = result.type
              break
          }
        }
      console.log(this.imageURL)
    } catch (e) {
      console.log(e)
    }
  }

  paramsArray: any = []
  isDownloading: boolean = false

  async additionalDoc() {
    if (!this.isDownloading) {
      try {
        this.isDownloading = true
        const fileType = 5
        this.imageURL.additionalImgUrl = []
        this.imageURL.additionalImgUrlData = []
        for await (const param of this.paramsArray) {
          const params = {
            userId: this.userInfo.userId,
            userType: this.userType,
            fileType: fileType,
            additionalType: param,
            date: new Date(),
          }
          const result = await userService.getUserFile(params)
          const fileData = {
            imageUrl : '',
            imageType : '',
          }
          fileData.imageUrl = URL.createObjectURL(result)
          fileData.imageType = result.type
          this.imageURL.additionalImgUrlData.push(fileData)
          this.imageURL.additionalImgUrl.push(URL.createObjectURL(result))
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.isDownloading = false
      }
    }
  }

  get getBusinessRegImgUrl() {
    return (
      axiosApiUrl +
      `/user/file?userId=${this.userInfo.userId}&userType=${this.userType}&fileType=1`
    )
  }

  get getBrokerPermitImgUrl() {
    return (
      axiosApiUrl +
      `/user/file?userId=${this.userInfo.userId}&userType=${this.userType}&fileType=2`
    )
  }

  get getTransportLicenseImgUrl() {
    return (
      axiosApiUrl +
      `/user/file?userId=${this.userInfo.userId}&userType=${this.userType}&fileType=3`
    )
  }

  get getVehicleRegImgUrl() {
    console.log(
      axiosApiUrl +
        `/user/file?userId=${this.userInfo.userId}&userType=${this.userType}&fileType=4`,
    )
    return (
      axiosApiUrl +
      `/user/file?userId=${this.userInfo.userId}&userType=${this.userType}&fileType=4`
    )
  }

  btnDisabled = false
  async onClickNext() {

    this.btnDisabled = true
    this.currentStage++

    console.log(this.userInfo)
    console.log(this.audit)
    console.log(this.userRejectInfo)
    console.log(this.auditItems)

    if (!this.approvalDone) {
      if (this.audit) {
        this.auditItems[this.step].audit =
          USER_STATUS.APPROVAL_STATUS_STRING_APPROVAL
      } else {
        this.auditItems[this.step].audit =
          USER_STATUS.APPROVAL_STATUS_STRING_DENIED
        this.auditItems[this.step].reason = this.reason
      }

      if (this.audit) {
        //brokerPermitStatus : 주선 허가증
        //businessStatus : 사업자 등록증
        //transportStatus : 화물 운송 자격증
        //vehicleStatus : 차량 등록증
        //심사 종류 (1: 사업자등록증, 2:주선허가증, 3:화물운송자격증, 4:차량등록증)
        if (this.auditItems[this.step]?.value === 1) {
          if (
            this.userInfo.businessStatus !==
            USER_STATUS.APPROVAL_STATUS_STRING_APPROVAL
          ) {
            this.isUserSendReject = true
            await this.approveUserReview()
          }
        }

        if (this.auditItems[this.step]?.value === 2) {
          if (
            this.userInfo.brokerPermitStatus !==
            USER_STATUS.APPROVAL_STATUS_STRING_APPROVAL
          ) {
            this.isUserSendReject = true
            await this.approveUserReview()
          }
        }

        if (this.auditItems[this.step]?.value === 3) {
          if (
            this.userInfo.transportStatus !==
            USER_STATUS.APPROVAL_STATUS_STRING_APPROVAL
          ) {
            this.isUserSendReject = true
            await this.approveUserReview()
          }
        }
        if (this.auditItems[this.step]?.value === 4) {
          if (
            this.userInfo.vehicleStatus !==
            USER_STATUS.APPROVAL_STATUS_STRING_APPROVAL
          ) {
            this.isUserSendReject = true
            await this.approveUserReview()
          }
        }
      } else {
        // 반려 상태에서 이전 반력 상태와 비교하여 같은 값일 경우 회원 가입 심사 거절 API 호출 안하게 수정함.
        const findReason = _.find(this.userRejectInfo, {
          rejectType: USER_STATUS.GET_REJECT_TYPE_STRING(
            this.auditItems[this.step].value,
          ),
        })
        if (
          _.isNil(findReason) ||
          !_.isEqual(findReason.content, this.auditItems[this.step].reason)
        ) {
          this.isUserSendReject = true
          await this.rejectUserReview()
        }
      }

      if (this.isLastStep) {
        //변경 사항이 발생 되었을때만 사용하게 함.
        if (this.isUserSendReject) {
          const audit = this.auditItems.every(
            (item) =>
              item.audit === USER_STATUS.APPROVAL_STATUS_STRING_APPROVAL,
          )

          const type = audit ? 'success' : 'error'

          const content = audit
            ? '심사가 승인 되었습니다.'
            : '심사가 반려 되었습니다.'

          await this.$toast({ type, content })

          this.sendReviewResult(audit)
        }

        const name =
          this.reviewType === 'changeDocument' ? 'memberDoc' : 'memberReg'

        this.btnDisabled = false

        return this.$router.push({ name })
      }
    }

    this.step++
    if (
      _.includes(
        USER_STATUS.USER_APPROVAL_STATUS_LIST(),
        this.auditItems[this.step]?.audit,
      )
    ) {
      this.audit = true
    } else if (
      this.auditItems[this.step]?.audit ===
      USER_STATUS.APPROVAL_STATUS_STRING_DENIED
    ) {
      this.audit = false
      this.reason = this.auditItems[this.step]?.reason
    } else {
      this.audit = null
    }

    if (this.auditItems[this.step].reason.length)
      this.reason = this.auditItems[this.step].reason
    else this.reason = ''

    this.btnDisabled = false
  }

  onClickPrev() {
    this.btnDisabled = false
    this.currentStage--

    if (this.audit) {
      this.auditItems[this.step].audit =
        USER_STATUS.APPROVAL_STATUS_STRING_APPROVAL
    } else {
      this.auditItems[this.step].audit =
        USER_STATUS.APPROVAL_STATUS_STRING_DENIED
      this.auditItems[this.step].reason = this.reason
    }

    this.step--
    if (
      _.includes(
        USER_STATUS.USER_APPROVAL_STATUS_LIST(),
        this.auditItems[this.step]?.audit,
      )
    ) {
      this.audit = true
    } else if (
      this.auditItems[this.step]?.audit ===
      USER_STATUS.APPROVAL_STATUS_STRING_DENIED
    ) {
      this.audit = false
      this.reason = this.auditItems[this.step]?.reason
    } else {
      this.audit = null
    }

    if (this.auditItems[this.step].reason.length)
      this.reason = this.auditItems[this.step].reason
    else this.reason = ''
  }

  async approveUserReview() {
    try {
      const params = {
        userId: this.id,
        userRoleType: this.reviewInfo?.userType,
        confirmItemType: this.auditItems[this.step]?.value,
        // vehicleId: 2, // 차주 추가차량등록증 심사에서만 필수, 그외 선택
      }

      return AdminService.approveUserReview(params)
    } catch (error: any) {
      this.$alert(error.response.data.errMsg)
    }
  }

  async rejectUserReview() {
    try {
      const params = {
        userId: this.id,
        userType: this.reviewInfo?.userType,
        rejectType: this.auditItems[this.step]?.value,
        content: this.auditItems[this.step]?.reason,
        // vehicleId: 2, // 차주 추가차량등록증 심사에서만 필수, 그외 선택
      }

      return AdminService.rejectUserReview(params)
    } catch (error: any) {
      this.$alert(error.response.data.errMsg)
    }
  }

  sendReviewResult(approve) {
    if (approve) {
      console.log(
        'SMS 전송 : [화물잇고] {이름}님, 회원가입 심사가 승인되었습니다.',
      )
    } else {
      console.log(
        'SMS 전송 : [화물잇고] {이름}님, 회원가입 심사가 반려되었습니다. 반려사유를 확인해주세요.',
      )
    }
  }

  isPdfView = false
  isAddDocument = false
  AddDocumentUrl= ''
  popupDocument() {
    this.isAddDocument = false
    this.isPdfView = true
  }

  closePdfView(){
    this.isPdfView = false
  }

  addPopupDocument(index){
    console.log(index)
    this.isAddDocument = true
    this.isPdfView = true
    this.AddDocumentUrl = this.imageURL.additionalImgUrlData[index].imageUrl
  }
  isPdfDocumentType(data) {
    return data.imageType === 'application/pdf';
  }
}
