var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "flex-start-contents" },
      [
        _c(
          "div",
          {
            staticClass: "font-size14 surface500 my-1",
            staticStyle: { width: "70%" },
          },
          [_c("div", [_vm._v(" " + _vm._s(_vm.title) + " ")])]
        ),
        _c(
          "div",
          {
            staticClass: "font-size22 font-weight900 my-1",
            staticStyle: { width: "30%" },
          },
          [
            _c("div", { staticClass: "flex-end-contents mr-10" }, [
              _c("span", { staticClass: "primary-text" }, [
                _vm._v(_vm._s(_vm.content)),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "font-size13" }, [_vm._v(" 건")]),
            ]),
          ]
        ),
        _vm._t("default"),
      ],
      2
    ),
    _vm.title !== "화물 배차 취소"
      ? _c("div", [_c("v-divider", { staticClass: "my-2" })], 1)
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }