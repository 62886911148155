var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: _vm.width,
        height: _vm.height,
        viewBox: _vm.viewBox,
      },
    },
    [
      _c(
        "g",
        {
          attrs: {
            id: "그룹_40425",
            "data-name": "그룹 40425",
            transform: "translate(-48 -289)",
          },
        },
        [
          _c("rect", {
            attrs: {
              id: "사각형_31188",
              "data-name": "사각형 31188",
              width: "28",
              height: "28",
              transform: "translate(48 289)",
              fill: "none",
            },
          }),
          _c(
            "g",
            { attrs: { id: "note", transform: "translate(51.438 292.438)" } },
            [
              _c("path", {
                attrs: {
                  id: "패스_29332",
                  "data-name": "패스 29332",
                  d: "M10.526,17.146H6V16h4.526Z",
                  transform: "translate(-2.562 -1.103)",
                  fill: _vm.iconColor,
                  stroke: _vm.iconColor,
                  "stroke-width": "0.5",
                  "fill-rule": "evenodd",
                },
              }),
              _c("path", {
                attrs: {
                  id: "패스_29333",
                  "data-name": "패스 29333",
                  d: "M11.729,13H6v1.146h5.729V13Z",
                  transform: "translate(-2.562 -1.541)",
                  fill: _vm.iconColor,
                  stroke: _vm.iconColor,
                  "stroke-width": "0.5",
                },
              }),
              _c("path", {
                attrs: {
                  id: "패스_29334",
                  "data-name": "패스 29334",
                  d: "M14.747,10.081h0V10H6v1.146h8.747Z",
                  transform: "translate(-2.562 -1.979)",
                  fill: _vm.iconColor,
                  stroke: _vm.iconColor,
                  "stroke-width": "0.5",
                },
              }),
              _c("path", {
                attrs: {
                  id: "패스_29335",
                  "data-name": "패스 29335",
                  d: "M16.751,3l4.584,4.584V9.4a2.281,2.281,0,0,0-1.064.6l-.081.081V8.157H17.324a1.146,1.146,0,0,1-1.146-1.146V4.146H4.146V22.48H20.188V14.958l1.146-1.146V22.48a1.146,1.146,0,0,1-1.146,1.146H4.146A1.146,1.146,0,0,1,3,22.48V4.146A1.146,1.146,0,0,1,4.146,3Zm.573,2.193,1.817,1.817H17.324Z",
                  transform: "translate(-3 -3)",
                  fill: _vm.iconColor,
                  stroke: _vm.iconColor,
                  "stroke-width": "0.5",
                  "fill-rule": "evenodd",
                },
              }),
              _c("path", {
                attrs: {
                  id: "패스_29336",
                  "data-name": "패스 29336",
                  d: "M19.353,9.125a1.719,1.719,0,0,1,2.431,2.431L14.9,18.443l-2.836.405.405-2.836Zm-5,8.238-.945.135.135-.945,5.4-5.4.81.81Zm6.212-6.212-.81-.81.405-.405a.573.573,0,0,1,.81.81Z",
                  transform: "translate(-1.678 -2.18)",
                  fill: _vm.iconColor,
                  stroke: _vm.iconColor,
                  "stroke-width": "0.5",
                  "fill-rule": "evenodd",
                },
              }),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }