var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "vertical-nav-header d-flex align-center justify-space-between ps-5 pe-5 pt-5 pb-2",
      staticStyle: { "min-height": "120px" },
    },
    [
      _c(
        "router-link",
        {
          staticClass: "d-flex align-center text-decoration-none",
          attrs: { to: "/" },
        },
        [
          _c("v-img", {
            staticClass: "app-logo me-3",
            attrs: {
              src: _vm.logoChange,
              "max-width": "110px",
              alt: "logo",
              contain: "",
              eager: "",
            },
          }),
          _c("v-slide-x-transition", [
            _c("h2", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !(_vm.menuIsVerticalNavMini && !_vm.isMouseHovered),
                  expression: "!(menuIsVerticalNavMini && !isMouseHovered)",
                },
              ],
              staticClass: "app-title",
            }),
          ]),
        ],
        1
      ),
      _c(
        "v-slide-x-transition",
        [
          _vm.$vuetify.breakpoint.lgAndUp
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !(
                        _vm.menuIsVerticalNavMini && !_vm.isMouseHovered
                      ),
                      expression: "!(menuIsVerticalNavMini && !isMouseHovered)",
                    },
                  ],
                  staticClass: "d-flex align-center ms-1",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      _vm.menuIsVerticalNavMini = !_vm.menuIsVerticalNavMini
                    },
                  },
                },
                [
                  _c(
                    "v-icon",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.menuIsVerticalNavMini,
                          expression: "!menuIsVerticalNavMini",
                        },
                      ],
                      staticClass: "cursor-pointer",
                      staticStyle: { color: "#e7e3fc" },
                      attrs: { size: "20" },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.icons.mdiRecordCircleOutline) + " "
                      ),
                    ]
                  ),
                  _c(
                    "v-icon",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.menuIsVerticalNavMini,
                          expression: "menuIsVerticalNavMini",
                        },
                      ],
                      staticClass: "cursor-pointer",
                      staticStyle: { color: "#e7e3fc" },
                      attrs: { size: "20" },
                    },
                    [_vm._v(" " + _vm._s(_vm.icons.mdiRadioboxBlank) + " ")]
                  ),
                ],
                1
              )
            : _c(
                "v-icon",
                {
                  staticClass: "d-inline-block",
                  staticStyle: { color: "#e7e3fc" },
                  attrs: { size: "20" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.$emit("close-nav-menu")
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.icons.mdiClose) + " ")]
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }