var logi = logi ?? {};
logi['maps'] = logi['maps'] ?? {};
import a4_0x26882a from './logi-maps-defines.js';
logi['maps']['Defines'] = a4_0x26882a, logi['maps']['Database'] = class {
    #indexedDBName = 'logimaps';
    #indexedDBVersion = 0x2;
    #storeName = '';
    #storePngName = 'tiledmap-png';
    #storeSvgName = 'tiledmap-svg';
    #storeLegacyName = 'tiledmap';
    #indexName = '';
    #indexPngName = 'index_tiledmap-png';
    #indexSvgName = 'index_tiledmap-svg';
    #mapVersionKey = '_VERSION_';
    #MAX_DATABASE_SIZE = 0x1400;
    #CHECK_DATABASE_SIZE_TICKTIME = 0xfa0;
    #lastCheckDatabaseSizeTickTime = 0x0;
    #logiMapDatabase = null;
    #mapTileDatas = new Map();
    #MAX_MAP_TILE_DATA_SIZE = 0x40;
    #MAX_DELAY_PUT_CNT = 0x8;
    #MIN_DELAY_PUT_TICKTIME = 0x3e8;
    #MAX_DELAY_PUT_TICKTIME = 0xfa0;
    #lastPutTickTime = 0x0;
    constructor(_0x3cc486) {
        _0x3cc486 == logi['maps']['Defines']['MAP_TILE_DATA_SVG'] || _0x3cc486 == logi['maps']['Defines']['MAP_TILE_DATA_XVG'] ? (this.#storeName = this.#storeSvgName, this.#indexName = this.#indexSvgName) : (this.#storeName = this.#storePngName, this.#indexName = this.#indexPngName);
    }
    ['initDatabase']() {
        return new Promise((_0x444e49, _0x246b1f) => {
            try {
                if (!window['indexedDB'])
                    _0x246b1f();
                else {
                    const _0x33293a = this, _0x4d3c22 = window['indexedDB']['open'](this.#indexedDBName, this.#indexedDBVersion);
                    _0x4d3c22['onupgradeneeded'] = _0x16bbe5 => {
                        const _0x4f4a40 = _0x16bbe5['target']['result'];
                        {
                            if (!_0x4f4a40['objectStoreNames']['contains'](_0x33293a.#storePngName)) {
                                console['log']('[logi.maps]\x20Create\x20logimaps.' + _0x33293a.#storePngName);
                                const _0x1bca69 = _0x4f4a40['createObjectStore'](_0x33293a.#storePngName, { 'keyPath': 'key' });
                                _0x1bca69['createIndex'](_0x33293a.#indexPngName, 'key', { 'unique': !![] });
                            }
                            if (!_0x4f4a40['objectStoreNames']['contains'](_0x33293a.#storeSvgName)) {
                                console['log']('[logi.maps]\x20Create\x20logimaps.' + _0x33293a.#storeSvgName);
                                const _0x230d89 = _0x4f4a40['createObjectStore'](_0x33293a.#storeSvgName, { 'keyPath': 'key' });
                                _0x230d89['createIndex'](_0x33293a.#indexSvgName, 'key', { 'unique': !![] });
                            }
                            _0x4f4a40['objectStoreNames']['contains'](_0x33293a.#storeLegacyName) && (console['log']('[logi.maps]\x20Delete\x20logimaps.' + _0x33293a.#storeLegacyName), _0x4f4a40['deleteObjectStore'](_0x33293a.#storeLegacyName));
                        }
                    }, _0x4d3c22['onsuccess'] = _0x5a16d3 => {
                        _0x33293a.#logiMapDatabase = _0x4d3c22['result'], console['log']('[logi.maps]\x20initDatabase'), _0x444e49();
                    }, _0x4d3c22['onerror'] = _0x2e8144 => {
                        console['log']('[logi.maps]\x20failed\x20to\x20\x20initDatabase:\x20' + _0x2e8144['target']['error']['message']), _0x33293a.#deleteDatabase()['then'](() => {
                            _0x246b1f();
                        });
                    };
                }
            } catch (_0x36d557) {
                console['log']('[logi.maps]\x20indexedDB:' + _0x36d557['message']), this.#deleteDatabase()['then'](() => {
                    _0x246b1f();
                });
            }
        });
    }
    #deleteDatabase() {
        return this.#logiMapDatabase = null, new Promise(_0x331667 => {
            try {
                if (!window['indexedDB'])
                    _0x331667();
                else {
                    const _0x151695 = window['indexedDB']['deleteDatabase'](this.#indexedDBName);
                    _0x151695['onsuccess'] = _0x59aad0 => {
                        console['log']('[logi.maps]\x20IndexedDB\x20deleted\x20successfully'), _0x331667();
                    }, _0x151695['onerror'] = _0x4f8390 => {
                        console['log']('[logi.maps]\x20Error\x20deleting\x20indexedDB:\x20' + _0x4f8390['target']['error']['message']), _0x331667();
                    };
                }
            } catch (_0x4afa47) {
                console['log']('[logi.maps]\x20indexedDB:' + _0x4afa47['message']), _0x331667();
            }
        });
    }
    ['resizeDatabase']() {
        if (this.#logiMapDatabase != null)
            try {
                const _0x2beb5c = new Date()['getTime']();
                if (_0x2beb5c >= this.#lastCheckDatabaseSizeTickTime + this.#CHECK_DATABASE_SIZE_TICKTIME) {
                    this.#lastCheckDatabaseSizeTickTime = _0x2beb5c + this.#CHECK_DATABASE_SIZE_TICKTIME * 0x4;
                    let _0xa410c0 = this.#logiMapDatabase['transaction']([this.#storeName], 'readwrite'), _0x40e795 = _0xa410c0['objectStore'](this.#storeName);
                    const _0x43b76e = _0x40e795['count'](), _0x47d15f = this;
                    _0x43b76e['onsuccess'] = _0x4883cc => {
                        let _0x3cff96 = parseInt(_0x47d15f.#MAX_DATABASE_SIZE * 0.5);
                        if (_0x43b76e['result'] > _0x47d15f.#MAX_DATABASE_SIZE) {
                            const _0x13d76f = _0x40e795['openCursor'](null, 'prev');
                            _0x13d76f['onsuccess'] = _0x2f5d9c => {
                                const _0x2f8508 = _0x2f5d9c['target']['result'];
                                _0x2f8508 && (_0x2f8508['key'] !== _0x47d15f.#mapVersionKey && (_0x2f8508['delete'](), _0x3cff96 -= 0x1), _0x3cff96 >= 0x0 && _0x2f8508['continue']());
                            };
                        }
                        _0x47d15f.#lastCheckDatabaseSizeTickTime = new Date()['getTime']();
                    }, _0x43b76e['onerror'] = _0x160d99 => {
                        console['log']('[logi.maps]\x20failed\x20to\x20resizeDatabase:\x20' + _0x160d99['target']['error']['message']), this.#logiMapDatabase = null;
                    };
                }
            } catch (_0x29b223) {
                console['log']('[logi.maps]\x20indexedDB:' + _0x29b223['message']), this.#deleteDatabase();
            }
    }
    ['checkMapVersion'](_0x341d16) {
        return new Promise(_0x2e0e97 => {
            const _0x34fb20 = this;
            this.#getMapVersion()['then'](_0x33852a => {
                _0x341d16 == _0x33852a ? _0x2e0e97(![]) : _0x34fb20.#clearAll()['then'](() => {
                    _0x34fb20.#putMapVersion(_0x341d16)['then'](() => {
                        _0x2e0e97(!![]);
                    });
                });
            });
        });
    }
    ['addMapTileData'](_0x4cc05f, _0x342ca9) {
        this.#logiMapDatabase != null && (this.#mapTileDatas['size'] < this.#MAX_MAP_TILE_DATA_SIZE && this.#mapTileDatas['set'](_0x4cc05f, _0x342ca9));
    }
    ['putMapTileDatas']() {
        if (this.#logiMapDatabase != null)
            try {
                const _0x1cef8c = new Date()['getTime']();
                if (this.#mapTileDatas['size'] >= this.#MAX_DELAY_PUT_CNT && _0x1cef8c >= this.#lastPutTickTime + this.#MIN_DELAY_PUT_TICKTIME || this.#mapTileDatas['size'] > 0x0 && _0x1cef8c >= this.#lastPutTickTime + this.#MAX_DELAY_PUT_TICKTIME) {
                    this.#lastPutTickTime = _0x1cef8c + this.#MAX_DELAY_PUT_TICKTIME * 0x4;
                    const _0x427a54 = this.#logiMapDatabase['transaction']([this.#storeName], 'readwrite'), _0x571f6e = _0x427a54['objectStore'](this.#storeName), _0xdd6180 = this, _0x15e761 = [];
                    let _0x2f8d95 = this.#MAX_DELAY_PUT_CNT;
                    for (const [_0x2e4a0d, _0x1de247] of this.#mapTileDatas) {
                        if (--_0x2f8d95 < 0x0)
                            break;
                        const _0x280b6f = new Promise((_0x3b595d, _0x3364ff) => {
                            const _0x29dd68 = {
                                    'key': _0x2e4a0d,
                                    'value': _0x1de247
                                }, _0x6166a9 = _0x571f6e['put'](_0x29dd68);
                            _0x6166a9['onsuccess'] = _0x1ae31b => {
                                _0x3b595d();
                            }, _0x6166a9['onerror'] = _0x4e622c => {
                                _0x3364ff();
                            };
                        });
                        _0x15e761['push'](_0x280b6f), _0xdd6180.#mapTileDatas['delete'](_0x2e4a0d);
                    }
                    Promise['all'](_0x15e761)['then'](_0x3d7a4d => {
                        _0xdd6180.#lastPutTickTime = new Date()['getTime']();
                    })['catch'](_0x3ebbac => {
                        console['log']('[logi.maps]\x20failed\x20to\x20putMapTileData'), _0xdd6180.#lastPutTickTime = new Date()['getTime']();
                    });
                }
            } catch (_0x19f53f) {
                console['log']('[logi.maps]\x20indexedDB:' + _0x19f53f['message']), this.#logiMapDatabase = null;
            }
    }
    ['getMapTileTasks'](_0x4664ab) {
        const _0x2dd815 = [];
        if (_0x4664ab?.['length'] > 0x0) {
            if (this.#logiMapDatabase == null)
                for (let _0x3b4a24 of _0x4664ab) {
                    const _0x6756b7 = new Promise((_0x3227c6, _0x3670a8) => {
                        _0x3227c6({
                            'tile': _0x3b4a24,
                            'data': null
                        });
                    });
                    _0x2dd815['push'](_0x6756b7);
                }
            else
                try {
                    const _0x4ca9f0 = this.#logiMapDatabase['transaction']([this.#storeName], 'readonly'), _0x3117fa = _0x4ca9f0['objectStore'](this.#storeName), _0xde1f4b = _0x3117fa['index'](this.#indexName);
                    for (let _0x45b748 of _0x4664ab) {
                        const _0x3f2725 = new Promise((_0x4bac4b, _0x2990bf) => {
                            const _0x1d1f05 = _0xde1f4b['get'](_0x45b748['getId']());
                            _0x1d1f05['onsuccess'] = _0xc3f06d => {
                                _0x4bac4b({
                                    'tile': _0x45b748,
                                    'data': _0xc3f06d['target']['result']?.['value']
                                });
                            }, _0x1d1f05['onerror'] = _0x3fa811 => {
                                _0x4bac4b({
                                    'tile': _0x45b748,
                                    'data': null
                                });
                            };
                        });
                        _0x2dd815['push'](_0x3f2725);
                    }
                } catch (_0x22f3e1) {
                    console['log']('[logi.maps]\x20indexedDB:' + _0x22f3e1['message']), this.#logiMapDatabase = null;
                }
        }
        return _0x2dd815;
    }
    #clearAll() {
        return new Promise(_0x345d28 => {
            if (this.#logiMapDatabase == null)
                _0x345d28();
            else
                try {
                    let _0x3c9e22 = this.#logiMapDatabase['transaction']([this.#storeName], 'readwrite'), _0x2f1f53 = _0x3c9e22['objectStore'](this.#storeName);
                    const _0x14a0ad = _0x2f1f53['clear']();
                    _0x14a0ad['onsuccess'] = _0x4612d4 => {
                        _0x345d28();
                    }, _0x14a0ad['onerror'] = _0x17dd88 => {
                        console['log']('[logi.maps]\x20Error\x20clearAll\x20indexedDB:\x20' + _0x17dd88['target']['error']['message']), this.#logiMapDatabase = null, _0x345d28();
                    };
                } catch (_0xa4daa3) {
                    console['log']('[logi.maps]\x20indexedDB:' + _0xa4daa3['message']), this.#deleteDatabase()['then'](() => {
                        _0x345d28();
                    });
                }
        });
    }
    #putMapVersion(_0x33be21) {
        return new Promise(_0x2efea9 => {
            if (this.#logiMapDatabase == null)
                _0x2efea9();
            else
                try {
                    let _0xe962ef = this.#logiMapDatabase['transaction']([this.#storeName], 'readwrite'), _0x89594d = _0xe962ef['objectStore'](this.#storeName);
                    const _0x14570a = {
                            'key': this.#mapVersionKey,
                            'value': _0x33be21
                        }, _0x17a481 = _0x89594d['put'](_0x14570a);
                    _0x17a481['onsuccess'] = _0x5a44c0 => {
                        _0x2efea9();
                    }, _0x17a481['onerror'] = _0x395c08 => {
                        console['log']('[logi.maps]\x20failed\x20to\x20putMapVersion:\x20' + _0x395c08['target']['error']['message']), this.#logiMapDatabase = null, _0x2efea9();
                    };
                } catch (_0x2a5ca5) {
                    console['log']('[logi.maps]\x20indexedDB:' + _0x2a5ca5['message']), this.#deleteDatabase()['then'](() => {
                        _0x2efea9();
                    });
                }
        });
    }
    #getMapVersion() {
        return new Promise(_0x303361 => {
            if (this.#logiMapDatabase == null)
                _0x303361(null);
            else
                try {
                    const _0x4909dd = this.#logiMapDatabase['transaction']([this.#storeName], 'readonly'), _0x5356c8 = _0x4909dd['objectStore'](this.#storeName), _0x122adf = _0x5356c8['index'](this.#indexName), _0x430b90 = _0x122adf['get'](this.#mapVersionKey);
                    _0x430b90['onsuccess'] = _0x4c7667 => {
                        _0x303361(_0x4c7667['target']['result']?.['value']);
                    }, _0x430b90['onerror'] = _0x43b971 => {
                        console['log']('[logi.maps]\x20failed\x20to\x20getMapVersion:\x20' + _0x43b971['target']['error']['message']), _0x303361(null);
                    };
                } catch (_0x4d6197) {
                    console['log']('[logi.maps]\x20indexedDB:' + _0x4d6197['message']), this.#deleteDatabase()['then'](() => {
                        _0x303361(null);
                    });
                }
        });
    }
};
export default logi['maps']['Database'];