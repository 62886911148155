import { log } from 'console'
import _ from 'lodash'

/**
 * 회원가잆 심사 상태 정의
 */
export = (() => {
  const _APPROVAL_STATUS: any = {
    NONE: '미등록',
    REQUEST: '심사 대기',
    DENIED: '심사 반려',
    REREQUEST: '재요청',
    APPROVAL: '심사 승인',
    CHANGE: '심사 대기',
    CHANGEDENIED: '심사 반려',
    CHANGEAPPROVAL: '심사 승인',
    CHANGEALL: '전체',
  }

  const _APPROVAL_STATUS_STRING: any = {
    NONE: 'NONE',
    REQUEST: 'REQUEST',
    DENIED: 'DENIED',
    REREQUEST: 'REREQUEST',
    APPROVAL: 'APPROVAL',
    CHANGE: 'CHANGE',
    CHANGEDENIED: 'CHANGEDENIED',
    CHANGEAPPROVAL: 'CHANGEAPPROVAL',
  }

  const _USER_REJECT_TYPE: any = {
    BUSINESS: 'BUSINESS',
    BROKERPERMIT: 'BROKERPERMIT',
    TRANSPORT: 'TRANSPORT',
    VEHICLEREG: 'VEHICLE_REG',
  }

  const _USER_STATUS: any = {
    GENERAL: 1,
    CAUTION: 2,
    WARNING: 3,
    SUSPENSION: 4,
    FORCED_WITHDRAWAL: 5,
    WITHDRAWAL: 6,
    DORMANCY: 7,
  }

  const _USER_STATUS_STRING: any = {
    GENERAL: '일반',
    CAUTION: '주의',
    WARNING: '경고',
    SUSPENSION: '정지',
    FORCED_WITHDRAWAL: '강제 탈퇴',
    WITHDRAWAL: '탈퇴',
    DORMANCY: '휴면',
  }

  const _USER_TYPE: any = {
    CALLER: 1,
    DRIVER: 2,
    BROKER: 3,
    MANAGER: 4,
  }

  const _USER_STRING: any = {
    CALLER: '1',
    DRIVER: '2',
    BROKER: '3',
    MANAGER: '4',
  }

  const _USER_TYPE_STRING: any = {
    CALLER: '화주',
    DRIVER: '차주',
    BROKER: '주선사',
    MANAGER: 'Admin',
  }

  const _DOCUMENT_TYPE: any = {
    BUSINESS_REGISTRATION: 1,
    BROKER_PERMIT: 2,
    TRANSPORT_LICENCE: 3,
    VEHICLE_REGISTRATION: 4,
    CARGO_INSURANCE: 5,
    ADDITIONAL_VEHICLE_REGISTRATION: 6,
  }

  const _DOCUMENT_TYPE_STRING: any = {
    BUSINESS_REGISTRATION: '사업자 등록증',
    BROKER_PERMIT: '주선허가증',
    TRANSPORT_LICENCE: '화물 운송 자격증',
    VEHICLE_REGISTRATION: '차량 등록증',
    CARGO_INSURANCE: '화물적재물보험',
    ADDITIONAL_VEHICLE_REGISTRATION: '추가차량등록증',
  }

  Object.freeze(_APPROVAL_STATUS)
  Object.freeze(_APPROVAL_STATUS_STRING)
  Object.freeze(_USER_STATUS)
  Object.freeze(_USER_STATUS_STRING)
  Object.freeze(_USER_TYPE)
  Object.freeze(_USER_TYPE_STRING)
  Object.freeze(_DOCUMENT_TYPE)
  Object.freeze(_DOCUMENT_TYPE_STRING)

  const _USER_DOCUMENT_TYPE_CHANGE_REJECT_TYPE_: Map<number, string> = new Map<
    number,
    string
  >([
    [_DOCUMENT_TYPE.BUSINESS_REGISTRATION, _USER_REJECT_TYPE.BUSINESS],
    [_DOCUMENT_TYPE.BROKER_PERMIT, _USER_REJECT_TYPE.BROKERPERMIT ],
    [_DOCUMENT_TYPE.TRANSPORT_LICENCE, _USER_REJECT_TYPE.TRANSPORT ],
    [_DOCUMENT_TYPE.VEHICLE_REGISTRATION, _USER_REJECT_TYPE.VEHICLEREG ],
  ])

  const _USER_REJECT_TYPE_CHANGE_DOCUMENT_TYPE: Map<string, number> = new Map<
    string,
    number
  >([
    [_USER_REJECT_TYPE.BUSINESS, _DOCUMENT_TYPE.BUSINESS_REGISTRATION],
    [_USER_REJECT_TYPE.BROKERPERMIT, _DOCUMENT_TYPE.BROKER_PERMIT],
    [_USER_REJECT_TYPE.TRANSPORT, _DOCUMENT_TYPE.TRANSPORT_LICENCE],
    [_USER_REJECT_TYPE.VEHICLEREG, _DOCUMENT_TYPE.VEHICLE_REGISTRATION],
  ])

  const _USER_TYPE_WEB_NAME: Map<number, string> = new Map<number, string>([
    [_USER_TYPE.CALLER, _USER_TYPE_STRING.CALLER],
    [_USER_TYPE.DRIVER, _USER_TYPE_STRING.DRIVER],
    [_USER_TYPE.BROKER, _USER_TYPE_STRING.BROKER],
    [_USER_TYPE.MANAGER, _USER_TYPE_STRING.MANAGER],
  ])

  const _USER_STRING_TYPE_WEB_NAME: Map<string, string> = new Map<string, string>([
    [_USER_STRING.CALLER, _USER_TYPE_STRING.CALLER],
    [_USER_STRING.DRIVER, _USER_TYPE_STRING.DRIVER],
    [_USER_STRING.BROKER, _USER_TYPE_STRING.BROKER],
    [_USER_STRING.MANAGER, _USER_TYPE_STRING.MANAGER],
  ])

  const _USER_STATUS_WEB_NAME: Map<number, string> = new Map<number, string>([
    [_USER_STATUS.GENERAL, _USER_STATUS_STRING.GENERAL],
    [_USER_STATUS.CAUTION, _USER_STATUS_STRING.CAUTION],
    [_USER_STATUS.WARNING, _USER_STATUS_STRING.WARNING],
    [_USER_STATUS.SUSPENSION, _USER_STATUS_STRING.SUSPENSION],
    [_USER_STATUS.FORCED_WITHDRAWAL, _USER_STATUS_STRING.FORCED_WITHDRAWAL],
    [_USER_STATUS.WITHDRAWAL, _USER_STATUS_STRING.WITHDRAWAL],
    [_USER_STATUS.DORMANCY, _USER_STATUS_STRING.DORMANCY],
  ])

  const _USER_TYPE_STRING_WEB_NAME: Map<number, string> = new Map<
    number,
    string
  >([
    [_USER_TYPE_STRING.CALLER, _USER_TYPE.CALLER],
    [_USER_TYPE_STRING.DRIVER, _USER_TYPE.DRIVER],
    [_USER_TYPE_STRING.BROKER, _USER_TYPE.BROKER],
  ])

  const _DOCUMENT_TYPE_WEB_NAME: Map<number, string> = new Map<number, string>([
    [
      _DOCUMENT_TYPE.BUSINESS_REGISTRATION,
      _DOCUMENT_TYPE_STRING.BUSINESS_REGISTRATION,
    ],
    [_DOCUMENT_TYPE.BROKER_PERMIT, _DOCUMENT_TYPE_STRING.BROKER_PERMIT],
    [_DOCUMENT_TYPE.TRANSPORT_LICENCE, _DOCUMENT_TYPE_STRING.TRANSPORT_LICENCE],
    [
      _DOCUMENT_TYPE.VEHICLE_REGISTRATION,
      _DOCUMENT_TYPE_STRING.VEHICLE_REGISTRATION,
    ],
    [_DOCUMENT_TYPE.CARGO_INSURANCE, _DOCUMENT_TYPE_STRING.CARGO_INSURANCE],
    [
      _DOCUMENT_TYPE.ADDITIONAL_VEHICLE_REGISTRATION,
      _DOCUMENT_TYPE_STRING.ADDITIONAL_VEHICLE_REGISTRATION,
    ],
  ])

  return Object.freeze({
    APPROVAL_STATUS: _APPROVAL_STATUS,

    APPROVAL_STATUS_NONE: _APPROVAL_STATUS.NONE,
    APPROVAL_STATUS_REQUEST: _APPROVAL_STATUS.REQUEST,
    APPROVAL_STATUS_DENIED: _APPROVAL_STATUS.DENIED,
    APPROVAL_STATUS_REREQUEST: _APPROVAL_STATUS.REREQUEST,
    APPROVAL_STATUS_APPROVAL: _APPROVAL_STATUS.APPROVAL,

    APPROVAL_STATUS_CHANGE: _APPROVAL_STATUS.CHANGE,
    APPROVAL_STATUS_CHANGEDENIED: _APPROVAL_STATUS.CHANGEDENIED,
    APPROVAL_STATUS_CHANGEAPPROVAL: _APPROVAL_STATUS.CHANGEAPPROVAL,
    APPROVAL_STATUS_CHANGEALL: _APPROVAL_STATUS.CHANGEALL,

    USER_TYPE_CALLER: _USER_TYPE.CALLER,
    USER_TYPE_DRIVER: _USER_TYPE.DRIVER,
    USER_TYPE_BROKER: _USER_TYPE.BROKER,
    USER_TYPE_MANAGER: _USER_TYPE.MANAGER,

    USER_TYPE_STRING_CALLER: _USER_TYPE_STRING.CALLER,
    USER_TYPE_STRING_DRIVER: _USER_TYPE_STRING.DRIVER,
    USER_TYPE_STRING_BROKER: _USER_TYPE_STRING.BROKER,
    USER_TYPE_STRING_MANAGER: _USER_TYPE_STRING.MANAGER,

    USER_STATUS_GENERAL: _USER_STATUS.GENERAL,
    USER_STATUS_CAUTION: _USER_STATUS.CAUTION,
    USER_STATUS_WARNING: _USER_STATUS.WARNING,
    USER_STATUS_SUSPENSION: _USER_STATUS.SUSPENSION,
    USER_STATUS_FORCED_WITHDRAWAL: _USER_STATUS.FORCED_WITHDRAWAL,
    USER_STATUS_WITHDRAWAL: _USER_STATUS.WITHDRAWAL,
    USER_STATUS_DORMANCY: _USER_STATUS.DORMANCY,

    USER_STATUS_STRING_GENERAL: _USER_STATUS_STRING.GENERAL,
    USER_STATUS_STRING_CAUTION: _USER_STATUS_STRING.CAUTION,
    USER_STATUS_STRING_WARNING: _USER_STATUS_STRING.WARNING,
    USER_STATUS_STRING_SUSPENSION: _USER_STATUS_STRING.SUSPENSION,
    USER_STATUS_STRING_FORCED_WITHDRAWAL: _USER_STATUS_STRING.FORCED_WITHDRAWAL,
    USER_STATUS_STRING_WITHDRAWAL: _USER_STATUS_STRING.WITHDRAWAL,
    USER_STATUS_STRING_DORMANCY: _USER_STATUS_STRING.DORMANCY,

    DOCUMENT_TYPE_BUSINESS_REGISTRATION: _DOCUMENT_TYPE.BUSINESS_REGISTRATION,
    DOCUMENT_TYPE_BROKER_PERMIT: _DOCUMENT_TYPE.BROKER_PERMIT,
    DOCUMENT_TYPE_TRANSPORT_LICENCE: _DOCUMENT_TYPE.TRANSPORT_LICENCE,
    DOCUMENT_TYPE_VEHICLE_REGISTRATION: _DOCUMENT_TYPE.VEHICLE_REGISTRATION,
    DOCUMENT_TYPE_CARGO_INSURANCE: _DOCUMENT_TYPE.CARGO_INSURANCE,
    DOCUMENT_TYPE_ADDITIONAL_VEHICLE_REGISTRATION:
      _DOCUMENT_TYPE.ADDITIONAL_VEHICLE_REGISTRATION,

    DOCUMENT_TYPE_STRING_BUSINESS_REGISTRATION:
      _DOCUMENT_TYPE_STRING.BUSINESS_REGISTRATION,
    DOCUMENT_TYPE_STRING_BROKER_PERMIT: _DOCUMENT_TYPE_STRING.BROKER_PERMIT,
    DOCUMENT_TYPE_STRING_TRANSPORT_LICENCE:
      _DOCUMENT_TYPE_STRING.TRANSPORT_LICENCE,
    DOCUMENT_TYPE_STRING_VEHICLE_REGISTRATION:
      _DOCUMENT_TYPE_STRING.VEHICLE_REGISTRATION,
    DOCUMENT_TYPE_STRING_CARGO_INSURANCE: _DOCUMENT_TYPE_STRING.CARGO_INSURANCE,
    DOCUMENT_TYPE_STRING_ADDITIONAL_VEHICLE_REGISTRATION:
      _DOCUMENT_TYPE_STRING.ADDITIONAL_VEHICLE_REGISTRATION,

    APPROVAL_STATUS_STRING_NONE: _APPROVAL_STATUS_STRING.NONE,
    APPROVAL_STATUS_STRING_REQUEST: _APPROVAL_STATUS_STRING.REQUEST,
    APPROVAL_STATUS_STRING_DENIED: _APPROVAL_STATUS_STRING.DENIED,
    APPROVAL_STATUS_STRING_REREQUEST: _APPROVAL_STATUS_STRING.REREQUEST,
    APPROVAL_STATUS_STRING_APPROVAL: _APPROVAL_STATUS_STRING.APPROVAL,
    APPROVAL_STATUS_STRING_CHANGE: _APPROVAL_STATUS_STRING.CHANGE,
    APPROVAL_STATUS_STRING_CHANGEDENIED: _APPROVAL_STATUS_STRING.CHANGEDENIED,
    APPROVAL_STATUS_STRING_CHANGEAPPROVAL:
      _APPROVAL_STATUS_STRING.CHANGEAPPROVAL,

    USER_APPROVAL_STATUS_LIST: (): string[] => [
      _APPROVAL_STATUS_STRING.APPROVAL,
      _APPROVAL_STATUS_STRING.CHANGE,
      _APPROVAL_STATUS_STRING.CHANGEDENIED,
      _APPROVAL_STATUS_STRING.CHANGEAPPROVAL,
    ],

    GET_USER_STATUS: (userStatus: number): string => {
      const _userStatus: string | undefined =
        _USER_STATUS_WEB_NAME.get(userStatus)
      return _userStatus ? _userStatus : '-'
    },

    GET_USER_TYPE: (userType: number): string => {
      const _userType: string | undefined = _USER_TYPE_WEB_NAME.get(userType)

      return _userType ? _userType : '-'
    },

    GET_USER_STRING_TYPE: (userType: string): string => {
      const _userType: string | undefined = _USER_STRING_TYPE_WEB_NAME.get(userType)

      return _userType ? _userType : '-'
    },

    GET_DOCUMENT_TYPE_STRING: (type: number): string => {
      const _type: string | undefined = _USER_TYPE_STRING_WEB_NAME.get(type)

      return _type ? _type : '-'
    },

    GET_DOCUMENT_TYPE_NUMBER: (type: string): number => {
      const _type: number | undefined =
        _USER_REJECT_TYPE_CHANGE_DOCUMENT_TYPE.get(type)
      return _type ? _type : 0
    },

    GET_REJECT_TYPE_STRING: (type : number): string => {
      const _type: string | undefined =
        _USER_DOCUMENT_TYPE_CHANGE_REJECT_TYPE_.get(type)
      return _type ? _type : '-'
    },
  })
})()
