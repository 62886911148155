var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content-wrapper content_body" }, [
    _c(
      "div",
      { staticClass: "content-inner" },
      [
        _c(
          "v-row",
          [
            _c(
              "v-col",
              { attrs: { cols: "12", md: "3" } },
              [_c("today-summary")],
              1
            ),
            _c(
              "v-col",
              { attrs: { cols: "12", md: "6" } },
              [_c("week-reg-report")],
              1
            ),
            _c(
              "v-col",
              { attrs: { cols: "12", md: "3" } },
              [_c("broker-driver-reg-ranking")],
              1
            ),
            _c(
              "v-col",
              { attrs: { cols: "12", md: "6" } },
              [_c("broker-reg-dispatch-ranking")],
              1
            ),
            _c(
              "v-col",
              { attrs: { cols: "12", md: "6" } },
              [_c("driver-reg-withdrawal-count")],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }