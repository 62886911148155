var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "d-flex flex-column",
      attrs: { height: "792px", width: "551px" },
    },
    [
      _c(
        "v-card-title",
        { staticClass: "font-size24 surface800 font-weight900" },
        [
          _c("span", [_vm._v("문자 전송")]),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { icon: "" },
              on: {
                click: function ($event) {
                  return _vm.close()
                },
              },
            },
            [
              _c("v-icon", { attrs: { size: "34", color: "#2F2F2F" } }, [
                _vm._v(" " + _vm._s(_vm.icons.mdiWindowClose) + " "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card-title",
        { staticClass: "my-0 pt-0 pb-4" },
        [_c("v-divider")],
        1
      ),
      _c(
        "v-card-text",
        [
          _c(
            "v-form",
            {
              ref: "form",
              model: {
                value: _vm.valid,
                callback: function ($$v) {
                  _vm.valid = $$v
                },
                expression: "valid",
              },
            },
            [
              _c("v-text-field", {
                staticClass: "mt-1",
                attrs: {
                  dense: "",
                  maxlength: "11",
                  outlined: "",
                  label: "받는 사람",
                  "persistent-placeholder": "",
                  required: "",
                  clearable: "",
                  "hide-details": "",
                  disabled: "",
                  oninput: "this.value = this.value.replace(/[^0-9]/g, '');",
                },
                model: {
                  value: _vm.userData.sendData,
                  callback: function ($$v) {
                    _vm.$set(_vm.userData, "sendData", $$v)
                  },
                  expression: "userData.sendData",
                },
              }),
              _c("v-textarea", {
                ref: "refContent",
                staticClass: "mt-4",
                attrs: {
                  label: "내용",
                  placeholder:
                    "내용을 입력해 주세요. 80Byte가 초과되면 자동으로 MMS로 전환됩니다.",
                  "persistent-placeholder": "",
                  outlined: "",
                  "no-resize": "",
                  counter: "",
                  rules: _vm.rules.contentRules,
                  rows: "10",
                },
                model: {
                  value: _vm.content,
                  callback: function ($$v) {
                    _vm.content = $$v
                  },
                  expression: "content",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-spacer"),
      _c("v-divider", { staticClass: "pa-0 ma-0" }),
      _c(
        "v-card-actions",
        { staticClass: "py-8 flex-center-contents flex-end-contents" },
        [
          _c(
            "v-btn",
            {
              staticClass: "popup_btn font-size18",
              staticStyle: { "box-shadow": "none !important" },
              attrs: { color: "primary", disabled: !_vm.valid },
              on: { click: _vm.onClickAddGroup },
            },
            [_vm._v(" 전송 ")]
          ),
          _c(
            "v-btn",
            {
              staticClass: "popup_btn cancel_btn font-size18",
              staticStyle: { "box-shadow": "none !important" },
              on: { click: _vm.close },
            },
            [_vm._v(" 취소 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }