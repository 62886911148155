<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="viewBox"
  >
    <g id="ic_customervoice" transform="translate(499 -626)">
      <rect id="사각형_31283" data-name="사각형 31283" width="28" height="28" transform="translate(-499 626)" fill="none"/>
      <g id="그룹_40392" data-name="그룹 40392">
        <g id="그룹_40391" data-name="그룹 40391" transform="translate(-496.466 631.549)">
          <g id="male" transform="translate(0)">
            <path id="패스_29477" data-name="패스 29477" d="M8,7.894A4.517,4.517,0,0,1,9.037,4.7a4.493,4.493,0,0,1,3.337-1.413A4.493,4.493,0,0,1,15.711,4.7a4.517,4.517,0,0,1,1.037,3.194v.966a6,6,0,0,1-1,3.285,3.972,3.972,0,0,1-3.371,1.763A3.972,3.972,0,0,1,9,12.145,6.01,6.01,0,0,1,8,8.787Zm7.654.722L13.468,7.3h0a.02.02,0,0,0-.01,0,14.059,14.059,0,0,1-1.993.969,11.659,11.659,0,0,1-1.845.433c-.194.032-.373.058-.524.08V9.2A3.693,3.693,0,0,0,9.9,11.52a2.88,2.88,0,0,0,2.474,1.295,2.88,2.88,0,0,0,2.474-1.295,4.921,4.921,0,0,0,.806-2.732ZM9.123,7.679a4.461,4.461,0,0,1,.711-2.23,3.335,3.335,0,0,1,2.54-1.068,3.335,3.335,0,0,1,2.54,1.068,4.429,4.429,0,0,1,.657,1.842l-1.541-.924a1.106,1.106,0,0,0-1.118-.01,13.077,13.077,0,0,1-1.814.887A10.641,10.641,0,0,1,9.44,7.63Z" transform="translate(-3.626 -3.287)" :fill="iconColor" :stroke="iconColor" stroke-width="0.5" fill-rule="evenodd"/>
            <path id="패스_29478" data-name="패스 29478" d="M20.4,20.561v-3.25a1.093,1.093,0,0,0-.793-1.051L15.671,15.3a2.7,2.7,0,0,1-.813.836,4.047,4.047,0,0,1-4.22,0,2.694,2.694,0,0,1-.813-.836l-3.938.955a1.093,1.093,0,0,0-.793,1.051v3.25ZM5.612,15.2,10.561,14a1.408,1.408,0,0,0,.163.674,2.253,2.253,0,0,0,2.023.966,2.253,2.253,0,0,0,2.023-.966A1.408,1.408,0,0,0,14.935,14l4.949,1.2a2.187,2.187,0,0,1,1.612,2.11v3.25A1.093,1.093,0,0,1,20.4,21.654H5.093A1.093,1.093,0,0,1,4,20.561v-3.25A2.187,2.187,0,0,1,5.612,15.2Z" transform="translate(-4 -2.286)" :fill="iconColor" :stroke="iconColor" stroke-width="0.5" fill-rule="evenodd"/>
          </g>
        </g>
        <path id="패스_29479" data-name="패스 29479" d="M-402.974,624.017a4.229,4.229,0,0,1,0,6.22" transform="translate(-77.033 9.977)" :fill="iconColor" :stroke="iconColor" stroke-width="1.6"/>
        <path id="패스_29480" data-name="패스 29480" d="M-403.362,623.527s5.22,5.136,0,10.7" transform="translate(-73.865 8.022)" :fill="iconColor" :stroke="iconColor" stroke-width="1.6"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'customervoice',

  props: {
    viewBox: { type: String, default: '0 0 28 28' },
    iconName: { type: String, default: '' },
    width: { type: [Number, String], default: 28 },
    height: { type: [Number, String], default: 28 },
    iconColor: { type: String, default: '#979eaa' },
  },
}
</script>

<style scoped></style>
