import _ from 'lodash'

/**
 * 차량 정보
 */
export = (() => {
  const _CALL_HISTORY_STATUS: any = {
    ASSIGNABLE: 'ASSIGNABLE',
    ASSIGN_REQUEST: 'ASSIGN_REQUEST',
    CANCEL_REQUEST: 'CANCEL_REQUEST',
    CANCEL_APPROVE: 'CANCEL_APPROVE',
    CANCEL_REJECT: 'CANCEL_REJECT',
    CHANGE_REQUEST: 'CHANGE_REQUEST',
    CHANGE_APPROVE: 'CHANGE_APPROVE',
    CHANGE_REJECT: 'CHANGE_REJECT',
    PAYED: 'PAYED',
    ASSIGNED: 'ASSIGNED',
    TRANSPORT_START: 'TRANSPORT_START',
    PICKED_UP: 'PICKED_UP',
    DROPPED_OFF: 'DROPPED_OFF',
    LEFT_WAYPOINT: 'LEFT_WAYPOINT',
    PICKUP_RETURNED: 'PICKUP_RETURNED',
    INVOICE_REGISTERED: 'INVOICE_REGISTERED',
    INVOICE_REJECTED: 'INVOICE_REJECTED',
    COMPLETED: 'COMPLETED',
    COMPLETED_BY_CONTROLLER: 'COMPLETED_BY_CONTROLLER',
    CANCELED_BY_CONTROLLER: 'CANCELED_BY_CONTROLLER',
    HOLDING: 'HOLDING',
    CANCELED: 'CANCELED',
    CANCELED_BY_SCHEDULE: 'CANCELED_BY_SCHEDULE',
    CHANGED: 'CHANGED',
  }

  const _CALL_HISTORY_STATUS_STRING: any = {
    ASSIGNABLE: '배차 대기',
    ASSIGN_REQUEST: '배차 요청',
    CANCEL_REQUEST: '화물 취소 요청',
    CANCEL_APPROVE: '화물 취소 승인',
    CANCEL_REJECT: '화물 취소 거절',
    CHANGE_REQUEST: '화물 변경 요청',
    CHANGE_APPROVE: '화물 변경 승인',
    CHANGE_REJECT: '화물 변경 거절',
    PAYED: '결제 완료',
    ASSIGNED: '배차 완료',
    TRANSPORT_START: '운송 시작',
    PICKED_UP: '상차 완료',
    DROPPED_OFF: '하차 완료',
    LEFT_WAYPOINT: '경유지 상/하차 완료',
    PICKUP_RETURNED: '상차지 복귀 완료',
    INVOICE_REGISTERED: '인수증 등록',
    INVOICE_REJECTED: '인수증 승인 거절',
    COMPLETED: '인수증 승인',
    COMPLETED_BY_CONTROLLER: '관리자에 의한 운송 완료',
    CANCELED_BY_CONTROLLER: '관리자에 의한 운송 취소',
    HOLDING: '관리자에 의한 운송 보류',
    CANCELED: '화물 취소',
    CANCELED_BY_SCHEDULE: '자동 화물 취소',
    CHANGED: '화물 변경',
  }

  Object.freeze(_CALL_HISTORY_STATUS)
  Object.freeze(_CALL_HISTORY_STATUS_STRING)

  const _CALL_HISTORY_STATUS_WEB_NAME: Map<string, string> = new Map<
    string,
    string
  >([
    [_CALL_HISTORY_STATUS.ASSIGNABLE, _CALL_HISTORY_STATUS_STRING.ASSIGNABLE],
    [
      _CALL_HISTORY_STATUS.ASSIGN_REQUEST,
      _CALL_HISTORY_STATUS_STRING.ASSIGN_REQUEST,
    ],
    [
      _CALL_HISTORY_STATUS.CANCEL_REQUEST,
      _CALL_HISTORY_STATUS_STRING.CANCEL_REQUEST,
    ],
    [
      _CALL_HISTORY_STATUS.CANCEL_APPROVE,
      _CALL_HISTORY_STATUS_STRING.CANCEL_APPROVE,
    ],
    [
      _CALL_HISTORY_STATUS.CANCEL_REJECT,
      _CALL_HISTORY_STATUS_STRING.CANCEL_REJECT,
    ],
    [
      _CALL_HISTORY_STATUS.CHANGE_REQUEST,
      _CALL_HISTORY_STATUS_STRING.CHANGE_REQUEST,
    ],
    [
      _CALL_HISTORY_STATUS.CHANGE_APPROVE,
      _CALL_HISTORY_STATUS_STRING.CHANGE_APPROVE,
    ],
    [
      _CALL_HISTORY_STATUS.CHANGE_REJECT,
      _CALL_HISTORY_STATUS_STRING.CHANGE_REJECT,
    ],
    [_CALL_HISTORY_STATUS.PAYED, _CALL_HISTORY_STATUS_STRING.PAYED],
    [_CALL_HISTORY_STATUS.ASSIGNED, _CALL_HISTORY_STATUS_STRING.ASSIGNED],
    [
      _CALL_HISTORY_STATUS.TRANSPORT_START,
      _CALL_HISTORY_STATUS_STRING.TRANSPORT_START,
    ],
    [_CALL_HISTORY_STATUS.PICKED_UP, _CALL_HISTORY_STATUS_STRING.PICKED_UP],
    [_CALL_HISTORY_STATUS.DROPPED_OFF, _CALL_HISTORY_STATUS_STRING.DROPPED_OFF],
    [
      _CALL_HISTORY_STATUS.LEFT_WAYPOINT,
      _CALL_HISTORY_STATUS_STRING.LEFT_WAYPOINT,
    ],
    [
      _CALL_HISTORY_STATUS.PICKUP_RETURNED,
      _CALL_HISTORY_STATUS_STRING.PICKUP_RETURNED,
    ],
    [
      _CALL_HISTORY_STATUS.INVOICE_REGISTERED,
      _CALL_HISTORY_STATUS_STRING.INVOICE_REGISTERED,
    ],
    [
      _CALL_HISTORY_STATUS.INVOICE_REJECTED,
      _CALL_HISTORY_STATUS_STRING.INVOICE_REJECTED,
    ],
    [_CALL_HISTORY_STATUS.COMPLETED, _CALL_HISTORY_STATUS_STRING.COMPLETED],
    [
      _CALL_HISTORY_STATUS.COMPLETED_BY_CONTROLLER,
      _CALL_HISTORY_STATUS_STRING.COMPLETED_BY_CONTROLLER,
    ],
    [
      _CALL_HISTORY_STATUS.CANCELED_BY_CONTROLLER,
      _CALL_HISTORY_STATUS_STRING.CANCELED_BY_CONTROLLER,
    ],
    [_CALL_HISTORY_STATUS.HOLDING, _CALL_HISTORY_STATUS_STRING.HOLDING],
    [_CALL_HISTORY_STATUS.CANCELED, _CALL_HISTORY_STATUS_STRING.CANCELED],
    [_CALL_HISTORY_STATUS.CANCELED_BY_SCHEDULE, _CALL_HISTORY_STATUS_STRING.CANCELED_BY_SCHEDULE],
    [_CALL_HISTORY_STATUS.CHANGED, _CALL_HISTORY_STATUS_STRING.CHANGED],
  ])

  return Object.freeze({
    CALL_HISTORY_STATUS_WEB_NAME: (str: string): string => {
      const callHistoryStatusName: string | undefined =
        _CALL_HISTORY_STATUS_WEB_NAME.get(str)

      return callHistoryStatusName ? callHistoryStatusName : '-'
    },
  })
})()
