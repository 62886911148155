















































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { mdiCheckCircleOutline } from '@mdi/js'
import { addCommas } from '@/utils/business.util'
import _ from 'lodash'
import PaymentHistoryPopup from '@/views/freightHistory/PaymentHistoryPopup.vue'

@Component({
  components: { PaymentHistoryPopup },
})
export default class CardPaymentCanceledCompleted extends Vue {
  @Prop(Object) itemDetail

  icons = {
    mdiCheckCircleOutline,
  }

  isPaymentBtn = true

  get paymentInfo() {
    return {
      title: '결제 정보',
      data: this.setPaymentData(),
    }
  }

  setPaymentData() {
    let data: any = [
      {
        title: '카드명',
        content: `신한카드`,
      },
      {
        title: '카드번호',
        content: `123123123**1231`,
      },
      {
        title: '결제 금액',
        content: `${addCommas(this.itemDetail?.finalPaymentAmount)}원`,
      },
    ]
    return data
  }

  lineDisplay(item) {
    if (_.isEqual(item.title, '카드번호')) return true
    else return false
  }

  isHistoryList = false
  callRequestId = null

  openHistoryListPopup(){
    this.callRequestId = this.itemDetail.callRequestId
    this.isHistoryList = true
  }
}
