







































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import Pagination from '@/views/utils/Pagination.vue'
import { mdiMagnify, mdiChevronDown, mdiPlus } from '@mdi/js'
import { State } from 'vuex-class'
import AdminService from '@/services-itgo/admin.service'
import SearchAddress from '@/views/utils/SearchAddress.vue'
import ManagerUserInfo from '@/views/managerMember/ManagerUserInfo.vue'
import ManagerEditPopup from '@/views/managerMember/ManagerEditPopup.vue'
import DatetimeUtils from '@/utils/datetime.utils'
import _ from 'lodash'

@Component({
  components: {
    ManagerEditPopup,
    ManagerUserInfo,
    SearchAddress,
    Pagination,
  },

  mounted() {
    this.fetchUserChangeList()
  },
})
export default class ManagerList extends Vue {
  @State((state) => state.auth.user) loginUser

  icons: any = {
    mdiMagnify,
    mdiChevronDown,
    mdiPlus,
  }

  type = null

  managerUsersInfo = {
    loading: false,
    headers: [
      { text: '권한', value: 'controllerRole', align: 'center', width: '7%' },
      {
        text: '사용자명',
        value: 'name',
        align: 'center',
        width: '8%',
      },
      {
        text: 'ID',
        value: 'loginId',
        align: 'center',
        width: '10%',
      },
      {
        text: '생성사유',
        value: 'regReason',
        align: 'center',
        width: '15%',
      },
      {
        text: '생성일',
        value: 'regDt',
        align: 'center',
        width: '10%',
      },
      {
        text: '변경사유',
        value: 'changeReason',
        align: 'center',
        width: '15%',
      },
      {
        text: '변경일',
        value: 'inactiveDt',
        align: 'center',
        width: '10%',
      },
      {
        text: '삭제사유',
        value: 'deleteReason',
        align: 'center',
        width: '15%',
      },
      {
        text: '삭제일',
        value: 'deleteDt',
        align: 'center',
        width: '10%',
      },
    ],
    items: [],
    serverItemsLength: 10,
    itemsPerPageOptions: [10, 20, 30],
  }

  get totalPageCount() {
    const length = Math.ceil(
      this.managerUsersInfo.serverItemsLength / this.pageSize,
    )
    return length === 0 ? 1 : length
  }

  pageSizes = [10, 20, 30]
  pageSize = 10
  pageNum = 1

  public isUserAddPopup: boolean = false
  public editCountKey: number = 0

  /**
   * pageSize: 페이지 크기
   * pageNum: 페이지 번호
   */
  @Watch('pageSize')
  onChangePageSize() {
    this.pageNum = 1
    this.fetchUserChangeList()
  }

  @Watch('pageNum')
  onChangePageNum() {
    this.fetchUserChangeList()
  }

  async fetchUserChangeList() {
    try {
      console.log('fetchUserChangeList')

      this.managerUsersInfo.loading = true
      this.managerUsersInfo.items = []
      //this.managerUsersInfo.serverItemsLength = 0

      const params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      }

      const data = await AdminService.getManagerList(params)

      console.log(data)
      this.managerUsersInfo.items = data.userList.map((item, index) => ({
        ...item,
      }))
      this.managerUsersInfo.serverItemsLength = data.totalCount
    } catch (error) {
      console.error(error)
    } finally {
      this.managerUsersInfo.loading = false
    }
  }

  userDetailInfo(item) {
    //TODO: 심사 서류 정보 화면으로 이동
    console.log('userDetailInfo', item)
    this.$router.push({
      name: 'managerUserInfo',
      params: { userInfo: item },
    })
  }

  userCreated() {
    console.log('userCreated')
    this.editCountKey = this.editCountKey + 1
    this.isUserAddPopup = true
  }

  editPopupAct(value) {
    this.isUserAddPopup = !this.isUserAddPopup
    console.log(value)
    if (value) {
      this.fetchUserChangeList()
    }
  }

  getRowStyle(item) {
    if (item.deleteDt) return 'deleted-id'
    else if (item.inactiveDt) return 'disabled-id'
    else return 'normal-id'
  }

  dateChangedType(date) {
    if (_.isNil(date)) return '-'
    else return DatetimeUtils.stringDate(date, 'YY-MM-DD HH:mm')
  }

  truncateText(text, maxLength) {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + '...'
    }
    return text
  }
}
