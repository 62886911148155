var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-wrapper content_body" },
    [
      _c(
        "div",
        { staticClass: "content-inner" },
        [
          _c(
            "v-card",
            { staticStyle: { height: "913px" } },
            [
              _c(
                "v-card-title",
                { staticClass: "px-4 pt-8 d-flex align-center" },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex align-center" },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "surface800",
                          attrs: { size: "35" },
                          on: {
                            click: function ($event) {
                              return _vm.$router.back()
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.icons.mdiChevronLeft) + " ")]
                      ),
                      _c(
                        "span",
                        {
                          staticClass:
                            "font-size26 font-weight700 surface800 pr-7",
                        },
                        [_vm._v(" 심사 서류 정보 ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "d-flex align-center",
                      staticStyle: { height: "20px" },
                    },
                    [
                      _c("span", { staticClass: "font-size20" }, [
                        _vm._v(_vm._s(_vm.name)),
                      ]),
                      _c("v-divider", {
                        staticStyle: { margin: "0 14px" },
                        attrs: { vertical: "" },
                      }),
                      _c("span", { staticClass: "font-size18" }, [
                        _vm._v(_vm._s(_vm.type)),
                      ]),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "pr-11", staticStyle: { "margin-top": "75px" } },
                [
                  _c(
                    "v-tabs",
                    {
                      staticStyle: {
                        "box-shadow": "none !important",
                        "border-bottom": "1px solid #e2e2e2",
                      },
                      attrs: { color: "accent" },
                      model: {
                        value: _vm.tab,
                        callback: function ($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab",
                      },
                    },
                    [
                      _c("v-tabs-slider", { attrs: { color: "accent" } }),
                      _vm._l(_vm.tabItems, function (item, index) {
                        return _c(
                          "v-tab",
                          {
                            key: index,
                            staticClass: "font-size18 font-weight500",
                            staticStyle: { width: "150px" },
                            attrs: {
                              disabled:
                                (index === 2 || index === 3) && !_vm.isDriver,
                            },
                            on: {
                              click: function ($event) {
                                index === 3 && _vm.additionalDoc()
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(item.title) + " ")]
                        )
                      }),
                    ],
                    2
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pa-0 left-col",
                              attrs: { cols: "12", md: "6", sm: "12" },
                            },
                            [
                              _c(
                                "v-tabs-items",
                                {
                                  model: {
                                    value: _vm.tab,
                                    callback: function ($$v) {
                                      _vm.tab = $$v
                                    },
                                    expression: "tab",
                                  },
                                },
                                _vm._l(_vm.tabItems, function (item, index) {
                                  return _c(
                                    "v-tab-item",
                                    { key: index },
                                    _vm._l(_vm.info, function (item, index) {
                                      return _c(
                                        "v-card",
                                        { key: index, staticClass: "pa-11" },
                                        [
                                          _c(
                                            "v-card-title",
                                            {
                                              key: "title_" + index,
                                              staticClass:
                                                "px-0 mb-5 font-size16 surface800 font-weight700",
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.title) + " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-card-text",
                                            { staticClass: "px-0 pt-0 pb-6" },
                                            [
                                              _vm._l(
                                                item.data,
                                                function (_item, i) {
                                                  return _item.show
                                                    ? _c("freight-info-item", {
                                                        key:
                                                          "data_" +
                                                          index +
                                                          "-" +
                                                          i,
                                                        attrs: {
                                                          title: _item.title,
                                                          content:
                                                            _item.content,
                                                        },
                                                      })
                                                    : _vm._e()
                                                }
                                              ),
                                              _vm.tab === 3 &&
                                              _vm.paramsArray.length > 0
                                                ? _c(
                                                    "viewer",
                                                    {
                                                      ref: "viewer",
                                                      refInFor: true,
                                                      staticClass: "add-image",
                                                      attrs: {
                                                        images:
                                                          _vm.imageURL.additionalImgUrlData.map(
                                                            function (x) {
                                                              if (
                                                                x.imageType !==
                                                                "application/pdf"
                                                              ) {
                                                                return x.imageUrl
                                                              }
                                                            }
                                                          ),
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.imageURL
                                                        .additionalImgUrlData,
                                                      function (data, index) {
                                                        return _c("div", [
                                                          !_vm.isPdfDocumentType(
                                                            data
                                                          )
                                                            ? _c("div", [
                                                                _c("img", {
                                                                  key:
                                                                    "additional-img-" +
                                                                    index,
                                                                  attrs: {
                                                                    src: data.imageUrl,
                                                                  },
                                                                }),
                                                              ])
                                                            : _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "audit-pdf-s",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.addPopupDocument(
                                                                          index
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "vue-pdf-embed",
                                                                    {
                                                                      key:
                                                                        "additional-pdf-" +
                                                                        index,
                                                                      attrs: {
                                                                        source:
                                                                          data.imageUrl,
                                                                        page: 1,
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                        ])
                                                      }
                                                    ),
                                                    0
                                                  )
                                                : _vm.tab === 3 &&
                                                  _vm.imageURL.additionalImgUrl
                                                    .length == 0
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "flex-center-contents flex-column py-8",
                                                    },
                                                    [
                                                      _c("NoneDoc"),
                                                      _c(
                                                        "span",
                                                        { staticClass: "mt-2" },
                                                        [
                                                          _vm._v(
                                                            "등록된 서류가 없습니다."
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pa-0 d-flex flex-column right-col",
                              staticStyle: { height: "681px" },
                              attrs: { cols: "12", md: "6", sm: "12" },
                            },
                            [
                              _c("div", { staticClass: "audit-progress" }, [
                                _c("img", {
                                  attrs: { src: _vm.progressBarImage },
                                }),
                              ]),
                              _c(
                                "perfect-scrollbar",
                                { staticClass: "ps-user-notifications" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex flex-column align-center mt-5",
                                      staticStyle: { flex: "1" },
                                    },
                                    [
                                      _vm.beDocumentType
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "audit-pdf",
                                              on: { click: _vm.popupDocument },
                                            },
                                            [
                                              _c("vue-pdf-embed", {
                                                attrs: {
                                                  source: _vm.documentImage,
                                                  page: 1,
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _c(
                                            "div",
                                            { staticClass: "audit-img" },
                                            [
                                              _vm.documentImage
                                                ? _c("viewer", {
                                                    ref: "viewer",
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function (scope) {
                                                            return [
                                                              _c("img", {
                                                                attrs: {
                                                                  src: _vm.documentImage,
                                                                },
                                                              }),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      1178601248
                                                    ),
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                      !_vm.approvalDone
                                        ? [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex align-center",
                                              },
                                              [
                                                _c(
                                                  "v-radio-group",
                                                  {
                                                    attrs: { row: "" },
                                                    model: {
                                                      value: _vm.audit,
                                                      callback: function ($$v) {
                                                        _vm.audit = $$v
                                                      },
                                                      expression: "audit",
                                                    },
                                                  },
                                                  [
                                                    _c("v-radio", {
                                                      staticClass:
                                                        "mt-0 v-check-customer",
                                                      attrs: {
                                                        label: "승인",
                                                        value: true,
                                                        color: "accent",
                                                        dense: "",
                                                        "hide-details": "",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.setAuditValue(
                                                            true
                                                          )
                                                        },
                                                      },
                                                    }),
                                                    _c("v-radio", {
                                                      staticClass:
                                                        "mt-0 v-check-customer",
                                                      attrs: {
                                                        label: "반려",
                                                        value: false,
                                                        color: "accent",
                                                        dense: "",
                                                        "hide-details": "",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.setAuditValue(
                                                            false
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: _vm.showReason,
                                                    expression: "showReason",
                                                  },
                                                ],
                                                staticClass: "audit-select",
                                              },
                                              [
                                                _c("v-select", {
                                                  attrs: {
                                                    items: _vm.showReasonItems,
                                                    label: "반려 사유 선택",
                                                    dense: "",
                                                    outlined: "",
                                                    "hide-details": "",
                                                    "menu-props": {
                                                      bottom: true,
                                                      offsetY: true,
                                                    },
                                                  },
                                                  model: {
                                                    value: _vm.reason,
                                                    callback: function ($$v) {
                                                      _vm.reason = $$v
                                                    },
                                                    expression: "reason",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex align-center justify-center my-7",
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "prev-btn mr-5",
                                      staticStyle: {
                                        "box-shadow": "none !important",
                                      },
                                      attrs: {
                                        disabled: _vm.disabledPrevButton,
                                      },
                                      on: { click: _vm.onClickPrev },
                                    },
                                    [_vm._v(" 이전 ")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "next-btn",
                                      class:
                                        _vm.audit === null ||
                                        (_vm.isLastStep && _vm.approvalDone) ||
                                        (_vm.audit === false && !_vm.reason)
                                          ? "disabled"
                                          : "active",
                                      staticStyle: {
                                        "box-shadow": "none !important",
                                      },
                                      attrs: {
                                        disabled:
                                          _vm.btnDisabled ||
                                          _vm.audit === null ||
                                          (_vm.isLastStep &&
                                            _vm.approvalDone) ||
                                          (_vm.audit === false && !_vm.reason),
                                      },
                                      on: { click: _vm.onClickNext },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.isLastStep ? "완료" : "다음"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.isPdfView
        ? _c("pdf-view-popup", {
            attrs: {
              "pdf-url": _vm.isAddDocument
                ? _vm.AddDocumentUrl
                : _vm.documentImage,
            },
            on: { closePdfView: _vm.closePdfView },
            model: {
              value: _vm.isPdfView,
              callback: function ($$v) {
                _vm.isPdfView = $$v
              },
              expression: "isPdfView",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }