import _ from 'lodash'

/**
 * 차량 정보
 */
export = (() => {
  const _TON_TYPE: any = {
    TON_1000: 1000,
    TON_1400: 1400,
    TON_2500: 2500,
    TON_3500: 3500,
    TON_5000: 5000,
    TON_8000: 8000,
    TON_9500: 9500,
    TON_11000: 11000,
    TON_14000: 14000,
    TON_15000: 15000,
    TON_16000: 16000,
    TON_18000: 18000,
    TON_22000: 22000,
    TON_25000: 25000,
  }

  const _TON_TYPE_STRING: any = {
    TON_1000: '1톤',
    TON_1400: '1.4톤',
    TON_2500: '2.5톤',
    TON_3500: '3.5톤',
    TON_5000: '5톤',
    TON_8000: '8톤',
    TON_9500: '9.5톤',
    TON_11000: '11톤',
    TON_14000: '14톤',
    TON_15000: '15톤',
    TON_16000: '16톤',
    TON_18000: '18톤',
    TON_22000: '22톤',
    TON_25000: '25톤',
  }

  Object.freeze(_TON_TYPE)
  Object.freeze(_TON_TYPE_STRING)

  const _TON_TYPE_WEB_NAME: Map<number, string> = new Map<number, string>([
    [_TON_TYPE.TON_1000, _TON_TYPE_STRING.TON_1000],
    [_TON_TYPE.TON_1400, _TON_TYPE_STRING.TON_1400],
    [_TON_TYPE.TON_2500, _TON_TYPE_STRING.TON_2500],
    [_TON_TYPE.TON_3500, _TON_TYPE_STRING.TON_3500],
    [_TON_TYPE.TON_5000, _TON_TYPE_STRING.TON_5000],
    [_TON_TYPE.TON_8000, _TON_TYPE_STRING.TON_8000],
    [_TON_TYPE.TON_9500, _TON_TYPE_STRING.TON_9500],
    [_TON_TYPE.TON_11000, _TON_TYPE_STRING.TON_11000],
    [_TON_TYPE.TON_14000, _TON_TYPE_STRING.TON_14000],
    [_TON_TYPE.TON_15000, _TON_TYPE_STRING.TON_15000],
    [_TON_TYPE.TON_16000, _TON_TYPE_STRING.TON_16000],
    [_TON_TYPE.TON_18000, _TON_TYPE_STRING.TON_18000],
    [_TON_TYPE.TON_22000, _TON_TYPE_STRING.TON_22000],
    [_TON_TYPE.TON_25000, _TON_TYPE_STRING.TON_25000],
  ])

  const _TON_SELECT_LIST = [
    { name: _TON_TYPE_STRING.TON_1000, value: _TON_TYPE.TON_1000 },
    { name: _TON_TYPE_STRING.TON_1400, value: _TON_TYPE.TON_1400 },
    { name: _TON_TYPE_STRING.TON_2500, value: _TON_TYPE.TON_2500 },
    { name: _TON_TYPE_STRING.TON_3500, value: _TON_TYPE.TON_3500 },
    { name: _TON_TYPE_STRING.TON_5000, value: _TON_TYPE.TON_5000 },
    { name: _TON_TYPE_STRING.TON_8000, value: _TON_TYPE.TON_8000 },
    { name: _TON_TYPE_STRING.TON_9500, value: _TON_TYPE.TON_9500 },
    { name: _TON_TYPE_STRING.TON_11000, value: _TON_TYPE.TON_11000 },
    { name: _TON_TYPE_STRING.TON_14000, value: _TON_TYPE.TON_14000 },
    { name: _TON_TYPE_STRING.TON_15000, value: _TON_TYPE.TON_15000 },
    { name: _TON_TYPE_STRING.TON_16000, value: _TON_TYPE.TON_16000 },
    { name: _TON_TYPE_STRING.TON_18000, value: _TON_TYPE.TON_18000 },
    { name: _TON_TYPE_STRING.TON_22000, value: _TON_TYPE.TON_22000 },
    { name: _TON_TYPE_STRING.TON_25000, value: _TON_TYPE.TON_25000 },
  ]

  return Object.freeze({
    TON_TOTAL_LIST: _TON_SELECT_LIST,

    TONE_NAME_WEB: (weightValue: number): string => {
      const tonName: string | undefined = _TON_TYPE_WEB_NAME.get(weightValue)
      return tonName ? tonName : '-'
    },
  })
})()
