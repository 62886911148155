var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "dash-board-card-contents2" },
    [
      _c("v-card-title", { staticClass: "align-start" }, [
        _c(
          "span",
          { staticClass: "font-size19 font-weight-semibold surface800" },
          [_vm._v(" 주선사 등록·배차 순위 ")]
        ),
      ]),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6" } },
            [
              _c("div", { staticClass: "flex-start-contents mb-2" }, [
                _c(
                  "div",
                  { staticClass: "ml-8", staticStyle: { width: "50%" } },
                  [
                    _c(
                      "span",
                      {
                        staticClass:
                          "font-size14 font-weight-semibold surface800",
                      },
                      [_vm._v(" 화물 등록 ")]
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticStyle: { width: "50%" } },
                  [
                    _c("vue2-date-picker", {
                      staticStyle: {
                        "max-width": "100%",
                        "padding-right": "16px",
                      },
                      attrs: {
                        type: "month",
                        "default-value": new Date().setHours(12, 0, 0, 0),
                        "disabled-date": _vm.notBeforeToday,
                        "disabled-time": _vm.notBeforeTodayTwelveOClock,
                        lang: "ko",
                      },
                      on: { change: _vm.regSetInputDate },
                      model: {
                        value: _vm.monthDate,
                        callback: function ($$v) {
                          _vm.monthDate = $$v
                        },
                        expression: "monthDate",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("dash-board-simple-table", {
                attrs: { "reg-dispatch-data": _vm.brokerRegRankingData },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6" } },
            [
              _c("div", { staticClass: "flex-start-contents mb-2" }, [
                _c(
                  "div",
                  { staticClass: "ml-8", staticStyle: { width: "50%" } },
                  [
                    _c(
                      "span",
                      {
                        staticClass:
                          "font-size14 font-weight-semibold surface800",
                      },
                      [_vm._v(" 화물 배차 ")]
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticStyle: { width: "50%" } },
                  [
                    _c("vue2-date-picker", {
                      staticStyle: {
                        "max-width": "100%",
                        "padding-right": "16px",
                      },
                      attrs: {
                        type: "month",
                        "default-value": new Date().setHours(12, 0, 0, 0),
                        "disabled-date": _vm.notBeforeToday,
                        "disabled-time": _vm.notBeforeTodayTwelveOClock,
                        lang: "ko",
                      },
                      on: { change: _vm.dispatchSetInputDate },
                      model: {
                        value: _vm.monthDateDispatch,
                        callback: function ($$v) {
                          _vm.monthDateDispatch = $$v
                        },
                        expression: "monthDateDispatch",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("dash-board-simple-table", {
                attrs: { "reg-dispatch-data": _vm.brokerDispatchRankingData },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }