var logi = logi ?? {};
logi['maps'] = logi['maps'] ?? {};
import a10_0x31259c from './logi-maps-defines.js';
logi['maps']['Defines'] = a10_0x31259c;
import a10_0x3ade40 from './logi-maps-types.js';
logi['maps']['ALIGN'] = a10_0x3ade40['ALIGN'], logi['maps']['Utils'] = class {
    static #androidEnv = null;
    static #iOSEnv = null;
    static #keyStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
    static ['isAndroid']() {
        return this.#androidEnv == null && (this.#androidEnv = navigator['userAgent']['match'](/Android/i) == null ? ![] : !![]), this.#androidEnv;
    }
    static ['isIOS']() {
        return this.#iOSEnv == null && (this.#iOSEnv = navigator['userAgent']['match'](/iPhone|iPad|iPod/i) == null ? ![] : !![]), this.#iOSEnv;
    }
    static ['getCurTick']() {
        return new Date()['getTime']();
    }
    static ['longitude2TileX'](_0x10f954, _0x5b3379) {
        return (_0x10f954 + 0xb4) / 0x168 * logi['maps']['Defines']['POW_TABLE'][_0x5b3379];
    }
    static ['latitude2TileY'](_0x4124b5, _0x66acfa) {
        return (0x1 - Math['log'](Math['tan'](_0x4124b5 * logi['maps']['Defines']['DIV_PI_180']) + 0x1 / Math['cos'](_0x4124b5 * logi['maps']['Defines']['DIV_PI_180'])) / Math['PI']) / 0x2 * logi['maps']['Defines']['POW_TABLE'][_0x66acfa];
    }
    static ['tileX2Longitude'](_0x596c00, _0x5f1889) {
        return _0x596c00 / logi['maps']['Defines']['POW_TABLE'][_0x5f1889] * 0x168 - 0xb4;
    }
    static ['tileY2Latitude'](_0xd0989c, _0x5b7079) {
        return Math['atan'](Math['sinh'](Math['PI'] * (0x1 - 0x2 * _0xd0989c / logi['maps']['Defines']['POW_TABLE'][_0x5b7079]))) * logi['maps']['Defines']['DIV_180_PI'];
    }
    static ['degToRad'](_0x42ec8d) {
        return _0x42ec8d * Math['PI'] / 0xb4;
    }
    static ['rectOnRect'](_0x75d27d, _0x29b5b6, _0x286ef2, _0x10257f, _0x52c116, _0x3106a6, _0xd228be, _0x4b6d15) {
        return _0x286ef2 >= _0x52c116 && _0x75d27d <= _0xd228be && _0x10257f >= _0x3106a6 && _0x29b5b6 <= _0x4b6d15;
    }
    static ['pointInRect'](_0x469471, _0x590f52, _0x188f8f, _0x27267c, _0x186593, _0x34be05) {
        return _0x469471 >= _0x188f8f && _0x590f52 >= _0x27267c && _0x469471 <= _0x186593 && _0x590f52 <= _0x34be05;
    }
    static ['generalizeDegree'](_0x355c36, _0x3ad7dd = 0x168) {
        const _0x291cba = 0x80;
        for (let _0xf0439d = 0x0; _0xf0439d < _0x291cba && _0x355c36 < 0x0; ++_0xf0439d) {
            _0x355c36 += _0x3ad7dd;
        }
        for (let _0x12b790 = 0x0; _0x12b790 < _0x291cba && _0x355c36 > _0x3ad7dd; ++_0x12b790) {
            _0x355c36 -= _0x3ad7dd;
        }
        return _0x355c36;
    }
    static ['getDegreeBetweenVectors'](_0x3f02ca, _0x202825) {
        const _0x2edf04 = _0x3f02ca['x'] * _0x202825['x'] + _0x3f02ca['y'] * _0x202825['y'], _0x46699c = _0x3f02ca['x'] * _0x202825['y'] - _0x3f02ca['y'] * _0x202825['x'];
        let _0x2220b0 = Math['acos'](_0x2edf04);
        return _0x46699c < 0x0 && (_0x2220b0 = Math['PI'] * 0x2 - _0x2220b0), logi['maps']['Utils']['generalizeDegree'](_0x2220b0 * (0xb4 / Math['PI']));
    }
    static ['getVectorNormalize'](_0x299723) {
        const _0x50e7ed = logi['maps']['Utils']['getVectorLength'](_0x299723);
        if (_0x50e7ed == 0x0)
            return {
                'x': _0x299723['x'],
                'y': _0x299723['y']
            };
        return {
            'x': _0x299723['x'] / _0x50e7ed,
            'y': _0x299723['y'] / _0x50e7ed
        };
    }
    static ['getVectorLength'](_0x5c5f17) {
        return Math['sqrt'](_0x5c5f17['x'] * _0x5c5f17['x'] + _0x5c5f17['y'] * _0x5c5f17['y']);
    }
    static ['getAlignPosition'](_0xcde879, _0x1e347d, _0x416076, _0x295dc1, _0x5cddaa) {
        let _0x1c5a71 = {
            'x': 0x0,
            'y': 0x0
        };
        switch (_0x416076) {
        case logi['maps']['ALIGN']['LT']:
        case logi['maps']['ALIGN']['LM']:
        case logi['maps']['ALIGN']['LB']:
            _0x1c5a71['x'] = _0xcde879;
            break;
        case logi['maps']['ALIGN']['CT']:
        case logi['maps']['ALIGN']['CM']:
        case logi['maps']['ALIGN']['CB']:
            _0x1c5a71['x'] = _0xcde879 - _0x295dc1 * 0.5;
            break;
        case logi['maps']['ALIGN']['RT']:
        case logi['maps']['ALIGN']['RM']:
        case logi['maps']['ALIGN']['RB']:
            _0x1c5a71['x'] = _0xcde879 - _0x295dc1 * 0x1;
            break;
        }
        switch (_0x416076) {
        case logi['maps']['ALIGN']['LT']:
        case logi['maps']['ALIGN']['CT']:
        case logi['maps']['ALIGN']['RT']:
            _0x1c5a71['y'] = _0x1e347d;
            break;
        case logi['maps']['ALIGN']['LM']:
        case logi['maps']['ALIGN']['CM']:
        case logi['maps']['ALIGN']['RM']:
            _0x1c5a71['y'] = _0x1e347d - _0x5cddaa * 0.5;
            break;
        case logi['maps']['ALIGN']['LB']:
        case logi['maps']['ALIGN']['CB']:
        case logi['maps']['ALIGN']['RB']:
            _0x1c5a71['y'] = _0x1e347d - _0x5cddaa * 0x1;
            break;
        }
        return _0x1c5a71;
    }
    static ['getPivotPoint'](_0x505f35, _0x1b5032, _0x1970ca) {
        let _0x560664 = {
            'x': 0x0,
            'y': 0x0
        };
        switch (_0x505f35) {
        case logi['maps']['ALIGN']['LT']:
        case logi['maps']['ALIGN']['LM']:
        case logi['maps']['ALIGN']['LB']:
            _0x560664['x'] = 0x0;
            break;
        case logi['maps']['ALIGN']['CT']:
        case logi['maps']['ALIGN']['CM']:
        case logi['maps']['ALIGN']['CB']:
            _0x560664['x'] = _0x1b5032 * 0.5;
            break;
        case logi['maps']['ALIGN']['RT']:
        case logi['maps']['ALIGN']['RM']:
        case logi['maps']['ALIGN']['RB']:
            _0x560664['x'] = _0x1b5032 * 0x1;
            break;
        }
        switch (_0x505f35) {
        case logi['maps']['ALIGN']['LT']:
        case logi['maps']['ALIGN']['CT']:
        case logi['maps']['ALIGN']['RT']:
            _0x560664['y'] = 0x0;
            break;
        case logi['maps']['ALIGN']['LM']:
        case logi['maps']['ALIGN']['CM']:
        case logi['maps']['ALIGN']['RM']:
            _0x560664['y'] = _0x1970ca * 0.5;
            break;
        case logi['maps']['ALIGN']['LB']:
        case logi['maps']['ALIGN']['CB']:
        case logi['maps']['ALIGN']['RB']:
            _0x560664['y'] = _0x1970ca * 0x1;
            break;
        }
        return _0x560664;
    }
    static ['convertToTextAlign'](_0x2516ba) {
        switch (_0x2516ba) {
        case logi['maps']['ALIGN']['LT']:
        case logi['maps']['ALIGN']['LM']:
        case logi['maps']['ALIGN']['LB']:
            return 'left';
        case logi['maps']['ALIGN']['CT']:
        case logi['maps']['ALIGN']['CM']:
        case logi['maps']['ALIGN']['CB']:
            return 'center';
        case logi['maps']['ALIGN']['RT']:
        case logi['maps']['ALIGN']['RM']:
        case logi['maps']['ALIGN']['RB']:
            return 'right';
        default:
            return 'left';
        }
    }
    static ['convertToTextBaseline'](_0x422474) {
        switch (_0x422474) {
        case logi['maps']['ALIGN']['LT']:
        case logi['maps']['ALIGN']['CT']:
        case logi['maps']['ALIGN']['RT']:
            return 'top';
        case logi['maps']['ALIGN']['LM']:
        case logi['maps']['ALIGN']['CM']:
        case logi['maps']['ALIGN']['RM']:
            return 'middle';
        case logi['maps']['ALIGN']['LB']:
        case logi['maps']['ALIGN']['CB']:
        case logi['maps']['ALIGN']['RB']:
            return 'bottom';
        default:
            return 'top';
        }
    }
    static ['getTextWidth'](_0x5d71bd, _0x2c64da, _0x4cfa49, _0xf30e62) {
        return _0x4cfa49 == '' ? _0x5d71bd['font'] = _0xf30e62 + ('px\x20' + logi['maps']['Defines']['DEFAULT_FONT']) : _0x5d71bd['font'] = _0xf30e62 + ('px\x20' + _0x4cfa49), _0x5d71bd['measureText'](_0x2c64da)['width'];
    }
    static ['getBoundaryBox'](_0xba5b6a, _0x59008f, _0x185a38, _0x584e16, _0x2bbc64) {
        let _0x9d3acd = [
                {
                    'x': 0x0,
                    'y': 0x0
                },
                {
                    'x': 0x0,
                    'y': 0x0
                },
                {
                    'x': 0x0,
                    'y': 0x0
                },
                {
                    'x': 0x0,
                    'y': 0x0
                }
            ], _0x1fac68 = logi['maps']['Utils']['getAlignPosition'](_0xba5b6a['x'], _0xba5b6a['y'], _0x584e16, _0x59008f, _0x185a38);
        _0x9d3acd[0x0] = {
            'x': _0x1fac68['x'],
            'y': _0x1fac68['y']
        }, _0x9d3acd[0x1] = {
            'x': _0x1fac68['x'] + _0x59008f,
            'y': _0x1fac68['y']
        }, _0x9d3acd[0x2] = {
            'x': _0x1fac68['x'] + _0x59008f,
            'y': _0x1fac68['y'] + _0x185a38
        }, _0x9d3acd[0x3] = {
            'x': _0x1fac68['x'],
            'y': _0x1fac68['y'] + _0x185a38
        };
        if (_0x2bbc64 != 0x0) {
            let _0x4dde26 = logi['maps']['Utils']['getPivotPoint'](_0x584e16, _0x59008f, _0x185a38);
            _0x4dde26['x'] += _0x1fac68['x'], _0x4dde26['y'] += _0x1fac68['y'];
            const _0x28161f = logi['maps']['Utils']['degToRad'](_0x2bbc64);
            for (let _0x576afa = 0x0; _0x576afa < _0x9d3acd['length']; ++_0x576afa) {
                const _0x1f1558 = _0x9d3acd[_0x576afa]['x'], _0x4e8ad0 = _0x9d3acd[_0x576afa]['y'];
                _0x9d3acd[_0x576afa]['x'] = _0x4dde26['x'] + (_0x1f1558 - _0x4dde26['x']) * Math['cos'](-_0x28161f) + (_0x4e8ad0 - _0x4dde26['y']) * Math['sin'](-_0x28161f), _0x9d3acd[_0x576afa]['y'] = _0x4dde26['y'] - (_0x1f1558 - _0x4dde26['x']) * Math['sin'](-_0x28161f) + (_0x4e8ad0 - _0x4dde26['y']) * Math['cos'](-_0x28161f);
            }
        }
        return _0x9d3acd;
    }
    static ['getBoundaryCircle'](_0x4f88e8) {
        let _0x52b523 = {
                'center': {
                    'x': 0x0,
                    'y': 0x0
                },
                'maxR': 0x0
            }, _0x2f4877 = {
                'x': _0x4f88e8[0x0]['x'],
                'y': _0x4f88e8[0x0]['y']
            }, _0x5c5f40 = {
                'x': _0x4f88e8[0x0]['x'],
                'y': _0x4f88e8[0x0]['y']
            };
        for (let _0xf95c7f = 0x1; _0xf95c7f < 0x4; ++_0xf95c7f) {
            _0x2f4877['x'] = _0x2f4877['x'] < _0x4f88e8[_0xf95c7f]['x'] ? _0x2f4877['x'] : _0x4f88e8[_0xf95c7f]['x'], _0x2f4877['y'] = _0x2f4877['y'] < _0x4f88e8[_0xf95c7f]['y'] ? _0x2f4877['y'] : _0x4f88e8[_0xf95c7f]['y'], _0x5c5f40['x'] = _0x5c5f40['x'] > _0x4f88e8[_0xf95c7f]['x'] ? _0x5c5f40['x'] : _0x4f88e8[_0xf95c7f]['x'], _0x5c5f40['y'] = _0x5c5f40['y'] > _0x4f88e8[_0xf95c7f]['y'] ? _0x5c5f40['y'] : _0x4f88e8[_0xf95c7f]['y'];
        }
        const _0x483aad = {
            'w': (_0x5c5f40['x'] - _0x2f4877['x']) * 0.5,
            'h': (_0x5c5f40['y'] - _0x2f4877['y']) * 0.5
        };
        return _0x52b523['center']['x'] = _0x2f4877['x'] + _0x483aad['w'], _0x52b523['center']['y'] = _0x2f4877['y'] + _0x483aad['h'], _0x52b523['maxR'] = Math['sqrt'](_0x483aad['w'] * _0x483aad['w'] + _0x483aad['h'] * _0x483aad['h']), _0x52b523;
    }
    static ['getRoughLatLngs'](_0x4631e6, _0x453fd8 = 0x4) {
        let _0x3893bd = new Array();
        if (_0x4631e6['length'] > 0x0) {
            let _0x4de0ce = {
                    'x': 0x0,
                    'y': 0x0
                }, _0x58efb3 = {
                    'x': 0x0,
                    'y': 0x0
                }, _0x505dfe = {
                    'x': 0x0,
                    'y': 0x0
                };
            _0x58efb3['x'] = this['longitude2TileX'](_0x4631e6[0x0]['lng'], logi['maps']['Defines']['MAX_LEVEL']) * logi['maps']['Defines']['TILE_W'], _0x58efb3['y'] = this['latitude2TileY'](_0x4631e6[0x0]['lat'], logi['maps']['Defines']['MAX_LEVEL']) * logi['maps']['Defines']['TILE_H'], _0x3893bd['push']({
                'lng': _0x4631e6[0x0]['lng'],
                'lat': _0x4631e6[0x0]['lat']
            });
            for (let _0x205785 of _0x4631e6) {
                _0x4de0ce['x'] = this['longitude2TileX'](_0x205785['lng'], logi['maps']['Defines']['MAX_LEVEL']) * logi['maps']['Defines']['TILE_W'], _0x4de0ce['y'] = this['latitude2TileY'](_0x205785['lat'], logi['maps']['Defines']['MAX_LEVEL']) * logi['maps']['Defines']['TILE_H'];
                const _0x5402eb = Math['sqrt'](Math['pow'](_0x58efb3['x'] - _0x4de0ce['x'], 0x2) + Math['pow'](_0x58efb3['y'] - _0x4de0ce['y'], 0x2));
                _0x5402eb >= _0x453fd8 && (_0x3893bd['length'] >= 0x2 ? this['isStraight'](_0x505dfe['x'], _0x505dfe['y'], _0x58efb3['x'], _0x58efb3['y'], _0x4de0ce['x'], _0x4de0ce['y']) == !![] ? _0x3893bd[_0x3893bd['length'] - 0x1] = {
                    'lng': _0x205785['lng'],
                    'lat': _0x205785['lat']
                } : _0x3893bd['push']({
                    'lng': _0x205785['lng'],
                    'lat': _0x205785['lat']
                }) : _0x3893bd['push']({
                    'lng': _0x205785['lng'],
                    'lat': _0x205785['lat']
                }), _0x505dfe['x'] = _0x58efb3['x'], _0x505dfe['y'] = _0x58efb3['y'], _0x58efb3['x'] = _0x4de0ce['x'], _0x58efb3['y'] = _0x4de0ce['y']);
            }
        }
        return _0x3893bd;
    }
    static ['addRoughLatLngs'](_0x3deae8, _0x596b62, _0x3e7fc2 = 0x4) {
        let _0xf92d78 = ![];
        if (_0x3deae8['length'] == 0x0)
            _0x3deae8['push']({
                'lng': _0x596b62['lng'],
                'lat': _0x596b62['lat']
            }), _0xf92d78 = !![];
        else {
            let _0x151384 = {
                    'x': 0x0,
                    'y': 0x0
                }, _0x3a863f = {
                    'x': 0x0,
                    'y': 0x0
                }, _0x2600b7 = {
                    'x': 0x0,
                    'y': 0x0
                };
            _0x151384['x'] = this['longitude2TileX'](_0x596b62['lng'], logi['maps']['Defines']['MAX_LEVEL']) * logi['maps']['Defines']['TILE_W'], _0x151384['y'] = this['latitude2TileY'](_0x596b62['lat'], logi['maps']['Defines']['MAX_LEVEL']) * logi['maps']['Defines']['TILE_H'], _0x3a863f['x'] = this['longitude2TileX'](_0x3deae8[_0x3deae8['length'] - 0x1]['lng'], logi['maps']['Defines']['MAX_LEVEL']) * logi['maps']['Defines']['TILE_W'], _0x3a863f['y'] = this['latitude2TileY'](_0x3deae8[_0x3deae8['length'] - 0x1]['lat'], logi['maps']['Defines']['MAX_LEVEL']) * logi['maps']['Defines']['TILE_H'];
            const _0x3df7b8 = Math['sqrt'](Math['pow'](_0x3a863f['x'] - _0x151384['x'], 0x2) + Math['pow'](_0x3a863f['y'] - _0x151384['y'], 0x2));
            _0x3df7b8 >= _0x3e7fc2 && (_0x3deae8['length'] >= 0x2 ? (_0x2600b7['x'] = this['longitude2TileX'](_0x3deae8[_0x3deae8['length'] - 0x2]['lng'], logi['maps']['Defines']['MAX_LEVEL']) * logi['maps']['Defines']['TILE_W'], _0x2600b7['y'] = this['latitude2TileY'](_0x3deae8[_0x3deae8['length'] - 0x2]['lat'], logi['maps']['Defines']['MAX_LEVEL']) * logi['maps']['Defines']['TILE_H'], this['isStraight'](_0x2600b7['x'], _0x2600b7['y'], _0x3a863f['x'], _0x3a863f['y'], _0x151384['x'], _0x151384['y']) == !![] ? _0x3deae8[_0x3deae8['length'] - 0x1] = {
                'lng': _0x596b62['lng'],
                'lat': _0x596b62['lat']
            } : _0x3deae8['push']({
                'lng': _0x596b62['lng'],
                'lat': _0x596b62['lat']
            })) : _0x3deae8['push']({
                'lng': _0x596b62['lng'],
                'lat': _0x596b62['lat']
            }), _0xf92d78 = !![]);
        }
        return _0xf92d78;
    }
    static ['isStraight'](_0x3cee08, _0xc476e1, _0xdb49bb, _0x3ba756, _0x30389d, _0x1cebe2, _0x121b6d = 0x2) {
        _0x3cee08 = Math['round'](_0x3cee08 / _0x121b6d), _0xc476e1 = Math['round'](_0xc476e1 / _0x121b6d), _0xdb49bb = Math['round'](_0xdb49bb / _0x121b6d), _0x3ba756 = Math['round'](_0x3ba756 / _0x121b6d), _0x30389d = Math['round'](_0x30389d / _0x121b6d), _0x1cebe2 = Math['round'](_0x1cebe2 / _0x121b6d);
        const _0x238961 = (_0x3ba756 - _0xc476e1) * (_0x30389d - _0x3cee08) + _0xc476e1 * (_0xdb49bb - _0x3cee08), _0x27033f = (_0xdb49bb - _0x3cee08) * _0x1cebe2;
        return _0x238961 == _0x27033f;
    }
    static #compress(_0x58ee24) {
        if (_0x58ee24 == null)
            return '';
        var _0x2ef8d9, _0x208eab, _0x118c8c = {}, _0x573825 = {}, _0x2e6e25 = '', _0x5ddd35 = '', _0x30bc3f = '', _0x52acd9 = 0x2, _0x2cb067 = 0x3, _0x2494f5 = 0x2, _0x5453eb = '', _0x263e0f = 0x0, _0x44a38f = 0x0, _0x1ea394;
        for (_0x1ea394 = 0x0; _0x1ea394 < _0x58ee24['length']; _0x1ea394 += 0x1) {
            _0x2e6e25 = _0x58ee24['charAt'](_0x1ea394);
            !Object['prototype']['hasOwnProperty']['call'](_0x118c8c, _0x2e6e25) && (_0x118c8c[_0x2e6e25] = _0x2cb067++, _0x573825[_0x2e6e25] = !![]);
            _0x5ddd35 = _0x30bc3f + _0x2e6e25;
            if (Object['prototype']['hasOwnProperty']['call'](_0x118c8c, _0x5ddd35))
                _0x30bc3f = _0x5ddd35;
            else {
                if (Object['prototype']['hasOwnProperty']['call'](_0x573825, _0x30bc3f)) {
                    if (_0x30bc3f['charCodeAt'](0x0) < 0x100) {
                        for (_0x2ef8d9 = 0x0; _0x2ef8d9 < _0x2494f5; _0x2ef8d9++) {
                            _0x263e0f = _0x263e0f << 0x1, _0x44a38f == 0xf ? (_0x44a38f = 0x0, _0x5453eb += String['fromCharCode'](_0x263e0f), _0x263e0f = 0x0) : _0x44a38f++;
                        }
                        _0x208eab = _0x30bc3f['charCodeAt'](0x0);
                        for (_0x2ef8d9 = 0x0; _0x2ef8d9 < 0x8; _0x2ef8d9++) {
                            _0x263e0f = _0x263e0f << 0x1 | _0x208eab & 0x1, _0x44a38f == 0xf ? (_0x44a38f = 0x0, _0x5453eb += String['fromCharCode'](_0x263e0f), _0x263e0f = 0x0) : _0x44a38f++, _0x208eab = _0x208eab >> 0x1;
                        }
                    } else {
                        _0x208eab = 0x1;
                        for (_0x2ef8d9 = 0x0; _0x2ef8d9 < _0x2494f5; _0x2ef8d9++) {
                            _0x263e0f = _0x263e0f << 0x1 | _0x208eab, _0x44a38f == 0xf ? (_0x44a38f = 0x0, _0x5453eb += String['fromCharCode'](_0x263e0f), _0x263e0f = 0x0) : _0x44a38f++, _0x208eab = 0x0;
                        }
                        _0x208eab = _0x30bc3f['charCodeAt'](0x0);
                        for (_0x2ef8d9 = 0x0; _0x2ef8d9 < 0x10; _0x2ef8d9++) {
                            _0x263e0f = _0x263e0f << 0x1 | _0x208eab & 0x1, _0x44a38f == 0xf ? (_0x44a38f = 0x0, _0x5453eb += String['fromCharCode'](_0x263e0f), _0x263e0f = 0x0) : _0x44a38f++, _0x208eab = _0x208eab >> 0x1;
                        }
                    }
                    _0x52acd9--, _0x52acd9 == 0x0 && (_0x52acd9 = Math['pow'](0x2, _0x2494f5), _0x2494f5++), delete _0x573825[_0x30bc3f];
                } else {
                    _0x208eab = _0x118c8c[_0x30bc3f];
                    for (_0x2ef8d9 = 0x0; _0x2ef8d9 < _0x2494f5; _0x2ef8d9++) {
                        _0x263e0f = _0x263e0f << 0x1 | _0x208eab & 0x1, _0x44a38f == 0xf ? (_0x44a38f = 0x0, _0x5453eb += String['fromCharCode'](_0x263e0f), _0x263e0f = 0x0) : _0x44a38f++, _0x208eab = _0x208eab >> 0x1;
                    }
                }
                _0x52acd9--, _0x52acd9 == 0x0 && (_0x52acd9 = Math['pow'](0x2, _0x2494f5), _0x2494f5++), _0x118c8c[_0x5ddd35] = _0x2cb067++, _0x30bc3f = String(_0x2e6e25);
            }
        }
        if (_0x30bc3f !== '') {
            if (Object['prototype']['hasOwnProperty']['call'](_0x573825, _0x30bc3f)) {
                if (_0x30bc3f['charCodeAt'](0x0) < 0x100) {
                    for (_0x2ef8d9 = 0x0; _0x2ef8d9 < _0x2494f5; _0x2ef8d9++) {
                        _0x263e0f = _0x263e0f << 0x1, _0x44a38f == 0xf ? (_0x44a38f = 0x0, _0x5453eb += String['fromCharCode'](_0x263e0f), _0x263e0f = 0x0) : _0x44a38f++;
                    }
                    _0x208eab = _0x30bc3f['charCodeAt'](0x0);
                    for (_0x2ef8d9 = 0x0; _0x2ef8d9 < 0x8; _0x2ef8d9++) {
                        _0x263e0f = _0x263e0f << 0x1 | _0x208eab & 0x1, _0x44a38f == 0xf ? (_0x44a38f = 0x0, _0x5453eb += String['fromCharCode'](_0x263e0f), _0x263e0f = 0x0) : _0x44a38f++, _0x208eab = _0x208eab >> 0x1;
                    }
                } else {
                    _0x208eab = 0x1;
                    for (_0x2ef8d9 = 0x0; _0x2ef8d9 < _0x2494f5; _0x2ef8d9++) {
                        _0x263e0f = _0x263e0f << 0x1 | _0x208eab, _0x44a38f == 0xf ? (_0x44a38f = 0x0, _0x5453eb += String['fromCharCode'](_0x263e0f), _0x263e0f = 0x0) : _0x44a38f++, _0x208eab = 0x0;
                    }
                    _0x208eab = _0x30bc3f['charCodeAt'](0x0);
                    for (_0x2ef8d9 = 0x0; _0x2ef8d9 < 0x10; _0x2ef8d9++) {
                        _0x263e0f = _0x263e0f << 0x1 | _0x208eab & 0x1, _0x44a38f == 0xf ? (_0x44a38f = 0x0, _0x5453eb += String['fromCharCode'](_0x263e0f), _0x263e0f = 0x0) : _0x44a38f++, _0x208eab = _0x208eab >> 0x1;
                    }
                }
                _0x52acd9--, _0x52acd9 == 0x0 && (_0x52acd9 = Math['pow'](0x2, _0x2494f5), _0x2494f5++), delete _0x573825[_0x30bc3f];
            } else {
                _0x208eab = _0x118c8c[_0x30bc3f];
                for (_0x2ef8d9 = 0x0; _0x2ef8d9 < _0x2494f5; _0x2ef8d9++) {
                    _0x263e0f = _0x263e0f << 0x1 | _0x208eab & 0x1, _0x44a38f == 0xf ? (_0x44a38f = 0x0, _0x5453eb += String['fromCharCode'](_0x263e0f), _0x263e0f = 0x0) : _0x44a38f++, _0x208eab = _0x208eab >> 0x1;
                }
            }
            _0x52acd9--, _0x52acd9 == 0x0 && (_0x52acd9 = Math['pow'](0x2, _0x2494f5), _0x2494f5++);
        }
        _0x208eab = 0x2;
        for (_0x2ef8d9 = 0x0; _0x2ef8d9 < _0x2494f5; _0x2ef8d9++) {
            _0x263e0f = _0x263e0f << 0x1 | _0x208eab & 0x1, _0x44a38f == 0xf ? (_0x44a38f = 0x0, _0x5453eb += String['fromCharCode'](_0x263e0f), _0x263e0f = 0x0) : _0x44a38f++, _0x208eab = _0x208eab >> 0x1;
        }
        while (!![]) {
            _0x263e0f = _0x263e0f << 0x1;
            if (_0x44a38f == 0xf) {
                _0x5453eb += String['fromCharCode'](_0x263e0f);
                break;
            } else
                _0x44a38f++;
        }
        return _0x5453eb;
    }
    static #decompress(_0x5c92dd) {
        if (_0x5c92dd == null)
            return '';
        if (_0x5c92dd == '')
            return null;
        var _0xf9c155 = [], _0x54718f, _0x230569 = 0x4, _0x40e532 = 0x4, _0x8b9d81 = 0x3, _0x342a54 = '', _0x1c3eed = '', _0x3d65f0, _0x1f5275, _0x41a554, _0x2f099b, _0x20fb4a, _0xb90bb6, _0x4f75d9, _0x427a2e = {
                'string': _0x5c92dd,
                'val': _0x5c92dd['charCodeAt'](0x0),
                'position': 0x8000,
                'index': 0x1
            };
        for (_0x3d65f0 = 0x0; _0x3d65f0 < 0x3; _0x3d65f0 += 0x1) {
            _0xf9c155[_0x3d65f0] = _0x3d65f0;
        }
        _0x41a554 = 0x0, _0x20fb4a = Math['pow'](0x2, 0x2), _0xb90bb6 = 0x1;
        while (_0xb90bb6 != _0x20fb4a) {
            _0x2f099b = _0x427a2e['val'] & _0x427a2e['position'], _0x427a2e['position'] >>= 0x1, _0x427a2e['position'] == 0x0 && (_0x427a2e['position'] = 0x8000, _0x427a2e['val'] = _0x427a2e['string']['charCodeAt'](_0x427a2e['index']++)), _0x41a554 |= (_0x2f099b > 0x0 ? 0x1 : 0x0) * _0xb90bb6, _0xb90bb6 <<= 0x1;
        }
        switch (_0x54718f = _0x41a554) {
        case 0x0:
            _0x41a554 = 0x0, _0x20fb4a = Math['pow'](0x2, 0x8), _0xb90bb6 = 0x1;
            while (_0xb90bb6 != _0x20fb4a) {
                _0x2f099b = _0x427a2e['val'] & _0x427a2e['position'], _0x427a2e['position'] >>= 0x1, _0x427a2e['position'] == 0x0 && (_0x427a2e['position'] = 0x8000, _0x427a2e['val'] = _0x427a2e['string']['charCodeAt'](_0x427a2e['index']++)), _0x41a554 |= (_0x2f099b > 0x0 ? 0x1 : 0x0) * _0xb90bb6, _0xb90bb6 <<= 0x1;
            }
            _0x4f75d9 = String['fromCharCode'](_0x41a554);
            break;
        case 0x1:
            _0x41a554 = 0x0, _0x20fb4a = Math['pow'](0x2, 0x10), _0xb90bb6 = 0x1;
            while (_0xb90bb6 != _0x20fb4a) {
                _0x2f099b = _0x427a2e['val'] & _0x427a2e['position'], _0x427a2e['position'] >>= 0x1, _0x427a2e['position'] == 0x0 && (_0x427a2e['position'] = 0x8000, _0x427a2e['val'] = _0x427a2e['string']['charCodeAt'](_0x427a2e['index']++)), _0x41a554 |= (_0x2f099b > 0x0 ? 0x1 : 0x0) * _0xb90bb6, _0xb90bb6 <<= 0x1;
            }
            _0x4f75d9 = String['fromCharCode'](_0x41a554);
            break;
        case 0x2:
            return '';
        }
        _0xf9c155[0x3] = _0x4f75d9, _0x1f5275 = _0x1c3eed = _0x4f75d9;
        while (!![]) {
            if (_0x427a2e['index'] > _0x427a2e['string']['length'])
                return '';
            _0x41a554 = 0x0, _0x20fb4a = Math['pow'](0x2, _0x8b9d81), _0xb90bb6 = 0x1;
            while (_0xb90bb6 != _0x20fb4a) {
                _0x2f099b = _0x427a2e['val'] & _0x427a2e['position'], _0x427a2e['position'] >>= 0x1, _0x427a2e['position'] == 0x0 && (_0x427a2e['position'] = 0x8000, _0x427a2e['val'] = _0x427a2e['string']['charCodeAt'](_0x427a2e['index']++)), _0x41a554 |= (_0x2f099b > 0x0 ? 0x1 : 0x0) * _0xb90bb6, _0xb90bb6 <<= 0x1;
            }
            switch (_0x4f75d9 = _0x41a554) {
            case 0x0:
                _0x41a554 = 0x0, _0x20fb4a = Math['pow'](0x2, 0x8), _0xb90bb6 = 0x1;
                while (_0xb90bb6 != _0x20fb4a) {
                    _0x2f099b = _0x427a2e['val'] & _0x427a2e['position'], _0x427a2e['position'] >>= 0x1, _0x427a2e['position'] == 0x0 && (_0x427a2e['position'] = 0x8000, _0x427a2e['val'] = _0x427a2e['string']['charCodeAt'](_0x427a2e['index']++)), _0x41a554 |= (_0x2f099b > 0x0 ? 0x1 : 0x0) * _0xb90bb6, _0xb90bb6 <<= 0x1;
                }
                _0xf9c155[_0x40e532++] = String['fromCharCode'](_0x41a554), _0x4f75d9 = _0x40e532 - 0x1, _0x230569--;
                break;
            case 0x1:
                _0x41a554 = 0x0, _0x20fb4a = Math['pow'](0x2, 0x10), _0xb90bb6 = 0x1;
                while (_0xb90bb6 != _0x20fb4a) {
                    _0x2f099b = _0x427a2e['val'] & _0x427a2e['position'], _0x427a2e['position'] >>= 0x1, _0x427a2e['position'] == 0x0 && (_0x427a2e['position'] = 0x8000, _0x427a2e['val'] = _0x427a2e['string']['charCodeAt'](_0x427a2e['index']++)), _0x41a554 |= (_0x2f099b > 0x0 ? 0x1 : 0x0) * _0xb90bb6, _0xb90bb6 <<= 0x1;
                }
                _0xf9c155[_0x40e532++] = String['fromCharCode'](_0x41a554), _0x4f75d9 = _0x40e532 - 0x1, _0x230569--;
                break;
            case 0x2:
                return _0x1c3eed;
            }
            _0x230569 == 0x0 && (_0x230569 = Math['pow'](0x2, _0x8b9d81), _0x8b9d81++);
            if (_0xf9c155[_0x4f75d9])
                _0x342a54 = _0xf9c155[_0x4f75d9];
            else {
                if (_0x4f75d9 === _0x40e532)
                    _0x342a54 = _0x1f5275 + _0x1f5275['charAt'](0x0);
                else
                    return null;
            }
            _0x1c3eed += _0x342a54, _0xf9c155[_0x40e532++] = _0x1f5275 + _0x342a54['charAt'](0x0), _0x230569--, _0x1f5275 = _0x342a54, _0x230569 == 0x0 && (_0x230569 = Math['pow'](0x2, _0x8b9d81), _0x8b9d81++);
        }
    }
    static ['compressToBase64'](_0x5054e1) {
        if (_0x5054e1 == null)
            return '';
        var _0x2d072e = '', _0x39ac20, _0x3dc477, _0x52ed71, _0x52f653, _0x5792e6, _0x3caa5e, _0x3882df, _0x152087 = 0x0;
        _0x5054e1 = logi['maps']['Utils'].#compress(_0x5054e1);
        while (_0x152087 < _0x5054e1['length'] * 0x2) {
            if (_0x152087 % 0x2 == 0x0) {
                _0x39ac20 = _0x5054e1['charCodeAt'](_0x152087 / 0x2) >> 0x8, _0x3dc477 = _0x5054e1['charCodeAt'](_0x152087 / 0x2) & 0xff;
                _0x152087 / 0x2 + 0x1 < _0x5054e1['length'] ? _0x52ed71 = _0x5054e1['charCodeAt'](_0x152087 / 0x2 + 0x1) >> 0x8 : _0x52ed71 = NaN;
                ;
            } else
                _0x39ac20 = _0x5054e1['charCodeAt']((_0x152087 - 0x1) / 0x2) & 0xff, (_0x152087 + 0x1) / 0x2 < _0x5054e1['length'] ? (_0x3dc477 = _0x5054e1['charCodeAt']((_0x152087 + 0x1) / 0x2) >> 0x8, _0x52ed71 = _0x5054e1['charCodeAt']((_0x152087 + 0x1) / 0x2) & 0xff) : _0x3dc477 = _0x52ed71 = NaN;
            _0x152087 += 0x3, _0x52f653 = _0x39ac20 >> 0x2, _0x5792e6 = (_0x39ac20 & 0x3) << 0x4 | _0x3dc477 >> 0x4, _0x3caa5e = (_0x3dc477 & 0xf) << 0x2 | _0x52ed71 >> 0x6, _0x3882df = _0x52ed71 & 0x3f, isNaN(_0x3dc477) ? _0x3caa5e = _0x3882df = 0x40 : isNaN(_0x52ed71) && (_0x3882df = 0x40), _0x2d072e = _0x2d072e + logi['maps']['Utils'].#keyStr['charAt'](_0x52f653) + logi['maps']['Utils'].#keyStr['charAt'](_0x5792e6) + logi['maps']['Utils'].#keyStr['charAt'](_0x3caa5e) + logi['maps']['Utils'].#keyStr['charAt'](_0x3882df);
        }
        return _0x2d072e;
    }
    static ['decompressFromBase64'](_0x4b3da1) {
        if (_0x4b3da1 == null)
            return '';
        var _0x432012 = '', _0x4bf875 = 0x0, _0x1fc273, _0xcc711d, _0x5a130d, _0x142a8a, _0x6c0364, _0x3ad8c2, _0x2524a2, _0x350163, _0x453095 = 0x0;
        _0x4b3da1 = _0x4b3da1['replace'](/[^A-Za-z0-9\+\/\=]/g, '');
        while (_0x453095 < _0x4b3da1['length']) {
            _0x6c0364 = logi['maps']['Utils'].#keyStr['indexOf'](_0x4b3da1['charAt'](_0x453095++)), _0x3ad8c2 = logi['maps']['Utils'].#keyStr['indexOf'](_0x4b3da1['charAt'](_0x453095++)), _0x2524a2 = logi['maps']['Utils'].#keyStr['indexOf'](_0x4b3da1['charAt'](_0x453095++)), _0x350163 = logi['maps']['Utils'].#keyStr['indexOf'](_0x4b3da1['charAt'](_0x453095++)), _0xcc711d = _0x6c0364 << 0x2 | _0x3ad8c2 >> 0x4, _0x5a130d = (_0x3ad8c2 & 0xf) << 0x4 | _0x2524a2 >> 0x2, _0x142a8a = (_0x2524a2 & 0x3) << 0x6 | _0x350163, _0x4bf875 % 0x2 == 0x0 ? (_0x1fc273 = _0xcc711d << 0x8, _0x2524a2 != 0x40 && (_0x432012 += String['fromCharCode'](_0x1fc273 | _0x5a130d)), _0x350163 != 0x40 && (_0x1fc273 = _0x142a8a << 0x8)) : (_0x432012 = _0x432012 + String['fromCharCode'](_0x1fc273 | _0xcc711d), _0x2524a2 != 0x40 && (_0x1fc273 = _0x5a130d << 0x8), _0x350163 != 0x40 && (_0x432012 += String['fromCharCode'](_0x1fc273 | _0x142a8a))), _0x4bf875 += 0x3;
        }
        return logi['maps']['Utils'].#decompress(_0x432012);
    }
    static ['compressToUTF16'](_0x2f2501) {
        if (_0x2f2501 == null)
            return '';
        var _0x3305b2 = '', _0x32abef, _0x533c48, _0xd623a1, _0x25c614 = 0x0;
        _0x2f2501 = logi['maps']['Utils'].#compress(_0x2f2501);
        for (_0x32abef = 0x0; _0x32abef < _0x2f2501['length']; _0x32abef++) {
            _0x533c48 = _0x2f2501['charCodeAt'](_0x32abef);
            switch (_0x25c614++) {
            case 0x0:
                _0x3305b2 += String['fromCharCode']((_0x533c48 >> 0x1) + 0x20), _0xd623a1 = (_0x533c48 & 0x1) << 0xe;
                break;
            case 0x1:
                _0x3305b2 += String['fromCharCode'](_0xd623a1 + (_0x533c48 >> 0x2) + 0x20), _0xd623a1 = (_0x533c48 & 0x3) << 0xd;
                break;
            case 0x2:
                _0x3305b2 += String['fromCharCode'](_0xd623a1 + (_0x533c48 >> 0x3) + 0x20), _0xd623a1 = (_0x533c48 & 0x7) << 0xc;
                break;
            case 0x3:
                _0x3305b2 += String['fromCharCode'](_0xd623a1 + (_0x533c48 >> 0x4) + 0x20), _0xd623a1 = (_0x533c48 & 0xf) << 0xb;
                break;
            case 0x4:
                _0x3305b2 += String['fromCharCode'](_0xd623a1 + (_0x533c48 >> 0x5) + 0x20), _0xd623a1 = (_0x533c48 & 0x1f) << 0xa;
                break;
            case 0x5:
                _0x3305b2 += String['fromCharCode'](_0xd623a1 + (_0x533c48 >> 0x6) + 0x20), _0xd623a1 = (_0x533c48 & 0x3f) << 0x9;
                break;
            case 0x6:
                _0x3305b2 += String['fromCharCode'](_0xd623a1 + (_0x533c48 >> 0x7) + 0x20), _0xd623a1 = (_0x533c48 & 0x7f) << 0x8;
                break;
            case 0x7:
                _0x3305b2 += String['fromCharCode'](_0xd623a1 + (_0x533c48 >> 0x8) + 0x20), _0xd623a1 = (_0x533c48 & 0xff) << 0x7;
                break;
            case 0x8:
                _0x3305b2 += String['fromCharCode'](_0xd623a1 + (_0x533c48 >> 0x9) + 0x20), _0xd623a1 = (_0x533c48 & 0x1ff) << 0x6;
                break;
            case 0x9:
                _0x3305b2 += String['fromCharCode'](_0xd623a1 + (_0x533c48 >> 0xa) + 0x20), _0xd623a1 = (_0x533c48 & 0x3ff) << 0x5;
                break;
            case 0xa:
                _0x3305b2 += String['fromCharCode'](_0xd623a1 + (_0x533c48 >> 0xb) + 0x20), _0xd623a1 = (_0x533c48 & 0x7ff) << 0x4;
                break;
            case 0xb:
                _0x3305b2 += String['fromCharCode'](_0xd623a1 + (_0x533c48 >> 0xc) + 0x20), _0xd623a1 = (_0x533c48 & 0xfff) << 0x3;
                break;
            case 0xc:
                _0x3305b2 += String['fromCharCode'](_0xd623a1 + (_0x533c48 >> 0xd) + 0x20), _0xd623a1 = (_0x533c48 & 0x1fff) << 0x2;
                break;
            case 0xd:
                _0x3305b2 += String['fromCharCode'](_0xd623a1 + (_0x533c48 >> 0xe) + 0x20), _0xd623a1 = (_0x533c48 & 0x3fff) << 0x1;
                break;
            case 0xe:
                _0x3305b2 += String['fromCharCode'](_0xd623a1 + (_0x533c48 >> 0xf) + 0x20, (_0x533c48 & 0x7fff) + 0x20), _0x25c614 = 0x0;
                break;
            }
        }
        return _0x3305b2 + String['fromCharCode'](_0xd623a1 + 0x20);
    }
    static ['decompressFromUTF16'](_0x5bcb13) {
        if (_0x5bcb13 == null)
            return '';
        var _0x810d5e = '', _0x5c1f17, _0x241ff0, _0x37e124 = 0x0, _0x5f7de6 = 0x0;
        while (_0x5f7de6 < _0x5bcb13['length']) {
            _0x241ff0 = _0x5bcb13['charCodeAt'](_0x5f7de6) - 0x20;
            switch (_0x37e124++) {
            case 0x0:
                _0x5c1f17 = _0x241ff0 << 0x1;
                break;
            case 0x1:
                _0x810d5e += String['fromCharCode'](_0x5c1f17 | _0x241ff0 >> 0xe), _0x5c1f17 = (_0x241ff0 & 0x3fff) << 0x2;
                break;
            case 0x2:
                _0x810d5e += String['fromCharCode'](_0x5c1f17 | _0x241ff0 >> 0xd), _0x5c1f17 = (_0x241ff0 & 0x1fff) << 0x3;
                break;
            case 0x3:
                _0x810d5e += String['fromCharCode'](_0x5c1f17 | _0x241ff0 >> 0xc), _0x5c1f17 = (_0x241ff0 & 0xfff) << 0x4;
                break;
            case 0x4:
                _0x810d5e += String['fromCharCode'](_0x5c1f17 | _0x241ff0 >> 0xb), _0x5c1f17 = (_0x241ff0 & 0x7ff) << 0x5;
                break;
            case 0x5:
                _0x810d5e += String['fromCharCode'](_0x5c1f17 | _0x241ff0 >> 0xa), _0x5c1f17 = (_0x241ff0 & 0x3ff) << 0x6;
                break;
            case 0x6:
                _0x810d5e += String['fromCharCode'](_0x5c1f17 | _0x241ff0 >> 0x9), _0x5c1f17 = (_0x241ff0 & 0x1ff) << 0x7;
                break;
            case 0x7:
                _0x810d5e += String['fromCharCode'](_0x5c1f17 | _0x241ff0 >> 0x8), _0x5c1f17 = (_0x241ff0 & 0xff) << 0x8;
                break;
            case 0x8:
                _0x810d5e += String['fromCharCode'](_0x5c1f17 | _0x241ff0 >> 0x7), _0x5c1f17 = (_0x241ff0 & 0x7f) << 0x9;
                break;
            case 0x9:
                _0x810d5e += String['fromCharCode'](_0x5c1f17 | _0x241ff0 >> 0x6), _0x5c1f17 = (_0x241ff0 & 0x3f) << 0xa;
                break;
            case 0xa:
                _0x810d5e += String['fromCharCode'](_0x5c1f17 | _0x241ff0 >> 0x5), _0x5c1f17 = (_0x241ff0 & 0x1f) << 0xb;
                break;
            case 0xb:
                _0x810d5e += String['fromCharCode'](_0x5c1f17 | _0x241ff0 >> 0x4), _0x5c1f17 = (_0x241ff0 & 0xf) << 0xc;
                break;
            case 0xc:
                _0x810d5e += String['fromCharCode'](_0x5c1f17 | _0x241ff0 >> 0x3), _0x5c1f17 = (_0x241ff0 & 0x7) << 0xd;
                break;
            case 0xd:
                _0x810d5e += String['fromCharCode'](_0x5c1f17 | _0x241ff0 >> 0x2), _0x5c1f17 = (_0x241ff0 & 0x3) << 0xe;
                break;
            case 0xe:
                _0x810d5e += String['fromCharCode'](_0x5c1f17 | _0x241ff0 >> 0x1), _0x5c1f17 = (_0x241ff0 & 0x1) << 0xf;
                break;
            case 0xf:
                _0x810d5e += String['fromCharCode'](_0x5c1f17 | _0x241ff0), _0x37e124 = 0x0;
                break;
            }
            _0x5f7de6++;
        }
        return logi['maps']['Utils'].#decompress(_0x810d5e);
    }
};
export default logi['maps']['Utils'];