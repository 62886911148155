import axios from 'axios'

class GradeService {
  private apiUrl: string
  constructor() {
    this.apiUrl = '/grade'
  }

  // 통계 > 회원 평가  목록 조회
  getGradeList(params) {
    return axios.post(this.apiUrl + `/list`,{},{ params }).then((res) => res.data)
  }
}

export default new GradeService()
