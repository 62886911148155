import axios from 'axios'

class DataDownloadService {
  private apiUrl: string

  constructor() {
    this.apiUrl = '/data-download'
  }

  /**
   * 현재 업데이트 상태 체크
   *"willUpdate" => 다운로드 받을 데이터 있음.    다운로드 버튼 활성화
   *"none" => 업데이트 완료 또는 업데이트할 데이터 없음
   *"updating" => 현재 업데이트 중
   */
  checkingDataDownload() {
    return axios
      .get(this.apiUrl + '/check')
      .then((res) => res.data)
  }

  /**
  * 다운로드 실행  ( status : "willUpdate"  일때만 )
   */
  runDataDownload() {
    return axios
      .get(this.apiUrl + '/run')
      .then((res) => res.data)
  }

  reset(){
    return axios
      .get(this.apiUrl + '/reset')
      .then((res) => res.data)
  }
}

export default new DataDownloadService()
