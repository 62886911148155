var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    { attrs: { cols: "12", sm: "12" } },
    [
      _c(
        "v-row",
        _vm._l(
          [[_vm.requiredInfo, _vm.choiceInfo, _vm.cardInfo]],
          function (arr, key) {
            return _c(
              "v-col",
              {
                key: "__" + key,
                staticStyle: { padding: "30px 36px 10px 36px !important" },
                attrs: { cols: "12", md: "12", sm: "12" },
              },
              _vm._l(arr, function (info, i) {
                return _c(
                  "v-card",
                  {
                    key: "info_" + key + "_" + i,
                    staticClass: "pa-0 elevation-0",
                  },
                  _vm._l(info, function (item, index) {
                    return item.show
                      ? _c(
                          "v-card",
                          {
                            key: index,
                            staticClass: "terms-history-window elevation-0",
                          },
                          [
                            _c(
                              "v-card-title",
                              {
                                key: "title_" + key + "_" + i + "_" + index,
                                staticClass:
                                  "px-0 pt-0 pb-6 font-size18 surface800 font-weight700",
                              },
                              [_vm._v(" " + _vm._s(item.title) + " ")]
                            ),
                            _c(
                              "v-card-text",
                              { staticClass: "px-0 pt-0 pb-0" },
                              _vm._l(item.data, function (_item, idx) {
                                return _item.show
                                  ? _c("terms-info-item", {
                                      key:
                                        "data_" +
                                        key +
                                        "_" +
                                        i +
                                        "_" +
                                        index +
                                        "_" +
                                        idx,
                                      attrs: {
                                        title: _item.title,
                                        content: _item.content,
                                        isBtn: _item.btnShow,
                                        "terms-type": _item.termsType,
                                        "user-id": _vm.id,
                                        type: _vm.type,
                                      },
                                      on: {
                                        updateUserInfo: _vm.updateUserInfo,
                                        showHistory: _vm.showHistory,
                                      },
                                    })
                                  : _vm._e()
                              }),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e()
                  }),
                  1
                )
              }),
              1
            )
          }
        ),
        1
      ),
      _c(
        "v-dialog",
        {
          key: _vm.componentKey.termsHistoryPopupKey,
          staticClass: "v-dialog",
          attrs: { "max-width": "551", persistent: "" },
          model: {
            value: _vm.termsHistoryPopup,
            callback: function ($$v) {
              _vm.termsHistoryPopup = $$v
            },
            expression: "termsHistoryPopup",
          },
        },
        [
          _c("terms-history-popup", {
            attrs: {
              type: _vm.type,
              "terms-history-data": _vm.userTermHistoryData,
            },
            on: { historyPopupHide: _vm.historyPopupHide },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }