import { render, staticRenderFns } from "./calculate.vue?vue&type=template&id=1bf05116&scoped=true&"
import script from "./calculate.vue?vue&type=script&lang=js&"
export * from "./calculate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1bf05116",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/work/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1bf05116')) {
      api.createRecord('1bf05116', component.options)
    } else {
      api.reload('1bf05116', component.options)
    }
    module.hot.accept("./calculate.vue?vue&type=template&id=1bf05116&scoped=true&", function () {
      api.rerender('1bf05116', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/icons/leftPanel/calculate.vue"
export default component.exports