








































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import Pagination from '@/views/utils/Pagination.vue'
import { mdiMagnify, mdiChevronDown, mdiMapCheckOutline } from '@mdi/js'
import { State, Mutation } from 'vuex-class'
import driverService from '@/services-itgo/driver.service'
import USER_STATUS from '@/views/constant/user.status'
import LocationCargoAround from '@/views/popup/480X280/LocationsCargoAroundPopup.vue'

@Component({
  components: {
    Pagination,
    LocationCargoAround,
  },
  created() {
    this.$router.beforeEach((from, to, next) => {
      if (from.name === 'driverInfo' && to.name === 'driverManagement') {
        this.setIsManageFilterStatus({ type: 'driverMng', data: true })
      }
      next()
    })
  },
  mounted() {
    if (this.isDriverMng) {
      this.keyword = this.filterData.keyword
      this.pageNum = this.filterData.pageNum
      this.pageSize = this.filterData.pageSize
      this.setIsManageFilterStatus({ type: 'driverMng', data: false })
    }
    this.fetchDriverList()
  },
})
export default class DriverManagement extends Vue {
  @State((state) => state.auth.user) loginUser
  @State((state) => state.pageFilter.status.isDriverMng) isDriverMng
  @State((state) => state.pageFilter.filter.driverMngFilter) filterData
  @Mutation('pageFilter/setIsManageFilterStatus') setIsManageFilterStatus
  @Mutation('pageFilter/setManageFilterData') setManageFilterData

  icons: any = {
    mdiMagnify,
    mdiChevronDown,
    mdiMapCheckOutline,
  }

  keyword = ''

  driverList = {
    loading: false,
    headers: [
      { text: '차주명', value: 'driverName', align: 'center', width: '8%' },
      { text: '전화번호', value: 'mobile', align: 'center', width: '9%' },
      { text: 'ID', value: 'loginId', align: 'center', width: '8%' },
      {
        text: '차량 번호',
        value: 'registrationNumber',
        align: 'center',
        width: '8%',
      },
      {
        text: '차량 정보',
        value: 'vehicleInfo',
        align: 'center',
        width: '7%',
      },
      {
        text: '추천 주선사',
        value: 'recommendName',
        align: 'center',
        width: '7%',
      },
      { text: '상태', value: 'usetStatus', align: 'center', width: '4%' },
      { text: '평점', value: 'grade', align: 'center', width: '10%' },
      {
        text: '공차 위치',
        value: '',
        align: 'center',
        width: '10%',
      },
    ],
    items: [],
    serverItemsLength: 0,
  }

  get totalPageCount() {
    const length = Math.ceil(this.driverList.serverItemsLength / this.pageSize)
    return length === 0 ? 1 : length
  }

  pageSizes = [10, 20, 30]
  pageSize = 10
  pageNum = 1

  /**
   * pageSize: 페이지 크기
   * pageNum: 페이지 번호
   */
  @Watch('pageSize')
  onChangePageSize() {
    this.pageNum = 1
    this.fetchDriverList()
  }

  @Watch('pageNum')
  onChangePageNum() {
    this.fetchDriverList()
  }

  async fetchDriverList() {
    try {
      this.driverList.loading = true
      this.driverList.items = []

      const params = {
        keyword: this.keyword,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      }

      this.setManageFilterData({ type: 'driverMng', data: params })

      const data = await driverService.getDriverList(params)

      this.driverList.items = data.driverList.map((item, index) => ({
        ...item,
        vehicleInfo: `${item.weight ? `${item.weight}톤` : '-'}/${
          item.vehicleType || '-'
        }`,
        usetStatus: USER_STATUS.GET_USER_STATUS(item.status),
        key: `${item.name}_${item.mobile}_${index}`,
        driverId: item.driverId,
        isNearDistance: item.isNearDistance,
      }))

      this.driverList.serverItemsLength = data.totalCount
    } catch (error: any) {
      this.$alert(error.response.data.errMsg)
    } finally {
      this.driverList.loading = false
    }
  }

  onSearch() {
    this.pageNum = 1
    this.fetchDriverList()
  }

  onKeyupKeyword() {
    if (this.validateName(this.keyword)) return

    this.keyword = this.keyword
      .replace(/[^ㄱ-ㅎ가-힣0-9]/g, '')
      .replace(/\s/g, '')
  }

  validateName(value: string) {
    const regExp = /^[ㄱ-ㅎ|가-힣|0-9]+$/

    return regExp.test(value)
  }

  onClickRow(item) {
    this.$router.push({
      name: 'driverInfo',
      params: { item },
    })
  }

  // 내 주변 화물 위치
  aroundDriverId = null
  openLocationCargoAroundPopup = false

  onLocationsCargoAroundPopup(driverId) {
    this.aroundDriverId = driverId
    this.openLocationCargoAroundPopup = true
  }
}
