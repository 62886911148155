var logi = logi ?? {};
logi.maps = logi.maps ?? {};

//맵타일 서버 주소
logi.maps.Config = {
  //맵 서버
  server_url : process.env.VUE_APP_MAP_TILE_SERVER_URL ||"https://itgo-map-tile2.logisteq.com",

  //DEV.QA 맵 서버
  dev_server_url : process.env.VUE_APP_MAP_TILE_SERVER_URL ||"https://itgo-map-tile2.logisteq.com",

  //PROD 맵 서버
  prod_server_url : process.env.VUE_APP_MAP_TILE_SERVER_URL ||"https://itgo-map-tile2.logisteq.com",

  //API 버전
  api_version : "2.0.1.21"
}

///**javascript_module**
export default logi.maps.Config;
//**javascript_module**/
