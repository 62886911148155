
















import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import PAYMENT_METHOD from '@/views/constant/freight.payment.type.constant'

@Component({
  name: 'FreightPaymentType',
})
export default class FreightPaymentType extends Vue {
  @Prop(Number) value
  payment = null
  paymentItems = [
    {
      text: PAYMENT_METHOD.TOTAL_STRING,
      value: PAYMENT_METHOD.TOTAL,
    },
    // {
    //   text: PAYMENT_METHOD.PREPAYMENT_STRING,
    //   value: PAYMENT_METHOD.PREPAYMENT,
    // },
    // {
    //   text: PAYMENT_METHOD.DEFERRED_STRING,
    //   value: PAYMENT_METHOD.DEFERRED,
    // },

    {
      text: PAYMENT_METHOD.INVOICE_STRING,
      value: PAYMENT_METHOD.INVOICE,
    },
    {
      text: PAYMENT_METHOD.CARD_STRING,
      value: PAYMENT_METHOD.CARD,
    },
    // {
    //   text: PAYMENT_METHOD.VIRTUAL_ACCOUNT_STRING,
    //   value: PAYMENT_METHOD.VIRTUAL_ACCOUNT,
    // },
  ]

  @Watch('value')
  onChangeValue(value){
    this.payment = value 
  }

  changeItem(value) {
    console.log(value)
    this.$emit('setInputPaymentItem', value)
  }
}
