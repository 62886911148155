



















































































import { Vue, Component } from 'vue-property-decorator'
import { mdiChevronLeft } from '@mdi/js'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import FreightInfoItem from '@/views/freightHistory/FreightInfoItem.vue'
import UserInfoTab from '@/views/management/UserInfoTab.vue'
import FreightHistoryTab from '@/views/management/FreightHistoryTab.vue'
import ServiceFeeTab from '@/views/management/ServiceFeeTab.vue'
import UserStatusTab from '@/views/management/UserStatusTab.vue'
import Freight from '@/views/icons/leftPanel/freight.vue'
import USER_STATUS from '@/views/constant/user.status'
import TermsConditionsHistoryTab from '@/views/management/TermsConditionsHistoryTab.vue'

@Component({
  components: {
    TermsConditionsHistoryTab,
    Freight,
    PerfectScrollbar,
    FreightInfoItem,
    UserInfoTab,
    FreightHistoryTab,
    ServiceFeeTab,
    UserStatusTab,
  },

  mounted() {
    if (!this.$route.params.item) return
    if (this.$route.params.backPath === 'freightInfo') return (this.tab = 1)
  },
})
export default class BrokerInfo extends Vue {
  icons = {
    mdiChevronLeft,
  }

  type = USER_STATUS.USER_TYPE_BROKER

  get item(): any {
    return this.$route.params.item || {}
  }

  get id() {
    return this.item?.userId || 0
  }

  get userStatus() {
    return this.item?.status || 0
  }

  get companyId() {
    console.log(this.item)
    return this.item?.companyId || ''
  }

  get userId() {
    return this.item?.userId || ''
  }

  get companyName() {
    return this.item?.companyName || ''
  }

  get name() {
    return this.item?.name || ''
  }

  get grade() {
    return this.item?.grade || ''
  }

  get count() {
    return this.item?.count || 0
  }

  get isBroker() {
    return Boolean(Object.keys(this.item).find((key) => key === 'brokerId'))
  }

  tab = null
  get tabItems() {
    return this.isBroker
      ? [
          { title: '회원 정보' },
          { title: '운송 이력' },
          { title: '회원 상태' },
          { title: '약관 이력' },
        ]
      : [{ title: '회원 정보' }, { title: '회원 상태' }]
  }
}
