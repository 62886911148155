var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: _vm.width,
        height: _vm.height,
        viewBox: _vm.viewBox,
      },
    },
    [
      _c("defs", [
        _c("clipPath", { attrs: { id: "clip-path" } }, [
          _c("rect", {
            attrs: {
              id: "사각형_31239",
              "data-name": "사각형 31239",
              width: "21",
              height: "21",
              transform: "translate(0.5 -0.5)",
              fill: _vm.iconColor,
              stroke: _vm.iconColor,
              "stroke-width": "0.2",
            },
          }),
        ]),
      ]),
      _c(
        "g",
        {
          attrs: {
            id: "그룹_40432",
            "data-name": "그룹 40432",
            transform: "translate(-341 -249)",
          },
        },
        [
          _c(
            "g",
            {
              attrs: {
                id: "그룹_40375",
                "data-name": "그룹 40375",
                transform: "translate(344.5 252.5)",
              },
            },
            [
              _c(
                "g",
                { attrs: { id: "그룹_40374", "data-name": "그룹 40374" } },
                [
                  _c(
                    "g",
                    {
                      attrs: {
                        id: "그룹_40373",
                        "data-name": "그룹 40373",
                        "clip-path": "url(#clip-path)",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          id: "패스_29406",
                          "data-name": "패스 29406",
                          d: "M18.583,0H2.065A2.03,2.03,0,0,0,.609.609,2.03,2.03,0,0,0,0,2.065V14.453a2.03,2.03,0,0,0,.609,1.456,2.03,2.03,0,0,0,1.456.609h4.13l4.13,4.13,4.13-4.13h4.13a2.03,2.03,0,0,0,1.456-.609,2.091,2.091,0,0,0,.609-1.456V2.065A2.03,2.03,0,0,0,20.039.609,2.03,2.03,0,0,0,18.583,0M2.065,14.453V2.065H18.583V14.453H13.6l-3.273,3.273L7.051,14.453",
                          transform: "translate(0.676 -0.324)",
                          fill: _vm.iconColor,
                          stroke: _vm.iconColor,
                          "stroke-linejoin": "round",
                          "stroke-width": "0.2",
                        },
                      }),
                    ]
                  ),
                ]
              ),
              _c("path", {
                attrs: {
                  id: "패스_29407",
                  "data-name": "패스 29407",
                  d: "M2.175,11.179H3.761c-.186-1.747,1.97-2.341,1.97-4.1A2.276,2.276,0,0,0,3.154,4.724a3.428,3.428,0,0,0-2.6,1.239L1.593,7.019a1.911,1.911,0,0,1,1.375-.694c.644,0,1.016.257,1.016.876C3.984,8.379,1.878,9.184,2.175,11.179Zm.793,3.3A1.191,1.191,0,1,0,1.84,13.285,1.141,1.141,0,0,0,2.968,14.474Z",
                  transform: "translate(7.859 -0.879)",
                  fill: _vm.iconColor,
                },
              }),
            ]
          ),
          _c("rect", {
            attrs: {
              id: "사각형_31240",
              "data-name": "사각형 31240",
              width: "28",
              height: "28",
              transform: "translate(341 249)",
              fill: "none",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }