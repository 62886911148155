var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-app", [
    _c("div", { staticClass: "service-check" }, [
      _c("div", { staticClass: "service-wrapper" }, [
        _c(
          "div",
          { staticClass: "service-inner" },
          [
            _c(
              "v-btn",
              { attrs: { icon: "" }, on: { click: _vm.reload } },
              [_c("logo-large")],
              1
            ),
            _c("div", [
              _c("h2", [_vm._v("시스템 점검 중 입니다.")]),
              _c("p", [
                _vm._v(" 서비스 이용에 불편을 드린 점 양해 부탁 드립니다. "),
                _c("br"),
                _vm._v(
                  " 시스템 안정화를 위한 점검을 진행중이며, 아래 점검기간 동안 서비스를 이용하실 수 없습니다. "
                ),
                _c("br"),
                _vm._v(" 점검 후 더욱 좋은 모습으로 찾아 뵙겠습니다. "),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "service-info" },
              [
                _c(
                  "v-card-title",
                  [
                    _c("v-card-text", [_vm._v("점검 시간")]),
                    _c("v-card-text", [_vm._v(_vm._s(_vm.serviceTimeString))]),
                  ],
                  1
                ),
                _c(
                  "v-card-title",
                  [
                    _c("v-card-text", [_vm._v("점검 이유")]),
                    _c("v-card-text", [_vm._v("시스템 점검 작업")]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }