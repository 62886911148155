<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="viewBox"
  >
    <g id="그룹_40430" data-name="그룹 40430" transform="translate(-45 -492)">
      <g id="payment" transform="translate(48.571 498.134)">
        <path
          id="패스_29329"
          data-name="패스 29329"
          d="M13.907,13.272H5V12h8.907Z"
          transform="translate(-1.328 -4.365)"
          :fill="iconColor"
          :stroke="iconColor"
          stroke-width="0.5"
          fill-rule="evenodd"
        />
        <path
          id="패스_29330"
          data-name="패스 29330"
          d="M11.362,15.272H5V14h6.362Z"
          transform="translate(-1.328 -3.184)"
          :fill="iconColor"
          :stroke="iconColor"
          stroke-width="0.5"
          fill-rule="evenodd"
        />
        <path
          id="패스_29331"
          data-name="패스 29331"
          d="M3,7.311A1.242,1.242,0,0,1,4.159,6H22.7a1.242,1.242,0,0,1,1.159,1.311v13.11A1.242,1.242,0,0,1,22.7,21.732H4.159A1.242,1.242,0,0,1,3,20.421Zm1.159,0H22.7V9.933H4.159ZM22.7,11.244v9.177H4.159V11.244Z"
          transform="translate(-3 -6)"
          :fill="iconColor"
          :stroke="iconColor"
          stroke-width="0.5"
          fill-rule="evenodd"
        />
      </g>
      <rect
        id="사각형_31206"
        data-name="사각형 31206"
        width="28"
        height="28"
        transform="translate(45 492)"
        fill="none"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'card',

  props: {
    viewBox: { type: String, default: '0 0 28 28' },
    iconName: { type: String, default: '' },
    width: { type: [Number, String], default: 28 },
    height: { type: [Number, String], default: 28 },
    iconColor: { type: String, default: '#ffffff' },
  },
}
</script>

<style scoped></style>
