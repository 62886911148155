import axios from 'axios'

import store from '../store'
import FcmService from '@/services-itgo/fcm.service'
import AdminService from '@/services-itgo/admin.service'


class AuthService {
  private refreshTokenScheduler: any
  private autoLogoutScheduler: any
  private apiUrl: string
  private BASIC_AUTH_STR: string
  private headers: { Authorization: string; 'content-type': string }
  private _store: any
  private AXIOS_BASIC_KEY: any

  constructor() {
    this.refreshTokenScheduler = null
    this.apiUrl = '/auth/token'
    this.AXIOS_BASIC_KEY = process.env.VUE_APP_AXIOS_BASIC_KEY

    this.BASIC_AUTH_STR =
      'Basic ' + btoa('web_monitor' + ':' + this.AXIOS_BASIC_KEY)
    this.headers = {
      Authorization: this.BASIC_AUTH_STR,
      'content-type': 'application/x-www-form-urlencoded',
    }
    this._store = store
  }

  /**
   * 로그인
   * @param user
   *
   * userType: 사용자 유형 (1:화주앱, 2:차주앱, 3:주선사웹, 4: 관제웹)
   */
  signcode(user) {
    const body = {
      loginId: user.loginId,
      password: user.password,
    }

    return axios.post('/auth/code?userType=4', body).then((res) => {
      return res.data
    })
  }

  signin(user) {
    const params = new URLSearchParams()
    params.append('grant_type', 'password')
    params.append('scope', 'read write')
    params.append('username', user.loginId)
    params.append('password', user.password)

    // API 호출
    return axios
      .post(this.apiUrl, params, {headers: this.headers})
      .then(this.handleResponse)
  }

  //logoutType  ==> 0 사용자가 로그 아웃 / 1 자동 로그 아웃 / 2 중복 로그 아웃 / 3 강제 로그 아웃
  async signout(logoutType) {
    try {
      await FcmService.deleteFCMToken()
      if (logoutType === 0 || logoutType === 1) {
        await AdminService.signout()
      }
      clearInterval(this.refreshTokenScheduler)
      delete axios.defaults.headers.common['Authorization']

      if (logoutType === 0 || logoutType === 2) location.reload()
    } catch (e) {
    } finally {
    }
  }

  signup(info) {
    return axios.post('/admin/signup', info).then((res) => {
      return res.data
    })
  }

  getRefreshToken(rTkn) {
    const params = new URLSearchParams()
    params.append('grant_type', 'refresh_token')
    params.append('refresh_token', rTkn)

    // API 호출
    return axios
      .post(this.apiUrl, params, {headers: this.headers})
      .then(this.handleResponse)
  }

  /**
   * 토큰 갱신 스케줄러 시작
   * (50분 주기)
   */
  startRefreshTokenScheduler() {
    if (this.refreshTokenScheduler) {
      // TODO : 정책에 따라 강제로 스케줄 시작해야 할수도 있음.
      return
    }

    console.log(`토큰 갱신 스케줄러 시작`)

    this.refreshTokenScheduler = setInterval(() => {
      //const token = this._store.state.auth.token
      const refreshToken = window.sessionStorage.getItem('refreshToken')
      if (refreshToken) {
        this._store.dispatch('auth/refresh', refreshToken).then(
          () => {
            console.log(`접근키 갱신 성공!!!!`)
          },
          (err) => {
            console.error(`접근키 갱신 실패!!!!! - ${JSON.stringify(err)}`)
          },
        )
      }
    }, 1000 * 60 * 50) // 50분
  }

  handleResponse(response) {
    if (response.status !== 200) {
      const error = response.data && response.data.message
      return Promise.reject(error)
    }

    const token = response.data.access_token
    if (token) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
    }
    return Promise.resolve(response.data)
  }

  // businessCertification(bizNo) {
  //   return axios
  //     .post(`/admin/business-certification`, {
  //       bizNo: bizNo,
  //     })
  //     .then((response) => {
  //       return response.data
  //     })
  // }

  // userResetPasswordCode(mobile) {
  //   const body = {
  //     mobile: mobile,
  //   }
  //   return axios.post(`/auth/code/user-reset-password`, body).then((res) => {
  //     return res.data
  //   })
  // }

  // validateAuthCode(mobile, authCode) {
  //   const body = {
  //     mobile: mobile,
  //     authCode: authCode,
  //   }
  //   return axios.post(`/auth/code/verification`, body).then((res) => {
  //     return res.data
  //   })
  // }


  /**
   * 자동 로그 아웃 스케출
   * (20분 주기)
   */
  // startAutoLogoutScheduler() {
  //   console.log(`자동 로그 아웃 스케줄러 시작`)
  //
  //   this.autoLogoutScheduler = setTimeout(() => {
  //     console.log('여기로 로그 아웃 기능 추가하면 되지 않을까요?')
  //     this._store.dispatch('auth/getAutoLogOutFlag', true)
  //   }, 1000 * 60 * 20) // 20분
  // }
  //
  // resetLogoutScheduler() {
  //   console.log('자동 로그 아웃 초기화')
  //   clearTimeout(this.autoLogoutScheduler)
  //   this._store.dispatch('auth/getAutoLogOutFlag', false)
  // }

  startAutoLogoutListenerScheduler() {
    const refreshToken = window.sessionStorage.getItem('refreshToken')
    if (refreshToken) {
      if (this.autoLogoutScheduler) clearTimeout(this.autoLogoutScheduler)
      this.autoLogoutScheduler = setTimeout(() => {
        console.log('여기로 로그 아웃 기능 추가하면 되지 않을까요?')
        this._store.dispatch('auth/getAutoLogOutFlag', true)
      }, 1000 * 60 * 20) // 20분
    }
  }

  resetTimer() {
    if (this.autoLogoutScheduler) clearTimeout(this.autoLogoutScheduler)
  }
}

export default new AuthService()
