import { render, staticRenderFns } from "./TermsEditPopup.vue?vue&type=template&id=05a39e3d&"
import script from "./TermsEditPopup.vue?vue&type=script&lang=ts&"
export * from "./TermsEditPopup.vue?vue&type=script&lang=ts&"
import style0 from "./TermsEditPopup.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCardTitle,VCheckbox,VDivider,VIcon,VSpacer,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/work/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('05a39e3d')) {
      api.createRecord('05a39e3d', component.options)
    } else {
      api.reload('05a39e3d', component.options)
    }
    module.hot.accept("./TermsEditPopup.vue?vue&type=template&id=05a39e3d&", function () {
      api.rerender('05a39e3d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/management/TermsEditPopup.vue"
export default component.exports