import axios from 'axios'

class SubscribersService {
  private apiUrl: string
  constructor() {
    this.apiUrl = '/user-status'
  }

  /**
   * 회원별 가입/탈퇴자 수 날짜별 목록 조회
   */
  getUserCount(params) {
    return axios.get(this.apiUrl + '/count', { params }).then((res) => res.data)
  }

  /**
   * 엑셀 다운로드
   */
  getUserExcel(params) {
    return axios
      .get(this.apiUrl + '/count-excel', {
        params: params,
        responseType: 'blob',
      })
      .then((res) => res.data)
  }
}

export default new SubscribersService()
