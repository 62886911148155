









import { Vue, Component } from 'vue-property-decorator'

// SVG import
import ColorLogo from '@/views/icons/logo/ColorLogo.vue'

@Component({
  components: {
    ColorLogo,
  },
})
export default class NotFound extends Vue {}
