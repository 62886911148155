import numeral from 'numeral'
import moment from 'moment'

const DEFAULT_ELLIPSIS_LENGTH = 2
const DEFAULT_FIXED_COUNT = 2
const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD HH:mm'

/**
 * 주소 맨 뒤 세 자리 ... 표시
 * TODO: 기획 픽스 후 재확인 필요
 */
export function ellipsisEnd(value: string, length = DEFAULT_ELLIPSIS_LENGTH) {
  if (value.length <= length) return value

  const showText = value.substring(0, length)

  return `${showText}...`
}

/**
 * 사업자번호
 */
export function formatBizno(value: string) {
  if (!value) return '-'

  if(value.length !== 10) return value

  const first = value.slice(0, 3)
  const middle = value.slice(3, 5)
  const end = value.slice(5)

  return [first, middle, end].join('-')
}

/**
 * 핸드폰번호
 */
export function formatPhoneNumber(value: string, isMasking = false) {
  if (!value) return '-'

  const _value = value.replaceAll(/[^0-9]/g, '')

  const first = _value.slice(0, 3)
  const middle = _value.slice(3, -4)
  const end = _value.slice(-4)

  return isMasking
    ? [first, '*'.repeat(middle.length), end].join('-')
    : [first, middle, end].join('-')
}

/**
 * 전화번호 / 휴대전화
 */
export function formatPhoneNumberIntegrated(value: any, isMasking = false) {
  if (!value) {
    return '-'
  }

  const _value = value.replaceAll(/[^0-9]/g, '')
  let phoneNumber = ''

  try{
    if (_value.length == 11) {
      phoneNumber = _value.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
    } else if (_value.length == 8) {
      phoneNumber = _value.replace(/(\d{4})(\d{4})/, '$1-$2');
    } else {
      if (_value.indexOf('02') == 0) {
        phoneNumber = _value.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3');
      } else {
        phoneNumber = _value.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
      }
    }
  } catch(e) {
    phoneNumber = _value;
  }
  return phoneNumber;
}

export function addCommas(
  value: number | string,
  fixed = DEFAULT_FIXED_COUNT,
  roundUp = false,
) {
  if (!value) return '-'

  const format = `0,0.[${'0'.repeat(fixed)}]`
  const pad = Number((0.1 ** fixed / 2).toFixed(fixed + 1))

  return numeral(value)
    .add(roundUp ? pad : 0)
    .format(format)
}

export function formatDate(date: any, format = DEFAULT_DATE_FORMAT) {
  return date ? moment(date).format(format) : '-'
}

export function maskingName(value = '') {
  if (!value) return '-'

  const _length = value.length

  if (_length < 2) return value

  if (_length === 2) return value.slice(0, 1).concat('*')

  const front = value.slice(0, 1)
  const middle = '*'.repeat(_length - 2)
  const end = value.slice(-1)

  return [front, middle, end].join('')
}

// 파일 사이즈
export function formatFileSize(fileSize, option) {
  if (isNaN(fileSize) || fileSize < 0) {
    return false
  }

  switch (option) {
    case 'KB':
      return (fileSize / 1024).toFixed(1) + ' KB'
    case 'MB':
      return (fileSize / (1024 * 1024)).toFixed(1) + ' MB'
    case 'GB':
      return (fileSize / (1024 * 1024 * 1024)).toFixed(1) + ' GB'
    default:
      return false
  }
}
