





































































































import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { State } from 'vuex-class'
import PAYMENT_HISTORY_STATUS from '@/views/constant/payment.history.status.constant'
import USER_ROLE_TYPE from '@/views/constant/user.role.type'
import { formatDate } from '@/utils/business.util'
import smartroService from '@/services-itgo/smartro.service'
import { mdiWindowClose } from '@mdi/js'
import _ from 'lodash'

@Component({
  name: 'PaymentHistoryPopup',
  components: {
    PerfectScrollbar,
  },

  mounted() {
    if (!_.isNull(this.callRequestId)) this.onLoadingData()
  },
})
export default class PaymentHistoryPopup extends Vue {
  @Prop(Boolean) value
  @Prop(Number) callRequestId

  @State((state) => state.auth.user) loginUser

  @Watch('callRequestId')
  initHistoryList() {
    if (!_.isNull(this.callRequestId)) this.onLoadingData()
  }

  perfectScrollbarOptions: any = {
    maxScrollbarLength: 30,
    wheelPropagation: false,
  }

  icons: any = {
    mdiWindowClose,
  }

  paymentHistoryStatus: any = PAYMENT_HISTORY_STATUS
  userRoleType: any = USER_ROLE_TYPE

  history: any = []

  async onLoadingData() {
    try {
      console.log(this.callRequestId)
      const params = { callRequestId: this.callRequestId }
      this.history = await smartroService.getPaymentHistoryList(params)
    } catch (err: any) {
      await this.$alert({
        title: '결제 이력 조회 에러',
        content: err.response.data.errMsg,
      })
      await this.$emit('close')
    }
  }

  systemStatusText(status) {
    let str: any = ''
    switch (status) {
      case 1:
        str = '결제가 완료 되었습니다.'
        break
      case 2:
        str = '결제가 실패되었습니다.'
        break
      case 6:
        str = '결제가 취소 되었습니다.'
        break
      case 7:
        str = '결제취소가 실패되었습니다.'
        break
      default:
        str = false
    }
    return str
  }

  onClickClose() {
    this.$emit('close')
  }
}
