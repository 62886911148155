











































































































import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import miscService from '@/services-itgo/misc.service'
import { mdiMagnify, mdiClose } from '@mdi/js'
import _ from 'lodash'

@Component({
  components: {
    PerfectScrollbar,
  },
})
export default class FreightUsePopup extends Vue {
  @Prop(Boolean) value
  @Prop(Array) item

  @Watch('value')
  openPopup(value) {
    if (value) {
      this.selectRegion = [...this.item]
      return this.fetchRegionData()
    }
  }
  icons: any = {
    mdiMagnify,
    mdiClose,
  }
  regionData: any = []

  perfectScrollbarOptions: any = {
    maxScrollbarLength: 30,
    wheelPropagation: false,
  }

  keyword = ''
  sido = ''
  sigungu = ''
  selectRegion: string[] = []
  searchSido = ''
  searchSigungu = ''

  get sigunguItems() {
    if (!this.sido) return ['전체']
    if (this.searchSigungu) {
      return [this.searchSido ? this.searchSigungu : '전체']
    }
    const target = this.regionData.find((item) => item.adminArea === this.sido)
    return ['전체', ...target.subAdminArea]
  }
  get sidoItems() {
    if (this.searchSido) {
      return [this.searchSido]
    } else {
      return ['전체', ...this.regionData.map((item: any) => item.adminArea)]
    }
  }

  async fetchRegionData() {
    try {
      const result = await miscService.getRegionData()
      this.regionData = result
    } catch (err) {
      console.log('Error : ', err)
    }
  }

  onSelectSido(item) {
    if (item === '전체') {
      return (this.selectRegion = ['전체'])
    }
    this.sido = item
  }

  onSelectSigugun(item) {
    // 시.도를 선택하지 않은 경우
    if (!this.sido) return

    // 전체 선택이 되어있는 경우
    if (this.selectRegion.find((item) => item === '전체')) {
      this.selectRegion = []
    }

    // 중복 확인
    if (this.selectRegion.includes(`${this.sido} ${item}`)) return

    // '전체' 선택시 : 해당 시군구 선택된 것들 삭제
    if (item === '전체') {
      this.sigungu = '전체'
      this.selectRegion = this.selectRegion.filter((item) =>
        this.sido && item.includes(this.sido) ? '' : item,
      )
      return (this.selectRegion = this.selectRegion.concat(`${this.sido} 전체`))
    } else {
      this.selectRegion = this.selectRegion.filter((item) =>
        this.sido && item.includes(`${this.sido} 전체`) ? '' : item,
      )
    }

    this.sigungu = item
    this.selectRegion = this.selectRegion.concat(`${this.sido} ${this.sigungu}`)
  }
  a
  onRemoveRegion(index: number) {
    this.selectRegion.splice(index, 1)
  }

  onSearch() {
    this.searchSido = ''
    this.searchSigungu = ''

    // 초기화
    if (!this.keyword) {
      this.sido = ''
      this.sigungu = ''
      return
    }

    // 키워드 검색
    if (this.regionData.some((item) => item.adminArea === this.keyword)) {
      this.searchSido = this.keyword
      this.sido = this.keyword
    } else {
      const result = this.regionData.find((item) =>
        item.subAdminArea.some((ele) => ele === this.keyword),
      )

      if (result) {
        this.sido = result.adminArea
        this.searchSido = result.adminArea
        this.searchSigungu = this.keyword
      }
    }
  }

  onClickClose() {
    this.$emit('close', this.selectRegion)
  }
}
