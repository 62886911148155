import axios from 'axios'

class EventService {
  private apiUrl: string
  constructor() {
    this.apiUrl = '/event'
  }

  // 4. 이벤트 목록 조회
  getPageList(params) {
    return axios
      .get(this.apiUrl + `/page`, { params: params })
      .then((res) => res.data)
  }

  // 6. 이벤트 유형 목록 조회
  // getCategories() {
  //   return axios.get(this.apiUrl + `/categories`).then((res) => res.data)
  // }

  // 7. 이벤트 상세 조회
  getEventDetails(id) {
    const params = {
      eventId: id,
    }
    return axios
      .get(this.apiUrl + `/detail`, { params: params })
      .then((res) => res.data)
  }

  // 3. 이벤트 삭제
  deleteEvent(id) {
    const params = {
      eventIdList: id,
    }
    return axios.delete(this.apiUrl, { params: params }).then((res) => res.data)
  }

  // 8. 이벤트 PUSH 발송
  postAppPush(params) {
    return axios
      .post(this.apiUrl + `/app-push`, {
        eventId: params.id,
        appPushTitle: params.title,
        appPushContent: params.content,
      })
      .then((res) => res.status)
  }

  // 9. 이벤트 SMS 발송
  postSmsPush(params) {
    return axios
      .post(this.apiUrl + `/sms-push`, {
        eventId: params.id,
        smsPushTitle: params.title,
        smsPushContent: params.content,
      })
      .then((res) => res.status)
  }

  // 1. 이벤트 등록
  postRegisterevent(body) {
    return axios.post(this.apiUrl, body).then((res) => res.data)
  }

  getEventFile(params) {
    return axios
      .get(this.apiUrl + '/file', {
        responseType: 'blob',
        params: params,
      })
      .then((res) => res.data)
  }

  // 2. 이벤트 수정
  putEditEvent(body) {
    return axios.put(this.apiUrl, body).then((res) => res.data)
  }
}

export default new EventService()
