var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content-wrapper content_body" }, [
    _c(
      "div",
      { staticClass: "content-inner" },
      [
        _c(
          "v-card",
          {
            staticClass: "d-flex flex-column justify-space-between",
            staticStyle: { "border-radius": "10px", height: "913px" },
          },
          [
            _c(
              "div",
              { staticStyle: { padding: "8.5px 9px" } },
              [
                _c(
                  "v-card-title",
                  { staticClass: "d-flex justify-space-between align-center" },
                  [
                    _c(
                      "div",
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "font-size26 font-weight700 surface800",
                          },
                          [_vm._v("통계")]
                        ),
                        _c("v-divider", {
                          staticClass: "mx-4",
                          attrs: { vertical: "" },
                        }),
                        _c("span", { staticClass: "font-size20" }, [
                          _vm._v("차주 앱화면 이용"),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "d-flex justify-end",
                        staticStyle: { width: "20%", gap: "10px" },
                      },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "px-2 mt-n1 font-size14",
                            attrs: {
                              color: "#183256",
                              dense: "",
                              outlined: "",
                            },
                            on: { click: _vm.onClickDownloadExcel },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/icons/download.svg"),
                              },
                            }),
                            _c("span", { staticClass: "pl-1" }, [
                              _vm._v("엑셀 다운로드"),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
            _c(
              "v-tabs",
              {
                staticClass: "mt-3 my-1",
                staticStyle: {
                  "box-shadow": "none !important",
                  "border-bottom": "1px solid #e2e2e2",
                },
                attrs: { color: "accent" },
                model: {
                  value: _vm.tab,
                  callback: function ($$v) {
                    _vm.tab = $$v
                  },
                  expression: "tab",
                },
              },
              [
                _c("v-tabs-slider", { attrs: { color: "accent" } }),
                _vm._l(_vm.tabItems, function (item, index) {
                  return _c(
                    "v-tab",
                    {
                      key: index,
                      staticClass: "font-size18 font-weight500",
                      staticStyle: { width: "150px" },
                    },
                    [_vm._v(" " + _vm._s(item.title) + " ")]
                  )
                }),
                _c(
                  "v-tab-item",
                  [_vm.tab === 0 ? _c("main-feature") : _vm._e()],
                  1
                ),
                _c(
                  "v-tab-item",
                  [_vm.tab === 1 ? _c("main-view") : _vm._e()],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }