import axios from 'axios'
import AuthService from '@/services-itgo/auth.service'
import DatetimeUtils from '@/utils/datetime.utils'
import store from '@/store'

class AxiosService {
  constructor() {}

  requestInterceptors() {
    axios.interceptors.request.use(
      function (config) {
        config.headers['X-ITGO-TLO-ACCSS-TYPE'] = 'ADM'
        config.headers['X-ITGO-TLO-DEV-INFO'] = 'PC'
        config.headers['X-ITGO-TLO-OS-INFO'] = ''
        config.headers['X-ITGO-TLO-NW-INFO'] = 'ETC'
        config.headers['X-ITGO-TLO-DEV-MODEL'] = ''
        config.headers['X-ITGO-TLO-CARRIER-TYPE'] = ''
        config.headers['X-ITGO-TLO-LOG-ID'] =
          DatetimeUtils.now_format('YYYYMMDDHHmmssSSS') + '_admin'
        return config
      },
      function (error) {
        return Promise.reject(error)
      },
    )
  }

  responseInterceptors() {
    axios.interceptors.response.use(
      function (response) {
        return response
      },
      function (error) {
        console.log(error)
        // if (error.response?.status === 401) {
        //   return location.reload()
        // } else
        if (
          error.code === 'ECONNABORTED' ||
          error.response?.status === 408
        ) {
          store.dispatch(
            'popup/alert',
            '일시적인 오류가 발생했습니다. <br/> 다시 시도해 주세요.',
          )
        } else return Promise.reject(error)
      },
    )
  }
}

export default new AxiosService()
