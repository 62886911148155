var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-select", {
    staticStyle: { width: "100%" },
    attrs: {
      items: _vm.statusItems,
      label: "화물 상태",
      "item-text": "text",
      "item-value": "value",
      dense: "",
      outlined: "",
      "hide-details": "",
      "menu-props": { bottom: true, offsetY: true },
    },
    on: { change: _vm.changeItem },
    model: {
      value: _vm.status,
      callback: function ($$v) {
        _vm.status = $$v
      },
      expression: "status",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }