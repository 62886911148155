import axios from 'axios'

class PushService {
  private apiUrl: string
  constructor() {
    this.apiUrl = '/push-log'
  }

  // 알람 목록 조회
  getPushLogList() {
    return axios.get(this.apiUrl + `/new`).then((res) => res.data)
  }

  // 알람 목록 조회
  readPushLog(body) {
    return axios.put(this.apiUrl + `/read`, body).then((res) => res.data)
  }
}

export default new PushService()
