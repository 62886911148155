
















import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import FREIGHT_STATUS from '@/views/constant/freight.status'

@Component({
  name: 'FreightStatusType',
})
export default class FreightStatusType extends Vue {
  @Prop(Array) value

  status = [FREIGHT_STATUS.TOTAL]
  statusItems = [
    { text: FREIGHT_STATUS.TOTAL_STRING, value: [FREIGHT_STATUS.TOTAL] },
    {
      text: FREIGHT_STATUS.DISPATCH_WAITING_STRING,
      value: [FREIGHT_STATUS.DISPATCH_WAITING],
    },
    {
      text: FREIGHT_STATUS.DISPATCH_REQ_STRING,
      value: [FREIGHT_STATUS.DISPATCH_REQ],
    },
    {
      text: FREIGHT_STATUS.DISPATCH_COMPLETED_STRING,
      value: [FREIGHT_STATUS.DISPATCH_COMPLETED],
    },
    {
      text: FREIGHT_STATUS.IN_TRANSIT_STRING,
      value: [FREIGHT_STATUS.IN_TRANSIT],
    },
    {
      text: FREIGHT_STATUS.TRANS_COMPLETED_STRING,
      value: [FREIGHT_STATUS.TRANS_COMPLETED],
    },
    {
      text: FREIGHT_STATUS.FAILED_STRING,
      value: [
        FREIGHT_STATUS.FAILED,
        FREIGHT_STATUS.CANCELLED_BY_CALLER,
        FREIGHT_STATUS.CANCELLED_BY_BROKER,
        FREIGHT_STATUS.CANCELLED_BY_CONTROLLER_FOR_CALLER,
        FREIGHT_STATUS.CANCELLED_BY_CONTROLLER_FOR_BROKER,
        FREIGHT_STATUS.DELETED_BY_CONTROLLER,
        FREIGHT_STATUS.CANCELLED_BY_PICKUP_SCHEDULE,
      ],
    },
    { text: FREIGHT_STATUS.HIDDEN_STRING, value: [FREIGHT_STATUS.HIDDEN] },
    { text: FREIGHT_STATUS.HOLDING_STRING, value: [FREIGHT_STATUS.HOLDING] },
  ]

  @Watch('value')
  onChangeValue(value){
    this.status = value 
  }

  changeItem(value) {
    console.log(value)
    this.$emit('setInputStatusItem', value)
  }
}
