var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "elevation-0 pa-0 ma-0" },
    [
      _c("v-card-title", { staticClass: "px-6 pt-10 pb-7" }, [
        _c(
          "div",
          { staticClass: "flex-start-contents" },
          [
            _c(
              "div",
              { staticClass: "pr-2", staticStyle: { width: "15%" } },
              [
                _c("v-select", {
                  staticStyle: { width: "100%" },
                  attrs: {
                    items: _vm.statusItems,
                    label: "회원 상태",
                    "item-text": "text",
                    "item-value": "value",
                    dense: "",
                    outlined: "",
                    "hide-details": "",
                    "menu-props": { bottom: true, offsetY: true },
                  },
                  on: { change: _vm.onChangeStatus },
                  model: {
                    value: _vm.status,
                    callback: function ($$v) {
                      _vm.status = $$v
                    },
                    expression: "status",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "pr-2", staticStyle: { width: "20%" } },
              [
                _c("vue2-date-picker", {
                  staticClass: "management",
                  staticStyle: { width: "100%" },
                  attrs: {
                    type: "date",
                    editable: false,
                    placeholder: "날짜",
                    range: "",
                    lang: "ko",
                  },
                  on: { change: _vm.onChangeDate },
                  model: {
                    value: _vm.date,
                    callback: function ($$v) {
                      _vm.date = $$v
                    },
                    expression: "date",
                  },
                }),
              ],
              1
            ),
            _c("v-spacer"),
            !(_vm.isBroker || _vm.isCaller)
              ? _c(
                  "div",
                  {
                    staticClass: "d-flex justify-end",
                    staticStyle: { width: "20%" },
                  },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "px-2 mt-n1 font-size18 payment-btn",
                        attrs: {
                          color: "#196CE7",
                          dense: "",
                          outlined: "",
                          disabled: _vm.userStatus === 5,
                        },
                        on: { click: _vm.onClickChangeStatus },
                      },
                      [_vm._v(" " + _vm._s(_vm.onClickChangeStatusStr) + " ")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c(
        "v-data-table",
        _vm._b(
          {
            staticStyle: { "min-height": "540px !important" },
            attrs: {
              "items-per-page": _vm.pageSize,
              page: _vm.pageNum,
              "item-key": "key",
              "hide-default-footer": "",
              "disable-sort": "",
            },
            on: {
              "update:page": function ($event) {
                _vm.pageNum = $event
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "no-data",
                  fn: function () {
                    return [_vm._v("정보가 없습니다.")]
                  },
                  proxy: true,
                },
                {
                  key: "item.regDt",
                  fn: function (ref) {
                    var value = ref.value
                    return [
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("formatDate")(value, "YYYY-MM-DD")) +
                          " "
                      ),
                    ]
                  },
                },
                {
                  key: "item.userStatus",
                  fn: function (ref) {
                    var value = ref.value
                    return [
                      _vm._v(
                        " " + _vm._s(_vm.userStatusString(value) || "-") + " "
                      ),
                    ]
                  },
                },
                {
                  key: "item.reason",
                  fn: function (ref) {
                    var value = ref.value
                    return [_vm._v(" " + _vm._s(value || "-") + " ")]
                  },
                },
                {
                  key: "item.adminName",
                  fn: function (ref) {
                    var value = ref.value
                    return [
                      _vm._v(" " + _vm._s(_vm._f("maskingName")(value)) + " "),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          },
          "v-data-table",
          _vm.userStatusInfo,
          false
        )
      ),
      _c("Pagination", {
        attrs: {
          pageSizes: _vm.pageSizes,
          totalPageCount: _vm.totalPageCount,
          pageSize: _vm.pageSize,
          pageNum: _vm.pageNum,
        },
        on: {
          "update:pageSize": function ($event) {
            _vm.pageSize = $event
          },
          "update:page-size": function ($event) {
            _vm.pageSize = $event
          },
          "update:pageNum": function ($event) {
            _vm.pageNum = $event
          },
          "update:page-num": function ($event) {
            _vm.pageNum = $event
          },
        },
      }),
      _c("change-user-status-popup", {
        key: _vm.popupKey,
        attrs: { item: _vm.items },
        on: {
          changedUserStatus: _vm.changedUserStatus,
          close: _vm.onCloseChangeUserStatus,
        },
        model: {
          value: _vm.openChangeUserStatus,
          callback: function ($$v) {
            _vm.openChangeUserStatus = $$v
          },
          expression: "openChangeUserStatus",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }