



















































import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import {} from '@mdi/js'
import TermsEditPopup from '@/views/management/TermsEditPopup.vue'
import TermsHistoryPopup from '@/views/management/TermsHistoryPopup.vue'

@Component({
  name: 'FreightInfoItem',
  components: { TermsHistoryPopup, TermsEditPopup },
})
export default class TermsInfoItem extends Vue {
  @Prop(String) title
  @Prop(String) content
  @Prop(Boolean) isBtn
  @Prop(String) termsType
  @Prop(Number) userId
  @Prop(Number) type

  termsEditPopup: boolean = false
  termsHistoryPopup: boolean = false

  componentKey: any = {
    termsEditPopupKey: 0,
    termsHistoryPopupKey: 0,
  }

  editPopup() {
    this.termsEditPopup = true
    this.componentKey.termsEditPopupKey++
  }

  editPopupHide() {
    this.termsEditPopup = false
  }

  historyPopup() {
    console.log(this.termsType)
    this.$emit('showHistory', this.termsType)
    // this.termsHistoryPopup = true
    // this.componentKey.termsHistoryPopupKey++
  }

  // historyPopupHide() {
  //   this.termsHistoryPopup = false
  // }

  updateUserInfo() {
    this.editPopupHide()
    this.$emit('updateUserInfo')
  }
}
