<template>
  <v-list-item
    v-if="canViewVerticalNavMenuLink(item)"
    v-bind="linkProps"
    :class="{ 'bg-gradient-primary white--text': isActive }"
    class="vertical-nav-menu-link"
    active-class="bg-gradient-primary"
  >
    <v-list-item-icon>
      <dashboard
        v-if="item.title === 'Dashboards'"
        :icon-color="isActiveMenu(isActive, item)"
      ></dashboard>
      <record
        v-if="item.title === 'FreightHistory'"
        :icon-color="isActiveMenu(isActive, item)"
      >
      </record>
      <noti
        v-else-if="item.title === 'NoticeManagement'"
        :icon-color="isActiveMenu(isActive, item)"
      ></noti>
      <faq
        v-else-if="item.title === 'FAQManagement'"
        :icon-color="isActiveMenu(isActive, item)"
      ></faq>
      <customervoice
        v-else-if="item.title === 'VOCManagement'"
        :icon-color="isActiveMenu(isActive, item)">
      </customervoice>
      <event
        v-else-if="item.title === 'EventManagement'"
        :icon-color="isActiveMenu(isActive, item)">
      </event>
      <system
        v-else-if="item.title === 'AdminManagement'"
        :icon-color="isActiveMenu(isActive, item)">
      </system>

    </v-list-item-icon>

    <v-list-item-title :class="{ 'white--text': isActive }">
      <span class="text-base">{{ t(item.title) }}</span>
    </v-list-item-title>
    <v-list-item-action v-if="item.badge" class="flex-shrink-0">
      <v-badge :color="item.badgeColor" inline :content="item.badge"></v-badge>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import themeConfig from '@themeConfig'
import { useUtils } from '@core/libs/i18n'
import { useUtils as useAclUtils } from '@core/libs/acl'
import useVerticalNavLink from '@core/layouts/composable/vertical-nav/useVerticalNavLink'
import dashboard from '@/views/icons/leftPanel/dashboard.vue'
import calculate from '@/views/icons/leftPanel/calculate.vue'
import card from '@/views/icons/leftPanel/card.vue'
import faq from '@/views/icons/leftPanel/faq.vue'
import freight from '@/views/icons/leftPanel/freight.vue'
import noti from '@/views/icons/leftPanel/noti.vue'
import shipper from '@/views/icons/leftPanel/shipper.vue'
import truck from '@/views/icons/leftPanel/truck.vue'
import driver from '@/views/icons/leftPanel/driver.vue'
import Record from "@/views/icons/leftPanel/record.vue";
import Customervoice from "@/views/icons/leftPanel/customervoice.vue";
import event from "@/views/icons/leftPanel/event.vue"
import system from "@/views/icons/leftPanel/system.vue"

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  components: {
    Customervoice,
    Record,
    dashboard,
    calculate,
    card,
    faq,
    freight,
    noti,
    shipper,
    truck,
    driver,
    event,
    system
  },
  setup(props) {
    const { isActive, linkProps } = useVerticalNavLink(props.item)
    const { t } = useUtils()
    const { canViewVerticalNavMenuLink } = useAclUtils()
    return {
      isActive,
      linkProps,
      alternateIcon: themeConfig.menu.groupChildIcon,

      // i18n
      t,

      // ACL
      canViewVerticalNavMenuLink,
    }
  },
  methods: {
    isActiveMenu(isActive, item) {
      if (isActive) {
        return '#FFFFFF'
      } else {
        return '#979EAA'
      }
    },
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.vertical-nav-menu-link {
  &.v-list-item--active {
    box-shadow: 0 5px 10px -4px rgba(94, 86, 105, 0.42);
    @include elevationTransition();
  }
}
</style>
