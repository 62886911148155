


































































//TS에서는 @로 시작하는 임포트를 ts  선언 타입으로 해석하는것 같음 . 아래와 같이 상대 경로로 잡음
// import themeConfig from '@themeConfig'
import themeConfig from '../../../themeConfig'
import {
  mdiFacebook,
  mdiTwitter,
  mdiGithub,
  mdiGoogle,
  mdiEyeOutline,
  mdiEyeOffOutline,
} from '@mdi/js'
import { Component, Vue } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'
import AuthService from '@/services-itgo/auth.service'
import LogoLarge from '@/views/icons/logo/LogoLarge.vue'
import PasswordChangedPopup from '@/views/login/PasswordChangedPopup.vue'
import _ from 'lodash'

@Component({
  name: 'LoginV1',
  components: {
    PasswordChangedPopup,
    LogoLarge,
  },
})
export default class LoginV1 extends Vue {
  @Mutation('auth/saveNiceData') saveNiceData
  @Mutation('auth/saveEncData') saveEncData
  @Mutation('auth/saveLogoutType') saveLogoutType
  @Getter('auth/getLogoutTypeUser') getLogoutTypeUser

  public appName = themeConfig.app.name

  public appLogo = themeConfig.app.logo

  public icons = {
    mdiEyeOutline,
    mdiEyeOffOutline,
  }

  componentKey = {
    passwordPopupKey: 0,
  }

  public isPasswordVisible = false
  public loginId = ''
  public password = ''

  async mounted() {
    this.saveNiceData(null)
    this.saveEncData(null)

    if (this.getLogoutTypeUser === 2) {
      await this.$alert({
        title: '접근 권한 해제',
        content:
          '동일한 아이디로 중복 로그인하여 <br/> 자동 로그아웃 처리 되었습니다.',
      })
      this.saveLogoutType(0)
    }
  }

  async loginBtnClicked() {
    try {
      if (_.isEmpty(this.loginId) || _.isEmpty(this.password)) {
        await this.$alert({
          title: '로그인 실패',
          content: `아이디 또는 비밀번호 입력해주세요.`,
        })
      } else {
        const result = await AuthService.signcode({
          loginId: this.loginId,
          password: this.password,
        })

        console.log(result)

        await this.$store.dispatch('auth/signin', {
          loginId: this.loginId,
          password: this.password,
        })

        await this.$store.dispatch('auth/getUser')
        this.$root.$emit('load-init-data')
        await this.$router.push({ name: 'dashboards' })
      }
    } catch (e) {
      ////////////////////////////////////////////////////
      //   case 'E1036': //90일 비밀 번호 변경 안했을 경우
      //     await this.$alert({
      //       title: '비밀번호 변경 안내',
      //       content: `회원님은 90일 이상 동일한 비밀번호를<br/> 사용하고 계십니다. 소중한 개인 정보 보호를<br />위해 비밀번호를 변경해 주세요`,
      //       confirmText: '비밀번호 변경',
      //     })
      //     await this.$router.push({ name: 'findPasswordId' })
      //     break;
      ////////////////////////////////////////////////////
      const errorStruct = {
        title: '',
        content: '',
        confirmText: '확인',
      }
      switch (e.response.data.errCode) {
        case 'E1033':
          errorStruct.title = `로그인 실패 (${e.response.data.errMsg.substr(
            11,
          )})`
          errorStruct.content = `아이디 혹은 비밀 번호 확인 후 <br/> 다시 시도해 주세요`
          break
        case 'E1032':
          errorStruct.title = `로그인 시도 횟수 초과`
          errorStruct.content = `5회 이상 로그인에 실패한 계정입니다 <br/> Super Admin에게 비밀번호 변경을 요청 해주세요`
          break
        case 'E1040':
          errorStruct.title = `로그인 실패`
          errorStruct.content = `Super Admin에 의해서 계정이 비활성화되었습니다.`
          break
        case 'E1036':
          errorStruct.title = `비밀번호 변경 안내`
          errorStruct.content = `회원님은 90일 이상 동일한 비밀번호를 사용하고 계십니다 <br/> 소중한 개인 정보보호를 위해 비밀번호를 변경해 주세요`
          errorStruct.confirmText = '비밀번호 변경'
          break
        case 'E1052':
          errorStruct.title = `비밀번호 변경 안내`
          errorStruct.content = `안전한 사용을 위해 최초로 로그인 시 <br/> 비밀번호 변경해야 됩니다.`
          errorStruct.confirmText = '비밀번호 변경'
          break
        default:
          errorStruct.title = '로그인 실패'
          errorStruct.content = e.response.data.errMsg
          break
      }
      await this.$alert({
        title: errorStruct.title,
        content: errorStruct.content,
        confirmText: errorStruct.confirmText,
      })

      if (
        e.response.data.errCode === 'E1036' ||
        e.response.data.errCode === 'E1052'
      ) {
        this.onClickPasswordPopup()
      }
      // switch(e.response.data.errCode){
      //   case 'E1032': // 로그인 시도 횟수 초과
      //     const result = await this.$confirm({
      //       title: '로그인 시도 횟수 초과',
      //       content:
      //         '5회 이상 로그인에 실패한 계정입니다.<br />Super Admin에게 비밀번호 변경을 요청해 주세요.',
      //       cancelText: '취소',
      //       confirmText: '비밀번호 재설정',
      //     })
      //     result ? await this.$router.push({ name: 'findPasswordId' }) : ''
      //     break;
      //
      //   case 'E1036': //90일 비밀 번호 변경 안했을 경우
      //     await this.$alert({
      //       title: '비밀번호 변경 안내',
      //       content: `회원님은 90일 이상 동일한 비밀번호를<br/> 사용하고 계십니다. 소중한 개인 정보 보호를<br />위해 비밀번호를 변경해 주세요`,
      //       confirmText: '비밀번호 변경',
      //     })
      //     await this.$router.push({ name: 'findPasswordId' })
      //     break;
      //
      //   case 'E1011': // 탈퇴 계정
      //     await this.$alert({
      //       title: '탈퇴 계정 안내',
      //       content: `해당 계정은 탈퇴된 계정입니다. <br/> 탈퇴 계정은 동일한 계정으로 <br/> 로그인 또는 회윈 재가입이 불가능합니다.`,
      //     })
      //     break;
      //
      //   case 'E1012': // 강제 탈퇴
      //     await this.$alert({
      //       title: '강제 탈퇴 안내',
      //       content: `서비스 운영 정책에 따라 강제 탈퇴 처리 되었습니다.<br/>회원님의 계정으로 로그인 또는 재가입이 불가능합니다. <br/> 자세한 사항은 콜센터를 통해 문의 해 주세요.`,
      //     })
      //     break;
      //
      //   case 'E1040': // 비활성화 계정
      //     const accountResult = await this.$confirm({
      //       title: '로그인 실패',
      //       content: `Super admin에 의해서 계정이 비활성화되었습니다.`,
      //       confirmText: '휴면 해제',
      //     })
      //     break;
      //
      //   case 'E1033': // 로그인 실패
      //     const message = e.response.data.errMsg.split(' ')
      //     await this.$alert({
      //       title: '로그인 실패 (' + message[3] + ')',
      //       content: `아이디 혹은 비밀번호 확인 후 <br/> 다시 시도해 주세요.`,
      //     })
      //     break;
      //
      //   default:
      //     await this.$alert({
      //       title: '로그인 실패',
      //       content: e.response.data.errMsg,
      //     })
      // }
    } finally {
    }
  }

  openPasswordPopup = false

  onClickPasswordPopup() {
    this.componentKey.passwordPopupKey ++
    this.openPasswordPopup = true
  }

  closePasswordPopup() {
    this.openPasswordPopup = false
  }

}
