import {
  mdiLandPlots,
  mdiFileAccountOutline,
  mdiNoteCheckOutline,
  mdiChartBoxOutline,
  mdiDatabaseOutline,
  mdiFileDocumentOutline,
  mdiBullhornOutline,
  mdiMessageQuestionOutline,
  mdiMonitorAccount,
} from '@mdi/js'

export default [
  {
    title: 'Dashboards',
    to: 'dashboards',
  },
  {
    title: 'Membership',
    children: [
      {
        title: 'MemberRegistration',
        to: 'memberReg',
      },
      {
        title: 'MemberDocument',
        to: 'memberDoc',
      },
      {
        title: 'AssociateMemberRegistration',
        to: 'associateMemberReg',
      },
      {
        title: 'ForcedConversionCustomer',
        to: 'forcedConversionCustomer',
      },
    ],
  },
  {
    title: 'MemberManagement',
    children: [
      {
        title: 'Broker',
        to: 'brokerManagement',
      },
      {
        title: 'Driver',
        to: 'driverManagement',
      },
      {
        title: 'Caller',
        to: 'callerManagement',
      },
    ],
  },
  {
    title: 'FreightHistory',
    to: 'freightHistory',
  },
  {
    title: 'Statistics',
    children: [
      {
        title: 'BrokerCalculate',
        to: 'brokerCalculate',
      },
      {
        title: 'FreightUse',
        to: 'freightUse',
      },
      // {
      //   title: 'DriverApp',
      //   to: 'driverApp',
      // },
      {
        title: 'Testimonials',
        to: 'testimonials',
      },
      {
        title: 'DriverMonthUse',
        to: 'driverMonthUse',
      },
      {
        title: 'UsageHistory',
        to: 'usageHistory',
      },
      {
        title: 'SubscriptionLeave',
        to: 'subscriptionLeave',
      },
    ],
  },
  {
    title: 'NoticeManagement',
    to: 'noticeManagement',
  },
  {
    title: 'EventManagement',
    to: 'eventManagement',
  },
  {
    title: 'FAQManagement',
    to: 'faqManagement',
  },
  {
    title: 'VOCManagement',
    to: 'vocManagement',
  },
]
