import _ from 'lodash'

/**
 * 차량 정보
 */
export = (() => {
  const _CARGO_TYPE: any = {
    INDUSTRY: 1, // 공산품
    FOOD: 2, // 식품
    AGRICULTURE: 3, // 농산물
    SEAFOOD: 4, // 수산물
    LIVESTOCK: 5, // 축산물
    MEDICINE: 6, // 의약품
    WASTE: 7, // 폐기물
  }

  const _CARGO_TYPE_STRING: any = {
    INDUSTRY: '공산품',
    FOOD: '식품',
    AGRICULTURE: '농산물',
    SEAFOOD: '수산물',
    LIVESTOCK: '축산물',
    MEDICINE: '의약품',
    WASTE: '폐기물',
  }

  Object.freeze(_CARGO_TYPE)
  Object.freeze(_CARGO_TYPE_STRING)

  const _CARGO_TYPE_WEB_NAME: Map<number, string> = new Map<number, string>([
    [_CARGO_TYPE.INDUSTRY, _CARGO_TYPE_STRING.INDUSTRY],
    [_CARGO_TYPE.FOOD, _CARGO_TYPE_STRING.FOOD],
    [_CARGO_TYPE.AGRICULTURE, _CARGO_TYPE_STRING.AGRICULTURE],
    [_CARGO_TYPE.SEAFOOD, _CARGO_TYPE_STRING.SEAFOOD],
    [_CARGO_TYPE.LIVESTOCK, _CARGO_TYPE_STRING.LIVESTOCK],
    [_CARGO_TYPE.MEDICINE, _CARGO_TYPE_STRING.MEDICINE],
    [_CARGO_TYPE.WASTE, _CARGO_TYPE_STRING.WASTE],
  ])

  return Object.freeze({
    INDUSTRY: _CARGO_TYPE.INDUSTRY,
    FOOD: _CARGO_TYPE.FOOD,
    AGRICULTURE: _CARGO_TYPE.AGRICULTURE,
    SEAFOOD: _CARGO_TYPE.SEAFOOD,
    LIVESTOCK: _CARGO_TYPE.LIVESTOCK,
    MEDICINE: _CARGO_TYPE.MEDICINE,
    WASTE: _CARGO_TYPE.WASTE,

    INDUSTRY_STRING: _CARGO_TYPE_STRING.INDUSTRY,
    FOOD_STRING: _CARGO_TYPE_STRING.FOOD,
    AGRICULTURE_STRING: _CARGO_TYPE_STRING.AGRICULTURE,
    SEAFOOD_STRING: _CARGO_TYPE_STRING.SEAFOOD,
    LIVESTOCK_STRING: _CARGO_TYPE_STRING.LIVESTOCK,
    MEDICINE_STRING: _CARGO_TYPE_STRING.MEDICINE,
    WASTE_STRING: _CARGO_TYPE_STRING.WASTE,

    CARGO_TYPE_NAME_WEB: (index: number): string => {
      const cargoTypeName: string | undefined = _CARGO_TYPE_WEB_NAME.get(index)
      return cargoTypeName ? cargoTypeName : '-'
    },
  })
})()
