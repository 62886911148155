var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "vertical-nav-menu-container",
      on: {
        mouseenter: function ($event) {
          _vm.isMouseHovered = true
        },
        mouseleave: function ($event) {
          _vm.isMouseHovered = false
        },
      },
    },
    [
      _vm._t("v-nav-menu-header", function () {
        return [
          _c("vertical-nav-menu-header", {
            on: {
              "close-nav-menu": function ($event) {
                return _vm.$emit("close-nav-menu")
              },
            },
          }),
        ]
      }),
      _c(
        "perfect-scrollbar",
        { staticClass: "ps-nav-menu-items" },
        [
          _c(
            "v-list",
            {
              staticClass: "vertical-nav-menu-items",
              staticStyle: { color: "white" },
              attrs: { expand: "" },
            },
            _vm._l(_vm.navMenuItems, function (item) {
              return _c(_vm.resolveNavItemComponent(item), {
                key: item.subheader || item.title,
                tag: "component",
                attrs: { item: item },
              })
            }),
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }