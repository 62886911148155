





















































import { Component, Prop, Ref, Vue } from 'vue-property-decorator'
import { mdiChevronDown } from '@mdi/js'
import DatetimeUtils from '@/utils/datetime.utils'

@Component
export default class DateMonthPicker extends Vue {
  @Ref() datePicker
  @Prop(String) type

  public picker = DatetimeUtils.setKoreaDate(new Date(), 'month')
  public menu = false
  public monthPicker = null

  public icons = {
    mdiChevronDown,
  }

  set() {
    this.menu = false
    this.datePicker.save(this.picker)
    if (this.type === 'reg') {
      this.$emit('regSetInputDate', this.monthPicker)
    } else if (this.type === 'dispatch') {
      this.$emit('dispatchSetInputDate', this.monthPicker)
    } else {
      this.$emit('setInputDate', this.monthPicker)
    }
  }

  onClearClicked() {
    this.$emit('setInputDate', this.picker)
  }

  settingKoreaDate(date) {
    console.log(date)
    this.monthPicker = date
    this.picker = DatetimeUtils.setKoreaDate(date, 'month')
  }
}
