var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        width: "337",
        height: "85",
        viewBox: "0 0 337 85",
      },
    },
    [
      _c("defs", [
        _c("clipPath", { attrs: { id: "clip-path" } }, [
          _c("rect", {
            attrs: {
              id: "사각형_33704",
              "data-name": "사각형 33704",
              width: "212.662",
              height: "85",
              fill: "none",
            },
          }),
        ]),
      ]),
      _c(
        "g",
        {
          attrs: {
            id: "그룹_41955",
            "data-name": "그룹 41955",
            transform: "translate(22704 12038)",
          },
        },
        [
          _c(
            "g",
            {
              attrs: {
                id: "그룹_41950",
                "data-name": "그룹 41950",
                transform: "translate(-22641 -12038)",
              },
            },
            [
              _c("path", {
                attrs: {
                  id: "패스_30923",
                  "data-name": "패스 30923",
                  d: "M379.958,99.99H368.236v28.059h38.232l9.363-10.194H379.958Z",
                  transform: "translate(-209.246 -56.818)",
                  fill: "#173357",
                },
              }),
              _c(
                "g",
                { attrs: { id: "그룹_41948", "data-name": "그룹 41948" } },
                [
                  _c(
                    "g",
                    {
                      attrs: {
                        id: "그룹_41947",
                        "data-name": "그룹 41947",
                        "clip-path": "url(#clip-path)",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          id: "패스_30924",
                          "data-name": "패스 30924",
                          d: "M255.561,33.358a4.729,4.729,0,1,0,4.73,4.73,4.736,4.736,0,0,0-4.73-4.73m0,19.652a14.923,14.923,0,1,1,14.923-14.923,14.94,14.94,0,0,1-14.923,14.923",
                          transform: "translate(-136.74 -13.163)",
                          fill: "#173357",
                        },
                      }),
                      _c("path", {
                        attrs: {
                          id: "패스_30925",
                          "data-name": "패스 30925",
                          d: "M337.392,46.1,327.2,56.854v-45.3h10.193Z",
                          transform: "translate(-185.927 -6.565)",
                          fill: "#173357",
                        },
                      }),
                      _c("path", {
                        attrs: {
                          id: "패스_30926",
                          "data-name": "패스 30926",
                          d: "M174.173,0H140.86V30.073h33.313ZM163.979,19.878H151.053V10.194h12.926Z",
                          transform: "translate(-80.042)",
                          fill: "#173357",
                        },
                      }),
                      _c("path", {
                        attrs: {
                          id: "패스_30927",
                          "data-name": "패스 30927",
                          d: "M142.372,118.007h28.343V96.544H142.636V92.186H170.11l8.672-10.194h-36.92l-9.683,10.925V105.44h28.343v3.439H132.179v22.712h37.958l8.071-9.788H142.372Z",
                          transform: "translate(-75.109 -46.591)",
                          fill: "#173357",
                        },
                      }),
                      _c("path", {
                        attrs: {
                          id: "패스_30928",
                          "data-name": "패스 30928",
                          d: "M45.454,36.638V11.554H35.261v54.8H20.019V58.428a14.922,14.922,0,1,0-10.193,0v7.923H.675V76.545H36.623L45.454,67.1V46.832h6.1l9.262-10.194Zm-25.8,7.783a4.73,4.73,0,1,1-4.73-4.73,4.735,4.735,0,0,1,4.73,4.73M.675,27.058h28.5V16.864H20.019v-5.3H9.826v5.3H.675Z",
                          transform: "translate(0 -6.565)",
                          fill: "#173357",
                        },
                      }),
                      _c("path", {
                        attrs: {
                          id: "패스_30929",
                          "data-name": "패스 30929",
                          d: "M247.169,128.244H261.5l9.326-11.418,9.327,11.418h14.33l-23.654-27.91,0,0Z",
                          transform: "translate(-140.451 -57.014)",
                          fill: "#173357",
                        },
                      }),
                      _c("path", {
                        attrs: {
                          id: "패스_30930",
                          "data-name": "패스 30930",
                          d: "M441.511,14.49h-30.35L402.734,23.3h28.931Z",
                          transform: "translate(-228.849 -8.233)",
                          fill: "#e4308e",
                        },
                      }),
                      _c("path", {
                        attrs: {
                          id: "패스_30931",
                          "data-name": "패스 30931",
                          d: "M469.74,23.3V50.838l9.847-9.987V14.489Z",
                          transform: "translate(-266.925 -8.233)",
                          fill: "#aa005e",
                        },
                      }),
                      _c("path", {
                        attrs: {
                          id: "패스_30932",
                          "data-name": "패스 30932",
                          d: "M403.589,50.221H375.676l-9.594,10.073h26.427Z",
                          transform: "translate(-208.022 -28.537)",
                          fill: "#3369a5",
                        },
                      }),
                      _c("path", {
                        attrs: {
                          id: "패스_30933",
                          "data-name": "패스 30933",
                          d: "M427.29,60.294V89.357l11.079-11.163V50.221Z",
                          transform: "translate(-242.803 -28.537)",
                          fill: "#173357",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _c("rect", {
            attrs: {
              id: "사각형_31246",
              "data-name": "사각형 31246",
              width: "337",
              height: "85",
              transform: "translate(-22704 -12038)",
              fill: "none",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }