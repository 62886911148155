
















































import { Vue, Component, Ref, Watch } from 'vue-property-decorator'
import FileUpload from '@/views/utils/FileUpload.vue'
import DataDownloadService from '@/services-itgo/data.download.service'
import { mdiUpload } from '@mdi/js';

@Component({
  components: {
    FileUpload,
  },
})
export default class MapUpload extends Vue {
  version = 'v1.0.0'
  file = {}

  icons = {
    mdiUpload
  }

  mapInfo:any = {}

  mounted(){
    this.getMapDownload()
  }

  async getMapDownload(){
    try {
      this.mapInfo = await DataDownloadService.checkingDataDownload()
      this.version = this.mapInfo?.version? this.mapInfo.version : '-'
      if(this.mapInfo.status === "updating"){
        await this.progressBar()
      }else if(this.mapInfo.status === "none"){
        this.version = this.mapInfo.currentVersion?.version ? this.mapInfo.currentVersion.version : '-'
      }
    }catch (e) {
      await this.$alert({
        title: '지도 정보 error',
        content: e.response.data.errMsg,
      })
    }finally {
    }
  }

  get isDownloadFlag(){
    return this.mapInfo?.status === 'willUpdate';
  }

  async downloadRunning(){
    try {
      if(this.mapInfo?.status === 'willUpdate'){
        await DataDownloadService.runDataDownload()
        this.progressBar()
      }
    }catch (e) {
      await this.$alert({
        title: '지도 update 에러',
        content: e.response.data.errMsg,
      })
    }finally {

    }

  }

  interval : any = 0
  progressBar(){
    this.interval = setInterval(async () => {
      this.mapInfo = await DataDownloadService.checkingDataDownload()
    }, 1000)
  }


  @Watch('mapInfo', {deep:true})
  watchMapDownloadStatus(value){
    if(this.mapInfo?.status === "none") {
      this.version = this.mapInfo.currentVersion?.version ? this.mapInfo.currentVersion.version : '-'
      clearInterval(this.interval)
    }
  }

  beforeDestroy(){
    clearInterval(this.interval)
  }

  async downloadReset(){
    try {
      await DataDownloadService.reset()
      await this.getMapDownload()
    }catch (e) {

    }finally {

    }
  }
}
