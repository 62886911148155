import axios from 'axios'

class AdminService {
  private apiUrl: string

  constructor() {
    this.apiUrl = '/admin'
  }

  /**
   * @param body
   * 필수
   * name: 이름
   * mobile: 휴대폰번호
   * loginId: 로그인 ID
   * password: 비밀번호
   * controllerRole: 관리자 Type (1:Admin, 2:Manager, 3:Call Center)
   *
   * 선택
   * companyId: 사업자 ID
   * detailInfo: 상세 정보
   */
  // setAdminSignup(body) {
  //   return axios.post(this.apiUrl + `/signup`, body).then((res) => {
  //     return Promise.resolve(res.data)
  //   })
  // }

  /**
   * 주선사 로그아웃
   */
  signout() {
    return axios.post(this.apiUrl + '/signout').then((res) => res.data)
  }

  /**
   * 내 정보 조회
   */
  getMyInfo() {
    return axios.get(this.apiUrl + `/my-info`).then((res) => {
      return Promise.resolve(res.data)
    })
  }

  /**
   * 회원 가입 심사 승인
   */
  approveUserReview(params) {
    return axios
      .post(this.apiUrl + `/confirm-item-type`, params)
      .then((res) => {
        return Promise.resolve(res.data)
      })
  }

  /**
   * 회원 가입 심사 거절
   */
  rejectUserReview(params) {
    return axios.post('/user-reject-note', params).then((res) => {
      return Promise.resolve(res.data)
    })
  }

  /**
   * 사용자별 reject 목록 조회 (for admin)
   */
  getRejectUserIdReview(params) {
    return axios.get('/user-reject-note/get-type-reject', {params: params}).then((res) => {
      return Promise.resolve(res.data)
    })
  }

  /**
   * 관리자 계정 목록
   */
  getManagerList(params) {
    return axios.get(this.apiUrl + '/list', {params: params}).then((res) => {
      return Promise.resolve(res.data)
    })
  }

  /**
   * 관리자 계정 비활성화
   */
  setInactiveManagerUser(body){
    return axios.put(this.apiUrl + '/inactive' , body).then((res) =>{
      return Promise.resolve(res.data)
    })
  }

  /**
   * 관리자 계정 변경
   */
  setManagerUserModify(body){
    return axios.put(this.apiUrl + '/modify', body).then((res)=>{
      return Promise.resolve(res.data)
    })
  }

  /**
   * 관리자 계정 생성
   */
  setNewManagerUser(body){
    return axios.post(this.apiUrl + '/signup', body).then((res) =>{
      return Promise.resolve(res.data)
    })
  }

  /**
   * 관리자 계정 삭제
   */
  setDeletedManagerUser(userId, body){
    return axios.post(this.apiUrl + `/delete`, body).then((res) =>{
      return Promise.resolve(res.data)
    })
  }

  /**
   * 차주 월간 이용내역 엑셀 다운로드
   */
  getDriverReport(body){
    return axios.post(this.apiUrl + '/driver-report', body,{responseType:"blob"}).then((res) =>{
      return Promise.resolve(res.data)
    })
  }

  /**
   * 비밀번호 재설정
   * {
   *     "password": "123456",
   *     "mobile" : "01022221113"
   * }
   */
  setUserPasswordReset(body) {
    return axios.post(this.apiUrl + `/password`, body).then((res) => {
      return Promise.resolve(res.data)
    })
  }
}

export default new AdminService()
