



































import { Vue, Component, Prop, PropSync, Watch } from 'vue-property-decorator'

@Component
export default class Pagination extends Vue {
  @Prop(Number) totalPageCount
  @Prop(Array) pageSizes

  /**
   * two-way
   */
  @Prop({ type: Number, default: 10 }) pageSize
  @Prop({ type: Number, default: 1 }) pageNum

  @PropSync('pageSize', { type: Number }) syncedPageSize
  @PropSync('pageNum', { type: Number }) syncedPageNum

  @Watch('syncedPageSize')
  onPageSizeChanged(cur) {
    this.$emit('update:pageSize', cur)
  }
  @Watch('syncedPageNum')
  onPageNumChanged(cur) {
    this.$emit('update:pageNum', cur)
  }

  handlePageSizeChange(value) {
    this.syncedPageSize = value
  }

  handlePageNumChange(value) {
    this.syncedPageNum = value
  }
}
