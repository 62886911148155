import axios from 'axios'

class FaqService {
    private apiUrl: string
    constructor() {
        this.apiUrl = '/faq'
    }

    // 4. FAQ 목록 조회
    getPageList(params){
        return axios.get(this.apiUrl + `/page`, {params: params}).then((res) => res.data)
    }

    // 6. FAQ 유형 목록 조회 
    getCategories(){
        return axios.get(this.apiUrl + `/categories`).then(res=> res.data)
    }

    // 7. FAQ 상세 조회
    // getFaqDetails(id){
    //     return axios.get(this.apiUrl+`/${id}`).then(res=> res.data)
    // }

    // 3. FAQ 삭제
    deleteFaq(id){
        const params : any ={
            faqIdList: id
        }
        return axios.delete(this.apiUrl, {params:params}).then(res=> res.data)
    }

    // 8. FAQ PUSH 발송
    // postAppPush(params){
    //     return axios.post(this.apiUrl+`/app-push`,{
    //         faqId : params.id,
    //         appPushTitle: params.title,
    //         appPushContent: params.content,
    //     }).then(res => res.data)
    // }

    // 9. FAQ SMS 발송
    // postSmsPush(params){
    //     return axios.post(this.apiUrl+`/sms-pus`,{
    //         faqId : params.id,
    //         smsPushTitle: params.title,
    //         smsPushContent: params.content,
    //     }).then(res => res.data)
    // }

    // 1. FAQ 등록
    postRegisterFaq(body){
        return axios.post(this.apiUrl, body).then(res => res.status)
    }

    // 2. FAQ 수정
    putEditFaq(body){
        return axios.put(this.apiUrl, body).then(res => res.status)
    }
}

export default new FaqService()