<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="viewBox"
  >
    <g id="ic_full" transform="translate(-41.5 -393.5)">
      <g
        id="그룹_40923"
        data-name="그룹 40923"
        transform="translate(-1564 -80)"
      >
        <g
          id="그룹_40408"
          data-name="그룹 40408"
          transform="translate(1535 124)"
        >
          <g
            transform="matrix(1, 0, 0, 1, 70.5, 349.5)"
            filter="url(#사각형_31236)"
          >
            <rect
              id="사각형_31236-2"
              data-name="사각형 31236"
              width="34"
              height="34"
              rx="4"
              transform="translate(4.5 4.5)"
              fill="#fff"
            />
          </g>
        </g>
        <path
          id="Icon_awesome-expand"
          data-name="Icon awesome-expand"
          d="M0,8.9V3.329A1.076,1.076,0,0,1,1.079,2.25H6.652a.541.541,0,0,1,.539.539v1.8a.541.541,0,0,1-.539.539H2.876V8.9a.541.541,0,0,1-.539.539H.539A.541.541,0,0,1,0,8.9ZM12.944,2.789v1.8a.541.541,0,0,0,.539.539h3.775V8.9a.541.541,0,0,0,.539.539h1.8a.541.541,0,0,0,.539-.539V3.329A1.076,1.076,0,0,0,19.056,2.25H13.483A.541.541,0,0,0,12.944,2.789Zm6.652,12.4H17.8a.541.541,0,0,0-.539.539v3.775H13.483a.541.541,0,0,0-.539.539v1.8a.541.541,0,0,0,.539.539h5.573a1.076,1.076,0,0,0,1.079-1.079V15.733A.541.541,0,0,0,19.6,15.194Zm-12.4,6.652v-1.8a.541.541,0,0,0-.539-.539H2.876V15.733a.541.541,0,0,0-.539-.539H.539A.541.541,0,0,0,0,15.733v5.573a1.076,1.076,0,0,0,1.079,1.079H6.652A.541.541,0,0,0,7.191,21.845Z"
          transform="translate(1616.933 482.683)"
          fill="#2f2f2f"
          stroke="#fff"
          stroke-width="1.2"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'full_icon',

  props: {
    viewBox: { type: String, default: '0 0 43 43' },
    width: { type: [Number, String], default: 43 },
    height: { type: [Number, String], default: 43 },
  },
}
</script>

<style scoped></style>
