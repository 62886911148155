import axios from 'axios'

class UserStatusService {
  private apiUrl: string
  constructor() {
    this.apiUrl = '/user-status'
  }

  // 회원 상태 목록
  getUserStatusList(params) {
    return axios.get(this.apiUrl + `/page`, { params }).then((res) => res.data)
  }

  // 회원 상태 목록
  postUserStatusList(body) {
    return axios.post(this.apiUrl, body).then((res) => res.data)
  }
}

export default new UserStatusService()
