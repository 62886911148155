var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: _vm.width,
        height: _vm.height,
        viewBox: _vm.viewBox,
      },
    },
    [
      _c(
        "g",
        {
          attrs: {
            id: "그룹_41658",
            "data-name": "그룹 41658",
            transform: "translate(-1488.5 -149.5)",
          },
        },
        [
          _c(
            "g",
            {
              attrs: {
                id: "그룹_40927",
                "data-name": "그룹 40927",
                transform: "translate(1418 -200)",
              },
            },
            [
              _c(
                "g",
                {
                  attrs: {
                    transform: "matrix(1, 0, 0, 1, 70.5, 349.5)",
                    filter: "url(#패스_29526)",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      id: "패스_29526-2",
                      "data-name": "패스 29526",
                      d: "M4,0H30a4,4,0,0,1,4,4V30a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4V4A4,4,0,0,1,4,0Z",
                      transform: "translate(4.5 4.5)",
                      fill: "#fff",
                      opacity: "0.9",
                    },
                  }),
                ]
              ),
            ]
          ),
          _c(
            "g",
            {
              attrs: {
                id: "그룹_40926",
                "data-name": "그룹 40926",
                transform: "translate(1401 -242.279)",
              },
            },
            [
              _c(
                "g",
                {
                  attrs: {
                    id: "그룹_40887",
                    "data-name": "그룹 40887",
                    transform: "translate(0.886)",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      id: "패스_29500",
                      "data-name": "패스 29500",
                      d: "M-14962.472-13477.722h5.172v5.437",
                      transform:
                        "translate(-14845.771 -13068.006) rotate(-180)",
                      fill: "none",
                      stroke: "#2f2f2f",
                      "stroke-linejoin": "round",
                      "stroke-width": "1.6",
                    },
                  }),
                  _c("path", {
                    attrs: {
                      id: "패스_29501",
                      "data-name": "패스 29501",
                      d: "M0,5.437H5.172V0",
                      transform: "translate(99.528 404.279)",
                      fill: "none",
                      stroke: "#2f2f2f",
                      "stroke-linejoin": "round",
                      "stroke-width": "1.6",
                    },
                  }),
                ]
              ),
              _c(
                "g",
                {
                  attrs: {
                    id: "그룹_40888",
                    "data-name": "그룹 40888",
                    transform: "translate(217.114 824.995) rotate(180)",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      id: "패스_29500-2",
                      "data-name": "패스 29500",
                      d: "M-14962.472-13477.722h5.172v5.437",
                      transform:
                        "translate(-14845.771 -13068.006) rotate(-180)",
                      fill: "none",
                      stroke: "#2f2f2f",
                      "stroke-linejoin": "round",
                      "stroke-width": "1.6",
                    },
                  }),
                  _c("path", {
                    attrs: {
                      id: "패스_29501-2",
                      "data-name": "패스 29501",
                      d: "M0,5.437H5.172V0",
                      transform: "translate(99.528 404.279)",
                      fill: "none",
                      stroke: "#2f2f2f",
                      "stroke-linejoin": "round",
                      "stroke-width": "1.6",
                    },
                  }),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }