















































































import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import logi from '@/components/map/logi-maps-api'
//import RouteService from '@/services/route.service'
import _ from 'lodash'
import { Getter, State } from 'vuex-class'
import {
  mdiPlus,
  mdiMinus,
  mdiMagnifyPlusOutline,
  mdiMagnifyMinusOutline,
} from '@mdi/js'
import CallService from '@/services-itgo/call.service'
import fullIcon from '@/views/icons/map/FullIcon.vue'
import smallIcon from '@/views/icons/map/SmallIcon.vue'
import MaskingUtil from '@/utils/masking.utils'
import CALL_STATUS from '@/views/constant/call.status'
import CALL_REQUEST_STATUS from '@/views/constant/call.request.status'
import DatetimeUtils from '@/utils/datetime.utils'

@Component({
  components: {
    fullIcon,
    smallIcon,
  },
})
export default class MapCard extends Vue {
  @Prop(Object) callRequestInfo
  @Getter('serverConfig/getCurrentConfigRefresh') refreshTime

  public logiMap: any = null
  levelRange: any = { min: 8, max: 18 } //set levelRange for logi.maps engine
  zoomLevel: number = 15
  isMouseDownAndMove: boolean = false
  callRequestRoute: any = {}
  icons: object = {
    mdiPlus,
    mdiMinus,
    mdiMagnifyPlusOutline,
    mdiMagnifyMinusOutline,
  }

  public setIntervalTimer: any = null
  public isMapPlus: boolean = false

  @Watch('callRequestInfo')
  async changedCallRequestInfo(value, oldValue) {
    this.routeLineObject = null
    this.trackLineObject = null
    this.driverImageObject = null
    this.pickupPointFlag = null
    this.dropoffPointFlag = null
    this.waypointFlag = null
    this.startPointFlag = null
    this.preCallStatus = 0

    await this.logiMap.removeAll()
    await this.loadCallRequestRouteInfo()
  }

  @Watch('callId')
  changedCallId(value, oldValue) {
    this.isMapPlus = false
  }

  created() {
    console.log(`MapCard created()`)
    this.initMap()
  }

  mounted() {
    console.log(`MapCard mounted()`)
    this.loadCallRequestRouteInfo()
  }

  beforeDestroy() {
    this.$emit('mapSizeMinus')
    if (this.setIntervalTimer) clearInterval(this.setIntervalTimer)
    console.log(`beforeDestroy------------------------------`)
  }

  /**
   * 맵 초기화
   */
  initMap() {
    const _this = this
    setTimeout(() => {
      if (!_this.logiMap) {
        _this.logiMap = new logi.maps.Map('div_canvas', _this)
        _this.logiMap.enableWheelEvent()

        const mapCanvas: HTMLElement | null =
          document.getElementById('div_canvas')

        if (mapCanvas) {
          const handleScroll = (event: WheelEvent) => {
            event.stopPropagation()
            event.preventDefault()
          }

          mapCanvas.addEventListener('wheel', handleScroll, { passive: false })
        }
      }
      _this.logiMap.setCenter({ lng: 126.88744, lat: 37.57998 })
      _this.logiMap.setLevel(this.zoomLevel)
    }, 200)
  }

  OnMouseDown(event) {
    this.isMouseDownAndMove = true

    const clickScreenPoint = { x: event.clientX, y: event.clientY - (30 + 16) }
    // const clickScreenPoint = {x:event.clientX, y:event.clientY};

    // 클릭한 스크린좌표를 월드좌표로 변환
    const latlng = this.logiMap.screen2world({
      x: clickScreenPoint.x,
      y: clickScreenPoint.y,
    })
  }

  OnMouseMove(event) {
    this.isMouseDownAndMove = true
  }

  // 맵 마우스 업 이벤트
  OnMouseUp(event) {
    this.isMouseDownAndMove = false
  }

  OnDoubleClick(event) {}

  OnDraw() {}

  // 맵 리사이즈
  resize() {}

  /**
   * Call request info route
   */
  public routeLineObject: any = null
  public trackLineObject: any = null
  public driverImageObject: any = null
  public pickupPointFlag: any = null
  public dropoffPointFlag: any = null
  public waypointFlag: any = null
  public startPointFlag: any = null
  public preCallStatus: number = 0

  async loadCallRequestRouteInfo() {
    if (!this.callRequestInfo.callInfos[0].callId) return

    await new Promise((resolve) => setTimeout(resolve, 500))

    const params: any = {
      callId: this.callRequestInfo.callInfos[0].callId,
      getDestination: true,
    }
    this.callRequestRoute = await CallService.getTrackRoute(params)

    this.preCallStatus = _.cloneDeep(this.callRequestRoute.callStatus)

    //상차 하차 경유지 출발지 pin 그림
    console.log(this.callRequestRoute)
    this.drawCallRequestPoints()

    if (!_.isNil(this.callRequestRoute.currentAddress)) {
      this.$emit(
        'currentAddress',
        this.callRequestRoute.currentAddress.intersectinglocality,
      )
    }

    //현재 완료 상태 일 경우 route 안보여주게 함.
    if (
      this.callRequestInfo.callRequestStatus !==
        CALL_REQUEST_STATUS.COMPLETED &&
      this.callRequestRoute.route.coordinates
    ) {
      this.routeLineObject = this.drawDestinationRoute(
        this.callRequestRoute.route,
        this.callRequestInfo.callInfos[0].callId,
      )
    }

    if (this.callRequestRoute.tracks.length) {
      this.trackLineObject = this.drawTrack(
        this.callRequestRoute.tracks,
        this.callRequestInfo.callInfos[0].callId,
      )
      const track: any = _.last(this.callRequestRoute.tracks)
      this.logiMap.setCenter({
        lng: track.matchedLocation.x,
        lat: track.matchedLocation.y,
      })

      this.setDriverDraw(track)
    }

    //운행 중일때만 setInterval 구동 시킴
    if (
      _.includes(
        CALL_STATUS.DRIVING_STATUS_LIST(),
        this.callRequestInfo.callInfos[0].callStatus,
      )
    ) {
      this.setIntervalTimerFunction()
    }
  }

  /**
   * 의뢰 콜 지도 표시 (픽업, 목적지)
   */
  drawCallRequestPoints() {
    console.log(this.callRequestRoute.callStatus)
    // 출발 -> 상차지 : 5,21(출발 / 상차지)
    if (
      this.callRequestRoute.callStatus === CALL_STATUS.STARTED ||
      this.callRequestRoute.callStatus === CALL_STATUS.ARRIVED_PICKUP
    ) {
      if (this.callRequestRoute.tracks.length) this.startPointDraw()
      this.pickupPointDraw()
    }
    //상차지 -> 하자치 / 상차지 -> 경유지 6,22,2, 25
    else if (
      this.callRequestRoute.callStatus === CALL_STATUS.PICKED_UP ||
      this.callRequestRoute.callStatus === CALL_STATUS.ARRIVED_DROPOFF ||
      this.callRequestRoute.callStatus === CALL_STATUS.ARRIVED_PICKUP_RETURN ||
      this.callRequestRoute.callStatus === CALL_STATUS.ARRIVED_WAYPOINT ||
      this.callRequestRoute.callStatus === CALL_STATUS.PICKUP_RETURNED
    ) {
      this.pickupPointDraw()

      if (
        this.callRequestInfo.callDestinations.length === 3 &&
        this.callRequestInfo.orderInfos[0].deliveryRound !== 'return'
      ) {
        this.wayPointDraw()
      } else {
        this.dropoffPointDraw()
      }
    } else if (
      this.callRequestRoute.callStatus === CALL_STATUS.LEFT_WAYPOINT ||
      this.callRequestRoute.callStatus === CALL_STATUS.ARRIVED_DROPOFF
    ) {
      this.dropoffPointDraw()
      this.wayPointDraw()
    } else if (
      this.callRequestRoute.callStatus === CALL_STATUS.PICKUP_RETURNED
    ) {
      this.totalPointDraw()
    } else if (
      this.callRequestRoute.callStatus === CALL_STATUS.DROPPED_OFF ||
      this.callRequestRoute.callStatus === CALL_STATUS.INVOICE_REGISTERED ||
      this.callRequestRoute.callStatus === CALL_STATUS.COMPLETED
    ) {
      if (this.callRequestInfo.orderInfos[0].deliveryRound === 'return') {
        if (
          this.callRequestRoute.tracks.length &&
          this.callRequestRoute.callStatus !== CALL_STATUS.DROPPED_OFF
        )
          this.startPointDraw()
        this.pickupPointDraw()
        this.dropoffPointDraw()
      } else {
        if (this.callRequestRoute.tracks.length) this.startPointDraw()
        this.totalPointDraw()
      }
    }
  }

  startPointDraw() {
    this.startPointFlag = this.drawAssignedCallRequestDestination(
      this.callRequestInfo.callInfos[0].callId,
      this.callRequestRoute.tracks[0].matchedLocation,
      'STARTPOINT',
    )
    console.log(this.startPointFlag)
  }
  pickupPointDraw() {
    const pickup: any = _.find(this.callRequestInfo.callDestinations, {
      type: 'PICKUP',
    })

    this.pickupPointFlag = this.drawAssignedCallRequestDestination(
      pickup.destinationId,
      pickup.point,
      pickup.type,
    )
    console.log(this.pickupPointFlag)
  }
  dropoffPointDraw() {
    const dropoff: any = _.find(this.callRequestInfo.callDestinations, {
      type: 'DROPOFF',
      numOfOrder: 2,
    })
    this.dropoffPointFlag = this.drawAssignedCallRequestDestination(
      dropoff.destinationId,
      dropoff.point,
      dropoff.type,
    )
    console.log(this.dropoffPointFlag)
  }
  wayPointDraw() {
    const waypoint: any = _.find(this.callRequestInfo.callDestinations, {
      type: 'WAYPOINT',
    })
    this.waypointFlag = this.drawAssignedCallRequestDestination(
      waypoint.destinationId,
      waypoint.point,
      waypoint.type,
    )
    console.log(this.waypointFlag)
  }
  totalPointDraw() {
    _.forEach(this.callRequestInfo.callDestinations, (callDestination) => {
      if (callDestination.type === 'PICKUP') this.pickupPointDraw()
      else if (callDestination.type === 'DROPOFF') this.dropoffPointDraw()
      else if (callDestination.type === 'WAYPOINT') this.wayPointDraw()
    })
  }
  /**
   * 의뢰 차된 목적지 그리기
   * @param destinationId
   * @param point
   * @param destType
   */
  drawAssignedCallRequestDestination(destinationId, point, destType) {
    if (destType == 'PICKUP')
      this.logiMap.setCenter({ lng: point.x, lat: point.y })

    let img: string = ''

    switch (destType) {
      case 'PICKUP':
        img = '../images/itgo/icon_flag_blue.png'
        break

      case 'DROPOFF':
        img = '../images/itgo/icon_flag_magenta.png'
        break

      case 'WAYPOINT':
        img = '../images/itgo/icon_flag_navy.png'
        break

      case 'STARTPOINT':
        img = '../images/itgo/icon_flag_gray.png'
        break

      default:
        break
    }

    let destination = new logi.maps.Image(
      img,
      { lng: point.x, lat: point.y },
      { key: `pin-${destinationId}`, angle: 0 },
    )
    this.logiMap?.addImage(destination)
    return destination
  }

  /**
   * track 정보 지도에 표시
   */
  drawTrack(tracks, callId) {
    let color = '#94A2B4'
    let bgColor = '#D3D3D3'

    const lineObject = new logi.maps.Line(logi.maps.LINETYPE.POLY, {
      key: `tracks-${callId}`,
      latlngs: tracks.map((item) => ({
        lng: item.matchedLocation.x,
        lat: item.matchedLocation.y,
      })),
      width: 3,
      color: color,
      strokeWidth: 1,
      strokeColor: bgColor,
    })
    this.logiMap.addLine(lineObject)
    return lineObject
  }

  /**
   * 경유지 그리기
   * @param callId
   * @param destinationId
   * @param routeInfo
   * @param destType
   */
  drawDestinationRoute(route, callId) {
    let color = '#196CE7'
    let bgColor = '#196CE7'

    const result = route.coordinates.map((item) => ({
      lat: item.y,
      lng: item.x,
    }))

    const lineObject = new logi.maps.Line(logi.maps.LINETYPE.POLY, {
      key: `route-${callId}`,
      latlngs: route.coordinates.map((item) => ({
        lat: item.y,
        lng: item.x,
      })),
      width: 3,
      color: color,
      strokeWidth: 1,
      strokeColor: bgColor,
    })
    this.logiMap.addLine(lineObject)
    return lineObject
  }

  setDriverDraw(track) {
    const _this = this

    //기사 아이콘이 없을 경우 생성함.
    if (!this.driverImageObject) {
      ///////진행에 대한 정보를 넣어야함.
      const pickUp: any = _.find(this.callRequestInfo.callDestinations, {
        type: 'PICKUP',
      })

      const dropOff: any = _.find(this.callRequestInfo.callDestinations, {
        type: 'DROPOFF',
      })

      const route: any = _.find(this.callRequestInfo.callDestinations, {
        type: 'WAYPOINT',
      })

      let pickUpDetail: string =
        pickUp.adminAreaAbbr + ' ' + pickUp.subAdminAreaAbbr
      let dropOffDetail: string =
        dropOff.adminAreaAbbr + ' ' + dropOff.subAdminAreaAbbr

      let wayPointDetail: any = null

      if (!_.isEmpty(route)) {
        wayPointDetail = route.adminAreaAbbr + ' ' + route.subAdminAreaAbbr
      }

      this.driverImageObject = _this.drawDriverIcon(
        this.callRequestInfo.callInfos[0].callId,
        track.matchedLocation,
        track.angle,
        MaskingUtil.maskingName(this.callRequestInfo.driverName),
        pickUpDetail,
        dropOffDetail,
        wayPointDetail,
        track.regDt,
      )

      //차량 현지 위치를 중앙으로
      _this.logiMap.setCenter({
        lng: track.matchedLocation.x,
        lat: track.matchedLocation.y,
      })
    }
    //기사 아이콘이 있응 경우 update 시킴
    else {
      this.driverImageObject.move(
        {
          lng: track.matchedLocation.x,
          lat: track.matchedLocation.y,
        },
        track.angle,
      )
      //
      // //차량 현지 위치를 중앙으로
      // _this.logiMap.setCenter({
      //   lng: track.matchedLocation.x,
      //   lat: track.matchedLocation.y,
      // })
    }
  }

  /**
   * 차량 그리기
   * @param callId
   * @param point
   * @param angle
   * @param driverName
   * @param pickUpDetail
   * @param dropOffDetail
   * @param wayPointDetail
   */
  drawDriverIcon(
    callId,
    point,
    angle,
    driverName,
    pickUpDetail,
    dropOffDetail,
    wayPointDetail,
    regDt,
  ) {
    const key = `rider_${callId}`

    const vehicleImg =
      this.callRequestInfo.callInfos[0].callStatus === CALL_STATUS.COMPLETED
        ? '../images/itgo/truck_disabled.svg'
        : '../images/itgo/truck_0.svg'

    let image = new logi.maps.Image(
      vehicleImg,
      { lng: point.x, lat: point.y },
      { key: key, angle: angle },
    )

    let routeText: string = ''
    let bgImageText: string = '/images/itgo/tag_normal.png'
    if (this.callRequestInfo.orderInfos[0].deliveryRound === 'oneway') {
      if (_.isNull(wayPointDetail)) {
        routeText = pickUpDetail + ` → ` + dropOffDetail
      } else {
        bgImageText = '/images/itgo/tag_route.png'
        routeText =
          pickUpDetail + ` → ` + wayPointDetail + ` → ` + dropOffDetail
      }
    } else {
      routeText = pickUpDetail + ` ↔ ` + dropOffDetail
    }

    image.textInfo = {
      offsetX: 0,
      offsetY: -45,
      text: ' ',
      textColor: '#FFFFFF',
      fontSize: 12,
      textAlign: logi.maps.ALIGN.CB,
      bgImgSrc: bgImageText,
      bgImgAlign: logi.maps.ALIGN.CM,
    }

    image.addTextInfo({
      offsetX: 0,
      offsetY: -54,
      text: routeText,
      textColor: '#FFFFFF',
      fontSize: 12,
      textAlign: logi.maps.ALIGN.CT,
    })

    image.addTextInfo({
      offsetX: 0,
      offsetY: -39,
      text: `${driverName} 기사`,
      textColor: '#FFFFFF',
      fontSize: 12,
      textAlign: logi.maps.ALIGN.CT,
    })

    image.addTextInfo({
      offsetX: 0,
      offsetY: -70,
      text: `${DatetimeUtils.stringDate(regDt, 'YY-MM-DD HH:mm')}`,
      textColor: '#FFFFFF',
      fontSize: 11,
      textAlign: logi.maps.ALIGN.CT,
    })

    this.logiMap.addImage(image)
    return image
  }

  //2초마다 확인하는 기능 구현해두었음
  //어떻게 진행 할지 확인 후 차주 진행 해야함.
  setIntervalTimerFunction() {
    if (this.setIntervalTimer === null) {
      this.setIntervalTimer = setInterval(async () => {
        const params: any = {
          callId: this.callRequestInfo.callInfos[0].callId,
          getDestination: true,
        }
        this.callRequestRoute = await CallService.getTrackRoute(params)
        console.log(this.callRequestRoute)

        //point 변경이 발생해야함.
        if (this.preCallStatus !== this.callRequestRoute.callStatus) {
          if (this.startPointFlag) {
            this.logiMap.removeImage(this.startPointFlag.key)
          }
          if (this.pickupPointFlag) {
            this.logiMap.removeImage(this.pickupPointFlag.key)
          }
          if (this.dropoffPointFlag) {
            this.logiMap.removeImage(this.dropoffPointFlag.key)
          }
          if (this.waypointFlag) {
            this.logiMap.removeImage(this.waypointFlag.key)
          }
          this.drawCallRequestPoints()
        }

        if (!_.isNil(this.callRequestRoute.currentAddress)) {
          this.$emit(
            'currentAddress',
            this.callRequestRoute.currentAddress.intersectinglocality,
          )
        }

        if (this.callRequestRoute.route.coordinates) {
          this.routeLineObject = this.drawDestinationRoute(
            this.callRequestRoute.route,
            this.callRequestInfo.callInfos[0].callId,
          )
        }

        if (this.callRequestRoute.tracks.length) {
          this.trackLineObject = this.drawTrack(
            this.callRequestRoute.tracks,
            this.callRequestInfo.callInfos[0].callId,
          )
          const track: any = _.last(this.callRequestRoute.tracks)
          this.setDriverDraw(track)
        }
      }, this.refreshTime * 1000)
    }
  }

  zoomOut() {
    if (this.zoomLevel > 8) {
      this.zoomLevel = this.zoomLevel - 1
    }
    this.setZoomLevel()
  }

  zoomIn() {
    if (this.zoomLevel < 17) {
      this.zoomLevel = this.zoomLevel + 1
    }
    this.setZoomLevel()
  }

  setZoomLevel() {
    this.logiMap.setLevel(this.zoomLevel)
  }

  mapSizePlus() {
    this.isMapPlus = true
    this.$emit('mapSizePlus')
  }

  mapSizeMinus() {
    this.isMapPlus = false
    this.$emit('mapSizeMinus')
  }
}
