


































































































import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { State } from 'vuex-class'
import CALL_STATUS from '@/views/constant/call.status'
import CALL_REQUEST_STATUS from '@/views/constant/call.request.status'
import CALL_HISTORY_STATUS from '@/views/constant/call.history.status.constant'
import USER_ROLE_TYPE from '@/views/constant/user.role.type'
import { formatDate } from '@/utils/business.util'
import callRequestStatus from '../constant/call.request.status'
import callHistoryService from '@/services-itgo/call.history.service'
import _ from 'lodash'
import { mdiWindowClose } from '@mdi/js'

@Component({
  name: 'FreightHistoryPopup',
  components: {
    PerfectScrollbar,
  },
})
export default class FreightHistoryPopup extends Vue {
  @Prop(Boolean) value
  @Prop(Object) item

  @State((state) => state.auth.user) loginUser

  @Watch('value')
  openPopup(value) {
    if (value) return this.fetchCallHistory()

    this.history = []
  }

  icons: any = {
    mdiWindowClose,
  }

  get callRequestCode() {
    return this.item?.callRequestCode || ''
  }

  perfectScrollbarOptions: any = {
    maxScrollbarLength: 30,
    wheelPropagation: false,
  }

  callStatus: any = CALL_STATUS
  callRequestStatus: any = CALL_REQUEST_STATUS
  userRoleType: any = USER_ROLE_TYPE
  callHistoryStatus: any = CALL_HISTORY_STATUS

  history: any[] = []

  async fetchCallHistory() {
    try {
      this.history = []

      const id = this.item.callInfos[0].callRequestId

      const data = await callHistoryService.getCallHistoryWithStatus(id)

      this.history = _.reverse(data)
    } catch (error) {
      console.log(error)
    }
  }

  systemStatusText(item) {
    if (item.callHistoryStatus === 'CANCELED_BY_SCHEDULE')
      return '상차일자 경과로 인한 화물취소'
    else if (item.callHistoryStatus === 'CHANGED')
      return '요청 사항을 승인하여 변경 되었습니다.'
    else if (
      item.newCallRequestStatus === CALL_REQUEST_STATUS.ASSIGNABLE &&
      item.oldCallRequestStatus === CALL_REQUEST_STATUS.ASSIGN_REQUEST
    )
      return '배차 요청 시간 초과로 배차대기 변경됨'
    else return ''
  }

  onClickClose() {
    this.$emit('close')
  }
}
