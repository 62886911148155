










































































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import FreightStatusType from '@/views/freightHistory/FreightStatusType.vue'
import FreightPaymentType from '@/views/freightHistory/FreightPaymentType.vue'
import Vue2DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ko'
import DatetimeUtils from '@/utils/datetime.utils'
import Pagination from '@/views/utils/Pagination.vue'
import { mdiMagnify, mdiChevronDown } from '@mdi/js'
import MapCard from '@/views/map/MapCard.vue'
import { State } from 'vuex-class'
import FreightHistoryPopup from '@/views/freightHistory/FreightHistoryPopup.vue'
import billingService from '@/services-itgo/billing.service'
import _ from 'lodash'

@Component({
  components: {
    Vue2DatePicker,
    FreightStatusType,
    FreightPaymentType,
    MapCard,
    Pagination,
    FreightHistoryPopup,
  },

  async mounted() {
    this.fetchBillingList()
  },
})
export default class ServiceFeeTab extends Vue {
  @Prop(Number) id
  @State((state) => state.auth.user) loginUser

  icons: any = {
    mdiMagnify,
    mdiChevronDown,
  }

  type = 0
  typeItems = [
    { text: '전체', value: 0 },
    { text: '27,500원(25톤 미만)', value: 1, type: '27500' },
    { text: '33,000원(25톤 이상)', value: 2, type: '33000' },
  ]

  method = 0
  methodItems = [
    { text: '전체', value: 0 },
    { text: '카드 결제', value: 1 },
    { text: '간편 결제', value: 2 },
  ]

  getToTalAmount(value) {
    return this.typeItems.find((item) => item.type == value)?.text || '-'
  }

  date = this.setDefaultDate

  @Watch('date')
  changeDates(value) {
    if (value?.length > 1) {
      this.set(value)
    }
  }

  previousDayDate: Date[] | null = null

  async set(value) {
    const temp = [...this.date]
    temp.sort((a, b) => new Date(a).getTime() - new Date(b).getTime())

    if (temp.length === 2) {
      if (DatetimeUtils.asMonths(temp[0], temp[1]) > 12) {
        await this.$alert({
          title: '기간 설정 오류',
          content: '기간 설정은 최대 12개월까지 가능합니다.',
        })

        if (this.previousDayDate) {
          this.date = [...this.previousDayDate]
        }
      } else {
        this.previousDayDate = [...this.date]
      }
    }
  }

  get setDefaultDate() {
    const current = new Date()
    const lastMonth = new Date(current.getFullYear(), current.getMonth() - 1, 1)
    const endOfMonth = new Date(
      current.getFullYear(),
      current.getMonth() + 1,
      0,
    )

    return [lastMonth, endOfMonth]
  }

  billingInfo = {
    loading: false,
    headers: [
      {
        text: '결제 방식',
        align: 'center',
        value: 'method',
        show: true,
        // width: '7%',
      },
      {
        text: '결제 일시',
        align: 'center',
        value: 'approvedAt',
        show: true,
        // width: '8%',
      },
      {
        text: '요금 유형',
        align: 'center',
        value: 'totalAmount',
        show: true,
        // width: '12%',
      },
    ],
    items: [],
    serverItemsLength: 10,
    billingEndDate: '',
    isSubscribe: false,
  }

  isSearched = false

  get totalPageCount() {
    const length = Math.ceil(this.billingInfo.serverItemsLength / this.pageSize)
    return length === 0 ? 1 : length
  }

  /**
   * Pagiation 관련
   */
  pageSizes = [10, 20, 30]
  pageSize = 10
  pageNum = 1

  @Watch('pageSize')
  onChangePageSize() {
    this.pageNum = 1
    this.fetchBillingList()
  }

  @Watch('pageNum')
  onChangePageNum() {
    this.fetchBillingList()
  }

  async fetchBillingList() {
    try {
      this.billingInfo.loading = true
      this.billingInfo.items = []
      //this.billingInfo.serverItemsLength = 0

      const fromDate = this.date[0]
        ? DatetimeUtils.stringDate(this.date[0], 'YYYY-MM-DD')
        : this.setDefaultDate[0]
      const toDate = this.date[1]
        ? DatetimeUtils.stringDate(this.date[1], 'YYYY-MM-DD')
        : this.setDefaultDate[1]

      const params: any = {
        userId: this.id,
        searchStartDt: fromDate,
        searchEndDt: toDate,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        amount: this.type,
        type: this.method,
      }
      const data = await billingService.getBillingList(params)

      this.billingInfo.items = data.billingApprovalList.map((item) => ({
        ...item,
        key: item.orderId,
      }))
      this.billingInfo.billingEndDate = data.billingEndDate
      this.billingInfo.isSubscribe = data.isSubscribe

      this.billingInfo.serverItemsLength = data.billingApprovalList.length
    } catch (error: any) {
      this.$alert(error.response.data.errMsg)
    } finally {
      this.billingInfo.loading = false
    }
  }

  onSearch() {
    this.isSearched = true
    this.pageNum = 1
    this.fetchBillingList()
  }

  onChangePaymentStatus() {
    console.log('onChangePaymentStatus')
  }

  onChangeType() {
    console.log('onChangeType', this.type)
  }

  onChangeMethod() {
    console.log('onChangeMethode', this.method)
  }
}
