var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content-wrapper content_body" }, [
    _c(
      "div",
      { staticClass: "content-inner" },
      [
        _vm.toggleFaq === 1
          ? _c(
              "v-card",
              {
                staticClass: "d-flex flex-column justify-space-between",
                staticStyle: { "border-radius": "10px", height: "880px" },
              },
              [
                _c(
                  "div",
                  { staticClass: "faq-list" },
                  [
                    _c("v-card-title", { staticClass: "px-6 pt-8" }, [
                      _c(
                        "span",
                        {
                          staticClass: "font-size26 font-weight700 surface800",
                        },
                        [_vm._v("FAQ")]
                      ),
                    ]),
                    _c("v-card-title", { staticClass: "mt-10 pb-5 px-6" }, [
                      _c("div", { staticClass: "flex-start-contents" }, [
                        _c(
                          "div",
                          { staticStyle: { width: "15%" } },
                          [
                            _c("v-select", {
                              staticClass: "pr-2",
                              staticStyle: { width: "100%" },
                              attrs: {
                                dense: "",
                                label: "대상",
                                items: _vm.faqTargetItems,
                                "item-text": "text",
                                "item-value": "value",
                                outlined: "",
                                "hide-details": "",
                                "menu-props": { bottom: true, offsetY: true },
                              },
                              model: {
                                value: _vm.faqTarget,
                                callback: function ($$v) {
                                  _vm.faqTarget = $$v
                                },
                                expression: "faqTarget",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticStyle: { width: "15%" } },
                          [
                            _c("v-select", {
                              staticClass: "pr-2",
                              staticStyle: { width: "100%" },
                              attrs: {
                                dense: "",
                                label: "질문유형",
                                items: _vm.categoryItems,
                                "item-text": "text",
                                "item-value": "value",
                                outlined: "",
                                "hide-details": "",
                                "menu-props": { bottom: true, offsetY: true },
                              },
                              model: {
                                value: _vm.category,
                                callback: function ($$v) {
                                  _vm.category = $$v
                                },
                                expression: "category",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "mr-2",
                            staticStyle: { width: "15%" },
                          },
                          [
                            _c("vue2-date-picker", {
                              staticClass: "management",
                              staticStyle: { width: "100%" },
                              attrs: {
                                "value-type": "format",
                                format: "YYYY-MM-DD",
                                range: "",
                                placeholder: "등록일",
                                lang: "ko",
                              },
                              model: {
                                value: _vm.date,
                                callback: function ($$v) {
                                  _vm.date = $$v
                                },
                                expression: "date",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticStyle: { width: "30%" } },
                          [
                            _c("v-text-field", {
                              staticClass: "pr-2",
                              attrs: {
                                label: "제목, 질문 내용으로 검색",
                                "append-icon": _vm.icons.mdiMagnify,
                                outlined: "",
                                dense: "",
                                "hide-details": "",
                                clearable: "",
                                maxLength: "100",
                              },
                              on: {
                                keypress: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.onSearch.apply(null, arguments)
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "append",
                                    fn: function () {
                                      return [
                                        _c(
                                          "v-icon",
                                          { on: { click: _vm.onSearch } },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.icons.mdiMagnify) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                582925168
                              ),
                              model: {
                                value: _vm.keyWord,
                                callback: function ($$v) {
                                  _vm.keyWord = $$v
                                },
                                expression: "keyWord",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "pr-2" },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass:
                                  "itgoWhite--text font-size18 search-btn",
                                attrs: { color: "itgoInfo" },
                                on: { click: _vm.onSearch },
                              },
                              [_vm._v(" 조회 ")]
                            ),
                          ],
                          1
                        ),
                        _vm.isBulletinWritable
                          ? _c(
                              "div",
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "font-size18 search-btn",
                                    attrs: {
                                      color: "#183256",
                                      dense: "",
                                      outlined: "",
                                    },
                                    on: { click: _vm.onCreate },
                                  },
                                  [_vm._v(" 작성하기 ")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ]),
                    _c(
                      "v-data-table",
                      _vm._b(
                        {
                          staticClass: "row-pointer",
                          staticStyle: { "min-height": "540px !important" },
                          attrs: {
                            "items-per-page": _vm.pageSize,
                            page: _vm.pageNum,
                            expanded: _vm.expanded,
                            "item-key": "key",
                            "checkbox-color": "#E5318E",
                            "show-select": _vm.isBulletinWritable,
                            "hide-default-footer": "",
                            "disable-sort": "",
                            "item-class": function () {
                              return "row-style"
                            },
                          },
                          on: {
                            "update:page": function ($event) {
                              _vm.pageNum = $event
                            },
                            "update:expanded": function ($event) {
                              _vm.expanded = $event
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              _vm.isBulletinWritable
                                ? {
                                    key: "top",
                                    fn: function () {
                                      return [
                                        _c(
                                          "v-card-text",
                                          { staticClass: "table-top" },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(_vm.selectedItems.length)
                                              ),
                                            ]),
                                            _c(
                                              "span",
                                              {
                                                class: {
                                                  disabled:
                                                    !_vm.selectedItems.length,
                                                },
                                                style:
                                                  _vm.selectedItems.length &&
                                                  "cursor: pointer",
                                                on: {
                                                  click: _vm.onClickDeleteFaq,
                                                },
                                              },
                                              [_vm._v(" 삭제 ")]
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  }
                                : null,
                              {
                                key: "no-data",
                                fn: function () {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.isSearched
                                            ? "검색 결과가 없습니다."
                                            : "등록된 게시글이 없습니다."
                                        ) +
                                        " "
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                              {
                                key: "header.data-table-select",
                                fn: function (ref) {
                                  var on = ref.on
                                  var props = ref.props
                                  return [
                                    _c(
                                      "v-simple-checkbox",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            attrs: {
                                              color: "#E5318E",
                                              ripple: false,
                                            },
                                          },
                                          "v-simple-checkbox",
                                          props,
                                          false
                                        ),
                                        on
                                      )
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "item.data-table-select",
                                fn: function (ref) {
                                  var isSelected = ref.isSelected
                                  var select = ref.select
                                  return [
                                    _c("v-simple-checkbox", {
                                      attrs: {
                                        color: "#E5318E",
                                        ripple: false,
                                        value: isSelected,
                                      },
                                      on: {
                                        input: function ($event) {
                                          return select($event)
                                        },
                                      },
                                    }),
                                  ]
                                },
                              },
                              {
                                key: "item.faqId",
                                fn: function (ref) {
                                  var value = ref.value
                                  return [
                                    _vm._v(" " + _vm._s(value || "-") + " "),
                                  ]
                                },
                              },
                              {
                                key: "item.category",
                                fn: function (ref) {
                                  var value = ref.value
                                  return [
                                    _vm._v(" " + _vm._s(value || "-") + " "),
                                  ]
                                },
                              },
                              {
                                key: "item.question",
                                fn: function (ref) {
                                  var value = ref.value
                                  return [
                                    _vm._v(" " + _vm._s(value || "-") + " "),
                                  ]
                                },
                              },
                              {
                                key: "item.regDt",
                                fn: function (ref) {
                                  var value = ref.value
                                  return [
                                    _vm._v(" " + _vm._s(value || "-") + " "),
                                  ]
                                },
                              },
                              {
                                key: "item.answer",
                                fn: function (ref) {
                                  var item = ref.item
                                  return [
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "me-2 mt-1",
                                            attrs: { size: "24" },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.onRowClicked(item)
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.icons.mdiChevronDown
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "expanded-item",
                                fn: function (ref) {
                                  var headers = ref.headers
                                  var item = ref.item
                                  return [
                                    _c(
                                      "td",
                                      { attrs: { colspan: headers.length } },
                                      [
                                        _c(
                                          "v-container",
                                          {
                                            staticClass: "pa-0 ma-0",
                                            staticStyle: {
                                              "min-height": "100px",
                                            },
                                            attrs: { fluid: "" },
                                          },
                                          [
                                            _c(
                                              "v-card",
                                              { staticClass: "elevation-0" },
                                              [
                                                _c(
                                                  "v-card-text",
                                                  { staticClass: "py-0 px-6" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "detail-box pa-5",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              "text-align":
                                                                "right",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                staticClass:
                                                                  "edit-btn font-size14",
                                                                attrs: {
                                                                  dense: "",
                                                                  outlined: "",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.onEdit(
                                                                        item
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [_vm._v(" 수정 ")]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c("v-textarea", {
                                                          staticClass:
                                                            "detail-content mx-6",
                                                          attrs: {
                                                            "no-resize": "",
                                                            readonly: "",
                                                            rows: "6",
                                                          },
                                                          model: {
                                                            value: item.answer,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                item,
                                                                "answer",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "item.answer",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                          model: {
                            value: _vm.selectedItems,
                            callback: function ($$v) {
                              _vm.selectedItems = $$v
                            },
                            expression: "selectedItems",
                          },
                        },
                        "v-data-table",
                        _vm.faqInfo,
                        false
                      )
                    ),
                  ],
                  1
                ),
                _c("Pagination", {
                  attrs: {
                    pageSizes: _vm.pageSizes,
                    totalPageCount: _vm.totalPageCount,
                    pageSize: _vm.pageSize,
                    pageNum: _vm.pageNum,
                  },
                  on: {
                    "update:pageSize": function ($event) {
                      _vm.pageSize = $event
                    },
                    "update:page-size": function ($event) {
                      _vm.pageSize = $event
                    },
                    "update:pageNum": function ($event) {
                      _vm.pageNum = $event
                    },
                    "update:page-num": function ($event) {
                      _vm.pageNum = $event
                    },
                  },
                }),
              ],
              1
            )
          : _c(
              "v-card",
              { staticStyle: { "min-height": "880px" } },
              [
                _c(
                  "v-card-title",
                  {
                    staticStyle: {
                      display: "flex",
                      "justify-content": "space-between",
                      "align-items": "center",
                      padding: "20px 24px 20px 16px",
                    },
                  },
                  [
                    _c("div", [
                      _c("div", { staticClass: "flex-start-contents" }, [
                        _c(
                          "div",
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "pr-2",
                                attrs: { size: "50" },
                                on: { click: _vm.backKeyToList },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.icons.mdiChevronLeft) + " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c("div", [
                          _c(
                            "span",
                            {
                              staticClass:
                                "font-size26 font-weight700 surface800",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.faqMode === "create"
                                      ? "FAQ 작성"
                                      : "FAQ 수정"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                    _c(
                      "div",
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "font-size18 search-btn mr-2",
                            attrs: { dense: "", outlined: "" },
                            on: { click: _vm.toggleLoadPopup },
                          },
                          [_vm._v(" 불러오기 ")]
                        ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "font-size18 search-btn mr-2",
                            attrs: {
                              dense: "",
                              outlined: "",
                              disabled: _vm.isRegisterLoading,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.saveFaq(true)
                              },
                            },
                          },
                          [
                            _vm._v(
                              " 임시 저장 | " +
                                _vm._s(_vm.temporaryItems.length) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "v-btn",
                          {
                            staticClass:
                              "itgoWhite--text px-2 font-size18 search-btn",
                            attrs: {
                              color: "itgoInfo",
                              disabled: _vm.isRegisterLoading,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.saveFaq(false)
                              },
                            },
                          },
                          [_vm._v(" 등록 ")]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c("v-card-title", { staticClass: "mt-10 pb-5 px-6" }, [
                  _c("div", { staticClass: "flex-start-contents" }, [
                    _c(
                      "div",
                      { staticStyle: { width: "20%" } },
                      [
                        _c("v-select", {
                          staticClass: "pr-2",
                          staticStyle: { width: "100%" },
                          attrs: {
                            dense: "",
                            label: "대상",
                            "persistent-placeholder": "",
                            items: _vm.faqTargetCreateItems,
                            "item-text": "text",
                            "item-value": "value",
                            outlined: "",
                            "hide-details": "",
                            "menu-props": { bottom: true, offsetY: true },
                          },
                          model: {
                            value: _vm.faqContents.faqCreate,
                            callback: function ($$v) {
                              _vm.$set(_vm.faqContents, "faqCreate", $$v)
                            },
                            expression: "faqContents.faqCreate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticStyle: { width: "15%" } },
                      [
                        _c("v-select", {
                          staticStyle: { width: "100%" },
                          attrs: {
                            dense: "",
                            label: "유형",
                            items: _vm.categoryCreateItems,
                            "item-text": "text",
                            "item-value": "value",
                            outlined: "",
                            "hide-details": "",
                            "menu-props": { bottom: true, offsetY: true },
                          },
                          model: {
                            value: _vm.faqContents.categoryCreate,
                            callback: function ($$v) {
                              _vm.$set(_vm.faqContents, "categoryCreate", $$v)
                            },
                            expression: "faqContents.categoryCreate",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c("v-divider", { staticClass: "mt-2" }),
                _c(
                  "v-card-text",
                  { staticClass: "px-6" },
                  [
                    _c("v-text-field", {
                      attrs: {
                        label: "질문 내용",
                        placeholder: "질문 내용",
                        "persistent-placeholder": "",
                        outlined: "",
                        "no-resize": "",
                        dense: "",
                        "hide-details": "",
                        maxlength: 100,
                      },
                      model: {
                        value: _vm.faqContents.question,
                        callback: function ($$v) {
                          _vm.$set(_vm.faqContents, "question", $$v)
                        },
                        expression: "faqContents.question",
                      },
                    }),
                    _c("div", { staticClass: "d-flex justify-end mt-1" }, [
                      _vm._v(
                        " " + _vm._s(_vm.faqContents.question.length) + "/100 "
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "v-card-text",
                  { staticClass: "px-6" },
                  [
                    _c("v-textarea", {
                      attrs: {
                        label: "답변 내용",
                        placeholder: "답변 내용",
                        "persistent-placeholder": "",
                        outlined: "",
                        "no-resize": "",
                        rows: "10",
                        "hide-details": "",
                        maxlength: 500,
                      },
                      model: {
                        value: _vm.faqContents.answer,
                        callback: function ($$v) {
                          _vm.$set(_vm.faqContents, "answer", $$v)
                        },
                        expression: "faqContents.answer",
                      },
                    }),
                    _c("div", { staticClass: "d-flex justify-end mt-1" }, [
                      _vm._v(
                        " " + _vm._s(_vm.faqContents.answer.length) + "/500 "
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
        _c(
          "v-dialog",
          {
            attrs: { "max-width": "551" },
            model: {
              value: _vm.isLoadPopup,
              callback: function ($$v) {
                _vm.isLoadPopup = $$v
              },
              expression: "isLoadPopup",
            },
          },
          [
            _c("load-popup", {
              attrs: { temporaryItems: _vm.temporaryItems },
              on: {
                delete: _vm.deleteItems,
                load: _vm.loadItem,
                close: _vm.loadPopupClose,
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }