import axios from 'axios'

class BillingService {
  private apiUrl: string

  constructor() {
    this.apiUrl = '/billing'
  }

  /**
   * 자동결제 승인 내역 조회 API
   */
  getBillingList(params) {
    return axios
      .get(this.apiUrl + `/approval/list`, { params })
      .then((res) => res.data)
  }
}

export default new BillingService()
