import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import store from '../store'
import axios from 'axios'
import _ from 'lodash'

Vue.use(VueRouter)

// configure router
const vueRouter = new VueRouter({
  routes, // short for routes: routes
  mode: 'history',

  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

vueRouter.beforeEach((to, from, next) => {
  const _store: any = store

  //404 페이지 보여주기
  if (!to.matched.length) {
    next('/notFound');
    return;
  }

  const accessToken = window.sessionStorage.getItem('accessToken')
  const refreshToken = window.sessionStorage.getItem('refreshToken')

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!accessToken && !refreshToken) {
      next({
        path: '/login',
        query: { redirect: to.fullPath },
      })
    } else {
      //브라우저 리프레쉬시에  token refresh 보다 먼저 axios 호출이 이뤄지는 경우를 대비해서 강제로 axios access_token값 설정함
      if (!axios.defaults.headers.common['Authorization']) {
        console.log(
          'Exception - Login completed, But axios auth header is null ',
        )
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken
      }

      if (
        _.includes(
          to?.meta?.authorization,
          _store.state.auth.user.controllerRole,
        )
      ) {
        next()
      } else {
        if (_store.state.auth.user.controllerRole == 'CALLCENTER') {
          next({
            path: '/callcenter',
          })
        } else if (_store.state.auth.user.controllerRole == 'CALLER') {
          next({
            path: '/caller',
          })
        }
      }

      // if(store.state.auth.user === null)
      //     next();
      // else if(_.includes(to.meta.authorization, store.state.auth.user.controllerRole)){
      //     next();
      // }else{
      //     return;
      // }
    }
  } else {
    if (accessToken && refreshToken) {
      next({
        path: '/',
        query: { redirect: to.fullPath },
      })
    } else {
      next()
    }
  }
})

export default vueRouter
