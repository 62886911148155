var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content-wrapper content_body" }, [
    _c(
      "div",
      { staticClass: "content-inner" },
      [
        _c(
          "v-card",
          [
            _c(
              "v-card-title",
              [
                _c(
                  "span",
                  { staticClass: "font-size26 font-weight700 surface800" },
                  [_vm._v("계정 관리")]
                ),
                _c("v-spacer"),
                _c(
                  "div",
                  {
                    staticClass: "d-flex justify-end",
                    staticStyle: { width: "20%" },
                  },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass:
                          "px-2 mt-n1 font-size18 shipper-add-btn create-btn",
                        attrs: { color: "#196CE7", dense: "", outlined: "" },
                        on: { click: _vm.userCreated },
                      },
                      [
                        _c("v-icon", { attrs: { left: "" } }, [
                          _vm._v(" " + _vm._s(_vm.icons.mdiPlus) + " "),
                        ]),
                        _vm._v(" 계정 생성 "),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "v-data-table",
              _vm._b(
                {
                  staticClass: "mb-15",
                  staticStyle: { "min-height": "540px !important" },
                  attrs: {
                    "items-per-page": _vm.pageSize,
                    page: _vm.pageNum,
                    "item-key": "key",
                    "hide-default-footer": "",
                    "disable-sort": "",
                  },
                  on: {
                    "update:page": function ($event) {
                      _vm.pageNum = $event
                    },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "item",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          _c(
                            "tr",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.userDetailInfo(item)
                                },
                              },
                            },
                            [
                              _c("td", { class: ["text-center"] }, [
                                _c(
                                  "span",
                                  {
                                    style: {
                                      color: item.deleteDt
                                        ? "#e5318e"
                                        : item.inactiveDt
                                        ? "#999"
                                        : "#2f2f2f",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(item.controllerRole) + " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c(
                                "td",
                                {
                                  class: ["text-center", _vm.getRowStyle(item)],
                                },
                                [_vm._v(" " + _vm._s(item.name) + " ")]
                              ),
                              _c(
                                "td",
                                {
                                  class: ["text-center", _vm.getRowStyle(item)],
                                },
                                [_vm._v(" " + _vm._s(item.loginId) + " ")]
                              ),
                              _c(
                                "td",
                                {
                                  class: ["text-center", _vm.getRowStyle(item)],
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        item.regReason
                                          ? _vm.truncateText(item.regReason, 10)
                                          : "-"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "td",
                                {
                                  class: ["text-center", _vm.getRowStyle(item)],
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.dateChangedType(item.regDt)) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "td",
                                {
                                  class: ["text-center", _vm.getRowStyle(item)],
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        item.changeReason
                                          ? _vm.truncateText(
                                              item.changeReason,
                                              10
                                            )
                                          : "-"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "td",
                                {
                                  class: ["text-center", _vm.getRowStyle(item)],
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.dateChangedType(item.changeDt)
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "td",
                                {
                                  class: ["text-center", _vm.getRowStyle(item)],
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        item.deleteReason
                                          ? _vm.truncateText(
                                              item.deleteReason,
                                              10
                                            )
                                          : "-"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "td",
                                {
                                  class: ["text-center", _vm.getRowStyle(item)],
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.dateChangedType(item.deleteDt)
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                },
                "v-data-table",
                _vm.managerUsersInfo,
                false
              )
            ),
            _c("Pagination", {
              attrs: {
                pageSizes: _vm.pageSizes,
                totalPageCount: _vm.totalPageCount,
                pageSize: _vm.pageSize,
                pageNum: _vm.pageNum,
              },
              on: {
                "update:pageSize": function ($event) {
                  _vm.pageSize = $event
                },
                "update:page-size": function ($event) {
                  _vm.pageSize = $event
                },
                "update:pageNum": function ($event) {
                  _vm.pageNum = $event
                },
                "update:page-num": function ($event) {
                  _vm.pageNum = $event
                },
              },
            }),
          ],
          1
        ),
        _c(
          "v-dialog",
          {
            staticClass: "v-dialog",
            attrs: { persistent: "", "max-width": "600px" },
            model: {
              value: _vm.isUserAddPopup,
              callback: function ($$v) {
                _vm.isUserAddPopup = $$v
              },
              expression: "isUserAddPopup",
            },
          },
          [
            _c("manager-edit-popup", {
              key: _vm.editCountKey,
              on: { close: _vm.editPopupAct },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }