import { render, staticRenderFns } from "./AppBarCompany.vue?vue&type=template&id=47651af3&"
import script from "./AppBarCompany.vue?vue&type=script&lang=ts&"
export * from "./AppBarCompany.vue?vue&type=script&lang=ts&"
import style0 from "./AppBarCompany.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
installComponents(component, {VChip})


/* hot reload */
if (module.hot) {
  var api = require("/work/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('47651af3')) {
      api.createRecord('47651af3', component.options)
    } else {
      api.reload('47651af3', component.options)
    }
    module.hot.accept("./AppBarCompany.vue?vue&type=template&id=47651af3&", function () {
      api.rerender('47651af3', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/layouts/app-bar/AppBarCompany.vue"
export default component.exports