import AuthService from '@/services-itgo/auth.service'
import BrokerService from '@/services-itgo/broker.service'
import AdminService from "@/services-itgo/admin.service";

/**
 * 인증 모듈
 */
export const auth = {
  namespaced: true,
  state: {
//    token: null,
    user: null,
    niceData: null,
    encData: null,
    logoutType: null,
    autoLogOutFlag: null,
  },
  actions: {
    signcode({ commit }, user) {
      return AuthService.signcode(user).then(() => {
        return Promise.resolve()
      })
    },
    signin({ commit }, user) {
      return AuthService.signin(user).then(
        (token) => {
          commit('token', token)
          return Promise.resolve(token)
        },
        (error) => {
          commit('failedToken')
          return Promise.reject(error.response.data)
        },
      )
    },
    signout({ commit }, logoutType) {
      AuthService.signout(logoutType)
      commit('signout', logoutType)
    },
    signup({ commit }, info) {
      return AuthService.signup(info).then(() => {
        return Promise.resolve()
      })
    },
    refresh({ commit }, rTkn) {
      return AuthService.getRefreshToken(rTkn).then(
        (token) => {
          commit('token', token)
          return Promise.resolve(token)
        },
        (error) => {
          //commit('failedToken');
          return Promise.reject(error.response.data)
        },
      )
    },
    getUser({ commit }) {
      return AdminService.getMyInfo().then((user) => {
        console.log(user)
        commit('saveUser', user)
        return Promise.resolve(user)
      })
    },
    getAutoLogOutFlag({ commit }, autoLogOutFlag) {
      commit('saveAutoLogout', autoLogOutFlag)
    },
  },
  mutations: {
    token(state, token) {
      // token를 session에 저장함.
      window.sessionStorage.setItem('accessToken', token.access_token)
      window.sessionStorage.setItem('refreshToken', token.refresh_token)
    },
    failedToken(state) {
      window.sessionStorage.removeItem('accessToken')
      window.sessionStorage.removeItem('refreshToken')
    },
    signout(state, logoutType) {
      state.user = null
      state.autoLogOutFlag = false
      state.logoutType = logoutType

      window.sessionStorage.removeItem('accessToken')
      window.sessionStorage.removeItem('refreshToken')
    },
    saveUser(state, user) {
      state.user = user
    },
    saveNiceData(state, data) {
      state.niceData = data
    },
    saveEncData(state, data) {
      state.encData = data
    },
    saveAutoLogout(state, autoLogOutFlag) {
      state.autoLogOutFlag = autoLogOutFlag
    },
    saveLogoutType(state, logoutType) {
      state.logoutType = logoutType
    },
  },

  getters: {
    getBizId(state) {
      return state.user?.bizId
    },
    getUserName(state) {
      return state.user?.name
    },
    getUserLoginId(state) {
      return state.user?.loginId
    },
    getLogoutTypeUser(state) {
      return state.logoutType
    },
    getNiceData(state) {
      return state.niceData
    },
    getEncData(state) {
      return state.encData
    },
    getIsAutoLogout(state) {
      return state.autoLogOutFlag
    },
  },
}
