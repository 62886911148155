





























































































































































import { Vue, Component, Watch, Ref } from 'vue-property-decorator'
import Vue2DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ko'
import DatetimeUtils from '@/utils/datetime.utils'
import Pagination from '@/views/utils/Pagination.vue'
import { Getter, Mutation, State } from 'vuex-class'
import UserService from '@/services-itgo/user.service'
import USER_STATUS from '@/views/constant/user.status'
import _ from 'lodash'
import { mdiEmailOutline, mdiMagnify } from '@mdi/js'
import SMSSendPopup from '@/views/popup/551X792/SMSSendPopup.vue'
import userService from '@/services-itgo/user.service'
import driverService from '@/services-itgo/driver.service'

@Component({
  components: {
    SMSSendPopup,
    Vue2DatePicker,
    Pagination,
  },

  created() {
    this.$router.beforeEach((from, to, next) => {
      if (from.name === 'associateMemberInfo' && to.name === 'associateMemberReg') {
        this.setIsMemberFilterStatus({ type: 'associateMember', data: true })
      }
      next()
    })
  },
  mounted() {
    this.date = this.baseDate

    if (this.isAssociateMemberReg) {
      this.keyword = this.filterData.keyword
      this.date = [this.filterData.fromDt, this.filterData.toDt]
      this.pageNum = this.filterData.pageNum
      this.pageSize = this.filterData.pageSize
      this.setIsMemberFilterStatus({ type: 'associateMember', data: false })
    }

    this.fetchAssociateUserReviewList()
  },
})
export default class AssociateMemberReg extends Vue {
  @State((state) => state.auth.user) loginUser
  @State((state) => state.pageFilter.status.isAssociateMemberReg) isAssociateMemberReg
  @State((state) => state.pageFilter.filter.associateMemberFilter) filterData
  @Mutation('pageFilter/setMemberFilterData') setMemberFilterData
  @Mutation('pageFilter/setIsMemberFilterStatus') setIsMemberFilterStatus

  beIsSearched = false

  icons = {
    mdiMagnify,
    mdiEmailOutline,
  }

  keyword: string = ''

  openSMSSendPopup = false
  smsCountKey = 0
  preData = null

  onOpenSMSSendPopup(item){
    this.preData = item
    this.smsCountKey ++
    this.openSMSSendPopup = true
  }

  onCloseSMSSendPopup(){
    this.openSMSSendPopup = false
  }

  get isSearched() {
    return this.beIsSearched
  }

  get topItems() {
    return [
      {
        title: '전체 가입',
        date:
          DatetimeUtils.setKoreaDate(
            DatetimeUtils.datePlusDays(new Date(), -1),
            'day',
          ) + ' 기준',
        count: this.userReviewInfo.totalCount,
      },
      {
        title: '전체 탈퇴',
        date:
          DatetimeUtils.setKoreaDate(
            DatetimeUtils.datePlusDays(new Date(), -1),
            'day',
          ) + ' 기준',
        count: this.userReviewInfo.withdrawalCount,
      },
    ]
  }

  get baseDate() {
    const today = new Date()
    const beforeMonth = new Date(today)
    beforeMonth.setDate(today.getDate() - 29)
    return [beforeMonth, today]
  }

  date: any = [,]

  @Watch('date')
  changeDates(value) {
    if (value?.length > 1) {
      this.set(value)
    }
  }

  previousDayDate: Date[] | null = null

  async set(value) {
    const temp = [...this.date]
    temp.sort((a, b) => new Date(a).getTime() - new Date(b).getTime())

    if (temp.length === 2) {
      if (DatetimeUtils.asDays(temp[0], temp[1]) >= 30) {
        this.date = this.previousDayDate
        await this.$alert({
          title: '기간 설정 오류',
          content: '기간 설정은 최대 30일까지 가능합니다.',
        })
      } else {
        this.previousDayDate = [...this.date]
      }
    }
  }

  type = null
  typeItems = [
    { text: '전체', value: null },
    { text: '일반 가입', value: 1 },
    // { text: '네이버', value: 2 },
    // { text: '구글', value: 3 },
    // { text: '애플', value: 4 },
    // { text: '카카오', value: 5 },
  ]

  userReviewInfo = {
    loading: false,
    headers: [
      { text: '가입 경로', value: 'signupType', align: 'center', width: '15%' },
      { text: '회원명', value: 'name', align: 'center', width: '15%' },
      { text: '전화번호', value: 'mobile', align: 'center', width: '20%' },
      { text: '아이디', value: 'loginId', align: 'center', width: '15%' },
      { text: '가입일시', value: 'regDt', align: 'center', width: '20%' },
      { text: '문자 전송', value: 'sms', align: 'center', width: '15%' },
    ],
    items: [],
    serverItemsLength: 0,
    totalCount: 0,
    withdrawalCount: 0,
  }

  get totalPageCount() {
    const length = Math.ceil(
      this.userReviewInfo.serverItemsLength / this.pageSize,
    )
    return length === 0 ? 1 : length
  }

  pageSizes = [10, 20, 30]
  pageSize = 10
  pageNum = 1

  /**
   * pageSize: 페이지 크기
   * pageNum: 페이지 번호
   */
  @Watch('pageSize')
  onChangePageSize() {
    this.pageNum = 1
    this.fetchAssociateUserReviewList()
  }

  @Watch('pageNum')
  onChangePageNum() {
    this.fetchAssociateUserReviewList()
  }

  async fetchAssociateUserReviewList() {
    console.log('this.date', this.date[0], this.date[1])

    if (this.date[0] && this.date[1]) {
      if (DatetimeUtils.asDays(this.date[0], this.date[1]) > 30) {
        this.date = this.baseDate
        return this.$alert({
          title: '기간 설정 오류',
          content: '기간 설정은 최대 30일까지 가능합니다.',
        })
      }
    }

    try {
      this.userReviewInfo.loading = true
      this.userReviewInfo.items = []

      const fromDate = this.date[0]
        ? DatetimeUtils.stringDate(this.date[0], 'YYYY-MM-DD')
        : ''
      const toDate = this.date[1]
        ? DatetimeUtils.datePlusDays(this.date[1], 1)
        : ''

      const params = {
        keyword: this.keyword,
        fromDt: fromDate,
        toDt: toDate,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      }

      this.setMemberFilterData({
        type: 'associateMember',
        data: { ...params, fromDt: this.date[0], toDt: this.date[1] },
      })

      const data = await UserService.getAssociateUserList(params)

      this.userReviewInfo.items = data.userList.map((item, index) => ({
        ...item,
        status: USER_STATUS.APPROVAL_STATUS[item.approvalStatus],
        type: USER_STATUS.GET_USER_TYPE(item.userType),
        key: `${item.name}_${item.mobile}_${index}`,
      }))

      this.userReviewInfo.serverItemsLength = data.listCount
      this.userReviewInfo.withdrawalCount = data.withdrawalCount
      this.userReviewInfo.totalCount = data.totalCount
    } catch (error: any) {
      this.$alert(error.response.data.errMsg)
    } finally {
      this.userReviewInfo.loading = false
    }
  }

  async onSearch() {
    this.pageNum = 1
    this.beIsSearched = false
    await this.fetchAssociateUserReviewList()
    this.beIsSearched = true
  }

  signUpPathString(value) {
    const result = _.find(this.typeItems, { value: value })
    return result?.text
  }

  async onClickRow(item) {
    await this.$router.push({
      name: 'associateMemberInfo',
      params: { reviewInfo: item, reviewType: 'userReview' },
    })
  }
}
