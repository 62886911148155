<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="viewBox"
  >
    <g
      id="그룹_40426"
      data-name="그룹 40426"
      transform="translate(-46 -330.06)"
    >
      <g id="그룹_40194" data-name="그룹 40194" transform="translate(46 330)">
        <rect
          id="사각형_31091"
          data-name="사각형 31091"
          width="28"
          height="28"
          transform="translate(0 0.06)"
          fill="none"
        />
        <g
          id="그룹_40193"
          data-name="그룹 40193"
          transform="translate(2.339 7.211)"
        >
          <path
            id="패스_27310"
            data-name="패스 27310"
            d="M24.625,14.864H1.23a.53.53,0,0,1-.53-.53V1.23A.53.53,0,0,1,1.23.7H15.621a.53.53,0,0,1,.53.53V14.863H8.086"
            transform="translate(-0.7 -0.7)"
            fill="none"
            :stroke="iconColor"
            stroke-width="1.6"
          />
          <path
            id="패스_27311"
            data-name="패스 27311"
            d="M16.7,15.618V3.367h3.863l3.863,5.011v7.109"
            transform="translate(-1.249 -0.792)"
            fill="none"
            :stroke="iconColor"
            stroke-width="1.6"
          />
          <path
            id="패스_27312"
            data-name="패스 27312"
            d="M16.7,15.618V3.367h3.863l3.863,5.011v7.109"
            transform="translate(-1.249 -0.792)"
            fill="none"
            :stroke="iconColor"
            stroke-linejoin="round"
            stroke-width="1.6"
          />
          <line
            id="선_891"
            data-name="선 891"
            x2="7.726"
            transform="translate(15.451 7.714)"
            fill="#fafafa"
            :stroke="iconColor"
            stroke-width="1.6"
          />
          <line
            id="선_892"
            data-name="선 892"
            x2="7.726"
            transform="translate(15.451 7.714)"
            fill="none"
            :stroke="iconColor"
            stroke-linejoin="round"
            stroke-width="1.6"
          />
          <path
            id="패스_27313"
            data-name="패스 27313"
            d="M7.914,15.322a1.258,1.258,0,1,1-1.258-1.258,1.258,1.258,0,0,1,1.258,1.258"
            transform="translate(-0.861 -1.158)"
            fill="none"
            :stroke="iconColor"
            stroke-width="1.6"
          />
          <line
            id="선_893"
            data-name="선 893"
            x2="8.426"
            transform="translate(2.197 4.107)"
            fill="none"
            :stroke="iconColor"
            stroke-width="1.6"
          />
          <line
            id="선_894"
            data-name="선 894"
            x2="5.088"
            transform="translate(2.197 7.711)"
            fill="none"
            :stroke="iconColor"
            stroke-width="1.6"
          />
          <circle
            id="타원_2560"
            data-name="타원 2560"
            cx="1.812"
            cy="1.812"
            r="1.812"
            transform="translate(3.982 12.352)"
            :fill="iconColor"
            :stroke="iconColor"
            stroke-linejoin="round"
            stroke-width="1.6"
          />
          <circle
            id="타원_2601"
            data-name="타원 2601"
            cx="1.812"
            cy="1.812"
            r="1.812"
            transform="translate(17.501 12.352)"
            :fill="iconColor"
            :stroke="iconColor"
            stroke-linejoin="round"
            stroke-width="1.6"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'truck',

  props: {
    viewBox: { type: String, default: '0 0 28 28' },
    iconName: { type: String, default: '' },
    width: { type: [Number, String], default: 28 },
    height: { type: [Number, String], default: 28 },
    iconColor: { type: String, default: '#ffffff' },
  },
}
</script>

<style scoped></style>
