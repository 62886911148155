var logi = logi ?? {};
logi['maps'] = logi['maps'] ?? {};
import a12_0x484917 from './base/logi-maps-defines.js';
logi['maps']['Defines'] = a12_0x484917;
import a12_0x21898a from './base/logi-maps-types.js';
logi['maps']['TileInfo'] = a12_0x21898a['TileInfo'], logi['maps']['ALIGN'] = a12_0x21898a['ALIGN'];
import a12_0x3ff1c3 from './base/logi-maps-utils.js';
logi['maps']['Utils'] = a12_0x3ff1c3;
import a12_0x323b53 from './base/logi-maps-resource.js';
logi['maps']['Resource'] = a12_0x323b53;
import a12_0xcaa77 from './base/logi-maps-object.js';
logi['maps']['Object'] = a12_0xcaa77;
import a12_0x5ec127 from './base/logi-maps-boundarydata.js';
logi['maps']['BoundaryData'] = a12_0x5ec127;
import a12_0x2d9049 from './base/logi-maps-boundarychecker.js';
logi['maps']['BoundaryChecker'] = a12_0x2d9049, logi['maps']['Evcs'] = class extends logi['maps']['Object'] {
    #src = '';
    #position = {
        'lng': 0x0,
        'lat': 0x0
    };
    #offsetX;
    #offsetY;
    #angle;
    #align;
    #image = null;
    #images = new Map();
    #tileInfo = new logi['maps']['TileInfo']();
    #expiredTileId = !![];
    #boundaryData = new logi['maps']['BoundaryData']();
    #tileId = 0x0;
    #evsesStat = 0x0;
    #location = null;
    constructor(_0x4fdef7, _0x50dd74, _0x1e6dad) {
        const _0x2f6e97 = _0x1e6dad?.['key'] ?? 'ev_' + Math['random']()['toString'](0x24)['slice'](-0x8), _0x5e2c88 = _0x1e6dad?.['class'] ?? '', _0x16369a = _0x1e6dad?.['zIndex'] ?? 0x0;
        super(_0x2f6e97, logi['maps']['Object']['OBJTYPE']['evcs'], _0x5e2c88, _0x16369a), this['setImageSrc'](_0x4fdef7 ?? ''), this.#position['lng'] = _0x50dd74?.['lng'] ?? 0x0, this.#position['lat'] = _0x50dd74?.['lat'] ?? 0x0, this.#offsetX = _0x1e6dad?.['offsetX'] ?? 0x0, this.#offsetY = _0x1e6dad?.['offsetY'] ?? 0x0, this.#angle = _0x1e6dad?.['angle'] ?? 0x0, this.#align = _0x1e6dad?.['align'] ?? logi['maps']['ALIGN']['CM'], this['boundaryData']['groupId'] = _0x1e6dad?.['groupId'] ?? 0x0, this['resetBoundary'](), this.#tileId = _0x1e6dad?.['tileId'], this['setMap'](_0x1e6dad?.['map']);
    }
    ['setImageSrc'](_0x1a3a55) {
        this.#src = _0x1a3a55 ?? '';
        const _0x101b03 = this;
        logi['maps']['Resource']['getImage'](_0x101b03.#src)['then'](_0x4272b4 => {
            _0x101b03.#image = _0x4272b4, _0x101b03['updateObj']();
        })['catch'](_0x54c89e => {
            _0x101b03.#image = null, _0x101b03['updateObj'](), console['log'](_0x54c89e);
        });
    }
    ['getImageSrc']() {
        return this.#src;
    }
    ['setEvIconSrc'](_0xa79f75, _0x20b356, _0x52ee7f) {
        let _0x20d95d = new Map();
        _0x20d95d['set']('EMPTY', _0xa79f75), _0x20d95d['set']('PART', _0x20b356), _0x20d95d['set']('FULL', _0x52ee7f);
        if (_0x20d95d['size'] > 0x0) {
            const _0x289795 = this;
            for (const [_0xc22be5, _0x3a5e1d] of _0x20d95d) {
                logi['maps']['Resource']['getImage'](_0x3a5e1d)['then'](_0x42557c => {
                    _0x289795.#images['set'](_0xc22be5, _0x42557c), _0x289795['updateObj']();
                })['catch'](_0x6fa37a => {
                    _0x289795.#images['set'](_0xc22be5, null), _0x289795['updateObj'](), console['log'](_0x6fa37a);
                });
            }
        }
    }
    ['setPosition'](_0x3f9f4e) {
        (this.#position['lng'] !== _0x3f9f4e['lng'] || this.#position['lat'] !== _0x3f9f4e['lat']) && (this.#position['lng'] = _0x3f9f4e['lng'], this.#position['lat'] = _0x3f9f4e['lat'], (this.#position['lng'] < this['tileInfo']['boundary']['xMin'] || this.#position['lng'] > this['tileInfo']['boundary']['xMax'] || this.#position['lat'] < this['tileInfo']['boundary']['yMin'] || this.#position['lat'] > this['tileInfo']['boundary']['yMax']) && (this['expiredTileId'] = !![]), this['updateObj']());
    }
    ['getPosition']() {
        return {
            'lng': this.#position['lng'],
            'lat': this.#position['lat']
        };
    }
    ['setOffsetX'](_0xb03482) {
        _0xb03482 = _0xb03482 ?? 0x0, this.#offsetX !== _0xb03482 && (this.#offsetX = _0xb03482, this['updateObj']());
    }
    ['getOffsetX']() {
        return this.#offsetX;
    }
    ['setOffsetY'](_0x13be31) {
        _0x13be31 = _0x13be31 ?? 0x0, this.#offsetY !== _0x13be31 && (this.#offsetY = _0x13be31, this['updateObj']());
    }
    ['getOffsetY']() {
        return this.#offsetY;
    }
    ['setAngle'](_0x1b988f) {
        this.#angle !== _0x1b988f && (this.#angle = _0x1b988f, this['updateObj']());
    }
    ['getAngle']() {
        return this.#angle;
    }
    get ['tileInfo']() {
        return this.#tileInfo;
    }
    set ['expiredTileId'](_0x449a64) {
        this.#expiredTileId = _0x449a64;
    }
    get ['expiredTileId']() {
        return this.#expiredTileId;
    }
    get ['boundaryData']() {
        return this.#boundaryData;
    }
    ['resetBoundary']() {
        this['boundaryData']['bLoad'] = logi['maps']['BoundaryData']['STATUS']['NOT_LOAD'], this['boundaryData']['tagName'] = this['getKey'](), this['boundaryData']['boundaryCircle'] = {
            'center': {
                'x': 0x0,
                'y': 0x0
            },
            'maxR': 0x0
        }, this['boundaryData']['boundaryRect'] = [
            {
                'x': 0x0,
                'y': 0x0
            },
            {
                'x': 0x0,
                'y': 0x0
            },
            {
                'x': 0x0,
                'y': 0x0
            },
            {
                'x': 0x0,
                'y': 0x0
            }
        ], this['boundaryData']['creationTick'] = logi['maps']['Utils']['getCurTick'](), this['boundaryData']['overlapCnt'] = 0x0;
    }
    ['updateBoundary']() {
        let _0x51e1cf = [
                {
                    'x': 0x0,
                    'y': 0x0
                },
                {
                    'x': 0x0,
                    'y': 0x0
                },
                {
                    'x': 0x0,
                    'y': 0x0
                },
                {
                    'x': 0x0,
                    'y': 0x0
                }
            ], _0x560c41 = {
                'center': {
                    'x': 0x0,
                    'y': 0x0
                },
                'maxR': 0x0
            }, _0x298871 = this['getMapCoord'](), _0x5f50c2 = _0x298871['world2screen'](this.#position['lng'], this.#position['lat']);
        if (this.#image) {
            let _0x3cc7bc = {
                'x': 0x0,
                'y': 0x0
            };
            _0x3cc7bc['x'] = _0x5f50c2['x'] + this.#offsetX, _0x3cc7bc['y'] = _0x5f50c2['y'] + this.#offsetY;
            const _0x355b7e = this.#image?.['naturalWidth'], _0x483a35 = this.#image?.['naturalHeight'];
            _0x51e1cf = logi['maps']['Utils']['getBoundaryBox'](_0x3cc7bc, _0x355b7e, _0x483a35, this.#align, this.#angle), _0x560c41 = logi['maps']['Utils']['getBoundaryCircle'](_0x51e1cf);
        }
        this['boundaryData']['setBoundary'](_0x560c41, _0x51e1cf);
    }
    ['getWidth']() {
        if (this.#image)
            return this.#image['naturalWidth'];
        return 0x0;
    }
    ['getHeight']() {
        if (this.#image)
            return this.#image['naturalHeight'];
        return 0x0;
    }
    ['getAlign']() {
        return this.#align;
    }
    ['getTileId']() {
        return this.#tileId;
    }
    ['getName']() {
        return this.#location?.['name'] ? this.#location?.['name'] : '';
    }
    ['setInfo'](_0x2c3322) {
        const _0x286e89 = _0x2c3322;
        this.#location = {
            'lbs_region_code': _0x286e89['lbs_region_code'],
            'lbs_location_key': _0x286e89['lbs_location_key'],
            'country_code': _0x286e89['country_code'],
            'party_id': _0x286e89['party_id'],
            'id': _0x286e89['id'],
            'publish': _0x286e89['publish'],
            'publish_allowed_to': _0x286e89['publish_allowed_to'],
            'name': _0x286e89['name'],
            'address': _0x286e89['address'],
            'city': _0x286e89['city'],
            'postal_code': _0x286e89['postal_code'],
            'state': _0x286e89['state'],
            'country': _0x286e89['country'],
            'latitude': _0x286e89['latitude'],
            'longitude': _0x286e89['longitude'],
            'related_locations': _0x286e89['related_locations'],
            'parking_type': _0x286e89['parking_type'],
            'directions': _0x286e89['directions'],
            'operator_name': _0x286e89['operator_name'],
            'operator_website': _0x286e89['operator_website'],
            'operator_logo': _0x286e89['operator_logo'],
            'suboperator_name': _0x286e89['suboperator_name'],
            'suboperator_website': _0x286e89['suboperator_website'],
            'suboperator_logo': _0x286e89['suboperator_logo'],
            'owner_name': _0x286e89['owner_name'],
            'owner_website': _0x286e89['owner_website'],
            'owner_logo': _0x286e89['owner_logo'],
            'facilities': _0x286e89['facilities'],
            'time_zone': _0x286e89['time_zone'],
            'opening_times': _0x286e89['opening_times'],
            'charging_when_closed': _0x286e89['charging_when_closed'],
            'images': _0x286e89['images'],
            'energy_mix': _0x286e89['energy_mix'],
            'last_updated': _0x286e89['last_updated']
        }, this.#location['evses'] = new Map();
        const _0x1fd553 = this.#location['evses'], _0x270183 = _0x286e89['evses'];
        for (const _0x4899c9 of _0x270183) {
            const _0x33cca8 = {
                'lbs_region_code': _0x4899c9['lbs_region_code'],
                'lbs_location_key': _0x4899c9['lbs_location_key'],
                'lbs_evse_key': _0x4899c9['lbs_evse_key'],
                'uid': _0x4899c9['uid'],
                'evse_id': _0x4899c9['evse_id'],
                'status': _0x4899c9['status'],
                'status_schedule': _0x4899c9['status_schedule'],
                'capabilities': _0x4899c9['capabilities'],
                'floor_level': _0x4899c9['floor_level'],
                'latitude': _0x4899c9['latitude'],
                'longitude': _0x4899c9['longitude'],
                'physical_reference': _0x4899c9['physical_reference'],
                'directions': _0x4899c9['directions'],
                'parking_restrictions': _0x4899c9['parking_restrictions'],
                'images': _0x4899c9['images'],
                'last_updated': _0x4899c9['last_updated']
            };
            _0x1fd553['set'](_0x33cca8['lbs_evse_key'], _0x33cca8), _0x33cca8['connectors'] = new Map();
            const _0x1f17ab = _0x33cca8['connectors'], _0x3b78d4 = _0x4899c9['connectors'];
            for (const _0xff035b of _0x3b78d4) {
                const _0x14d7d8 = {
                    'lbs_region_code': _0xff035b['lbs_region_code'],
                    'lbs_location_key': _0xff035b['lbs_location_key'],
                    'lbs_evse_key': _0xff035b['lbs_evse_key'],
                    'lbs_connector_key': _0xff035b['lbs_connector_key'],
                    'id': _0xff035b['id'],
                    'standard': _0xff035b['standard'],
                    'format': _0xff035b['format'],
                    'power_type': _0xff035b['power_type'],
                    'max_voltage': _0xff035b['max_voltage'],
                    'max_amperage': _0xff035b['max_amperage'],
                    'max_electric_power': _0xff035b['max_electric_power'],
                    'terms_and_conditions': _0xff035b['terms_and_conditions'],
                    'last_updated': _0xff035b['last_updated']
                };
                _0x1f17ab['set'](_0x14d7d8['lbs_connector_key'], _0x14d7d8);
            }
        }
        let _0x1bb8b2 = 0x0, _0x52b861 = 0x0;
        for (const [_0x2b23a2, _0x11b89d] of this.#location['evses']) {
            _0x11b89d['status'] == 'AVAILABLE' && ++_0x52b861, ++_0x1bb8b2;
        }
        if (_0x52b861 == _0x1bb8b2)
            this.#evsesStat = 0x0;
        else
            _0x52b861 > 0x0 && _0x52b861 < _0x1bb8b2 ? this.#evsesStat = 0x1 : this.#evsesStat = 0x2;
    }
    ['isHit'](_0x31b966) {
        if (!this['getLayer']() || !this['getVisible']())
            return ![];
        if (this['boundaryData']['bLoad'] != logi['maps']['BoundaryData']['STATUS']['LOAD'])
            return ![];
        return logi['maps']['BoundaryChecker']['pointInRegion'](_0x31b966, this['boundaryData']['boundaryRect'], this['boundaryData']['boundaryRect']['length']);
    }
    ['draw']() {
        const _0x3beb79 = this['getMapCoord'](), _0x4488fb = this['getDevicePixelRatio'](), _0x2d5951 = this['getContext']();
        _0x2d5951['save'](), _0x2d5951['scale'](_0x4488fb, _0x4488fb);
        let _0x10be4e = null;
        if (this.#evsesStat == 0x0)
            _0x10be4e = this.#images['get']('EMPTY');
        else {
            if (this.#evsesStat == 0x1)
                _0x10be4e = this.#images['get']('PART');
            else
                this.#evsesStat == 0x2 && (_0x10be4e = this.#images['get']('FULL'));
        }
        if (this['getLayer']() && this['getVisible']()) {
            let _0x5b4314 = _0x3beb79['world2screen'](this.#position['lng'], this.#position['lat']);
            _0x5b4314['x'] += this.#offsetX, _0x5b4314['y'] += this.#offsetY;
            if (_0x10be4e) {
                const _0x86c301 = _0x10be4e['naturalWidth'], _0x1c2aa9 = _0x10be4e['naturalHeight'];
                let _0x495048 = logi['maps']['Utils']['getAlignPosition'](_0x5b4314['x'], _0x5b4314['y'], this.#align, _0x86c301, _0x1c2aa9), _0x13845f = logi['maps']['Utils']['getPivotPoint'](this.#align, _0x86c301, _0x1c2aa9);
                this.#angle == 0x0 ? _0x2d5951['drawImage'](_0x10be4e, _0x495048['x'], _0x495048['y']) : (_0x2d5951['save'](), _0x2d5951['translate'](_0x495048['x'] + _0x13845f['x'], _0x495048['y'] + _0x13845f['y']), _0x2d5951['rotate'](logi['maps']['Utils']['degToRad'](this.#angle)), _0x2d5951['drawImage'](_0x10be4e, -_0x13845f['x'], -_0x13845f['y']), _0x2d5951['restore']());
            }
        }
        logi['maps']['Defines']['DEBUG_MODE'] && this.#drawBoundaryLine(_0x2d5951, this['boundaryData']['boundaryRect']), _0x2d5951['restore']();
    }
    #drawBoundaryLine(_0x5201cf, _0x464d78) {
        if (_0x464d78?.['length'] >= 0x2) {
            _0x5201cf['beginPath'](), _0x5201cf['lineWidth'] = 0x2, _0x5201cf['strokeStyle'] = 'red', _0x5201cf['lineCap'] = 'round', _0x5201cf['lineJoin'] = 'round', _0x5201cf['moveTo'](_0x464d78[0x0]['x'], _0x464d78[0x0]['y']);
            for (let _0x2dc11a of _0x464d78) {
                _0x5201cf['lineTo'](_0x2dc11a['x'], _0x2dc11a['y']);
            }
            _0x5201cf['stroke']();
        }
    }
};
export default logi['maps']['Evcs'];