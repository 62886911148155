




















































import { Vue, Component, Prop } from 'vue-property-decorator'
import { mdiWindowClose } from '@mdi/js'

@Component({
  components: {},
})
export default class SendPopup extends Vue {
  @Prop({ type: String, default: '' }) state
  @Prop({ type: Number, default: 140 }) contentLimit

  icons: any = {
    mdiWindowClose,
  }

  title = ''
  content = ''

  clearText() {
    this.title = ''
    this.content = ''
  }

  async cancelBtn() {
    const res = await this.$confirm({
      title: '종료 안내',
      content:
        '작성 중인 내용이 있습니다. 팝업을 닫으면 <br/> 작성 중인 내용은 저장되지 않습니다.',
    })
    if (res) {
      this.clearText()
      this.$emit('close')
    }
  }

  async confirmBtn() {
    const res = await this.$confirm({
      title: '전송 안내',
      content: `해당 공지사항을 ${this.state}로 <br/> 전송 하시겠습니까?`,
    })
    if (res)
      this.$emit('confirmSend', { title: this.title, content: this.content })

    this.clearText()
    this.$emit('close')
  }
}
