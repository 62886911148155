var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: _vm.width,
        height: _vm.height,
        viewBox: _vm.viewBox,
      },
    },
    [
      _c(
        "g",
        {
          attrs: {
            id: "그룹_40424",
            "data-name": "그룹 40424",
            transform: "translate(-29 -135)",
          },
        },
        [
          _c(
            "g",
            {
              attrs: {
                id: "사각형_31249",
                "data-name": "사각형 31249",
                transform: "translate(32 139.063)",
                fill: "none",
                stroke: _vm.iconColor,
                "stroke-width": "1.6",
              },
            },
            [
              _c("rect", {
                attrs: { width: "22", height: "16", rx: "1", stroke: "none" },
              }),
              _c("rect", {
                attrs: {
                  x: "0.8",
                  y: "0.8",
                  width: "20.4",
                  height: "14.4",
                  rx: "0.2",
                  fill: "none",
                },
              }),
            ]
          ),
          _c("path", {
            attrs: {
              id: "패스_29412",
              "data-name": "패스 29412",
              d: "M-1964,176.559v4.082",
              transform: "translate(2006.644 -22.183)",
              fill: _vm.iconColor,
              stroke: _vm.iconColor,
              "stroke-width": "1.6",
            },
          }),
          _c("path", {
            attrs: {
              id: "패스_29413",
              "data-name": "패스 29413",
              d: "M0,0V11.151",
              transform: "translate(48.22 158.937) rotate(90)",
              fill: _vm.iconColor,
              stroke: _vm.iconColor,
              "stroke-width": "1.6",
            },
          }),
          _c("rect", {
            attrs: {
              id: "사각형_31250",
              "data-name": "사각형 31250",
              width: "28",
              height: "28",
              transform: "translate(29 135)",
              fill: "none",
            },
          }),
          _c(
            "g",
            {
              attrs: {
                id: "사각형_31252",
                "data-name": "사각형 31252",
                transform: "translate(42.551 142.656)",
                fill: "none",
                stroke: _vm.iconColor,
                "stroke-linejoin": "round",
                "stroke-width": "1.6",
              },
            },
            [
              _c("rect", {
                attrs: { width: "7.56", height: "8.814", stroke: "none" },
              }),
              _c("rect", {
                attrs: {
                  x: "0.8",
                  y: "0.8",
                  width: "5.96",
                  height: "7.214",
                  fill: "none",
                },
              }),
            ]
          ),
          _c("path", {
            attrs: {
              id: "패스_29419",
              "data-name": "패스 29419",
              d: "M.024,1.6H4.711",
              transform: "translate(36.252 142.656)",
              fill: _vm.iconColor,
              stroke: _vm.iconColor,
              "stroke-width": "1.6",
            },
          }),
          _c(
            "g",
            {
              attrs: {
                id: "사각형_31255",
                "data-name": "사각형 31255",
                transform: "translate(36.3 146.5)",
                fill: "none",
                stroke: _vm.iconColor,
                "stroke-linejoin": "round",
                "stroke-width": "1.6",
              },
            },
            [
              _c("rect", {
                attrs: { width: "4.7", height: "5", stroke: "none" },
              }),
              _c("rect", {
                attrs: {
                  x: "0.8",
                  y: "0.8",
                  width: "3.1",
                  height: "3.4",
                  fill: "none",
                },
              }),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }