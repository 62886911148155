



































export default {
  name: 'FindPassword',
  data() {
    return {}
  },
  methods: {},
  mounted() {},
}
