import axios from 'axios'

const v1Axios = axios.create({
  //all axios can be used, shown in axios documentation
  baseURL: `${process.env.VUE_APP_REST_V1_API_HOST}/v1`,
  headers: {
    'Authorization' : null,
    'CLIENT-APP-KEY' : `${process.env.VUE_APP_ADMIN_CLIENT_APP_KEY}`
  }
})

export {v1Axios}

export const axiosApiUrl = `${process.env.VUE_APP_REST_API_HOST}/api`
