var logi = logi ?? {};
logi['maps'] = logi['maps'] ?? {}, logi['maps']['BoundaryData'] = class {
    static ['STATUS'] = {
        'NOT_LOAD': 0x0,
        'LOAD': 0x1,
        'OVERLAP': 0x2,
        'LOAD_OUTSIDE': 0x3,
        'OVERLAP_OUTSIDE': 0x4
    };
    constructor() {
        this['bLoad'] = logi['maps']['BoundaryData']['STATUS']['NOT_LOAD'], this['groupId'] = 0x0, this['tagName'] = '', this['boundaryCircle'] = {
            'center': {
                'x': 0x0,
                'y': 0x0
            },
            'maxR': 0x0
        }, this['boundaryRect'] = [
            {
                'x': 0x0,
                'y': 0x0
            },
            {
                'x': 0x0,
                'y': 0x0
            },
            {
                'x': 0x0,
                'y': 0x0
            },
            {
                'x': 0x0,
                'y': 0x0
            }
        ], this['creationTick'] = 0x0, this['overlapCnt'] = 0x0;
    }
    static ['isSamePoint'](_0x27e55e, _0x9c95ba) {
        return _0x27e55e['x'] == _0x9c95ba['x'] && _0x27e55e['y'] == _0x9c95ba['y'];
    }
    static ['compare'](_0x348e6a, _0x1d70cc) {
        if (_0x348e6a['creationTick'] == _0x1d70cc['creationTick']) {
            if (_0x348e6a['tileId'] == _0x1d70cc['tileId'])
                return _0x348e6a['tagName'] < _0x1d70cc['tagName'];
            return _0x348e6a['tileId'] < _0x1d70cc['tileId'];
        }
        return _0x348e6a['creationTick'] < _0x1d70cc['creationTick'];
    }
    ['setBoundary'](_0x1815b0, _0x54c1b8) {
        this['boundaryCircle']['center']['x'] = _0x1815b0['center']['x'], this['boundaryCircle']['center']['y'] = _0x1815b0['center']['y'], this['boundaryCircle']['maxR'] = _0x1815b0['maxR'];
        for (let _0x10b342 = 0x0; _0x10b342 < 0x4; ++_0x10b342) {
            this['boundaryRect'][_0x10b342]['x'] = _0x54c1b8[_0x10b342]['x'], this['boundaryRect'][_0x10b342]['y'] = _0x54c1b8[_0x10b342]['y'];
        }
    }
    static ['copy'](_0x4630ae, _0x4932ae) {
        _0x4630ae['creationTick'] = _0x4932ae['creationTick'], _0x4630ae['bLoad'] = _0x4932ae['bLoad'], _0x4630ae['groupId'] = _0x4932ae['groupId'], _0x4630ae['tagName'] = _0x4932ae['tagName'], _0x4630ae['boundaryCircle']['center']['x'] = _0x4932ae['boundaryCircle']['center']['x'], _0x4630ae['boundaryCircle']['center']['y'] = _0x4932ae['boundaryCircle']['center']['y'], _0x4630ae['boundaryCircle']['maxR'] = _0x4932ae['boundaryCircle']['maxR'], _0x4630ae['boundaryRect'][0x0]['x'] = _0x4932ae['boundaryRect'][0x0]['x'], _0x4630ae['boundaryRect'][0x0]['y'] = _0x4932ae['boundaryRect'][0x0]['y'], _0x4630ae['boundaryRect'][0x1]['x'] = _0x4932ae['boundaryRect'][0x1]['x'], _0x4630ae['boundaryRect'][0x1]['y'] = _0x4932ae['boundaryRect'][0x1]['y'], _0x4630ae['boundaryRect'][0x2]['x'] = _0x4932ae['boundaryRect'][0x2]['x'], _0x4630ae['boundaryRect'][0x2]['y'] = _0x4932ae['boundaryRect'][0x2]['y'], _0x4630ae['boundaryRect'][0x3]['x'] = _0x4932ae['boundaryRect'][0x3]['x'], _0x4630ae['boundaryRect'][0x3]['y'] = _0x4932ae['boundaryRect'][0x3]['y'];
    }
    static ['merge'](_0x92cbb4, _0x835e82) {
        _0x92cbb4[0x0]['x'] = _0x92cbb4[0x0]['x'] < _0x835e82['lxMin'] ? _0x92cbb4[0x0]['x'] : _0x835e82['lxMin'], _0x92cbb4[0x3]['x'] = _0x92cbb4[0x3]['x'] < _0x835e82['lxMin'] ? _0x92cbb4[0x3]['x'] : _0x835e82['lxMin'], _0x92cbb4[0x1]['x'] = _0x92cbb4[0x1]['x'] > _0x835e82['lxMax'] ? _0x92cbb4[0x1]['x'] : _0x835e82['lxMax'], _0x92cbb4[0x2]['x'] = _0x92cbb4[0x2]['x'] > _0x835e82['lxMax'] ? _0x92cbb4[0x2]['x'] : _0x835e82['lxMax'], _0x92cbb4[0x0]['y'] = _0x92cbb4[0x0]['y'] < _0x835e82['lyMin'] ? _0x92cbb4[0x0]['y'] : _0x835e82['lyMin'], _0x92cbb4[0x1]['y'] = _0x92cbb4[0x1]['y'] < _0x835e82['lyMin'] ? _0x92cbb4[0x1]['y'] : _0x835e82['lyMin'], _0x92cbb4[0x2]['y'] = _0x92cbb4[0x2]['y'] > _0x835e82['lyMax'] ? _0x92cbb4[0x2]['y'] : _0x835e82['lyMax'], _0x92cbb4[0x3]['y'] = _0x92cbb4[0x3]['y'] > _0x835e82['lyMax'] ? _0x92cbb4[0x3]['y'] : _0x835e82['lyMax'];
    }
};
export default logi['maps']['BoundaryData'];