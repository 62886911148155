var logi = logi ?? {};
logi['maps'] = logi['maps'] ?? {}, logi['maps']['EVENT'] = {
    'dblclick': 'dblclick',
    'mousedown': 'mousedown',
    'mousemove': 'mousemove',
    'mouseup': 'mouseup',
    'touchstart': 'touchstart',
    'touchmove': 'touchmove',
    'touchend': 'touchend'
}, logi['maps']['OBJEVENT'] = {
    'dblclick': 'dblclick',
    'click': 'click',
    'dbltouch': 'dbltouch',
    'touch': 'touch'
}, logi['maps']['BRIDGE_MAPEVENT'] = {
    'movestarted': 'movestarted',
    'moved': 'moved',
    'movefinished': 'movefinished',
    'touch': 'touch'
}, logi['maps']['ALIGN'] = {
    'LT': 'left-top',
    'CT': 'center-top',
    'RT': 'right-top',
    'LM': 'left-middle',
    'CM': 'center-middle',
    'RM': 'right-middle',
    'LB': 'left-bottom',
    'CB': 'center-bottom',
    'RB': 'right-bottom'
}, logi['maps']['LINETYPE'] = {
    'STRAIGHT': 'STRAIGHT',
    'POLY': 'POLY',
    'DOT': 'DOT',
    'DASH': 'DASH'
}, logi['maps']['LatLng'] = class {
    constructor(_0x2f4357, _0x4f506b) {
        this['lat'] = _0x2f4357 ?? 0x0, this['lng'] = _0x4f506b ?? 0x0;
    }
}, logi['maps']['Point'] = class {
    constructor(_0xb623da, _0x3f172e) {
        this['x'] = _0xb623da ?? 0x0, this['y'] = _0x3f172e ?? 0x0;
    }
}, logi['maps']['LatLngBounds'] = class {
    constructor(_0x33586e, _0x36b048) {
        this['min']['lat'] = _0x33586e?.['lat'] ?? 0x0, this['min']['lng'] = _0x33586e?.['lng'] ?? 0x0, this['max']['lat'] = _0x36b048?.['lat'] ?? 0x0, this['max']['lng'] = _0x36b048?.['lng'] ?? 0x0;
    }
}, logi['maps']['Rect'] = class {
    constructor(_0x33b080, _0x50564e, _0x315a8c, _0x1f422a) {
        this['xMin'] = _0x33b080 ?? 0x0, this['yMin'] = _0x50564e ?? 0x0, this['xMax'] = _0x315a8c ?? 0x0, this['yMax'] = _0x1f422a ?? 0x0;
    }
}, logi['maps']['Size'] = class {
    constructor(_0x1f0f32, _0x6581e) {
        this['width'] = _0x1f0f32 ?? 0x0, this['height'] = _0x6581e ?? 0x0;
    }
}, logi['maps']['TileUID'] = class {
    #tileX = 0x0;
    #tileY = 0x0;
    #level = 0x0;
    #tileId = '';
    constructor(_0xb58ea8 = 0x0, _0x45d60e = 0x0, _0x180eeb = 0x0) {
        this['setTile'](_0xb58ea8, _0x45d60e, _0x180eeb);
    }
    ['setTile'](_0x4fabaa, _0x2f3e7e, _0x4443cc) {
        this.#tileX = parseInt(_0x4fabaa), this.#tileY = parseInt(_0x2f3e7e), this.#level = parseInt(_0x4443cc), this.#tileId = this.#level['toString'](0x24)['toUpperCase']() + this.#tileX['toString'](0x24)['toUpperCase']()['padStart'](0x4, '0') + this.#tileY['toString'](0x24)['toUpperCase']()['padStart'](0x4, '0');
    }
    ['setId'](_0x3c43dc) {
        const _0x22ee49 = _0x3c43dc['split']('.');
        _0x22ee49['length'] == 0x3 && (this.#tileX = parseInt(_0x22ee49[0x0], 0x24), this.#tileY = parseInt(_0x22ee49[0x1], 0x24), this.#level = parseInt(_0x22ee49[0x2], 0x24), this.#tileId = _0x3c43dc);
    }
    ['getId']() {
        return this.#tileId;
    }
    ['getTileX']() {
        return this.#tileX;
    }
    ['getTileY']() {
        return this.#tileY;
    }
    ['getLevel']() {
        return this.#level;
    }
}, logi['maps']['TileOnScreen'] = class {
    constructor() {
        this['tileUID'] = new logi['maps']['TileUID'](), this['rctOnScreen'] = new logi['maps']['Rect'](), this['distanceFromCenter'] = 0x0;
    }
    ['getId']() {
        return this['tileUID']['getId']();
    }
    ['getTileX']() {
        return this['tileUID']['getTileX']();
    }
    ['getTileY']() {
        return this['tileUID']['getTileY']();
    }
    ['getLevel']() {
        return this['tileUID']['getLevel']();
    }
}, logi['maps']['TileInfo'] = class {
    constructor() {
        this['tileId'] = '', this['boundary'] = {
            'xMin': 0x0,
            'yMin': 0x0,
            'xMax': 0x0,
            'yMax': 0x0
        };
    }
}, logi['maps']['ObjectsInTile'] = class {
    constructor() {
        this['tileInfo'] = new logi['maps']['TileInfo'](), this['objects'] = new Map();
    }
};
export default logi['maps'];