var logi = logi ?? {};
logi['maps'] = logi['maps'] ?? {};
import a13_0x2bfabd from './base/logi-maps-utils.js';
logi['maps']['Utils'] = a13_0x2bfabd;
import a13_0x11339d from './base/logi-maps-types.js';
logi['maps']['TileUID'] = a13_0x11339d['TileUID'], logi['maps']['EvcsInter'] = class {
    #MAX_WAITING_QUERY_TIME = 0xf * 0x3e8;
    #REFRESH_QUERY_TIME = 0xf * 0x3e8;
    #queriesWaitingForResponse = new Map();
    #queryRecord = new Map();
    constructor() {
    }
    ['purge']() {
        const _0x4572fe = logi['maps']['Utils']['getCurTick']();
        for (const [_0x49f6b9, _0x30973e] of this.#queriesWaitingForResponse) {
            _0x4572fe > _0x30973e + this.#MAX_WAITING_QUERY_TIME && this.#queriesWaitingForResponse['delete'](_0x49f6b9);
        }
    }
    ['requestQuery'](_0x509984) {
        return new Promise((_0x36ef9, _0x3ee8f7) => {
            const _0x5281c8 = this.#queryRecord['get'](_0x509984), _0x3e690a = logi['maps']['Utils']['getCurTick']();
            let _0x53a976 = this.#queriesWaitingForResponse['get'](_0x509984);
            if (_0x53a976) {
                _0x36ef9({
                    'items': undefined,
                    'modifiedTime': undefined
                });
                return;
            }
            if (_0x5281c8 && _0x5281c8['modifiedTime'] + this.#REFRESH_QUERY_TIME > _0x3e690a) {
                _0x36ef9(_0x5281c8);
                return;
            }
            this.#queriesWaitingForResponse['set'](_0x509984, _0x3e690a), this.#requestQuery(_0x509984)['then'](_0x46b66f => {
                if (_0x46b66f['length'] == 0x1) {
                    const _0x1ffff0 = _0x46b66f[0x0];
                    let _0x535b75 = logi['maps']['Utils']['getCurTick']();
                    this.#queryRecord['set'](_0x509984, {
                        'items': _0x1ffff0['items'],
                        'modifiedTime': _0x535b75
                    }), this.#queriesWaitingForResponse['delete'](_0x509984), _0x36ef9({
                        'items': _0x1ffff0['items'],
                        'modifiedTime': _0x535b75
                    });
                }
            });
        });
    }
    #requestQuery(_0x5c7607) {
        const _0x4beb54 = this;
        return new Promise((_0x40f013, _0x2a0055) => {
            const _0xae22d5 = new logi['maps']['TileUID']();
            _0xae22d5['setId'](_0x5c7607);
            const _0x1c714b = 'http://10.10.10.3:16001/getEvCharger';
            let _0x21b761 = '?' + encodeURIComponent('countryCode') + '=' + encodeURIComponent('KR');
            _0x21b761 += '&' + encodeURIComponent('type') + '=' + encodeURIComponent('tile'), _0x21b761 += '&' + encodeURIComponent('tilex') + '=' + encodeURIComponent(_0xae22d5['getTileX']()), _0x21b761 += '&' + encodeURIComponent('tiley') + '=' + encodeURIComponent(_0xae22d5['getTileY']()), _0x21b761 += '&' + encodeURIComponent('level') + '=' + encodeURIComponent(_0xae22d5['getLevel']());
            const _0x186b7e = new XMLHttpRequest();
            _0x186b7e['open']('GET', _0x1c714b + _0x21b761), _0x186b7e['onreadystatechange'] = function () {
                if (this['readyState'] == XMLHttpRequest['DONE']) {
                    if (this['status'] === 0x0 || this['status'] >= 0xc8 && this['status'] < 0x190) {
                        let _0x735a3 = null;
                        try {
                            _0x735a3 = JSON['parse'](this['responseText']);
                        } catch (_0xaf5b87) {
                            console['log']('[logi.maps]\x20EV\x20SERVICE\x20ERROR\x20(' + _0xae22d5['getTileX']() + ',\x20' + _0xae22d5['getTileY']() + ',\x20' + _0xae22d5['getLevel']() + ')');
                        }
                        _0x735a3?.['length'] > 0x0 ? _0x40f013(_0x735a3) : _0x2a0055();
                    }
                }
            }, _0x186b7e['send']();
        });
    }
};
export default logi['maps']['EvcsInter'];