














































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import DatePicker from '@/views/utils/DatePicker.vue'
import Pagination from '@/views/utils/Pagination.vue'
import { mdiMagnify, mdiChevronDown } from '@mdi/js'
import { State } from 'vuex-class'
import USER_STATUS from '@/views/constant/user.status'
import gradeService from '@/services-itgo/grade.service'

@Component({
  components: {
    DatePicker,
    Pagination,
  },

  mounted() {
    this.fetchGradeList()
  },
})
export default class Testimonials extends Vue {
  @State((state) => state.auth.user) loginUser

  icons: any = {
    mdiMagnify,
    mdiChevronDown,
  }

  type = null
  typeItems = [
    { text: '전체', value: null },
    { text: '주선사', value: 3 },
    { text: '차주', value: 2 },
  ]

  keyword = ''

  gradeList = {
    loading: false,
    headers: [
      { text: '회원 유형', value: 'type', align: 'center', width: '15%' },
      { text: '회원명', value: 'userName', align: 'center', width: '15%' },
      { text: '사업자명', value: 'company', align: 'center', width: '20%' },
      { text: '로그인 ID', value: 'loginId', align: 'left', width: '20%' },
      { text: '평가자수', value: 'count', align: 'left', width: '15%' },
      {
        text: '평점',
        value: 'grade',
        align: 'center',
        width: '15%',
      },
    ],
    items: [],
    serverItemsLength: 0,
  }

  get totalPageCount() {
    const length = Math.ceil(this.gradeList.serverItemsLength / this.pageSize)
    return length === 0 ? 1 : length
  }

  pageSizes = [10, 20, 30]
  pageSize = 10
  pageNum = 1

  /**
   * pageSize: 페이지 크기
   * pageNum: 페이지 번호
   */
  @Watch('pageSize')
  onChangePageSize() {
    this.pageNum = 1
    this.fetchGradeList()
  }

  @Watch('pageNum')
  onChangePageNum() {
    this.fetchGradeList()
  }

  async fetchGradeList() {
    try {
      console.log('fetchGradeList')

      this.gradeList.loading = true
      this.gradeList.items = []
      //this.gradeList.serverItemsLength = 0

      const params = {
        userType: this.type,
        keyword: this.keyword,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      }

      const data = await gradeService.getGradeList(params)

      this.gradeList.items = data.userList.map((item, index) => ({
        ...item,
        type: USER_STATUS.GET_USER_TYPE(item.userType),
        company:
          item.userType === USER_STATUS.USER_TYPE_DRIVER || !item.companyName
            ? '-'
            : item.companyName,
        key: `${item.name}_${item.mobile}_${index}`,
      }))
      this.gradeList.serverItemsLength = data.totalCount
    } catch (error: any) {
      this.$alert(error.response.data.errMsg)
    } finally {
      this.gradeList.loading = false
    }
  }

  onSearch() {
    this.pageNum = 1
    this.fetchGradeList()
  }
}
