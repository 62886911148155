var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: _vm.width,
        height: _vm.height,
        viewBox: _vm.viewBox,
      },
    },
    [
      _c(
        "g",
        {
          attrs: {
            id: "그룹_40428",
            "data-name": "그룹 40428",
            transform: "translate(-46 -412)",
          },
        },
        [
          _c("rect", {
            attrs: {
              id: "사각형_31201",
              "data-name": "사각형 31201",
              width: "28",
              height: "28",
              transform: "translate(46 412)",
              fill: "none",
            },
          }),
          _c(
            "g",
            {
              attrs: {
                id: "clipboard",
                transform: "translate(50.667 414.333)",
              },
            },
            [
              _c("path", {
                attrs: {
                  id: "패스_29341",
                  "data-name": "패스 29341",
                  d: "M13.771,2a2.443,2.443,0,0,0-2.443,2.443H5.221A1.221,1.221,0,0,0,4,5.664V23.985a1.221,1.221,0,0,0,1.221,1.221h17.1a1.221,1.221,0,0,0,1.221-1.221V5.664a1.221,1.221,0,0,0-1.221-1.221H16.214A2.443,2.443,0,0,0,13.771,2Zm1.221,2.443a1.221,1.221,0,1,0-2.443,0V5.664H10.107V8.107h7.328V5.664H14.992ZM8.886,8.107V5.664H5.221V23.985h17.1V5.664H18.657V8.107a1.221,1.221,0,0,1-1.221,1.221H10.107A1.221,1.221,0,0,1,8.886,8.107Z",
                  transform: "translate(-4 -2)",
                  fill: _vm.iconColor,
                  stroke: _vm.iconColor,
                  "stroke-width": "0.4",
                  "fill-rule": "evenodd",
                },
              }),
              _c("path", {
                attrs: {
                  id: "패스_29342",
                  "data-name": "패스 29342",
                  d: "M19.214,11.221H7V10H19.214Z",
                  transform: "translate(-3.336 -0.229)",
                  fill: _vm.iconColor,
                  stroke: _vm.iconColor,
                  "stroke-width": "0.4",
                  "fill-rule": "evenodd",
                },
              }),
              _c("path", {
                attrs: {
                  id: "패스_29343",
                  "data-name": "패스 29343",
                  d: "M19.214,14.221H7V13H19.214Z",
                  transform: "translate(-3.336 0.435)",
                  fill: _vm.iconColor,
                  stroke: _vm.iconColor,
                  "stroke-width": "0.4",
                  "fill-rule": "evenodd",
                },
              }),
              _c("path", {
                attrs: {
                  id: "패스_29344",
                  "data-name": "패스 29344",
                  d: "M14.328,17.221H7V16h7.328Z",
                  transform: "translate(-3.336 1.099)",
                  fill: _vm.iconColor,
                  stroke: _vm.iconColor,
                  "stroke-width": "0.4",
                  "fill-rule": "evenodd",
                },
              }),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }