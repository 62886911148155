import axios from 'axios'
import en from '@/plugins/i18n/locales/en'

class BrokerService {
  private apiUrl: string

  constructor() {
    this.apiUrl = '/broker'
  }

  /**
   * 가입 여부 조회
   */
  // getMember(data) {
  //   const body: any = {
  //     name: data.name,
  //     mobile: data.mobile,
  //     birthDt: data.birthDt,
  //   }

  //   return axios
  //     .post(this.apiUrl + `/member`, body)
  //     .then((res) => {
  //       return Promise.resolve(res.data)
  //     })
  // }

  /**
   * 게정 등록
   * {
   *     "name" : "강주선",
   *     "mobile": "010-1234-0002",
   *     "birthDt" : "1975-04-08",
   *     "loginId": "caller002",
   *     "password": "123456"
   * }
   */
  signup(data) {
    const body: any = {
      name: data.name,
      mobile: data.mobile,
      birthDt: data.birthDt,
      loginId: data.loginId,
      password: data.password,
    }

    return axios.post(this.apiUrl + `/signup`, body).then((res) => {
      return Promise.resolve(res.data)
    })
  }

  /**
   * 내 정보 수정 (사업자 정보 등록, 심사 서류 등록)
   * 사업자 정보 등록인 경우
   * {
   *     "userId" : 9,
   *     "name": "왕화주",
   *     "mobile": "010-1234-0002",
   *     "companyId" : 4
   * }
   *
   * 계좌 정보 등록인 경우
   * {
   *     "userId" : 9,
   *     "name": "왕차주",
   *     "mobile": "010-1234-0002",
   *     "bankName" : "신한은행",
   *     "accountName" : "왕차주",
   *     "accountNumber" : "123-456-789-1"
   * }
   *
   * 심사 서류 등록인 경우
   * {
   *     "userId" : 9,
   *     "name": "왕화주",
   *     "mobile": "010-1234-0002",
   *     "businessRegImg" : 사업자등록증 이미지 파일
   * }
   */
  // setUserModify(body) {
  //   return axios.put(this.apiUrl + `/modify`, body).then((res) => {
  //     return Promise.resolve(res.data)
  //   })
  // }

  /**
   * 내 정보 조회
   */
  // getMyInfo() {
  //   return axios.get(this.apiUrl + `/my-info`).then((res) => {
  //     return Promise.resolve(res.data)
  //   })
  // }

  /**
   * 내 심사 결과 조회
   */
  // getReviewResult() {
  //   return axios.get(this.apiUrl + `/review-result`).then((res) => {
  //     return Promise.resolve(res.data)
  //   })
  // }

  /**
   * 탈퇴
   */
  // setUserWithdrawal() {
  //   return axios.post(this.apiUrl + `/withdrawal`).then((res) => {
  //     return Promise.resolve(res.data)
  //   })
  // }

  /**
   * 주선사 정보 조회
   */
  getBrokerUserInfo(id) {
    const body : any = {
      userId : id
    }

    return axios.post(this.apiUrl + `/user-info`, body).then((res) => res.data)
  }

  /**
   * 관리자 - 주선사 회사 목록
   */
  getBrokerCompanyList(params) {
    return axios.post(this.apiUrl + `/page`,{},{ params }).then((res) => res.data)
  }

  /**
   * 관리자 - 주선사 관리자 목록
   */
  getBrokerList(params) {
    return axios
      .post(this.apiUrl + `/brokerList`,{}, { params })
      .then((res) => res.data)
  }

  /**
   * 관리자 - 추천 주선사 변경용 주선사 목록
   */
  getBrokerCompany(params) {
    return axios
      .get(this.apiUrl + `/companyList`, { params })
      .then((res) => res.data)
  }

  // 운송료의 위수탁 세금계산서 URL 조회
  getTaxInvoiceUrl(brokerId, callId) {
    return axios
      .get(this.apiUrl + `/tax-invoice/url?brokerId=${brokerId}&callId=${callId}`)
      .then((res) => res.data)
  }
}

export default new BrokerService()
