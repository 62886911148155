

























































































import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { State } from 'vuex-class'
import brokerService from '@/services-itgo/broker.service'
import { mdiMagnify, mdiWindowClose } from '@mdi/js'
import _ from 'lodash'
import driverService from '@/services-itgo/driver.service'

@Component({
  components: {
    PerfectScrollbar,
  },
})
export default class ChangeBrokerCompanyPopup extends Vue {
  @Prop(Boolean) value
  @Prop(Object) item

  @State((state) => state.auth.user) loginUser

  @Watch('value')
  openPopup(value) {
    if (!value) return
    this.fetchBrokerCompany()
  }

  icons = {
    mdiMagnify,
    mdiWindowClose,
  }

  keyword = ''

  perfectScrollbarOptions: any = {
    maxScrollbarLength: 30,
    wheelPropagation: false,
  }

  selectedIndex = null
  brokerCompanyList: any[] = []

  async fetchBrokerCompany() {
    try {
      const params = {
        keyword: this.keyword,
      }

      const data = await brokerService.getBrokerCompany(params)

      this.brokerCompanyList = _.filter(data, item => {
        return !_.isNil(item.companyName)
      } )

    } catch (error: any) {
      this.$alert(error.response.data.errMsg)
    }
  }

  onSearch() {
    this.fetchBrokerCompany()
  }

  onClickChangeCompany() {
    const companyId = this.brokerCompanyList[this.selectedIndex!].companyId

    this.$emit('change', companyId)
  }

  onClickClose() {
    this.$emit('close')
  }
}
