var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      staticClass: "ma-0 pa-0",
      attrs: { persistent: "" },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "transparent" },
        [
          _c(
            "v-card-title",
            { staticClass: "font-size24 surface800 overflow-hidden" },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "btn",
                  attrs: { icon: "" },
                  on: {
                    click: function ($event) {
                      return _vm.cancelBtn()
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { size: "40" } }, [
                    _vm._v(" " + _vm._s(_vm.icons.mdiCloseCircle) + " "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "overflow-hidden content_view" },
            [
              _c(
                "perfect-scrollbar",
                {
                  staticClass: "content_view",
                  attrs: { options: _vm.perfectScrollbarOptions },
                },
                [
                  _c(
                    "div",
                    { staticClass: "pdf-div" },
                    [_c("VuePdfEmbed", { attrs: { source: _vm.pdfUrl } })],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }