












































import {Vue, Component, Watch, Prop} from 'vue-property-decorator'
import {mdiArrowRight, mdiMenuUp, mdiMenuDown, mdiCircleMedium} from '@mdi/js'

import _ from 'lodash'
import DatetimeUtils from '@/utils/datetime.utils'
import ChartjsComponentBarChart from '@/views/chartjs/charts-components/ChartjsComponentBarChart.vue'
import moment from 'moment/moment'
import CallRequestService from "@/services-itgo/call.request.service";

@Component({
  name: 'WeekRegReport',
  components: {
    ChartjsComponentBarChart,
  },
})
export default class WeekRegReport extends Vue {
  public icons = {
    mdiArrowRight,
    mdiMenuUp,
    mdiMenuDown,
    mdiCircleMedium
  }

  public weekStartDate: string = DatetimeUtils.nowPlusDays(-6, 'YYYY-MM-DD')
  public displayWeekStartDate: string = DatetimeUtils.nowPlusDays(
    -7,
    'YYYY-MM-DD',
  )
  public displayWeekEndDate: string = DatetimeUtils.nowPlusDays(
    -1,
    'YYYY-MM-DD',
  )

  public baseLabels: any[] = []
  public baseData: any[] = []
  public maxValue: number = 0
  public maxCount: string = ''
  public stepSize: number = 1

  mounted() {
    this.getWeekRegReportData()
    this.setLabelsSetting()
  }

  async getWeekRegReportData() {
    const result = await CallRequestService.getWeekRequestCount()
    this.baseData = []

    this.baseData.push(result.contents.map(function (content) {
      return content.new
    }))
    this.baseData.push(result.contents.map(function (content) {
      return content.assigned
    }))
    this.maxCount = result.new.toLocaleString()

    const maxNew : any = _.max(this.baseData[0])
    const maxAssigned : any = _.max(this.baseData[1])

    this.setMaxValue(maxNew>maxAssigned ? maxNew : maxAssigned)
  }

  setMaxValue(maxValue) {
    const power = Math.pow(10, Math.floor(Math.log10(maxValue)))
    const result = Math.floor(maxValue / power) * power
    this.maxValue = result * 2
  }

  todayString() {
    const today = DatetimeUtils.now_format('YYYY년 MM월 DD일')
    return today
  }

  setLabelsSetting() {
    const dayIndex: number = DatetimeUtils.dayOfWeekIndex(this.weekStartDate)
    let index: number = 0
    for (index; index < 7; index++) {
      const datSet: any = []
      const day: any = moment(this.displayWeekStartDate)
        .add(index, 'days')
        .format('MM.DD')
      datSet.push(DatetimeUtils.dayOfWeekString((dayIndex + index) % 7))
      this.baseLabels.push(datSet)
    }
  }
}
