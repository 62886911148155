var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": _vm.width, persistent: _vm.persistent },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c(
        "v-card",
        {
          staticClass:
            "d-flex flex-column justify-space-between pt-12 pb-8 text-center",
          staticStyle: { padding: "50px 20px 33px 20px !important" },
          attrs: { height: _vm.height },
        },
        [
          _c(
            "v-card-title",
            {
              staticClass: "flex-center-contents font-size24 font-weight700",
              staticStyle: {
                color: "#2f2f2f !important",
                padding: "0 !important",
              },
            },
            [_vm._v(" " + _vm._s(_vm.title) + " ")]
          ),
          _c("v-card-text", {
            staticClass: "flex-center-contents font-size18",
            class: { "content-only": !_vm.title },
            staticStyle: {
              "letter-spacing": "-0.3px",
              "line-height": "1.29",
              "word-break": "break-all !important",
              padding: "0 !important",
            },
            domProps: { innerHTML: _vm._s(_vm.content) },
          }),
          _c(
            "v-card-actions",
            {
              staticClass: "flex-center-contents",
              staticStyle: { padding: "0 !important" },
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "popup_btn",
                  attrs: { color: "primary" },
                  on: { click: _vm.onClickConfirm },
                },
                [_vm._v(" " + _vm._s(_vm.confirmText) + " ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "popup_btn cancel_btn",
                  on: { click: _vm.onClickCancel },
                },
                [_vm._v(" " + _vm._s(_vm.cancelText) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }