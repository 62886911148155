import _ from 'lodash'

export const CONTROLLER_ROLE: any = (function () {

  enum _CONTROLLER_ROLE {
    SUPERADMIN = 'SUPERADMIN',
    ADMIN = 'ADMIN',
    MANAGER = 'MANAGER',
    USER = 'USER',
  }

  return {
    SUPERADMIN: _CONTROLLER_ROLE.SUPERADMIN,
    ADMIN: _CONTROLLER_ROLE.ADMIN,
    MANAGER: _CONTROLLER_ROLE.MANAGER,
    USER: _CONTROLLER_ROLE.USER,

    ALL: [
      _CONTROLLER_ROLE.SUPERADMIN,
      _CONTROLLER_ROLE.ADMIN,
      _CONTROLLER_ROLE.MANAGER,
      _CONTROLLER_ROLE.USER,
    ],

    MANAGERUSER: [
      _CONTROLLER_ROLE.SUPERADMIN,
      _CONTROLLER_ROLE.ADMIN,
      _CONTROLLER_ROLE.MANAGER,
    ],

    isSuperAdmin: (role: string): boolean => _CONTROLLER_ROLE.SUPERADMIN === role,
    isAdmin: (role: string): boolean => _CONTROLLER_ROLE.ADMIN === role,
    isManager: (role: string): boolean => _CONTROLLER_ROLE.MANAGER === role,
    isUser: (role: string): boolean => _CONTROLLER_ROLE.USER === role,

    // 각종 게시판 쓰기 권한 여부
    isBulletinWritable: (role: string): boolean => _.includes([_CONTROLLER_ROLE.SUPERADMIN, _CONTROLLER_ROLE.ADMIN, _CONTROLLER_ROLE.MANAGER], role),
  };
})();