


























































































































































































































































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import truck_70x70_cancel from '@/views/icons/webIcon/truck_70x70_cancel.vue'
import truck_70x70_disable from '@/views/icons/webIcon/truck_70x70_disable.vue'
import DriverService from '@/services-itgo/driver.service'
import PAYMENT_METHOD from '@/views/constant/freight.payment.type.constant'
import CALL_STATUS from '@/views/constant/call.status'
import { addCommas, formatDate, formatPhoneNumber } from '@/utils/business.util'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import CALL_REQUEST_STATUS from '../constant/call.request.status'
import moment from 'moment'
import callService from '@/services-itgo/call.service'
import _ from 'lodash'
import CARD_CANCEL_STATUS from '@/views/constant/card.cancel.status.constant'
import CardPaymentCompleted from '@/views/freightHistory/cardPaymentStatus/CardPaymentCompleted.vue'
import CardPaymentInit from '@/views/freightHistory/cardPaymentStatus/CardPaymentInit.vue'
import CardPaymentCanceled from '@/views/freightHistory/cardPaymentStatus/CardPaymentCanceled.vue'
import CardPaymentCanceledReject from '@/views/freightHistory/cardPaymentStatus/CardPaymentCanceledReject.vue'
import CardPaymentCanceledCompleted from '@/views/freightHistory/cardPaymentStatus/CardPaymentCanceledCompleted.vue'

@Component({
  name: 'FreightInfoTab',
  components: {
    CardPaymentCanceledCompleted,
    CardPaymentCanceledReject,
    CardPaymentCanceled,
    CardPaymentInit,
    CardPaymentCompleted,
    truck_70x70_cancel,
    truck_70x70_disable,
    PerfectScrollbar,
  },

  mounted() {
    this.init()
  },
})
export default class FreightInfoTab extends Vue {
  @Prop(Object) itemDetail

  @Watch('itemDetail')
  init() {
    if (!this.itemDetail.callInfos?.length) return

    this.fetchDriverInfo()
    this.fetchdriverInfoScore()

    this.approvable = this.checkApprovable()

    if (
      this.approvable &&
      this.itemDetail.callRequestStatus === CALL_REQUEST_STATUS.ASSIGN_REQUEST
    )
      this.getTimeLimit()

    //인수증 파일 가져 오기
    if (this.itemDetail.callRequestStatus === CALL_REQUEST_STATUS.COMPLETED)
      this.getInvoiceUrl()
  }

  timeLimit = ''

  approvable = true

  checkApprovable() {
    const assignedDt = this.itemDetail.callInfos[0].assignedDt
    if (!assignedDt) return false

    const assignedDate = moment(assignedDt).add(5, 'minutes')

    const current = moment()

    const minutes = moment.duration(assignedDate.diff(current)).minutes()
    const seconds = moment.duration(assignedDate.diff(current)).seconds()

    if (minutes <= 0 && seconds <= 0) return false

    return true
  }

  getTimeLimit() {
    if (!this.isRequesting) return

    const assignedDt = this.itemDetail.callInfos[0].assignedDt
    if (!assignedDt) return

    const assignedDate = moment(assignedDt).add(5, 'minutes')

    const interval = setInterval(() => {
      const current = moment()

      const minutes = moment.duration(assignedDate.diff(current)).minutes()
      const seconds = moment.duration(assignedDate.diff(current)).seconds()

      if (minutes <= 0 && seconds <= 0) {
        clearInterval(interval)
        this.approvable = false
        // this.$emit('refresh')
      }

      this.timeLimit = `${minutes}분 ${seconds}초`
    }, 1000)
  }

  driverInfo: any = null

  async fetchDriverInfo() {
    try {
      this.driverInfo = null

      const id = this.itemDetail.driver?.userId

      if (!id) return

      const result = await DriverService.getDriverUserInfo(id)

      this.driverInfo = result
    } catch (error: any) {
      this.$alert(error.response.data.errMsg)
    }
  }

  driverInfoScore: any = null

  async fetchdriverInfoScore() {
    try {
      if (
        this.driverInfoScore &&
        this.driverInfoScore.driverId === this.itemDetail.driverId
      )
        return

      this.driverInfoScore = null

      const driverId = this.itemDetail.callInfos[0].driverId
      const callRequestCode = this.itemDetail.callRequestCode

      if (!driverId) return

      const body = {
        driverId: driverId,
        callRequestCode: callRequestCode,
      }
      const result = await DriverService.getDriverInfoScore(body)

      this.driverInfoScore = result
    } catch (error: any) {
      this.$alert(error.response.data.errMsg)
    }
  }

  get info() {
    return [
      {
        title: '전화번호',
        content: formatPhoneNumber(this.driverInfo?.mobile),
      },
      {
        title: '차량정보',
        content: this.driverInfo
          ? `${this.driverInfo?.weight}톤/${this.driverInfo?.vehicleType}`
          : '-',
      },
      {
        title: '차량 번호',
        content: this.driverInfo?.registrationNumber || '-',
      },
      {
        title: '평점',
        content:
          this.driverInfoScore && this.driverInfoScore.scoreAverage
            ? `${this.driverInfoScore.scoreAverage}/5`
            : '-',
      },
    ]
  }

  get paymentComplete() {
    return Boolean(this.itemDetail?.paidDt) // 결제 완료일 경우 취소 버튼 비활성화
  }

  // To do: 추후 결제취소 여부에 대한 값이 나오면 수정
  get isCanceledPayment() {
    return false
  }

  get paidByInvoice() {
    return (
      this.itemDetail?.paymentMethod === PAYMENT_METHOD.INVOICE &&
      !this.paymentCancelCompleted
    )
  }

  get paymentInfo() {
    return {
      title: this.paymentComplete
        ? this.isCanceledPayment
          ? '결제취소가 완료 되었습니다.'
          : '결제가 완료되었습니다'
        : '결제 정보',
      data: this.setPaymentData(),
    }
  }

  setPaymentData() {
    const data = [
      {
        title: '결제 방법',
        content:
          PAYMENT_METHOD.PAYMENT_NAME_WEB(this.itemDetail?.paymentMethod) ||
          '-',
      },
      {
        title: '운송료 (VAT별도)',
        content: `${addCommas(this.itemDetail?.totalFreightCharge)}원`,
      },
      {
        title: '수수료',
        content:
          this.itemDetail?.paymentMethod === PAYMENT_METHOD.INVOICE
            ? '-'
            : `${addCommas(this.itemDetail?.totalFreightFee)}원`,
      },
      {
        title: '합계 (VAT별도)',
        content: `${addCommas(this.itemDetail?.finalPaymentAmount)}원`,
      },
    ]

    const completedData = {
      title: '결제 일시',
      content: formatDate(this.itemDetail?.callInfos?.[0].settleChargeIssueDt),
    }

    const canceledData = {
      title: '결제 취소일시',
      content: formatDate(this.itemDetail?.callInfos?.[0].settleChargeIssueDt),
    }

    this.paymentComplete
      ? this.isCanceledPayment
        ? data.splice(0, 1, canceledData)
        : data.splice(0, 1, completedData)
      : ''

    return data
  }

  tab: any = null
  get tabItems() {
    console.log(this.itemDetail)
    if (
      _.isEqual(this.itemDetail.paymentMethod, PAYMENT_METHOD.CARD) ||
      this.paymentCancelCompleted
    )
      return [{ title: '화물 상태' }, { title: '인수증' }, { title: '결제' }]
    else return [{ title: '배차 승인' }, { title: '인수증' }]
  }

  get rejectionReason() {
    return this.itemDetail.callInfos[0]?.rejectionReason || ''
  }

  canceledItems = CALL_REQUEST_STATUS.CANCELLED_STATUS_LIST()

  /* 배차대기 | 배차요청 | 배차완료 | 운송중 상태일 때 인수증/결제 탭 비활성화 처리 */
  get disabledTab() {
    return (
      this.canceledItems.includes(this.itemDetail.callRequestStatus) ||
      this.itemDetail.callRequestStatus === CALL_REQUEST_STATUS.ASSIGNABLE ||
      this.itemDetail.callRequestStatus ===
        CALL_REQUEST_STATUS.ASSIGN_REQUEST ||
      this.itemDetail.callRequestStatus === CALL_REQUEST_STATUS.ASSIGNED ||
      this.itemDetail.callRequestStatus === CALL_REQUEST_STATUS.TRANSPORTING
    )
  }

  callRequestStatus: any = CALL_REQUEST_STATUS

  get isRequesting() {
    return (
      this.itemDetail.callRequestStatus === CALL_REQUEST_STATUS.ASSIGN_REQUEST
    )
  }

  get returnCaseMessage() {
    // if (
    //   this.itemDetail.callRequestStatus === CALL_REQUEST_STATUS.COMPLETED &&
    //   (this.approved || this.rejected)
    // )
    //   return `인수증 ${this.approved ? '승인이' : '완료가'} 완료되었습니다.`

    switch (this.itemDetail.callInfos[0].callStatus) {
      case CALL_STATUS.ASSIGNED: {
        return '배차 완료 처리가 되었습니다.'
      }
      case CALL_STATUS.STARTED:
      case CALL_STATUS.ARRIVED_PICKUP: {
        return '운송이 시작되었습니다.'
      }
      case CALL_STATUS.PICKED_UP:
      case CALL_STATUS.ARRIVED_DROPOFF: {
        return '하차지로 이동 중 입니다.'
      }
      case CALL_STATUS.DROPPED_OFF: {
        return '상차지로 복귀 중입니다.'
      }
      case CALL_STATUS.PICKUP_RETURNED: {
        return '상차지에 도착하였습니다.'
      }
      case CALL_STATUS.INVOICE_REGISTERED:
      case CALL_STATUS.INVOICE_REREGISTERED: {
        return '인수증 승인 요청이 왔습니다.'
      }
      case CALL_STATUS.INVOICE_REJECTED: {
        return '인수증이 반려되었습니다.'
      }
      case CALL_STATUS.COMPLETED:
      case CALL_STATUS.COMPLETED_ARBITRARY_BY_CONTROLLER: {
        return '인수증 승인이 완료되었습니다.'
      }
      default:
        return
    }
  }

  get transitCaseMessage() {
    // if (
    //   this.itemDetail.callRequestStatus === CALL_REQUEST_STATUS.COMPLETED &&
    //   (this.approved || this.rejected)
    // )
    //   return `인수증 ${this.approved ? '승인이' : '완료가'} 완료되었습니다.`

    switch (this.itemDetail.callInfos[0].callStatus) {
      case CALL_STATUS.ASSIGNED: {
        return '배차 완료 처리가 되었습니다'
      }
      case CALL_STATUS.STARTED:
      case CALL_STATUS.ARRIVED_PICKUP: {
        return '운송이 시작 되었습니다.'
      }
      case CALL_STATUS.PICKED_UP:
      case CALL_STATUS.ARRIVED_WAYPOINT: {
        return '경유지로 이동 중입니다.'
      }
      case CALL_STATUS.LEFT_WAYPOINT: {
        return '하차지로 이동 중입니다.'
      }
      case CALL_STATUS.DROPPED_OFF: {
        return '하차가 완료 되었습니다.'
      }
      case CALL_STATUS.INVOICE_REGISTERED:
      case CALL_STATUS.INVOICE_REREGISTERED: {
        return '인수증 승인 요청이 왔습니다.'
      }
      case CALL_STATUS.INVOICE_REJECTED: {
        return '인수증이 반려되었습니다.'
      }
      case CALL_STATUS.COMPLETED:
      case CALL_STATUS.COMPLETED_ARBITRARY_BY_CONTROLLER: {
        return '인수증 승인 완료되었습니다.'
      }
      default:
        return
    }
  }

  get threeStepImage() {
    let name: string = ''

    // if (this.itemDetail.callRequestStatus === CALL_REQUEST_STATUS.COMPLETED)
    //   return require(`@/assets/imgs/completed.png`)

    switch (this.itemDetail.callInfos[0].callStatus) {
      case CALL_STATUS.ASSIGNED: {
        name = 'assigned'
        break
      }
      case CALL_STATUS.STARTED: {
        // 운행시작
        // 상차지 복귀 완료 (왕복)
        name = 'started'
        break
      }
      case CALL_STATUS.ARRIVED_PICKUP: // 상차지도착
      case CALL_STATUS.PICKED_UP: {
        // 상차완료
        name = 'picked'
        break
      }
      case CALL_STATUS.ARRIVED_WAYPOINT: // 경유지도착
      case CALL_STATUS.LEFT_WAYPOINT: // 경유완료
      case CALL_STATUS.ARRIVED_DROPOFF: // 하차지도착
      case CALL_STATUS.DROPPED_OFF: // 하차완료
      case CALL_STATUS.PICKUP_RETURNED: {
        // 상차지 복귀 완료 (왕복)
        name = 'return'
        break
      }
      case CALL_STATUS.INVOICE_REGISTERED:
      case CALL_STATUS.INVOICE_REREGISTERED:
      case CALL_STATUS.INVOICE_REJECTED: {
        name = 'completed'
        break
      }
      case CALL_STATUS.COMPLETED:
      case CALL_STATUS.COMPLETED_ARBITRARY_BY_CONTROLLER: {
        name = 'finished'
        break
      }
      default:
        return
    }

    return name ? require(`@/assets/imgs/freightImages/${name}.png`) : ''
  }

  get statusMessage() {
    switch (this.itemDetail.callInfos[0].callStatus) {
      case CALL_STATUS.ASSIGNED: {
        return '배차 완료 처리가 되었습니다'
      }
      case CALL_STATUS.STARTED:
      case CALL_STATUS.ARRIVED_PICKUP: {
        return '운송이 시작되었습니다.'
      }
      case CALL_STATUS.PICKED_UP:
      case CALL_STATUS.ARRIVED_DROPOFF: {
        return '하차지로 이동 중입니다.'
      }
      case CALL_STATUS.DROPPED_OFF: {
        return '하차가 완료되었습니다.'
      }
      case CALL_STATUS.INVOICE_REGISTERED:
      case CALL_STATUS.INVOICE_REREGISTERED: {
        return '인수증 승인 요청이 왔습니다.'
      }
      case CALL_STATUS.INVOICE_REJECTED: {
        return '인수증이 반려되었습니다.'
      }
      case CALL_STATUS.COMPLETED:
      case CALL_STATUS.COMPLETED_ARBITRARY_BY_CONTROLLER: {
        return '인수증 승인 완료 되었습니다.'
      }
      case CALL_STATUS.PAYMENT_WAIT: {
        return '카드 결제 대기중 입니다.'
      }
      case CALL_STATUS.PAYMENT_FAILED: {
        return '카드 결제 실패 하였습니다.'
      }
      default:
        return
    }
  }

  get statusImage() {
    let name: string = ''

    switch (this.itemDetail.callInfos[0].callStatus) {
      case CALL_STATUS.ASSIGNED: {
        name = 'freightAssigned'
        break
      }
      case CALL_STATUS.STARTED:
      case CALL_STATUS.ARRIVED_PICKUP: {
        name = 'freightStarted'
        break
      }
      case CALL_STATUS.PICKED_UP:
      case CALL_STATUS.ARRIVED_DROPOFF: {
        name = 'freightPicked'
        break
      }
      case CALL_STATUS.DROPPED_OFF:
      case CALL_STATUS.INVOICE_REGISTERED:
      case CALL_STATUS.INVOICE_REREGISTERED:
      case CALL_STATUS.INVOICE_REJECTED: {
        name = 'freightDropped'
        break
      }
      case CALL_STATUS.COMPLETED:
      case CALL_STATUS.COMPLETED_ARBITRARY_BY_CONTROLLER:
      case CALL_STATUS.PAYMENT_WAIT:
      case CALL_STATUS.PAYMENT_FAILED: {
        name = 'freightCompleted'
        break
      }
      default:
        return
    }

    return name ? require(`@/assets/imgs/freightImages/${name}.png`) : ''
  }

  public invoiceUrlString: string = ''
  async getInvoiceUrl() {
    try {
      const params = {
        callId: this.itemDetail.callInfos[0].callId,
      }
      const result = await callService.getInvoiceFile(params)
      this.invoiceUrlString = URL.createObjectURL(result)
    } catch (e) {
      this.invoiceUrlString = require(`@/assets/imgs/freightImages/invoiceByAdmin.png`)
    } finally {
      console.log(this.invoiceUrlString)
    }
  }

  invoiceApprove: boolean | null = null
  // TODO: 이미지 필요 > 관리자 WEB에서 완료 처리 할 경우 대체 이미지로 표시
  get invoiceUrl() {
    return this.changedInvoiceByAdmin
      ? require(`@/assets/imgs/freightImages/invoiceByAdmin.png`)
      : this.itemDetail.callInfos[0].invoiceUrl
  }

  // 인수증 등록
  get registered() {
    return (
      this.itemDetail.callInfos[0].callStatus ===
        CALL_STATUS.INVOICE_REGISTERED ||
      this.itemDetail.callInfos[0].callStatus ===
        CALL_STATUS.INVOICE_REREGISTERED
    )
    // return this.itemDetail.callRequestStatus === CALL_REQUEST_STATUS.COMPLETED
    // (
    // this.itemDetail.callInfos?.[0]?.callStatus === CALL_STATUS.COMPLETED ||
    // this.itemDetail.callInfos?.[0]?.callStatus ===
    //   CALL_STATUS.COMPLETED_ARBITRARY_BY_CONTROLLER
    // )
  }

  // 인수증 승인
  get approved() {
    return (
      this.itemDetail.callInfos[0].callStatus === CALL_STATUS.COMPLETED ||
      this.itemDetail.callInfos[0].callStatus === CALL_STATUS.PAYMENT_WAIT ||
      this.itemDetail.callInfos[0].callStatus === CALL_STATUS.PAYMENT_FAILED
    )
  }

  // 인수증 반려
  get rejected() {
    return (
      this.itemDetail.callInfos?.[0]?.callStatus ===
      CALL_STATUS.INVOICE_REJECTED
    )
  }

  //관리자에 의한 의뢰 완료
  get controllerCompleted() {
    return (
      this.itemDetail.callInfos?.[0]?.callStatus ===
      CALL_STATUS.COMPLETED_ARBITRARY_BY_CONTROLLER
    )
  }

  // 인수증 있는지 확인
  get isInvoiceUrl() {
    return (
      (this.itemDetail.callInfos[0]?.callStatus ===
        CALL_STATUS.INVOICE_REGISTERED ||
        this.itemDetail.callInfos[0]?.callStatus ===
          CALL_STATUS.INVOICE_REREGISTERED ||
        this.itemDetail.callInfos[0]?.callStatus ===
          CALL_STATUS.INVOICE_REJECTED ||
        this.itemDetail.callRequestStatus === CALL_REQUEST_STATUS.COMPLETED) &&
      // this.itemDetail.callInfos[0]?.callStatus === CALL_STATUS.COMPLETED ||
      // this.itemDetail.callInfos[0]?.callStatus ===
      //   CALL_STATUS.COMPLETED_ARBITRARY_BY_CONTROLLER
      this.itemDetail.callInfos[0].invoiceUrl
    )
  }

  get changedInvoiceByAdmin() {
    return (
      this.itemDetail.callInfos[0]?.callStatus ===
      CALL_STATUS.COMPLETED_ARBITRARY_BY_CONTROLLER
    )
  }

  @Watch('tab')
  async checkCertificate(tab) {
    if (tab === 0) return

    /**
     * READ:
     ** 인수증 등록 전 상태에서 인수증 TAB 선택 시 표출 되는 팝업
     ** 확인 선택 시 배차 승인 TAB 화면으로 전환
     */
    if (tab === 1 && !this.isInvoiceUrl && !this.changedInvoiceByAdmin) {
      await this.$alert({
        title: '인수증 조회 안내',
        content:
          '인수증이 등록되지 않았습니다.<br />차주가 인수증을 등록한 후 조회가 가능합니다.',
      })
      this.tab = 0
      return
    }

    /**
     * READ:
     ** 인수증 승인 전 결제 화면 진입 시 표출 되는 팝업
     ** 확인 선택 시 팝업 종료 후 인수증 화면으로 전환
     */
    if (
      tab === 2 &&
      this.itemDetail.callRequestStatus !== CALL_REQUEST_STATUS.COMPLETED
      // !(
      // this.itemDetail.callInfos[0]?.callStatus === CALL_STATUS.COMPLETED ||
      // this.itemDetail.callInfos[0]?.callStatus ===
      //   CALL_STATUS.COMPLETED_ARBITRARY_BY_CONTROLLER
      // )
    ) {
      await this.$alert({
        content: '인수증 승인 후 결제를 진행해 주세요.',
      })
      this.tab = 1
      return
    }

    /**
     * READ:
     ** 인수증 승인 후 결제 화면 진입 시 표출 되는 팝업
     ** 확인 선택 시 팝업 종료
     */
    // if (tab === 2)
    //   await this.$confirm({
    //     title: '결제 진행 안내',
    //     content:
    //       '결제를 진행해 주세요.<br />결제를 진행 하지 않으면 인수증 승인 후<br />24시간 이후에 자동 결제 됩니다.',
    //   })
  }

  // get paymentComplete() {
  //   return Boolean(this.itemDetail?.paidDt) // 결제 완료일 경우 취소 버튼 비활성화
  // }

  get paymentCancel() {
    return Boolean(this.itemDetail?.paidCancelDt) // 결제 완료일 경우 취소 버튼 비활성화
  }

  get paymentCancelInit() {
    return _.isEqual(
      this.itemDetail.paymentCancelStatus,
      CARD_CANCEL_STATUS.INIT,
    )
  }

  get paymentCancelWait() {
    return (
      _.isEqual(
        this.itemDetail.paymentCancelStatus,
        CARD_CANCEL_STATUS.REQUEST_WAIT,
      ) ||
      _.isEqual(
        this.itemDetail.paymentCancelStatus,
        CARD_CANCEL_STATUS.REQUEST_APPROVE,
      )
    )
  }

  get paymentCancelReject() {
    return _.isEqual(
      this.itemDetail.paymentCancelStatus,
      CARD_CANCEL_STATUS.REQUEST_REJECT,
    )
  }

  get paymentCancelCompleted() {
    return _.isEqual(
      this.itemDetail.paymentCancelStatus,
      CARD_CANCEL_STATUS.REQUEST_COMPLETED,
    )
  }

  get paymentCancelFailure() {
    return _.isEqual(
      this.itemDetail.paymentCancelStatus,
      CARD_CANCEL_STATUS.REQUEST_FAILURE,
    )
  }
}
