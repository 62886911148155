

































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { mdiWindowClose } from '@mdi/js'

@Component({
  components: {},
})
export default class FileUpload extends Vue {
  @Prop(Object) mapInfo

  icons = {
    mdiWindowClose,
  }

  // @Watch('mapInfo', {deep : true})
  // changedMapInfo(value){
  //   console.log(this.mapInfo)
  //   console.log(value)
  // }

  get getPercent() {
    if (this.mapInfo?.percent && this.mapInfo?.status === 'updating')
      return this.mapInfo?.percent
    else return 0
  }

  mounted() {}
}
