

































































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import FreightStatusType from '@/views/freightHistory/FreightStatusType.vue'
import FreightPaymentType from '@/views/freightHistory/FreightPaymentType.vue'
import Vue2DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ko'
import DatetimeUtils from '@/utils/datetime.utils'
import Pagination from '@/views/utils/Pagination.vue'
import { mdiMagnify, mdiChevronDown } from '@mdi/js'
import MapCard from '@/views/map/MapCard.vue'
import { State } from 'vuex-class'
import FreightHistoryPopup from '@/views/freightHistory/FreightHistoryPopup.vue'
import USER_STATUS from '@/views/constant/user.status'
import userStatusService from '@/services-itgo/user.status.service'
import ChangeBrokerCompanyPopup from '@/views/management/ChangeBrokerCompanyPopup.vue'
import ChangeUserStatusPopup from '@/views/management/ChangeUserStatusPopup.vue'

@Component({
  components: {
    ChangeUserStatusPopup,
    ChangeBrokerCompanyPopup,
    Vue2DatePicker,
    FreightStatusType,
    FreightPaymentType,
    MapCard,
    Pagination,
    FreightHistoryPopup,
  },

  async mounted() {
    console.log('items', this.items)
    this.fetchUserStatusList()
  },
})
export default class UserStatusTab extends Vue {
  @Prop(Number) id
  @Prop(Number) type
  @Prop(Number) userStatus
  @Prop(Boolean) isBroker

  @State((state) => state.auth.user) loginUser

  icons: any = {
    mdiMagnify,
    mdiChevronDown,
  }

  get items() {
    return {
      id: this.id,
      type: this.type,
    }
  }

  userStatusString(value) {
    return USER_STATUS.GET_USER_STATUS(parseInt(value))
  }

  status = null
  statusItems = [
    { text: '전체', value: null },
    {
      text: USER_STATUS.USER_STATUS_STRING_GENERAL,
      value: USER_STATUS.USER_STATUS_GENERAL,
    },
    {
      text: USER_STATUS.USER_STATUS_STRING_CAUTION,
      value: USER_STATUS.USER_STATUS_CAUTION,
    },
    {
      text: USER_STATUS.USER_STATUS_STRING_WARNING,
      value: USER_STATUS.USER_STATUS_WARNING,
    },
    {
      text: USER_STATUS.USER_STATUS_STRING_SUSPENSION,
      value: USER_STATUS.USER_STATUS_SUSPENSION,
    },
    {
      text: USER_STATUS.USER_STATUS_STRING_FORCED_WITHDRAWAL,
      value: USER_STATUS.USER_STATUS_FORCED_WITHDRAWAL,
    },
    {
      text: USER_STATUS.USER_STATUS_STRING_WITHDRAWAL,
      value: USER_STATUS.USER_STATUS_WITHDRAWAL,
    },
    {
      text: USER_STATUS.USER_STATUS_STRING_DORMANCY,
      value: USER_STATUS.USER_STATUS_DORMANCY,
    },
  ]

  date = [new Date(), new Date()]

  @Watch('date')
  changeDates(value) {
    if (value?.length > 1) {
      this.set(value)
    }
  }

  previousDayDate: Date[] | null = null

  async set(value) {
    const temp = [...this.date]
    temp.sort((a, b) => new Date(a).getTime() - new Date(b).getTime())

    if (temp.length === 2) {
      if (DatetimeUtils.asDays(temp[0], temp[1]) >= 30) {
        await this.$alert({
          title: '기간 설정 오류',
          content: '기간 설정은 최대 30일까지 가능합니다.',
        })
      } else {
        this.previousDayDate = [...this.date]
      }
    }
  }

  userStatusInfo = {
    loading: false,
    headers: [
      {
        text: '번호',
        align: 'center',
        value: 'idx',
        show: true,
        width: '15%',
      },
      {
        text: '날짜',
        align: 'center',
        value: 'regDt',
        show: true,
        width: '20%',
      },
      {
        text: '상태',
        align: 'center',
        value: 'userStatus',
        show: true,
        width: '15%',
      },
      {
        text: '사유',
        align: 'center',
        value: 'reason',
        show: true,
        width: '30%',
      },
      {
        text: '담당자',
        align: 'center',
        value: 'adminName',
        show: true,
        width: '20%',
      },
    ],
    items: [],
    serverItemsLength: 10,
  }

  isSearched = false

  get totalPageCount() {
    const length = Math.ceil(
      this.userStatusInfo.serverItemsLength / this.pageSize,
    )
    return length === 0 ? 1 : length
  }

  /**
   * Pagiation 관련
   */
  pageSizes = [10, 20, 30]
  pageSize = 10
  pageNum = 1

  @Watch('pageSize')
  onChangePageSize() {
    this.pageNum = 1
    this.fetchUserStatusList()
  }

  @Watch('pageNum')
  onChangePageNum() {
    this.fetchUserStatusList()
  }

  async fetchUserStatusList() {
    try {
      this.userStatusInfo.loading = true
      this.userStatusInfo.items = []
      //this.userStatusInfo.serverItemsLength = 0

      const fromDate = this.date[0]
        ? DatetimeUtils.stringDate(this.date[0], 'YYYY-MM-DD')
        : ''
      const toDate = this.date[1]
        ? DatetimeUtils.datePlusDays(this.date[1], 1)
        : ''

      const params = {
        fromDt: fromDate,
        toDt: toDate,
        status: this.status,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      }

      const filteredParams = {}

      for (const key in params) {
        if (params[key] !== '') {
          filteredParams[key] = params[key]
        }
      }

      const idParam =
        this.type === USER_STATUS.USER_TYPE_BROKER
          ? {
              companyId: this.id,
            }
          : {
              userId: this.id,
            }

      const data = await userStatusService.getUserStatusList({
        ...filteredParams,
        ...idParam,
      })
      this.userStatusInfo.items = data.list.map((item, index) => ({
        ...item,
        idx: data.totalCount - (this.pageNum - 1) * this.pageSize - index,
        status: USER_STATUS.GET_USER_STATUS(Number(item.userStatus)),
        key: `${item.regDt}_${index}`,
      }))
      this.userStatusInfo.serverItemsLength = data.totalCount
    } catch (error: any) {
      this.$alert(error.response.data.errMsg)
    } finally {
      this.userStatusInfo.loading = false
    }
  }

  onChangeStatus() {
    this.isSearched = true
    this.fetchUserStatusList()
  }

  onChangeDate() {
    this.fetchUserStatusList()
  }

  // setDate(dates) {
  //   this.date = dates
  // }

  get isCaller() {
    return this.type === USER_STATUS.USER_TYPE_CALLER
  }

  get onClickChangeStatusStr() {
    if (this.userStatus === USER_STATUS.USER_STATUS_SUSPENSION)
      return '정지 해제'
    else if (this.userStatus === USER_STATUS.USER_STATUS_FORCED_WITHDRAWAL)
      return '일반 회원 처리'
    else return '회원 상태 처리'
  }

  openChangeUserStatus = false
  popupKey = 0

  async onClickChangeStatus() {
    if (this.userStatus === USER_STATUS.USER_STATUS_SUSPENSION) {
      const result = await this.$confirm({
        title: '정지 해제',
        content:
          '해당 회원을 정지 해제 하시겠습니까? <br/> 정지 해제를 할 경우 일부 서비스 사용이 재개됩니다.',
      })
      if (result) {
        let body: any = {}

        if (this.type === USER_STATUS.USER_TYPE_BROKER) {
          body = {
            companyId:
              this.type === USER_STATUS.USER_TYPE_BROKER ? this.id : null,
            userType: this.type,
            status: USER_STATUS.USER_STATUS_GENERAL,
            suspensionDays: 0,
            reason: '-',
          }
        } else {
          body = {
            userId: this.type !== USER_STATUS.USER_TYPE_BROKER ? this.id : null,
            userType: this.type,
            status: USER_STATUS.USER_STATUS_GENERAL,
            suspensionDays: 0,
            reason: '-',
          }
        }
        const data = await userStatusService.postUserStatusList(body)

        this.userStatus = data.status
        await this.$toast({
          type: 'error',
          content: `정지 해제 처리가 되었습니다.`,
        })
        await this.fetchUserStatusList()
      }
    } else {
      this.popupKey++
      this.openChangeUserStatus = true
    }
  }

  async changedUserStatus(value) {
    this.userStatus = value
  }

  async onCloseChangeUserStatus() {
    this.openChangeUserStatus = false
    this.fetchUserStatusList()
  }
}
