var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: _vm.width,
        height: _vm.height,
        viewBox: _vm.viewBox,
      },
    },
    [
      _c(
        "g",
        { attrs: { id: "ic_setting", transform: "translate(-1556 -231)" } },
        [
          _c(
            "g",
            { attrs: { id: "settings", transform: "translate(1555 230)" } },
            [
              _c("path", {
                attrs: {
                  id: "패스_29473",
                  "data-name": "패스 29473",
                  d: "M5.426,6.431,6.431,5.426a1.111,1.111,0,0,1,1.38-.153l1.438.911a7.731,7.731,0,0,1,1.583-.656l.373-1.661A1.111,1.111,0,0,1,12.289,3h1.421a1.111,1.111,0,0,1,1.084.868l.373,1.66a7.73,7.73,0,0,1,1.584.657l1.437-.911a1.111,1.111,0,0,1,1.381.153l1,1a1.111,1.111,0,0,1,.153,1.381l-.911,1.437a7.725,7.725,0,0,1,.657,1.585l1.659.372A1.111,1.111,0,0,1,23,12.29v1.42a1.111,1.111,0,0,1-.868,1.084l-1.659.372a7.728,7.728,0,0,1-.657,1.585l.911,1.437a1.111,1.111,0,0,1-.153,1.381l-1,1a1.111,1.111,0,0,1-1.381.153l-1.437-.911a7.729,7.729,0,0,1-1.585.657l-.372,1.659A1.111,1.111,0,0,1,13.71,23H12.29a1.111,1.111,0,0,1-1.084-.868l-.372-1.66a7.733,7.733,0,0,1-1.584-.657l-1.438.911a1.111,1.111,0,0,1-1.38-.153L5.426,19.569a1.111,1.111,0,0,1-.153-1.38l.911-1.438a7.732,7.732,0,0,1-.656-1.582l-1.661-.373A1.111,1.111,0,0,1,3,13.711V12.289a1.111,1.111,0,0,1,.868-1.084l1.661-.373A7.73,7.73,0,0,1,6.184,9.25L5.273,7.812A1.111,1.111,0,0,1,5.426,6.431Zm8.284-2.32H12.289l-.517,2.3-.631.183a6.619,6.619,0,0,0-1.355.562l-.576.317L7.217,6.212,6.212,7.217,7.475,9.211l-.317.576A6.618,6.618,0,0,0,6.6,11.142l-.183.631-2.3.517v1.421l2.3.517.183.631a6.618,6.618,0,0,0,.562,1.355l.317.576L6.212,18.783l1.005,1.005,1.994-1.263.576.317a6.617,6.617,0,0,0,1.356.562l.632.183.516,2.3h1.42l.515-2.3.632-.183a6.618,6.618,0,0,0,1.357-.563l.576-.318,1.993,1.263,1-1-1.263-1.992.318-.576a6.622,6.622,0,0,0,.563-1.358l.183-.632,2.3-.515V12.29l-2.3-.515-.183-.632a6.62,6.62,0,0,0-.563-1.358l-.318-.576,1.263-1.992-1-1L16.791,7.476l-.576-.318A6.618,6.618,0,0,0,14.858,6.6l-.631-.183Z",
                  fill: _vm.iconColor,
                  stroke: _vm.iconColor,
                  "stroke-width": "0.5",
                  "fill-rule": "evenodd",
                },
              }),
              _c("path", {
                attrs: {
                  id: "패스_29474",
                  "data-name": "패스 29474",
                  d: "M12.333,14.556a2.222,2.222,0,1,0-2.222-2.222A2.222,2.222,0,0,0,12.333,14.556Zm3.333-2.222A3.333,3.333,0,1,1,12.333,9,3.333,3.333,0,0,1,15.667,12.333Z",
                  transform: "translate(0.667 0.667)",
                  fill: _vm.iconColor,
                  stroke: _vm.iconColor,
                  "stroke-width": "0.5",
                  "fill-rule": "evenodd",
                },
              }),
            ]
          ),
          _c("rect", {
            attrs: {
              id: "사각형_31303",
              "data-name": "사각형 31303",
              width: "28",
              height: "28",
              transform: "translate(1556 231)",
              fill: "none",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }