













































































































































































































import { Vue, Component, Watch, Ref } from 'vue-property-decorator'
// import DatePicker from '@/views/utils/DatePicker.vue'
import Vue2DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ko'
import DatetimeUtils from '@/utils/datetime.utils'
import Pagination from '@/views/utils/Pagination.vue'
import { mdiMagnify, mdiChevronDown, mdiEmailOutline } from '@mdi/js'
import { State, Mutation } from 'vuex-class'
import UserService from '@/services-itgo/user.service'
import USER_STATUS from '@/views/constant/user.status'
import SMSSendPopup from '@/views/popup/551X792/SMSSendPopup.vue'

function getDefaultReviewCount() {
  return {
    approved: null,
    failed: null,
    reRequest: null,
    total: null,
    waiting: null,
  }
}

@Component({
  components: {
    SMSSendPopup,
    Vue2DatePicker,
    Pagination,
  },
  created() {
    this.$router.beforeEach((from, to, next) => {
      if (from.name === 'memberInfo' && to.name === 'memberReg') {
        this.setIsMemberFilterStatus({ type: 'memberReg', data: true })
      }
      next()
    })
  },
  mounted() {
    this.date = this.baseDate

    if (this.isMemberReg) {
      this.type = this.filterData.userType
      this.status = this.filterData.reviewStatus
      this.keyword = this.filterData.keyword
      this.date = [this.filterData.fromDt, this.filterData.toDt]
      this.pageNum = this.filterData.pageNum
      this.pageSize = this.filterData.pageSize
      this.setIsMemberFilterStatus({ type: 'memberReg', data: false })
    }

    this.fetchUserReviewList()
    this.fetchUserReviewCount()
  },
})
export default class MemberReg extends Vue {
  @State((state) => state.auth.user) loginUser
  @State((state) => state.pageFilter.status.isMemberReg) isMemberReg
  @State((state) => state.pageFilter.filter.memberRegFilter) filterData
  @Mutation('pageFilter/setMemberFilterData') setMemberFilterData
  @Mutation('pageFilter/setIsMemberFilterStatus') setIsMemberFilterStatus

  icons: any = {
    mdiMagnify,
    mdiChevronDown,
    mdiEmailOutline,
  }

  get isSearched() {
    return this.type || this.status || this.keyword
  }

  reviewCount = getDefaultReviewCount()

  get topItems() {
    return [
      {
        icon: require('@/assets/icons/ic_waiting.svg'),
        title: '심사 대기',
        count: this.reviewCount.waiting,
        color: '#6E6E6E',
      },
      {
        icon: require('@/assets/icons/ic_refer.svg'),
        title: '심사 반려',
        count: this.reviewCount.failed,
        color: '#2F2F2F',
      },
      {
        icon: require('@/assets/icons/ic_recycle.svg'),
        title: '재요청',
        count: this.reviewCount.reRequest,
        color: '#7538FF',
      },
      {
        icon: require('@/assets/icons/ic_agree.svg'),
        title: '심사 승인',
        count: this.reviewCount.approved,
        color: '#E5318E',
      },
    ]
  }

  get baseDate() {
    const today = new Date()
    const beforeMonth = new Date(today)
    beforeMonth.setDate(today.getDate() - 29)
    return [beforeMonth, today]
  }

  date: any = [,]

  @Watch('date')
  changeDates(value) {
    if (value?.length > 1) {
      this.set(value)
    }
  }

  previousDayDate: Date[] | null = null

  async set(value) {
    const temp = [...this.date]
    temp.sort((a, b) => new Date(a).getTime() - new Date(b).getTime())

    if (temp.length === 2) {
      if (DatetimeUtils.asDays(temp[0], temp[1]) >= 30) {
        this.date = this.previousDayDate
        await this.$alert({
          title: '기간 설정 오류',
          content: '기간 설정은 최대 30일까지 가능합니다.',
        })
      } else {
        this.previousDayDate = [...this.date]
      }
    }
  }

  keyword = ''

  status = null
  statusItems = [
    { text: '전체', value: null },
    { text: '심사 대기', value: 1 },
    { text: '재요청', value: 3 },
    { text: '심사 반려', value: 2 },
    { text: '심사 승인', value: 4 },
    // { text: '미등록', value: 5},
  ]

  type = null
  typeItems = [
    { text: '전체', value: null },
    { text: '주선사', value: 3 },
    { text: '화주', value: 1 },
    { text: '차주', value: 2 },
  ]

  userReviewInfo = {
    loading: false,
    headers: [
      { text: '심사 상태', value: 'status', align: 'center' }, //width: '15%'
      { text: '회원 유형', value: 'type', align: 'center' }, //width: '12%'
      { text: '회원명', value: 'name', align: 'center' }, //width: '15%'
      { text: '전화번호', value: 'mobile', align: 'center' }, //width: '15%'
      { text: '등록일시', value: 'regDt', align: 'center' }, //width: '10%'
      { text: '심사일시', value: 'confirmDt', align: 'center' }, //width: '10%'
      { text: '관리자ID', value: 'adminId', align: 'center' }, //width: '10%'
      { text: '문자 전송', value: 'sms', align: 'center'},
    ],
    items: [],
    serverItemsLength: 0,
  }

  get totalPageCount() {
    const length = Math.ceil(
      this.userReviewInfo.serverItemsLength / this.pageSize,
    )
    return length === 0 ? 1 : length
  }

  pageSizes = [10, 20, 30]
  pageSize = 10
  pageNum = 1

  /**
   * pageSize: 페이지 크기
   * pageNum: 페이지 번호
   */
  @Watch('pageSize')
  onChangePageSize() {
    this.pageNum = 1
    this.fetchUserReviewList()
  }

  @Watch('pageNum')
  onChangePageNum() {
    this.fetchUserReviewList()
  }

  async fetchUserReviewList() {
    console.log('this.date', this.date[0], this.date[1])

    if (this.date[0] && this.date[1]) {
      if (DatetimeUtils.asDays(this.date[0], this.date[1]) > 30) {
        this.date = this.baseDate
        return this.$alert({
          title: '기간 설정 오류',
          content: '기간 설정은 최대 30일까지 가능합니다.',
        })
      }
    }

    try {
      this.userReviewInfo.loading = true
      this.userReviewInfo.items = []
      //this.userReviewInfo.serverItemsLength = 0

      const fromDate = this.date[0]
        ? DatetimeUtils.stringDate(this.date[0], 'YYYY-MM-DD')
        : ''
      const toDate = this.date[1]
        ? DatetimeUtils.datePlusDays(this.date[1], 1)
        : ''

      const params = {
        userType: this.type,
        reviewStatus: this.status,
        fromDt: fromDate,
        toDt: toDate,
        keyword: this.keyword,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      }

      this.setMemberFilterData({
        type: 'memberReg',
        data: { ...params, fromDt: this.date[0], toDt: this.date[1] },
      })

      const data = await UserService.getUserReviewList(params)

      this.userReviewInfo.items = data.userList.map((item, index) => ({
        ...item,
        status: USER_STATUS.APPROVAL_STATUS[item.approvalStatus],
        type: USER_STATUS.GET_USER_TYPE(item.userType),
        key: `${item.name}_${item.mobile}_${index}`,
      }))
      this.userReviewInfo.serverItemsLength = data.totalCount
    } catch (error: any) {
      this.$alert(error.response.data.errMsg)
    } finally {
      this.userReviewInfo.loading = false
    }
  }

  async fetchUserReviewCount() {
    try {
      this.reviewCount = getDefaultReviewCount()

      const data = await UserService.getUserReviewCount()

      this.reviewCount = data
    } catch (error: any) {
      this.$alert(error.response.data.errMsg)
    }
  }

  onSearch() {
    this.pageNum = 1
    this.fetchUserReviewList()
  }

  onClickRow(item) {
    this.$router.push({
      name: 'memberInfo',
      params: { reviewInfo: item, reviewType: 'userReview' },
    })
  }

  openSMSSendPopup = false
  smsCountKey = 0
  preData = null
  show = false

  onOpenSMSSendPopup(item) {
    this.preData = item
    this.smsCountKey++
    this.openSMSSendPopup = true
  }

  onCloseSMSSendPopup() {
    this.openSMSSendPopup = false
    // this.fetchUserReviewList()
    // this.fetchUserReviewCount()
  }
}
