var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    { attrs: { cols: "12", sm: "12" } },
    [
      _c(
        "v-row",
        _vm._l(
          [
            [_vm.personalInfo, _vm.businessInfo],
            [_vm.vehicleInfo, _vm.brokerInfo],
          ],
          function (arr, key) {
            return _c(
              "v-col",
              {
                key: "__" + key,
                staticClass: "pa-0",
                attrs: { cols: "12", md: "6", sm: "12" },
              },
              _vm._l(arr, function (info, i) {
                return _c(
                  "v-card",
                  {
                    key: "info_" + key + "_" + i,
                    staticClass: "pa-0 elevation-0",
                  },
                  _vm._l(info, function (item, index) {
                    return item.show
                      ? _c(
                          "v-card",
                          { key: index, staticClass: "pa-11 elevation-0" },
                          [
                            _c(
                              "v-card-title",
                              {
                                key: "title_" + key + "_" + i + "_" + index,
                                staticClass:
                                  "px-0 mb-5 font-size16 surface800 font-weight700",
                              },
                              [_vm._v(" " + _vm._s(item.title) + " ")]
                            ),
                            _c(
                              "v-card-text",
                              { staticClass: "px-0 pt-0" },
                              _vm._l(item.data, function (_item, idx) {
                                return _item.show
                                  ? _c(
                                      "freight-info-item",
                                      {
                                        key:
                                          "data_" +
                                          key +
                                          "_" +
                                          i +
                                          "_" +
                                          index +
                                          "_" +
                                          idx,
                                        attrs: {
                                          title: _item.title,
                                          content: _item.content,
                                        },
                                      },
                                      [
                                        _item.btnShow
                                          ? _c(
                                              "v-btn",
                                              {
                                                staticClass:
                                                  "ma-0 pa-0 font-size14 surface800 detail-btn",
                                                attrs: { outlined: "" },
                                                on: {
                                                  click:
                                                    _vm.onClickChangeBrokerCompany,
                                                },
                                              },
                                              [_vm._v(" 변경 ")]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              }),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e()
                  }),
                  1
                )
              }),
              1
            )
          }
        ),
        1
      ),
      _c("change-broker-company-popup", {
        key: _vm.openChangedKey,
        attrs: { value: _vm.openChangeBrokerCompany },
        on: {
          change: _vm.onChangeBrokerCompany,
          close: _vm.onCloseChangeBrokerCompany,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }