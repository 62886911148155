var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      staticClass: "v-dialog wrapper",
      attrs: { persistent: "", "max-width": "480px" },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "px-8 py-4 d-flex align-center" },
            [
              _c("span", [_vm._v("화물 상태 처리")]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "pa-0",
                  attrs: { icon: "" },
                  on: { click: _vm.onClickClose },
                },
                [
                  _c("v-icon", { attrs: { size: "20" } }, [
                    _vm._v(" " + _vm._s(_vm.icons.mdiClose) + " "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider", { staticClass: "mx-8 my-0" }),
          _c(
            "v-card-text",
            { staticClass: "px-5 py-0" },
            [
              _c(
                "v-container",
                { staticClass: "px-0", attrs: { fluid: "" } },
                [
                  _c(
                    "v-radio-group",
                    {
                      staticClass: "px-2 py-0 ma-0",
                      attrs: { "hide-details": "" },
                      model: {
                        value: _vm.status,
                        callback: function ($$v) {
                          _vm.status = $$v
                        },
                        expression: "status",
                      },
                    },
                    _vm._l(_vm.statusItems, function (item, index) {
                      return _c("v-radio", {
                        key: index,
                        staticClass: "radio py-1",
                        attrs: {
                          label: item.text,
                          value: item.value,
                          color: "primary",
                          "hide-details": "",
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider", { staticClass: "ma-0" }),
          _c(
            "v-card-actions",
            { staticClass: "pb-4 flex-center-contents" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "popup_btn",
                  attrs: { color: "primary" },
                  on: { click: _vm.onClickConfirm },
                },
                [_vm._v(" 확인 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }