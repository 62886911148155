var logi = logi ?? {};
logi['maps'] = logi['maps'] ?? {};
import a21_0x323b65 from './base/logi-maps-defines.js';
logi['maps']['Defines'] = a21_0x323b65;
import a21_0x442764 from './base/logi-maps-types.js';
logi['maps']['ALIGN'] = a21_0x442764['ALIGN'];
import a21_0x165e95 from './base/logi-maps-utils.js';
logi['maps']['Utils'] = a21_0x165e95;
import a21_0x1a0127 from './base/logi-maps-resource.js';
logi['maps']['Resource'] = a21_0x1a0127, logi['maps']['TextInfo'] = class {
    #_owner;
    #_offsetX;
    #_offsetY;
    #_text;
    #_textItems;
    #_fontFamily;
    #_textColor;
    #_fontSize;
    #_textAlign;
    #_bgImgSrc;
    #_bgImgAlign;
    #_bgImgOffsetX;
    #_bgImgOffsetY;
    #_bgImage = null;
    constructor(_0x47c011, _0x154645, _0x52a3bb, _0x3318d4, _0x1d1cdd, _0x341e02, _0x431b3f, _0x2ba51b, _0x1e9906, _0x41341c, _0x40b210) {
        this.#_owner = _0x47c011, this['offsetX'] = _0x154645 ?? 0x0, this['offsetY'] = _0x52a3bb ?? 0x0, this['text'] = _0x3318d4 ?? '', this['fontFamily'] = '', this['textColor'] = _0x1d1cdd ?? '#000000', this['fontSize'] = _0x341e02 ?? 0x12, this['textAlign'] = _0x431b3f ?? logi['maps']['ALIGN']['CM'], this['bgImgSrc'] = _0x2ba51b ?? '', this['bgImgAlign'] = _0x1e9906 ?? logi['maps']['ALIGN']['CM'], this['bgImgOffsetX'] = _0x41341c ?? 0x0, this['bgImgOffsetY'] = _0x40b210 ?? 0x0, this.#_owner?.['updateObj']();
    }
    set ['offsetX'](_0x1bd0d6) {
        _0x1bd0d6 = _0x1bd0d6 ?? 0x0, this.#_offsetX !== _0x1bd0d6 && (this.#_offsetX = _0x1bd0d6, this.#_owner?.['updateObj']());
    }
    get ['offsetX']() {
        return this.#_offsetX;
    }
    set ['offsetY'](_0x4209a1) {
        _0x4209a1 = _0x4209a1 ?? 0x0, this.#_offsetY !== _0x4209a1 && (this.#_offsetY = _0x4209a1, this.#_owner?.['updateObj']());
    }
    get ['offsetY']() {
        return this.#_offsetY;
    }
    set ['text'](_0x4a3d2f) {
        _0x4a3d2f = _0x4a3d2f ?? '', this.#_text !== _0x4a3d2f && (this.#_text = _0x4a3d2f, this.#_textItems = this.#_text['toString']()['split']('\x0a'), this.#_owner?.['updateObj']());
    }
    get ['text']() {
        return this.#_text;
    }
    set ['fontFamily'](_0xb34bf0) {
        _0xb34bf0 = _0xb34bf0 ?? '', this.#_fontFamily !== _0xb34bf0 && (this.#_fontFamily = _0xb34bf0, this.#_owner?.['updateObj']());
    }
    get ['fontFamily']() {
        return this.#_fontFamily;
    }
    set ['textColor'](_0xfe4b43) {
        _0xfe4b43 = _0xfe4b43 ?? '#000000', this.#_textColor !== _0xfe4b43 && (this.#_textColor = _0xfe4b43, this.#_owner?.['updateObj']());
    }
    get ['textColor']() {
        return this.#_textColor;
    }
    set ['fontSize'](_0x556241) {
        _0x556241 = _0x556241 ?? 0x12, this.#_fontSize !== _0x556241 && (this.#_fontSize = _0x556241, this.#_owner?.['updateObj']());
    }
    get ['fontSize']() {
        return this.#_fontSize;
    }
    set ['textAlign'](_0x75dff4) {
        _0x75dff4 = _0x75dff4 ?? logi['maps']['ALIGN']['CM'], this.#_textAlign !== _0x75dff4 && (this.#_textAlign = _0x75dff4, this.#_owner?.['updateObj']());
    }
    get ['textAlign']() {
        return this.#_textAlign;
    }
    set ['bgImgSrc'](_0x4d57b8) {
        this.#_bgImgSrc = _0x4d57b8 ?? '';
        const _0x178d5d = this;
        logi['maps']['Resource']['getImage'](_0x178d5d.#_bgImgSrc)['then'](_0x5cafca => {
            _0x178d5d.#_bgImage = _0x5cafca, _0x178d5d.#_owner['updateObj']();
        })['catch'](_0x27c6c4 => {
            _0x178d5d.#_bgImage = null, _0x178d5d.#_owner['updateObj'](), console['log'](_0x27c6c4);
        });
    }
    get ['bgImgSrc']() {
        return this.#_bgImgSrc;
    }
    set ['bgImg'](_0x31c0e8) {
        this['bgImgSrc'] = _0x31c0e8;
    }
    get ['bgImg']() {
        return this['bgImgSrc'];
    }
    set ['bgImgAlign'](_0x4634df) {
        _0x4634df = _0x4634df ?? logi['maps']['ALIGN']['CM'], this.#_bgImgAlign !== _0x4634df && (this.#_bgImgAlign = _0x4634df, this.#_owner?.['updateObj']());
    }
    get ['bgImgAlign']() {
        return this.#_bgImgAlign;
    }
    set ['bgImgOffsetX'](_0x351f58) {
        _0x351f58 = _0x351f58 ?? 0x0, this.#_bgImgOffsetX !== _0x351f58 && (this.#_bgImgOffsetX = _0x351f58, this.#_owner?.['updateObj']());
    }
    get ['bgImgOffsetX']() {
        return this.#_bgImgOffsetX;
    }
    set ['bgImgOffsetY'](_0x43e549) {
        _0x43e549 = _0x43e549 ?? 0x0, this.#_bgImgOffsetY !== _0x43e549 && (this.#_bgImgOffsetY = _0x43e549, this.#_owner?.['updateObj']());
    }
    get ['bgImgOffsetY']() {
        return this.#_bgImgOffsetY;
    }
    #drawText = function (_0x431aa0, _0x347090, _0x54deb1, _0x44fe7a, _0x12e1e9, _0x4b6728, _0x51d1f8) {
        _0x44fe7a != '' && document['fonts'] && !document['fonts']['check']('1em\x20' + _0x44fe7a) && (_0x44fe7a = '');
        let _0x2c2de8 = this.#_owner?.['getContext']();
        _0x2c2de8 && (_0x44fe7a == '' ? _0x2c2de8['font'] = _0x12e1e9 + ('px\x20' + logi['maps']['Defines']['DEFAULT_FONT']) : _0x2c2de8['font'] = _0x12e1e9 + ('px\x20' + _0x44fe7a), _0x2c2de8['fillStyle'] = _0x4b6728, _0x2c2de8['textAlign'] = logi['maps']['Utils']['convertToTextAlign'](_0x51d1f8), _0x2c2de8['textBaseline'] = logi['maps']['Utils']['convertToTextBaseline'](_0x51d1f8), _0x2c2de8['fillText'](_0x431aa0, _0x347090, _0x54deb1));
    };
    ['draw'](_0x59328c, _0x237182) {
        if (this.#_text !== '') {
            let _0x527f96 = this.#_owner?.['getContext']();
            if (_0x527f96) {
                const _0x3c608a = {
                    'x': _0x59328c + this.#_offsetX,
                    'y': _0x237182 + this.#_offsetY
                };
                if (this.#_bgImage) {
                    let _0x1f8e1d = {
                        'x': _0x3c608a['x'] + this.#_bgImgOffsetX,
                        'y': _0x3c608a['y'] + this.#_bgImgOffsetY
                    };
                    _0x1f8e1d = logi['maps']['Utils']['getAlignPosition'](_0x1f8e1d['x'], _0x1f8e1d['y'], this.#_bgImgAlign, this.#_bgImage['naturalWidth'], this.#_bgImage['naturalHeight']), _0x527f96['drawImage'](this.#_bgImage, _0x1f8e1d['x'], _0x1f8e1d['y']);
                }
                const _0x319211 = this.#_fontSize * 1.2;
                let _0x1425e2 = 0x0;
                this.#_textItems['length'] >= 0x2 && ((this.#_textAlign == logi['maps']['ALIGN']['LM'] || this.#_textAlign == logi['maps']['ALIGN']['CM'] || this.#_textAlign == logi['maps']['ALIGN']['RM']) && (_0x1425e2 = (this.#_textItems['length'] - 0x1) * _0x319211 * -0.5), (this.#_textAlign == logi['maps']['ALIGN']['LB'] || this.#_textAlign == logi['maps']['ALIGN']['CB'] || this.#_textAlign == logi['maps']['ALIGN']['RB']) && (_0x1425e2 = (this.#_textItems['length'] - 0x1) * _0x319211 * -0x1)), this.#_textItems['forEach'](_0x503dc2 => {
                    this.#drawText(_0x503dc2, _0x3c608a['x'], _0x3c608a['y'] + _0x1425e2, this.#_fontFamily, this.#_fontSize, this.#_textColor, this.#_textAlign), _0x1425e2 += _0x319211;
                });
            }
        }
    }
};
export default logi['maps']['TextInfo'];