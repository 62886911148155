var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      staticClass: "v-dialog wrapper",
      attrs: { persistent: "", "max-width": "480px" },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "font-size24 surface800 font-weight900" },
            [_vm._v(" 지역 설정 ")]
          ),
          _c(
            "v-card-text",
            { staticClass: "mt-n4 pb-0" },
            [_c("v-divider")],
            1
          ),
          _c(
            "v-card-text",
            [
              _c("v-text-field", {
                attrs: {
                  label: "지역명 검색 예) 서울,서초구",
                  "append-icon": _vm.icons.mdiMagnify,
                  maxlength: "10",
                  dense: "",
                  clearable: "",
                  "hide-details": "",
                  outlined: "",
                  oninput:
                    "javascript: this.value = this.value.replace(/[^ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/gi, '' );",
                },
                on: {
                  keypress: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.onSearch.apply(null, arguments)
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "append",
                    fn: function () {
                      return [
                        _c("v-icon", { on: { click: _vm.onSearch } }, [
                          _vm._v(" " + _vm._s(_vm.icons.mdiMagnify) + " "),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.keyword,
                  callback: function ($$v) {
                    _vm.keyword = $$v
                  },
                  expression: "keyword",
                },
              }),
            ],
            1
          ),
          _c("v-divider", { staticClass: "mb-0" }),
          _c("v-card-text", { staticClass: "region-wrap" }, [
            _c("dl", { staticClass: "region-area" }, [
              _c("div", { staticClass: "region-box" }, [
                _c("dt", { staticClass: "title" }, [_vm._v("시·도")]),
                _c(
                  "dd",
                  { staticClass: "content" },
                  [
                    _c(
                      "perfect-scrollbar",
                      {
                        staticClass: "ps-favorites-notifications",
                        attrs: { options: _vm.perfectScrollbarOptions },
                      },
                      _vm._l(_vm.sidoItems, function (item, idx) {
                        return _c(
                          "span",
                          {
                            key: "sidoItem-" + idx,
                            class: [
                              "content-item",
                              _vm.sido === item ? "on" : "",
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.onSelectSido(item)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(item) + " ")]
                        )
                      }),
                      0
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "region-box" }, [
                _c("dt", { staticClass: "title" }, [_vm._v("시·구·군")]),
                _c(
                  "dd",
                  { staticClass: "content" },
                  [
                    _c(
                      "perfect-scrollbar",
                      {
                        staticClass: "ps-favorites-notifications",
                        attrs: { options: _vm.perfectScrollbarOptions },
                      },
                      _vm._l(_vm.sigunguItems, function (item, idx) {
                        return _c(
                          "span",
                          {
                            key: "sigunguItem-" + idx,
                            class: [
                              "content-item",
                              _vm.sigungu === item ? "on" : "",
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.onSelectSigugun(item)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(item) + " ")]
                        )
                      }),
                      0
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _c("v-divider", { staticClass: "ma-0" }),
          _c(
            "v-card-text",
            { staticClass: "select-wrap" },
            [
              _c(
                "perfect-scrollbar",
                {
                  staticClass: "ps-select-region",
                  attrs: { options: _vm.perfectScrollbarOptions },
                },
                [
                  _c(
                    "div",
                    { staticClass: "select-area" },
                    _vm._l(_vm.selectRegion, function (item, idx) {
                      return _c(
                        "span",
                        { key: idx, staticClass: "select-item" },
                        [
                          _vm._v(" " + _vm._s(item) + " "),
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "", "x-small": "" },
                              on: {
                                click: function ($event) {
                                  return _vm.onRemoveRegion(idx)
                                },
                              },
                            },
                            [
                              _c("v-icon", [
                                _vm._v(" " + _vm._s(_vm.icons.mdiClose) + " "),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ]
              ),
            ],
            1
          ),
          _c("v-divider", { staticClass: "ma-0" }),
          _c(
            "v-card-actions",
            { staticClass: "py-7 d-flex justify-center align-center" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "popup_btn",
                  attrs: { color: "primary" },
                  on: { click: _vm.onClickClose },
                },
                [_vm._v(" 확인 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }