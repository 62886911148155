var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "not-found" },
    [
      _c("ColorLogo"),
      _c("h2", [_vm._v("404 - 페이지를 찾을 수 없습니다.")]),
      _c("p", [_vm._v("죄송합니다. 요청하신 페이지를 찾을 수 없습니다.")]),
      _c("router-link", { attrs: { to: "/" } }, [
        _vm._v("홈페이지로 돌아가기"),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }