import _ from 'lodash'

/**
 * 의뢰 상태 정의
 */
export = (() => {
  const _CALL_REQUEST_STATUS: any = {
    ASSIGNABLE: 1, // 배차대기 (상차요청일시 이전)
    ASSIGN_REQUEST: 15, // 배차요청
    ASSIGNED: 3, // 배차완료 (상차요청일시 경과)
    TRANSPORTING: 10, // 운송중
    WAITING_FOR_DEPOSIT: 4, // 가상계좌 입금대기
    COMPLETED: 5, // 의뢰완료
    FAILED: 8, // 의뢰실패 (이거 없어도 되지 않나?)
    HIDDEN: 6, // 비공개
    CANCELLED_BY_CALLER: 7, // 화주에 의한 의뢰취소 >>> 현재 사용중인 취소 status
    HOLDING: 16, // 관제에 의한 보류

    CANCELLED_BY_PICKUP_SCHEDULE: 9, // 상차요청시각 되었으나 수주 건이 없어 자동취소 >>> 현재 사용중인 취소 status
    CANCELLED_BY_BROKER: 11, // 주선사에 의한 의뢰취소 >>> 현재 사용중인 취소 status
    CANCELLED_BY_CONTROLLER_FOR_CALLER: 12, // 화주의 요청으로 관제에 의한 의뢰취소
    CANCELLED_BY_CONTROLLER_FOR_BROKER: 13, // 주선사의 요청으로 관제에 의한 의뢰취소 (TBD)

    REGISTERED: 2, // 등록 후 결제대기
    DELETED_BY_CALLER: 21, // 화주에 의한 의뢰삭제
    DELETED_BY_CONTROLLER: 22, // 관제에 의한 의뢰삭제 >>> 현재 사용중인 취소 status
    DELETED_BY_PICKUP_SCHEDULE: 26, // 상차요청시각 되었으나 결제승인되지 않아 자동삭제
    DELETED_BY_DEPOSIT_SCHEDULE: 27, // 가상계좌 입금기한이 되었으나 입금되지 않아 자동삭제

    CANCELLED_BY_DEPOSIT_SCHEDULE: 14, // 가상계좌 입금기한이 되었으나 입금되지 않아 자동취소 (현재는 사용하지 않음)
  }
  Object.freeze(_CALL_REQUEST_STATUS)

  const _CALL_REQUEST_STATUS_NAME: Map<number, string> = new Map<
    number,
    string
  >([
    [
      _CALL_REQUEST_STATUS.REGISTERED,
      `결제대기(${_CALL_REQUEST_STATUS.REGISTERED})`,
    ],
    [
      _CALL_REQUEST_STATUS.WAITING_FOR_DEPOSIT,
      `입금대기(${_CALL_REQUEST_STATUS.WAITING_FOR_DEPOSIT})`,
    ],
    [
      _CALL_REQUEST_STATUS.ASSIGNABLE,
      `배차대기(${_CALL_REQUEST_STATUS.ASSIGNABLE})`,
    ],
    [
      _CALL_REQUEST_STATUS.ASSIGNED,
      `배차완료(${_CALL_REQUEST_STATUS.ASSIGNED})`,
    ],
    [
      _CALL_REQUEST_STATUS.COMPLETED,
      `의뢰완료(${_CALL_REQUEST_STATUS.COMPLETED})`,
    ],
    [_CALL_REQUEST_STATUS.FAILED, `의뢰실패(${_CALL_REQUEST_STATUS.FAILED})`],
    [
      _CALL_REQUEST_STATUS.CANCELLED_BY_PICKUP_SCHEDULE,
      `미수주자동의뢰취소(${_CALL_REQUEST_STATUS.CANCELLED_BY_PICKUP_SCHEDULE})`,
    ],
    [
      _CALL_REQUEST_STATUS.CANCELLED_BY_CALLER,
      `화주의뢰취소(${_CALL_REQUEST_STATUS.CANCELLED_BY_CALLER})`,
    ],
    [
      _CALL_REQUEST_STATUS.CANCELLED_BY_DISPOSER,
      `사토장의뢰취소(${_CALL_REQUEST_STATUS.CANCELLED_BY_DISPOSER})`,
    ],
    [
      _CALL_REQUEST_STATUS.CANCELLED_BY_CONTROLLER_FOR_CALLER,
      `화주관제의뢰취소(${_CALL_REQUEST_STATUS.CANCELLED_BY_CONTROLLER_FOR_CALLER})`,
    ],
    [
      _CALL_REQUEST_STATUS.CANCELLED_BY_CONTROLLER_FOR_DISPOSER,
      `사토장관제의뢰취소(${_CALL_REQUEST_STATUS.CANCELLED_BY_CONTROLLER_FOR_DISPOSER})`,
    ],
    [
      _CALL_REQUEST_STATUS.CANCELLED_BY_DEPOSIT_SCHEDULE,
      `미입금자동의뢰취소(${_CALL_REQUEST_STATUS.CANCELLED_BY_DEPOSIT_SCHEDULE})`,
    ],
    [
      _CALL_REQUEST_STATUS.DELETED_BY_CALLER,
      `화주의뢰삭제(${_CALL_REQUEST_STATUS.DELETED_BY_CALLER})`,
    ],
    [
      _CALL_REQUEST_STATUS.DELETED_BY_CONTROLLER,
      `관제의뢰삭제(${_CALL_REQUEST_STATUS.DELETED_BY_CONTROLLER})`,
    ],
    [
      _CALL_REQUEST_STATUS.DELETED_BY_PICKUP_SCHEDULE,
      `미결제자동의뢰삭제(${_CALL_REQUEST_STATUS.DELETED_BY_PICKUP_SCHEDULE})`,
    ],
    [
      _CALL_REQUEST_STATUS.DELETED_BY_DEPOSIT_SCHEDULE,
      `미입금자동의뢰삭제(${_CALL_REQUEST_STATUS.DELETED_BY_DEPOSIT_SCHEDULE})`,
    ],
  ])

  const _CALL_REQUEST_STATUS_WEB_NAME: Map<number, string> = new Map<
    number,
    string
  >([
    [_CALL_REQUEST_STATUS.REGISTERED, '결제대기'],
    [_CALL_REQUEST_STATUS.WAITING_FOR_DEPOSIT, '입금대기'],
    [_CALL_REQUEST_STATUS.ASSIGNABLE, '배차대기'],
    [_CALL_REQUEST_STATUS.ASSIGN_REQUEST, '배차요청'],
    [_CALL_REQUEST_STATUS.TRANSPORTING, '운송중'],
    [_CALL_REQUEST_STATUS.ASSIGNED, '배차완료'],
    [_CALL_REQUEST_STATUS.COMPLETED, '의뢰완료'],
    [_CALL_REQUEST_STATUS.FAILED, '의뢰실패'],
    [_CALL_REQUEST_STATUS.HIDDEN, '비공개'],
    [_CALL_REQUEST_STATUS.HOLDING, '보류중'],
    [_CALL_REQUEST_STATUS.CANCELLED_BY_BROKER, '화물취소'],
    [_CALL_REQUEST_STATUS.CANCELLED_BY_CALLER, '화주의뢰취소'],
    [_CALL_REQUEST_STATUS.CANCELLED_BY_DISPOSER, '사토장의뢰취소'],
    [
      _CALL_REQUEST_STATUS.CANCELLED_BY_CONTROLLER_FOR_CALLER,
      '화주관제의뢰취소',
    ],
    [
      _CALL_REQUEST_STATUS.CANCELLED_BY_CONTROLLER_FOR_DISPOSER,
      '사토장관제의뢰취소',
    ],
    [_CALL_REQUEST_STATUS.CANCELLED_BY_DEPOSIT_SCHEDULE, '미입금자동의뢰취소'],
    [_CALL_REQUEST_STATUS.DELETED_BY_CALLER, '화주의뢰삭제'],
    [_CALL_REQUEST_STATUS.DELETED_BY_CONTROLLER, '관제의뢰삭제'],
    [_CALL_REQUEST_STATUS.DELETED_BY_PICKUP_SCHEDULE, '미결제자동의뢰삭제'],
    [_CALL_REQUEST_STATUS.DELETED_BY_DEPOSIT_SCHEDULE, '미입금자동의뢰삭제'],
    [_CALL_REQUEST_STATUS.CANCELLED_BY_PICKUP_SCHEDULE, '화물취소'],
  ])

  const _CALL_REQUEST_STATUS_COLOR_NAME: Map<number, string> = new Map<
    number,
    string
  >([
    [_CALL_REQUEST_STATUS.REGISTERED, 'info'],
    [_CALL_REQUEST_STATUS.WAITING_FOR_DEPOSIT, 'info'],
    [_CALL_REQUEST_STATUS.ASSIGNABLE, 'info'],
    [_CALL_REQUEST_STATUS.ASSIGN_REQUEST, 'info'],
    [_CALL_REQUEST_STATUS.TRANSPORTING, 'info'],
    [_CALL_REQUEST_STATUS.ASSIGNED, 'primary'],
    [_CALL_REQUEST_STATUS.COMPLETED, 'success'],
    [_CALL_REQUEST_STATUS.FAILED, 'warning'],
    [_CALL_REQUEST_STATUS.HIDDEN, 'info'],
    [_CALL_REQUEST_STATUS.HOLDING, 'info'],
    [_CALL_REQUEST_STATUS.CANCELLED_BY_BROKER, 'error'],
    [_CALL_REQUEST_STATUS.CANCELLED_BY_CALLER, 'error'],
    [_CALL_REQUEST_STATUS.CANCELLED_BY_DISPOSER, 'error'],
    [_CALL_REQUEST_STATUS.CANCELLED_BY_CONTROLLER_FOR_CALLER, 'error'],
    [_CALL_REQUEST_STATUS.CANCELLED_BY_CONTROLLER_FOR_DISPOSER, 'error'],
    [_CALL_REQUEST_STATUS.CANCELLED_BY_DEPOSIT_SCHEDULE, 'error'],
    [_CALL_REQUEST_STATUS.DELETED_BY_CALLER, 'error'],
    [_CALL_REQUEST_STATUS.DELETED_BY_CONTROLLER, 'error'],
    [_CALL_REQUEST_STATUS.DELETED_BY_PICKUP_SCHEDULE, 'error'],
    [_CALL_REQUEST_STATUS.DELETED_BY_DEPOSIT_SCHEDULE, 'error'],
  ])

  const _CALL_REQUEST_PAYMENT_STATUS_COLOR_NAME: Map<number, string> = new Map<
    number,
    string
  >([
    [_CALL_REQUEST_STATUS.REGISTERED, 'info'],
    [_CALL_REQUEST_STATUS.WAITING_FOR_DEPOSIT, 'info'],
    [_CALL_REQUEST_STATUS.ASSIGNABLE, 'primary'],
    [_CALL_REQUEST_STATUS.ASSIGN_REQUEST, 'primary'],
    [_CALL_REQUEST_STATUS.TRANSPORTING, 'primary'],
    [_CALL_REQUEST_STATUS.ASSIGNED, 'primary'],
    [_CALL_REQUEST_STATUS.COMPLETED, 'primary'],
    [_CALL_REQUEST_STATUS.FAILED, 'warning'],
    [_CALL_REQUEST_STATUS.HIDDEN, 'info'],
    [_CALL_REQUEST_STATUS.HOLDING, 'info'],
    [_CALL_REQUEST_STATUS.CANCELLED_BY_PICKUP_SCHEDULE, 'warning'],
    [_CALL_REQUEST_STATUS.CANCELLED_BY_BROKER, 'warning'],
    [_CALL_REQUEST_STATUS.CANCELLED_BY_CALLER, 'warning'],
    [_CALL_REQUEST_STATUS.CANCELLED_BY_DISPOSER, 'warning'],
    [_CALL_REQUEST_STATUS.CANCELLED_BY_CONTROLLER_FOR_CALLER, 'warning'],
    [_CALL_REQUEST_STATUS.CANCELLED_BY_CONTROLLER_FOR_DISPOSER, 'warning'],
    [_CALL_REQUEST_STATUS.CANCELLED_BY_DEPOSIT_SCHEDULE, 'warning'],
    [_CALL_REQUEST_STATUS.DELETED_BY_CALLER, 'error'],
    [_CALL_REQUEST_STATUS.DELETED_BY_CONTROLLER, 'error'],
    [_CALL_REQUEST_STATUS.DELETED_BY_PICKUP_SCHEDULE, 'error'],
    [_CALL_REQUEST_STATUS.DELETED_BY_DEPOSIT_SCHEDULE, 'warning'],
  ])

  return Object.freeze({
    REGISTERED: _CALL_REQUEST_STATUS.REGISTERED,
    WAITING_FOR_DEPOSIT: _CALL_REQUEST_STATUS.WAITING_FOR_DEPOSIT,
    ASSIGNABLE: _CALL_REQUEST_STATUS.ASSIGNABLE,
    TRANSPORTING: _CALL_REQUEST_STATUS.TRANSPORTING,
    ASSIGN_REQUEST: _CALL_REQUEST_STATUS.ASSIGN_REQUEST,
    ASSIGNED: _CALL_REQUEST_STATUS.ASSIGNED,
    HIDDEN: _CALL_REQUEST_STATUS.HIDDEN,
    COMPLETED: _CALL_REQUEST_STATUS.COMPLETED,
    FAILED: _CALL_REQUEST_STATUS.FAILED,
    CANCELLED_BY_PICKUP_SCHEDULE:
      _CALL_REQUEST_STATUS.CANCELLED_BY_PICKUP_SCHEDULE,
    CANCELLED_BY_BROKER: _CALL_REQUEST_STATUS.CANCELLED_BY_BROKER,
    CANCELLED_BY_CALLER: _CALL_REQUEST_STATUS.CANCELLED_BY_CALLER,
    HOLDING: _CALL_REQUEST_STATUS.HOLDING,
    CANCELLED_BY_DISPOSER: _CALL_REQUEST_STATUS.CANCELLED_BY_DISPOSER,
    CANCELLED_BY_CONTROLLER_FOR_CALLER:
      _CALL_REQUEST_STATUS.CANCELLED_BY_CONTROLLER_FOR_CALLER,
    CANCELLED_BY_CONTROLLER_FOR_BROKER:
      _CALL_REQUEST_STATUS.CANCELLED_BY_CONTROLLER_FOR_BROKER,
    CANCELLED_BY_CONTROLLER_FOR_DISPOSER:
      _CALL_REQUEST_STATUS.CANCELLED_BY_CONTROLLER_FOR_DISPOSER,
    CANCELLED_BY_DEPOSIT_SCHEDULE:
      _CALL_REQUEST_STATUS.CANCELLED_BY_DEPOSIT_SCHEDULE,
    DELETED_BY_CALLER: _CALL_REQUEST_STATUS.DELETED_BY_CALLER,
    DELETED_BY_CONTROLLER: _CALL_REQUEST_STATUS.DELETED_BY_CONTROLLER,
    DELETED_BY_PICKUP_SCHEDULE: _CALL_REQUEST_STATUS.DELETED_BY_PICKUP_SCHEDULE,
    DELETED_BY_DEPOSIT_SCHEDULE:
      _CALL_REQUEST_STATUS.DELETED_BY_DEPOSIT_SCHEDULE,

    NAME: (callRequestStatus: number): string => {
      const callRequestStatusName: string | undefined =
        _CALL_REQUEST_STATUS_NAME.get(callRequestStatus)
      return callRequestStatusName ? callRequestStatusName : '-'
    },

    NAME_WEB: (callRequestStatus: number): string => {
      const callRequestStatusName: string | undefined =
        _CALL_REQUEST_STATUS_WEB_NAME.get(callRequestStatus)
      return callRequestStatusName ? callRequestStatusName : '-'
    },

    NAME_COLOR: (callRequestStatusColor: number): string => {
      const callRequestStatusNameColor: string | undefined =
        _CALL_REQUEST_STATUS_COLOR_NAME.get(callRequestStatusColor)
      return callRequestStatusNameColor ? callRequestStatusNameColor : 'error'
    },

    NAME_PAYMENT_COLOR: (callRequestStatusColor: number): string => {
      const callRequestStatusNameColor: string | undefined =
        _CALL_REQUEST_PAYMENT_STATUS_COLOR_NAME.get(callRequestStatusColor)
      return callRequestStatusNameColor ? callRequestStatusNameColor : 'error'
    },

    // 전체 의뢰 상태 목록
    ALL_LIST: (): number[] => [
      _CALL_REQUEST_STATUS.REGISTERED,
      _CALL_REQUEST_STATUS.WAITING_FOR_DEPOSIT,
      _CALL_REQUEST_STATUS.ASSIGNABLE,
      _CALL_REQUEST_STATUS.ASSIGNED,
      _CALL_REQUEST_STATUS.COMPLETED,
      _CALL_REQUEST_STATUS.FAILED,
      _CALL_REQUEST_STATUS.CANCELLED_BY_PICKUP_SCHEDULE,
      _CALL_REQUEST_STATUS.CANCELLED_BY_BROKER,
      _CALL_REQUEST_STATUS.CANCELLED_BY_CALLER,
      _CALL_REQUEST_STATUS.CANCELLED_BY_DISPOSER,
      _CALL_REQUEST_STATUS.CANCELLED_BY_CONTROLLER_FOR_CALLER,
      _CALL_REQUEST_STATUS.CANCELLED_BY_CONTROLLER_FOR_DISPOSER,
      _CALL_REQUEST_STATUS.CANCELLED_BY_DEPOSIT_SCHEDULE,
      _CALL_REQUEST_STATUS.DELETED_BY_CALLER,
      _CALL_REQUEST_STATUS.DELETED_BY_CONTROLLER,
      _CALL_REQUEST_STATUS.DELETED_BY_PICKUP_SCHEDULE,
      _CALL_REQUEST_STATUS.DELETED_BY_DEPOSIT_SCHEDULE,
    ],

    // 총 의뢰 상태 목록 (취소, 실패 제외)
    TOTAL_LIST: (): number[] => [
      _CALL_REQUEST_STATUS.REGISTERED,
      _CALL_REQUEST_STATUS.WAITING_FOR_DEPOSIT,
      _CALL_REQUEST_STATUS.ASSIGNABLE,
      _CALL_REQUEST_STATUS.ASSIGNED,
      _CALL_REQUEST_STATUS.COMPLETED,
    ],

    // 수주 가능한 의뢰 상태 목록
    ASSIGNABLE_STATUS_LIST: (): number[] => [_CALL_REQUEST_STATUS.ASSIGNABLE],

    // 결제미승인 의뢰 상태 목록
    PAYMENT_DISAPPROVED_STATUS_LIST: (): number[] => [
      _CALL_REQUEST_STATUS.REGISTERED,
    ],

    // 결제승인 의뢰 상태 목록 (가상계좌 발급 자체도 승인으로 간주한다)
    PAYMENT_APPROVED_STATUS_LIST: (): number[] => [
      _CALL_REQUEST_STATUS.WAITING_FOR_DEPOSIT,
      _CALL_REQUEST_STATUS.ASSIGNABLE,
      _CALL_REQUEST_STATUS.ASSIGNED,
      _CALL_REQUEST_STATUS.COMPLETED,
      _CALL_REQUEST_STATUS.FAILED,
    ],

    // 상차요청일시 이후 결제 정산 대상이 되는 의뢰 상태 목록
    SETTLEMENT_TARGET_STATUS_LIST: (): number[] => [
      _CALL_REQUEST_STATUS.ASSIGNED,
      _CALL_REQUEST_STATUS.COMPLETED,
    ],

    // 진행중 의뢰 상태 목록
    ON_GOING_STATUS_LIST: (): number[] => [
      _CALL_REQUEST_STATUS.REGISTERED,
      _CALL_REQUEST_STATUS.WAITING_FOR_DEPOSIT,
      _CALL_REQUEST_STATUS.ASSIGNABLE,
      _CALL_REQUEST_STATUS.ASSIGNED,
    ],

    // 종료된 의뢰 상태 목록
    TERMINATED_STATUS_LIST: (): number[] => [
      _CALL_REQUEST_STATUS.COMPLETED,
      _CALL_REQUEST_STATUS.FAILED,
      _CALL_REQUEST_STATUS.CANCELLED_BY_PICKUP_SCHEDULE,
      _CALL_REQUEST_STATUS.CANCELLED_BY_BROKER,
      _CALL_REQUEST_STATUS.CANCELLED_BY_CALLER,
      _CALL_REQUEST_STATUS.CANCELLED_BY_DISPOSER,
      _CALL_REQUEST_STATUS.CANCELLED_BY_CONTROLLER_FOR_CALLER,
      _CALL_REQUEST_STATUS.CANCELLED_BY_CONTROLLER_FOR_DISPOSER,
      _CALL_REQUEST_STATUS.CANCELLED_BY_DEPOSIT_SCHEDULE,
      _CALL_REQUEST_STATUS.DELETED_BY_CALLER,
      _CALL_REQUEST_STATUS.DELETED_BY_CONTROLLER,
      _CALL_REQUEST_STATUS.DELETED_BY_PICKUP_SCHEDULE,
      _CALL_REQUEST_STATUS.DELETED_BY_DEPOSIT_SCHEDULE,
    ],

    // 유효하지 않은 의뢰 상태 목록
    INACTIVE_STATUS_LIST: (): number[] => [
      _CALL_REQUEST_STATUS.CANCELLED_BY_PICKUP_SCHEDULE,
      _CALL_REQUEST_STATUS.CANCELLED_BY_BROKER,
      _CALL_REQUEST_STATUS.CANCELLED_BY_CALLER,
      _CALL_REQUEST_STATUS.CANCELLED_BY_DISPOSER,
      _CALL_REQUEST_STATUS.CANCELLED_BY_CONTROLLER_FOR_CALLER,
      _CALL_REQUEST_STATUS.CANCELLED_BY_CONTROLLER_FOR_DISPOSER,
      _CALL_REQUEST_STATUS.CANCELLED_BY_DEPOSIT_SCHEDULE,
      _CALL_REQUEST_STATUS.DELETED_BY_CALLER,
      _CALL_REQUEST_STATUS.DELETED_BY_CONTROLLER,
      _CALL_REQUEST_STATUS.DELETED_BY_PICKUP_SCHEDULE,
      _CALL_REQUEST_STATUS.DELETED_BY_DEPOSIT_SCHEDULE,
    ],

    // 차주가 없는 상태
    DRIVER_INACTIVE_STATUS_LIST: (): number[] => [
      _CALL_REQUEST_STATUS.CANCELLED_BY_PICKUP_SCHEDULE,
      _CALL_REQUEST_STATUS.CANCELLED_BY_CALLER,
      _CALL_REQUEST_STATUS.CANCELLED_BY_DISPOSER,
      _CALL_REQUEST_STATUS.CANCELLED_BY_CONTROLLER_FOR_CALLER,
      _CALL_REQUEST_STATUS.CANCELLED_BY_CONTROLLER_FOR_DISPOSER,
      _CALL_REQUEST_STATUS.CANCELLED_BY_DEPOSIT_SCHEDULE,
      _CALL_REQUEST_STATUS.HIDDEN,
    ],

    // 차주가 조회할 수 있는 의뢰 상태 목록
    DRIVER_READABLE_STATUS_LIST: (): number[] => [
      _CALL_REQUEST_STATUS.ASSIGNABLE,
      _CALL_REQUEST_STATUS.ASSIGNED,
      _CALL_REQUEST_STATUS.COMPLETED,
      _CALL_REQUEST_STATUS.FAILED,
    ],

    // 사토장이 조회할 수 있는 의뢰 상태 목록
    DISPOSER_READABLE_STATUS_LIST: (): number[] => [
      _CALL_REQUEST_STATUS.ASSIGNABLE,
      _CALL_REQUEST_STATUS.ASSIGNED,
      _CALL_REQUEST_STATUS.COMPLETED,
      _CALL_REQUEST_STATUS.FAILED,
    ],

    // 취소된 의뢰 상태 목록
    CANCELLED_STATUS_LIST: (): number[] => [
      _CALL_REQUEST_STATUS.CANCELLED_BY_PICKUP_SCHEDULE,
      _CALL_REQUEST_STATUS.CANCELLED_BY_CALLER,
      _CALL_REQUEST_STATUS.CANCELLED_BY_BROKER,
      _CALL_REQUEST_STATUS.CANCELLED_BY_DISPOSER,
      _CALL_REQUEST_STATUS.CANCELLED_BY_CONTROLLER_FOR_BROKER,
      _CALL_REQUEST_STATUS.CANCELLED_BY_CONTROLLER_FOR_CALLER,
      _CALL_REQUEST_STATUS.CANCELLED_BY_CONTROLLER_FOR_DISPOSER,
      _CALL_REQUEST_STATUS.CANCELLED_BY_DEPOSIT_SCHEDULE,
    ],

    // 상차일시에 자동 취소할 수 있는 의뢰 상태 목록
    PICKUP_SCHEDULE_CANCELABLE_STATUS_LIST: (): number[] => [
      _CALL_REQUEST_STATUS.WAITING_FOR_DEPOSIT,
      _CALL_REQUEST_STATUS.ASSIGNABLE,
    ],

    // 화주가 취소할 수 있는 의뢰 상태 목록
    CALLER_CANCELABLE_STATUS_LIST: (): number[] => [
      _CALL_REQUEST_STATUS.WAITING_FOR_DEPOSIT,
      _CALL_REQUEST_STATUS.ASSIGNABLE,
      _CALL_REQUEST_STATUS.ASSIGNED,
    ],

    // 사토장이 취소할 수 있는 의뢰 상태 목록
    DISPOSER_CANCELABLE_STATUS_LIST: (): number[] => [
      _CALL_REQUEST_STATUS.WAITING_FOR_DEPOSIT,
      _CALL_REQUEST_STATUS.ASSIGNABLE,
      _CALL_REQUEST_STATUS.ASSIGNED,
    ],

    // 관제가 취소할 수 있는 의뢰 상태 목록
    CONTROLLER_CANCELABLE_STATUS_LIST: (): number[] => [
      _CALL_REQUEST_STATUS.WAITING_FOR_DEPOSIT,
      _CALL_REQUEST_STATUS.ASSIGNABLE,
      _CALL_REQUEST_STATUS.ASSIGNED,
    ],

    // 삭제된 의뢰 상태 목록
    DELETED_STATUS_LIST: (): number[] => [
      _CALL_REQUEST_STATUS.DELETED_BY_CALLER,
      _CALL_REQUEST_STATUS.DELETED_BY_CONTROLLER,
      _CALL_REQUEST_STATUS.DELETED_BY_PICKUP_SCHEDULE,
      _CALL_REQUEST_STATUS.DELETED_BY_DEPOSIT_SCHEDULE,
    ],

    // 상차일시에 자동 삭제할 수 있는 의뢰 상태 목록 (not used)
    PICKUP_SCHEDULE_DELETABLE_STATUS_LIST: (): number[] => [
      _CALL_REQUEST_STATUS.REGISTERED,
    ],

    // 미입금 의뢰 상태 목록
    UNDEPOSITED_STATUS_LIST: (): number[] => [
      _CALL_REQUEST_STATUS.WAITING_FOR_DEPOSIT,
      _CALL_REQUEST_STATUS.CANCELLED_BY_DEPOSIT_SCHEDULE,
      _CALL_REQUEST_STATUS.DELETED_BY_DEPOSIT_SCHEDULE,
    ],

    // 화주가 삭제할 수 있는 의뢰 상태 목록
    CALLER_DELETABLE_STATUS_LIST: (): number[] => [
      _CALL_REQUEST_STATUS.REGISTERED,
    ],

    // 관제가 삭제할 수 있는 의뢰 상태 목록
    CONTROLLER_DELETABLE_STATUS_LIST: (): number[] => [
      _CALL_REQUEST_STATUS.REGISTERED,
    ],

    // 관제가 의뢰 완료 상태 목록
    WEB_COMPLETE_STATUS_LIST: (): number[] => [_CALL_REQUEST_STATUS.COMPLETED],

    // 관제가 의뢰 진행 상태 목록
    WEB_PROGRESS_STATUS_LIST: (): number[] => [
      _CALL_REQUEST_STATUS.ASSIGNABLE,
      _CALL_REQUEST_STATUS.ASSIGNED,
    ],

    // 관제가 의뢰 예정 상태 목록
    WEB_EXPECTED_STATUS_LIST: (): number[] => [
      _CALL_REQUEST_STATUS.REGISTERED,
      _CALL_REQUEST_STATUS.WAITING_FOR_DEPOSIT,
    ],

    // 관제웹 의뢰 대기 상태 목록
    WEB_WAITING_STATUS_LIST: (): number[] => [
      _CALL_REQUEST_STATUS.REGISTERED,
      _CALL_REQUEST_STATUS.WAITING_FOR_DEPOSIT,
      _CALL_REQUEST_STATUS.ASSIGNABLE,
    ],

    // 관제웹 의뢰 배차 완료 상태 목록
    WEB_ASSIGNED_STATUS_LIST: (): number[] => [_CALL_REQUEST_STATUS.ASSIGNED],

    // 관제웹 의뢰 실패 상태 목록
    WEB_FAILED_STATUS_LIST: (): number[] => [_CALL_REQUEST_STATUS.FAILED],

    // 관제웹 결제 상태 중 완료 목록
    WEB_PAYMENT_COMPLETE_STATUS_LIST: (): number[] => [
      _CALL_REQUEST_STATUS.ASSIGNABLE,
      _CALL_REQUEST_STATUS.ASSIGNED,
      _CALL_REQUEST_STATUS.COMPLETED,
    ],

    // WEB_PAYMENT_VIRTUAL_ACCOUNT_STATUS_LIST: (): number[] => [
    //     _CALL_REQUEST_PAYMENT_STATS.PAYMENT_VIRTUAL_ACCOUNT,
    // ],
    //
    // WEB_PAYMENT__CARD_STATUS_LIST: (): number[] => [
    //     _CALL_REQUEST_PAYMENT_STATS.PAYMENT_CARD,
    // ],
    //
    // WEB_PAYMENT_DEFERRED_STATUS_LIST: (): number[] => [
    //     _CALL_REQUEST_PAYMENT_STATS.PAYMENT_DEFERRED,
    // ],

    makeCallRequestStatusNameCaseSql: (): string => {
      let sql: string = `(CASE callRequestStatus `
      for (const entry of _CALL_REQUEST_STATUS_NAME) {
        sql += `WHEN ${entry[0]} THEN '${entry[1]}' `
      }
      sql += `ELSE '알 수 없는' END)`
      return sql
    },

    makeEachCallRequestStatusSparseCountSql: (
      statusList: number[] | undefined,
      tableName?: string,
    ): string => {
      const callRequestStatusFieldName: string = `${
        !_.isEmpty(tableName) ? tableName + '.' : ''
      }call_request_status`

      if (_.isNil(statusList)) {
        return _.chain(_.values(_CALL_REQUEST_STATUS))
          .map((callRequestStatus: number): string => {
            return `count(case when ${callRequestStatusFieldName} = ${callRequestStatus} then 1 end) as '${callRequestStatus}'`
            // return `count(if(${callRequestStatusFieldName} = ${callRequestStatus}, 1, null)) as '${callRequestStatus}'`;
          })
          .join(',\n')
          .value()
      } else if (_.isArray(statusList) && !_.isEmpty(statusList)) {
        return _.chain(statusList)
          .map((callRequestStatus: number): string => {
            return `count(case when ${callRequestStatusFieldName} = ${callRequestStatus} then 1 end) as '${callRequestStatus}'`
            // return `count(if(${callRequestStatusFieldName} = ${callRequestStatus}, 1, null)) as '${callRequestStatus}'`;
          })
          .join(',\n')
          .value()
      } else {
        return '' // error
      }
    },

    /**
     * @deprecated
     * @param queryString
     */
    getCallRequestStatusListFromQueryString: (queryString: string): any => {
      if (_.isNil(queryString)) {
        return queryString
      } else {
        const allRequestStatusList: number[] = _.values(_CALL_REQUEST_STATUS)
        const filteredRequestStatusList: number[] = _.chain(queryString)
          .split(',')
          .map(_.trim.bind(_))
          .map(_.toNumber.bind(_))
          .filter((callRequestStatus: number): boolean => {
            return _.includes(allRequestStatusList, callRequestStatus)
          })
          .value()

        return filteredRequestStatusList
      }
    },
  })
})()
