import _ from 'lodash'

/**
 * 차량 정보
 */
export = (() => {
  const _CARD_CANCEL_STATUS: any = {
    INIT: 0,
    REQUEST_WAIT: 1, // 취소 요청중
    REQUEST_APPROVE: 2, //취소 요청 승인
    REQUEST_REJECT: 3, // 취소 요청 거절
    REQUEST_COMPLETED: 4, // 취소 완료
    REQUEST_FAILURE: 5, // 취소 실패
  }

  const _CARD_CANCEL_STATUS_STRING: any = {
    INIT: '초기상태',
    REQUEST_WAIT: '취소요청중', // 취소 요청중 (주선사 행동)
    REQUEST_APPROVE: '취소요청승인', //취소 요청 승인 (차주 행동)
    REQUEST_REJECT: '취소요청거절', // 취소 요청 거절 (차주 행동)
    REQUEST_COMPLETED: '취소완료', // 취소 완료 ()
    REQUEST_FAILURE: '취소실패', // 취소 실패
  }

  Object.freeze(_CARD_CANCEL_STATUS)
  Object.freeze(_CARD_CANCEL_STATUS_STRING)

  const _CARD_CANCEL_STATUS_WEB_NAME: Map<number, string> = new Map<
    number,
    string
  >([
    [_CARD_CANCEL_STATUS.INIT, _CARD_CANCEL_STATUS_STRING.INIT],
    [_CARD_CANCEL_STATUS.REQUEST_WAIT, _CARD_CANCEL_STATUS_STRING.REQUEST_WAIT],
    [
      _CARD_CANCEL_STATUS.REQUEST_APPROVE,
      _CARD_CANCEL_STATUS_STRING.REQUEST_APPROVE,
    ],
    [
      _CARD_CANCEL_STATUS.REQUEST_REJECT,
      _CARD_CANCEL_STATUS_STRING.REQUEST_REJECT,
    ],
    [
      _CARD_CANCEL_STATUS.REQUEST_COMPLETED,
      _CARD_CANCEL_STATUS_STRING.REQUEST_COMPLETED,
    ],
    [
      _CARD_CANCEL_STATUS.REQUEST_FAILURE,
      _CARD_CANCEL_STATUS_STRING.REQUEST_FAILURE,
    ],
  ])

  return Object.freeze({
    INIT: _CARD_CANCEL_STATUS.INIT,
    REQUEST_WAIT: _CARD_CANCEL_STATUS.REQUEST_WAIT,
    REQUEST_APPROVE: _CARD_CANCEL_STATUS.REQUEST_APPROVE,
    REQUEST_REJECT: _CARD_CANCEL_STATUS.REQUEST_REJECT,
    REQUEST_COMPLETED: _CARD_CANCEL_STATUS.REQUEST_COMPLETED,
    REQUEST_FAILURE: _CARD_CANCEL_STATUS.REQUEST_FAILURE,

    INIT_STRING: _CARD_CANCEL_STATUS_STRING.INIT,
    REQUEST_WAIT_STRING: _CARD_CANCEL_STATUS_STRING.REQUEST_WAIT,
    REQUEST_APPROVE_STRING: _CARD_CANCEL_STATUS_STRING.REQUEST_APPROVE,
    REQUEST_REJECT_STRING: _CARD_CANCEL_STATUS_STRING.REQUEST_REJECT,
    REQUEST_COMPLETED_STRING: _CARD_CANCEL_STATUS_STRING.REQUEST_COMPLETED,
    REQUEST_FAILURE_STRING: _CARD_CANCEL_STATUS_STRING.REQUEST_FAILURE,

    CARD_CANCEL_STATUS_NAME_WEB: (index: number): string => {
      const cardCancelStatusName: string | undefined =
        _CARD_CANCEL_STATUS_WEB_NAME.get(index)
      return cardCancelStatusName ? cardCancelStatusName : '-'
    },
  })
})()
