var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: {
        "offset-y": "",
        left: "",
        "nudge-bottom": "22",
        transition: "slide-y-transition",
        "min-width": "328",
        "content-class": "notification-menu-content",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function (ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _vm.notifications.length
                ? _c(
                    "div",
                    { staticClass: "ml-4" },
                    [
                      _c(
                        "v-badge",
                        {
                          attrs: {
                            bordered: "",
                            color: "#E5318E",
                            dot: "",
                            overlap: "",
                          },
                        },
                        [
                          _c(
                            "v-icon",
                            _vm._g(
                              _vm._b(
                                {
                                  on: {
                                    click: function ($event) {
                                      _vm.extCount++
                                    },
                                  },
                                },
                                "v-icon",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _vm._v(
                                " " + _vm._s(_vm.icons.mdiBellOutline) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "div",
                    { staticClass: "ml-4" },
                    [
                      _c(
                        "v-icon",
                        _vm._g(_vm._b({}, "v-icon", attrs, false), on),
                        [_vm._v(" " + _vm._s(_vm.icons.mdiBellOutline) + " ")]
                      ),
                    ],
                    1
                  ),
            ]
          },
        },
      ]),
    },
    [
      _c(
        "v-card",
        { staticClass: "app-bar-notification-content-container" },
        [
          _c(
            "v-list",
            {},
            [
              _c("v-list-item", { staticClass: "d-flex" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "mt-3 d-flex align-center justify-space-between flex-grow-1",
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "text-xl font-weight-semibold" },
                      [_vm._v("알림 목록")]
                    ),
                    _vm.notifications.length
                      ? _c(
                          "v-chip",
                          {
                            attrs: { color: "#E5318E", "text-color": "white" },
                          },
                          [_vm._v(" " + _vm._s(_vm.notifications.length) + " ")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c("v-divider", { staticClass: "pb-0 mb-0" }),
          _c(
            "perfect-scrollbar",
            {
              key: _vm.extCount,
              staticClass: "ps-alarm-notifications",
              attrs: { options: _vm.perfectScrollbarOptions },
            },
            [
              _c(
                "v-list",
                { staticClass: "py-0" },
                [
                  _vm._l(_vm.notifications, function (notification, index) {
                    return [
                      _c(
                        "v-list-item",
                        {
                          key: notification.pushId,
                          staticClass: "mb-6 mt-6",
                          attrs: { dense: "" },
                          on: {
                            click: function ($event) {
                              return _vm.actionFunction(notification)
                            },
                          },
                        },
                        [
                          _c(
                            "v-list-item-content",
                            { staticClass: "d-block" },
                            [
                              _c(
                                "v-list-item-title",
                                {
                                  staticClass:
                                    "text-sm font-weight-semibold line-height12",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.titleString(notification.type)
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c("v-list-item-subtitle", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.accessTimeStr(notification.createdDt)
                                    ) +
                                    " "
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-action",
                            [
                              _c(
                                "v-btn",
                                { attrs: { icon: "" } },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "grey lighten-1" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.icons.mdiChevronRight) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  }),
                  !_vm.notifications.length
                    ? _c(
                        "div",
                        {
                          staticClass: "d-flex justify-center",
                          staticStyle: { padding: "136px 0" },
                        },
                        [
                          _c("div", [
                            _c(
                              "div",
                              { staticStyle: { "text-align": "center" } },
                              [_vm._v(" 아직 도착한 알림이 없습니다. ")]
                            ),
                            _c(
                              "div",
                              { staticStyle: { "text-align": "center" } },
                              [_vm._v(" (알림은 2추 후 자동으로 삭제됩니다.) ")]
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }