
import Alert from '@/components/Alert.vue'
import Confirm from '@/components/Confirm.vue'

export default {
  components: { Alert, Confirm },
  data() {
    return {}
  },
  computed: {
    popup() {
      return this.$store.getters['popup/getCurrentPopup']
    },
    toast: {
      get() {
        return this.$store.getters['popup/getCurrentToast']
      },
      set() {
        this.$store.getters['popup/getCurrentToast']
      },
    },
    toastItems() {
      return this.$store.getters['popup/getToastItems']
    },
    alertProps() {
      return {
        title: this.popup.title,
        content: this.popup.content,
        confirmText: this.popup.confirmText,
        persistent: this.popup.persistent,
      }
    },
    confirmProps() {
      return {
        title: this.popup.title,
        content: this.popup.content,
        cancelText: this.popup.cancelText,
        confirmText: this.popup.confirmText,
        persistent: this.popup.persistent,
      }
    },
  },
  watch: {},
  created() {
    this.$store.dispatch('popup/watchCurrentToast')
  },
  mounted() {},
  methods: {
    getToastIcon(status) {
      return status ? require(`@/assets/images/icons/toast-${status}.svg`) : ''
    },
    onClose(response?: boolean) {
      this.popup.onClose?.(response)
    },
  },
}
