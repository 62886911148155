var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { width: "100%" } }, [
    _c(
      "div",
      { staticClass: "dropzone d-flex flex-column justify-center" },
      [
        [
          _vm.getPercent
            ? _c(
                "div",
                {
                  staticClass: "d-flex flex-column justify-center",
                  staticStyle: { height: "100%" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex justify-center" },
                    [
                      _c("v-progress-circular", {
                        attrs: {
                          size: "40",
                          indeterminate: "",
                          color: "primary",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("span", { staticClass: "mt-4 font-size16" }, [
                    _vm._v("업로드 중…"),
                  ]),
                ]
              )
            : _c(
                "div",
                {
                  staticClass: "d-flex flex-column justify-center",
                  staticStyle: { height: "100%" },
                },
                [
                  _c("span", { staticClass: "mt-4 font-size16" }, [
                    _vm._v(_vm._s(_vm.mapInfo.msg ? _vm.mapInfo.msg : "")),
                  ]),
                ]
              ),
        ],
        _c("div", {
          staticClass: "dz-message",
          staticStyle: { display: "none" },
        }),
        _c("div", {
          staticStyle: { display: "none" },
          attrs: { id: "previews" },
        }),
      ],
      2
    ),
    _vm.getPercent
      ? _c("div", { staticClass: "d-flex mt-4" }, [
          _c("div", { staticClass: "mr-4" }, [
            _c("img", {
              attrs: {
                src: require("@/assets/icons/ic_image.svg"),
                width: "34",
              },
            }),
          ]),
          _c("div", { staticClass: "flex-fill mr-4" }, [
            _c("div", { staticClass: "d-flex justify-space-between" }, [
              _vm._m(0),
              _c("div", { staticClass: "font-size14" }, [
                _vm._v(_vm._s(Math.floor(_vm.getPercent)) + " %"),
              ]),
            ]),
            _c(
              "div",
              [
                _c("v-progress-linear", {
                  attrs: {
                    color: "#E5318E",
                    height: "6",
                    value: _vm.getPercent,
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div"),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("span", { staticClass: "font-size14" }, [
        _vm._v(" Map 파일 업로드 중… "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }