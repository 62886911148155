


































import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { mdiContentPaste } from '@mdi/js'
import TodaySummary from '@/views/dashBoards/TodaySummary.vue'
import WeekRegReport from '@/views/dashBoards/WeekRegReport.vue'
import BrokerDriverRegRanking from "@/views/dashBoards/BrokerDriverRegRanking.vue";
import BrokerRegDispatchRanking from "@/views/dashBoards/BrokerRegDispatchRanking.vue";
import DriverRegWithdrawalCount from "@/views/dashBoards/DriverRegWithdrawalCount.vue";

@Component({
  name: "DashBoard",
  components: {
    DriverRegWithdrawalCount,
    BrokerRegDispatchRanking,
    BrokerDriverRegRanking,
    WeekRegReport,
    TodaySummary,
  },
})
export default class DashBoard extends Vue {
  public icons = {
    mdiContentPaste,
  }
}
