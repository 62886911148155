



















































































































































































































































































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import DatePicker from '@/views/utils/DatePicker.vue'
import Pagination from '@/views/utils/Pagination.vue'
import { mdiMagnify, mdiChevronDown, mdiChevronLeft } from '@mdi/js'
import noticeService from '@/services-itgo/notice.service'
import SendPopup from '@/views/popup/551X792/SendPopup.vue'
import LoadPopup from '@/views/popup/551X792/LoadPopup.vue'
import _ from 'lodash'
import { State } from 'vuex-class'
import { CONTROLLER_ROLE } from '@/views/constant/user.role.constant'
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
import DatetimeUtils from '@/utils/datetime.utils'
import StringUtils from '@/utils/string.utils'
//@ts-ignore
import Vue2DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ko'
import moment from 'moment'
import DateTimeUtil from '@/utils/datetime.utils'

@Component({
  components: {
    DatePicker,
    Pagination,
    SendPopup,
    LoadPopup,
    VueTimepicker,
    Vue2DatePicker,
  },
  beforeRouteLeave(to, from, next) {
    // 현재 라우트가 공지사항 상세보기(toggleNotice === 1)일 때만 확인
    if (this.toggleNotice === 1) {
      // toggleNotice를 0으로 설정하고 목록 라우트로 이동
      this.toggleNotice = 0
      next(false) // 이동을 경로 막기
    } else {
      next() // 다른 상태에서는 그대로 라우트 변경을 허용
    }
  },
})
export default class NoticeManagement extends Vue {
  @State((state) => state.auth.user) loginUser

  created() {
    this.fetchCategories()
    this.fetchPageList()
  }
  public icons: any = {
    mdiMagnify,
    mdiChevronDown,
    mdiChevronLeft,
  }

  keyWord = ''

  category: any = null
  categoryItems: any = []
  categoryCreateItems: any = []
  notice: any = null

  // public selectedTime: any = null

  public pickUpConfigTime: any = {
    start: '00:30',
    step: '00:30',
    end: '23:30',
  }

  noticeTargetCreateItems = [
    { name: '공통', value: 0 },
    { name: '화주', value: 1 },
    { name: '차주', value: 2 },
    { name: '주선사', value: 3 },
  ]
  noticeItems = [{ name: '전체', value: null }, ...this.noticeTargetCreateItems]

  date: any = []

  @Watch('date')
  changeDates(value) {
    if (value?.length > 1) {
      this.set(value)
    }
  }

  previousDayDate: Date[] | null = null

  async set(value) {
    const temp = [...this.date]
    temp.sort((a, b) => new Date(a).getTime() - new Date(b).getTime())

    if (temp.length === 2) {
      if (DatetimeUtils.asDays(temp[0], temp[1]) >= 30) {
        this.date = this.previousDayDate
        await this.$alert({
          title: '기간 설정 오류',
          content: '기간 설정은 최대 30일까지 가능합니다.',
        })
      } else {
        this.previousDayDate = [...this.date]
      }
    }
  }

  get pageLength() {
    const length = Math.ceil(this.noticeInfo.serverItemsLength / this.pageSize)
    return length === 0 ? 1 : length
  }

  isSearched = false

  totalPageCount = 1
  pageSizes = [10, 20, 30]
  pageSize = 10
  pageNum = 1

  /**
   * pageSize: 페이지 크기
   * pageNum: 페이지 번호
   */
  @Watch('pageSize')
  onChangePageSize() {
    this.pageNum = 1
    this.fetchPageList()
  }

  @Watch('pageNum')
  onChangePageNum() {
    this.fetchPageList()
  }

  expanded: any = []

  noticeInfo = {
    loading: false,
    headers: [
      { text: '번호', align: 'center', value: 'noticeId', width: '10%' },
      {
        text: '공지대상',
        align: 'center',
        value: 'target',
        width: '10%',
      },
      { text: '공지유형', align: 'center', value: 'category', width: '10%' },
      { text: '제목', align: 'start', value: 'title', width: '30%' },
      { text: '작성자', align: 'center', value: 'userName', width: '10%' },
      { text: 'PUSH', align: 'center', value: 'appPushedDt', width: '10%' },
      // MODIFY: SMS 연동 시 복구
      // { text: 'SMS', align: 'center', value: 'smsPushedDt', width: '10%' },
      { text: '등록일시', align: 'center', value: 'regDt', width: '15%' },
    ],
    items: [] as any[],
    serverItemsLength: 10,
  }

  get isBulletinWritable() {
    if (CONTROLLER_ROLE.isBulletinWritable(this.loginUser.controllerRole))
      return true
    else return false
  }

  onSearch() {
    this.isSearched = true
    this.pageNum = 1
    this.fetchPageList()
  }

  async fetchCategories() {
    try {
      this.categoryItems = [{ name: '전체', value: null }]
      const params = {
        temporary: 0,
      }
      const res = await noticeService.getCategories(params)
      _.forEach(res, (item) => {
        this.categoryItems.push({ name: item, value: item })
        this.categoryCreateItems.push({ name: item, value: item })
      })
    } catch (e) {
    } finally {
    }
  }

  async fetchPageList() {
    try {
      this.noticeInfo.loading = true

      const params = {
        temporary: false,
        noticeTargetList: this.notice === null ? null : this.notice.toString(),
        category: this.category,
        keyword: _.isEmpty(this.keyWord) ? null : this.keyWord,
        fromDt: this.date[0]
          ? DatetimeUtils.setTypeStartDate(this.date[0], 'hour')
          : null,
        toDt: this.date[1]
          ? DatetimeUtils.setTypeEndDate(this.date[1], 'hour')
          : null,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      }

      const res = await noticeService.getPageList(params)

      this.noticeInfo.items = res.content.map((x) => ({
        ...x,
        target:
          this.noticeItems.find((y) => y.value === x.noticeTarget)?.name || '-',
      }))

      this.noticeInfo.serverItemsLength = res.totalElementCount
      this.totalPageCount = this.pageLength
    } catch (err) {
      console.error(err)
    } finally {
      this.noticeInfo.loading = false
    }
  }

  async fetchTemporaryPageList() {
    try {
      const params = {
        temporary: true,
      }
      const res = await noticeService.getPageList(params)
      this.temporaryItems = res.content
    } catch (err) {
      console.error(err)
    } finally {
    }
  }

  /**
   * 공지사항 - 새 페이지
   */

  selected: any = {}
  categoryDetail: any = {}

  // 리스트: 0, 내용: 1, 작성: 2
  toggleNotice = 0

  backToList() {
    this.toggleNotice = 0
    this.selected = {}
    this.clearCreateContent()
    this.fetchPageList()
  }

  async backKeyToList() {
    console.log(this.isChangedContents)
    if (this.isChangedContents) {
      const result = await this.$confirm({
        title: '종료 안내',
        content:
          '변경 또는 작성 중인 내용이 있습니다.<br/> 팝업을 닫으면 변경 또는 작성 중인<br/> 내용은 저장되지 않습니다',
      })
      if (result) {
        this.toggleNotice = 0
        await this.fetchPageList()
        this.clearCreateContent()
      }
    } else {
      this.toggleNotice = 0
      await this.fetchPageList()
      this.clearCreateContent()
    }
  }

  clearCreateContent() {
    this.noticeContents.title = ''
    this.noticeContents.content = ''
    this.noticeContents.noticeTargetCreate = 0
    this.noticeContents.categoryCreate = null

    this.noticeContents.isReserve = false
    this.noticeContents.openDate.createDate = new Date()
    this.noticeContents.openDate.selectedTime = this.setSelectTime
    this.noticeContents.closeDate.createDate = new Date()
    this.noticeContents.closeDate.selectedTime = this.setEndDateTime

    // 수정 시
    this.editNoticeId = null
  }

  public noticeMode = 'create'

  editNoticeId = null
  editMode = false

  async onEdit(item) {
    this.noticeMode = 'edit'
    this.editMode = true

    try {
      this.fetchTemporaryPageList()

      this.editNoticeId = item.noticeId
      const noticeId = item.noticeId

      const result = await noticeService.getNoticeDetails(noticeId)

      if (result.postStartDt == null && result.postEndDt == null) {
        this.noticeContents.defaultCheck = true
        this.noticeContents.isReserve = false
      } else {
        this.noticeContents.defaultCheck = false
        this.noticeContents.isReserve = true
      }

      this.toggleNotice = 2
      this.noticeContents.title = result.title
      this.noticeContents.content = result.content
      this.noticeContents.noticeTargetCreate = result.noticeTarget
      this.noticeContents.categoryCreate = result.category
      this.noticeContents.isReserve = result.isReserve

      if (!_.isNil(result.postStartDt)) {
        this.noticeContents.defaultCheck = false
        this.noticeContents.isReserve = true
        this.noticeContents.openDate.createDate = new Date(result.postStartDt)
        this.noticeContents.openDate.selectedTime = new Date(result.postStartDt)
      }

      if (!_.isNil(result.postEndDt)) {
        this.noticeContents.closeDate.createDate = new Date(result.postEndDt)
        this.noticeContents.closeDate.selectedTime = new Date(result.postEndDt)
      }
    } catch (e) {
      console.log(e)
    }
  }

  async onRowClicked(item) {
    this.selected = item
    this.categoryDetail = await this.fetchNoticeDetail(item.noticeId)
    console.log(this.categoryDetail)
    this.toggleNotice = 1
  }

  async fetchNoticeDetail(noticeId) {
    try {
      const res = await noticeService.getNoticeDetails(noticeId)
      return res
    } catch (e) {
      console.error(e)
    }
  }

  formattedText(content) {
    return StringUtils.formattedContentText(content)
  }

  /**
   * 공지사항 - 관리자 - 발송
   */

  isSendPopup = false
  pushState = ''

  pushSMS() {
    this.isSendPopup = true
    this.pushState = 'SMS'
  }

  pushApp() {
    this.pushState = 'PUSH'
    this.sendContents({
      title: this.selected.title,
      content: '자세한 내용은 눌러서 확인 하세요',
    })
  }

  async sendContents(value) {
    try {
      if (this.pushState === 'SMS') {
        await noticeService.postSmsPush({
          title: value.title,
          content: value.content,
          id: this.selected.noticeId,
        })
      } else {
        await noticeService.postAppPush({
          title: value.title,
          content: value.content,
          id: this.selected.noticeId,
        })
      }
      await this.$toast({
        type: 'notice',
        content: `${this.pushState} 발송 완료`,
      })
    } catch (e: any) {
      let errorContent: string = ''
      switch (e.response.data.errCode) {
        case 'E5106':
          errorContent =
            '해당 공지사항에 대해 이미 PUSH를 <br/> 전송한 이력이 있습니다.'
          break

        default:
          errorContent = e.response.data.errMsg
          break
      }
      await this.$alert({
        title: '발송 실패',
        content: errorContent,
      })
    }
  }

  /**
   * 공지사항 - 관리자 - 작성
   */
  basicCheck = true
  temporaryItems = [] as any
  isLoadPopup = false

  // 체크박스 변경
  checkboxChange(type) {
    if (type === 'default' && this.noticeContents.defaultCheck) {
      this.noticeContents.isReserve = false
    } else if (type === 'reserve' && this.noticeContents.isReserve) {
      this.noticeContents.defaultCheck = false
    }
  }

  toggleLoadPopup() {
    this.isLoadPopup = !this.isLoadPopup
  }

  loadPopupClose() {
    this.isLoadPopup = false
    this.fetchPageList()
  }

  // 불러오기 삭제
  async deleteItems(payload) {
    if (payload.length > 0) {
      try {
        const noticeIds = payload.map((x) => x.noticeId).join(',')
        await noticeService.deleteNotice(noticeIds)
        await this.fetchTemporaryPageList()
        this.toggleLoadPopup()
      } catch (e: any) {
        await this.$alert({
          title: '공지사항 불러오기 삭제 실패',
          content: e.response.data.errMsg,
        })
      }
    }
  }

  // 불러오기
  async loadItem(payload) {
    if (payload) {
      const res = await this.fetchNoticeDetail(payload.noticeId)
      console.log(res)
      this.noticeContents.title = res.title
      this.noticeContents.content = res.content
      this.noticeContents.categoryCreate = res.category
      this.noticeContents.noticeTargetCreate = res.noticeTarget
      this.noticeContents.isReserve =
        res.postStartDt || res.postEndDt ? true : false
      this.noticeContents.defaultCheck = !this.noticeContents.isReserve
      if (res.postStartDt) {
        this.noticeContents.openDate.createDate = DatetimeUtils.stringDate(
          res.postStartDt,
          'YYYY-MM-DD',
        )
        this.noticeContents.openDate.selectedTime = new Date(res.postStartDt)
      }
      if (res.postEndDt) {
        this.noticeContents.closeDate.createDate = DatetimeUtils.stringDate(
          res.postEndDt,
          'YYYY-MM-DD',
        )
        this.noticeContents.closeDate.selectedTime = new Date(res.postEndDt)
      }
    }
  }

  // 등록하기
  public isRegisterLoading: boolean = false

  async saveNotice(temporary) {
    const params = {
      noticeId: this.editNoticeId,
      temporary,
      noticeTarget: this.noticeContents.noticeTargetCreate,
      category: this.noticeContents.categoryCreate,
      title: this.noticeContents.title,
      content: this.noticeContents.content,
      ...(this.noticeContents.isReserve
        ? {
            postStartDt: this.formattedDateTime,
            postEndDt: this.formattedcloseDateTime,
          }
        : {}),
    }

    try {
      if (this.editNoticeId) {
        if (temporary) {
          this.isRegisterLoading = true
          const temporaryStorage = await this.$confirm({
            title: '임시저장 안내',
            content: '작성하신 내용을 임시 저장하시겠습니까?',
          })
          if (temporaryStorage) {
            await noticeService.postRegisterNotice(params)
            await this.fetchTemporaryPageList()
            await this.$toast({
              type: 'notice',
              content: '임시 저장 되었습니다',
            })
          }
          this.isRegisterLoading = false
        } else {
          this.isRegisterLoading = true
          await noticeService.putEditNotice(params)
          await this.$toast({
            type: 'notice',
            content: `공지사항이 수정 되었습니다.`,
          })
          this.isRegisterLoading = false
          this.backToList()
        }
      } else {
        if (!temporary) {
          this.isRegisterLoading = true
          const result = await this.$confirm({
            title: '공지사항 등록',
            content: '작성하신 내용으로 공지사항을<br/>등록 하시겠습니까?',
          })
          if (result) {
            await noticeService.postRegisterNotice(params)
            this.isRegisterLoading = false
            await this.$toast({
              type: 'notice',
              content: `공지사항이 등록 되었습니다.`,
            })
            this.backToList()
          } else {
            this.isRegisterLoading = false
          }
        } else {
          this.isRegisterLoading = true
          const temporaryStorage = await this.$confirm({
            title: '임시저장 안내',
            content: '작성하신 내용을 임시 저장하시겠습니까?',
          })
          if (temporaryStorage) {
            await noticeService.postRegisterNotice(params)
            await this.fetchTemporaryPageList()
            await this.$toast({
              type: 'notice',
              content: '임시 저장 되었습니다',
            })
          } else {
            this.isRegisterLoading = false
          }
        }
      }
    } catch (e: any) {
      this.isRegisterLoading = false
      const errCode = e.response.data.errCode
      let errContent = ''

      if (errCode === 'E0010')
        errContent = '미입력 또는 미선택한 항목이 존재합니다.'
      else errContent = e.response.data.errMsg

      await this.$alert({
        title: '공지사항 등록 실패',
        content: errContent,
      })
    } finally {
      this.isRegisterLoading = false
    }
  }

  //공지사항 정보
  noticeContents = {
    title: '',
    content: '',
    noticeTargetCreate: 0,
    categoryCreate: null,
    defaultCheck: true,
    isReserve: false,
    openDate: {
      createDate: new Date(),
      selectedTime: this.setSelectTime,
    },
    closeDate: {
      createDate: new Date(),
      selectedTime: this.setEndDateTime,
    },
  }

  get setSelectTime() {
    let currentDate = new Date()
    let currentMinutes = currentDate.getMinutes()
    let adjustedMinutes = Math.floor(currentMinutes / 30) * 30 + 30
    currentDate.setMinutes(adjustedMinutes)
    return currentDate
  }

  get setEndDateTime() {
    let currentDate = new Date(Date.now() + 60 * 60 * 1000)
    let currentMinutes = currentDate.getMinutes()
    let adjustedMinutes = Math.floor(currentMinutes / 30) * 30 + 30
    currentDate.setMinutes(adjustedMinutes)
    return currentDate
  }

  get formattedDateTime() {
    const date = DatetimeUtils.stringDate(
      this.noticeContents.openDate.createDate,
      'YYYY-MM-DD',
    )
    const time = DatetimeUtils.stringTimerDate(
      this.noticeContents.openDate.selectedTime,
    )

    return `${date} ${time}`
  }

  get formattedcloseDateTime() {
    const date = DatetimeUtils.stringDate(
      this.noticeContents.closeDate.createDate,
      'YYYY-MM-DD',
    )
    const time = DatetimeUtils.stringTimerDate(
      this.noticeContents.closeDate.selectedTime,
    )

    return `${date} ${time}`
  }

  isChangedContents: boolean = false
  isDateValidate: boolean = false

  @Watch('noticeContents', { deep: true })
  changedNoticeContents(value) {
    console.log(value)
    console.log(this.toggleNotice)
    if (this.toggleNotice === 0) this.isChangedContents = false
    else this.isChangedContents = true
    console.log(this.isChangedContents)
  }

  @Watch('noticeContents.openDate', { deep: true })
  changeOpenDate(value) {
    const currentDiff =
      DatetimeUtils.stringDate(value.createDate, 'YYYY-MM-DD') ===
      DatetimeUtils.now_format('YYYY-MM-DD')

    const durationDiff =
      DatetimeUtils.stringDate(value.createDate, 'YYYY-MM-DD') ===
      DatetimeUtils.stringDate(
        this.noticeContents.closeDate.createDate,
        'YYYY-MM-DD',
      )

    if (!this.editMode) {
      if (moment().diff(value.createDate, 'days') > 0) {
        // 현재보다 과거 날짜 선택시
        this.$toast({
          type: 'error',
          content: '발송날짜/시간을 다시 확인해 주세요',
        })
        this.clearDatePicker('openDate')
      } else if (
        currentDiff &&
        moment().diff(value.selectedTime, 'minutes') > 0
      ) {
        // 현재 날짜에 과거 시간 선택시
        this.$toast({
          type: 'error',
          content: '발송날짜/시간을 다시 확인해 주세요',
        })
        this.clearDatePicker('openDate')
      } else if (
        moment(this.noticeContents.closeDate.createDate).diff(
          value.createDate,
          'days',
        ) < 0
      ) {
        // closeDate보다 미래날짜를 선택 시
        this.$toast({
          type: 'error',
          content: '설정 날짜/시간을 다시 확인해 주세요',
        })
        this.clearDatePicker('openDate')
      } else if (
        durationDiff &&
        moment(this.noticeContents.closeDate.selectedTime).diff(
          value.selectedTime,
          'minutes',
        ) < 0
      ) {
        // closeDate와 같은 날짜에 미래 시간 선택시
        this.$toast({
          type: 'error',
          content: '설정 날짜/시간을 다시 확인해 주세요',
        })
        this.clearDatePicker('openDate')
      }
    }
  }

  @Watch('noticeContents.closeDate', { deep: true })
  changeCloseDate(value) {
    const currentDiff =
      DatetimeUtils.stringDate(value.createDate, 'YYYY-MM-DD') ===
      DatetimeUtils.now_format('YYYY-MM-DD')
    const durationDiff =
      DatetimeUtils.stringDate(value.createDate, 'YYYY-MM-DD') ===
      DatetimeUtils.stringDate(
        this.noticeContents.openDate.createDate,
        'YYYY-MM-DD',
      )

    if (!this.editMode) {
      if (moment().diff(value.createDate, 'days') > 0) {
        // 현재보다 과거 날짜 선택시
        this.$toast({
          type: 'error',
          content: '설정 날짜/시간을 다시 확인해 주세요',
        })
        this.clearDatePicker('closeDate')
        return
      } else if (
        currentDiff &&
        moment().diff(value.selectedTime, 'minutes') > 0
      ) {
        // 현재 날짜에 과거 시간 선택시
        this.$toast({
          type: 'error',
          content: '설정 날짜/시간을 다시 확인해 주세요',
        })
        this.clearDatePicker('closeDate')
      } else if (
        moment(value.createDate).diff(
          this.noticeContents.openDate.createDate,
          'days',
        ) < 0
      ) {
        // openDate보다 과거날짜를 선택 시
        this.$toast({
          type: 'error',
          content: '설정 날짜/시간을 다시 확인해 주세요',
        })
        this.clearDatePicker('closeDate')
      } else if (
        durationDiff &&
        moment(value.selectedTime).diff(
          this.noticeContents.openDate.selectedTime,
          'minutes',
        ) < 0
      ) {
        // openDate와 같은 날짜에 과거 시간 선택시
        this.$toast({
          type: 'error',
          content: '설정 날짜/시간을 다시 확인해 주세요',
        })
        this.clearDatePicker('closeDate')
      }
    }
  }

  clearDatePicker(type = 'openDate') {
    switch (type) {
      case 'openDate':
        this.noticeContents.openDate.createDate = new Date()
        this.noticeContents.openDate.selectedTime = this.setSelectTime
        break
      case 'closeDate':
        this.noticeContents.closeDate.createDate = new Date()
        this.noticeContents.closeDate.selectedTime = this.setEndDateTime
        break
      default:
        ''
    }
  }

  onCreate() {
    this.fetchTemporaryPageList()
    this.toggleNotice = 2
    this.noticeMode = 'create'
  }

  /**
   * 공지사항 - 관리자 관련
   */

  selectedItems = []

  checkSent(v) {
    return v ? '전송완료' : '미전송'
  }

  async onDelete() {
    if (this.selectedItems.length > 0) {
      try {
        const noticeIds = _.map(this.selectedItems, 'noticeId').join(',')
        const res = await this.$confirm({
          title: '공지사항 삭제',
          content: '해당 공지사항을 삭제 하시겠습니까?',
        })

        if (res) {
          const result = await noticeService.deleteNotice(noticeIds)
          this.selectedItems = []
          await this.fetchPageList()
        }
      } catch (e: any) {
        await this.$alert({
          title: '공지 사항 삭제 실패',
          content: e.response.data.errMsg,
        })
      } finally {
      }
    }
  }

  get setPushBtnIsActive() {
    if (this.selected?.appPushedDt) return true
    else if (
      DatetimeUtils.asDays(
        DatetimeUtils.stringDate(this.selected?.regDt, 'YYYY-MM-DD'),
        DatetimeUtils.now_format('YYYY-MM-DD'),
      ) > 4
    )
      return true
    else return false
  }

  get exposureString() {
    if (
      _.isNil(this.selected.postStartDt) &&
      _.isNil(this.selected.postEndDt)
    ) {
      return '상시 노출'
    } else {
      return (
        '노출 예약 : ' +
        this.getDtString(this.selected.postStartDt) +
        ' ~ ' +
        this.getDtString(this.selected.postEndDt)
      )
    }
  }

  getDtString(date) {
    if (_.isNil(date)) return ' '
    else return DateTimeUtil.stringDate(date, 'YYYY-MM-DD HH:mm')
  }

  get noticeTypeString() {
    const nameString = this.noticeTargetCreateItems.find(
      (y) => y.value === this.selected.noticeTarget,
    )?.name
    console.log(nameString)
    return '공지사항 대상 : ' + nameString
  }
}
