




































































import { Vue, Component, Prop, Ref, Watch } from 'vue-property-decorator'
import { mdiWindowClose } from '@mdi/js'
import userService from '@/services-itgo/user.service'

@Component({
  name: 'SMSSendPopup',
  components: {  },
})
export default class SMSSendPopup extends Vue {
  @Prop(Object) preDataInfo
  @Ref() refContent

  userData :any = { }

  async getUserInfo(){
    try {
      const result = await userService.getUserInfo({ userId: this.preDataInfo.userId })
      this.userData = {
        userId: this.preDataInfo?.userId || null,
        name: result.name,
        mobile: result.mobile,
        sendData: result.name + ' ( ' + result.mobile + ' ) '
      }
    }catch (e) {
      await this.$alert({
        title: '화원 정보 조회 싫패',
        content: e.response.data.errMsg,
      })
    }
  }

  icons: any = {
    mdiWindowClose,
  }

  valid: boolean = false
  content = ''

  rules: any = {
    contentRules: [
      (v) => !!v || '내용을 입력해주세요.',
    ],
  }

  mounted(){
    this.getUserInfo()
    this.refContent.onFocus()
  }

  async onClickAddGroup() {
    try {
      await userService.getUserSendMMS({name : this.userData.name, mobile : this.userData.mobile, content : this.content})
      await this.$toast({
        type: 'error',
        content: this.userData.name + '님에게 전송하였습니다.',
      })
      this.$emit('close', false)
    } catch (e: any) {
      await this.$alert({
        title: 'MSM 전송 실패',
        content: e.response.data.errMsg,
      })
    }
  }

  async close() {
    if(this.content){
      const result = await this.$confirm({
        title: '작성 중인 내용이 있습니다.',
        content: `팝업 종료 시 작성 내용이 사라집니다. <br/> 계속 하시겠습니까?.`,
      })
      if (result) {
        this.$emit('close', false)
      }
    }else
      this.$emit('close', false)
  }
}
